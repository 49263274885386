import React from "react";
import ReactPlayer from 'react-player'
//import './ShowYoutube.css'

const YoutubeEmbed = (props) => {

    //https://www.youtube.com/playlist?list=PLoTt7uES3154kpdcGvYny8dsj1EAhe2Jo

    //https://www.youtube.com/embed/${embedId}

    return (
        <div className="player-wrapper">
           
            <ReactPlayer
                url={props.YoutubeLink}
                controls={true}
                stopOnUnmount={true}
                className="react-player"
                width={'100%'}
                height={400}
              //  playing={Videoopen}
                config={{
                    youtube: {
                        playerVars: { showinfo: 1 }
                    }
                }}
            />

        </div>
    )
}    ;

//YoutubeEmbed.propTypes = {
//    embedId: PropTypes.string.isRequired
//};

export default YoutubeEmbed;