import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';

//const AWS = require("aws-sdk");
//AWS.config.region = 'us-east-1'; // Region
//AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
//});

//var dynamodb = new AWS.DynamoDB({ apiVersion: '2012-08-10' });

function removeEmptyStringElements(obj) {
    for (var prop in obj) {
        if (typeof obj[prop] === 'object') {
            removeEmptyStringElements(obj[prop]);
        } else if (obj[prop] === '' || obj[prop] === undefined) {
            delete obj[prop];
        }
    }
    return obj;
}

export const EnterSMS = (props) => {

    let InputArray = [];

    let SubSetupId = `Subscription::${props.OwnerId}`

    const SetupSMS = {
        "pk1": `phone::${props.SelectedNumber}`,
        "sk1": `phone::${props.SelectedNumber}`,
        "PhoneSid": props.PhoneSid,
        "Access": `All`,
        "AllItemForms": [
            {
                "sk1": `Data::Company::Asset::AddSMS::${props.UUID}`,
                "Category": `AddSMS`,
                "Documents": [
                ],
                "field1": `Add SMS`,
                "FormData": [
                    {
                        "Category": `AddSMS`,
                        "defaultValue": `${props.Title}`,
                        "Editable": false,
                        "extra": `field1`,
                        "key": `ff_MznF1Ixq28`,
                        "label": `Short Title`,
                        "options": [
                        ],
                        "Order": 1,
                        "required": true,
                        "Searchable": true,
                        "ShowValue": `${props.Title}`,
                        "TableColumn": true,
                        "type": `textline`,
                        "VariableInput": `${props.Title}`,
                        "VariableName": `name`
                    },
                    {
                        "Category": `AddSMS`,
                        "defaultValue": `${props.SelectedNumber}`,
                        "Editable": false,
                        "key": `ff_MznF1Ixq29`,
                        "label": `Mobile/SMS`,
                        "options": [
                        ],
                        "Order": 3,
                        "required": true,
                        "ShowValue": `${props.SelectedNumber}`,
                        "TableColumn": false,
                        "type": `phone`,
                        "VariableInput": `${props.SelectedNumber}`,
                        "VariableName": `mobile`
                    }
                ],
                "FormName": `Add SMS`,
                "FormType": `AddSMS`,
                "key": `Data::Company::Asset::AddSMS::${props.UUID}`,
                "PhotoService": [
                ],
                "SearchWords": `x`,
                "ValueAdded": true
            }
        ],
        "AllowSMSService": true,
        "BranchId": props.OwnerId,
        "BranchName": props.OwnerName,
        "SubscriptionPlan": props.SubscriptionPlan,
        "Category": `AddSMS`,
        "cid": `Company_V94CtJtL`,
        "CompanyId": props.OwnerId,
        "CompanyName": props.OwnerName,
        "ContactCardFileFullPath": `https://s3.amazonaws.com/app.contractorcheckin.com/public/SMSContactCard_5aP8SCxS.vcf`,
        "ContractorId": props.OwnerId,
        "CustomerType": `Contractor`,
        "date": props.date,
        "TimeStamp": props.TimeStamp,
        "Documents": [
        ],
        "field1": `${props.Title}`,
        "field2": `SMS/Text # ${props.SelectedNumber}`,
        "gsi1Pk2": "Phone::Developer::Account::0786",
        "gsi1Sk2": props.TimeStamp,
        "gsi2Pk2": props.OwnerId,
        "gsi2Sk2": `phone::${props.SelectedNumber}`,
        "HQId": `Developer::Account::0786`,
        "HQName": `Service Notification Inc.`,
        "id": `${props.UUID}`,
        "key": `Data::Company::Asset::AddSMS::${props.date}`,
        "Level": 100,
        "mobile": `${props.SelectedNumber}`,
        "phone": props.PersonMobile,
        "PhotoService": [
        ],
        "posterId": {
            "pk1": props.PersonId,
            "sk1": props.PersonId,
            "email": props.PersonEmail,
            "field1": `Support`,
        },
        "Privileges": {
            "label": `Field Resource`,
            "value": 100
        },
        "receiveEmail": false,
        "receivePhone": false,
        "receiveSMS": false,
        "SearchWords": `AddSMS: ${props.Title}, ${props.SelectedNumber}, `,
        "ShowDate": props.ShowDate,
        "status": props.status,
        "SubDomain": `app.${props.WebsiteType}.com`,
        "TimeZone": props.TimeZone,
        "SubSetupId": SubSetupId,

    };

    let EnterPhone = {
        "pk1": `phone::${props.SelectedNumber}`,
        "sk1": `phone::${props.SelectedNumber}`,
        "gsi1Pk2": "Phone::Developer::Account::0786",   //search of account within 30 days and after 30 days
        "gsi1Sk2": props.TimeStamp,
        "gsi2Pk2": props.OwnerId,                       //get numbers by each owner
        "gsi2Sk2": `phone::${props.SelectedNumber}`,
        "gsi3Pk": "Phone::Developer::Account::0786",
        "gsi3Sk": props.SubscriptionPlan,
        "dataJSON": JSON.stringify(removeEmptyStringElements(SetupSMS))
    }

    InputArray.push(EnterPhone);
    console.log("CreateDB.SetupSMS", SetupSMS);

    if (props.NewSubscription) {

        const SubscriptionJSON = {

            "pk1": SubSetupId,                          // `Subscription::${props.OwnerId}`
            "sk1": props.date,                          // search by time stamp 
            "gsi1Pk2": SubSetupId,          // lookup when updating subscription
            "gsi1Sk2": "Pending",           // subscription status
            "gsi2Pk2": "Subscription::Developer::Account::0786",       //dev find sub by dates                
            "gsi2Sk2": props.date,
            "gsi3Pk": "Subscription::Developer::Account::0786",         //dev find by sub Plan
            "gsi3Sk": props.SubscriptionPlan,

            "CompanyId": props.OwnerId,
            "CompanyName": props.OwnerName,
            "ContractorId": props.OwnerId,
            "TimeStamp": props.TimeStamp,
            "SubscriptionPlan": props.SubscriptionPlan,
            "SubSetupId": SubSetupId,
            "date": props.date,

            "field1": props.OwnerName,
            "field2": `Subscription Start: ${props.ShowDate}`,

            PersonId: props.PersonId,
            PersonName: props.PersonName,
            PersonMobile: props.PersonMobile,
            PersonEmail: props.PersonEmail,

            "id": props.PhoneSid,
            "key": SubSetupId,
            "posterId": {
                "pk1": props.PersonId,
                "sk1": props.PersonId,
                "field1": `Support`,
                "field2": props.PersonEmail
            },
            "status": `Active`,
            "SubDomain": `app.${props.WebsiteType}.com`
        };

        const SubscriptionSetup = {

            "pk1": SubSetupId,                          // `Subscription::${props.OwnerId}`
            "sk1": props.date,                          // search by time stamp 
            "gsi1Pk2": SubSetupId,          // lookup when updating subscription
            "gsi1Sk2": "Pending",           // subscription status
            "gsi2Pk2": "Subscription::Developer::Account::0786",       //dev find sub by dates                
            "gsi2Sk2": `Pending::${props.date}`,
            "gsi3Pk": "Subscription::Developer::Account::0786",         //dev find by sub Plan
            "gsi3Sk": props.SubscriptionPlan,

            "dataJSON": JSON.stringify(removeEmptyStringElements(SubscriptionJSON))
        }

        InputArray.push(SubscriptionSetup);

        console.log("CreateDB.SubscriptionSetup", SubscriptionJSON)

    }



    const PolicyDjson = {
        "pk1": props.OwnerId,
        "sk1": `Data::Online::Lambda::Pref::SMS::${props.SelectedNumber}::Urgency_${props.UUID}`,
        "Category": `AddSMS`,
        "CompanyId": props.OwnerId,
        "CompanyName": props.OwnerName,
        "ContractorId": props.OwnerId,
        "date": props.date,
        "DB": [
            {
                "pk1": `Developer::Account::0786`,
                "sk1": props.OwnerId
            },
            {
                "pk1": `Developer::Account::0786`,
                "sk1": `Developer::Account::0786`
            }
        ],
        "field1": `${props.Title} Policy`,
        "field2": `${props.Title} # ${props.SelectedNumber}`,
        "gsi1Pk2": props.OwnerId,
        "gsi1Sk2": `Data::Online::Lambda::Pref::SMS::${props.SelectedNumber}::Urgency_${props.UUID}`,
        "gsi2Pk2": props.OwnerId,
        "gsi2Sk2": `Data::Online::Lambda::Pref::SMS::${props.SelectedNumber}::Urgency_${props.UUID}`,
        "id": `Urgency_UXBnzREj`,
        "key": `Data::Online::Lambda::Pref::SMS::${props.SelectedNumber}::Urgency_${props.UUID}`,
        "ManagerApprovalStatus": `Pending`,
        "mobile": `${props.SelectedNumber}`,
        "posterId": {
            "pk1": props.PersonId,
            "sk1": props.PersonId,
            "field1": props.PersonName,
            "field2": props.PersonEmail
        },
        "ReplyEmail": `noreply@sninc.us`,
        "status": `Active`,
        "StepValueArray": [
            {
                "id": 0,
                "KeyId": `CheckInQuestions`,
                "SMSQuestions": [
                    {
                        "Category": `plan`,
                        "defaultValue": true,
                        "field1": `What is the plan for today?`,
                        "include": false,
                        "key": `plan`,
                        "keywords": {
                            "ESC": [
                            ]
                        },
                        "label": `What is the plan for today?`,
                        "PreSelect": false,
                        "VariableInput": true
                    },
                    {
                        "Category": `endtime`,
                        "field1": `What time will you be done for today? please make sure to checkout when you are leaving.`,
                        "include": false,
                        "key": `endtime`,
                        "keywords": {
                            "ESC": [
                            ]
                        },
                        "label": `What time will you be done for today? please make sure to checkout when you are leaving.`,
                        "PreSelect": false,
                        "VariableInput": false
                    },
                    {
                        "Category": `Location`,
                        "field1": `Please share your location`,
                        "include": false,
                        "key": `Location`,
                        "keywords": {
                            "ESC": [
                            ]
                        },
                        "label": `Please share your location`,
                        "PreSelect": false,
                        "VariableInput": false
                    }
                ],
                "UseCustomSMS": true
            },
            {
                "DetailsProps": {
                    "Category": `Assets`,
                    "defaultValue": true,
                    "field1": `Please select the Equipment, Location, Assets`,
                    "include": false,
                    "key": `SMSNew_bkkJVSqJ`,
                    "keywords": {
                        "ESC": [
                        ],
                        "words": `,`
                    },
                    "VariableInput": true
                },
                "id": 1,
                "KeyId": `CheckOutQuestions`,
                "SMSQuestions": [
                    {
                        "Category": `end`,
                        "defaultValue": true,
                        "field1": `Please provide a short summary of the work completed.`,
                        "include": false,
                        "key": `end`,
                        "keywords": {
                            "ESC": [
                            ]
                        },
                        "label": `Please provide a short summary of the work completed.`,
                        "PreSelect": false,
                        "VariableInput": true
                    },
                    {
                        "Category": `pictures`,
                        "defaultValue": false,
                        "field1": `Please include any pictures of the work completed, or say no pictures`,
                        "include": false,
                        "key": `pictures`,
                        "keywords": {
                            "ESC": [
                            ],
                            "words": `,`
                        },
                        "label": `Please include any pictures of the work completed`,
                        "PreSelect": false,
                        "VariableInput": false
                    },
                    {
                        "Category": `Assets`,
                        "defaultValue": true,
                        "field1": `Please select the Equipment, Location, Assets`,
                        "include": false,
                        "key": `SMSNew_bkkJVSqJ`,
                        "keywords": {
                            "ESC": [
                            ],
                            "words": `,`
                        },
                        "VariableInput": false
                    },
                    {
                        "Category": `Location`,
                        "defaultValue": true,
                        "field1": `Please share your location`,
                        "include": false,
                        "key": `Location`,
                        "keywords": {
                            "ESC": [
                            ]
                        },
                        "label": `Please share your location`,
                        "PreSelect": false,
                        "VariableInput": false
                    }
                ],
                "UseCustomSMS": true
            },
            {
                "CompanyESC": [
                    {
                        "pk1": props.OwnerId,
                        "sk1": `Data::Online::Lambda::ESC::Time#ESC_${props.UUID}`
                    }
                ],
                "CompanyESCDefault": [
                    `Data::Online::Lambda::ESC::Time#ESC_${props.UUID}`
                ],
                "id": 2,
                "KeyId": `ESCSelectContractor`,
                "UseCustomESC": false
            },
            {
                "Delay": `1440`,
                "id": 3,
                "KeyId": `ReminderSetup`
            }
        ],
        "SubDomain": `app.${props.WebsiteType}.com`
    }

    let TimeSetup = {
        "pk1": props.OwnerId,
        "sk1": `Data::Online::Lambda::Pref::SMS::${props.SelectedNumber}::Urgency_${props.UUID}`,
        "gsi1Pk2": props.OwnerId,
        "gsi1Sk2": `Data::Online::Lambda::Pref::SMS::${props.SelectedNumber}::Urgency_${props.UUID}`,
        "gsi2Pk2": props.OwnerId,
        "gsi2Sk2": `Data::Online::Lambda::Pref::SMS::${props.SelectedNumber}::Urgency_${props.UUID}`,
        "dataJSON": JSON.stringify(removeEmptyStringElements(PolicyDjson))
    }

    InputArray.push(TimeSetup);



    const NotificationSchedule_dataJSON = {
        "pk1": props.OwnerId,
        "sk1": `Data::Online::Lambda::ESC::Time#ESC_${props.UUID}`,
        "Colors": [
            {
                "color": `#ee1313`,
                "key": `ShiftBoxColor`,
                "name": `Shift Calendar Box Color`,
            },
            {
                "color": `#ffffff`,
                "key": `ShiftTextColor`,
                "name": `Shift Text Color`,
            }
        ],
        "CompanyId": props.OwnerId,
        "CompanyName": props.OwnerName,
        "ContractorId": props.OwnerId,
        "Days": [
            0,
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "EndTime": `24:00`,
        "field1": `${props.Title} Shift`,
        "field2": props.OwnerName,
        "gsi1Pk2": `ESC::${props.OwnerId}`,
        "gsi1Sk2": `3000-01-01T23:59:59.000`,
        "gsi2Pk2": props.OwnerId,
        "gsi2Sk2": `Data::Online::Lambda::ESC::Time#ESC_${props.UUID}`,
        "id": `ESC_${props.UUID}`,
        "key": `Data::Online::Lambda::ESC::Time#ESC_${props.UUID}`,
        "MonthsOfYear": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11
        ],
        "NewTeamId": `ESC_${props.UUID}::Team_mgU3rL5z`,
        "NextDay": false,
        "PersonalDB": [
            {
                "pk1": props.PersonId,
                "sk1": props.PersonId,
            }
        ],
        "posterId": {
            "pk1": props.PersonId,
            "sk1": props.PersonId,
            "email": props.PersonEmail,
            "mobile": props.PersonMobile,
            "phone": props.PersonMobile
        },
        "SearchWords": `${props.Title} Shift`,
        "StartTime": `00:00`,
        "status": `Active`,
        "StepValueArray": [
            {
                "delay": `end`,
                "EditItem": {},
                "key": `Drwr_ufEp4w7W1`,
                "NotificationStepsList": [
                    {
                        "pk1": props.OwnerId,
                        "CompanyName": props.OwnerName,
                        "DB": [
                            {
                                "pk1": props.PersonId,
                                "sk1": props.PersonId,
                            }
                        ],
                        "EndDate": `${props.ShowDate}`,
                        "ESCId": `ESC_${props.UUID}`,
                        "field1": `${props.Title} Shift Step 1`,
                        "field2": `${props.ShowDate} to On Going`,
                        "key": `Data::Online::Lambda::ESC::Steps#ESC_${props.UUID}#Step1#TeamTeam_${props.UUID}`,
                        "ScheduleType": `Recurring`,
                        "SearchWords": `${props.Title} Shift Step 1 | ${props.PersonName} ${props.PersonEmail}`,
                        "StartDate": `${props.ShowDate}`,
                        "StepName": `${props.Title} Shift Step 1`,
                        "StepNumber": 1,
                        "StepPersonalList": [
                            {
                                "pk1": props.PersonId,
                                "sk1": props.PersonId,
                                "Category": `Person`,
                                "CompanyName": props.OwnerName,
                                "email": props.PersonEmail,
                                "emailcheck": true,
                                "field1": props.PersonName,
                                "field2": props.OwnerName,
                                "key": props.PersonId,
                                "mobile": props.PersonMobile,
                                "phone": props.PersonMobile,
                                "smscheck": true
                            }
                        ],
                        "StepTeam": `Team_${props.UUID}`,
                    }
                ],
                "OpenAlert": false,
                "StepNumber": 1,
                "StepTeam": `Team_${props.UUID}`,
                "WebsiteType": props.WebsiteType,
            },
            {
                "key": `Drwr_ufEp4w7W2`,
                "StepNumber": 2
            },
            {
                "key": `Drwr_ufEp4w7W3`,
                "StepNumber": 3
            },
            {
                "key": `Drwr_ufEp4w7W4`,
                "StepNumber": 4
            },
            {
                "key": `Drwr_ufEp4w7W5`,
                "StepNumber": 5
            }
        ],
        "WeekOfMonth": [
            1,
            2,
            3,
            4,
            5
        ]
    };


    const Schedule = {
        "pk1": props.OwnerId,
        "sk1": `Data::Online::Lambda::ESC::Time#ESC_${props.UUID}`,
        "gsi1Pk2": `ESC::${props.OwnerId}`,
        "gsi1Sk2": `3000-01-01T23:59:59.000`,
        "gsi2Pk2": props.OwnerId,
        "gsi2Sk2": `Data::Online::Lambda::ESC::Time#ESC_${props.UUID}`,
        "dataJSON": JSON.stringify(removeEmptyStringElements(NotificationSchedule_dataJSON))
    }

    InputArray.push(Schedule);

    console.log("CreateDB.NotificationSchedule_dataJSON", NotificationSchedule_dataJSON)
    console.log("CreateDB.InputArray", InputArray)

    API.graphql(graphqlOperation(createBatch, { input: InputArray }))
        .then((results) => {
            console.log('createBatch Data ', results);
            SendText(props.PhoneSid, "+15625689508");
            SendText(`Account Setup Success \n\n${props.OwnerName}\n${props.PhoneSid}\n${props.OwnerId}\n${props.PersonEmail}`, "+15627601914");

        }).catch(error => {

            SendText(`Account Setup Failed \n\n${props.OwnerName}\n${props.PhoneSid}\n${props.OwnerId}\n${props.PersonEmail}`, "+15627601914");

            console.log('createBatch error ', error)
        }
        );

}

const SendText = (postTEXT, phone) => {

    let SMSstring = {
        Body: postTEXT,
        FromSMS: "+15627601914",
        From: "+15623936334",
        To: [phone],
        mediaUrl: [],
        pk1: "",
        CompanyId: "",
        ContractorId: "",
        SMSList: [{
            PersonName: "",
            PersonId: "",
            phone: "+15627601914"
        }]
    };

    // console.log("ItemAddPage.Invite.SMS", SMSstring)

    API.post("SMStoTwilio", "", {
        body: { Records: SMSstring }
    });

}