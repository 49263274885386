import React, { useState, useEffect } from 'react';
import { Storage } from "aws-amplify";
import FilePdfOutlined from '@mui/icons-material/PictureAsPdf';
import FileZipOutlined from '@mui/icons-material/Description';
import VideoCameraOutlined from '@mui/icons-material/Videocam';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Grid, LinearProgress, Paper, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import aws_exports from '../aws-exports'
//import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";



function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 150,
    },
    boxx: {
        padding: theme.spacing(2),
        margin: 'auto',
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    input: {
        display: 'none',
    }
}));


const Uploadtest = (props) => {
    const classes = useStyles();
    const initialState = {
        selectedFile: null,
        filess: null,
        loadings: 0,
        alartmessage: "",
        ImageDisplay: [],
        ImageList: [],
        AlartMessage: "",
        DocList: [],
        open: false,
        increment: 1,
    }
    const [state, setState] = useState(initialState);
    //  const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);

    //useEffect(() => {
    //  //setState(...props.ImageStateSet)
    //  console.log("props.ImageStateSet", props.ImageStateSet)
    //  setState(prevState => ({ ...prevState, ...props.ImageStateSet }))
    //}, [props.ImageStateSet])



    //useEffect(() => {
    //  // props.ImageStateGet(state)
    //}, [state])

    const onChangeHandler = (event) => {
        try {
            // var loading = 0;
            // var loadProgress = 0;
            var filetypes = 0;
            var uuuid = "";
            var fileUploadedarr = {};
            //var tempimagearr = state.ImageDisplay;
            const fileUploaded = event.target.files[0];
            //setState({ alartmessage: '' })

            fileUploadedarr.name = fileUploaded.name;

            var ext = getExtension(fileUploaded.name);
            let AlarmMessage = "";
            fileUploadedarr.caption = "";
            //toast.dismiss();
            console.log("fileUploadedarr", fileUploadedarr);
            switch (ext.toLowerCase()) {
                case "zip":
                    filetypes = 1;
                    fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "7z":
                    filetypes = 1;
                    fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "tar":
                    filetypes = 1;
                    fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "gz":
                    filetypes = 1;
                    fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "wim":
                    filetypes = 1;
                    fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "pdf":
                    filetypes = 2;
                    fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FilePdfOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "m4v":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "avi":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "mpg":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "mp4":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "wmv":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "jpg":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "gif":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "bmp":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "png":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                default:
                    AlarmMessage += "* Please Upload Correct File ";
            }

            //console.log("fileUploadedarr", fileUploadedarr);
            if (fileUploaded.size > 20 * 1048576) {
                AlarmMessage += "* File is Larger then 20M ";
            }

            if (props.fileallowed === "image") {
                AlarmMessage += "* Only Image File allowed ";
            }

            if (AlarmMessage !== "") {
                //toast.error(AlarmMessage, {
                //  position: "top-center",
                //  autoClose: false,
                //  hideProgressBar: true,
                //  closeOnClick: true,
                //  pauseOnHover: true,
                //  draggable: true,
                //  progress: undefined,
                //});

                setState((prevState) => ({
                    ...prevState,
                    open: true, AlartMessage: AlarmMessage
                }))
                return;

            }
            //console.log("fileUploadedarr", fileUploadedarr);
            const cleanfile_name = fileUploaded.name.replace(/[^a-zA-Z0-9._]/g, "");

            //let Uploaddir = HQData.ContractorId + "/";

            fileUploadedarr.filetypes = filetypes;
            fileUploadedarr.dir = `public/`;
            fileUploadedarr.Uploaddir = "";
            fileUploadedarr.bucket = HQData.Bucket;
            fileUploadedarr.region = aws_exports.aws_project_region;

            const file_name = `${Date.now()}-${cleanfile_name}`;
            const file_name_dir = fileUploadedarr.Uploaddir + file_name;
            fileUploadedarr.filename = file_name;
            fileUploadedarr.thum = "thum_" + file_name;
            fileUploadedarr.width = 100;
            fileUploadedarr.type = fileUploaded.type;

            fileUploadedarr.ImageFullPath = `https://s3.amazonaws.com/${HQData.Bucket}/public/${file_name}`
               // `https://${HQData.Bucket}.s3.amazonaws.com/public/${file_name}`


            console.log("fileUploadedarr", fileUploadedarr);
            Storage.put(file_name_dir, fileUploaded, {
                bucket: HQData.Bucket,
                progressCallback: (progress) => {
                    handleprocessbar(progress);
                },
            }).then((ret) => {
                console.log("image back", ret);
                fileUploadedarr.inbucket = "public/" + ret.key;
            });

            uuuid = `rug-${Date.now()}-${state.increment}`;
            setState(prevState => ({ ...prevState, increment: state.increment + 1 }))

            fileUploadedarr.uid = uuuid;
            fileUploadedarr.cat = props.Category;
            props.TotalFile(fileUploadedarr)

        } catch (err) { console.log("Image err", err); }
    };

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            AlartMessage: "",
            open: false
        }))
    };

    //const onRemoveItem = (i) => {
    //  const list = state.list.filter((item, j) => i !== j);
    //  setState(prevState => ({ ...prevState, list: list }))
    //};


    //const onUpdateItems = () => {
    //  const list = state.list.map((item) => item + 1);
    //  setState(prevState => ({ ...prevState, list: list }))
    //};

    //const onAddItem = () => {
    //  const list = state.list.concat(state.value);
    //  setState(prevState => ({ ...prevState, list: list, value: "" }));
    //};

    //const captionText = (e, index, uid) => {
    //  try { 
    //  let emailtemparr = state.ImageDisplay;
    //  let filetypes = emailtemparr[index].filetypes;
    //  emailtemparr[index].caption = e.target.value;
    //  let uuid = emailtemparr[index].uid;
    //  setState(prevState => ({ ...prevState, ImageDisplay: emailtemparr }));

    //    console.log("emailtemparr", emailtemparr)

    //  if (filetypes > 2) {
    //    //let ImageList = [...state.ImageList, fileUploadedarr]

    //    let toUpdate = state.ImageList;
    //    let newArray = toUpdate.findIndex(obj => obj.uid === uuid);
    //    console.log("ImageList-newArray", uuid, newArray, toUpdate);
    //    if (newArray > -1) {
    //      toUpdate[newArray].caption = e.target.value;
    //      props.ImageList(toUpdate);
    //      // props.ImageStateGet(state)
    //      setState(prevState => ({ ...prevState, ImageList: toUpdate }));
    //      //setState({ ImageList: toUpdate });
    //    }
    //  }

    //  if (filetypes < 3) {
    //    let toUpdate = state.DocList;
    //    let newArray = toUpdate.findIndex(obj => obj.uid === uuid);
    //    console.log("ImageList-newArray", uuid, newArray, toUpdate);
    //    if (newArray > -1) {
    //      toUpdate[newArray].caption = e.target.value;
    //      props.DocumentList(toUpdate);
    //      // props.ImageStateGet(state)
    //      setState(prevState => ({ ...prevState, DocList: toUpdate }));
    //      //setState({ DocList: toUpdate });
    //    }

    //    }
    //  } catch (err) { console.log("captionText err", err) }
    //};

    //const onDeleteImage = (e, index, uid) => {
    //  try {
    //  let emailtemparr = state.ImageDisplay;
    //  let filetypes = emailtemparr[index].filetypes;
    //  let uuid = emailtemparr[index].uid;
    //  emailtemparr.splice(index, 1);
    //  setState(prevState => ({ ...prevState, ImageDisplay: emailtemparr }));

    //  if (filetypes > 2) {
    //    //let ImageList = [...state.ImageList, fileUploadedarr]

    //    let toDelete = new Set([uuid]);
    //    let newArray = state.ImageList.filter(obj => !toDelete.has(obj.uid));

    //    console.log("ImageList-newArray", newArray);
    //    props.ImageList(newArray);
    //    // props.ImageStateGet(state)
    //    setState(prevState => ({ ...prevState, ImageList: newArray }));
    //    //setState({ ImageList: newArray });
    //  }

    //  if (filetypes < 3) {

    //    let toDelete = new Set([uuid]);
    //    let newArray = state.DocList.filter(obj => !toDelete.has(obj.uid));

    //    console.log("DocList-newArray", newArray);
    //    props.DocumentList(newArray);
    //    // props.ImageStateGet(state)
    //    setState(prevState => ({ ...prevState, DocList: newArray }));
    //    //setState({ DocList: newArray });
    //  }

    //  //props.imagearr(emailtemparr);
    //  //props.returnemails(emailtemparr);
    //} catch (err) { console.log("onDeleteImage err", err) }
    //};

    const handleprocessbar = (process) => {
        let loadiss = Math.round((process.loaded / process.total) * 100);
        setState(prevState => ({ ...prevState, loadings: loadiss }));
    };


    //    <Card
    //      extra={
    //        <a onClick={(e) => onDeleteImage(e, index, post.uid)}>
    //          Delete
    //          </a>
    //      }
    //      key={post.uid}
    //      size="small"
    //      style={{ width: 150 }}
    //      bordered={true}
    //      cover={
    //        post.filetypes === 4 ? (
    //          <img alt="preview" src={post.blob} />
    //        ) : (
    //            post.blob
    //          )
    //      }
    //    >
    //      <div key={index}>

    //      </div>
    //    </Card>
    //  </div>
    //));

    return (
        <div className={classes.root}>
            <Paper className={classes.boxx}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid xs={12} item>
                        <input
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={onChangeHandler}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="contained" color="primary" component="span"
                                startIcon={<CloudUploadIcon />}
                            >
                                {props.title}
                            </Button>

                        </label>

                    </Grid>
                    <Grid xs={12} item>
                        <LinearProgress variant="determinate" value={state.loadings} />
                    </Grid>

                </Grid>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={state.open}
                onClose={handleClose}
                message={state.AlartMessage}
                key="w87482ows"
            />
        </div>
    );
}



export default Uploadtest;
