import React, { useEffect, useState } from 'react';
//import Box from '@mui/material/Box';
import {
    Avatar,
    Button, Card, CardHeader, CardContent,
    Backdrop, CircularProgress
} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { API, graphqlOperation } from "aws-amplify";
import { deleteSvcbotDb } from '../graphql/mutations';
//import ColumnHeaderAppBar from '../components/ColumnHeaderAppBar';
import GlobalPersonToolbarHeading from '../components/GlobalPersonToolbarHeading';
//import SRListWindow from './SRListWindow';
import {
    NewPageId,
    FindCategoryIcon, Pk1SingelItem,
    removeEmptyStringElements,
    getAutoCompletedata
} from '../utils/CommonGraphql';
import Skeleton from '../components/Skeleton';
import Pagination from '@mui/material/Pagination';
//import ChartEachCompany from '../Charts/ChartEachCompany';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { red } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';


export default function ColumnsGrid(props) {
    const dispatch = useDispatch();
    const initialState = {
        SearchWords: "",
        SortTerm: "Latest",
        Data: [],
        PerPageData: [],
        NewPageIdd: "828",
        DeleteItem: "",
        DataBack: [],
        RawData: []
    }
    const [state, setState] = useState(initialState);
    const [noOfPages, SetnoOfPages] = useState(1);
    const [page, setPage] = useState(1);
    const [ItemCount, SetItemCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);
    const [open, setOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)

    useEffect(() => {
        console.log("SRTest.Column.props", props);
        GetDatafromDB("Person", props.CompanyId, "");
    }, []);

    useEffect(() => {

        if (TriggerUpdate === true) {
            console.log("DocumentPage.TriggerUpdate", TriggerUpdate);
            GetDatafromDB("Person", props.CompanyId, "");
            dispatch({ type: 'TRIGGER_UPDATE', payload: false });
        }

    }, [TriggerUpdate]);

    const GetDatafromDB = async (Category, pk1, AssetType) => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: "DataBack",
                    DuplicateArray: "RawData",
                    ArraySearchString: ":",
                    Order: "descending",
                    Sort: true,
                    Sortby: "field1"
                }
            ]
        };

        let NewData = await getAutoCompletedata(DataString, pk1, AssetType);

        if (NewData && NewData.DataBack && NewData.DataBack.length > 0) {

            DataPerPage(NewData.DataBack, "GetDatafromDB");

            setState(prevState => ({
                ...prevState,
                ...NewData
            }))
        }

        SetLoading(false);
        console.log("ListItems.NewData", NewData.DataBack);
    }
        
    const NarrowSearch = (search) => {

        try {

            let DataBack = [];
            let RawData = state.DataBack;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.SearchWords && str.SearchWords !== "") {
                        if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                            DataBack.push(str);
                        }
                    }
                })
            } else {
                DataBack = RawData;
            }

            //setState(prevState => ({
            //    ...prevState,
            //    DataBack: DataBack,
            //}));

            DataPerPage(DataBack, "NarrowSearch");

        } catch (err) {
            console.log("NarrowSearch err", err);
        }
    }

    const DataPerPage = (DataBack, from) => {
        SetItemCount(DataBack.length);
        SetnoOfPages(Math.ceil(DataBack.length / rowsPerPage))
        let PerPageData = DataBack.slice((page - 1) * rowsPerPage, page * rowsPerPage);

        setState(prevState => ({
            ...prevState,
            PerPageData: PerPageData,
            from: from
        }));
    }

    const handleChange = (event, value) => {
        setPage(value);
        DataPerPage(state.DataBack, "page")
    }

    const UpdateData = (data) => {
        console.log("GlobalPersonList.data", data);
        if (data) {
            DataPerPage(data, "UpdateData");

            setState(prevState => ({
                ...prevState,
                ItemData: data,
                DataBack: data,
                // BranchList: data.BranchList,
                NewPageIdd: NewPageId("glbl24")
            }));

        }
    }

    const ConfirmDelete = (item) => {
        setOpen(true)
        setState(prevState => ({ ...prevState, DeleteItem: item }));
    }

    const handleClose = () => {
        setOpen(false);
    };

    const RemovePersonDB = async (Indexx) => {

        try {
            
            if (state.DataBack
                && state.DataBack[Indexx]
                && state.DataBack[Indexx].pk1
            ) {
                console.log("GlobalPersonList.DeleteItem1", state.DeleteItem);
                let NewDBinput = {
                    pk1: state.DataBack[Indexx].pk1,
                    sk1: state.DataBack[Indexx].sk1
                }
                console.log("GlobalPersonList.NewDBinput", NewDBinput);
                API.graphql(graphqlOperation(deleteSvcbotDb, { input: NewDBinput }))
                    .then((results) => {
                        console.log("GlobalPersonList.results", results);
                    })
            }


        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const RemoveOption = () => {

        if (state.DeleteItem !== "") {

            let TradeServicestemparr = state.DataBack;
            let Findindd = state.DataBack.findIndex(e => e.sk1 === state.DeleteItem);

            RemovePersonDB(Findindd);
            setOpen(false);

            TradeServicestemparr.splice(Findindd, 1);

            setState(prevState => ({
                ...prevState,
                DataBack: TradeServicestemparr,
            }));

            DataPerPage(TradeServicestemparr, "RemoveOption");

        }
        
    }

    //  
    console.log("GlobalPersonList.state", state);
    console.log("GlobalPersonList.props", props);

    return (
        <div key={state.NewPageIdd}>

            <Grid container spacing={1}>

                <Grid item xs={12} >

                    <GlobalPersonToolbarHeading
                        Category="Person"
                        GlobalData={props.ItemData}
                        DataUpdate={e => UpdateData(e)}
                        BranchList={state.BranchList}
                        NarrowSearch={e => NarrowSearch(e)}
                        Data={state.DataBack}
                        ItemData={props.ItemData}
                    />

                </Grid>

                <Grid item xs={6} >
                    <Card>
                        <CardHeader
                            title="Personnel with Global Access"
                        />
                        <CardContent>
                            <Grid container spacing={1}>
                                {state.PerPageData && state.PerPageData.length > 0 ? (
                                    <>
                                        {page > 1 ? (
                                            <Grid item xs={12} >

                                                <Pagination
                                                    // count={noOfPages}
                                                    page={page}
                                                    onChange={handleChange}
                                                    count={noOfPages}
                                                    defaultPage={1}
                                                    color="primary"
                                                    size="small"
                                                    showFirstButton
                                                    showLastButton

                                                />

                                            </Grid>
                                        ) : null}
                                        <Grid item xs={12} >
                                            <List >
                                                {state.PerPageData
                                                    && state.PerPageData.length > 0
                                                    && state.PerPageData.map((each, ind) => (
                                                        <ListItem alignItems="flex-start" key={"lisst" + ind}>
                                                            <ListItemAvatar>
                                                                {each.avatar ?
                                                                    (<Avatar alt="Logo" src={each.avatar} />) : (
                                                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                                                            {FindCategoryIcon("Person")}
                                                                        </Avatar>
                                                                    )}
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={each.field1}
                                                                secondary={each.field2}
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge='end'
                                                                    aria-label="delete"
                                                                    onClick={() => ConfirmDelete(each.sk1)}
                                                                    size="large">
                                                                    <HighlightOffIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    ))}

                                            </List>
                                        </Grid>



                                        {page > 1 ? (
                                            <Grid item xs={12} >

                                                <Pagination
                                                    //  count={noOfPages}
                                                    page={page}
                                                    onChange={handleChange}
                                                    count={noOfPages}
                                                    defaultPage={1}
                                                    color="primary"
                                                    size="small"
                                                    showFirstButton
                                                    showLastButton

                                                />

                                            </Grid>
                                        ) : null}
                                    </>
                                ) : (<Skeleton />)}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
            
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                //  fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Delete Item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={RemoveOption} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
