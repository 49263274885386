import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import DrawerBottomCurved from '../utils/DrawerModule';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {    
    Stack
} from '@mui/material';
import {
    removeEmptyStringElements,
    getAutoCompletedata,
    NewPageId,
    UpdateData,
    FindCategoryIcon
} from '../utils/CommonGraphql';
import PrintIcon from '@mui/icons-material/Print';

//function createData(name, calories, fat, carbs, protein) {
//    return {
//        name,
//        calories,
//        fat,
//        carbs,
//        protein,
//    };
//}

//const rows = [
//    createData('Cupcake', 305, 3.7, 67, 4.3),
//    createData('Donut', 452, 25.0, 51, 4.9),
//    createData('Eclair', 262, 16.0, 24, 6.0),
//    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//    createData('Gingerbread', 356, 16.0, 49, 3.9),
//    createData('Honeycomb', 408, 3.2, 87, 6.5),
//    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//    createData('Jelly Bean', 375, 0.0, 94, 0.0),
//    createData('KitKat', 518, 26.0, 65, 7.0),
//    createData('Lollipop', 392, 0.2, 98, 0.0),
//    createData('Marshmallow', 318, 0, 81, 2.0),
//    createData('Nougat', 360, 19.0, 9, 37.0),
//    createData('Oreo', 437, 18.0, 63, 4.0),
//];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    //const stabilizedThis = array.map((el, index) => [el, index]);
    //stabilizedThis.sort((a, b) => {
    //    const order = comparator(a[0], b[0]);
    //    if (order !== 0) {
    //        return order;
    //    }
    //    return a[1] - b[1];
    //});
    //return stabilizedThis.map((el) => el[0]);

    return array.slice().sort(comparator)

}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Person Name',
    },
    {
        id: 'CompanyName',
        numeric: false,
        disablePadding: false,
        label: 'Company Name',
    },
    
    {
        id: 'CheckIn',
        numeric: false,
        disablePadding: false,
        label: 'Check In',
    },
    {
        id: 'CheckOut',
        numeric: false,
        disablePadding: false,
        label: 'Check Out',
    },
    {
        id: 'DocPic',
        numeric: true,
        disablePadding: false,
        label: 'Doc/Pic',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

//EnhancedTableHead.propTypes = {
//    numSelected: PropTypes.number.isRequired,
//    onRequestSort: PropTypes.func.isRequired,
//    onSelectAllClick: PropTypes.func.isRequired,
//    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//    orderBy: PropTypes.string.isRequired,
//    rowCount: PropTypes.number.isRequired,
//};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h3"
                    id="tableTitle"
                    component="div"
                >
                    History
                </Typography>
            )}

            {/*
                numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )
            */}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export default function EnhancedTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('CheckIn');
    const [selected, setSelected] = React.useState([]);
    const [rows, setrows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const initialState = {
        title: "",
        message: "",
        include: false,
        DrawerTitle: "Check-in Details",
        Progress: 0,
        ProgressList: [],
        label: "this is what",
        UpdateSREvent: "",
        UpdateSREventFlag: false,
        ManagerApprovalStatusColor: "#f5a623",
        Priority: "#4a90e2",
        BoxColor: "#ffffff",
        ShowLinkk: true
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        let Data = [];
        let name = "", CompanyName = "", CheckOut = "", DocPic = "";

        console.log("Table.rows.DataBack", props.DataBack)

        if (props.DataBack) {
            props.DataBack.map((each) => {

                if (each.posterId) {
                    if (each.posterId.field1) {
                        name = toTitleCase(each.posterId.field1);
                    } else {
                        name = "-"
                    }
                    if (each.posterId.CompanyName) {
                        CompanyName = toTitleCase(each.posterId.CompanyName);
                    } else {
                        CompanyName = "-"
                    }
                }

                if (each.status === "CheckOut") {
                    CheckOut = each.CheckOutTime
                } else {
                    CheckOut = "-";
                }

                DocPic = each.Documents.length + each.PhotoService.length

                Data.push({
                    name: name,
                    CompanyName: CompanyName,
                    CheckIn: each.CheckInTime,
                    CheckOut: CheckOut,
                    DocPic: DocPic,
                    pk1: each.pk1,
                    sk1: each.sk1
                })
            })

            setrows(Data);

            handleRequestSort("CheckIn");
        }

        if (state.SR && state.SR.sk1 !== "") {
            let SR = props.DataBack.find(x => x.sk1 === state.SR.sk1)
            console.log("Table.rows.SR", SR)
            setState({
                ...state,
                SR: SR
            });
        }

    }, [props.DataBack])

    const ClosingDrawer = () => {
        //   dispatch({ type: 'TRIGGER_UPDATE', payload: true });
      //  UpdateDatabase();
        SetDeawerOpen(false)
    }

    const UpdateDatabase = () => {

        // UpdateSREvent(data);

        let data = state.UpdateSREvent;

        if (data && data.ServiceRequest && data.ServiceRequest.pk1) {

            var ValueToUpdate = {
                pk1: data.ServiceRequest.pk1,
                sk1: data.ServiceRequest.sk1,
                gsi1Pk2: data.gsi1Pk2,
                gsi2Pk2: data.gsi2Pk2,
                dataJSON: JSON.stringify(removeEmptyStringElements(data)),
            };

            console.log("SRDetails.1.pksk", ValueToUpdate)
            console.log("SRDetails.1.datajson", data)

            UpdateData(ValueToUpdate, "381-SRDetails")
                .then((up) => {
                    console.log("SRDetails.Update.results", up)
                    // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                })
                .catch((err) => console.log("SRDetails.failed", err));
        }
    }

    const handleRequestSort = (event, property) => {
        
        const isAsc = orderBy === property && order === 'desc';
        console.log("Table.property", isAsc, property);
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.sk1);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (pk1, sk1) => {

        console.log("Table.handleClick", pk1, sk1);

        let SR = props.DataBack.find(x => x.sk1 === sk1 && x.pk1 === pk1);

        if (SR) {
            setState({
                ...state,
                SR: SR,
                LoadComponenet: "CheckInDetails",
                DrawerTitle: "Check-in Details",
            });
            SetDeawerOpen(true);
        }

    };
    //
    const PrintReport = (pk1, sk1) => {

        console.log("Table.handleClick", pk1, sk1);

       // let SR = props.DataBack.find(x => x.sk1 === sk1 && x.pk1 === pk1);

        if (selected.length > 0) {

            setState({
                ...state,
                LoadComponenet: "PrintReports",
                DrawerTitle: "Print Report",
            });

            SetDeawerOpen(true);

        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please select check boxes",
                OpenAlert: true
            }));

        }

    };

    const ExportExcel = () => {
             
        if (selected.length > 0) {

            setState({
                ...state,
                LoadComponenet: "ExportExcel",
                DrawerTitle: "Export to Excel",
            });

            SetDeawerOpen(true);

        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please select check boxes",
                OpenAlert: true
            }));

        }

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const CheckSelected = (id) => {
        console.log("Table.1.CheckSelected", id)

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);

       // return selected.indexOf(name) !== -1;

    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const SelectedValues = (item, name) => {
        console.log("SRBox.UpdateSREvent", item)
        setState({
            ...state,
            [name]: item,
        });
        // props.handleInclude(change, props.step.key);
    };

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    console.log("Table.selected", selected);
    console.log("Table.props", props);
    console.log("Table.visibleRows", visibleRows);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Stack
                    direction={{ xs: 'column', lg: 'row' }}
                    spacing={2}
                >
                    <FormControlLabel
                        control={<IconButton
                            onClick={() => PrintReport()}
                            aria-label="Print"
                            color="primary">
                            <PrintIcon />
                        </IconButton>}
                        label="Print Report"
                    />
                    <FormControlLabel
                        control={<IconButton
                            onClick={() => ExportExcel()}
                            aria-label="Export"
                            color="primary">
                            <SaveAltIcon />
                        </IconButton>}
                        label="Export Excel"
                    />
                </Stack>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                           // size={dense ? 'small' : 'medium'}
                            size='medium'
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.sk1);
                              //  const CheckItemSelected = CheckSelected(row.sk1);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                      //  onClick={() => handleClick(row.pk1, row.sk1)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.sk1}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >

                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={() => CheckSelected(row.sk1)}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            onClick={() => handleClick(row.pk1, row.sk1)}
                                           // padding="none"
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleClick(row.pk1, row.sk1)}
                                        >{row.CompanyName}</TableCell>
                                        <TableCell
                                            onClick={() => handleClick(row.pk1, row.sk1)}
                                            align="center">{row.CheckIn}</TableCell>
                                        <TableCell
                                            onClick={() => handleClick(row.pk1, row.sk1)}
                                            align="center">{row.CheckOut}</TableCell>
                                        <TableCell
                                            onClick={() => handleClick(row.pk1, row.sk1)}
                                            align="center">{row.DocPic}</TableCell>
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
                
            </Box> 

            
      

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <DrawerBottomCurved
                LoadComponenet={state.LoadComponenet} //"CheckInDetails"
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle={state.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: () => ClosingDrawer(),
                    SREvent: state.SR,
                    DataBack: props.DataBack,
                    SelectedItems: selected,
                    ReportName: "Report",
                    UpdateSREvent: data => SelectedValues(data, "UpdateSREvent"),
                    //UpdateSREventFlag: data => SelectedValues(data, "UpdateSREventFlag")
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="right"
            />
            </>
    );
}
