import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Grid,
    Hidden,
    List,
    Typography,
    Button,
    ListItem,
    Link,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    AlertCircle as AlertCircleIcon,
    Star as StarIcon,
    Globe as globeIcon,
    Aperture as ApertureIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    User as UserIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    Youtube as YoutubeIcon,
    Home as HomeIcon,
    //  trello as trelloIcon,
    Phone as PhoneCall,
    Calendar as CalendarIcon,
    Map as map,
} from 'react-feather';
import NavItem from './NavItem';
import DrawerShow from '../../../utils/DrawerModule';
import { useSelector, useDispatch } from 'react-redux';
//import AutoComplateBox from '../components/AutoComplete';
import {
    ContractorTabs, SmallContractorTabs,
    CompanyTabs, HQTabs,
    SubTabs, OwnerTabs, SmallCompanyTabs
} from '../../../Company/TabsList';
import { Buffer } from 'buffer';
import { NewPageId } from '../../../utils/CommonGraphql';


let NewIdd = NewPageId("nav")


const items = (props) => [
    {
        href: '/app/dashboard',
        icon: BarChartIcon,
        label: 'Home'
    },
    {
        href: `/app/company`,
        icon: BarChartIcon,
        label: `${props.CompanyName} page`
    },
    {
        href: '/app/customers',
        icon: BarChartIcon,
        label: 'Accounts'
    },
    {
        href: '/app/company',
        icon: PhoneCall,
        label: 'Company'
    },
    {
        href: '/app/servicelist',
        icon: PhoneCall,
        label: 'List Service'
    },
    //{
    //  href: '/app/list/Company',
    //  icon: ShoppingBagIcon,
    //  label: 'List Companies'
    //},
    {
        href: '/app/list/Person',
        icon: UserIcon,
        label: 'List Personal'
    },
    //{
    //  href: '/app/list/Team',
    //  icon: UsersIcon,
    //  label: 'List Teams'
    //},
    {
        href: '/app/list/Calendar',
        icon: CalendarIcon,
        label: 'Calendar'
    },
    {
        href: '/app/setup/pref/Pref',
        icon: SettingsIcon,
        label: 'Setup'
    },

    {
        href: '/app/add/Contractor',
        icon: SettingsIcon,
        label: 'Add Contractor'
    },

    //{
    //  href: '/app/setup/pref/Urgency',
    //  icon: PhoneCall,
    //  title: 'Service Actions'
    //},

    //{
    //  href: '/app/setup/pref/Area',
    //  icon: map,
    //  title: 'Area / Territory'
    //},
    /*
    {
      href: '/app/ListResources',
      icon: UserIcon,
      title: 'List Resources'
    },
    {
      href: '/app/account',
      icon: UserIcon,
      title: 'Account'
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: 'Settings'
    },
   
    {
      href: '/login',
      icon: LockIcon,
      title: 'Login'
    },
    {
      href: '/register',
      icon: UserPlusIcon,
      title: 'Register'
    },
    {
      href: '/404',
      icon: AlertCircleIcon,
      title: 'Error'
    }
    */
];

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto'
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
    const classes = useStyles();
    const location = useLocation();
    const [DrawerStatus, OpenDrawer] = useState(false);
    //const [OpenContractor, SetOpenContractor] = useState(false);
    //const [OpenAccounts, SetOpenAccounts] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    // const Global = useSelector(state => state.Global); 
    const WebsiteType = useSelector(state => state.WebsiteType);
    const version = useSelector(state => state.version);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const dispatch = useDispatch()
    // const cid = useSelector(state => state.cid);
    const ShowHelp = useSelector(state => state.ShowHelp);
    let navigate = useNavigate();


    const initialState = {
        //CurrentCompany: "",
        //OpenAutoComplete: false,
        //OpenAccountMenu: false,
        //OpenContractor: false,
        //DrawerComponenet: "NewServiceSteps",
        // NavList: items({ CompanyName: UserData.CompanyName, CompanyId: UserData.CompanyId }),

        ListMenu: [],
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        let linkInfo = "";
        let Dashboard = {
            href: '/app/dashboard',
            icon: HomeIcon,
            label: 'Home',
            key: 'Home'
        };

        let LogOut = {
            href: '/app/dashboard',
            icon: UserIcon,
            label: 'Log Out',
            key: 'LogOut'
        };

        let Branding = {
            label: "Branding",
            LoadComponenet: "BrandingUpdate",
            href: `/app/page/Service`,
            icon: StarIcon,
            key: "Branding",
            HelpBox: {
                ShowHelp: true,
                title: "Branding",
                description: "Change Corporate information and logo",
                ShowVideo: false,
                VideoId: "https://s3.amazonaws.com/bomrng.com/public/videos/System_intro_video_5-10-2023.mp4",
            },
            propss: {
                Category: "Branding",
                PopulatePage: [
                    {
                        LoadComponenet: "BrandingUpdate",
                        Category: "Branding",
                        size: 12,
                        HQId: ContractorData.HQId,
                        ListType: "",
                        NewLoadComponent: "BrandingUpdate",
                        message: "Branding Logo and corporate name change",
                        FormType: "",
                        title: `Branding / Logo / Name`,
                    }
                ]
            }
        };

        let GlobalContractor = {
            label: "Global Customer",
            LoadComponenet: "GlobalCustomerList",
            href: `/app/page/Service`,
            icon: globeIcon,
            key: "Global",
            HelpBox: {
                ShowHelp: true,
                title: "Global Customer",
                description: "Customer that have locations and offices in multiple Branch territories",
                ShowVideo: false,
                VideoId: "https://s3.amazonaws.com/bomrng.com/public/videos/System_intro_video_5-10-2023.mp4",
            },
            propss: {
                Category: "Global",
                PopulatePage: [
                    //{
                    //    LoadComponenet: "GlobalCustomerList",
                    //    Category: "Global",
                    //    //  title: "Branding",
                    //    size: 12,
                    //    HQId: ContractorData.HQId,
                    //    ListType: "",
                    //    NewLoadComponent: "GlobalCustomerList",
                    //    message: "Customer that have locations and offices in multiple Branch territories",
                    //    FormType: "",
                    //    title: `Global Customer`,
                    //},
                    {
                        LoadComponenet: "ToolbarHeading",
                        Category: "Global",
                        title: "Global",
                        size: 12,
                        CompanyId: ContractorData.HQId,
                        NowEditing: "Company",
                        UserEditor: "Contractor",
                        CustomerType: "Contractor",
                        CompanyName: ContractorData.CompanyName
                    },
                    {
                        LoadComponenet: "ListItems",
                        Category: "Global",
                        title: "Global",
                        size: 6,
                        CompanyId: ContractorData.HQId,
                        NowEditing: "Company",
                        UserEditor: "Contractor",
                        CustomerType: "Contractor",
                        CompanyName: ContractorData.CompanyName
                    },
                ]
            }
        };

        let GlobalCompany = {
            label: "Global Accounts",
            LoadComponenet: "GlobalCustomerList",
            href: `/app/page/Service`,
            icon: globeIcon,
            key: "Global",
            HelpBox: {
                ShowHelp: true,
                title: "Global Accounts",
                description: "Customer that have locations and offices in multiple Branch territories",
                ShowVideo: false,
                VideoId: "https://s3.amazonaws.com/bomrng.com/public/videos/System_intro_video_5-10-2023.mp4",
            },
            propss: {
                Category: "Global",
                PopulatePage: [
                    //{
                    //    LoadComponenet: "ToolbarHeading",
                    //    Category: "GlobalAccounts",
                    //    title: "Global",
                    //    size: 12,
                    //    CompanyId: UserData.CompanyId,
                    //    NowEditing: "Company",
                    //    UserEditor: "Company",
                    //    CustomerType: "Company",
                    //    CompanyName: UserData.CompanyName
                    //},
                    {
                        LoadComponenet: "ListItems",
                        Category: "GlobalAccounts",
                        title: "Global",
                        size: 6,
                        CompanyId: UserData.CompanyId,
                        NowEditing: "Company",
                        UserEditor: "Company",
                        CustomerType: "Company",
                        CompanyName: UserData.CompanyName
                    },
                ]
            }
        };

        let Youtube = {
            label: "Support",
            LoadComponenet: "YoutubeEmbed",
            href: `/app/page/Service`,
            icon: YoutubeIcon,
            key: "YoutubeEmbed",
            HelpBox: {
                ShowHelp: false,
                title: "YoutubeEmbed",
                description: "Change Corporate information and logo",
                ShowVideo: false,
                VideoId: "https://s3.amazonaws.com/bomrng.com/public/videos/System_intro_video_5-10-2023.mp4",
            },
            propss: {
                Category: "YoutubeEmbed",
                PopulatePage: [
                    {
                        LoadComponenet: "YoutubeEmbed",
                        Category: "YoutubeEmbed",
                        //  title: "Branding",
                        size: 12,
                        HQId: ContractorData.HQId,
                        ListType: "",
                        NewLoadComponent: "YoutubeEmbed",
                        message: "Support Videos",
                        FormType: "",
                        title: `Support`,
                    }
                ]
            }
        };


        if (UserData && UserData.CustomerType && UserData.email) {
            linkInfo = Buffer.from(`type=${UserData.CustomerType}&email=${UserData.email}`).toString('base64');

        }

        let TapsProps = {
            CompanyId: UserData.CompanyId,
            CustomerType: UserData.CustomerType,
            CompanyName: UserData.CompanyName,
            NowEditing: "",
            UserEditor: UserData.CustomerType,
            Category: "",
            ContractorId: ContractorData.CompanyId,
            ContractorName: ContractorData.CompanyName,
            ContractorType: "Contractor",
            BranchId: UserData.BranchId,
            HQId: UserData.HQId,
            Activefield1: CurrentCompany.field1,
            // ActiveCategory: Category,
            ActiveKey: CurrentCompany.CompanyId,
            ItemData: CurrentCompany,
        }

        let ListMenu = CompanyTabs(TapsProps);
        let tempMenu = [];
        let iitt = 0;

        switch (UserData.CustomerType) {

            case "Contractor":

                if (UserData.Level > 800) {
                    ListMenu = [Dashboard, LogOut];
                    iitt = 1;
                } else {
                    if (WebsiteType === "ContractorCheckIn") {
                        tempMenu = OwnerTabs(TapsProps);
                        iitt = 2;
                    } else {
                        tempMenu = SmallContractorTabs(TapsProps);
                        iitt = 3;
                    }
                    ListMenu = [...tempMenu, Youtube, LogOut];
                }
                break;

            case "Company":

                if (WebsiteType === "ContractorCheckIn") {
                    tempMenu = CompanyTabs(TapsProps);
                    iitt = 4;
                } else {
                    tempMenu = SmallCompanyTabs(TapsProps);
                    iitt = 5;
                }
                ListMenu = [Dashboard, ...tempMenu, LogOut];
               
                break;

            case "Sub":

                tempMenu = SubTabs(TapsProps);
                ListMenu = [Dashboard, ...tempMenu, LogOut];
                iitt = 6;
                break;

            default:
                ListMenu = CompanyTabs(TapsProps);
                iitt = 7;
        }





        setState(prevState => ({
            ...prevState,
            ListMenu: ListMenu,
            linkInfo: linkInfo
        }));

    }, [UserData.CompanyName]);

    //const GetCompanyName = (data) => {
    //  console.log("data", data);

    //  dispatch({ type: 'SET_CID', payload: data });

    //  setState(prevState => ({
    //    ...prevState,
    //    CurrentCompany: data.field1,
    //    OpenAutoComplete: false,
    //    OpenAccountMenu: true
    //  }));

    //  //SetOpenAccounts(false);

    //  //let CurrentCompany = data.field1;

    //  //if (rest.AddCompany) {
    //  //  CurrentCompany = `${state.CurrentCompany} + ${data.field1}`;
    //  //}

    //  //setState(prevState => ({
    //  //  ...prevState,
    //  //  CurrentCompany: CurrentCompany,
    //  //}));

    //  //SetSelectCompany(false);
    //  //rest.AddPersonal(data);

    //}

    const CloseDrawweerr = () => {
        OpenDrawer(false);
    }

    const NewServiceForm = (UrgencyType) => {

        setState(prevState => ({
            ...prevState,
            DrawerComponenet: "NewServiceSteps",
            DetailsProps: {
                UrgencyType: UrgencyType,
                CloseDrawer: () => CloseDrawweerr()
            },
        }));

        OpenDrawer(true);
    }

    const Steptest = (UrgencyType) => {

        setState(prevState => ({
            ...prevState,
            DrawerComponenet: "SelectStepBox",
            DetailsProps: {
                //UrgencyType: UrgencyType,

                CloseDrawer: () => CloseDrawweerr()
            },
        }));

        OpenDrawer(true);
    }


    const DetailLink = () => {

        let propss = {
            key: "UpdateMe",

        }

        navigate(`/app/company/${UserData.CompanyId}`, {
            state: {
                ...UserData,
                ActiveCategory: "Person",
                ActiveKey: UserData.key,
                Activefield1: UserData.field1,
                CompanyId: UserData.CompanyId,
                CompanyInfo: {
                    pk1: UserData.CompanyId
                }
            }
        });
    }

    const ChangeShowHelp = () => {

        dispatch({ type: 'SHOW_HELP', payload: !ShowHelp });

    }

    console.log('Navegation.state', state);
    console.log('Navegation.props', rest);

    const content = (
        <>
            <Box
                height="100%"
                display="flex"
                flexDirection="column"
            >

                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    p={2}
                >
                    <Avatar
                        className={classes.avatar}
                        //  component={RouterLink}
                        src={UserData.avatar}
                    //  to="/app/account"
                    />
                    <Typography
                        className={classes.name}
                        color="textPrimary"
                        variant="h5"
                    >
                        {UserData.field1}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {UserData.field2}
                    </Typography>

                    <Button
                        color="textSecondary"
                        variant="body2"
                        onClick={() => DetailLink()}
                    >
                        Update Me
                    </Button>
                </Box>
                <Divider />

                {/* 
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    p={2}
                >
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch
                                checked={ShowHelp}
                                onChange={() => ChangeShowHelp()}
                            />
                        } label="Show Help" />
                    </FormGroup>
                </Box>
                */}

                <Box p={2}>
                    <List>

                        {state.ListMenu.map((item, indd) => (
                            <>
                                <NavItem
                                    href={item.href}
                                    key={item.key}
                                    id={item.key + NewIdd + indd}
                                    title={item.label}
                                    icon={item.icon}
                                    item={item}
                                    HelpBox={item.HelpBox}
                                />

                            </>
                        ))}

                    </List>
                </Box>

                <Box flexGrow={1} />
            </Box>

            <Box
                height="100%"
                display="flex"
                flexDirection="row"
            >
                <Box sx={{ alignSelf: 'flex-end', p: 1 }}>
                    <Typography variant="caption" display="block" gutterBottom>
                        <a
                            target="_Blank"
                            rel="noreferrer"
                            href={`https://www.contractorcheckin.com/${state.linkInfo}`}
                        >
                            Service Notification Inc (c) 2023
                        </a>
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        {version}
                    </Typography>
                </Box>
            </Box>
        </>
    );





    return <>

        <Box sx={{ display: { xs: 'block', md: 'none' } }} >

            <Drawer
                anchor="left"
                classes={{ paper: classes.mobileDrawer }}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>

        </Box>

        <Box sx={{ display: { xs: 'none', md: 'block' } }} >
            <Drawer
                anchor="left"
                classes={{ paper: classes.desktopDrawer }}
                open
                variant="persistent"
            >
                {content}
            </Drawer>
        </Box>

        <DrawerShow
            LoadComponenet={state.DrawerComponenet}
            propss={state.DetailsProps}
            key="NewServiceSteps"
            DrawerStatus={DrawerStatus}
            OpenDrawer={(e) => OpenDrawer(e)}
            anchor="right"
        />

    </>;
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

export default NavBar;
