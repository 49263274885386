import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Auth } from "aws-amplify";
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
    Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import Logo from '../../components/Logo';

const TopBar = ({
    className,
    onMobileNavOpen,
    ...rest
}) => {

    const dispatch = useDispatch()
    let navigate = useNavigate();

    const MenuHeader = useSelector(state => state.MenuHeader);

    const SignOut = () => {

        Auth.signOut()
            .then(() => {
                console.log("signout success");
                dispatch({ type: 'LOGGED_OUT', payload: false });
                navigate("/login", {});
            })
            .catch((err) => {
                console.log("signout err", err);
            });
    };



    return (
        <AppBar
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
                <Box flexGrow={1} />
                
                <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                    <IconButton color="inherit" onClick={SignOut} size="large">
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ m: 1, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                        >
                            Log Off
                        </Typography>
                    </IconButton>
                    <IconButton color="inherit" onClick={SignOut} size="large">
                        <InputIcon />
                    </IconButton>
            </Box>

                <Box sx={{ display: { xs: 'block', md: 'none' } }} >

                    {/* 
                    <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{ m: 1, flexGrow: 1, display: { xs: 'block', md: 'none' } }}
                        >
                            {MenuHeader}
                        </Typography>
                    </IconButton>
                    */}

                    <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
                        <MenuIcon />
                    </IconButton>

        </Box>

            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
