import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
//import ColumnHeaderAppBar from '../components/ColumnHeaderAppBar';
import { useDispatch as UseDispt } from 'react-redux';
import GlobalToolbarHeading from '../components/GlobalToolbarHeading';
//import SRListWindow from './SRListWindow';
import { NewPageId, Pk1SingelItem } from '../utils/CommonGraphql';
import Skeleton from '../components/Skeleton';
import Pagination from '@mui/material/Pagination';
import ChartEachCompany from '../Charts/ChartEachCompany';
import { useSelector } from 'react-redux';
import {    
    Backdrop, CircularProgress,
} from '@mui/material';


export default function ColumnsGrid(props) {

    const dispatch = UseDispt()
    const items = useLocation();
    let navigate = useNavigate();
    const params = useParams();

    const initialState = {
        SearchWords: "",
        SortTerm: "Latest",
        Data: [],
        PerPageData: [],
        NewPageIdd: "828",
        DoneDone: false
    }
    const [state, setState] = useState(initialState);
    const [noOfPages, SetnoOfPages] = useState(1);
    const [page, setPage] = useState(1);
    const [ItemCount, SetItemCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const [loading, SetLoading] = useState(false);

    useEffect(() => {
        console.log("SRTest.Column.props", props);
        if (props && props.ItemData
            && props.ItemData.Accounts
            && props.ItemData.Accounts.length > 0) {

            setState(prevState => ({
                ...prevState,
                ItemData: props.ItemData,
                Data: props.ItemData.Accounts,
                BranchList: props.ItemData.BranchList,
                NewPageIdd: NewPageId("glb")
            }));

            DataPerPage(props.ItemData.Accounts, "useEffect");
        }

        
        //
    }, []);

    //useEffect(() => {
    //    let timer = "";
    //    if (state.DoneDone) {
    //        SetLoading(true)
    //        timer = setTimeout(() => {
    //            SetLoading(false)

    //            DetailLink();
    //            setState(prevState => ({
    //                ...prevState,
    //                DoneDone: false
    //            }));

    //        }, 3000);
    //    }
    //    return () => clearTimeout(timer);
    //}, [state.DoneDone]);

    //useEffect(() => {
    //    DataPerPage(state.Data, "page")
    //}, [page])

    const PriorityOrder = (Data, Priority) => {

        let PriorityList = ["Low", "Medium", "High"];
        let Levels = [];
        let NewArr = [];
        PriorityList.map(each => {
            Levels = Data.filter(x => x.Priority === each);
            NewArr = [...NewArr, ...Levels]
            Levels = [];
        })

        return NewArr

    }

    const SortObjectArray = (AutoSelectData, find) => {

        // AutoSelectData.sort((a, b) => (a[find].toLowerCase() > b[find].toLowerCase()) ? 1 : ((b[find].toLowerCase() > a[find].toLowerCase()) ? -1 : 0))

        AutoSelectData.sort(function (a, b) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        return AutoSelectData
    };

    const SortTerm = (data) => {

        let NewData = state.Data;
        let TempNewdata = [];
        let update = false;
        //switch (data) {
        //    case "Latest":
        //        NewData = SortObjectArray(state.Data);
        //        update = true;
        //        console.log("SRTest.Column.NewData1", data, NewData);
        //        break;
        //    case "Oldest":
        //        TempNewdata = SortObjectArray(state.Data);
        //        NewData = TempNewdata.reverse();
        //        update = true;
        //        console.log("SRTest.Column.NewData2", data, NewData);
        //        break;
        //    case "PriorityHightoLow":
        //        TempNewdata = PriorityOrder(state.Data);
        //        NewData = TempNewdata.reverse();
        //        update = true;
        //        console.log("SRTest.Column.NewData3", data, NewData);
        //        break;
        //    case "PriorityLowtoHigh":
        //        NewData = PriorityOrder(state.Data);
        //        update = true;
        //        console.log("SRTest.Column.NewData4", data, NewData);
        //        break;
        //}

        if (update) {

            setState(prevState => ({
                ...prevState,
                Data: NewData
            }));

            DataPerPage(NewData, "SortTerm");
        }

    }

    const NarrowSearch = (search) => {

        try {

            let DataBack = [];
            let RawData = state.Data;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.SearchWords && str.SearchWords !== "") {
                        if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                            DataBack.push(str);
                        }
                    }
                })
            } else {
                DataBack = RawData;
            }

            //setState(prevState => ({
            //    ...prevState,
            // //   Data: DataBack,
            //    // NewPageIdd: 
            //}));

            DataPerPage(DataBack, "NarrowSearch");

        } catch (err) {
            console.log("NarrowSearch err", err);
        }
    }

    const DataPerPage = (DataBack, from) => {
        SetItemCount(DataBack.length);
        SetnoOfPages(Math.ceil(DataBack.length / rowsPerPage))
        let PerPageData = DataBack.slice((page - 1) * rowsPerPage, page * rowsPerPage);

        setState(prevState => ({
            ...prevState,
            PerPageData: PerPageData,
            from: from
        }));
    }

    const handleChange = (event, value) => {
        setPage(value);
        DataPerPage(state.Data, "page")
    }

    const UpdateData = (data) => {
        console.log("ProgressArray.data", data);
        if (data && data.Accounts) {
            DataPerPage(data.Accounts, "UpdateData");

            setState(prevState => ({
                ...prevState,
                ItemData: data,
                Data: data.Accounts,
                BranchList: data.BranchList,
                NewPageIdd: NewPageId("glbl2")
            }));

        }
    }

    const DetailLink = (data) => {
        
       // let data = state.DData;
        //data.Category = "GlobalCompany";
        let Path = `/app/company/${data.CompanyId}`;
        navigate(Path, { state: data });

    }

    const Redirect = data => {
        console.log("ProgressArray.Redirect", data);
        //Load Branch
        if (ContractorData.CompanyId !== data.BranchId) {
            LoadData(data.HQId, data.BranchId, 'CONTRACTOR_DATA');
        }

        //Load Current Company
        if (CurrentCompany.CompanyId !== data.CompanyId) {
            LoadData(data.BranchId, data.CompanyId, 'CURRENT_COMPANY');
        } else {
            DetailLink(CurrentCompany);
        }

    }

    const LoadData = async (pk1, sk1, redux) => {
        SetLoading(true)
        let CompanyData = await Pk1SingelItem(pk1, sk1);
        
        if (CompanyData) {

            let dataJSon = JSON.parse(CompanyData.dataJSON)

            dispatch({ type: redux, payload: dataJSon });

            if (redux === 'CURRENT_COMPANY') {
                DetailLink(dataJSon)
            }
        }

        SetLoading(false)
    }

    //  
    console.log("ProgressArray.state", state);
    console.log("ProgressArray.props", props);

    return (
        <div key={state.NewPageIdd}>
            
            <Grid container spacing={1}>

                <Grid item xs={12} >

                    <GlobalToolbarHeading
                        Category="Account"
                        GlobalData={props.ItemData}
                        DataUpdate={e => UpdateData(e)}
                        BranchList={state.BranchList}
                        NarrowSearch={e => NarrowSearch(e)}
                    />

                </Grid>
                    

                    {state.PerPageData && state.PerPageData.length > 0 ? (
                        <>
                        {page > 1 ?(
                            <Grid item xs={12} >

                                <Pagination
                                    // count={noOfPages}
                                    page={page}
                                    onChange={handleChange}
                                    count={noOfPages}
                                    defaultPage={1}
                                    color="primary"
                                    size="small"
                                    showFirstButton
                                    showLastButton

                                />

                            </Grid>
                        ) : null}

                            <Grid item xs={12} >
                            <Grid container spacing={1}>
                            {state.PerPageData && state.PerPageData.map((each, ii) => (
                                <Grid item xs={12} sm={6} key={state.NewPageIdd + ii + "glbl"}>
                                    <ChartEachCompany
                                        key={state.NewPageIdd + ii + "glbl2"}
                                        SR={each}
                                        AllowCompanyPage={true}
                                        CompanyId={each.CompanyId}
                                        CompanyName={each.CompanyName}
                                        BranchName={each.BranchName}
                                        DetailLink={(e) => Redirect(e)}
                                    />
                                </Grid>
                            ))
                            }
                                </Grid>
                            </Grid>

                        {page > 1 ? (
                            <Grid item xs={12} >

                                <Pagination
                                    //  count={noOfPages}
                                    page={page}
                                    onChange={handleChange}
                                    count={noOfPages}
                                    defaultPage={1}
                                    color="primary"
                                    size="small"
                                    showFirstButton
                                    showLastButton

                                />
                            
                            </Grid>
                        ) : null}
                        </>
                    ) : (<Skeleton />)}

                </Grid>
            
            <Backdrop
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
