import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Stack,
    Backdrop, CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import AutoComplateBox from '../components/AutoComplete';
import { NewPageId, UpdateData, removeEmptyStringElements } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';


//const useStyles = makeStyles((theme) => ({
//  root: {},
//  importButton: {
//    marginRight: theme.spacing(1)
//  },
//  exportButton: {
//    marginRight: theme.spacing(1)
//  }
//}));

const Newpageiddd = NewPageId("toolbar")

const Toolbar = (props) => {
    // const classes = useStyles();
    const [SelectCompany, SetSelectCompany] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const [DrawerStatus, OpenDrawer] = useState(false);

    //const [UserData, SetUserData] = useState(UserData);

    const initialState = {
        NewCompany: "",
        CompanyName: "",
        CompanyId: "",
        NewCompanyId: "",
        disabled: true,
        DoneDone: false
    };

    const [state, setState] = useState(initialState);
    const [loading, SetLoading] = useState(false);

    const CloseDrawer = (c) => {
        if (props.CloseDrawerToolBar) {
            props.CloseDrawerToolBar();
        }
        OpenDrawer(false);
    }

    useEffect(() => {

        let CompanyName = "";
        let CompanyId = "";

        if (props.Data
            && props.Data.CompanyId
            && props.Data.CompanyName
            && props.Data.CompanyName !== ""
        ) {
            CompanyName = props.Data.CompanyName
            CompanyId = props.Data.CompanyId
        }

        setState(prevState => ({
            ...prevState,
            CompanyName: CompanyName,
            CompanyId: CompanyId
        }));

        // props.CompanyData(UserData2);

    }, [])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)

                console.log('state.DoneDone2', loading);

                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    const GetCompanyName = (data) => {
        console.log("ChangePersonCompany.data", data.CompanyId, data.CompanyName);

        setState(prevState => ({
            ...prevState,
            NewCompany: data.CompanyName,
            NewCompanyId: data.CompanyId,
            disabled: false
        }));
    }

    const UpdateSREvent = () => {

        let NewDataJson = {
            ...props.Data,
            CompanyName: state.NewCompany,
            CompanyId: state.NewCompanyId,
            Category: "Person",
            gsi2Pk2: state.NewCompanyId,
        }

        var ValueToUpdate = {
            pk1: props.Data.pk1,
            sk1: props.Data.sk1,
            gsi2Pk2: state.NewCompanyId,
            dataJSON: JSON.stringify(removeEmptyStringElements(NewDataJson)),
        };

        console.log("ChangePersonCompany.NewDataJson", NewDataJson)
        console.log("ChangePersonCompany.ValueToUpdate", ValueToUpdate)

        UpdateData(ValueToUpdate, "ChangePersonCompany")
            .then((up) => {
                setState(prevState => ({
                    ...prevState,
                    DoneDone: true
                }))
            })
            .catch((err) => console.log("SRDetails.failed", err));

    }

    const AddItem = (data) => {
        OpenDrawer(true);
    }

    //console.log("Toolbar.props", props);
    console.log("ChangePersonCompany.state", state);
    console.log("ChangePersonCompany.props", props);

    return (
        <div
            // className={clsx(classes.root, className)}
            key={"key" + Newpageiddd}
            id={"id" + Newpageiddd}
        >

            <Grid container spacing={2} >

                <Grid item xs={12} >
                    <Typography variant="h3">
                        Change Person's Company
                    </Typography>
                    <Typography >
                        Please keep in mind that changing the company will not transfer all the persons activities to the new company.
                    </Typography>
                </Grid>

                <Grid item xs={12} >
                    <Typography variant="h4">
                        {`Person: ${props.Activefield1}`}
                    </Typography>

                </Grid>


                <Grid
                    item
                    xs={12}
                >
                    <AutoComplateBox
                        //SaveDetails={props.SaveDetails}
                        //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                        Category="Company"
                        FormType="Company"
                        title="Company"
                        id={Newpageiddd}
                        ShowEditButton={false}
                        CompanyId={ContractorData.ContractorId}
                        ReturnBackData={e => console.log(e)}
                        PreviewEdit={false}
                        EditItem={e => console.log("EditItem", e)}
                        //FormData={each.FormData}
                        onSubmit={(model) => GetCompanyName(model)}
                    />
                </Grid>

                {state.NewCompany !== "" ? (
                    <Grid item xs={12} >
                        <Typography variant="h3">
                            {`Change Company To: ${state.NewCompany}`}
                        </Typography>
                        <Typography color="error">
                            Caution: This may effect notification setups. Please make sure to update all special policies and notifications setup for this person.
                        </Typography>
                    </Grid>
                ) : null}

                <Grid item xs={12} >

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => UpdateSREvent()}
                        disabled={state.disabled}
                    >
                        Confirm Change
                    </Button>

                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
