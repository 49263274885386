import React, { useEffect, useState } from 'react';
//import Radio from '@mui/material/Radio';
//import RadioGroup from '@mui/material/RadioGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
//import FormGroup from '@mui/material/FormGroup';
//import Switch from '@mui/material/Switch';
//import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
import {
    //TextField,
    //FormLabel,
    Checkbox,
    Grid, Button,
    //Backdrop,
    //CircularProgress,
    Typography,
    //Stepper, Step, StepContent, StepLabel,
    //Snackbar,
    FormControl, RadioGroup, FormControlLabel, Radio,
    FormGroup,
    //Switch
    //Card,
    //CardContent,
    //CardActions,
    //InputLabel, Select, FormControl, MenuItem, Checkbox, 
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';
//import { useSelector } from 'react-redux';

//const GetName = (email) => {

//    let newname = email.replace("_", " ")
//    newname = newname.replace(".", " ")
//    newname = newname.replace("-", " ")
//    const cleanedInput = newname.split('@')[0]?.replace(/[^\w]/g, ' ').split(' ');
//    const capitalizedInput = cleanedInput?.map(word => {
//        return word[0]?.toUpperCase() + word.substring(1);
//    }).join(' ');

//    return capitalizedInput
//}


export default function RadioButtonsGroup(props) {
    //const ContractorData = useSelector(state => state.ContractorData);
   // const HQData = useSelector(state => state.HQData);
   // const WebsiteType = useSelector(state => state.WebsiteType);

    const initialState = {
        Email: true,
        Name: false,
        Address: false,
        CustomerType: "Company",
        AddQuestions: []
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }
       // console.log("SelectCustomerType.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

        //let sksk = "all kksin"
        //let emailvvv = GetName(sksk);

        //console.log("SelectCustomerType.emai", emailvvv, sksk)


    }, [props])

    const handleNext = () => {

        let AddQuestions = [
            {
                "key": "email",
                "Category": "email",
                "label": "Please provide your email address",
                "field1": "Please provide your email address",
                "Question": "Please provide your email address",
                "include": false,
                "PreSelect": false,
                "VariableInput": true,
                "keywords": {
                    "words": "",
                    "ESC": []
                },
                "defaultValue": true
            },
            {
                "key": "Name",
                "Category": "Name",
                "label": "Please provide your Name",
                "field1": "Please provide your Name",
                "Question": "Please provide your Name",
                "include": false,
                "PreSelect": false,
                "VariableInput": state.Name,
                "keywords": {
                    "words": "",
                    "ESC": []
                },
                "defaultValue": state.Name
            },
            {
                "key": "Address",
                "Category": "Address",
                "label": "Please provide your Address",
                "field1": "Please provide your Address",
                "Question": "Please provide your Address",
                "include": false,
                "PreSelect": false,
                "VariableInput": state.Address,
                "keywords": {
                    "words": "",
                    "ESC": []
                },
                "defaultValue": state.Address
            }
        ]

        let SelectedDataReturn = {
            ...state,
            AddQuestions: AddQuestions,
            id: props.id,
            KeyId: props.KeyId
        }

        console.log("SelectCustomerType.add", SelectedDataReturn)

        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const SetValues = (data, cat) => {

        // let newdata = data.target.value;

        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));


    }

    console.log("SelectCustomerType.state", state)
    console.log("SelectCustomerType.props", props)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography gutterBottom>
                    When a new customer uses the system for the first time, the system will create a new account. If the Corporate Customer option is selected below, the system will create a new company in the system with email domain and street address.  All personnel with the same address and domain will be added to the same company account. 
                </Typography>
            </Grid>

                <Grid item xs={12}>
                <FormControl>                    
                    <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        name="CustomerType"
                        defaultValue={state.CustomerType}
                        value={state.CustomerType}
                        onChange={(data) => SetValues(data.target.value, "CustomerType")}
                    >
                        <FormControlLabel value="Company" control={<Radio />} label="Corporate Customer" />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography gutterBottom>
                                    For larger Commercial customers. The system will ask for company name, email, phone, address, etc.
                                </Typography>
                            </Grid>
                        </Grid>
                        <FormControlLabel value="Individual" control={<Radio />} label="Individual Customer" />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography gutterBottom>
                                    For residential or individual customers. Please select the check boxes below for the information that should be collected from customer.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>

                                <FormGroup>
                                    <FormControlLabel disabled control={<Checkbox onChange={(e) => SetValues(e.target.checked, "Email")} defaultChecked />} label="Email (required to avoid nuisance calls)" />
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={state.Name}
                                            onChange={(e) => SetValues(e.target.checked, "Name")} />} label="Persons Name" />
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={state.Address}
                                        onChange={(e) => SetValues(e.target.checked, "Address")} />} label="Address" />
                                </FormGroup>

                            </Grid>
                        </Grid>

                    </RadioGroup>
                </FormControl>                            
            </Grid>
            
            <Grid item xs={12}>
                <div>
                    <div>
                        <Button
                            disabled={props.activeStep === 0}
                            onClick={() => props.handleBack()}
                        // className={classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext()}
                        //  className={classes.button}
                        >
                            {'Next'}
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>

    );
}
