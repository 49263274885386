import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
//import ColumnHeaderAppBar from '../components/ColumnHeaderAppBar';
import AppBarforColumns from '../components/AppBarforColumns';
//import SRListWindow from './SRListWindow';
import { NewPageId } from '../utils/CommonGraphql';
import Skeleton from '../components/Skeleton';
import Pagination from '@mui/material/Pagination';
import SRBox from './SRBox'


export default function ColumnsGrid(props) {

    const initialState = {
        SearchWords: "",
        SortTerm: "Latest",
        Data: [],
        PerPageData: [],
        NewPageIdd: "828"
    }
    const [state, setState] = useState(initialState);
    const [noOfPages, SetnoOfPages] = useState(1);
    const [page, setPage] = useState(1);
    const [ItemCount, SetItemCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        console.log("SRTest.Column.props", props);
        if (props && props.Data) {

            setState(prevState => ({
                ...prevState,
                Data: props.Data,
                NewPageIdd: NewPageId("cloo")
            }));          

        }

        DataPerPage(props.Data, "useEffect");
        //
    }, [props.Data]);

    //useEffect(() => {
    //    DataPerPage(state.Data, "page")
    //}, [page])

    const PriorityOrder = (Data, Priority) => {

        let PriorityList = ["Low", "Medium", "High"];
        let Levels = [];
        let NewArr = [];
        PriorityList.map(each => {
            Levels = Data.filter(x => x.Priority === each);
            NewArr = [...NewArr, ...Levels]
            Levels = [];
        })

        return NewArr

    }

    const SortObjectArray = (AutoSelectData, find) => {

       // AutoSelectData.sort((a, b) => (a[find].toLowerCase() > b[find].toLowerCase()) ? 1 : ((b[find].toLowerCase() > a[find].toLowerCase()) ? -1 : 0))

        AutoSelectData.sort(function (a, b) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        return AutoSelectData
    };

    const SortTerm = (data) => {

        let NewData = state.Data;
        let TempNewdata = [];
        let update = false;
        switch (data) {
            case "Latest":
                NewData = SortObjectArray(state.Data);
                update = true;
                console.log("SRTest.Column.NewData1", data, NewData);
                break;
            case "Oldest":
                TempNewdata = SortObjectArray(state.Data);
                NewData = TempNewdata.reverse();
                update = true;
                console.log("SRTest.Column.NewData2", data, NewData);
                break;
            case "PriorityHightoLow":
                TempNewdata = PriorityOrder(state.Data);
                NewData = TempNewdata.reverse();
                update = true;
                console.log("SRTest.Column.NewData3", data, NewData);
                break;
            case "PriorityLowtoHigh":
                NewData = PriorityOrder(state.Data);  
                update = true;
                console.log("SRTest.Column.NewData4", data, NewData);
                break;
        }

        if (update) {

            setState(prevState => ({
                ...prevState,
                Data: NewData
            }));

            DataPerPage(NewData, "SortTerm");
        }

    }

    const NarrowSearch = (search) => {

        try {

            let DataBack = [];
            let RawData = props.Data;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.postTEXT) {
                        if (str.postTEXT.toLowerCase().includes(search.toLowerCase())) {
                            DataBack.push(str);
                        }
                    }
                })
            } else {
                DataBack = RawData;
            }

            setState(prevState => ({
                ...prevState,
                Data: DataBack,
               // NewPageIdd: 
            }));

            DataPerPage(DataBack, "NarrowSearch");

        } catch (err) {
            console.log("NarrowSearch err", err);
        }
    }

    const DataPerPage = (DataBack, from) => {
        SetItemCount(DataBack.length);
        SetnoOfPages(Math.ceil(DataBack.length / rowsPerPage))
        let PerPageData = DataBack.slice((page - 1) * rowsPerPage, page * rowsPerPage);

        setState(prevState => ({
            ...prevState,
            PerPageData: PerPageData,
            from: from
        }));
    }

    const handleChange = (event, value) => {
        setPage(value);
        DataPerPage(state.Data, "page")
    }

   // 
    console.log("SRTest.Column.state", props.idd, state);

    return (
        <div key={state.NewPageIdd + props.idd}>
        <Box sx={{ flexGrow: 1 }} key={state.NewPageIdd + "box"}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <AppBarforColumns
                        SearchValue={ss => NarrowSearch(ss)}
                        SortTerm={ss => SortTerm(ss)}
                        ColumnTitle={props.ColumnTitle}
                    />
                </Grid>
                
                {state.PerPageData && state.PerPageData.length > 0 ? (
                    <>

                        <Grid item xs={12} >

                            <Pagination
                                // count={noOfPages}
                                page={page}
                                onChange={handleChange}
                                count={noOfPages}
                                defaultPage={1}
                                color="primary"
                                size="small"
                                showFirstButton
                                showLastButton

                            />

                        </Grid>


                        {state.PerPageData && state.PerPageData.map((each, ii) => (
                            <Grid item xs={12} >
                                <SRBox
                                   // key={`checkbox-list-label-${ii}`}
                                    SR={each}
                                    key={state.NewPageIdd + props.idd + "src"}
                                />
                            </Grid>
                        ))
                        }


                        <Grid item xs={12} >

                            <Pagination
                                //  count={noOfPages}
                                page={page}
                                onChange={handleChange}
                                count={noOfPages}
                                defaultPage={1}
                                color="primary"
                                size="small"
                                showFirstButton
                                showLastButton

                            />

                        </Grid>

                    </>
                ) : (<Skeleton />)}
               
            </Grid>

            </Box>
            </div>
    );
}
