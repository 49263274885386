import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
//import * as serviceWorker from './serviceWorker';
import App from './App';
import RootReducer from './RootReducer'
import ContractorSignup from './MainWebsite/ContractorSignUpWiz';
import OwnerSignUpWiz from './MainWebsite/OwnerSignUpWiz';

const store = createStore(RootReducer);
const container = document.getElementById('root');
const root = createRoot(container); 

const WebDirection = () => {
    switch (window.WhereAreWe) {
        case "signupbox":
            return <ContractorSignup />
        case "OwnerSignin":
            return <OwnerSignUpWiz />
        default:
            return <App />
    }
}

root.render(
    <Provider store={store}>
        <BrowserRouter>
                {WebDirection()}
        </BrowserRouter>
    </Provider>
);

//serviceWorker.unregister();
