import React from "react";
import { InlineWidget } from "react-calendly";

const App = (props) => {
    console.log("Calendry Props", props)
    return (
        <div className="App">
            <InlineWidget
                url="https://calendly.com/servicewithtext/30min"
                prefill={props.prefill}
                styles={{
                    height: '1000px',
                    width: '600px'
                }}
            />
        </div>
    );
};

export default App;