import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
//import moment from 'moment';
import {
    Card, CardContent,
    FormControl, InputLabel, Select, MenuItem,
    Switch,
    Button,
    TextField, Stack,
    Typography,
    Grid,
    CardHeader,
} from '@mui/material';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';
import AutoComplateBox from '../components/AutoComplete';
import Amplify, { API, graphqlOperation } from "aws-amplify";
//import { useDispatch } from 'react-redux';
import Page from '../components/Page';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CloudUpload from '@mui/icons-material/CloudUpload';
//import { currentTime } from '../utils/DateTime';
import UploadMain from '../Upload/UploadMain';
import { createBatch, createSvcbotDb } from '../graphql/mutations';
//import { batchGet } from "../graphql/queries";
import { DocumentType } from '../utils/Documents';
import DrawerShow from '../utils/DrawerModule';





const Profile = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    //const [Requestor, SetRequestor] = useState(false);
   // const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    //const [SelectCompany, SetSelectCompany] = useState(false);
    //const [DrawerStatus, OpenDrawer] = useState(false);
    //const [AddAssetList, SetAddAssetList] = useState(false);
    //const [Upload, SetUpload] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        AssetList: [],
        CompanyName: "",
        CompanyId: ""
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        if (rest.SREvent && rest.SREvent.posterId) {
            setState(prevState => ({
                ...prevState,
                ContractorId: rest.SREvent.ContractorData.CompanyId,
                ContractorName: rest.SREvent.ContractorData.CompanyName
            }));
        }

    }, [rest.SREvent])

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const SaveData = () => {

        try {

            console.log('Addasset.state.AssetList', state.AssetList);

            let InputArray = [];
            let Location = "";
            let NewFormJSON = {};
            let EachDB = {};
            let event = rest.SREvent;
            let avatar = "";

            var dateToday = new Date();
            let currentTime = dateToday.toISOString();

            let ServiceRequestTime = new Date(event.UTCTime).toLocaleString();

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            let Mini = {
               // posterId: event.posterId,
               // post: event.postTEXT,  
                title: event.title,
                field1: event.posterId.field1,
                field2: event.title,
                ServiceRequest: event.ServiceRequest,
                PhotoService: event.PhotoService,
                Documents: event.Documents,
                date: currentTime,
                PostId: event.PostId,
                currentTime: event.currentTime,
                Category: rest.Category,
                posterId: posterId
            };

            if (state.AssetList.length > 0) {
                state.AssetList.map((each, indd) => {

                    if (each.Location) {
                        Location = each.Location;
                    } else {
                        Location = "";
                    }

                    if (each.avatar) {
                        avatar = each.avatar;
                    } else {
                        avatar = "";
                    }
                   
                    NewFormJSON = {
                        ...Mini,
                        field1: each.field1,
                        field2: `Work Date: ${ServiceRequestTime}`,
                        Location: Location,
                        AssetPkSk: {
                            pk1: each.pk1,
                            sk1: each.sk1
                        },
                        avatar: avatar,
                        SearchWords: `${each.field1} - ${event.title} - ${event.postTEXT}`,
                        pk1: `Post::${each.key}`,
                        sk1: event.ServiceRequest.sk1,
                        gsi1Pk2: `Post::${each.Category}::${event.posterId.CompanyId}`,
                        gsi1Sk2: event.ServiceRequest.sk1,
                        gsi2Pk2: `Post::${each.Category}::${event.ContractorData.CompanyId}`,
                        gsi2Sk2: event.ServiceRequest.sk1,
                        key: `Post::${rest.Category}::${each.key}::${event.ServiceRequest.sk1}`
                    }

                  //  console.log("ContactList.NewFormJSON", NewFormJSON);

                    //if (rest.Category === "Location") {

                    //    EachDB = {
                    //        pk1: `Post::${event.PostId}`,
                    //        sk1: `Post::${rest.Category}::${event.ServiceRequest.sk1}::${indd}`,
                    //        gsi1Pk2: `Post::${each.key}`,
                    //        gsi1Sk2: event.ServiceRequest.sk1,
                    //        gsi2Pk2: `Post::Location::${event.ContractorData.ContractorId}`,
                    //        gsi2Sk2: event.ServiceRequest.sk1,
                    //        dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                    //    };

                    //} else {

                   //     EachDB = {
                   //         pk1: `Post::${event.PostId}`,
                   //         sk1: `Post::${rest.Category}::${event.ServiceRequest.sk1}::${indd}`,
                   //         gsi1Pk2: `Post::${each.key}`,
                   //         gsi1Sk2: event.ServiceRequest.sk1,
                   //         gsi2Pk2: `Post::${rest.Category}::${event.posterId.CompanyId}`,
                   //         gsi2Sk2: event.ServiceRequest.sk1,
                   //         dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                   //     };
                   //// }

                   // InputArray.push(EachDB);

                    EachDB = {
                        pk1: `Post::${each.key}`,
                        sk1: event.ServiceRequest.sk1,
                        gsi1Pk2: `Post::Assets::${event.posterId.CompanyId}`,
                        gsi1Sk2: event.ServiceRequest.sk1,
                        gsi2Pk2: `Post::Assets::${event.ContractorData.CompanyId}`,
                        gsi2Sk2: event.ServiceRequest.sk1,
                        dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                    };


                    InputArray.push(EachDB);

                    NewFormJSON.sk1 = `Post::Assets::${event.ServiceRequest.sk1}`;
                    NewFormJSON.pk1 = `Post::${event.PostId}`;

                    EachDB = {

                        pk1: `Post::${event.PostId}`,
                        sk1: `Post::Assets::${event.ServiceRequest.sk1}`,
                        //gsi1Pk2: `CheckIn::${event.posterId.sk1}`,
                        //gsi1Sk2: event.ServiceRequest.sk1,
                        //gsi2Pk2: `Post::Person::${event.posterId.CompanyId}`,
                        //gsi2Sk2: event.ServiceRequest.sk1,
                        dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))

                    };

                    InputArray.push(EachDB);

                    console.log('Addasset.InputArray', InputArray);
                    //InputArray.push({
                    //    pk1: `Post::${event.PostId}`,
                    //    sk1: `Post::${rest.Category}::${each.key}::${event.ServiceRequest.sk1}`,
                    //    gsi1Pk2: each.key,
                    //    gsi1Sk2: `Post::${rest.Category}::${event.ServiceRequest.sk1}`,
                    //    gsi2Pk2: event.ContractorData.ContractorId,
                    //    gsi2Sk2: `Post::${rest.Category}::${event.ServiceRequest.sk1}`,
                    //    dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                    //})

                    Location = "";
                })
            }

            let TriggerSubs = {
                pk1: `Post::${event.PostId}`,
                sk1: `Trigger::Subscription`
                }

            if (InputArray.length > 0) {
                API.graphql(graphqlOperation(createBatch, { input: InputArray }))
                    .then((results) => {

                        API.graphql(graphqlOperation(createSvcbotDb, { input: TriggerSubs }))

                        if (rest.TriggerUpdate) {
                            dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                        }

                        if (rest.UpdateAsset) {
                            rest.UpdateAsset(true)
                        }

                        if (rest.CloseDrawer) {
                            rest.CloseDrawer();
                        }

                    })
            }

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    console.log("AddMessage.rest", rest)
    console.log("AddMessage.state", state)

    return (
        <Page key={state.NewId}>
            <Grid container spacing={2}>

                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title={`Select ${rest.Category}`}
                        />

                        <CardContent>
                            
                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "AssetList")}
                                CompanyId={state.ContractorId}
                                CompanyName={state.ContractorName}
                                title={`Select ${rest.Category}`}
                                Category={rest.Category}
                                AssetList={state.AssetList}
                            />

                        </CardContent>


                    </Card>
                </Grid>
                <Grid xs={12} item>
                    <Button color="primary"
                        variant="contained"
                        onClick={() => SaveData()}>
                        {`Save List`}
                    </Button>
                </Grid>
            </Grid>

            {/*
             * <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.NewCategory}
                DrawerTitle={state.DrawerTitle}
                propss={{
                    Category: state.NewCategory,
                    CompanyId: rest.CompanyId,
                    CompanyName: rest.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e),
                    // UploadFiles: state.UploadFiles,
                    id: state.DrawerKey,
                    // AddItem: () => AddItem("ItemAddPage", "Person")
                }}
                key={state.DrawerKey}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
            */}
        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
