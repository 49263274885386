import React, { useState, useEffect } from 'react';
import { Box, Container, Backdrop, CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import Toolbar from '../Setup/SetupToolbar';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, getAutoCompletedata } from '../utils/CommonGraphql';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    productCard: {
        height: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const ListItems = (props) => {

    let query = useQuery();
    console.log("query", query);

    const classes = useStyles();
    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData);
    const cid = useSelector(state => state.cid);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 6,
        SmallBoxWidth: 4,
        LargeBoxWidth: 8,
        //CompanyInfo: cid,
        //LoadComponenet: "ListModule",
        LoadComponenet: "CompanyPrefSetup",
        CompanyId: UserData.CompanyId,
        CompanyName: UserData.CompanyName,
        pk1: UserData.CompanyId,
        propss: {},
        key: NewPageId("win"),
    }
    const [state, setState] = useState(initialState);

    //useEffect(() => {
    //  let LoadComponenet = "ServiceActionList";

    //  if (params.Category) {

    //    switch (params.Category) {
    //      case "Urgency":
    //        LoadComponenet = "ServiceActionList";
    //        break;
    //      case "Area":
    //        LoadComponenet = "ServiceActionList";
    //        break;
    //      case "ESC":
    //        LoadComponenet = "ServiceActionList";
    //        break;
    //      default:
    //        LoadComponenet = "ServiceActionList";
    //    }

    //    setState(prevState => ({
    //      ...prevState,
    //      LoadComponenet: LoadComponenet,
    //    }));
    //  }
    //}, [params.Category])
    //propss = {
    //  DataBack, RawData, 
    //}
    //title, SearchWords, AddBox, disabled, ShowUpDown

    useEffect(() => {

        console.log('SetupPage.params', params);
        console.log('SetupPage.props', props);

        let Category = "Company";
        let DetailComponent = "Details"
        let LoadComponenet = "CompanyPrefSetup";
        let CompanyId = ContractorData.ContractorId;
        let CompanyName = ContractorData.ContractorName;
        let CustomerType = "Company";
        let pk1 = cid.CompanyId;

        if (params.Category) {
            Category = params.Category;
        }

        if (props.Category) {
            Category = props.Category;
        }

        //if (Category) {
        //  switch (Category) {
        //    case "Urgency":
        //      LoadComponenet = "ServiceActionList";
        //      break;
        //    case "Area":
        //      LoadComponenet = "ServiceActionList";
        //      break;
        //    case "ESC":
        //      LoadComponenet = "ServiceActionList";
        //      break;
        //    case "Pref":
        //      LoadComponenet = "CompanyPrefSetup";
        //      break;
        //    default:
        //      LoadComponenet = "CompanyPrefSetup";
        //  }
        //}

        if (props.CompanyId) {
            CompanyId = props.CompanyId;
            CompanyName = props.CompanyName;
            CustomerType = props.CustomerType;
        }

        //// why do I have this????????????????????
        /*
        if (UserData.CustomerType === "Contractor" && Category === "Company") {
          pk1 = ContractorData.ContractorId;
          setState(prevState => ({
            ...prevState,
            pk1: ContractorData.ContractorId,
          }));
        }
        */

        const UUid = NewPageId(Category);
        setState(prevState => ({
            ...prevState,
            Category: Category,
            UUid: UUid,
            //DetailComponent: DetailComponent,
            LoadComponenet: LoadComponenet,
            CompanyId: CompanyId,
            CompanyName: CompanyName,
            CustomerType: CustomerType,
            CompanyInfo: {
                CompanyId: CompanyId,
                CompanyName: CompanyName,
                CustomerType: CustomerType,
                field1: CompanyName,
                pk1: CompanyId
            }
        }));

        // GetDatafromDB(Category, pk1);

    }, [props.CompanyId])

    //const GetDatafromDB = async (Category, pk1) => {
    //  SetLoading(true);

    //  let ContractorInfo = [];
    //  if (UserData.CustomerType === "Contractor") {
    //    ContractorInfo = ContractorData;
    //  }

    //  const DataString = {
    //    Category: Category,
    //    FindArray: [
    //      {
    //        ArrayName: "DataBack",
    //        DuplicateArray: "RawData",
    //        ArraySearchString: ":",
    //      }
    //    ]
    //  };

    //  let NewData = await getAutoCompletedata(DataString, pk1);
    //  setState(prevState => ({ ...prevState, propss: { ContractorInfo, ...NewData } }))

    //  SetLoading(false);
    //  console.log("NewData", NewData);
    //}


    // Additional Information Box
    const AddBox = (data) => {
        console.log("setup.AddBox", data);
        let NewKey = NewPageId("list");

        let DetailsProps = {
            Data: data,
            CompanyId: state.CompanyId,
            ActiveCategory: props.ActiveCategory,
            Activefield1: props.Activefield1,
            ActiveKey: props.ActiveKey,
            CompanyName: state.CompanyName,
            key: data.key || NewKey,
        }

        if (matches) {
            setState(prevState => ({
                ...prevState,
                DetailComponent: data.LoadComponenet,
                DetailsProps: DetailsProps,
                key: data.key || NewKey,
                ShowDetails: true,
            }))

        } else {
            //open Drawer
            setState(prevState => ({
                ...prevState,
                DetailComponent: data.LoadComponenet,
                DetailsProps: DetailsProps,
                ShowDetails: false,
                key: data.key || NewKey
            }))

            OpenDrawer(true);
        }
    }

    //const CompanyData = (data) => {
    //  console.log("data", data);
    //  GetDatafromDB(state.Category, data.pk1);
    //}

    const AddItem = (data) => {
        setState(prevState => ({
            ...prevState,
            DetailComponent: "ItemAddPage",
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = () => {
        let NewKey = NewPageId("list");

        setState(prevState => ({
            ...prevState,
            key: NewKey,
            ShowDetails: false,
        }))

        OpenDrawer(false);
    }
    console.log('SetupPage.state', state);
    return (
        <Box mt={3}>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    key="10101010"
                    lg={state.SmallBoxWidth}
                    //md={6}
                    xs={12}
                >

                    <RenderModule
                        AddBox={(e) => AddBox(e)}
                        //SearchWords={state.SearchWords}
                        //title={state.Category}
                        CompanyInfo={state.CompanyInfo}
                        CompanyId={state.CompanyId}
                        CompanyName={state.CompanyName}
                        ActiveCategory={props.ActiveCategory}
                        Activefield1={props.Activefield1}
                        ActiveKey={props.ActiveKey}
                        CustomerType={state.CustomerType}
                        disabled={false}
                        LoadComponenet={state.LoadComponenet}
                        ItemData={props.ItemData}
                        propss={state.propss}
                    />
                </Grid>

                {state.ShowDetails && (
                    <Grid
                        item
                        key={state.key + "10101010ddss"}
                        lg={state.LargeBoxWidth}
                        //lg={6}
                        //md={6}
                        xs={12}
                    >
                        <RenderModule
                            key={state.key}
                            CloseDrawer={() => CloseDrawer()}
                            CompanyId={state.CompanyId}
                            ActiveCategory={props.ActiveCategory}
                            Activefield1={props.Activefield1}
                            ActiveKey={props.ActiveKey}
                            CompanyName={state.CompanyName}
                            LoadComponenet={state.DetailComponent}
                            {...state.DetailsProps}
                        />
                    </Grid>
                )}
            </Grid>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={props.Category}
                CompanyId={state.CompanyId}
                CompanyName={state.CompanyName}
                propss={state.DetailsProps}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </Box>
    );
};

export default ListItems;
