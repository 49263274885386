import React, { useState, useEffect } from 'react';
import {
    NewPageId,
    MiddlePostSearch,
    ContreactorPostSearch
} from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import {
    Grid,
    Backdrop, Box,
    CircularProgress,
    Card, CardHeader, Button, CardContent
} from '@mui/material';
import ListItems from '../List/ListItems';
import ListModule from '../List/ListModule';
import ShowDetailsText from '../Add/ShowDetailsText';
import { adjustedTime, TimeStampEndofDay, formatDate } from '../utils/DateTime';
import DrawerShow from '../utils/DrawerModule';
import NarrowDatesToolBar from '../components/NarrowDatesToolBar';
import { useNavigate } from 'react-router-dom';
import ChartOnly from '../Charts/ChartOnly';

const UUid = NewPageId("shwdtl");
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = adjustedTime(1, "End");

export default function SimpleCard(props) {
    let navigate = useNavigate();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const NowEditing = useSelector(state => state.NowEditing)
    const WebsiteType = useSelector(state => state.WebsiteType);
    const UserData = useSelector(state => state.UserData);
    const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        DataBack: [],
        id: UUid,
        StartDate: olddate,
        EndDate: Todaydatestring,
        BarData: [],
        BarNames: []
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("SRFrequency.props", props)

        GetDatafromNarrow(olddate, Todaydatestring);

        let LocalStart = formatDate(olddate);
        let LocalEnd = formatDate(Todaydatestring);

        setState(prevState => ({
            ...prevState,
            ShowDates: `${LocalStart} to ${LocalEnd}`
        }));


    }, [props.CompanyId])


    const GetDatafromNarrow = async (startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let ShowString = {};
        let Frequency = [];
        let tempmarker = "";
        let FindingSameLocation = -1;
        let BarData = [];
        let BarNames = [];

        ////////////////////////////

        if (startDate == null || startDate === "") {
            startDate = state.StartDate
        }

        if (endDate == null || endDate === "") {
            endDate = state.EndDate
        }

        let UTCdate = new Date(endDate).toISOString();
        let StartDate = new Date(startDate).toISOString();

        if (WebsiteType === "ContractorCheckIn") {

            NewData = await ContreactorPostSearch(`Parts::${ContractorData.CompanyId}`, StartDate, UTCdate);

        } else {

            if (NowEditing === "Contractor") {
                NewData = await ContreactorPostSearch(`Parts::${ContractorData.CompanyId}`, StartDate, UTCdate);
            }

            if (NowEditing === "Company") {
                NewData = await MiddlePostSearch(`Parts::${props.CompanyId}`, StartDate, UTCdate);
            }

        }

        console.log("SRFrequency.NewData", NewData);

        if (NewData && NewData.length > 0) {
            NewData.map((each, indd) => {

                ShowString = JSON.parse(each.dataJSON);
             //   console.log("SRFrequency.ShowString", ShowString);

                if (ShowString.PartNumber) {

                    FindingSameLocation = Frequency.findIndex(x => x.PartNumber === ShowString.PartNumber)

                  //  console.log("SRFrequency.find.each", FindingSameLocation, each.PartNumber, Frequency);

                    if (FindingSameLocation > -1) {
                        //add to count

                        // console.log(`SRFrequency.find ${Frequency[FindingSameLocation].gsi1Pk2} === ${each.gsi1Pk2}`);

                        tempmarker = Frequency[FindingSameLocation];
                       // tempmarker.Count = tempmarker.Count + Number(ShowString.Quantity);
                        tempmarker.Count = tempmarker.Count + 1;
                        tempmarker.field2 = `Orders: ${tempmarker.Count}`;
                        Frequency[FindingSameLocation] = tempmarker;

                        console.log("SRFrequency.tempmarker", tempmarker);

                    } else {

                        console.log("SRFrequency.ShowString", ShowString);
                                                
                        Frequency.push({
                            ...ShowString,
                            gsi1Pk2: ShowString.gsi1Pk2,
                         //   field1: `${ShowString.PartNumber} (qty ${ShowString.Quantity})`,
                            field2: `Order: 1`,
                            Count: 1,
                            //field2: `Count: ${ShowString.Quantity}`,
                            //Count: Number(ShowString.Quantity)
                        })
                    }

                }
            })
        }

        //sort list
        Frequency.sort((a, b) => (a.Count > b.Count) ? -1 : ((b.Count > a.Count) ? 1 : 0));

        //create chart
        for (var i = 0; i <= 4; i++) {
            if (Frequency[i] && Frequency[i].Count > 0) {
                BarData.push(Frequency[i].Count)
                BarNames.push(Frequency[i].field1)
            }
        }

        setState(prevState => ({
            ...prevState,
            DataBack: Frequency,
            BarData: BarData,
            BarNames: BarNames
        }))

        SetLoading(false);
        console.log("SRFrequency.NewData2", Frequency);
    }

    //const AddBox = (add) => {
    //    console.log("DocumentPage.AddBox", add);
    //    setState(prevState => ({
    //        ...prevState,
    //        DrawerTitle: "Service History",
    //        // SetupSubscription: true,
    //        DetailComponent: "SRDetailsPkSk",
    //        ServiceData: add,
    //        DrawerKey: NewPageId("DrawerKey"),
    //        NewCategory: "ShowDetails",
    //    }))

    //    SetDeawerOpen(true);
    //    //}
    //}



    const SortPref = (StartDate, EndDate) => {
        console.log("AssetId, startDate, endDate2", state.AssetId, StartDate, EndDate);
        if (StartDate !== state.StartDate || EndDate !== state.EndDate) {

            let NewStartDate = TimeStampEndofDay(StartDate, "Start");
            let NewEndDate = TimeStampEndofDay(EndDate, "End");

            GetDatafromNarrow(NewStartDate, NewEndDate);

            let LocalStart = formatDate(NewStartDate);
            let LocalEnd = formatDate(NewEndDate);

            setState(prevState => ({
                ...prevState,
                StartDate: NewStartDate,
                EndDate: NewEndDate,
                ShowDates: `${LocalStart} to ${LocalEnd}`
            }));
        }

    }

    const CloseDrawer = () => {
        //console.log("CloseDrawer", e)
        SetDeawerOpen(false)
    }

    //const DetailLink = item => {
    //    let Path = `/app/company/${item.CompanyId}`;
    //    navigate(Path, { state: item });
    //}

    const AddBox = (add, DetailComponent) => {

        let NewKey = NewPageId("list");
        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            Category: state.Category,
            key: add.key || NewKey,
            ServiceData: add,
            ShowDetails: false,
            DetailsProps: {
                title: add.field1,
                Category: state.Category,
                ShowEditButton: false,
                PreviewEdit: false,
                Data: add
            },
        }))

        SetDeawerOpen(true);
    }

    const DetailLink = (item, DetailComponent = "") => {

        console.log("SRFrequency.item", item)

        //let NewDetailComponent = "";
        //if (DetailComponent !== "") {
        //    NewDetailComponent = DetailComponent;
        //} else {
        //    NewDetailComponent = state.DetailComponent;
        //}

        // Use Datalink to change the page
        if (props.DetailLink) {
            AddBox(item, "CompanyPage");
        } else {
            let Path = `/app/company/${item.CompanyId}`;
            navigate(Path, { state: item });
        }
    }


    console.log("SRFrequency.state", state)

    return (
        <>

            <Card>
                <CardHeader
                    title={`Frequency by ${props.Category}`}
                    subheader={state.ShowDates}
                />
                <CardContent>
                    <Grid container spacing={2} >

                        {state.BarData && state.BarData.length > 0 && (
                            <Grid item xs={12}>
                                <ChartOnly
                                    BarData={state.BarData}
                                    Category="Assets"
                                    BarNames={state.BarNames}
                                    title={`Number of ${props.Category} Orders`}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <NarrowDatesToolBar
                                SortPref={(StartDate, EndDate) => SortPref(StartDate, EndDate)}
                                StartDate={state.StartDate}
                                EndDate={state.EndDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ListModule
                                DataBack={state.DataBack}
                                SearchWords={props.SearchWords}
                                title="Frequency"
                                disabled={false}
                                DetailLink={(e) => DetailLink(e)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: state.Category,
                    CloseDrawer: (e) => CloseDrawer(),
                    Data: state.ServiceData,
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </>
    );
}
