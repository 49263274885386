import React, { useState, useEffect } from 'react';
import {
    Card, CardHeader, CardContent,
    Box, Button,
    Backdrop, CircularProgress, Grid, TextField, Snackbar, Typography
} from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DrawerShow from '../utils/DrawerModule';
import {
    NewPageId,
    getAutoCompletedata,
    removeEmptyStringElements,
    UpdateData
} from '../utils/CommonGraphql';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';
//import ListModule from '../List/ListModule';
//import { currentTime } from '../utils/DateTime';

var dateToday = new Date();
let currentTime = dateToday.toISOString().replace("Z", "");

const ListItems = (props) => {
    const dispatch = useDispatch();
    //let query = useQuery();
    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const NowEditing = useSelector(state => state.NowEditing)
    //if window is mobile or regular
    // const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 6,
        AllowAdd: true,
        ListType: "ListModule",
        CompanyId: UserData.CompanyId,
        ContractorData: [],
        CompanyData: [],
        propss: {},
        key: NewPageId("win"),
        SMSJson: [],
        Category: "AddSMS",
        Activefield1: props.CompanyName,
        defaultValue: 0,
        DeActivePolicies: [],
        CompanyPref: [],
        SelectedArray: [],
        status: "Active",
        IntroVideo: "",
        IntroVideoList: [],
        VideoName: "",
        OpenAlert: false,
        AlarmMessage: "",
        SelectedSMS: [],
        IsDev: false,
        UUid: NewPageId("SMSCompanySelect")

        //NewLoadComponent: "UrgencyDetails"
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('SMSSetup.props', props);
        //get list of all active SMS numbers in use for the branch
        GetContractorSMS("AddSMS", ContractorData.ContractorId, "Data");


    }, [])

    //useEffect(() => {

    //    KeyArray(state.SelectedSMS);

    //}, [state.SelectedSMS])


    // getting list of SMS's
    const GetContractorSMS = async (Category, CompanyId, ArrayName, Asset = "", SMS = "") => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId, Asset, SMS);
        setState(prevState => ({ ...prevState, ...NewData }))
        SetLoading(false);
        // console.log("UrgencyDetails.NewData", NewData);

        console.log("SMSCompanySelect.NewData.SMSs - NewData", NewData);

    }

    const CloseDrawer = () => {
        GetContractorSMS("AddSMS", ContractorData.ContractorId, "Data");
        //get deactive policies
       // GetDatafromDB("SMSCompany", props.CompanyId, "DeActivePolicies", "status", "Disabled");
        OpenDrawer(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };
   
    const SetValues = (e) => {

        setState(prevState => ({
            ...prevState,
            SelectedSMS: e,
        }));

        let finditem = {};
        let Selectedlist = [];

        e.map(each => {
            finditem = state.Data.find(x => x.key === each);
            Selectedlist.push(finditem.mobile)
        })

        props.SMSList(Selectedlist);

    }

  

    console.log("InviteSMSList.state", state);

    return (
        <div key={state.UUid}>
            <Grid container spacing={2}>

                {state.Data && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title="Select SMS"
                                //subheader={props.Activefield1}
                            />
                            <CardContent>
                                <ListCheckboxMoveUpDown
                                    Data={state.Data}
                                    title="SMS List"
                                    key="SMS"
                                    ShowUpDown={false}
                                    ShowEdit={false}
                                    From="SMSCompanySelect - List SMS"
                                    // AddBox={(e) => props.AddBox(e)}
                                    DefaultChecked={state.SelectedSMS}
                                    // NextLoadComponenet={props.NextLoadComponenet}
                                    Category="SelectSMS"
                                    SelectedDataReturn={(e) => SetValues(e)}
                                    PerPage={10}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )}


            </Grid>

            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={() => CloseDrawer()}
                Category="AddSMS"
                propss={state.DetailsProps}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

        </div>
    );
};

export default ListItems;
