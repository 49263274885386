import React, { useEffect, useState } from 'react';
import {
    TextField,
    Link,
    Typography,
    //Paper,
    Grid,
    Button,
    Backdrop,
    CircularProgress,
    Card,
    Avatar,
    IconButton,
    //FormGroup,
    CardContent,
    CardActions,
    CardHeader,
    Stack,
    //InputLabel, FormControl, MenuItem, Checkbox, 
    //RadioGroup,
    //FormControlLabel,
    //Radio, IconButton, CardHeader,
    Switch,
    //Tooltip, Divider
} from '@mui/material';
import { NewPageId, MiddleCategories } from '../utils/CommonGraphql';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { red } from '@mui/material/colors';
import { Buffer } from 'buffer';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import WebhookIcon from '@mui/icons-material/Webhook';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

function ClipboardCopy(props) {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(props.copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            <Card>
                <CardHeader
                    title={props.title}
                />
                <CardContent>

                    <Typography sx={{ color: red[500] }} gutterBottom component="div">
                        {props.copyText}
                    </Typography>

                </CardContent>
                <CardActions>
                    <Button
                        onClick={handleCopyClick}
                        startIcon={<ContentCopyIcon />}
                    >
                        {isCopied ? `Copied!` : `Click here to copy`}
                    </Button>
                </CardActions>

            </Card>
        </div>
    );
}

export default function RadioButtonsGroup(props) {
    const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Zap: [],
        History: []
    }
    const [state, setState] = useState(initialState);

    const CompanyIdHex = Buffer.from(ContractorData.CompanyId).toString('base64');

    useEffect(() => {
        GetHistory()
    }, [ContractorData.CompanyId])

    const GetHistory = async () => {
        try {

            SetLoading(true);

            let Zap = [];
            let dataJSON = {};
            let ZapList = await MiddleCategories(ContractorData.CompanyId, "Zapier::")

            //if (ZapList.lenth > 0) {
            ZapList.map(each => {
                if (each.gsi1Sk2.includes("Subscribe")) {
                    dataJSON = JSON.parse(each.dataJSON);
                    Zap.push(dataJSON);
                }
            })
            // }

            setState(prevState => ({
                ...prevState,
                Zap: Zap
            }))

            console.log("ShowHistory.ZapList", ZapList, Zap);
            SetLoading(false);

        } catch (err) { }
    }

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Instructions"
                        />
                        <CardContent>
                            <Typography>
                                The data integration is through Zapier. Zapier allows connection with thousands of apps. You will need the API key listed below and your email address.  The email must be in the system.
                            </Typography>
                            <br />
                            <Typography>
                                To remove a data connection, use Manage Connection button below and turn off the Zap from the list.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Stack
                                direction={{ xs: 'column', lg: 'row' }}
                                spacing={2}>
                                <Button
                                    href="https://zapier.com/developer/public-invite/206346/6c3297bdecd89ae43a2e3e44f0ce5a41/"
                                    target="_blank"
                                    startIcon={<WebhookIcon />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Zapier
                                </Button>
                                <Button
                                    href={`https://www.${window.WebsiteType}.com/api.html`}
                                    target="_blank"
                                    startIcon={<CloseFullscreenIcon />}
                                    variant="contained"
                                    color="primary"
                                >
                                    API Documentation
                                </Button>
                                <Button
                                    href="https://zapier.com/app/zaps"
                                    target="_blank"
                                    startIcon={<FormatListBulletedIcon />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Manage Connections
                                </Button>
                            </Stack>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <ClipboardCopy
                        copyText={CompanyIdHex}
                        title="API Key"
                        key="apikey"
                    />
                </Grid>

                {state.Zap.length > 0 ? (
                    <>
                    <Grid item xs={12}>
                            <Typography variant="h3" gutterBottom>
                                Current Connections
                            </Typography>
                    </Grid>

                    {
                    state.Zap.map((each, indd) => (
                        <Grid item xs={12} key={`zapp-${indd}`}>
                            <Card key={`zappcard-${indd}`}>
                                <CardHeader
                                    title={each.field1}

                                    avatar={
                                        <Avatar >
                                            <LeakAddIcon />
                                        </Avatar>
                                    }
                                />

                            </Card>
                        </Grid>
                    ))
                        }
                    </>
                ) : null}
            </Grid>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
