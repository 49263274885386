import React, { useState, useEffect } from 'react';
import {
    Card, CardHeader, CardContent,
    Button, Grid
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, UpdateData, removeEmptyStringElements } from '../utils/CommonGraphql';
import ListModule from '../List/ListModule';

const ListItems = (props) => {

    const dispatch = useDispatch();
    const AccountAccess = useSelector(state => state.AccountAccess);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const initialState = {
        AccountsList: [],
        defaultAccountsList: [],
        AccountDB: [],
        Refresh: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        let AccountsList = [];
        let defaultAccountsList = [];
        let AccountDB = [];

        if (AccountAccess && AccountAccess.AccountsList) {
            AccountsList = AccountAccess.AccountsList;
        }
        if (AccountAccess && AccountAccess.defaultAccountsList) {
            defaultAccountsList = AccountAccess.defaultAccountsList;
        }
        if (AccountAccess && AccountAccess.AccountsList) {
            AccountDB = AccountAccess.AccountDB;
        }

        setState(prevState => ({
            ...prevState,
            AccountsList: AccountsList,
            defaultAccountsList: defaultAccountsList,
            AccountDB: AccountDB
        }));

    }, []);

    useEffect(() => {

        if (state.Refresh) {

            UpdateDatabase();

            setState(prevState => ({
                ...prevState,
                Refresh: false
            }));
        }

    }, [state.Refresh]);

    const UpdateDatabase = () => {

        let data = {
            ...AccountAccess,
            AccountsList: state.AccountsList,
            AccountDB: state.AccountDB,
            defaultAccountsList: state.defaultAccountsList
        };

        var ValueToUpdate = {
            pk1: AccountAccess.pk1,
            sk1: AccountAccess.sk1,
            dataJSON: JSON.stringify(removeEmptyStringElements(data)),
        };

        dispatch({ type: 'ACCOUNT_ACCESS', payload: data });

       // dispatch({ type: 'USER_DATA', payload: data });

        UpdateData(ValueToUpdate, "95-ListSelectedAccounts")
            .then((up) => {
                console.log("SRDetails.Update.results", up)
                // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
            })
            .catch((err) => console.log("SRDetails.failed", err));

    }


    const AddNew = () => {

        setState(prevState => ({
            ...prevState,
            NewKey: NewPageId("smsset")
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = () => {
        OpenDrawer(false);        
    }


    const handleChange = (data) => {
        setState({
            ...state,
            ...data,
        });
    };

    console.log("ListSelectedAccounts.state", state);
    console.log("ListSelectedAccounts.props", props);

    return (
        <>
            <Grid container spacing={2}>



                <Grid item xs={12}>
                    <Card
                        key={"smscardd"}
                    >
                        <CardHeader
                            title="Selected Accounts"
                            action={UserData.Level > 500 ? (
                                <Button
                                    onClick={() => AddNew()}
                                    color="primary"
                                    variant="contained"
                                >
                                    Select Accounts
                                </Button>
                            ) : null
                            }
                        />
                        <CardContent>
                            <ListModule
                                title="Selected Accounts"
                                SearchWords=""
                                ShowEdit={false}
                                disabled={false}
                                DetailLink={false}
                                DataBack={state.AccountsList}
                            />

                        </CardContent>
                    </Card>
                </Grid>

            </Grid>


            <DrawerShow
                LoadComponenet="SelectCompanies"
                DrawerKey={state.NewKey + "10103k"}
                DrawerTitle="Accounts List"
                key={state.NewKey + "sksal"}
                propss={{
                    Category: state.Category,
                    key: state.NewKey,
                    SelectedDataReturn: (e) => handleChange(e),
                    defaultAccountsList: state.defaultAccountsList
                }}
                DrawerStatus={DrawerStatus}
                CloseDrawer={(e) => CloseDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </>
    );
};

export default ListItems;
