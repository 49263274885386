import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import {
    Card, Grid, Button,Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar, FormControlLabel, Switch, TextField,
    Backdrop, 
    CircularProgress,
} from '@mui/material';
import readXlsxFile from 'read-excel-file';
import { removeEmptyStringElements, NewPageId, isNotEmptyObject } from '../utils/CommonGraphql';
import { createBatch } from '../graphql/mutations';
import { FormArray } from '../Forms/FormList';
//import Loadable from 'react-loadable';
import validator from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import { SendEmail } from '../utils/SendEmail';
import DynamicForms from '../Forms/FormShow';

//const DynamicForms = Loadable({
//    loader: () => import('../Forms/FormShow'),
//    loading() {
//        return <div>Loading...</div>
//    }
//});

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}

let ValidateEmail = (email) => {

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        return false
    } else {
        return true
    }
}

function idXLS(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'xls':
        case 'xlsx':
        case 'cvs':
            return true;
    }
    return false;
}

const Uploadtest = (props) => {
    const [ExistingEmails, setExistingEmails] = useState([]);
    const [ReadyData, setReadyData] = useState([]);
    const [RejectData, setRejectData] = useState([]);
    const [Privilages, setPrivilages] = useState("Select");
    const [FinalData, setFinalData] = useState([]);
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const UserData = useSelector(state => state.UserData);
    const HQData = useSelector(state => state.HQData);
    const [loading, SetLoading] = useState(false);
    //const [itemid, setitemid] = useState(props.itemid);

    const [activeStep, setActiveStep] = useState(0);
    let initialState = {
        ColumnList: [],
        CommonList: [],
        RejectedData: [],
        NewTotalList: [],
        CountryCode: "US",
        DoneDone: false,
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("UploadExcel.props---", props)

        GetFormData();

    }, [props.Category])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    const GetFormData = () => {

        let TotalForm = [];
        let CommonList = [];
        let ColumnList = [];
        if (props.Category) {
            TotalForm = FormArray(props.Category + "Bulk", NewPageId("blk"));

            ColumnList = TotalForm[0];
            CommonList = TotalForm.slice(1, TotalForm.length);

            setState(prevState => ({
                ...prevState,
                ColumnList: ColumnList.FormData,
                ColumnForm: ColumnList,
                CommonList: CommonList,
                TotalForm: TotalForm,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                Category: props.Category
            }));

        }
    }

    const onChangeHandler = event => {


        let NewColumnList = state.ColumnList;
        let NewKey = NewPageId("Nkey");
        let ColumnForm = state.ColumnForm;
        let NewDataEntry = {};
        let NewTotal = [];
        let NewTotalList = [];
        let DBdataTotal = [];
        let eachroww = 0;
        let eachcolummmm = 0;
        let NewRow = [];

        const fileUploaded = event.target.files[0];

        if (!idXLS(fileUploaded.name)) {
            alert('Please select Excel file');
            return
        }

        readXlsxFile(fileUploaded).then((rows) => {
            // console.log('row', rows)
            console.log("UploadExcel.rows---", rows)
            rows.map((eachRow, indddd) => {
                NewRow = ProcessEachColumn(eachRow, NewColumnList, NewKey + indddd);

                NewDataEntry = {
                    ...ColumnForm,
                    FormData: NewRow,
                    Category: props.Category
                }

                NewTotal = [NewDataEntry, ...state.CommonList];
                DBdataTotal = CreateDBData(NewTotal, indddd);
                NewTotalList.push(DBdataTotal);
            })

            setState(prevState => ({
                ...prevState,
                NewTotalList: NewTotalList,
            }));

            

        })
    }

    const SendDatatoDB = () => {
        console.log("UploadExcel.SendDatatoDB", state.NewTotalList)
        if (state.NewTotalList && state.NewTotalList.length > 0) {
            BatchLimitPush(state.NewTotalList);
        }
    }

    const ProcessEachColumn = (eachRow, columns, keyy) => {
        let NewValueObj = [];
        var AcceptEmail = true;
        let AcceptPhone = true;
        let RejectedData = state.RejectedData;
        let PhoneUpdated = "";
        let PhoneNumber = "";
        let NewValuee = "";
        let NewColun = {};
        let NewRow = [];

        // console.log("UploadExcel.NewValueObj.101", eachRow)
        columns.map((eachColumn, indd) => {
            NewValueObj = eachColumn;

            switch (eachColumn.type) {
                case "email":
                    if (eachRow[indd] && validator.isEmail(eachRow[indd])) {
                        NewValuee = eachRow[indd];
                    } else {
                        AcceptEmail = false
                    }
                    break;
                case "phone":
                    if (eachRow[indd] && validator.isMobilePhone(eachRow[indd])) {
                        PhoneNumber = phoneUtil.parseAndKeepRawInput(eachRow[indd], state.CountryCode);
                        PhoneUpdated = phoneUtil.format(PhoneNumber, PNF.E164);
                        NewValuee = PhoneUpdated;
                    } else {
                        AcceptPhone = false;
                    }
                    break;
                default:
                    NewValuee = eachRow[indd];
            }

            if (AcceptPhone && AcceptEmail) {

                NewColun = {
                    ...NewValueObj,
                    VariableInput: NewValuee,
                    ShowValue: NewValuee,
                    defaultValue: NewValuee,
                    key: keyy + indd
                }

                NewRow.push(NewColun);

            } else {
                RejectedData.push(eachRow)
            }
        })

        setState(prevState => ({
            ...prevState,
            RejectedData: RejectedData,
        }));

        return NewRow
    }

    const Selecteddata = (data) => {
        console.log("data seleted", data)
        setFinalData(data);
    }

    ////////////////////////////////////////////
    const CreateDBData = (FormDataBack, indddd) => {


        try {
            console.log("ItemAddPage.fix.Start", FormDataBack);

            let AllItemForms = FormDataBack;
            // let FormExtra = FormDataBack.FormExtra;

            let Field1 = "";
            let Field2 = "";
            let SearchWords = "";
            let MissingInfoFlag = false;
            let dataJSON = "";
            let FormdataJSON = {};
            let Invitation = {};
            let SendEmailInvite = false;
            let SendSMSInvite = false;
            let AllowSMSService = true;
            let PhotoService = [];
            let Documents = [];
            let AlarmMessage = "";
            let DBInput = {};
            let DBInput2 = {};
            let Location = "";
            let Phone = "";
            let Email = "";
            let InputArray = [];
            let Address = "";
            let CompanyName = "";
            let FormDD = {};
            let avatar = "";
            let Mobile = "";
            let key = `Data::Company::Asset::${state.Category}::${state.UUid}${indddd}`;
            let status = "Active";
            let Cat = "";
            let Privileges = { value: 100, label: "Field Resource" };
            let Level = 100;
            let BranchType = "";
            let TimeZone = "";
            let ContractorId = ContractorData.ContractorId;
            let CompanyId = state.CompanyId;
            let BranchId = ContractorData.BranchId;
            let BranchName = ContractorData.BranchName;
            let HQId = ContractorData.HQId;
            let SubDomain = "";
            let HQName = ContractorData.HQName;
            let postHTML = "<table>";
            let SMStype = "";
            let postTEXT = "";
            let SelectedSMS = [];
            let SelectedVideo = [];
            let mediaUrl = [];
            let ContactCardFileFullPath = "";
            let CustomerType = "Company";
            let introVideo = {};
            let AuthorityLevelFound = false;

            if (state.CustomerType) {
                CustomerType = state.CustomerType;
            }

            if (props.UpdatedataJSON && props.UpdatedataJSON.CustomerType) {
                CustomerType = props.UpdatedataJSON.CustomerType;
            }

            postHTML += `<tr align="left"><th ></th><th >Information</th></tr>`;

            if (state.ImagePic && state.ImagePic.length > 0) {
                PhotoService.push(state.ImagePic)
            }

            //const key = `Data::Company::${Cat}#${state.UUid}`;
            var currentUnixTime = new Date();
            var currentTime = currentUnixTime.toISOString();

            //   let posterId = User;



            //make sure cid and companyid exist
            if (state.CompanyId !== "") {
                //console.log('CIDDDDDD', cid)
                CompanyId = state.CompanyId;
                CompanyName = state.CompanyName;
            } else {
                MissingInfoFlag = true;
                AlarmMessage += `* Please select Company `;
            }

            // put each form data in db
            if (AllItemForms && AllItemForms.length > 0) {
                AllItemForms.map((EachForm, index) => {

                    console.log('ItemAddPage.fix.EachForm', EachForm);


                    //if (!EachForm.ValueAdded && !FormDataBack.UpdateExisting) {
                    //    MissingInfoFlag = true;
                    //    AlarmMessage += `* Please complete ${EachForm.FormName} `;
                    //}

                    if (EachForm.Location && isNotEmptyObject(EachForm.Location)) {
                        console.log('ItemAddPage-Location1', EachForm.Location)
                        Location = EachForm.Location;
                    }

                    if (EachForm && EachForm.FormData && EachForm.FormData.length > 0) {

                        if (EachForm.Category && EachForm.Category !== "") {
                            console.log('ItemAddPage.Base', EachForm.Category)
                            SearchWords += `${EachForm.Category}: `;
                        }

                        EachForm.FormData.map((each, ind) => {
                            console.log('ItemAddPage.fix-each', each)
                            if (each.required) {
                                if (each.VariableInput === "") {
                                    //trigger alarm message if input value missing
                                    MissingInfoFlag = true;
                                    AlarmMessage += `* ${each.label} `;
                                }
                            }

                            //if (each.required || each.Searchable) {
                            //    if (each.VariableInput && each.VariableInput !== "") {
                            //        if (Object.keys(each.VariableInput).length === 0) {
                            //            SearchWords += `${each.VariableInput}, `;
                            //        }
                            //    }
                            //} 

                            if (each.ShowValue) {
                                SearchWords += `${each.ShowValue}, `;
                            }

                            //console.log('each.Category', state.Category, EachForm.Category)
                            if (state.Category === EachForm.Category) {
                                if (each.extra === "field1") {
                                    Field1 = each.ShowValue;
                                }
                                if (each.extra === "field2") {
                                    Field2 = each.ShowValue;
                                }
                                AllItemForms[index].key = key;
                                AllItemForms[index].sk1 = key;
                            }

                            if (each.VariableName === "Address" && isNotEmptyObject(each.Details)) {
                                console.log('ItemAddPage-Address1', each.Details)
                                Location = each.Details;
                                Address = each.Details.address;
                                // console.log('ItemAddPage-Location2', EachForm.Location)
                            }

                            //if (each.Category === "Location" && isNotEmptyObject(each.Details)) {
                            //    console.log('ItemAddPage-Location', each.Details)
                            //    Location = each.Details;
                            //    Address = each.Details.address;
                            //    //console.log('ItemAddPage-Location2', EachForm.Location)
                            //}

                            if (isNotEmptyObject(each.Location)) {
                                // console.log('ItemAddPage-Location3', each.Location)
                                Location = each.Location;
                            }

                            //New SMS public or dedicated
                            if (each.VariableName === "public") {
                                SMStype = each.VariableInput;
                            }


                            //TimeZone
                            if (each.VariableName === "TimeZone") {
                                TimeZone = each.Details;
                                //Invitation.Mobile = each.VariableInput;
                            }

                            //allow or denay SMS
                            if (each.VariableName === "AllowSMSService") {
                                AllowSMSService = each.VariableInput;
                                //Invitation.Mobile = each.VariableInput;
                            }

                            //allow or denay SMS
                            if (each.VariableName === "AuthorityLevel") {
                                if (each.Details && each.Details.value) {
                                    Privileges = each.Details;
                                    Level = Privileges.value;
                                    AuthorityLevelFound = true;
                                    //Invitation.Mobile = each.VariableInput;
                                }
                            }

                            if (each.VariableName === "status") {
                                // status = each.VariableInput;
                                if (each.VariableInput === true) {
                                    status = "Active";
                                } else {
                                    status = "Disabled";
                                }
                            }

                            if (each.VariableName === "mobile") {
                                Mobile = each.VariableInput;
                                Invitation.Mobile = each.VariableInput;
                            }
                            if (each.VariableName === "phone") {
                                Phone = each.VariableInput;
                                Invitation.Phone = each.VariableInput;
                            }

                            if (each.VariableName === "CompanyName") {
                                CompanyName = each.VariableInput;
                            }

                            if (each.VariableName === "email") {
                                Email = each.VariableInput.toLowerCase();
                                Invitation.Email = each.VariableInput.toLowerCase();

                                if (state.Category === "Person") {
                                    key = `Data::Company::Resource::Person::${WebsiteType}::${Email}`;
                                }

                            }
                            // }

                            if (state.Category === "Company"
                                || state.Category === "Contractor"
                                || state.Category === "Branch"
                            ) {
                                if (each.VariableName === "name") {
                                    CompanyName = each.VariableInput;
                                }
                                // CompanyId = key;
                            }

                            if (each.extra === "Invite") {
                                // console.log('ItemAddPage.Invite.each', each)
                                Invitation[each.VariableName] = each.VariableInput;
                                //SendInvite
                                if (each.VariableName === "Invite") {
                                    switch (each.VariableInput) {
                                        case "email":
                                            SendEmailInvite = true;
                                            SendSMSInvite = false;
                                            break;
                                        case "SMS":
                                            SendSMSInvite = true;
                                            SendEmailInvite = false;
                                            break;
                                        case "no":
                                            SendEmailInvite = false;
                                            SendSMSInvite = false;
                                            break;
                                        //default:
                                        //  SendEmailInvite = false;
                                        //  SendSMSInvite = false;
                                    }
                                }
                            }

                            if (each.PhotoService && each.PhotoService.length > 0) {
                                PhotoService = [...PhotoService, ...each.PhotoService];
                            }

                            if (each.Documents && each.Documents.length > 0) {
                                Documents = [...Documents, ...each.Documents];
                            }

                            if (each.VariableInput && Object.keys(each.VariableInput).length === 0) {
                                postHTML += `<tr align="left"><th >${each.VariableName}</th><th >${each.VariableInput}</th></tr>`;
                                postTEXT += `${each.VariableName}: ${each.VariableInput} \n`;
                            }

                        })
                    }
                })

                //if (FormExtra && FormExtra.length > 0) {
                //    FormExtra.map(each => {
                //        if (each && each.SearchWords) {
                //            SearchWords += `-- ${each.SearchWords}`;
                //        }

                //        if (each && isNotEmptyObject(each.Location)) {
                //            console.log('ItemAddPage-Address3', each.Location)
                //            Location = each.Location;
                //        }
                //    })
                //}

                //  console.log('ItemAddPage-SearchWords', SearchWords, state.Category)

                if (state.ImagePic && state.ImagePic.ImageFullPath) {
                    avatar = state.ImagePic.ImageFullPath;
                }

                switch (state.Category) {
                    case "Person":
                        //Cat = "Resource::Person";
                        key = `Data::Company::Resource::Person::${WebsiteType}::${Email}`;
                        break;
                    case "Teams":
                        //Cat = "Resource::Teams";
                        key = `Data::Company::Resource::Teams::${currentTime}${indddd}`;
                        break;
                    case "Location":
                        //Cat = "Resource::Teams";
                        key = `Data::Company::Asset::Location::${currentTime}${indddd}`;
                        break;
                    case "Equipment":
                        //Cat = "Resource::Teams";
                        key = `Data::Company::Asset::Equipment::${currentTime}${indddd}`;
                        break;
                    case "Area":
                        //Cat = "Resource::Teams";
                        key = `Data::Online::Lambda::Area::${currentTime}${indddd}`;
                    case "Branch":
                        //Cat = "Resource::Teams";
                        key = `Data::Corp::Branch::Info::${currentTime}${indddd}`;
                        break;
                    case "Company":
                        //Cat = "Info";
                        key = `Data::Company::Info::${currentTime}${indddd}`;
                        CustomerType = "Company";
                        CompanyId = key;
                        break;
                    default:
                        //Cat = `Asset::${state.Category}`;
                        key = `Data::Company::Asset::${state.Category}::${currentTime}${indddd}`;
                }

                //update key
                if (FormDataBack.UpdateExisting) {
                    key = state.sk1Update;

                    //when updating Admin who signed up
                    if (!AuthorityLevelFound
                        && props.UpdatedataJSON.Privileges
                        && props.UpdatedataJSON.Level) {
                        Privileges = props.UpdatedataJSON.Privileges;
                        Level = props.UpdatedataJSON.Level;
                    }

                }

                switch (state.Category) {
                    case "Person":
                        //Cat = "Resource::Person";
                        //   key = `Data::Company::Resource::Person::${Email}`;

                        if (FormDataBack.UpdateExisting) {
                            status = status;
                        } else {
                            status = "Waiting";
                        }

                        if (props.UpdatedataJSON
                            && props.UpdatedataJSON.status === "Waiting") {
                            status = "Waiting";
                        }

                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: BranchId,
                            gsi1Sk2: `phone::${WebsiteType}::${Mobile}`,
                            gsi2Pk2: HQId,
                            gsi2Sk2: `email::${Email}`
                        }
                        break;
                    case "Teams":
                        //Cat = "Resource::Teams";
                        //   key = `Data::Company::Resource::Teams::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;


                    case "AddSMS":
                        //  Field2 = `${Field2} - ${Mobile}`;
                        avatar = ContractorData.avatar;
                        SubDomain = ContractorData.SubDomain;
                        // ContactCardFileFullPath = await CreateContactCard(Field1, Field2, Mobile)
                        DBInput = {
                            pk1: `phone::${Mobile}`,
                            sk1: BranchId,
                            gsi1Pk2: HQId,
                            gsi1Sk2: `phone::${SMStype}::${Mobile}`,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: `phone::${SMStype}::${Mobile}`
                        }
                        break;

                    case "Location":
                        //Cat = "Resource::Teams";
                        //   key = `Data::Company::Asset::Location::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Equipment":
                        //Cat = "Resource::Teams";
                        //    key = `Data::Company::Asset::Equipment::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Area":
                        //Cat = "Resource::Teams";
                        //  key = `Data::Online::Lambda::Area::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                    case "Branch":
                        //Cat = "Resource::Teams";
                        //  key = `Data::Corp::Branch::Info::${currentTime}`;
                        //if (ContractorData.avatar
                        //    && ContractorData.avatar !== ""
                        //    && avatar === ""
                        //) {
                        //    avatar = ContractorData.avatar;
                        //}
                        BranchId = key;
                        ContractorId = key;
                        CompanyId = key;
                        BranchType = "Branch";
                        CustomerType = "Contractor";
                        BranchName = Field1;
                        SubDomain = HQData.SubDomain;
                        Field2 = Address;
                        DBInput = {
                            pk1: HQId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: HQId,
                            gsi2Pk2: HQId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Company":
                        //Cat = "Info";
                        //   key = `Data::Company::Info::${currentTime}`;
                        //CustomerType = "Company";
                        //CompanyId = key;
                        DBInput = {
                            pk1: BranchId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: BranchId,
                            gsi2Pk2: HQId,
                            gsi2Sk2: key
                        }
                        break;
                    default:
                        //Cat = `Asset::${state.Category}`;
                        //   key = `Data::Company::Asset::${state.Category}::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                }


                // console.log('ItemAddPage.fix.FormExtra', state.FormExtra, state.Category)

                //   console.log('ItemAddPage.Invite.SendSMSInvite', SendSMSInvite, SendEmailInvite)

                // postHTML += `<tr align="left"><th >ID</th><th >${key}</th></tr>`;
                postHTML += "</table>";

                FormdataJSON = {
                    AllItemForms: AllItemForms,
                    // FormExtra: FormExtra,
                    BranchId: BranchId,
                    BranchName: BranchName,
                    SMStype: SMStype,
                    BranchType: BranchType,
                    HQId: HQId,
                    HQName: HQName,
                    SubDomain: SubDomain,
                    status: status,
                    SearchWords: SearchWords,
                    AllowSMSService: AllowSMSService,
                    ContactCardFileFullPath: ContactCardFileFullPath,
                    Category: state.Category,
                    CustomerType: CustomerType,
                    TimeZone: TimeZone,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    Location: Location,
                    Address: Address,
                    avatar: avatar,
                    id: state.UUid,
                    key: key,
                    Privileges: Privileges,
                    Level: Level,
                    field1: Field1,
                    field2: Field2,
                    ContractorId: ContractorId,
                    date: currentTime,
                    posterId: {
                        pk1: UserData.pk1,
                        sk1: UserData.sk1,
                        field1: UserData.filed1,
                        field2: UserData.filed2,
                        avatar: UserData.avatar,
                        email: UserData.email,
                        mobile: UserData.mobile,
                        phone: UserData.phone,
                    },
                    PhotoService: PhotoService,
                    Documents: Documents,
                    phone: Phone,
                    mobile: Mobile,
                    email: Email,

                }

                console.log('ItemAddPage.fix-DB-FormdataJSON', FormdataJSON)
                //adding new SMS for contractor
                //if (state.Category === "AddSMS") {
                //    FormdataJSON = {
                //        ...FormdataJSON,
                //        pk1: `phone::${Mobile}`,
                //        sk1: ContractorId,
                //        gsi2Pk2: ContractorId,
                //        gsi2Sk2: `phone::${Mobile}`,
                //    }
                //}

                // console.log('ItemAddPage-FormdataJSON', FormdataJSON)

                // dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))


                FormDD = {
                    // AllItemForms: FormData,
                    ExtraInfo: true,
                    SearchWords: SearchWords,
                    Category: state.Category,
                    FormType: "AutoComplate",
                    Location: Location,
                    field1: Field1,
                    field2: Field2,
                    pk1: CompanyId,
                    sk1: key,
                }

                //console.log('FormDD', FormDD, props.id, props.key)

            }

            //if alerts
            if (MissingInfoFlag) {

                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: AlarmMessage,
                    OpenAlert: true
                }));

            } else {

                //let NewDBinput = {
                //    ...DBInput,
                //    dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
                //}



                ////InputArray.push(NewDBinput);

                //console.log('ItemAddPage-DB-props.UpdatedataJSON', props.UpdatedataJSON)
                //console.log('ItemAddPage-DB-FormdataJSON', FormdataJSON)
                //console.log('ItemAddPage-DB-NewDBinput', NewDBinput)


                //image resize lambda
                if (PhotoService.length > 0) {
                    API.post("ImageResize", "/send", {
                        body: { Records: PhotoService }
                    });
                }

                if (SendSMSInvite) {

                    var SMSstring = {};
                    let SMSLoop = [
                        {
                            Body: `${Invitation.message}. Use following link to setup your system password and access https://${HQData.SubDomain}`,
                            FromSMS: Invitation.Mobile,
                            To: [Invitation.Mobile],
                            mediaUrl: [],
                        }
                    ]

                    if (CustomerType === "Company") {
                        if (CurrentCompany
                            && CurrentCompany.SelectedSMSArray
                            && CurrentCompany.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = CurrentCompany.SelectedSMSArray;
                        } else if (ContractorData
                            && ContractorData.SelectedSMSArray
                            && ContractorData.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = ContractorData.SelectedSMSArray;
                        }

                        if (CurrentCompany
                            && CurrentCompany.SelectedVideo
                            && CurrentCompany.SelectedVideo.length > 0
                        ) {
                            SelectedVideo = CurrentCompany.SelectedVideo;
                        } else if (ContractorData
                            && ContractorData.SelectedVideo
                            && ContractorData.SelectedVideo.length > 0
                        ) {
                            SelectedVideo = ContractorData.SelectedVideo;
                        }



                        if (SelectedVideo.length > 0) {

                            SMSLoop.push({
                                Body: "Please see the following introductory video(s)",
                                mediaUrl: [],
                            })

                            SelectedVideo.map(each => {
                                SMSLoop.push({
                                    Body: each,
                                    mediaUrl: [],
                                })
                            })
                        }

                        if (SelectedSMS && SelectedSMS.length > 0) {

                            SMSLoop.push({
                                Body: "Please save each contact in your mobile device. Use the number to request service.",
                                mediaUrl: [],
                            })

                            SelectedSMS.map((each, indd) => {
                                // mediaUrl.push(each.ImageFullPath)
                                SMSLoop.push({
                                    Body: each.field1,
                                    mediaUrl: [each.ImageFullPath],
                                })

                            })
                        }
                    }

                    SMSLoop.map((each, insd) => {

                        SMSstring = {
                            Body: each.Body,
                            FromSMS: Invitation.Mobile,
                            From: "+15623936334",
                            To: [Invitation.Mobile],
                            mediaUrl: each.mediaUrl,
                            pk1: CompanyId,
                            CompanyId: CompanyId,
                            ContractorId: ContractorId,
                            SMSList: [{
                                PersonName: Field1,
                                PersonId: Field1,
                                phone: Invitation.Mobile
                            }]
                        };

                        console.log("ItemAddPage.Invite.SMS", SMSstring)

                        API.post("SMStoTwilio", "", {
                            body: { Records: SMSstring }
                        });

                        SMSstring = {};

                    })


                }

                if (SendEmailInvite) {

                    let InvitationMessage = Invitation.message;

                    InvitationMessage += ` Click on the button to access the system and setup password. `;
                    InvitationMessage += `Please use the links listed under Documents to access the service SMS numbers. Save the numbers to your mobile device. `;

                    if (CustomerType === "Company") {
                        if (CurrentCompany
                            && CurrentCompany.SelectedSMSArray
                            && CurrentCompany.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = CurrentCompany.SelectedSMSArray;
                        } else if (ContractorData
                            && ContractorData.SelectedSMSArray
                            && ContractorData.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = ContractorData.SelectedSMSArray;
                        }

                        if (SelectedSMS.length > 0) {

                            //  InvitationMessage += `Please see the following introductory video(s) under Images `;

                            SelectedSMS.map((each, innn) => {
                                //introVideo = ContractorData.IntroVideoList.find(x => x.key === each);
                                //console.log("ItemAddPage.Invite1.introVideo", each, introVideo)
                                //if (Object.keys(introVideo).length > 0) {
                                //  console.log("ItemAddPage.Invite.email", emailstring)
                                Documents.push(each);
                                //    }
                                //    introVideo = {};
                            })
                        }

                        console.log("ItemAddPage.Invite1.SelectedSMS", SelectedSMS)
                        if (CurrentCompany
                            && CurrentCompany.SelectedVideo
                            && CurrentCompany.SelectedVideo !== ""
                        ) {
                            SelectedVideo = CurrentCompany.SelectedVideo;
                        } else if (ContractorData
                            && ContractorData.SelectedVideo
                            && ContractorData.SelectedVideo.length > 0
                        ) {
                            SelectedVideo = ContractorData.SelectedVideo;
                        }

                        if (SelectedVideo.length > 0) {

                            InvitationMessage += `Please see the following introductory video(s) under Images `;

                            SelectedVideo.map((each, innn) => {
                                introVideo = ContractorData.IntroVideoList.find(x => x.key === each);
                                console.log("ItemAddPage.Invite1.introVideo", each, introVideo)
                                if (Object.keys(introVideo).length > 0) {
                                    //  console.log("ItemAddPage.Invite.email", emailstring)
                                    PhotoService.push(introVideo);
                                }
                                introVideo = {};
                            })
                        }
                    }

                    // let ContractorSubDomain = `https://${ContractorData.SubDomain}.service-call-cloud.com`;
                    var emailstring = {

                        PhotoService: PhotoService,
                        Documents: Documents,
                        SpecialInstructions: state.SpecialInstructions,
                        PreSelect: [],
                        title: `Invitation for ${ContractorData.CompanyName} Notification System`,
                        postHTML: InvitationMessage,
                        postTEXT: InvitationMessage,
                        PersonList: [Invitation.Email],
                        ReplyEmail: "ali_rehan@msn.com", //UserData.email,
                        PostId: state.NewId,

                        ContractorAddress: ContractorData.Address,
                        ContractorPhone: ContractorData.phone,
                        avatar: HQData.avatar,
                        ContractorName: ContractorData.CompanyName,
                        ContractorId: ContractorData.CompanyId,
                        CompanyId: CompanyId,
                        Avatar: UserData.Avatar,

                        UnsubscribeLink: `https://${HQData.SubDomain}/Unsubscribe`,
                        ActionButton: `https://${HQData.SubDomain}`,
                        ActionButtonTitle: "Setup Account",
                        ActionButtonShow: true,
                    };

                    console.log("ItemAddPage.Invite1.email", emailstring)
                    SendEmail(emailstring);

                }

                

                let Newentry = {
                    ...FormdataJSON,
                    ...DBInput
                }

                let NewDBinput = {
                    ...DBInput,
                    dataJSON: JSON.stringify(removeEmptyStringElements(Newentry))
                }


                console.log('ItemAddPage.fix-DB-New-DataJson', Newentry)

                return NewDBinput;


            } // end of no alerts

        } catch (err) { console.log("SaveData err", err); }
    }

    const BatchLimitPush = (data) => {

        let item_count = 0;
        let current_batch = [];

        for (var i = 0; i < data.length; i++) {
            item_count++
            current_batch.push(data[i])
            if (item_count % 25 === 0) {
                BatchSendData(current_batch);
                current_batch = []
            }
        }

        // Add the last batch if it has records and is not equal to 25
        if (current_batch.length > 0 && current_batch.length !== 25) {
            BatchSendData(current_batch);
        }

        

        //if (props.CloseDrawer) {
        //    props.CloseDrawer();
        //} DoneDone

        setState(prevState => ({
            ...prevState,
            DoneDone: true
        }));

    }

    const BatchSendData = (data) => {

        console.log("UploadExcel.NewTotalList---", data)

        API.graphql(graphqlOperation(createBatch, { input: data }))
            .then((results) => {
                console.log('createBatch Data ', results);
            }).catch(error => console.log('createBatch error ', error));
    }



    const handleNext = () => {
        console.log("UrgencyDetails.handleNext", activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const OnFormSubmit = (model, key) => {
        try {
            let CountryCode = state.CountryCode;
            console.log("ComboForm.model", key, model);

            //find country code eg "US"
            if (model && model.FormData) {
                let FormDataFind = model.FormData;
                FormDataFind.map((each, indd) => {
                    if (each.VariableName === "CountrySelect" && each.VariableInput !== "") {
                        CountryCode = each.VariableInput;
                    }
                })
            }

            let projects = state.CommonList;

            let DataExtract = {
                ...projects[key],
                ...model,
                ValueAdded: true
            }

            projects[key] = DataExtract;

            setState(prevState => ({
                ...prevState,
                CommonList: projects,
                CountryCode: CountryCode
            }));
           

        } catch (err) { }
    };

    console.log("UploadExcel.state---", state)

    return (
        <div>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {`Bulk Upload ${props.Category} Data`}
                    </Typography>
                </Grid>

                <Grid item xs={12}>

                    <Stepper activeStep={activeStep} orientation="vertical">

                        <Step key={1}>
                            <StepLabel>Data Template</StepLabel>
                            <StepContent>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Typography >Please create a Excel file with following colomn.  Do not use column headings. Make sure the columns are in the exact order as below</Typography>
                                    </Grid>

                                    {state.ColumnList && state.ColumnList.length > 0 ? (
                                        state.ColumnList.map((each, indd) => (
                                            <Grid item xs={12}>
                                                <Typography >{`Column #${indd + 1}: ${each.label}`}</Typography>
                                            </Grid>
                                        ))
                                    ) : null}

                                    <Grid item xs={12}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                            //  className={classes.button}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleNext()}
                                            //  className={classes.button}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>

                        {state.CommonList && state.CommonList.length > 0 ? (
                            <Step key={5}>
                                <StepLabel>Common Data</StepLabel>
                                <StepContent>

                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography >{`Following data will be applied to all the data enteries for ${props.Category}.  Indivisual items can be edited later. Please select/enter information that applies to most of the data enteries`}</Typography>
                                        </Grid>

                                        {state.CommonList.map((each, index) => (
                                            <Grid
                                                item
                                                xs={12}
                                                key={index + "autooo"}
                                            >
                                                <DynamicForms
                                                    key={index + each.key}
                                                    id={each.key}
                                                    //  SaveDetails={rest.SaveDetails}
                                                    Category={each.Category}
                                                    FormType={each.FormType}
                                                    // DisableForm={rest.DisableForm}
                                                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                                    // CompanyId={rest.CompanyId}
                                                    title={each.FormName}
                                                    ShowEditButton={false}
                                                    UpdateFormData={e => console.log("UpdateFormData", e)}
                                                    PreviewEdit={false}
                                                    EditItem={e => console.log("EditItem", e)}
                                                    FormData={each.FormData}
                                                    onSubmit={(model) => OnFormSubmit(model, index)}
                                                />


                                            </Grid>
                                        ))}

                                        <Grid item xs={12}>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                //  className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNext()}
                                                //  className={classes.button}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                        ) : null}

                        <Step key={10}>
                            <StepLabel>Upload Excel File</StepLabel>
                            <StepContent>

                                <Grid container spacing={2}>


                                    <Grid item xs={12}>
                                        <input
                                            type="file"
                                            lable='file Uploadddd'
                                            name="Upload Excel file"
                                            onChange={onChangeHandler}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                            //  className={classes.button}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleNext()}
                                            //  className={classes.button}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>

                        {state.RejectedData && state.RejectedData.length > 0 ? (
                            <Step key={20}>
                                <StepLabel>Data with Errors</StepLabel>
                                <StepContent>

                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography >Following lines had errors or did not match the template.  Please fix the data and re-upload the file in the previous step</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Card
                                                style={{ marginTop: 16 }}
                                                type="inner"
                                                title="Data with Errors"  >

                                                {state.RejectedData.map((each, indd) => (
                                                    <Grid item xs={12}>
                                                        <Typography >{each.join(', ')}</Typography>
                                                    </Grid>
                                                )
                                                )}

                                            </Card>
                                        </Grid>

                                        <Grid item xs={12}>

                                            {RejectData.length > 0 ? (
                                                <Card style={{ marginTop: 16 }} type="inner" title="Data with errors"  >

                                                </Card>
                                            ) : null}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                //  className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNext()}
                                                //  className={classes.button}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                        ) : null}

                        <Step key={30}>
                            <StepLabel>Save Data</StepLabel>
                            <StepContent>

                                <div
                                //className={classes.actionsContainer}
                                >
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        //  className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => SendDatatoDB()}
                                        //  className={classes.button}
                                        >
                                            Save Data
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>

                    </Stepper>
                </Grid>

            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </div>
    );
}



// <ul>
//           {this.state.list.map(item => (
//             <li key={item}>The person is {item} years old.</li>
//           ))}
//           <button type="button" onClick={this.onRemoveFirstItem}>
//             Remove First Item
//           </button>
//         </ul>

export default Uploadtest;