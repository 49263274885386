import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
    Snackbar,
} from '@mui/material';
import { FormSchema } from '../Forms/FormList';
import DynamicForms from '../Forms/FormShow';

export default function RadioButtonsGroup(props) {
    const initialState = {
        FormData: [],
        id: "",
        KeyId: "",
        loading: false,
        AlarmMessage: "",
        OpenAlert: false,
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let StepValueArray = {};
        let FormData = [];

        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        console.log("SignUpWizStep.StepValueArray", StepValueArray)

        if (StepValueArray && StepValueArray.FormData && StepValueArray.FormData.length > 0) {
            FormData = StepValueArray.FormData;
        } else {
            FormData = FormSchema.filter(x => x.Category === props.Category);
        }

        console.log("SignUpWizStep.FormData", FormData)

        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                id: props.id,
                Category: props.Category,
                KeyId: props.KeyId,
                title: props.title,
                FormData: FormData,
                loading: true,
            }));
        }

    }, [props.Category])

    useEffect(() => {

        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                loading: true,
            }));
        }

    }, [state.FormData])

    const handleNext = () => {

        let AlarmMessage = "";
        state.FormData.map((each, indd) => {
            if (each.required && each.VariableInput === "") {
                AlarmMessage += ` * Please complete ${each.label}`
            }
        })

        if (AlarmMessage !== "") {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: true
            }));

        } else {

            let SelectedDataReturn = {
                ...state,
                id: props.id,
                KeyId: props.KeyId
            }
            console.log("SignUpWizStep.Next", SelectedDataReturn)
            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedDataReturn)
            }

            if (props.handleNext) {
                props.handleNext();
            }
        }

    }

    const OnFormSubmit = (data, cat) => {
        console.log("SignUpWizStep.FormData.Filled", data)
        setState(prevState => ({
            ...prevState,
            Category: props.Category,
            KeyId: props.KeyId,
            FormData: FormData,
            title: props.title,
            ...data,
        }));

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };

    console.log("SignUpWizStep.state", state)
    console.log("SignUpWizStep.props", props)

    return (
        <div key={props.KeyId}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {state.loading && (
                        <DynamicForms
                            key={"dynFor"+ props.KeyId}
                            ShowEditButton={false}
                            PlaneData={true}
                            title={props.title}
                            PreviewEdit={false}
                            Category={props.Category}
                            FormData={state.FormData}
                            onSubmit={(model) => OnFormSubmit(model, "FormData")}
                        />
                    )}

                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

        </div>

    );
}
