import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/material/styles';
//import Paper from '@mui/material/Paper';
import {
  Grid,
  //Paper, Grid,
  //Backdrop, 
  //CircularProgress,
  //Card,
  //CardContent,
  //InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  //Switch, Tooltip, Divider
} from '@mui/material';
import UploadFile from '../Upload/UploadFile'
import ShowImages from '../Upload/ShowImages';
//import Divider from '@mui/material/Divider';
//import IconButton from '@mui/material/IconButton';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//import DeleteIcon from '@mui/icons-material/Delete';

//const useStyles = makeStyles((theme) => ({
//  root: {
//    padding: '2px 4px',
//    display: 'flex',
//    alignItems: 'center',
//    // width: 400,
//  },
//  input: {
//    marginLeft: theme.spacing(1),
//    flex: 1,
//  },
//  iconButton: {
//    padding: 10,
//  },
//  divider: {
//    height: 28,
//    margin: 4,
//  },
//}));

export default function CustomizedInputBase(props) {
  const initialState = {
    ImageDisplay: [],
    ImageList: [],
    DocList: []
  }
  const [state, setState] = useState(initialState);

  //useEffect(() => {
  //  setTitle(props.VariableInput)
  //}, [props.VariableInput])

  //const SelectedValues = e => {
  //  setTitle(e.target.value);
  //  props.SelectedValues(e.target.value)
  //}

  const SelectedValues = (data) => {
    
    let Tottal = [data, ...state.ImageDisplay] 
    setState(prevState => ({ ...prevState, ImageDisplay: Tottal }))

    console.log("Combineupload.filenew", Tottal);

    if (data.filetypes > 2) {
      let ImageList = [...state.ImageList, data]
      console.log("ImageList", ImageList);
      props.ImageList(ImageList);
      // props.ImageStateGet(state)
      setState(prevState => ({ ...prevState, ImageList: ImageList }))
    }

    if (data.filetypes < 3) {
      let DocList = [...state.DocList, data]
      console.log("DocList", DocList);
      props.DocumentList(DocList);
      // props.ImageStateGet(state)
      setState(prevState => ({ ...prevState, DocList: DocList }))
    }
  }


  return (
    <div>
      <Grid container spacing={2}>

        {/*
          state.ImageDisplay.length > 0 && (
        <Grid item xs={12}>
          <ShowImages
            ImageDisplay={state.ImageDisplay}
            ImageList={(e) => props.ImageList(e)}
            DocumentList={(e) => props.DocumentList(e)}
          />
        </Grid>
          )
        */}
        <Grid item xs={12}>
          <UploadFile
            TotalFile={(e) => SelectedValues(e)}
            title="Upload Pictures or Documents"
          />
        </Grid>
      </Grid>
      </div>
  );
}
