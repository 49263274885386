import React, { useEffect, useState } from 'react';
import {    
    Grid, Button,
    FormControl, RadioGroup, FormControlLabel, Radio, TextField
} from '@mui/material';

let ReportReminder = [
    {
        delay: "1440",
        label: "After 24 Hours"
    },
    {
        delay: "2880",
        label: "After 2 days"
    },
    {
        delay: "4320",
        label: "After 3 days"
    },
    {
        delay: "10080",
        label: "After 1 week"
    },
];

let ProgressReminder = [
    {
        delay: "120",
        label: "After 2 Hours"
    },
    {
        delay: "240",
        label: "After 4 Hours"
    },
    {
        delay: "360",
        label: "After 6 Hours"
    },
    
]

    

export default function RadioButtonsGroup(props) {

    const initialState = {
        Delay: "0",
        Message: "Please provide work progress with description and pictures"
    }
    const [state, setState] = useState(initialState);


    useEffect(() => {

        let Block = ReportReminder;
        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);

            if (props.KeyId === "ProgressReminder") {
                Block = ProgressReminder;
            }
        }
        console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
                Block: Block
            }));
        }

    }, [props.StepValueArray])

    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("SaveDataBack.Priority", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const KeyArray = (data, cat) => {

        let newdata = data.target.value;

        setState(prevState => ({
            ...prevState,
            [cat]: newdata
        }));


    }

    console.log("Priority.state", state)
    console.log("Priority.props", props)

    return (
        <Grid container spacing={2}>

            {state.Block && state.Block.length > 0 && (
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="Priority"
                        name="radio-buttons-group"
                        onChange={(data) => KeyArray(data, "Delay")}
                        value={state.Delay}
                        >

                            <FormControlLabel
                                key="skakaoq1"
                                value="0"
                                control={<Radio />}
                                label="No Reminder"
                            />

                            {state.Block.map((each, indd) => (
                                <FormControlLabel
                                    value={each.delay}
                                    key={each.deley + indd}
                                    control={<Radio />}
                                    label={each.label}
                                />
                            ))}                        

                    </RadioGroup>
                </FormControl>
            </Grid>
            )}

            {props.KeyId === "ProgressReminder" && (
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Reminder Message"
                        margin="normal"
                        name="Message"                        
                        multiline
                        minRows={2}
                        onChange={e => KeyArray(e, "Message")}
                        type="text"
                        value={state.Message || ""}
                        variant="outlined"
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <div>
                    <div>
                        <Button
                            disabled={props.activeStep === 0}
                            onClick={() => props.handleBack()}
                        // className={classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext()}
                        //  className={classes.button}
                        >
                            {'Next'}
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>

    );
}
