import React, { useState, useEffect } from 'react';
import { Grid, Button, Box } from '@mui/material';
import { useSelector } from 'react-redux';
//import makeStyles from '@mui/styles/makeStyles';
//import RenderModule from './RenderModule';
//import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NewPageId, getAutoCompletedata } from '../utils/CommonGraphql';
//import { Calendar } from 'react-feather';
import Calendar from '../Calendar/Calendar';
import DrawerShow from '../utils/DrawerModule';




function getWeekInMonth(year, month, day) {

    let weekNum = 1;
    let weekDay = new Date(year, month, 1).getDay();
    let monday = 0 + (7 - weekDay);
    while (monday <= day) { //we calculate in wich week is our day
        weekNum++;
        monday += 7;
    }
    return weekNum; //we return it
}

let ActiveDate = new Date();

const CalendarBox = (props) => {
    // const classes = useStyles();
   // const UUid = NewPageId("Drwr");
    const UserData = useSelector(state => state.UserData);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const initialState = {
        myEventsList: [],
        ActiveDate: ActiveDate,
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('CalendarGrid.props', props);
        LoadData();
        //checking if shift data coming from person on shift (PersonCalendarBox)


    }, [props])

    //useEffect(() => {

    //    console.log('CalendarGrid.props', props);
    //    GetDatafromDB("ESC", props.CompanyId, "Shift");

    //}, [])

    useEffect(() => {

        DatesArray(ActiveDate);

    }, [state.Shift])

    const LoadData = () => {

        if (props.ShiftShow && props.ShiftShow.length > 0) {
            setState(prevState => ({
                ...prevState,
                Shift: props.ShiftShow
            }))
        } else {
            GetDatafromDB("PMSchedule", props.CompanyId, "Shift");
        }
    }


    const GetDatafromDB = async (Category, CompanyId, ArrayName) => {
        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId);

        setState(prevState => ({
            ...prevState,
            ...NewData

        }))

        console.log("CalendarGrid.NewData", NewData);
    }

    const DatesArray = (ActiveDate) => {

        let NewActiveDate = new Date(ActiveDate);
        let y = NewActiveDate.getFullYear();
        let m = NewActiveDate.getMonth();

        let myEventsList = [];
        let ESCData = state.Shift;

        let FindWeekOfMonth = false;
        let FindDay = false;
        let FindMonth = false;
        let EndTime = [];
        let EndTimeHour = 0;
        let EndTimeMinutes = 0;
        let StartTimeHour = 0;
        let StartTimeMinutes = 0;
        let StartTime = [];
        let weekNumber = 1;
        //let dayToday = 0;
        let first = new Date(y, m, 1).getDay();
        let last = 32 - new Date(y, m, 32).getDate();
        let ShiftBoxColor = '#ff7f50';
        let ShiftTextColor = "#030202";
        let WithInDates = true;
        let StartDate = "";
        let EndDate = "";
        let ThisDate = "";
        //let Days = [1, 4, 6];
        //let WeekofMonth = [1, 3, 5];
        //let totalweeks = Math.ceil((first + last) / 7)

        //console.log('CalendarGrid.first', first);
        //console.log('CalendarGrid.last', last);
        //console.log('CalendarGrid.ESCData', ESCData);

        let WeekDay = first;
        if (ESCData && ESCData.length > 0) {
            for (let date = 1; date <= last; date++) {

                weekNumber = getWeekInMonth(y, m, date);

                ESCData.map((eachESC, Indd) => {
                    // console.log('CalendarGrid.Dates1', eachESC);
                    FindWeekOfMonth = eachESC.WeekOfMonth.includes(weekNumber)
                    FindDay = eachESC.Days.includes(WeekDay)
                    FindMonth = eachESC.MonthsOfYear.includes(m)

                    if (eachESC.Colors && eachESC.Colors.length > 0) {
                        ShiftBoxColor = eachESC.Colors[0].color;
                        ShiftTextColor = eachESC.Colors[1].color;
                    }

                    //schedule between dates
                    if (eachESC
                        && eachESC.StartDate
                        && eachESC.EndDate
                        && eachESC.StartDate !== ""
                        && eachESC.EndDate !== ""
                    ) {

                        StartDate = new Date(eachESC.StartDate).getTime();
                        EndDate = new Date(eachESC.EndDate).getTime();
                        
                        if (eachESC.ScheduleType === "Recurring") {
                            EndDate = "32503795199000";
                        }
                        
                        ThisDate = new Date(y, m, date).getTime()
                        
                        if (ThisDate >= StartDate && ThisDate <= EndDate) {
                            WithInDates = true;
                        } else {
                            WithInDates = false;
                        }
                    }

                    if (FindDay && FindWeekOfMonth && FindMonth && WithInDates) {

                        EndTime = eachESC.EndTime.split(":")
                        EndTimeHour = parseInt(EndTime[0]);
                        EndTimeMinutes = parseInt(EndTime[1]);

                        StartTime = eachESC.StartTime.split(":")
                        StartTimeHour = parseInt(StartTime[0]);
                        StartTimeMinutes = parseInt(StartTime[1]);

                        myEventsList.push({
                            id: eachESC.key + Indd,
                            ShiftBoxColor: ShiftBoxColor,
                            ShiftTextColor: ShiftTextColor,
                            CompanyId: props.CompanyId,
                            key: eachESC.key,
                            title: eachESC.field1,
                            start: new Date(y, m, date, StartTimeHour, StartTimeMinutes, 0),
                            end: new Date(y, m, date, EndTimeHour, EndTimeMinutes, 0),
                        })

                        //  console.log('CalendarGrid.results', y, m, StartTimeHour, EndTimeHour);
                    }
                })

                if (WeekDay === 6) {
                    WeekDay = 0;
                } else {
                    WeekDay++
                }
            }

        }

        setState(prevState => ({
            ...prevState,
            myEventsList: myEventsList
        }));

    }

    const onSelectEvent = event => {
        console.log("CalendarGrid.onSelectEvent", event);

        setState(prevState => ({
            ...prevState,
            Event: event,
            DetailComponent: "PMDayEdit",
            DrawerTitle: "PM Schedule"
        }));

        OpenDrawer(true);
    }

    const AddNewShift = () => {
        //  console.log("CalendarGrid.onSelectEvent", event);

        setState(prevState => ({
            ...prevState,
            //   Event: event,
            DetailComponent: "PMScheduler",
            DrawerTitle: "PM Schedule",

        }));

        OpenDrawer(true);
    }

    //console.log("DrawerModule.Props", props)
    // const CloseDrawerrr = () => {
    //   console.log("DrawerModule.DrawerClose")
    //   if (props.CloseDrawer) {
    //     props.CloseDrawer()
    //   }
    // }

    const CloseDrawer = (c) => {
        //GetDatafromDB(state.Category, state.pk1);
        // GetDatafromDB("ESC", props.CompanyId, "Contractor");
       // GetDatafromDB("ESC", props.CompanyId, "Shift");


        LoadData();
        OpenDrawer(false);
    }

    return (
        <>
            <Grid container spacing={1} >

                {UserData.CustomerType === "Contractor"
                || UserData.CustomerType === "Sub" ? (
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ mt: 2 }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => AddNewShift()}
                        //  disabled={rest.disabled}
                        >
                            {`Add PM schedule`}
                        </Button>
                    </Box>
                </Grid>
                ) : null}

                <Grid item xs={12}>
                    <Calendar
                        myEventsList={state.myEventsList}
                        ActiveDate={date => DatesArray(date)}
                        onSelectEvent={event => onSelectEvent(event)}
                    />
                </Grid>
            </Grid>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.DrawerTitle}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    Event: state.Event,
                    //Category: state.Category,
                    //key: state.key,
                    //StepPersonal: (e) => AddNextStepOrEnd(e),
                    //StepNumber: props.StepNumber,
                    //StepTeam: state.StepTeam,
                    //ShiftName: props.ShiftName,
                    //ShowEditButton: false,
                    //disabled: false,
                    //EditItem: state.EditItem,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    CustomerType: props.CustomerType,
                    id: NewPageId("Drwr")
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </>
    );
};

export default CalendarBox;

