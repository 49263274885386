/*
 * 
 * Show person or company details
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import {
    Grid, Typography,
    Card, CardContent, Avatar, CardHeader
} from '@mui/material';
import {
    FindCategoryIcon,
} from '../utils/CommonGraphql';
import { red } from '@mui/material/colors';

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}


export default function SimpleCard(props) {

    // console.log("SRinformation.props", props)


    return (

        <Card key={"crfddssd1"}>
            <CardHeader
                title="Service Requested By"
                //subheader="Information"

                avatar={props.Data.avatar && props.Data.avatar !== "" ? (
                    <Avatar alt={props.Data.field1} src={props.Data.avatar} />
                ) : (
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                        {FindCategoryIcon("Person")}
                    </Avatar>
                )}
            />

            <CardContent>
                <Grid container spacing={1}>

                    {props.Data.field1 && (
                        <>
                            <Grid item xs={12} md={4}>

                                <Typography variant="h5">
                                    Name:
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={8}>

                                <Typography>
                                    {props.Data.field1}
                                </Typography>

                            </Grid>
                        </>
                    )}

                    {props.Data.CompanyName && (
                        <>
                            <Grid item xs={12} md={4}>

                                <Typography variant="h5">
                                    Company:
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={8}>

                                <Typography>
                                    {props.Data.CompanyName}
                                </Typography>

                            </Grid>
                        </>
                    )}

                    {props.Data.field1 && (
                        <>
                            <Grid item xs={12} md={4}>

                                <Typography variant="h5">
                                    Email:
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={8}>

                                <Typography>
                                    {props.Data.email}
                                </Typography>

                            </Grid>
                        </>
                    )}

                    {props.Data.phone && (
                        <>
                            <Grid item xs={12} md={4}>

                                <Typography variant="h5">
                                    Phone:
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={8}>

                                <Typography>
                                    {formatPhoneNumber(props.Data.phone)}
                                </Typography>

                            </Grid>
                        </>
                    )}

                    {props.Data.mobile && (
                        <>
                            <Grid item xs={12} md={4}>

                                <Typography variant="h5">
                                    Mobile:
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={8}>

                                <Typography>
                                    {formatPhoneNumber(props.Data.mobile)}
                                </Typography>

                            </Grid>
                        </>
                    )}

                    {props.Data.Location && props.Data.Location.address && (
                        <>
                            <Grid item xs={12} md={4}>

                                <Typography variant="h5">
                                    Location:
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={8}>

                                <Typography>
                                    {props.Data.Location.address}
                                </Typography>

                            </Grid>
                        </>
                    )}

                </Grid>

            </CardContent>
        </Card>

    );
}
