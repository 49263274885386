import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/material/styles';
//import Paper from '@mui/material/Paper';
import { TextField, Grid, Typography, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTodaySlash, CompareDates, Hours } from '../utils/DateTime'
//import Quill from '../components/quill'
//import InputBase from '@mui/material/InputBase';
//import Divider from '@mui/material/Divider';
//import IconButton from '@mui/material/IconButton';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//import DeleteIcon from '@mui/icons-material/Delete';

//const useStyles = makeStyles((theme) => ({
//  root: {
//    padding: '2px 4px',
//    display: 'flex',
//    alignItems: 'center',
//    // width: 400,
//  },
//  input: {
//    marginLeft: theme.spacing(1),
//    flex: 1,
//  },
//  iconButton: {
//    padding: 10,
//  },
//  divider: {
//    height: 28,
//    margin: 4,
//  },
//}));

//var today = new Date();
//var dd = String(today.getDate()).padStart(2, '0');
//var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
//var yyyy = today.getFullYear();

//let startdate = mm + '/' + dd + '/' + yyyy;

//const DateFormat = 'MM/DD/YYYY';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  formControl: {
    // margin: theme.spacing(1),
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  const initialState = {
    StartTime: '07:00',
    EndTime: "17:00",
    Endtimemessage: "",
    //StartDate: DateTodaySlash,
    //EndDate: DateTodaySlash,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let DateRange = {
      StartTime: state.StartTime,
      EndTime: state.EndTime,
      EndTimeMessage: state.Endtimemessage
    }

    props.SelectedValues(DateRange);

  }, [state.StartTime, state.EndTime])

  //const SelectedValues = (name, item) => {

  //  if (name === "StartTime") {

  //    var time1Date = new Date("01/01/2000 " + item.target.value);
  //    var time2Date = new Date("01/01/2000 " + state.EndTime);

  //    if (time1Date > time2Date) {
  //      setState((prevState) => ({
  //        ...prevState,
  //        EndTime: item.target.value,
  //      })) 
  //    }
  //  }

  //  setState((prevState) => ({
  //    ...prevState,
  //    [name]: item.target.value,
  //  }))
  //}

  const EndTimeCheck = (e) => {
    let Endtimemessage = ""
    if (e < state.StartTime) {
      Endtimemessage = "Next Day";
    }
    setState(prevState => ({ ...prevState, EndTime: e, EndTimeMessage: Endtimemessage }));
  }

  const StartTimeCheck = (e) => {
    let Endtimemessage = ""
    if (e > state.EndTime) {
      Endtimemessage = "Next Day";
    }
    setState(prevState => ({ ...prevState, StartTime: e, EndTimeMessage: Endtimemessage }));
  }


  return (
    <>
      <Grid container spacing={2} >

        <Grid
          key="5"
          item
          xs={4}>
          <Typography variant="h5" component="h2">
            Shift Start Time:
            </Typography>
        </Grid>
        <Grid
          key="6"
          item
          xs={8}>

          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
            <InputLabel id="demo-simple-select-outlined-label">Start Time</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              //id={`select-${Item.key}-${index}`}
              defaultValue={state.StartTime}
              //error={ErrorMessage}
              //required={Item.required}
              disabled={state.disabled}
              autoWidth={true}
              value={state.StartTime || ""}
              onChange={e => StartTimeCheck(e.target.value)}
              label="Start Time"
            >
              {Hours.map((o, indd) => (
                <MenuItem key={indd} value={o.value}>
                  {o.label}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl >


        </Grid>
        <Grid
          key="7"
          item
          xs={4}>

          <Typography variant="h5" component="h2">
            Shift End Time:
              </Typography>
          {state.EndTimeMessage !== "" && (
            <Typography color='error'>
              {state.EndTimeMessage}
            </Typography>
          )}

        </Grid>
        <Grid
          key="8"
          item
          xs={8}
        >


          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
            <InputLabel id="demo-simple-select-outlined-label">End Time</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              //id={`select-${Item.key}-${index}`}
              defaultValue={state.EndTime}
              //error={ErrorMessage}
              //required={Item.required}
              disabled={state.disabled}
              autoWidth={true}
              value={state.EndTime || ""}
              onChange={e => EndTimeCheck(e.target.value)}
              //onChange={e => setState((prevState) => ({ ...prevState, EndTime: e.target.value }))}
              label="End Time"
            >
              {Hours.map((o, indd) => (
                <MenuItem key={indd} value={o.value}>
                  {o.label}
                </MenuItem>
              ))
              }
            </Select>
          </FormControl >

          

        </Grid>

      </Grid>
    </>
  );
}
