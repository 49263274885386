import React, { useEffect, useState } from 'react';
import {
    TextField,
    //Collapse,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import SelectAssets from '../components/SelectAssets';
//import { NewPageId } from '../utils/CommonGraphql';
//import MuiAlert from '@mui/material/Alert';
//import { useSelector } from 'react-redux';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function RadioButtonsGroup(props) {
  //  const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        Answer: false
    }
    const [state, setState] = useState(initialState);
  //  const [DrawerStatus, OpenDrawer] = useState(false);

   
    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

      //  console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])


    const SetValues = (data, name) => {

        let Body = state.Body;
        if (data) {
            Body = "Yes";
        } else {
            Body = "No"
        }

        setState(prevState => ({
            ...prevState,
            [name]: data,
            Body: Body
        }));

    }

    const handleNext = () => {

            let SelectedDataReturn = {
                ...state,
                id: props.id,
                KeyId: props.KeyId
            }
        console.log("YesNoBox.key", SelectedDataReturn)
            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedDataReturn)
            }

            if (props.handleNext) {
                props.handleNext();
            }

    }

   
    console.log("YesNoBox.state", state)
    console.log("YesNoBox.props", props)

    return (
            <Grid container spacing={1}>
               
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                checked={state.Answer}
                                    onChange={(e) => SetValues(e.target.checked, "Answer")}
                                />
                            } label="Yes" />
                    </FormGroup>

                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>
    );
}
