import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
//import { styled } from '@mui/material/styles';
import {
  TextField,
  Collapse,
  Paper, Grid,
  Backdrop, 
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const initialState = {
    title: "",
    message: "",
    include: false
  }
  const [state, setState] = useState(initialState);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  useEffect(() => {
    if (props && props.step) {

      setState(prevState => ({
        ...prevState,
        label: props.step.label,
        message: props.step.message,
        required: props.step.required,
        include: props.step.include,
      }));

    }
  }, [props.step])

  console.log("props", props)

  const ClosingDrawer = (e) => {
    console.log("ClosingDrawer", e)
    SetDeawerOpen(false)
  }

  const handleChange = (event, name) => {
    let change = event.target.checked;
    //setState({
    //  ...state,
    //  [name]: event.target.checked,
    //});

    setState(prevState => ({
      ...prevState,
      [name]: event.target.checked,
    }));

    props.handleInclude(change, props.step.key);
  };

  const handleRequired = (event, name) => {
    let change = event.target.checked;
    setState(prevState => ({
      ...prevState,
      [name]: event.target.checked,
    }));
   // props.handleInclude(change, props.step.key);
  };

  const SelectedValues = (item, name) => {
    
    setState({
      ...state,
      [name]: item.target.value,
    });
    // props.handleInclude(change, props.step.key);
  };

  const AddToImage = (item, name) => {

    setState({
      ...state,
      [name]: item,
    });
    // props.handleInclude(change, props.step.key);
  };

  /*
  <StepBox
    MoveUp={e => MoveUp(e)}
    MoveDown={e => MoveDown(e)}
    steps={""}
    UpdatedValue={(vv, ind) => UpdatedValue(vv, ind)}
    />

{
        "key": "title",
        "label": "Title",
        "ModuleTitle": "Title",
        "Preset": false,
        "message": "Please enter heading / title",
        "LoadComponenet": "Title",
        "LoadComponenet2": "",
        "LoadComponenet3": "",
        "Trigger1": false,
        "Trigger2": false,
        "Trigger3": false,
        "required": false,
        "DataReceivedArrayName": "title",
        "DataSendArrayName": "",
        "disabled": false,
        "Category": "",
        "VariableInput": "get the service started",
        "ShowHTML": "get the service started",
        "field1": "get the service started",
        "SearchWords": "get the service started",
        "pk1": "",
        "sk1": ""
    },

*/

  //console.log("SelectStepBox.step", props.step);

  
  return <>
    <Card className={classes.root}>
      <CardHeader
        action={[
           <IconButton aria-label="settings" size="large">
            <Switch
              checked={state.include}
              onChange={e => handleChange(e, "include")}
            />
          </IconButton>,
          <IconButton aria-label="settings" onClick={() => handleExpandClick()} size="large">
            <EditIcon />
          </IconButton>,
          <IconButton aria-label="settings" onClick={() => props.MoveDown()} size="large">
            <ExpandMoreIcon />
          </IconButton>,
          <IconButton aria-label="settings" onClick={() => props.MoveUp()} size="large">
            <ExpandLessIcon />
          </IconButton>,

       ]
        }
        title={`${props.step.labelPermanent} ${props.StepNumber}`}
      />            

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

          <Grid container spacing={2}>

            <Grid item xs={12}>

              <FormControlLabel
                control={
                  <Switch
                    checked={state.required}
                    onChange={e => handleRequired(e, "required")}
                  />}
                label="Required"
              />

            </Grid>

            <Grid item xs={12}>
              <TextField
                required={true}
                //error={ErrorMessage}
                //disabled={props.disabled}
                //id={`textline-${props.id}`}
                //defaultValue={Item.defaultValue}
                label="Label"
                name="label"
                value={state.label || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => SelectedValues(e, "label")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                //required={true}
                //error={ErrorMessage}
                //disabled={props.disabled}
                //id={`textline-${props.id}`}
                //defaultValue={Item.defaultValue}
                label="Instructions / message"
                multiline
                rows={3}
                name="message"
                value={state.message || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => SelectedValues(e, "message")}
              />
            </Grid>

            
           

            {props.step.Preset && (
              <>
                <Grid item xs={12}>

                  <Typography>Preset Values</Typography>
                  </Grid>
                <Grid item xs={12}>
          <RenderModule
            //key={props.step.key + indd + "key"}
            VariableName={props.step.key}
            label={props.step.label}
            CompanyId={props.CompanyId}
            Category={props.step.Category}
            id={props.step.key + "idddd"}
            title={props.step.ModuleTitle}
            VariableInput={state[props.step.DataReceivedArrayName]}
            disabled={props.step.disabled}
            DataSent={state[props.step.DataSendArrayName]}
            LoadComponenet={props.step.LoadComponenet}
            onSubmit={data => SelectedValues(data, props.step.DataReceivedArrayName)}
            SelectedValues={data => SelectedValues(data, props.step.DataReceivedArrayName)}
            ImageList={(e) => AddToImage(e, "PhotoService")}
            DocumentList={(e) => AddToImage(e, "Documents")}
            Trigger1={props.step.Trigger1}
            Trigger2={props.step.Trigger2}
            Trigger3={props.step.Trigger3}
          />
                </Grid>
                </>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
    <DrawerShow
      LoadComponenet={props.NextLoadComponenet}
      CloseDrawer={() => ClosingDrawer()}
      DrawerTitle="change this"
      //LoadComponenet="ItemAddPage"
      propss={{
        CloseDrawer: (e) => ClosingDrawer(e),
        ...props
      }}
      DrawerStatus={DeawerOpen}
      // CloseDrawer={(e) => SetDeawerOpen(false)}
      OpenDrawer={(e) => SetDeawerOpen(false)}
      anchor="left"
    />
  </>;
}
