//import React from 'react';
//import { useSelector } from 'react-redux';
//import { useSelector } from 'react-redux';
import { API } from "aws-amplify";

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
});
//const SES = new AWS.SES();
const UTF8CHARSET = 'UTF-8';


export const SendEmail = (event) => {
    // const ContractorData = useSelector(state => state.ContractorData);

    try {
        console.log("SendMail.event", event);

        let PersonList = []

        if (event.PersonList && event.PersonList.length > 0) {
            event.PersonList.map(each => {
                if (each.includes("@test-")) {
                    PersonList.push("ali_rehan@msn.com");
                } else {
                    PersonList.push(each);
                }
            })
        }

        let ReturnMessage = "";

        if (event && event.PersonList && event.PersonList.length > 0) {

            let EmailPrems = {
                ContractorPhone: "",
                avatar: "",
                ContractorName: "",
                UnsubscribeLink: "",
                ActionButton: "",
                ActionButtonTitle: "Go Online",
                ContractorAddress: "30 N Gould St, Suite 37310, Sheridan, WY 82801",
                PhotoService: [],
                Documents: [],
                SpecialInstructions: "",
                PreSelect: [],
                title: "",
                postHTML: "",
                PersonList: [],
                postTEXT: "",
                ReplyEmail: `noreply@sninc.us`,
                PostId: "",
                ActionButtonShow: true,
                ...event
            };

            console.log("SendMail.EmailPrems", EmailPrems);

            SendingEmail(EmailPrems);

            //const destination = {
            //    ToAddresses: event.PersonList
            //};

            /*
            let Emailtemplatebe = EmailTemplate(EmailPrems);

            const emailParams = {
                // Destination: destination,
                Destination: {
                    ToAddresses: PersonList
                },
                Message: {
                    Body: {
                        Html: { Charset: UTF8CHARSET, Data: Emailtemplatebe },
                        Text: { Charset: UTF8CHARSET, Data: event.postTEXT }
                    },
                    Subject: {
                        Charset: UTF8CHARSET,
                        Data: event.title
                    }
                },
                Source: event.ReplyEmail
            };

            if (event.ReplyEmail && Array.isArray(event.ReplyEmail)) {
                emailParams.ReplyToAddresses = event.ReplyEmail;
            }
          //  console.log("SendMail.emailParams", emailParams);

            var sendPromise = new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(emailParams).promise();

            // Handle promise's fulfilled/rejected states
            sendPromise.then(
                function (data) {
                    console.log("SendEmail.success", data.MessageId);
                }).catch(
                    function (err) {
                        console.error("SendEmail.fail", err, err.stack);
                    });

            //SES.sendEmail(emailParams).promise()
            //    .then(() => {
            //       // ReturnMessage = "Email Successfully Sent";
            //        console.log("Email Successfully Sent", ReturnMessage);
            //    })
            //    .catch(err => {
            //       // ReturnMessage = "There was an error in sending the email. Please try again";
            //        console.log("SendMail.err", err);
            //    });

            */
        }

        return ReturnMessage;

    } catch (err) { console.log("UrgencyDetails.err", err); }

};

const SendingEmail = (event) => {
    console.log("SendingEmail", event);

    API.post("SendEmail", "", {
        body: { Records: event }
    });
}


const EmailTemplate = event => {

    let ContractorName = event.ContractorName;
    let ContractorAddress = event.ContractorAddress;
    let ContractorPhone = event.ContractorPhone;
    let ActionButton = event.ActionButton;
    let ContractorLogo = event.avatar;
    let ActionButtonTitle = event.ActionButtonTitle;
    let PhotoService = event.PhotoService;
    let Documents = event.Documents;
    let SpecialInstructions = event.SpecialInstructions;
    let PreSelect = event.PreSelect;
    let UnsubscribeLink = event.UnsubscribeLink;
    let Heading = event.title;
    let Message = event.postHTML;
    let ActionButtonShow = event.ActionButtonShow;
    let ServiceLogo = "https://www.servicewithtext.com/images/Logo_svc_Black.png";
    let ServiceWebLink = "https://www.servicewithtext.com/";

    if (window.WebsiteType === "ContractorCheckIn") {
        ServiceLogo = "https://www.contractorcheckin.com/images/Logo_checkin_Black.png";
        ServiceWebLink = "https://www.contractorcheckin.com/";
    }

    let BaseInfo = BaseInfoList => {
        // console.log("BaseInfoList", BaseInfoList)
        let NewBulletItem = "";
        if (BaseInfoList && BaseInfoList.length > 0) {
            BaseInfoList.map((each, indd) => {
                if (each.VariableInput) {
                    NewBulletItem += `<li>${each.field1}</li>`;
                }
            });
        }
        return NewBulletItem;
    };


    let GetContactList = ContactList => {

        let GetContactListString = "";
        ContactList.map((each, indd) => {

            GetContactListString += `<tr>
          <td class="attributes_item">
            <span class="f-fallback">
                                    <h3>${each.role}</h3>
                                    </span>
          </td>
        </tr>
        <tr><td class="attributes_item">
            <span class="f-fallback">
              `;

            if (each.BaseInfo && each.BaseInfo.length > 0) {
                GetContactListString += `<ul>
                ${BaseInfo(each.BaseInfo)}`;
            }

            if (each.ExtraInfo && each.ExtraInfo.length > 0) {
                GetContactListString += `${BaseInfo(each.ExtraInfo)}`;
            }

            GetContactListString += `</ul>`;
        });

        GetContactListString += `</span>
          </td>
        </tr>
`;

        return GetContactListString;
    };

    let PreSelectCode = "";

    if (Documents && Documents.length > 0) {
        PreSelectCode += `<!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#F4F5FB;background-color:#F4F5FB;margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F4F5FB;background-color:#F4F5FB;width:100%;"><tbody><tr>
        <td style="direction:ltr;font-size:0px;padding:10px;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:580px;"><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
         <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"></table></div><!--[if mso | IE]></td></tr></table><![endif]-->
         </td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
      <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#ffffff;background-color:#ffffff;margin:0px auto;border-radius:20px;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;border-radius:20px;"><tbody>
        <tr><td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:600px;" ><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"><tbody><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
        <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:20px;font-weight:500;line-height:30px;text-align:left;color:#8189A9;">

Documents

</div></td></tr><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
<div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:20px;text-align:left;color:#8189A9;">
<table style="width:100%">`;

        Documents.map(each => {
            if (each.filetypes === 1) {
                PreSelectCode += `<tr><td>
<a href="${each.ImageFullPath}" target="_blank" alt="${each.caption}">
<img width="30px" class="Slides" src="https://bomrng.com/public/zipIcon.png" />
</a>
</td>`;
            } else if (each.filetypes === 10) {
                PreSelectCode += `<tr><td>
<a href="${each.ImageFullPath}" target="_blank" alt="${each.caption}">
<img width="30px" class="Slides" src="https://bomrng.com/public/ContactCard.png"  />
</a>
</td>`;
            }
            else {
                PreSelectCode += `<tr><td>
<a href="${each.ImageFullPath}" target="_blank" alt="${each.caption}">
<img width="30px" class="Slides" src="https://bomrng.com/public/pdfIcon.png" />
</a>
</td>`;
            }
            PreSelectCode += `<td><a href="${each.ImageFullPath}" target="_blank" alt="${each.caption}">
                                ${each.caption}
                                </a>
                                </td></tr>`;
        })

        PreSelectCode += `</table>
        </div></td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><![endif]--></td></tr></tbody></table></div>
        `;
    }

    if (SpecialInstructions && SpecialInstructions !== "") {
        PreSelectCode += `<!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#F4F5FB;background-color:#F4F5FB;margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F4F5FB;background-color:#F4F5FB;width:100%;"><tbody><tr>
        <td style="direction:ltr;font-size:0px;padding:10px;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:580px;"><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
         <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"></table></div><!--[if mso | IE]></td></tr></table><![endif]-->
         </td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
      <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#ffffff;background-color:#ffffff;margin:0px auto;border-radius:20px;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;border-radius:20px;"><tbody>
        <tr><td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:600px;" ><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"><tbody><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
        <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:20px;font-weight:500;line-height:30px;text-align:left;color:#8189A9;">

Special Instruction

</div></td></tr><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
<div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:20px;text-align:left;color:#8189A9;">
                          ${SpecialInstructions}
</div></td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><![endif]--></td></tr></tbody></table></div>`;
    }

    if (PreSelect && PreSelect.length > 0) {

        PreSelectCode += `<!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#F4F5FB;background-color:#F4F5FB;margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F4F5FB;background-color:#F4F5FB;width:100%;"><tbody><tr>
        <td style="direction:ltr;font-size:0px;padding:10px;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:580px;"><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
         <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"></table></div><!--[if mso | IE]></td></tr></table><![endif]-->
         </td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
      <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#ffffff;background-color:#ffffff;margin:0px auto;border-radius:20px;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;border-radius:20px;"><tbody>
        <tr><td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:600px;" ><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"><tbody><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
        <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:20px;font-weight:500;line-height:30px;text-align:left;color:#8189A9;">

Special Instruction

</div></td></tr><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
<div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:20px;text-align:left;color:#8189A9;">`;

        PreSelect.map((eachPreSelect, indd) => {
            // console.log("eachPreSelect", indd, eachPreSelect)
            PreSelectCode += `<h2>${eachPreSelect.field1}</h2>
<table class="attributes" width="100%" cellpadding="0" cellspacing="0" role="presentation">
  <tr>
    <td class="attributes_content">
      <table width="100%" cellpadding="0" cellspacing="0" role="presentation">
${GetContactList(eachPreSelect.ContactList)}
</table>
    </td>
  </tr>
</table>
`;
        });

        PreSelectCode += `
        </div></td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><![endif]--></td></tr></tbody></table></div>
        `;
    }

    let ShowButton = "";
    if (ActionButtonShow) {
        ShowButton = `
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
            <tbody><tr><td align="center" bgcolor="#2e58ff" role="presentation" style="border:none;border-radius:30px;cursor:auto;mso-padding-alt:10px 25px;background:#2e58ff;" valign="middle">
            <a href="${ActionButton}" style="display: inline-block; background: #2e58ff; color: #ffffff; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; line-height: 30px; margin: 0; text-decoration: none; text-transform: uppercase; padding: 10px 25px; mso-padding-alt: 0px; border-radius: 30px;" target="_blank"> 
            ${ActionButtonTitle}</a></td></tr></tbody></table>
            `;
    }

    let ImagesBlock = "";

    const ImagesBlocks = imagess => {
        let ImageList = "";
        imagess.map((image, ind) => {
            if (image.MediaContentType && image.MediaContentType.includes("video")) {

                ImageList += `<table width="100%" style="max-width:230px;"><tr><td>
<a href="${image.ImageFullPath}" target="_blank">
${image.caption}
<video width="220" controls>
<source width="220px" class="Slides" src="${image.ImageFullPath}" type="${image.MediaContentType}">
</video>
</a>
</td>
</tr></table>`;

            } else {
                ImageList += `<table width="100%" style="max-width:230px;"><tr><td>
<a href="${image.ImageFullPath}" target="_blank">
<img width="220px" class="Slides" src="${image.ImageFullPath}" />
</a>
</td>
</tr></table>`;
            }
        });
        return ImageList;
    };

    if (PhotoService && PhotoService.length > 0) {
        ImagesBlock += `<!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#F4F5FB;background-color:#F4F5FB;margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F4F5FB;background-color:#F4F5FB;width:100%;"><tbody><tr>
        <td style="direction:ltr;font-size:0px;padding:10px;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:580px;"><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
         <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"></table></div><!--[if mso | IE]></td></tr></table><![endif]-->
         </td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600">
      <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]--><div style="background:#ffffff;background-color:#ffffff;margin:0px auto;border-radius:20px;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;border-radius:20px;"><tbody>
        <tr><td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"><!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr>
<td class="" style="vertical-align:top;width:600px;" ><![endif]--><div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"><tbody><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
        <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:20px;font-weight:500;line-height:30px;text-align:left;color:#8189A9;">

Images / Video

</div></td></tr><tr><td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
<div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:20px;text-align:left;color:#8189A9;">`;
        ImagesBlock += `<div class="flex-container" align="center">${ImagesBlocks(PhotoService)}</div>`;
        ImagesBlock += `
        </div></td></tr></tbody></table></div><!--[if mso | IE]></td></tr></table><![endif]--></td></tr></tbody></table></div>
        `;
    }


    let Logo = "";
    if (ContractorLogo && ContractorLogo !== "") {

        Logo += `<a href="${ActionButton}" target="_blank">
        <img height="auto" src="${ContractorLogo}" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="150" alt="${ContractorName}" />
    </a>`;
    } else {
        Logo += `
        <div style="font-family:Nunito, Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:20px;text-align:left;color:#54595f;">
        <h1 style="margin: 0; font-size: 24px; line-height: 32px; line-height: normal; font-weight: bold; color: white">${ContractorName}</h1>
        </div>
        `;
    }



    return (`
    <!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <title>${Heading}</title>
    <!--[if !mso]><!-- -->
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style type="text/css">
        #outlook a {
            padding: 0;
        }

        body {
            margin: 0;
            padding: 0;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }

        table,
        td {
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }

        img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }

        p {
            display: block;
            margin: 13px 0;
        }


        .flex-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }

        .flexbox {
            width: 250px;
            margin: 10px;
        }

    </style>
    <!--[if mso]>
          <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
          </xml>
          <![endif]-->
    <!--[if lte mso 11]>
          <style type="text/css">
            .mj-outlook-group-fix { width:100% !important; }
          </style>
          <![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&amp;display=swap" rel="stylesheet" type="text/css" />
    <style type="text/css">
        @import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&amp;display=swap);
    </style>
    <!--<![endif]-->
    <style type="text/css">
        @media only screen and (min-width:480px) {
            .mj-column-per-100 {
                width: 100% !important;
                max-width: 100%;
            }
        }
    </style>
    <style type="text/css">
        @media only screen and (max-width:480px) {
            table.mj-full-width-mobile {
                width: 100% !important;
            }

            td.mj-full-width-mobile {
                width: auto !important;
            }
        }
    </style>
    <style type="text/css">
        a,
        span,
        td,
        th {
            -webkit-font-smoothing: antialiased !important;
            -moz-osx-font-smoothing: grayscale !important;
        }


    </style>
</head>

<body style="background-color:#F4F5FB;">

    <div style="background-color:#F4F5FB;">
        <!--[if mso | IE]>
    <table
       align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
    >
      <tr>
        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
    <![endif]-->
        <div style="margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                            <!--[if mso | IE]>
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                        <tr>

                            <td
                               class="" style="vertical-align:top;width:600px;"
                            >
                          <![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="font-size:0px;word-break:break-word;">
                                                <!--[if mso | IE]>

                                                <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td height="5" style="vertical-align:top;height:5px;">

                                            <![endif]-->
                                                <div style="height:5px;"> � </div>
                                                <!--[if mso | IE]>

                                                </td></tr></table>

                                            <![endif]-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--[if mso | IE]>
                            </td>

                        </tr>

                                  </table>
                                <![endif]-->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]>
        </td>
      </tr>
    </table>

    <table
       align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
    >
      <tr>
        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">

      <v:rect  style="width:600px;" xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false">
      <v:fill  origin="0.5, 0" position="0.5, 0" src="https://images.unsplash.com/photo-1494253109108-2e30c049369b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80" type="tile" />
      <v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0">
    <![endif]-->
        <div style="margin:0px auto;border-radius:20px;max-width:600px;">
            <div style="line-height:0;font-size:0;">
                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color: #384ad1; width: 100%; border-radius: 20px;">
                    <tbody>
                        <tr>
                            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                                <!--[if mso | IE]>
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                            <tr>

                                <td
                                   class="" style="vertical-align:top;width:600px;"
                                >
                              <![endif]-->
                                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                        <tbody>
                                            <tr>
                                                <td align="center" style="font-size:0px;padding:8px 0;word-break:break-word;">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                                                        <tbody>
                                                            <tr>
                                                                <td style="width:200px;">

                                                                    ${Logo}

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="font-size:0px;word-break:break-word;">
                                                    <!--[if mso | IE]>

                                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td height="250" style="vertical-align:top;height:250px;">

                                                <![endif]-->
                                                    <!--[if mso | IE]>

                                                    </td></tr></table>

                                                <![endif]-->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!--[if mso | IE]>
                                </td>

                            </tr>

                                      </table>
                                    <![endif]-->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--[if mso | IE]>
      </v:textbox>
    </v:rect>

        </td>
      </tr>
    </table>

    <table
       align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
    >
      <tr>
        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
    <![endif]-->
        <div style="background:#F4F5FB;background-color:#F4F5FB;margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F4F5FB;background-color:#F4F5FB;width:100%;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:10px;text-align:center;">
                            <!--[if mso | IE]>
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                        <tr>

                            <td
                               class="" style="vertical-align:top;width:580px;"
                            >
                          <![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                </table>
                            </div>
                            <!--[if mso | IE]>
                            </td>

                        </tr>

                                  </table>
                                <![endif]-->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]>
        </td>
      </tr>
    </table>

    <table
       align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
    >
      <tr>
        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
    <![endif]-->
        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;border-radius:20px;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;border-radius:20px;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                            <!--[if mso | IE]>
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                        <tr>

                            <td
                               class="" style="vertical-align:top;width:600px;"
                            >
                          <![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:20px;font-weight:500;line-height:30px;text-align:left;color:#8189A9;">
                                                    ${Heading}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:20px;text-align:left;color:#8189A9;">
                                                    <p>
                                                        ${Message}
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                ${ShowButton}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--[if mso | IE]>
                            </td>

                        </tr>

                                  </table>
                                <![endif]-->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        ${ImagesBlock}
        ${PreSelectCode}

        <!--[if mso | IE]>
        </td>
      </tr>
    </table>

    <table
       align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
    >
      <tr>
        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
    <![endif]-->
        <div style="background:#F4F5FB;background-color:#F4F5FB;margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#F4F5FB;background-color:#F4F5FB;width:100%;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:10px;text-align:center;">
                            <!--[if mso | IE]>
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                        <tr>

                            <td
                               class="" style="vertical-align:top;width:580px;"
                            >
                          <![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                </table>
                            </div>
                            <!--[if mso | IE]>
                            </td>

                        </tr>

                                  </table>
                                <![endif]-->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]>
        </td>
      </tr>
    </table>

    <table
       align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
    >
      <tr>
        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
    <![endif]-->
        <div style="background:#384ad1;background-color:#384ad1;margin:0px auto;border-radius:20px;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#384ad1;background-color:#384ad1;width:100%;border-radius:20px;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                            <!--[if mso | IE]>
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                        <tr>

                            <td
                               class="" style="vertical-align:top;width:600px;"
                            >
                          <![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <!--[if mso | IE]>
                                            <table
                                               align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                            >
                                              <tr>

                                                    <td>
                                                  <![endif]-->
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding:4px;">
                                                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:24px;">
                                                                    <tbody>
                                                                        <tr>

                                                                            <td style="font-size:0;height:24px;vertical-align:middle;width:24px;">
                                                                                <a href="${ServiceWebLink}" target="_blank" style="color: #0078be; text-decoration: none; font-weight: 500;">
                                                                                    <img alt="twitter-logo" src="${ServiceLogo}" style="border-radius:3px;display:block;" width="200" />
                                                                                </a>
                                                                            </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <!--[if mso | IE]>
                                                    </td>

                                                </tr>
                                              </table>
                                            <![endif]-->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:22px;text-align:center;color:#8189A9;">
                                                    ${ContractorName}
                                                    <br />${ContractorAddress}
                                                    <br />${ContractorPhone}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                <div style="font-family:Montserrat, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:22px;text-align:center;color:#8189A9;">
                                                
                                                Your contact information was added by the company to provide notifications. However, you can <a href="${UnsubscribeLink}">unsubscribe</a>. Please keep in mind that once unsubscribed cannot re-subscribe.
                                                
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--[if mso | IE]>
                            </td>

                        </tr>

                                  </table>
                                <![endif]-->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]>
        </td>
      </tr>
    </table>

    <table
       align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
    >
      <tr>
        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
    <![endif]-->
        <div style="margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                <tbody>
                    <tr>
                        <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                            <!--[if mso | IE]>
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                        <tr>

                            <td
                               class="" style="vertical-align:top;width:600px;"
                            >
                          <![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="font-size:0px;word-break:break-word;">
                                                <!--[if mso | IE]>

                                                <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td height="1" style="vertical-align:top;height:1px;">

                                            <![endif]-->
                                                <div style="height:1px;"> � </div>
                                                <!--[if mso | IE]>

                                                </td></tr></table>

                                            <![endif]-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--[if mso | IE]>
                            </td>

                        </tr>

                                  </table>
                                <![endif]-->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]>
        </td>
      </tr>
    </table>
    <![endif]-->
    </div>


</body>
</html>
`);
}
