import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Box, Card, CardHeader, CardContent, IconButton, Avatar
} from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
//import RenderModule from './RenderModule';
//import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    NewPageId, getAutoCompletedata,
    CustomerPostSearch, EleminateDuplicateObjects
} from '../utils/CommonGraphql';
//import { Calendar } from 'react-feather';
import Calendar from '../Calendar/Calendar';
import DrawerShow from '../utils/DrawerModule';
import { TimeStampEndofDay } from '../utils/DateTime';
import ShiftDetails from '../Setup/ShiftDetails';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red } from '@mui/material/colors';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const CalendarBox = (props) => {
    // const classes = useStyles();
    const UUid = NewPageId("Drwr");
    const [DrawerStatus, OpenDrawer] = useState(false);
    const initialState = {
        ActiveShift: [],
        ShiftShow: [],
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('CalendarGrid.props', props);
        GetDatafromDB("ESC", props.CompanyId, "Shift");

    }, [])

    useEffect(() => {

       // console.log('CalendarGrid.ShiftShow', state.ShiftShow);
        if (props.PutinCommonState && state.ShiftShow.length > 0) {
            let ShiftShow = EleminateDuplicateObjects(state.ShiftShow, "key");
            props.PutinCommonState({ itemvalue: ShiftShow, itemname: "ShiftShow" })
        }

    }, [state.ShiftShow])

    const GetDatafromDB = async (Category, CompanyId, ArrayName) => {

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId);
        PersonShifts(NewData.Shift)
        console.log("CalendarGrid.NewData", NewData);
    }

    const PersonShifts = (Shift) => {

        let today = new Date();
        let yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)

        let pk = "";
        let startdate = `${TimeStampEndofDay(yesterday)}-1-1`;
        let enddate = "3000-02-01T23:59:59.000-1-1";
       // console.log("CalendarGrid.startdate", startdate);

        let ShiftShow = [];
        let ActiveShift = [];
        let DataJSON = "";

        if (Shift && Shift.length > 0) {
            Shift.map(shift => {
                if (shift.NewTeamId) {
                    pk = `${props.ActiveKey}::${props.CompanyId}::${shift.NewTeamId}`
                    FindActiveShift(pk, startdate, enddate).then(CustomerPresent => {
                       // console.log("CalendarGrid.ActiveShift1", CustomerPresent);

                        if (CustomerPresent && CustomerPresent.length > 0) {
                            CustomerPresent.map(each => {
                                DataJSON = JSON.parse(each.dataJSON)
                               // console.log("CalendarGrid.ActiveShift2", DataJSON.sk1);
                                ActiveShift.push({
                                    field1: DataJSON.field1,
                                    field2: DataJSON.field2,
                                    CompanyId: shift.CompanyId,
                                    Duplicate: each.sk1,
                                    key: shift.key,
                                    Category: "shift",
                                    Event: shift
                                })

                                ShiftShow.push({
                                    ...shift,
                                    StartDate: DataJSON.StartDate,
                                    EndDate: DataJSON.EndDate,
                                    ScheduleType: DataJSON.ScheduleType
                                })
                            })
                        }
                      //  console.log("CalendarGrid.ActiveShift3", ActiveShift);
                        
                        setState(prevState => ({
                            ...prevState,
                            ActiveShift: ActiveShift,
                            ShiftShow: ShiftShow
                        }))

                    })
                }
            })
        }
    }

    const FindActiveShift = async (pk, startdate, enddate) => {

        let what = await CustomerPostSearch(pk, startdate, enddate)

        return what;
    }

    const onSelectEvent = event => {
      //  console.log("CalendarGrid.onSelectEvent", event);

        setState(prevState => ({
            ...prevState,
            Event: event,
            DetailComponent: "ESCTeamEdit",
            DrawerTitle: "Notification Policy Details"
        }));

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        GetDatafromDB("ESC", props.CompanyId, "Shift");
        OpenDrawer(false);
    }

    return state.ActiveShift && (
        <Box sx={{ m: 1 }}>
            <Grid container spacing={1} >
                {state.ActiveShift.map((each, indd) => (
                    <Grid key={indd + "gr11"} item md={6} xs={12}>
                        <Card key={indd + "crd1"}>
                            <CardHeader
                                title={each.field1}
                                subheader={each.field2}
                                action={
                                    <IconButton aria-label="settings"
                                        onClick={() => onSelectEvent(each)}
                                        size="large">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        <CalendarMonthIcon />
                                    </Avatar>
                                }


                            />

                            <CardContent>
                                <ShiftDetails
                                    Event={each.Event}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.DrawerTitle}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    Event: state.Event,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    CustomerType: props.CustomerType,
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </Box>
    );
};

export default CalendarBox;

