import React, { useState, useEffect } from 'react';
import {
    Box, Container, Backdrop, CircularProgress, Grid,
    CardHeader, Card, Button, CardContent
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import DocumentToolbar from '../components/DocumentToolbar';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import {
    NewPageId,
    MiddlePostSearch,
    CustomerPostSearch,
    ContreactorPostSearch,
    getAutoCompletedata
} from '../utils/CommonGraphql';
//import SRList from '../List/SRColumn';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime'
//import { API, graphqlOperation } from "aws-amplify";
//import { onBatchInputs } from '../graphql/subscriptions';
import SRColumn from '../List/SRColumn';
//import SubscriptionList from '../Setup/SubscriptionList';
import ListItems from '../List/ListItems';
import ListModule from '../List/ListModule';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

//let DateNow = new Date();
var EndDate = adjustedTime(1, "End");
let StartDate = adjustedTime(-30, "Start");

const DocumentPage = (props) => {

    let query = useQuery();

    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch();
    const NowEditing = useSelector(state => state.NowEditing)
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const ContractorData = useSelector(state => state.ContractorData);
    const cid = useSelector(state => state.cid);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 12,
        //ListType: "ListModule",
        ListType: "ListModule",
        CompanyId: props.CompanyId,
        pk1: props.CompanyId,
        propss: {},
        key: NewPageId("SRList"),
        DataBack: [],
        StartDate: StartDate,
        EndDate: EndDate,
        DocumentType: "Any",
        Data: [],
        TotalData: []
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        // let datatestt = [
        //     //{ gsi2Sk2: `Post::Progress::0` },
        //     //{ gsi2Sk2: `Post::Progress::0` },
        //     //{ gsi2Sk2: `Post::Progress::100` },
        //     //{ gsi2Sk2: `Post::Progress::75` },
        //     //{ gsi2Sk2: `Post::Progress::0` },
        //     //{ gsi2Sk2: `Post::Progress::25` },
        //     //{ gsi2Sk2: `Post::Progress::50` },
        //     { gsi2Sk2: 0 },
        //     { gsi2Sk2: 0 },
        //     { gsi2Sk2: 100 },
        //     { gsi2Sk2: 75 },
        //     { gsi2Sk2: 50 },
        //     { gsi2Sk2: 0 },
        //     { gsi2Sk2: 110 },
        // ];

        //// if (findsk.Sort && AutoSelectData.length > 0 && AutoSelectData[0].field1 && AutoSelectData[0].field1 !== "") {
        // datatestt.sort((a, b) => (a["gsi2Sk2"] > b["gsi2Sk2"]) ? 1 : ((b["gsi2Sk2"] > a["gsi2Sk2"]) ? -1 : 0))
        ////             } 

        // console.log('DocumentPage.datatestt', datatestt);

       // GetDocumentList(props.CompanyId, StartDate, EndDate);

        GetDatafromDB("Policy", props.CompanyId)

    }, [])

    useEffect(() => {

        if (TriggerUpdate === true) {
            console.log("DocumentPage.TriggerUpdate", TriggerUpdate);
            GetDatafromDB("Policy", props.CompanyId)
            dispatch({ type: 'TRIGGER_UPDATE', payload: false });
        }

    }, [TriggerUpdate]);

    useEffect(() => {

        if (state.DocumentType !== "Any" && state.TotalData.length > 0) {

            let NarrowData = state.TotalData.filter(x => x.gsi2Sk2.includes(state.DocumentType))
            console.log("DocumentPage.NarrowData", NarrowData);
            setState(prevState => ({
                ...prevState,
                Data: NarrowData
            }))

        }

        if (state.DocumentType === "Any") {

            setState(prevState => ({
                ...prevState,
                Data: state.TotalData
            }))

        }

    }, [state.DocumentType]);

    useEffect(() => {

        if (state.SearchWords !== "") {

            let NarrowData = state.TotalData.filter(x => x.SearchWords.toLowerCase().includes(state.SearchWords.toLowerCase()))

            setState(prevState => ({
                ...prevState,
                Data: NarrowData
            }))

        }

        if (state.SearchWords === "") {

            setState(prevState => ({
                ...prevState,
                Data: state.TotalData
            }))

        }

    }, [state.SearchWords]);

    const GetDatafromDB = async (Category, pk1) => {
        SetLoading(true);

        //let ContractorInfo = [];
        //if (UserData.CustomerType === "Contractor") {
        //    ContractorInfo = ContractorData;
        //}

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: "DataBack",
                    DuplicateArray: "RawData",
                    ArraySearchString: ":",
                    Sort: true,
                    Sortby: "field1"
                }
            ]
        };

        let NewData = await getAutoCompletedata(DataString, pk1);
        setState(prevState => ({
            ...prevState,
            ...NewData
        }))

        SetLoading(false);
        console.log("DocumentPage.NewData", NewData);
    }

    const GetDocumentList = async (pk1, startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        console.log("DocumentPage.DBinput", NowEditing, pk1, startDate, endDate);

        //if (NowEditing === "Contractor") {
        //    NewData = await ContreactorPostSearch("Doc::" + pk1, startDate, endDate);
        //} else {
        NewData = await MiddlePostSearch("Doc::" + pk1, startDate, endDate);
        // }

        console.log("DocumentPage.NewData1", NewData);
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                AutoSelectData.push(ShowString);
            })
        }

        setState(prevState => ({
            ...prevState,
            Data: AutoSelectData,
            TotalData: AutoSelectData
        }))

        SetLoading(false);
        console.log("DocumentPage.NewData2", AutoSelectData);
    }


    // Additional Information Box
    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Document Details",
            // SetupSubscription: true,
            DetailComponent: "ShowDetailsText",
            ItemData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        OpenDrawer(true);
        //}
    }

    // Additional Information Box
    const SendSubscription = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Document Details",
            // SetupSubscription: true,
            DetailComponent: "ShowDetailsText",
            SubData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        OpenDrawer(true);
        //}
    }

    const AddItem = (DetailComponent, Category) => {

        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Add Policy Document",
            SetupSubscription: true,
            DetailComponent: DetailComponent,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: Category,
        }))

        OpenDrawer(true);
    }

    const NewDocument = () => {

        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Add New Document",
            // SetupSubscription: true,
            DetailComponent: "ItemAddPage",
            DrawerKey: NewPageId("policydoc"),
            NewCategory: "Policy",
        }))

        OpenDrawer(true);
    }

    const CompanyData = (data) => {
        console.log("data", data);
        GetDatafromDB(state.Category, data.pk1);
    }

    const OpenNarrowSearch = () => {
        setState(prevState => ({
            ...prevState,
            DetailComponent: "NarrowSearch",
            ShowDetails: false,
            DetailsProps: {
                //title: add.field1,
                Category: state.Category,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,

                //ShowEditButton: false,
                //PreviewEdit: false,
                //Data: add
            },
            // key: add.key || NewKey
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        //    GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    const SortPref = sort => {
        console.log("DocumentPage.sort", sort);
        if (sort.StartDate !== state.StartDate || sort.EndDate !== state.EndDate) {

           // GetDocumentList(props.CompanyId, sort.StartDate, sort.EndDate);

            setState(prevState => ({
                ...prevState,
                StartDate: sort.StartDate,
                EndDate: sort.EndDate,
                DocumentType: sort.DocumentType
            }));

        } else {

            setState(prevState => ({
                ...prevState,
                DocumentType: sort.DocumentType
            }));
        }

    }

    const SetValue = (value, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    console.log("DocumentPage.state", state);
    console.log("DocumentPage.props", props);

    return (
        <>
            <Grid container spacing={2} >

                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Policy Document List"
                            // subheader={props.CompanyName}
                            action={
                                <Button
                                    onClick={() => NewDocument()}
                                    color="primary"
                                    variant="contained"
                                >
                                    Add Document
                                </Button>
                            }
                        />
                        <CardContent>

                    {state.DataBack && (

                    <ListModule
                        title="Policy Documents"
                      //  ShowDetailLink={false}
                                    AllowDelete={true}
                                    ShowDetailLink={true}
                                    DetailLink={(e) => AddBox(e)}
                        disabled={false}
                        DataBack={state.DataBack}
                        />
                    )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.NewCategory}
                DrawerTitle={state.DrawerTitle}
                propss={{
                    Category: state.NewCategory,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e),
                    TriggerUpdate: true,
                    SetupSubscription: state.SetupSubscription,
                    Data: state.ItemData,
                    SubData: state.SubData,
                    id: state.DrawerKey,
                    // AddItem: () => AddItem("ItemAddPage", "Person")
                }}
                key={state.DrawerKey}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </>
    );
};

export default DocumentPage;
