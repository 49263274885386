import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useLocation, Link } from 'react-router-dom';
//import { useLocation } from "react-router-dom"
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Container,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { NewPageId } from '../utils/CommonGraphql';
import { useDispatch } from 'react-redux';
import {
    CompanyFrontPage,
    DeveloperFrontPage,
    ContractorFrontPage, HQFrontPage,
    ServiceLanding,
    SubFrontPage,
    OwnerFrontPage,
    CheckinSubFrontPage,
    SmallCheckinSubFrontPage,
    SmallOwnerFrontPage,
    ServiceOwnerFrontPage,
    MultipleAccountFrontPage
} from '../Company/TabsList';
import Page from '../components/Page';
import RenderModule from '../utils/RenderModule';

const FrontPage = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    const [SelectCompany, SetSelectCompany] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const ServiceLandingPage = useSelector(state => state.ServiceLandingPage);
    const AccountsList = useSelector(state => state.AccountsList);
    const WebsiteType = useSelector(state => state.WebsiteType);
    console.log('FrontPage.ServiceLandingPage', ServiceLandingPage);
    console.log('FrontPage.props', props);
    const items = useLocation();

    const initialState = {
        PopulatePage: [],
        Newpageiddd: ""
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        NewValues(ServiceLandingPage)
    }, [UserData])

    useEffect(() => {
        NewValues(ServiceLandingPage)
    }, [props.NewPgId])


    const NewValues = (ShowServiceLandingPage) => {

        let Newpageiddd = NewPageId("page");
        let iitt = 0;

        dispatch({ type: 'NOW_EDITING', payload: UserData.CustomerType });

        let propss = {
            CompanyId: UserData.CompanyId,
            CompanyName: UserData.CompanyName,
            UserEditor: UserData.CustomerType,
            NowEditing: UserData.CustomerType,
            CustomerType: UserData.CustomerType,
            ContractorId: ContractorData.CompanyId,
            Activefield1: UserData.field1,
            ActiveKey: UserData.sk1,
            // CompanyName: UserData.CompanyName,
            // ActiveCategory: props.ActiveCategory,
        };

        console.log('FrontPage.AccountsList', AccountsList);
        console.log('FrontPage.propss.var', UserData.CustomerType, WebsiteType );

        let PopulatePage = CompanyFrontPage(propss);

        if (ShowServiceLandingPage === true) {

            PopulatePage = ServiceLanding(propss);

        } else {
            //

                switch (UserData.CustomerType) {
                    case "Contractor":

                        if (UserData.Level > 800) {
                            PopulatePage = DeveloperFrontPage(propss);
                            iitt = 1;
                        } else {

                            if (WebsiteType === "ContractorCheckIn") {
                                PopulatePage = OwnerFrontPage(propss);
                                iitt = 2;

                            } else if (WebsiteType === "ServiceWithText") {
                                PopulatePage = ServiceOwnerFrontPage(propss);     
                                iitt = 3;

                            } else {
                                PopulatePage = SmallOwnerFrontPage(propss);
                                iitt = 4;
                            }

                        }
                        break;
                    case "Company":
                        
                        if (WebsiteType === "ContractorCheckIn") {
                            PopulatePage = CheckinSubFrontPage(propss);
                            iitt = 5;

                        } else if (WebsiteType === "ServiceWithText") {

                            if (AccountsList.length > 0) {
                                PopulatePage = MultipleAccountFrontPage(propss);
                                iitt = 61;
                            } else {
                                PopulatePage = ServiceOwnerFrontPage(propss); 
                                iitt = 62;
                            }
                            
                            

                        } else {
                            PopulatePage = SmallCheckinSubFrontPage(propss);
                            iitt = 7;
                        }

                        break;
                    case "Sub":
                        PopulatePage = CheckinSubFrontPage(propss);
                        iitt = 8;
                        break;
                    default:
                        PopulatePage = CheckinSubFrontPage(propss);
                        iitt = 9;
                }

        }

        // PopulatePage = ContractorFrontPage(propss); MultipleAccountFrontPage

        console.log('FrontPage.PopulatePage', iitt, PopulatePage);

        setState(prevState => ({
            ...prevState,
            PopulatePage: PopulatePage,
            Newpageiddd: Newpageiddd
        }));

    }

    const CommonState = (item) => {

        setState(prevState => ({
            ...prevState,
            [item.itemname]: item.itemvalue
        }));

    }

    console.log('FrontPage.state', state);
    console.log('FrontPage.props', props);
    //console.log('FrontPage.items.state.item', items.state.item);

    return (

        <Page
            title={UserData.ContractorName}
            key={state.Newpageiddd + "page"}
        >
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Typography variant="h2">
                        {UserData.CompanyName}
                    </Typography>
                </Grid>

                {state.PopulatePage.map((each, indd) => (
                    <Grid item xs={12} lg={each.size} key={state.Newpageiddd + "grd" + indd} >
                        {each.Category && (<RenderModule
                            key={state.Newpageiddd + "page" + indd}
                            id={state.Newpageiddd + "idd" + indd}
                            SearchWords={state.SearchWords}
                            ShiftShow={state.ShiftShow}
                            PutinCommonState={(e) => CommonState(e)}
                            Category={each.Category}
                            {...each}
                        />)}
                    </Grid>
                ))}
            </Grid>
        </Page>

    );
};


export default FrontPage;
