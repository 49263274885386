import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
//import AccountView from './views/account/AccountView';
import ItemAddPage from './Add/ItemAddPage';
//import CustomerListView from './views/customer/CustomerListView';
//import DashboardView from './views/reports/DashboardView';
import FrontPage from './components/FrontPage'
import ListItems from './List/ListItems';
//import CreateForm from './DynamicForm/FormCreatorClass';
import NotFoundView from './views/errors/NotFoundView';
//import ProductListView from './views/product/ProductListView';
//import Calendar from './Calendar/Calendar';
//import RegisterView from './views/auth/RegisterView';
//import SettingsView from './views/settings/SettingsView';
import StartPage from './views/auth/LoginView';
import Unsubscribe from './views/auth/Unsubscribe';
import ESCSetup from './Setup/ESCMinSetup';
import Setup from './Setup/Setup';
import CompanyPage from './Company/CompanyPage';
import SRPage from './List/SRPage';
import ServiceLandingPage from './NewService/ServiceLandingPage';
import QuickView from './NewService/QuickView';
import CallInLanding from './NewService/CallInLanding';
import InvitePerson from './NewService/InvitePerson'; 
import PaymentPage from './NewService/PaymentPage';
import ShowMenuComponent from './components/ShowMenuComponent';
import OwnerSignUpWiz from './MainWebsite/OwnerSignUpWiz';
import ContractorSignup from './MainWebsite/ContractorSignUpWiz';
import Loadable from './ui-component/Loadable';
//import Unsubscribe from './NewService/ServiceLandingPage';
import { NewPageId } from './utils/CommonGraphql'; 
import DemoInputPage from './MainWebsite/DemoInputPage' 
import CheckInDemo from './Setup/CheckInDemo';
import SubscriptionSignUp from './Payment/SubscriptionSignUp';

//const ShowMenuComponent = Loadable(lazy(() => import('./components/ShowMenuComponent')));


const routes = (isLoggedIn) => [
    {
        path: 'app',
        
        element: isLoggedIn ? <DashboardLayout /> : <StartPage />,
        children: [
            { path: 'account', element: <ItemAddPage /> },
            { path: '', element: <Navigate to="/app/dashboard" /> },  
            { path: 'servicelist', element: <SRPage /> },
            {
                path: "page",
                children: [
                    { path: '/app/page/Branch', element: <ShowMenuComponent /> },
                    { path: '/app/page/:ShowMenuComponent', element: <ShowMenuComponent /> },
                ],
            },
            {
                path: "/app/company",
                children: [{ path: '/app/company/:CompanyId', element: <CompanyPage /> }],
            },
            
            {
                path: "list",
                children: [{ path: ':Category', element: <ListItems /> }],
            },
            {
                path: "add",
                children: [{ path: ':Category', element: <ItemAddPage /> }],
            },
            {
                path: "setup",
                children: [
                    { path: 'esc', element: <ESCSetup /> },
                    { path: 'pref/:Category', element: <Setup /> },
                ],
            },

            {
                path: "servicerequest",
                children: [{ path: '/app/servicerequest', element: <ServiceLandingPage /> }],
            },
            {
                path: "checkin",
                children: [{ path: '/app/checkin', element: <CallInLanding /> }],
            },           
            {
                path: "Payment",
                children: [{ path: '/app/Payment', element: <PaymentPage /> }],
            },           
            { path: 'dashboard', element: <FrontPage NewPgId={NewPageId("FrontPP")} /> }, 
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: '',
        element: !isLoggedIn ? <MainLayout /> : <Navigate to="app/dashboard" />,
        children: [
            { path: '', element: <StartPage /> },
            { path: 'login', element: <StartPage /> },
            { path: 'ServiceRequest/:pk/:sk', element: <ServiceLandingPage /> },
            { path: 'Invite/:CompanyName/:CompanyId', element: <InvitePerson /> },
            { path: 'QuickView/:Mobile', element: <QuickView /> },
            { path: 'SubscriptionSignUp/:cid', element: <SubscriptionSignUp /> },
            { path: 'checkin/:pk/:sk/:dir', element: <CallInLanding /> },
            { path: 'Payment/:pk/:sk/:dir', element: <PaymentPage /> },
            { path: 'Unsubscribe', element: <Unsubscribe /> },
            { path: '404', element: <NotFoundView /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: 'ContractorSignup',
        element: <ContractorSignup />,
    },
    {
        path: 'checkinsignup',
        element: <OwnerSignUpWiz />,
    },
    {
        path: "Payment",
        element: <PaymentPage />,
    }, 
    {
        path: 'demo/:type',
        element: <DemoInputPage />
    },    
    {
        path: 'Unsubscribe',
        element: <Unsubscribe />
    },
    {
        path: 'CheckInDemo',
        element: <CheckInDemo />
    },
];
//

export default routes;
