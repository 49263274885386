/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Card,
    CardContent,
} from '@mui/material';
// import { isMobile } from 'react-device-detect';
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';

//const GOOGLE_MAPS_API_KEY = 'AIzaSyCZPWSnel6lirA1J92d9yhC2yNNf3TRdK4';
const containerStyle = {
    width: '100%',
    height: '200px'
};

const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    //zoomControlOptions: {
    //    position: window.google.maps.ControlPosition.RIGHT_CENTER
    //}
};

function MyComponent(props) {
    const [userAddress, setUserAddress] = useState(null);
    const [GGPS, setGPS] = useState(null);
    const [map, setMap] = React.useState(null);
    const [image, setImage] = React.useState(null);
    const isMobile = true;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey:  process.env.REACT_APP_googleMapsApiKey
    })

    useEffect(() => {

        if (GGPS && GGPS !== null) {
            props.GPSfound(GGPS)
        }
        
    }, [GGPS])

    useEffect(() => {

       // GetGPS();

        if (props && props.defaultValue && props.defaultValue.lat !== "") {

            let GPSSSS = props.defaultValue

            let gpslink = `http://www.google.com/maps/place/${GPSSSS.lng},${GPSSSS.lat}`;

            console.log("GPSSS.gpslink", gpslink)

            let Location = {
                "lng": Number(GPSSSS.lng),
                "lat": Number(GPSSSS.lat)
            }

            //Location = {
            //    "lng": GPSSSS.lat,
            //    "lat": GPSSSS.lng
            //}

            setUserAddress(Location)

            setGPS({ Location: Location, gpslink: gpslink })

        }

    }, [props.defaultValue])

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const mapRef = React.useRef();

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    //const GetGPS = () => {
    //    navigator.geolocation.getCurrentPosition((position) => {
    //        const { latitude, longitude } = position.coords;
    //        let gpslink = `http://www.google.com/maps/place/${latitude},${longitude}`;

    //        let Location = {
    //            "lng": longitude,
    //            "lat": latitude
    //        }

    //        setGPS({ Location: Location, gpslink: gpslink })
    //        setUserAddress({ lat: latitude, lng: longitude })
    //    });
    //}

    //const TakePicture = () => {

    //    var canvas = document.getElementById("testingid");
    //   // var ctx = canvas.getContext("2d");
    //    var image = canvas.toDataURL("image/png");
    //    console.log("GPSSS.image", image)
    //    setImage(image)
    //}

    //  &&

    console.log("GPSSS-1", userAddress)
    console.log("GPSSS.props", props)

    return isMobile ? (

        <Card>
           
            <CardContent>

                <Grid container spacing={1}>
                    {/* 
                    <Grid item xs={12} >
                        <Typography>
                            Use follow button to mark the equipment or service location.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            onClick={() => GetGPS()}
                            variant="contained"
                            color="primary"
                        >
                            Mark GPS Location
                        </Button>
                    </Grid>
                    */}

                    {userAddress !== null && isLoaded ? (

                        <Grid item xs={12} >
                            
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={userAddress}
                                zoom={16}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                options={options}
                                onLoad={onMapLoad}
                            >
                                <Marker
                                    key="pressloccaatt2929"
                                    position={userAddress}
                                />
                            </GoogleMap>
                            
                        </Grid>

                    ) : null}

                </Grid>

            </CardContent>


        </Card>

    ) : (
        <Typography>
            Feature available only on Mobile Device
        </Typography>
    );
}
export default MyComponent;
