import { API } from "aws-amplify";


export const FindNumber = async (areaCode, CountryCode) => {
    try {

        var SMSstring = {
            "areaCode": areaCode,
            "CountryCode": CountryCode,
            "phoneNumber": "",
            "TwilioExecute": "NumberList"
        };
       
        console.log(
            "AddMessage.Completed",
            SMSstring
        );

        let LocalNumbers = await API.post("TwilioNumberList", "", {
            body: { Records: SMSstring }
        });

        return LocalNumbers;

    } catch (err) {
        console.log("FindNumber.err", err)
    }
}

//let NewJSON = {

//    phoneNumber: phoneNumber,
//    origin: 'twilio',
//    smsApplicationSid: '',
//    smsFallbackMethod: 'POST',
//    smsFallbackUrl: 'https://1pwx2ltag9.execute-api.us-east-1.amazonaws.com/Prod/message',
//    smsMethod: 'POST',
//    smsUrl: 'https://1pwx2ltag9.execute-api.us-east-1.amazonaws.com/Prod/message',
//    statusCallback: '',
//    statusCallbackMethod: 'POST'

//}

/*
response:

{
"available_phone_numbers": [
{
"address_requirements": "none",
"beta": false,
"capabilities": {
  "mms": true,
  "sms": false,
  "voice": true
},
"friendly_name": "(808) 925-1571",
"iso_country": "US",
"lata": "834",
"latitude": "19.720000",
"locality": "Hilo",
"longitude": "-155.090000",
"phone_number": "+18089251571",
"postal_code": "96720",
"rate_center": "HILO",
"region": "HI"
}
],
"uri": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/AvailablePhoneNumbers/US/Local.json"
}


smsUrl: 'https://www.your-sms-url.com/example',
voiceUrl: 'https://www.your-voice-url.com/example',
phoneNumber: '+15017122661'

"account_sid": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"address_requirements": "none",
"address_sid": "ADXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"beta": false,
"capabilities": {
"voice": true,
"sms": true,
"mms": true,
"fax": false
},
"emergency_status": "Active",
"emergency_address_sid": "ADXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"emergency_address_status": "registered",
"friendly_name": "friendly_name",

"origin": "origin",
"phone_number": "+18089255327",
"phoneNumber": '+15017122661'
"sms_application_sid": "APXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"sms_fallback_method": "GET",
"sms_fallback_url": "https://example.com",
"sms_method": "GET",
"sms_url": "https://example.com",
"smsUrl": 'https://www.your-sms-url.com/example',


"voice_application_sid": "APXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"voice_caller_id_lookup": false,
"voice_fallback_method": "GET",
"voice_fallback_url": "https://example.com",
"voice_method": "GET",
"voice_url": "https://example.com",
voiceUrl: 'https://www.your-voice-url.com/example',
"bundle_sid": ,
"voice_receive_mode": "voice",
}



  
  phoneNumber: '+15625612407',
  addressSid: 'ADd0dcf93f16459c5b85978e46f32da396',
  bundleSid: 'BUXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
  origin: 'twilio',
  smsApplicationSid: '',
  smsFallbackMethod: 'POST',
  smsFallbackUrl: 'https://1pwx2ltag9.execute-api.us-east-1.amazonaws.com/Prod/message',
  smsMethod: 'POST',
  smsUrl: 'https://1pwx2ltag9.execute-api.us-east-1.amazonaws.com/Prod/message',
  statusCallback: '',
  statusCallbackMethod: 'POST',
  trunkSid: null,
  voiceReceiveMode: undefined,
  voiceApplicationSid: null,
  voiceCallerIdLookup: false,
  voiceFallbackMethod: 'POST',
  voiceFallbackUrl: null,
  voiceMethod: 'POST',
  voiceUrl: null,
  emergencyStatus: 'Active',
  emergencyAddressSid: 'ADd0dcf93f16459c5b85978e46f32da396',
  emergencyAddressStatus: 'registered',
  


*/

//export const buyPhoneNumber = async (number) => {
//    const result = await fetch("https://api.twilio.com/2010-04-01/Accounts/AC7a95b1f2632898cb334915c106f0587f/IncomingPhoneNumbers.json?phoneNumber=" + number, {
//        "method": "POST",
//        "headers": {
//            "x-rapidapi-host": "api.twilio.com",
//            "x-rapidapi-key": "1181491619a1e7c61a19eb73f79000f7",
//            "content-type": "application/x-www-form-urlencoded"
//        },
//    });
//    const body = await result.json();
//    console.log('Twilio.Registor.buyPhoneNumber', body);
//  //  return body.phoneNumber?.sid;
//}

//export const PhoneNumberMessagingService = (phoneNumberSid) => {

//    try {

//        client.messaging.v1.services('MG5e7a72e52da831762ff67fba3c2f47cc')
//            .phoneNumbers
//            .create({
//                phoneNumberSid: phoneNumberSid
//            })
//            .then(phone_number => {
//                console.log("Twilio.Campaing1", phone_number);
//                return true
//            });

//    } catch (err) {
//        console.log("PhoneNumberMessagingService.err", err)
//    }
//}


//export const RegistorNumber = (phoneNumber) => {

//    try {

//        client.incomingPhoneNumbers
//            .create({ phoneNumber: phoneNumber })
//            //.then(incoming_phone_number => {
//            //    console.log("Twilio.Registor1", incoming_phone_number);
//            //    return incoming_phone_number.sid;
//            //}
//            //);

//    } catch (err) {
//        console.log("RegistorNumber.err", err)
//    }

//}


/*



{
"account_sid": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"address_requirements": "none",
"address_sid": "ADXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"api_version": "2010-04-01",
"beta": false,
"capabilities": {
"voice": true,
"sms": false,
"mms": true,
"fax": false
},
"date_created": "Thu, 30 Jul 2015 23:19:04 +0000",
"date_updated": "Thu, 30 Jul 2015 23:19:04 +0000",
"emergency_status": "Active",
"emergency_address_sid": "ADXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"emergency_address_status": "registered",
"friendly_name": "friendly_name",
"identity_sid": "RIXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"origin": "origin",
"phone_number": "+18089255327",
"sid": "PNXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"sms_application_sid": "APXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"sms_fallback_method": "GET",
"sms_fallback_url": "https://example.com",
"sms_method": "GET",
"sms_url": "https://example.com",
"status_callback": "https://example.com",
"status_callback_method": "GET",
"trunk_sid": null,
"uri": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/IncomingPhoneNumbers/PNXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX.json",
"voice_application_sid": "APXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"voice_caller_id_lookup": false,
"voice_fallback_method": "GET",
"voice_fallback_url": "https://example.com",
"voice_method": "GET",
"voice_url": "https://example.com",
"bundle_sid": "BUXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
"voice_receive_mode": "voice",
"status": "in-use"
}




*/