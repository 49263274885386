import React from 'react';
import { useSelector } from 'react-redux';

const Logo = (props) => {

    const SetLogo = useSelector(state => state.SetLogo);

    return (
    <img
      alt="Logo"
            src={SetLogo}
          height="50px"
      {...props}
    />
  );
};

export default Logo;
