import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Grid
} from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import SelectCategory from '../components/SelectCategory';
import { NewPageId } from '../utils/CommonGraphql';
import AutocompleteModule from '../components/AutoComplete';
import { useDispatch } from 'react-redux';

const NewKey = NewPageId("Requestor")

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const AddRequestor = props => {
  let Message = "";
  if (props.CustomerType === "Contractor") {
    if (props.PersonId === "") {
      if (props.cid === "") {
        Message = "Select Company";
      } else {
        Message = "Select Person";
      }
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    //maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inline: {
    display: 'inline',
  },
}));


const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const [Requestor, SetRequestor] = useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  const dispatch = useDispatch()

  const initialState = {
    CompanyId: "",
    RequesterList: [],
    CustomerType: "Company",
    ShowPersonalAuto: false,
    ShowCompanyAuto: false
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mounted = true;
    let CustomerType = "Contractor";
    let NewCid = "";
    let CompanyName = "";
    let ShowPersonalAuto = false;

    if (UserData && UserData.UserInfo) {
      CustomerType = UserData.UserInfo.CustomerType
    }

    if (cid && cid.CompanyId) {
      NewCid = cid.CompanyId;
      CompanyName = cid.CompanyName;
      ShowPersonalAuto = true;
    }

    if (rest && rest.CompanyId) {
      NewCid = rest.CompanyId;
      CompanyName = rest.CompanyName;
      ShowPersonalAuto = true;
    }

    if (mounted) {
      setState(prevState => ({
        ...prevState,
        CompanyId: NewCid,
        CustomerType: CustomerType,
        CompanyName: CompanyName,
        ShowPersonalAuto: ShowPersonalAuto
      }));
    }

    ShowCompanySelect(CustomerType);

    return () => mounted = false;

   

  }, [rest.CompanyId])

  const ShowCompanySelect = (CustomerType) => {

    if (ContractorData.ContractorId && CustomerType === "Contractor" && state.CompanyId === "") {
      setState(prevState => ({...prevState, ShowCompanyAuto: true }));
    }

  }

  const SelectedCompany = (e) => {
    console.log("company", e)

    setState(prevState => ({
      ...prevState,
      CompanyId: e.CompanyId,
      CompanyName: e.field1,
      ShowPersonalAuto: true,
      ShowCompanyAuto: false
    }));

    //set cid if company
   // if (UserData.UserInfo.CustomerType === "Company") {
      dispatch({ type: 'SET_CID', payload: e });
  //  }

    //if (rest.SelectedPerson) {
    //  rest.SelectedPerson(e)
    //}

  }

  const SelectedPersons = (e) => {
    console.log("SelectedPerson", e)

    setState(prevState => ({
      ...prevState,
      RequesterList: [e] //[...state.RequesterList, e],
    }));

    if (rest && rest.requestorid) {
      rest.requestorid(e)
    }

  }

  console.log("Requestor.ContractorData", ContractorData)
  console.log("Requestor.UserData", UserData)
  console.log("Requestor.cid", cid)
  console.log("Requestor.state", state)
  console.log("Requestor.props", rest)

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          className={classes.root}
        >
          <List >
            <ListItem alignItems="flex-start" key={"lisstsksksk"}>
              <ListItemAvatar>
                <Avatar alt={UserData.field1} src={UserData.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={UserData.field1}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {UserData.field2}
                    </Typography>
                    {` - ${moment().format('hh:mm A')}`}
                  </React.Fragment>
                }
              />
            </ListItem>
            {state.RequesterList.map((each, ind) => (
              <ListItem alignItems="flex-start" key={"lisst" + ind}>
                <ListItemAvatar>
                  <Avatar alt={each.field1} src={each.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={each.field1}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {each.field2}
                      </Typography>
                      {` - ${moment().format('hh:mm A')}`}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>

        <Grid container justifyContent="center" spacing={2}>
          {state.ShowCompanyAuto && (
            <Grid xs={12} item>
              <AutocompleteModule
                key={NewKey + "Company"}
                id={NewKey + "Company2"}
                FormType="Company"
                title="Company Requesting Service"
                Category="Company"
                CompanyId={ContractorData.ContractorId}
                Selection={(e) => SelectedCompany(e)}
              />
              </Grid>
        )}

        {!state.ShowCompanyAuto && state.CustomerType === "Contractor" && (
            <>
              <Grid xs={12} item>
                <Typography variant="h6" gutterBottom>{`Current Selected: ${state.CompanyName}`}</Typography>
              </Grid>
                <Grid xs={12} item>
          <Button
            onClick={() => setState(prevState => ({ ...prevState, ShowCompanyAuto: true }))}
                  variant="contained" color="primary"
          >
                  Change Company
          </Button>
              </Grid>
            </>
        )}

        {!state.ShowCompanyAuto && state.CustomerType === "Contractor" && state.ShowPersonalAuto && (
                  <Grid xs={12} item>
          <AutocompleteModule
            key={NewKey + "Person"}
            id={NewKey + "Person2"}
            Category="Person"
            FormType="Person"
            title="Person Requesting Service"
            CompanyId={state.CompanyId || ""}
            Selection={(e) => SelectedPersons(e)}
              />
            </Grid>
        )}
        </Grid>

      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
