import * as React from 'react';
//import { useTheme } from '@mui/material/styles';
//import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
//import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {
    Box, MobileStepper, Paper, Button,
    Typography,
    Stack,
    IconButton,
    Grid,
    CardHeader,
    Backdrop
} from '@mui/material';
import ReactPlayer from 'react-player';
import './ImageCenter.css'
import { isMobile } from 'react-device-detect';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    imagg: {
        maxHeight: '350px',
        maxWidth: '350px',
        alignItems: "center",
        justifyContent: "center"
    },
    imaggbig: {        
        maxHeight: '90%',
        maxWidth: '90%',
        alignItems: "center",
        justifyContent: "center",
    },
    
    alignItemsAndJustifyContent: {
        //width: 500,
        //height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //  backgroundColor: 'pink',
    },
}));

//'700px',

function SwipeableTextMobileStepper(props) {
    const theme = {};
    const images = props.PhotoService;
    theme.direction = 'rtl';
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;
    const [open, setOpen] = React.useState(false);
    const [imageshow, Setimageshow] = React.useState(false);
    const [Videoshow, SetVideoshow] = React.useState(false);
    const [DisplayVideo, SetDisplayVideo] = React.useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ShowImage = (image) => {
        //  console.log("Gallery.image", image)
        SetDisplayVideo(false)
        Setimageshow(image)
        setOpen(true);
    };

    const ShowVideo = (image) => {
        // console.log("Gallery.image", image)
        SetDisplayVideo(true)
        SetVideoshow(image)
        setOpen(true);
    };

    const GalleryRoll = (step) => {

        if (step.MediaContentType && step.MediaContentType.includes("video")) {
            return (
                <IconButton
                    edge="end" aria-label="Show Image"
                    onClick={() => ShowVideo(step.ImageFullPath)}>
                    <ReactPlayer
                        url={step.ImageFullPath}
                        playing={false}
                    />
                </IconButton>
            )
        } else {

            return (
                <IconButton
                    edge="end" aria-label="Show Image"
                    onClick={() => ShowImage(step.ImageFullPath)}>
                    <img
                        src={step.ImageFullPath}
                        className={classes.imagg}
                        alt={step.ImageFullPath}
                    />
                </IconButton>
            )
        }
    }

   
   

    // console.log("Gallery.open", open, )

    return (
        <>
            <Box
                sx={{ maxWidth: 400, flexGrow: 1 }}
                style={{ alignItems: "center", justifyContent: "center" }}
            >
                <AutoPlaySwipeableViews
                    axis={'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >

                    {images.map((step, index) => (
                        <div key={index + "img"} className={classes.alignItemsAndJustifyContent}>
                            {Math.abs(activeStep - index) <= 2 ? (GalleryRoll(step)) : null}
                        </div>
                    ))
                    }
                </AutoPlaySwipeableViews>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    }
                />
            </Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: 35001
                }}
                style={{ zIndex: 35001 }}
                open={open}
                onClick={handleClose}
            >
                {DisplayVideo ? (
                    <ReactPlayer
                        url={Videoshow}
                        controls={true}

                    />
                ) : (
                        <div class="container">
                        
                        <img src={imageshow} className={classes.imaggbig} alt={imageshow} />
                            
                            <div class="centered">
                                {isMobile ?
                                ("Hold down image to save to device") :
                                ("Right Click on Image to Save Image as...")}
                                </div>

                        </div>
                )}
            </Backdrop>
        </>
    );
}

export default SwipeableTextMobileStepper;
