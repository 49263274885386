import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch, createSvcbotDb } from '../graphql/mutations';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
    Paper, Grid, Button, Box,
    Backdrop,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar, FormControlLabel, Switch, TextField
} from '@mui/material';
import {
    removeEmptyStringElements,
    PrimaryKeyDataGet,
    ContractorDataPerCat,
    NewPageId,
    FindLatestDate,
    getAutoCompletedata
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';
import MuiAlert from '@mui/material/Alert';
import SelectAssets from '../components/SelectAssets';
import AccountSetup from '../Setup/AccountSetup'; //
import PolicyAcknowledge from '../Setup/PolicyAcknowledge';
import { Buffer } from 'buffer';
import { DateTime } from "luxon";
import ShowYoutube from '../components/ShowYoutube'
//import { open } from 'node:fs';
//import { ContractorBlocks, CompanyBlocks, EmailTriggerBlocks } from '../Setup/UrgencyDetailOptions';

const AWS = require("aws-sdk");
// Initialize the Amazon Cognito credentials provider
AWS.config.region = "us-east-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:85823b95-577e-4643-aadf-86ec38a0c989",
});
const dynamodb = new AWS.DynamoDB.DocumentClient();

var GetDBData = (params) => {
    return new Promise((resolve, reject) => {
        dynamodb.query(params, function (err, data) {
            if (err) {
                console.log("errrr", err, err.stack);
                // an error occurred
            } else {
                console.log("databack DB", data)
                resolve(data);
            }// successful response
        });
    });
};

const GetData = async (pk1, sk1) => {
    try {
        let getItemParams2 = {
            //IndexName: "gsi1Pk2-gsi1Sk2-index",
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and #sk1 = :sk1",
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1",
            },
            ExpressionAttributeValues: {
                ":pk1": pk1,
                ":sk1": sk1,
            }
        };

        let EscPolicy = await GetDBData(getItemParams2);
        return EscPolicy.Items;

    } catch (err) {
        console.log('GetData err', err);
    }
};

function secondsToHms(dssss) {
    let ssd = Number(dssss);
    let d = (ssd / 1000);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    let worked = Math.round((h + (m / 60) * 100)) / 100;
    return worked;
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}



let CheckInQuestions = [
    {
        VariableInput: true,
        include: false,
        field1: 'What is the plan for today?',
        Category: 'plan',
        keywords: [Object],
        defaultValue: true,
        label: 'What is the plan for today?',
        PreSelect: false,
        key: 'plan'
    },
    {
        VariableInput: true,
        include: false,
        field1: 'What time will you be done for today? please make sure to checkout when you are leaving.',
        Category: 'endtime',
        keywords: [Object],
        defaultValue: true,
        label: 'What time will you be done for today? please make sure to checkout when you are leaving.',
        PreSelect: false,
        key: 'endtime'
    },
    {
        VariableInput: true,
        include: false,
        field1: 'Please share your location',
        Category: 'Location',
        keywords: [Object],
        defaultValue: true,
        label: 'Please share your location',
        PreSelect: false,
        key: 'Location'
    }
];

//let AccountSetups = [
//    {
//        VariableInput: true,
//        include: false,
//        field1: 'Account Setup',
//        Category: 'AccountSetup',
//        keywords: "",
//        defaultValue: true,
//        label: 'Account Setup',
//        PreSelect: false,
//        key: 'AccountSetup'
//    },   
//];

var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();

function EleminateDuplicateObjects(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}

export default function SimpleCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const WebsiteType = useSelector(state => state.WebsiteType);
    // const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    // const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const NowEditing = useSelector(state => state.NowEditing)
    // const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        SMSQuestionsNew: [],
        StepValueArray: [],
        RequestorInfo: false,
        SenderName: "",
        SenderCompany: "",
        Quote: false,
        DoneDone: false,
        SelectSystem: "CheckIn",
        WaitForEmail: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        try {

            console.log("NewCheckIn.props1", props);

            let SelectSystem = "CheckIn";

            if (WebsiteType === "ServiceWithText") {
                SelectSystem = "Service";
            }

            GetEachPolicy(props.SREvent)

            setState(prevState => ({
                ...prevState,
                ...props.SREvent,
              //  WaitForEmail: true,
                SREvent: props.SREvent,
                SelectSystem: SelectSystem
            }))

        } catch (err) { console.log("UrgencyDetails.useEffect.err", err) }

    }, [])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    const TriggerLambda = (event, from) => {
        try {
            console.log("NewServiceCall.TriggerLambda", from, event);

            API.post("PostToDB", "", {
                body: { Records: event }
            });
        } catch (err) { console.log("NewServiceCall.err", err); }
    }

    const StartTimer = (event) => {
        console.log("NewServiceCall.StartTimer", event);

        API.post("StartTimer", "", {
            body: { Records: event }
        });
    }

    const GetEachPolicy = (SREvent) => {

        try {
            // console.log("NewServiceCall.SREvent", SREvent);
            let ContractorPolicyFound = "";
            let PolicyId = "";
            let EndMessage = "";
            let FindPolicyraw = {};
            let CompanyPolicyFound = "";
            let PersonPolicyFound = "";
            let FinalPolicy = "";
            let SMSQuestions = [];
            let ShowVideo = false;

            //if (SREvent.QuestionsAsked
            //    && SREvent.QuestionsAsked.Questions
            //    && SREvent.QuestionsAsked.Questions.length > 0) {
            //    SMSQuestions = SREvent.QuestionsAsked.Questions

            //    let starting = SREvent.QuestionsAsked.Asked;
            //    if (starting) {
            //        setActiveStep(starting)
            //    }

            //} else {
            SMSQuestions = SREvent.SMSQuestions;
            // }

            let SMSQuestionsNew = [];
            let LoadComponenet = "";
            let SenderCompany = state.CompanyName;
            // let SenderName = UserData.field1;
            let From = UserData.email;
            let PolicyFlag = true;
            let newSMSQuestions = [];

            let Location = [{
                SenderCompany: SREvent.posterId.CompanyName,
                SenderName: SREvent.posterId.field1,
                LoadComponenet: "Location",
                Category: "Location",
                PhotoService: [],
                label: "Please confirm your current location and click on Next",
                field1: "Location",
                Documents: [],
                TimeStamp: currentTime,
                SenderCompanyType: "Company",
                To: SREvent.ContractorSMS,
                Body: "",
                key: "Location",
                id: "Location"
            }];

            let Document = [{
                Category: "Documents",
                field1: "Documents/Reports/Pictures/Drawings",
                label: "Documents",
                To: SREvent.ContractorSMS,
                id: "Document",
                key: "Document"
            }];

            let Assets = [{
                Category: "Assets",
                field1: "Please identify the Assets (Equipment, Location, etc) involved in this visit",
                label: "Assets (Equipment, Location, etc)",
                id: "Assets",
                key: "Assets"
            }];

            //let Locationss = SMSQuestions.findIndex(x => x.Category === "Location")
            //SMSQuestions.splice(0, Locationss)


            switch (props.dir) {

                case "Location":
                    let Locationss = SMSQuestions.findIndex(x => x.Category === "Location" || x.Category === "MarkLocation")

                    console.log("Locationss", Locationss);
                    //SMSQuestions = SMSQuestions.slice(1)
                    //console.log("Locationss.SMSQuestions", SMSQuestions);
                    if (Locationss > -1) {
                        SMSQuestions = SMSQuestions.slice(Locationss)
                        console.log("Locationss.SMSQuestions", SMSQuestions);
                    } else {
                        SMSQuestions = [...Location]
                    }
                    break;
                //case "MarkLocation":
                //    let Locationss = SMSQuestions.findIndex(x => x.Category === "MarkLocation")
                //    if (Locationss && Locationss > -1) {
                //        SMSQuestions.splice(0, Locationss)
                //    } else {
                //        SMSQuestions = [...Location]
                //    }
                //    break;
                case "Document":
                    let Documentsss = SMSQuestions.findIndex(x => x.Category === "Document")
                    if (Documentsss && Documentsss > -1) {
                        SMSQuestions.splice(0, Documentsss)
                    } else {
                        SMSQuestions = [...Document]
                    }
                    break;
                case "Report":
                    let Report = SMSQuestions.findIndex(x => x.Category === "Report")
                    if (Report && Report > -1) {
                        SMSQuestions.splice(0, Report)
                    } else {
                        SMSQuestions = [...Document]
                    }
                    break;
                case "Assets":
                    let Assetssss = SMSQuestions.findIndex(x => x.Category === "Assets")
                    if (Assetssss && Assetssss > -1) {
                        SMSQuestions.splice(0, Assetssss)
                    } else {
                        SMSQuestions = [...Assets]
                    }
                    break;
                case "CheckOut":
                    SMSQuestions = SREvent.CheckOutQuestions;
                    EndMessage = `Thanks for Checking-in. Please send text messages with picture and notes showing work progress during your shift. \n\nUse following link to check-out at the end of your shift. \n\n${SREvent.CheckOutLink} \n\nThank You`;
                    SREvent.Direction = "CheckOut";
                    break;
                case "newaccount":
                    SMSQuestions = SREvent.SMSQuestions;
                    if (WebsiteType === "ContractorCheckIn") {
                        ShowVideo = true;
                    }
                    break;
                case "PolicyAck":
                    SMSQuestions = SREvent.SMSQuestions;
                    break;
                default:
                    let QuestionsAsked = SREvent.QuestionsAsked;
                    let Asked = QuestionsAsked.Asked;
                    console.log("NewServiceCall.Asked", Asked);
                    SMSQuestions = SREvent.SMSQuestions.slice(Asked);
            }

            //SMSQuestions = [
            //    {
            //        "VariableInput": true,
            //        "include": false,
            //        "field1": "Please provide a short summary of the work completed.",
            //        "Category": "end",
            //        "keywords": {
            //            "ESC": []
            //        },
            //        "defaultValue": true,
            //        "label": "Please provide a short summary of the work completed.",
            //        "PreSelect": false,
            //        "key": "end"
            //    },
            //    {
            //        "VariableInput": true,
            //        "include": false,
            //        "field1": "Please select the Equipment, Location, Assets",
            //        "Category": "Assets",
            //        "keywords": {
            //            "ESC": [],
            //            "words": ","
            //        },
            //        "defaultValue": true,
            //        "key": "SMSNew_bkkJVSqJ"
            //    },

            //];


            console.log("NewServiceCall.SMSQuestions", SMSQuestions);


            if (SMSQuestions.length > 0) {
                SMSQuestions.map((each, indd) => {

                    switch (each.Category) {
                        case "Location":
                            LoadComponenet = "LocationBox";
                            break;
                        case "GPS":
                            LoadComponenet = "LocationBox";
                            break;
                        case "MarkLocation":
                            LoadComponenet = "LocationBox";
                            break;
                        case "Equipment":
                            LoadComponenet = "AssetEachBox";
                            break;
                        case "Images":
                            LoadComponenet = "UploadEachBox";
                            break;
                        case "pictures":
                            LoadComponenet = "UploadEachBox";
                            break;
                        case "Documents":
                            LoadComponenet = "UploadEachBox";
                            break;
                        case "Details":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "PO":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "Additional":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "Project":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "Warranty":
                            LoadComponenet = "DescriptionBox";
                            break;
                        case "YesNoBox":
                            LoadComponenet = "YesNoBox"; 
                            break;
                        case "Emergency":
                            LoadComponenet = "YesNoBox"; 
                            break;
                        case "Quote":
                            LoadComponenet = "YesNoBox";
                            break;
                        case "AccountSetup":
                            LoadComponenet = "AccountSetup";
                            break;
                        case "Assets":
                            LoadComponenet = "AssetEachBox";
                            break;
                        default:
                            LoadComponenet = "DescriptionBox";
                            break;
                    }

                    console.log("NewServiceCall.SMSQuestions2", each.key, indd);

                    SMSQuestionsNew.push({
                        ...each,
                        SenderCompany: state.SenderCompany,
                        SenderName: state.SenderName,
                        avatar: state.Senderavatar,
                        PhotoService: [],
                        Documents: [],
                        From: From,
                        TimeStamp: currentTime,
                        SenderCompanyType: "Company",
                        To: state.mobile,
                        Body: "",
                        LoadComponenet: LoadComponenet,
                        EndMessage: EndMessage,
                        KeyId: each.key + indd
                    })

                    console.log("NewServiceCall.SMSQuestionsNew", SMSQuestionsNew);
                })
            }
            // }

            setState(prevState => ({
                ...prevState,
                SREvent: SREvent,
                SMSQuestionsNew: SMSQuestionsNew,
                StepValueArray: SMSQuestionsNew,
                FinalPolicy: FinalPolicy,
                PolicyId: PolicyId,
                ContractorPolicyFound: ContractorPolicyFound,
                PolicyFlag: PolicyFlag,
                ShowVideo: ShowVideo
            }))


        } catch (err) { console.log("GetApplicablePolicies err", err) }
    };



    const AdjustData = () => {
        let StepValueArray1 = state.StepValueArray;
        let postHTML = state.postHTML;
        let postTEXT = state.postTEXT;
        let DBList = state.DBList;
        let PhotoService = state.PhotoService;
        let Documents = state.Documents;
        let NewStepValueArray = [];
        let SearchWords = state.SREvent.SearchWords;
        let Quote = false;
        let Emergency = false;
        let Quoteanswe = "";

        if (WebsiteType === "ContractorCheckIn") {

            if (props.dir === "CheckOut") {

                NewStepValueArray.push({

                    Body: "****************** Check Out ******************",
                    SenderCompany: "",
                    Category: "checkout",
                    SenderName: "checkout",
                    avatar: "",
                    AssetList: []
                })
                
            }

            if (props.dir === "CheckIn") {

                NewStepValueArray.push({

                    Body: "****************** Check In ******************",
                    SenderCompany: "",
                    Category: "CheckIn",
                    SenderName: "CheckIn",
                    avatar: "",
                    AssetList: []
                })

            }
        }

        StepValueArray1.map((each, indd) => {

            postHTML += `<h4>${each.field1}</h4>${each.Body}`;
            postTEXT += `( ${each.Category}? ) ${each.Body}\n\n`;
            SearchWords += ` ${each.Body},`;

            if (each.AssetList && each.AssetList.length > 0) {
                DBList = [...DBList, ...each.AssetList];
            }

            if (each.PhotoService && each.PhotoService.length > 0) {
                PhotoService = [...PhotoService, ...each.PhotoService];
                PhotoService = EleminateDuplicateObjects(PhotoService, "ImageFullPath");
            }

            if (each.Documents && each.Documents.length > 0) {
                Documents = [...Documents, ...each.Documents];
                Documents = EleminateDuplicateObjects(Documents, "ImageFullPath");
            }
            //question
            NewStepValueArray.push({
                ...each,
                Body: each.field1,
                SenderCompany: ContractorData.CompanyName,
                SenderName: "Question",
                avatar: ContractorData.avatar,
                AssetList: []
            })
            //answer
            NewStepValueArray.push({
                ...each,
                Body: each.Body,
                SenderCompany: state.SenderCompany,
                SenderName: state.SenderName,
                avatar: state.Senderavatar,
                AssetList: []
            })

            Quoteanswe = each.Body.toLowerCase();

            if (each.Category === "Quote") {                
                if (Quoteanswe.includes("yes")) {
                    Quote = true;
                }
            }

            if (each.Category === "Emergency") {                
                if (Quoteanswe.includes("yes")) {
                    Emergency = true;
                }
            }

        })

        let QuestionsAsked = {
            Asked: StepValueArray1.length,
            Questions: StepValueArray1
        }

        postHTML += "</p>";

        CombinedEvent(postHTML, postTEXT, DBList, PhotoService, Documents, NewStepValueArray, SearchWords, QuestionsAsked, Quote, Emergency);
    }

    const CombinedEvent = (postHTML, postTEXT, DBList, PhotoService, Documents, NewStepValueArray, SearchWords, QuestionsAsked, Quote, Emergency) => {

        let event = state.SREvent;
        let CompanyQuote = [];
        let EmergencyNotification = [];
        let AddExtra = false;
        let posterId = event.posterId;
        let ShowDate = DateTime.now().setZone(event.timeZoneName).toLocaleString(DateTime.DATETIME_SHORT);
        let title = `${state.field1} - ${posterId.field1} - ${posterId.CompanyName} - ${ShowDate}`;


        if (Quote && event.CompanyQuote) {           
            CompanyQuote = event.CompanyQuote;
            AddExtra = true;
        }

        if (Emergency && event.EmergencyNotification) {
            AddExtra = true;
            EmergencyNotification = event.EmergencyNotification;
        }

        if (AddExtra) {
            let PresentEsc1 = event.EscPolicyFound;
            let NewESCPolicy1 = [...PresentEsc1, ...CompanyQuote, ...EmergencyNotification];
            event.EscPolicyFound = NewESCPolicy1;
        }


        let EventData = NewStepValueArray;
        if (event.EventData && event.EventData.length > 0) {
            EventData = [...event.EventData, ...NewStepValueArray];
        }

        let UTCTume = DateTime.utc().toString();
        let hour = DateTime.now().setZone(event.timeZoneName).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
             
        let event2 = {
            ...event,
            EventData: EventData,
            Quote: Quote,
            Emergency: Emergency,
            QuestionsAsked: QuestionsAsked,
            hour: hour,
            postHTML: postHTML,
            postTEXT: postTEXT,
            Documents: Documents,
            PhotoService: PhotoService,
            DBList: DBList,
            currentTime: UTCTume,
            UTCTime: UTCTume,
            date: UTCTume,
            ShowDate: ShowDate,
            Senderavatar: posterId.avatar,
            SearchWords: `${title} ${SearchWords}`,
            title: title,
            status: event.Direction,
            ActionButtonTitle: "Details",
        };

        console.log("NewCheckIn.Step1.CombinedEvent", event2)

        TriggerReminder(event2)

    }

    const TriggerReminder = (NewEvent) => {
        
        let EndMessage = "";
        let timer_seconds = 0;

        if (NewEvent.Direction === "Service") {
            NewEvent.CheckInTime = NewEvent.ShowDate;

            EndMessage = `Use following link to view Service Call Details\n\n${NewEvent.ServiceDetailLink}\n\nThanks`;

            if (NewEvent.SMSFeedbackMessages
                && NewEvent.SMSFeedbackMessages.EndOfQuestions
                && NewEvent.SMSFeedbackMessages.EndOfQuestions !== "") {
                EndMessage = `${NewEvent.SMSFeedbackMessages.EndOfQuestions}\n\n${EndMessage}`;
            }

            SendSMS(NewEvent, EndMessage)
        }


        if (NewEvent.Direction === "CheckIn") {

            NewEvent.status = "CheckIn";
            NewEvent.SMSBody = `Checking In\n${NewEvent.ShowDate}\n${NewEvent.posterId.field1}\n${NewEvent.posterId.CompanyName}\n${NewEvent.posterId.mobile} \n\n${NewEvent.ServiceDetailLink}\n\nThanks`;
            NewEvent.CheckInTime = NewEvent.ShowDate;

            EndMessage = `Thanks for Checking-in. Please text pictures and work progress during the shift to this number. \n\nUse following link to check-out at the end of your shift. \n\n${NewEvent.CheckOutLink} \n\nYou can also text the words "checking out" \n\nThank You`;

            if (NewEvent.SMSFeedbackMessages.checkinmessage && NewEvent.SMSFeedbackMessages.checkinmessage !== "") {
                EndMessage = `${NewEvent.SMSFeedbackMessages.checkinmessage} \n\nUse following link to check-out at the end of your shift. \n\n${NewEvent.CheckOutLink} \n\nYou can also text the words "checking out" \n\nThank You`;
            }

            SendSMS(NewEvent, EndMessage)

            let EmailPrems2 = {
                ContractorAddress: NewEvent.ContractorData.Address,
                ContractorPhone: NewEvent.ContractorData.phone,
                ContractorLogo: NewEvent.ContractorData.avatar,
                ContractorName: NewEvent.ContractorData.CompanyName,
                UnsubscribeLink: "https://app.contractorcheckin.com/Unsubscribe",
                ActionButton: NewEvent.DocumentLink,
                ActionButtonTitle: "Check Out",
                PhotoService: [],
                Documents: [],
                SpecialInstructions: "",
                PreSelect: [],
                title: `Reminder to Check Out\n\n${NewEvent.ShowDate}\n\nthanks`,
                postHTML: `<h4>Please make sure to Check Out at the end of your shift ${NewEvent.ShowDate}</h4>`,
                postTEXT: `Please make sure to Check Out at the end of your shift ${NewEvent.ShowDate}`,
                ReplyEmail: "no-reply@service-call-cloud.com",
                ActionButtonShow: true,
                timer_seconds: 28800,
                step: 1,
                PostId: NewEvent.PostId,
                ContractorSMS: NewEvent.ContractorSMS,
                CheckPk: NewEvent.ServiceRequest.pk1,
                CheckSk: NewEvent.ServiceRequest.sk1,
                hook: "checkout_reminder",
                sendemail: false,
                sendSMS: true,
                email: NewEvent.posterId.email,
                mobile: NewEvent.posterId.mobile,
                posterId: NewEvent.posterId
            };

            StartTimer(EmailPrems2);
        }

        if (NewEvent.Direction === "CheckOut"
            && props.dir !== "Report") {

            NewEvent.status = "CheckOut";
            NewEvent.SMSBody = `Checking Out\n${NewEvent.ShowDate}\n${NewEvent.posterId.field1}\n${NewEvent.posterId.CompanyName}\n${NewEvent.posterId.mobile} \n\n${NewEvent.ServiceDetailLink}`;
            // NewEvent.title = `Checking Out - ${NewEvent.ShowDate} - ${NewEvent.posterId.field1} - ${NewEvent.posterId.CompanyName}`;
            NewEvent.CheckOutTime = NewEvent.ShowDate;

            var nows = new Date();
            var TimeNow = nows.toISOString();

            timer_seconds = Number(NewEvent.Delay) * 60;

            let ResponseEndTime = new Date(TimeNow).getTime();

            let HoursWorked = secondsToHms(ResponseEndTime - NewEvent.ResponseStartTime);
            NewEvent.HoursWorked = HoursWorked;
            NewEvent.ResponseEndTime = ResponseEndTime;

            //  console.log("ResponseEndTime", ResponseEndTime)

            //creating end message
            EndMessage = `Thanks for Checking Out, you can add additional notes for this shift for next 2 hours. Use #new to start a new Shift`;

            if (NewEvent.SMSFeedbackMessages.checkoutmessage && NewEvent.SMSFeedbackMessages.checkoutmessage !== "") {
                EndMessage = `${NewEvent.SMSFeedbackMessages.checkoutmessage}, you can add additional notes for this shift for next 2 hours. Use #new to start a new Shift`;
            }

            //if the check out is more then 14 hours - then just say thanks you
            if (ResponseEndTime > (NewEvent.ResponseStartTime + (14 * 60 * 60 * 1000))) {
                EndMessage = `Thanks for Checking Out`;

                if (NewEvent.SMSFeedbackMessages.checkoutmessage && NewEvent.SMSFeedbackMessages.checkoutmessage !== "") {
                    EndMessage = NewEvent.SMSFeedbackMessages.checkoutmessage;
                }
            }

            SendSMS(NewEvent, EndMessage);

            UpdatePerson(NewEvent);

            if (timer_seconds > 0) {

                let EmailPrems = {
                    ContractorAddress: NewEvent.ContractorData.Address,
                    ContractorPhone: NewEvent.ContractorData.phone,
                    ContractorLogo: NewEvent.ContractorData.avatar,
                    ContractorName: NewEvent.ContractorData.CompanyName,
                    UnsubscribeLink: "https://app.contractorcheckin.com/Unsubscribe",
                    ActionButton: NewEvent.DocumentLink,
                    ResponseEndTime: ResponseEndTime,
                    ActionButtonTitle: "Service Reports",
                    PhotoService: [],
                    Documents: [],
                    SpecialInstructions: "",
                    PreSelect: [],
                    title: `Service Reports for ${NewEvent.ShowDate}`,
                    postHTML: `<h4>Please upload service report for work performed on ${NewEvent.ShowDate}</h4>`,
                    //  PersonList: PersonList,
                    postTEXT: `Please upload service report for work performed on ${NewEvent.ShowDate}`,
                    ReplyEmail: "no-reply@service-call-cloud.com",
                    ActionButtonShow: true,
                    timer_seconds: timer_seconds,
                    ContractorSMS: NewEvent.ContractorSMS,
                    step: 1,
                    PostId: NewEvent.PostId,
                    CheckPk: NewEvent.ServiceRequest.pk1,
                    CheckSk: NewEvent.ServiceRequest.sk1,
                    hook: "reports_reminder",
                    sendemail: true,
                    sendSMS: false,
                    email: NewEvent.posterId.email,
                    mobile: NewEvent.posterId.mobile,
                    posterId: NewEvent.posterId
                };

                StartTimer(EmailPrems);
            }

            //  TriggerPayment(NewEvent);
        }

        console.log("NewCheckIn.Step2.TriggerReminder", NewEvent)
        PrepUpdate(NewEvent);

    }

    const PrepUpdate = event => {

        const NewEvent = {

            pk1: event.ServiceRequest.pk1,
            sk1: event.ServiceRequest.sk1,
            gsi1Pk2: `${state.SelectSystem}::${event.posterId.CompanyId}`,
            gsi1Sk2: event.UTCTime,
            gsi2Pk2: `${state.SelectSystem}::` + event.ContractorData.CompanyId,
            gsi2Sk2: event.UTCTime,
            dataJSON: JSON.stringify(removeEmptyStringElements(event))
        };
        
        console.log("NewCheckIn.Step3.PrepUpdate", event)
       
         UpdateDatabase(NewEvent);

        if (props.UpdateEvent) {
            props.UpdateEvent(event)
        }

        if (state.WaitForEmail) {
            CreatePersonDB(event)
        }

        let ESCtitle = event;
        ESCtitle.title = `${event.Direction} ${event.posterId.field1} ${event.posterId.CompanyName} ${event.ShowDate}`;

        TriggerESCData(ESCtitle);

        setState(prevState => ({
            ...prevState,
            DoneDone: true,
            StatusMessage: "Thank you for completing the informaiton. You can close this page now"
        }))

    }

    const CreatePersonDB = event => {

        try {

            let InputArray = [];

            let Mini = {
                posterId: event.posterId,
                //  post: event.postTEXT,  //change this to the initial call??
                title: event.title,
                field1: event.posterId.field1,
                avatar: event.posterId.avatar,
                AssetPkSk: {
                    pk1: event.posterId.CompanyId,
                    sk1: event.posterId.sk1
                },
                Category: "Person",
                CustomerType: "Company",
                CheckInTime: event.CheckInTime,
                CheckOutTime: event.CheckOutTime,
                ShowDate: event.ShowDate,
                status: event.status,
                field2: event.title,
                ServiceRequest: event.ServiceRequest,
                SearchWords: `${event.title} - ${event.postTEXT}`,
                PhotoService: event.PhotoService,
                Documents: event.Documents,
                GetData: true,
                date: event.UTCTime,
                PostId: event.PostId,
                CompanyId: event.posterId.CompanyId,
                ContractorId: event.ContractorData.CompanyId,
                pk1: `${state.SelectSystem}::Person::${event.posterId.sk1}`,		//find all service by person 
                sk1: event.ServiceRequest.sk1,
                gsi1Pk2: `${state.SelectSystem}::${event.ContractorData.CompanyId}::${event.posterId.sk1}`,	// person under owner
                gsi1Sk2: event.ServiceRequest.sk1,
                gsi2Pk2: `${state.SelectSystem}::${event.ContractorData.CompanyId}::${event.posterId.CompanyId}`,				//all personal working for owner
                gsi2Sk2: event.ServiceRequest.sk1,
                key: `${state.SelectSystem}::Person::${event.posterId.sk1}::${event.ServiceRequest.sk1}`,
            };

            let PaymentJSON = {
                title: event.title,
                field1: event.posterId.field1,
                avatar: event.posterId.avatar,
                ShowDate: event.ShowDate,
                Amount: 3,
                field2: event.title,
                ServiceRequest: event.ServiceRequest,
                GetData: true,
                date: event.UTCTime,
                PostId: event.PostId,
                CompanyId: event.posterId.CompanyId,
                ContractorId: event.ContractorData.CompanyId,
                pk1: `Payment::${event.posterId.sk1}`,
                sk1: event.ServiceRequest.sk1,
                gsi1Pk2: `Payment::Due::${event.posterId.sk1}`,	// Payment due by person
                gsi1Sk2: event.ServiceRequest.sk1,
                gsi2Pk2: `Payment::Due::${event.posterId.CompanyId}`,	// payment due by company
                gsi2Sk2: event.ServiceRequest.sk1,
                key: `Payment::${event.posterId.sk1}::${event.ServiceRequest.sk1}`,
            };

            const MiniDataJSON = removeEmptyStringElements(Mini);

            //person
            const person = {

                pk1: `${state.SelectSystem}::Person::${event.posterId.sk1}`,		//find all service by person 
                sk1: event.ServiceRequest.sk1,
                gsi1Pk2: `${state.SelectSystem}::${event.ContractorData.CompanyId}::${event.posterId.sk1}`,	// person under owner
                gsi1Sk2: event.ServiceRequest.sk1,
                gsi2Pk2: `${state.SelectSystem}::${event.ContractorData.CompanyId}::${event.posterId.CompanyId}`,				//all personal working for owner
                gsi2Sk2: event.ServiceRequest.sk1,
                dataJSON: JSON.stringify(MiniDataJSON)

            };

            InputArray.push(person);

            const Payment = {
                pk1: `Payment::${event.posterId.sk1}`,		//find all service by person 
                sk1: event.ServiceRequest.sk1,
                gsi1Pk2: `Payment::Due::${event.posterId.sk1}`,	// person under owner
                gsi1Sk2: event.ServiceRequest.sk1,
                gsi2Pk2: `Payment::Due::${event.posterId.CompanyId}`,				//all personal working for owner
                gsi2Sk2: event.ServiceRequest.sk1,
                gsi3Pk: `Payment::Due::${event.ContractorData.CompanyId}`,				//all personal working for owner
                gsi3Sk: event.ServiceRequest.sk1,
                dataJSON: JSON.stringify(removeEmptyStringElements(PaymentJSON))
            };

            InputArray.push(Payment);

            if (event.posterId.Location
                && event.posterId.Location.place_id
                && event.posterId.Location.address !== ""
            ) {

                let LocJSON = {
                    title: event.posterId.CompanyName,
                    field1: event.posterId.Location.address,
                    avatar: event.posterId.avatar,
                    ShowDate: event.ShowDate,
                    field2: event.ShowDate,
                    ServiceRequest: event.ServiceRequest,
                    GetData: true,
                    date: event.UTCTime,
                    PostId: event.PostId,
                    Location: event.posterId.Location,
                    CompanyId: event.posterId.CompanyId,
                    ContractorId: event.ContractorData.CompanyId,
                    pk1: `Post::${event.PostId}`,
                    sk1: `Post::Location::${event.ServiceRequest.sk1}`,
                    gsi1Pk2: `Post::Location::${event.posterId.CompanyId}`,
                    gsi1Sk2: event.ServiceRequest.sk1,
                    gsi2Pk2: `Post::Location::${event.ContractorData.CompanyId}`,
                    gsi2Sk2: event.ServiceRequest.sk1,
                    key: `Location::${event.posterId.sk1}::${event.ServiceRequest.sk1}`,
                };

                let LocEachDB = {
                    pk1: `Post::${event.PostId}`,
                    sk1: `Post::Location::${event.ServiceRequest.sk1}`,
                    gsi1Pk2: `Post::Location::${event.posterId.CompanyId}`,
                    gsi1Sk2: event.ServiceRequest.sk1,
                    gsi2Pk2: `Post::Location::${event.ContractorData.CompanyId}`,
                    gsi2Sk2: event.ServiceRequest.sk1,
                    dataJSON: JSON.stringify(removeEmptyStringElements(LocJSON))
                };

                InputArray.push(LocEachDB);

            }


            let PosttoDB1 = {
                MutationType: "createBatch",
                DBData: InputArray
            };

            // Post to DB
            TriggerLambda(PosttoDB1, "createBatch");

        } catch (err) {

        }
    }

    const UpdatePerson = async (event) => {

        let ContractorPolicyList = await GetData(`${state.SelectSystem}::Person::${event.posterId.sk1}`, event.ServiceRequest.sk1);

        // let ContractorPolicyList = await GetData(pk1, sk1);

        if (ContractorPolicyList && ContractorPolicyList.length > 0) {
            let PersonJSON = ContractorPolicyList[0].dataJSON;
            PersonJSON.CheckOutTime = event.CheckOutTime;
            PersonJSON.status = event.status;

            const input = {
                pk1: `${state.SelectSystem}::Person::${event.posterId.sk1}`,
                sk1: event.ServiceRequest.sk1,
                dataJSON: JSON.stringify(removeEmptyStringElements(PersonJSON)),
            };


            let PosttoDB1 = {
                MutationType: "updateSvcbotDb",
                DBData: input
            };

            // Post to DB
            TriggerLambda(PosttoDB1, "UpdateDatabase");
        }
    }

    const SendSMS = (event, EndMessage) => {

        let SMSstring = {
            Body: EndMessage,
            FromSMS: event.ContractorSMS,
            From: event.ContractorSMS,
            To: [event.RequestorSMS],
            mediaUrl: [],
            pk1: event.posterId.CompanyId,
            CompanyId: event.posterId.CompanyId,
            //ContractorId: ContractorId,
            //SMSList: [{
            //    PersonName: Field1,
            //    PersonId: Field1,
            //    phone: Invitation.Mobile
            //}]
        };

        console.log("ItemAddPage.Invite.SMS", SMSstring)

        API.post("SMStoTwilio", "", {
            body: { Records: SMSstring }
        });
    }

    const TriggerESCData = (event) => {
        try {
            console.log("NewServiceCall.TriggerLambda", event);

            API.post("TriggerESCData", "/ESCData", {
                body: { Records: event }
            });
        } catch (err) { console.log("TriggerESCData.err", err); }
    }

    const TriggerPayment = (event) => {
        try {
            console.log("NewServiceCall.TriggerPayment", event);

            API.post("CheckPayment", "", {
                body: { Records: event }
            });
        } catch (err) { console.log("TriggerESCData.err", err); }
    }

    const UpdateDatabase = event => {
        try {
            console.log("NewServiceCall.UpdateDatabase", event);

            let PosttoDB = {
                MutationType: "updateSvcbotDb",
                DBData: event
            };

            // Post to DB
            TriggerLambda(PosttoDB, "UpdateDatabase");

        } catch (err) { console.log("TriggerESCData.err", err); }

    }


    const handleNext = () => {
        console.log("UrgencyDetails.handleNext", activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const ClosingDrawer = (e) => {
        console.log("ClosingDrawer", e)
        SetDeawerOpen(false)
    }


    const SaveSelection = (data) => {

        console.log("NewServiceCall.SaveSelection", data);

        let Quote = false;
        let QuoteQuestion = "";
        let Quoteanswe = "";
        let NewQuote = state.Quote;
        let event = state.SREvent;
        let PhotoService = state.PhotoService;
        let Documents = state.Documents;


        //if (data && data.field1 && data.Body) {

        //    let CurrentQuestion = data.field1;
        //    let LatestAnswer = data.Body;
        //    console.log("NewServiceCall.qqq", CurrentQuestion, LatestAnswer);
        //    if (CurrentQuestion !== "" && LatestAnswer !== "") {

        //        QuoteQuestion = CurrentQuestion.toLowerCase();
        //        Quoteanswe = LatestAnswer.toLowerCase();

        //        if (QuoteQuestion.includes("quote")
        //            || QuoteQuestion.includes("proposal")
        //        ) {
        //            if (Quoteanswe.includes("yes")) {
        //                Quote = true;
        //            }
        //        }
        //    }
        //}

        if (data && data.PhotoService && data.PhotoService.length > 0) {
            PhotoService = [...PhotoService, ...data.PhotoService];
            event.PhotoService = PhotoService;
        }

        if (data && data.Documents && data.Documents.length > 0) {
            Documents = [...Documents, ...data.Documents];
            event.Documents = Documents;
        }


        //if (!state.Quote) {
        //    setState(prevState => ({
        //        ...prevState,
        //        Quote: Quote,
        //    }))
        //}

        let StepValueArray1 = [];
        let Indexx = -1;

        if (state.StepValueArray && state.StepValueArray.length > 0) {
            Indexx = state.StepValueArray.findIndex(x => x.KeyId === data.KeyId);
            StepValueArray1 = state.StepValueArray;
        }



        if (Indexx > -1) {

            StepValueArray1[Indexx] = data;

        } else {

            StepValueArray1.push(data);
        }

        console.log("NewServiceCall.Indexx", Indexx, StepValueArray1);

        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray1,
            Documents: Documents,
            PhotoService: PhotoService,
            SREvent: event
        }))

        //}
    }

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const SetRequestor = (data) => {
        console.log("NewServiceCall.poster.data", data);

        if (data && data.posterId) {
            setState(prevState => ({
                ...prevState,
                ...data,
                SREvent: data,

            }));
        }

    }

    const CheckRequestor = () => {
        if (state.Email) {
            handleNext();
        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please provide Email",
                OpenAlert: true
            }));

        }
    }

    const CheckReason = () => {
        let StepValueArray = state.StepValueArray;
        if (state.Description && state.Description !== "") {

            StepValueArray[0] = {

            }

            handleNext();
        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please state the reason for the service request",
                OpenAlert: true
            }));

        }
    }

    // console.log("UrgencyDetails.SelectStepBox.Update5", state.StepValueArray)
    console.log("NewCheckin.state", state);
    console.log("NewCheckin.StepValueArray", state.StepValueArray)
    console.log("NewCheckin.activeStep", activeStep)
    console.log("NewCheckIn.props2", props);

    return state.SMSQuestionsNew.length > 0 && (
        <Paper id={state.id} key={state.UUid} sx={{ p: 1 }}>

            <Grid container spacing={2}>

                {state.DoneDone ? (
                    state.ShowVideo ? (
                        <Grid item xs={12}>
                            <Typography component="div" variant="h3">
                                System Overview Video
                            </Typography>
                            <ShowYoutube
                                YoutubeLink="https://youtu.be/N-bdevozWlI"
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Typography component="div" variant="h3">
                                {state.StatusMessage}
                            </Typography>
                        </Grid>
                    )
                ) : (
                    <>
                        <Grid item xs={12}>

                            <Stepper activeStep={activeStep} orientation="vertical">

                                {state.WaitForEmail ? (
                                    <Step key={1}>
                                        <StepLabel>Account Setup</StepLabel>
                                        <StepContent>
                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <Typography >Following inforamtion is required</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AccountSetup
                                                        SelectedDataReturn={(e) => SetRequestor(e)}
                                                        SREvent={props.SREvent}
                                                        // handleNext={() => handleNext()}
                                                        title={`Account Setup`}
                                                        Category="Person"
                                                    />
                                                </Grid>

                                            </Grid>
                                        </StepContent>
                                    </Step>
                                ) : null}

                                    {/*
                                        state.AskackPolicy ? (
                                    <Step key={2}>
                                        <StepLabel>Policy Acknowledgement</StepLabel>
                                        <StepContent>
                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <Typography >Following inforamtion is required</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PolicyAcknowledge
                                                        SelectedDataReturn={(e) => SetRequestor(e)}
                                                        SREvent={props.SREvent}
                                                        handleBack={() => handleBack()}
                                                        handleNext={() => handleNext()}
                                                        title={`Policy Acknowledgement`}
                                                        Category="Person"
                                                    />
                                                </Grid>

                                            </Grid>
                                        </StepContent>
                                    </Step>
                                        ) : null

                                    */}


                                {state.SMSQuestionsNew.map((block, index) => {

                                    return (

                                        <Step key={index + 5}>
                                            <StepLabel>{block.field1}</StepLabel>
                                            <StepContent>

                                                <RenderModule
                                                    LoadComponenet={block.LoadComponenet}
                                                    StepValueArray={state.StepValueArray}
                                                    Category={block.Category}
                                                    SREvent={state.SREvent}
                                                    // SaveDataBack={state.SaveDataBack[index]}
                                                    SelectedDataReturn={(data) => SaveSelection(data)}
                                                    id={index}
                                                    // activeStep={activeStep}
                                                    handleBack={() => handleBack()}
                                                    handleNext={() => handleNext(index)}
                                                    KeyId={block.KeyId}
                                                    key={index + block.id}
                                                />

                                            </StepContent>
                                        </Step>

                                    )
                                })}




                                <Step key={100}>
                                    <StepLabel>Final Step</StepLabel>
                                    <StepContent>

                                        <div
                                        //className={classes.actionsContainer}
                                        >
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                //  className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => AdjustData()}
                                                //  className={classes.button}
                                                >
                                                    Save data
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>


                            </Stepper>


                        </Grid>


                        <Grid item xs={12}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={() => setActiveStep(0)}
                            //  className={classes.button}
                            >
                                Reset
                            </Button>
                        </Grid>

                    </>

                )}




            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle="change this"
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => ClosingDrawer(e),
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </Paper>
    );
}
