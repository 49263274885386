import React, { useState, useEffect } from 'react';
//import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardHeader,
    Grid,
    Typography,
    IconButton
} from '@mui/material';
import DrawerShow from '../utils/DrawerModule';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ProfileDetails = (props) => {

    let initialState = {
        Data: [],
    };

    const [state, setState] = useState(initialState);
    const [DrawerStatus, OpenDrawer] = useState(false);
  
    const SRDetails = (data) => {
        setState(prevState => ({
            ...prevState,
            Data: data
        }));
        OpenDrawer(true);
    }

   
    const CloseDrawer = (c) => {
        OpenDrawer(false);
    }

    console.log("mapdata.props", props)

    return (
        <div>
        
            <Grid container sx={{ maxWidth: 360 }} spacing={2}>
                {props.data && props.data.SRInfo && props.data.SRInfo.length > 0 && (
                    props.data.SRInfo.map((each, indd) => (
                        <Grid item xs={12} key={indd +  each.date}>
                            <Card sx={{ maxWidth: 345 }} key={indd + each.date + "car"}>
                            <CardHeader
                                action={
                                    <IconButton aria-label="settings"
                                            onClick={() => SRDetails(each)} size="large">
                                        <MoreVertIcon />
                                    </IconButton>
                                }

                               // subheader={each.date}
                                title={each.field1}
                            />

                                <Box sx={{ m: 1 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        {each.field2}
                                    </Typography>
                                </Box>
                        </Card>
                        </Grid>
                        ))
                    )}
                
            </Grid>
            
            <DrawerShow
                LoadComponenet="ServiceDetails"
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle="Service Details"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    SREvent: state.Data,
                    GetData: true,
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>

    );
};

ProfileDetails.propTypes = {
    className: PropTypes.string
};

export default ProfileDetails;

