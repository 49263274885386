import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';


const CheckIn = [
    {
        PlanId: "CheckInSubcontractorPays",
        PlanTitle: "Subcontractor Pays",
        HeadingMessage: "Free for You",
        SubHeadingMessage: "Plan is free if you are a project or operations manager, and would like to document your subcontractor or employee activities. Easily create work progress reports for your customers or management",
        BodyMessage: "The person using the check-in app will be billed $3/shift. No credit card needed to start free trial",
        ButtonWords: "Select",
    },
    {
        PlanId: "CheckInYouPay",
        PlanTitle: "You Pay",
        HeadingMessage: "$3 per shift",
        SubHeadingMessage: "Document the work and the activities of your subcontractors or employees. Easily create work progress reports for your customers or management",
        BodyMessage: "You will be billed $3 per shift at the end of each month. No credit card needed to start free trial",
        ButtonWords: "Start 30 day free trail",
    },
]

const CheckInAdd = [
    {
        PlanId: "CheckInSubcontractorPaysAdd",
        PlanTitle: "Subcontractor Pays",
        HeadingMessage: "Free for You",
        SubHeadingMessage: "Plan is free if you are a project or operations manager, and would like to document your subcontractor or employee activities",
        BodyMessage: "Your sub-contractor is charged $3 per shift.",
        ButtonWords: "Select",
    },
    {
        PlanId: "CheckInYouPayAdd",
        PlanTitle: "You Pay",
        HeadingMessage: "$3 per shift",
        SubHeadingMessage: "Document activities of your subcontractors or employees. Easily create work progress reports with each milestone and pictures for your customers or management",
        BodyMessage: "You will be billed $3 per shift at the end of each month.",
        ButtonWords: "Select",
    },
]

const Service = [
    {
        PlanId: "ServiceWithTextPlan",
        PlanTitle: "ServiceWithText Plan",
        HeadingMessage: "30 Days Free Trial",
        SubHeadingMessage: "No Credit Card needed for trial",
        BodyMessage: "Easy flat rate of $5 per service call after the free trail period",
        ButtonWords: "Start Free Trial",
    },
]

const ServiceAdd = [
    {
        PlanId: "ServiceWithTextPlanAdd",
        PlanTitle: "ServiceWithText Plan",
        HeadingMessage: "$5 per service call",
        SubHeadingMessage: "",
        BodyMessage: "Easy flat rate of $5 per service call",
        ButtonWords: "Select",
    },
]



export default function ResponsiveGrid(props) {

    const initialState = {        
        SubBlock: []
    }

    const [state, setState] = useState(initialState);

    let SubBlock = "";

    if (props.WebsiteType === "ContractorCheckIn") {
        if (props.AddNewType === "Add") {
            SubBlock = CheckInAdd;
        } else {
            SubBlock = CheckIn;
        }
    }

    if (props.WebsiteType === "ServiceWithText") {
        if (props.AddNewType === "Add") {
            SubBlock = ServiceAdd;
        } else {
            SubBlock = Service;
        }        
    }

    useEffect(() => {

        let StepValueArray = {};
        

        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);

            setState(prevState => ({
                ...prevState,
                id: props.id,
                Category: props.Category,
                StepValueArray: StepValueArray,
                KeyId: props.KeyId,
                title: props.title,
                SubBlock: SubBlock,
                loading: true,
            }));
        }

    }, [])

    const handleNext = (id) => {

        if (props.SubscriptionId) {
            props.SubscriptionId(id);
        }

        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(state);
        }

        if (props.handleNext) {
            props.handleNext();
        }
    }


    console.log("subs.state", state)
    console.log("subs.props", props)

    return SubBlock.length > 0 && (

        <Grid container spacing={2}>

           
            <Grid item xs={12}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {SubBlock.map((each, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={index + each.PlanId}>
                            <Card >
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {each.PlanTitle}
                                    </Typography>
                                    <Typography variant="h3" component="div">
                                        {each.HeadingMessage}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {each.SubHeadingMessage}
                                    </Typography>
                                    <Typography variant="body2">
                                        {each.BodyMessage}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                       // variant="outlined"
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleNext(each.PlanId)}
                                    >
                                        {each.ButtonWords}
                                    </Button>
                                </CardActions>

                            </Card>


                        </Grid>
                    ))}
                </Grid>

            </Grid>
            {/* 
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
            </Grid>
                */}
        </Grid>


    );
}

