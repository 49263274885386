import React, { useEffect, useState } from 'react';
import {
    TextField,
    Box,
    Typography,
    //Paper,
    Grid,
    CardHeader, Card, Button, CardContent, InputLabel,
    //Backdrop,
    //CircularProgress,
    //Card,
    //FormGroup,
    //CardContent,
    //CardActions,
    Snackbar,
    //CardHeader,
    FormGroup, FormControlLabel, Checkbox,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';
import { API } from "aws-amplify";
import ListModule from '../List/ListModule';
import DrawerShow from '../utils/DrawerModule';
import { SendEmail } from '../utils/SendEmail';
import PhoneInput from 'react-phone-input-2';
import TimeZone from '../components/TimeZone';

//const { URL } = require('url');

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

var GetDBData = (params) => {
    try {
        return new Promise((resolve, reject) => {
            dynamodb.query(params, function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else resolve(data); // successful response
            });
        });
    } catch (err) {
        console.log('GetDBData err', err);
    }
};

//var testMail = 'testmail@fakemail.com';
//const ValidateEmail = testMail => {

//    var check_email = '[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com|msn.com|hotmail.com|yahoo.com)';
//    var patt = new RegExp(check_email);
//    var result = patt.test(testMail);

//    console.log("AccountSetup.ValidateEmail", result)
//    return result;
//}

export default function RadioButtonsGroup(props) {
    //  const ContractorData = useSelector(state => state.ContractorData);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const initialState = {
        AssetList: [],
        AlarmMessage: "",
        OpenAlert: false,
        Name: "",
        Email: "",
        CompanyName: "",
        Zip: "",
        NoCompanyEmail: false,
        NeedCompanyEmailAvailable: false,
        Acknowledge: false,
        receiveEmail: true,
        receiveSMS: true,
        receivePhone: true,
        disablemobile: false,
        receive: false
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);


    useEffect(() => {

        if (props.SREvent && props.SREvent.ContractorData) {
            GetPolicy(props.SREvent.ContractorData.CompanyId);
        }

        if (props.SREvent && props.SREvent.RequestorSMS !== "") {
            setState(prevState => ({
                ...prevState,
                Mobile: props.SREvent.RequestorSMS,
                disablemobile: true
            }));
        }

       // checkUUr();

    }, [props.SREvent])

    //const checkUUr = async () => {

    //    const endPoint = "https://yfxflbgfcnfb7axkgtvlimexl4.appsync-api.us-east-1.amazonaws.com/graphql";
    //    const myURL = await new URL(endPoint);
    //    console.log("AccountSetup.myURL", myURL)

    //}

    const GetPolicy = async (CompanyId) => {

        let DataBack = [];
        let Acknowledge = state.Acknowledge;

        let getItemParams = {
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1) ",
            ScanIndexForward: false,
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1"
            },
            ExpressionAttributeValues: {
                ":pk1": CompanyId,
                ":sk1": "Data::Policy::"
            }
        };

        var ContractorFound = await GetDBData(getItemParams);

        console.log("AccountSetup.ContractorPolicy", ContractorFound)

        if (ContractorFound.Count > 0) {
            ContractorFound.Items.map(each => {
                DataBack.push(each.dataJSON)
            })
        } else {
            Acknowledge = true
        }

        setState(prevState => ({
            ...prevState,
            DataBack: DataBack,
            Acknowledge: Acknowledge
        }));

    }

    const TriggerEmail = () => {

        let PhotoService = [];
        let Documents = [];

        if (state.DataBack && state.DataBack.length > 0) {

            state.DataBack.map(each => {
                PhotoService = [...PhotoService, ...each.PhotoService]
                Documents = [...Documents, ...each.Documents]
            })


            var emailstring = {

                PhotoService: PhotoService,
                Documents: Documents,
                SpecialInstructions: "",
                PreSelect: [],
                title: `Policy Documents`,
                postHTML: "<h3>Please review following Policy Documents</h3>",
                postTEXT: "Please review following Policy Documents",
                PersonList: ["ali.rehan@sninc.us"],//[state.Email],
                ReplyEmail: "no-reply@service-call-cloud.com", //UserData.email,
                PostId: state.NewId,

                ContractorAddress: "",
                ContractorPhone: "",
                ContractorLogo: props.SREvent.ContractorData.avatar,
                ContractorName: props.SREvent.ContractorData.CompanyName,
                ContractorId: props.SREvent.ContractorData.CompanyId,
                CompanyId: props.SREvent.ContractorData.CompanyId,
                avatar: "",

                UnsubscribeLink: `https://app.contractorcheckin.com/Unsubscribe`,
                ActionButton: `https://app.contractorcheckin.com`,
                ActionButtonTitle: "Online Access",
                ActionButtonShow: false,
            };

            console.log("ItemAddPage.Invite1.email", emailstring)
            SendEmail(emailstring);
        }
    }

    //const SetTimeZone = (TimeZone) => {
    //    try {
    //        if (TimeZone && TimeZone.value !== "") {
                
    //            setState(prevState => ({
    //                ...prevState,
    //                TimeZone: TimeZone
    //            }));
                
    //        }
    //    } catch (err) { console.log("SetAddressValues", err) }
    //}

    //const CreatePerson = () => {

    //    console.log("AccountSetup.CreatePerson", state)
    //    console.log("AccountSetup.CreatePerson.props", props)

    //    try {

    //        // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
    //        let mobile = state.Mobile;
    //        let OldConversation = props.SREvent;
    //        let ContractorData = props.SREvent.ContractorData;
    //        let Emailaddress = state.Email;
    //        let ParseEmail = Emailaddress.split("@");
    //        // let emailName = ParseEmail[0];
    //        let Domain = ParseEmail[1];
    //        //let CCName = ParseEmail[1].split(".");
    //        //CCName.pop();

    //        let Acknowledge = "Denied";
    //        if (state.Acknowledge) {
    //            Acknowledge = "Accepted";
    //        }

    //        let dateToday = new Date();
    //        //let currentTime = new Date(dateToday).getTime();
    //        const dateAsString = dateToday.toString();
    //        let TimeZoneValue = dateAsString.match(/\(([^\)]+)\)$/)[1];
    //        const timezoneOffset = (new Date()).getTimezoneOffset();
    //        let Offset = -1 * (timezoneOffset / 60);
    //        let TimeZone = {
    //            value: TimeZoneValue,
    //            offset: Offset
    //        }

    //        if (state.TimeZone && state.TimeZone.value !== "") {
    //            TimeZone = state.TimeZone;
    //            TimeZoneValue = state.TimeZone.value
    //        }
            

    //        if (ValidateEmail(state.Email)) {
    //            let CDomain = state.CompanyName.replace(/[^a-zA-Z0-9._]/g, "");
    //            Domain = CDomain.toLocaleLowerCase();
    //        }

    //        let CompanyId = `Data::Company::Info::${Domain}::${state.Zip}`;
    //        let CustomerType = "Company";
    //        let ContractorId = ContractorData.CompanyId;
    //        let ContractorName = ContractorData.CompanyName;

    //        if (props.OwnerSignup === true) {
    //            CompanyId = `Data::Owner::Info::${Domain}::${state.Zip}`;
    //            CustomerType = "Contractor";
    //            ContractorId = `Data::Owner::Info::${Domain}::${state.Zip}`;
    //            ContractorName = state.CompanyName;
    //        }  

    //        // let ContName = CCName.replace(/[_.-]/g, ' ');
    //        let CompanyName = state.CompanyName;  //ContName.titleCase();

    //        // let PersonName = emailName.replace(/[_.-]/g, ' ');
    //        let field1 = state.Name;  // PersonName.titleCase();

    //        let email = Emailaddress;
    //        let key = `Data::Company::Resource::Person::${email}`;
    //        let phone = mobile;
    //        // let CompanyName = ContractorName;
    //        let field2 = email;
            
    //        if (ContractorData.TimeZone && ContractorData.TimeZone.value) {
    //            TimeZoneValue = ContractorData.TimeZone.value;
    //        }
    //        // let CompanyId = Contractorkey;
    //        let SearchWords = `${field1} ${email} ${CompanyName} ${state.Zip}`;
    //        let PostId = NewPageId("Person");
    //        let Privileges = {
    //            "label": "Manager",
    //            "value": 300
    //        };
    //        let posterId = {
    //            field1: field1,
    //            field2: field2,
    //            CompanyId: CompanyId,
    //            CompanyName: CompanyName,
    //            avatar: "",
    //            key: key,
    //        }

    //        let AllItemForms = [
    //            {
    //                "sk1": key,
    //                "Category": "Person",
    //                "Documents": [
    //                ],
    //                "field1": "Person Infomation",
    //                "FormData": [
    //                    {
    //                        "Category": "Person",
    //                        "defaultValue": field1,
    //                        "Editable": false,
    //                        "extra": "field1",
    //                        "key": "ff_p6ZcrMay10",
    //                        "label": "Name",
    //                        "ListInfo": true,
    //                        "options": [
    //                        ],
    //                        "Order": 1,
    //                        "required": true,
    //                        "Searchable": true,
    //                        "ShowValue": field1,
    //                        "TableColumn": true,
    //                        "type": "textline",
    //                        "VariableInput": field1,
    //                        "VariableName": "name"
    //                    },
    //                    {
    //                        "Category": "Person",
    //                        "defaultValue": email,
    //                        "Editable": false,
    //                        "extra": "field2",
    //                        "key": "ff_p6ZcrMay11",
    //                        "label": "Email",
    //                        "ListInfo": true,
    //                        "options": [
    //                        ],
    //                        "Order": 1,
    //                        "required": true,
    //                        "Searchable": true,
    //                        "ShowValue": email,
    //                        "TableColumn": true,
    //                        "type": "email",
    //                        "VariableInput": email,
    //                        "VariableName": "email"
    //                    },
    //                    {
    //                        "Category": "Person",
    //                        "defaultValue": TimeZoneValue,
    //                        "Details": TimeZone,
    //                        "Editable": false,
    //                        "key": "ff_p6ZcrMay12",
    //                        "label": "Time Zone",
    //                        "options": [
    //                        ],
    //                        "Order": 1,
    //                        "required": true,
    //                        "Searchable": true,
    //                        "ShowValue": TimeZoneValue,
    //                        "TableColumn": true,
    //                        "type": "TimeZone",
    //                        "VariableInput": TimeZoneValue,
    //                        "VariableName": "TimeZone"
    //                    },
    //                    {
    //                        "Category": "Person",
    //                        "defaultValue": mobile,
    //                        "Editable": true,
    //                        "key": "ff_p6ZcrMay13",
    //                        "label": "Mobile/SMS",
    //                        "ListInfo": true,
    //                        "options": [
    //                        ],
    //                        "Order": 3,
    //                        "required": true,
    //                        "ShowValue": mobile,
    //                        "TableColumn": false,
    //                        "type": "phone",
    //                        "VariableInput": mobile,
    //                        "VariableName": "mobile"
    //                    },
    //                    {
    //                        "Category": "Person",
    //                        "defaultValue": phone,
    //                        "Editable": true,
    //                        "key": "ff_p6ZcrMay14",
    //                        "label": "Work Phone",
    //                        "ListInfo": true,
    //                        "options": [
    //                        ],
    //                        "Order": 3,
    //                        "required": true,
    //                        "ShowValue": phone,
    //                        "TableColumn": false,
    //                        "type": "phone",
    //                        "VariableInput": phone,
    //                        "VariableName": "phone"
    //                    },
    //                    {
    //                        "Category": "Person",
    //                        "defaultValue": Acknowledge,
    //                        "Editable": true,
    //                        "key": "ff_p6ZcrMay15",
    //                        "label": "Acknowledge Policy",
    //                        "ListInfo": true,
    //                        "options": [
    //                        ],
    //                        "Order": 3,
    //                        "required": true,
    //                        "ShowValue": Acknowledge,
    //                        "TableColumn": false,
    //                        "type": "checkbox",
    //                        "VariableInput": Acknowledge,
    //                        "VariableName": "Policy"
    //                    },
    //                    {
    //                        Order: 3,
    //                        options: [],
    //                        label: "Receive Email Notification",
    //                        VariableName: "receiveEmail",
    //                        Editable: true,
    //                        TableColumn: false,
    //                        extra: "",
    //                        VariableInput: state.receiveEmail,
    //                        defaultValue: state.receiveEmail,
    //                        message: "",
    //                        type: "checkbox",
    //                        key: 3,
    //                        Category: "Person",
    //                        ListInfo: true,
    //                        required: true
    //                    },
    //                    {
    //                        Order: 3,
    //                        options: [],
    //                        label: "Receive Text/SMS Notification",
    //                        VariableName: "receiveSMS",
    //                        Editable: true,
    //                        TableColumn: false,
    //                        extra: "",
    //                        VariableInput: state.receiveSMS,
    //                        defaultValue: state.receiveSMS,
    //                        message: "",
    //                        type: "checkbox",
    //                        key: 3,
    //                        Category: "Person",
    //                        ListInfo: true,
    //                        required: true
    //                    },
    //                    {
    //                        Order: 3,
    //                        options: [],
    //                        label: "Receive Phone Notification",
    //                        VariableName: "receivePhone",
    //                        Editable: true,
    //                        TableColumn: false,
    //                        extra: "",
    //                        VariableInput: state.receivePhone,
    //                        defaultValue: state.receivePhone,
    //                        message: "",
    //                        type: "checkbox",
    //                        key: 3,
    //                        Category: "Person",
    //                        ListInfo: true,
    //                        required: true
    //                    }
    //                ],
    //                "FormName": "Person Infomation",
    //                "FormType": "Person",
    //                "key": key,
    //                "PhotoService": [
    //                ],
    //                "SearchWords": "x",
    //                "ValueAdded": true
    //            },

    //        ];

    //        //  console.log("AllItemForms 421", JSON.stringify(AllItemForms, null, 2));

    //        let FormdataJSON = {
    //            AllItemForms: AllItemForms,
    //            BranchId: ContractorId,
    //            ContractorDataId: ContractorId,
    //            BranchName: ContractorName,
    //            HQId: "Developer::Account::0786",
    //            HQName: "Service Notification Inc.",
    //            status: "Waiting",
    //            SearchWords: SearchWords,
    //            AllowSMSService: true,
    //            Category: "Person",
    //            CustomerType: CustomerType,
    //            TimeZone: TimeZone,
    //            CompanyId: CompanyId,
    //            CompanyName: CompanyName,
    //            timezoneOffset: timezoneOffset,
    //            avatar: "",
    //            id: PostId,
    //            key: key,
    //            Privileges: Privileges,
    //            Level: 300,
    //            field1: field1,
    //            field2: field2,
    //            ContractorId: ContractorId,
    //            receiveEmail: state.receiveEmail,
    //            receiveSMS: state.receiveSMS,
    //            receivePhone: state.receivePhone,
    //            date: dateAsString,
    //            posterId: posterId,
    //            PhotoService: [],
    //            Documents: [],
    //            phone: phone,
    //            mobile: mobile,
    //            email: email,
    //            pk1: key,
    //            sk1: key,
    //            gsi1Pk2: `phone::${mobile}`,
    //            gsi1Sk2: "",
    //            gsi2Pk2: CompanyId,
    //            gsi2Sk2: key,
    //        };

    //        let PersonInfo = {

    //            BranchId: ContractorId,
    //            BranchName: ContractorName,
    //            HQId: "Developer::Account::0786",
    //            HQName: "Service Notification Inc.",
    //            Category: "Person",
    //            CustomerType: CustomerType,
    //            AllowSMSService: true,
    //            TimeZone: TimeZone,
    //            CompanyId: CompanyId,
    //            CompanyName: CompanyName,
    //            avatar: "",
    //            id: PostId,
    //            key: key,
    //            Privileges: Privileges,
    //            Level: 300,
    //            field1: field1,
    //            field2: field2,
    //            ContractorId: ContractorId,
    //            receiveEmail: state.receiveEmail,
    //            receiveSMS: state.receiveSMS,
    //            receivePhone: state.receivePhone,
    //            date: dateAsString,
    //            posterId: posterId,
    //            PhotoService: [],
    //            Documents: [],
    //            phone: phone,
    //            mobile: mobile,
    //            email: email,
    //            pk1: key,
    //            sk1: key,
    //            gsi1Pk2: `phone::${mobile}`,
    //            gsi1Sk2: "",
    //            gsi2Pk2: CompanyId,
    //            gsi2Sk2: key,
    //        };

    //        console.log("AccountSetup.AddPerson", FormdataJSON);

    //        OldConversation.posterId = PersonInfo;
    //        OldConversation.gsi1Pk2 = `CheckIn::${CompanyId}`;
    //        OldConversation.WaitForEmail = false;
    //        OldConversation.FormData = AllItemForms[0].FormData;

    //        CheckOnCompany(FormdataJSON);

    //        if (state.receive) {
    //            TriggerEmail();
    //        }

    //        setState(prevState => ({
    //            ...prevState,
    //            DoneDone: true,
    //        }))

    //        //console.log("AccountSetup.key", SelectedDataReturn)

    //        if (props.SelectedDataReturn) {
    //            props.SelectedDataReturn(OldConversation)
    //        }

    //        if (props.handleNext) {
    //            props.handleNext();
    //        }

    //        //adding person
    //        let DBInput = {
    //            pk1: key,
    //            sk1: key,
    //            gsi1Pk2: `phone::${mobile}`,
    //            gsi1Sk2: key,
    //            gsi2Pk2: CompanyId,   
    //            gsi2Sk2: key ,
    //            dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
    //        };

    //        // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
    //        let PosttoDB = {
    //            MutationType: "createSvcbotDb",
    //            DBData: DBInput
    //        };

    //        // let Messagereceived = `Account created, please refresh the Person list on the app`;
    //        // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

    //        // Post to DB
    //        TriggerLambda(PosttoDB, "CreatePerson");


    //    } catch (err) { console.log("put.err", err) }


    //}

    //const CheckOnCompany = (FormdataJSON) => {

    //    let Companyresult = CheckCompanyExist("Developer::Account::0786", FormdataJSON.CompanyId);

    //    if (Companyresult && Companyresult.exist) {

    //        //check if it exist under contactor
    //        let ExistUnder = CheckCompanyExist(FormdataJSON.ContractorId, FormdataJSON.CompanyId);
    //        if (ExistUnder && ExistUnder.exist) {
    //            //DO NOTHING
    //        } else {
    //            //create company under contraactor
    //            CreateUnderOwner(FormdataJSON, Companyresult.CompanyData)
    //        }

    //    } else {
    //        CreateCompany(FormdataJSON)
    //    }


    //}

    //const CheckCompanyExist = async (pk1, sk1) => {

    //    let getItemParams = {
    //        TableName: "svcbot_db",
    //        KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1) ",
    //        ScanIndexForward: false,
    //        ExpressionAttributeNames: {
    //            "#pk1": "pk1",
    //            "#sk1": "sk1"
    //        },
    //        ExpressionAttributeValues: {
    //            ":pk1": pk1,
    //            ":sk1": sk1
    //        }
    //    };

    //    var ContractorFound = await GetDBData(getItemParams);

    //    if (ContractorFound.Count > 0) {
    //        let FormdataJSON = ContractorFound.Items[0].dataJSON;

    //        console.log("AccountSetup.CheckCompanyExist", FormdataJSON);

    //        return {
    //            exist: true,
    //            CompanyData: FormdataJSON
    //        };
    //        //create under owner
    //        //  CreateUnderOwner(data, ContractorFound)
    //    } else {
    //        //create company
    //        return {
    //            exist: false,
    //            CompanyData: ""
    //        };

    //    }
    //}

    //const CreateUnderOwner = (data, CompanyData) => {
    //    try {

    //        let dataJSON = {
    //            ...CompanyData,
    //            pk1: data.ContractorId,
    //            sk1: data.CompanyId,
    //            key: data.CompanyId,
    //            gsi2Pk2: data.CompanyId,
    //            gsi2Sk2: data.ContractorId,
    //        }

    //        let DBInput = {
    //            pk1: data.ContractorId,
    //            sk1: data.CompanyId,
    //            gsi1Pk2: data.CompanyId,
    //            gsi1Sk2: data.ContractorId,
    //            gsi2Pk2: data.CompanyId,
    //            gsi2Sk2: data.ContractorId,
    //            dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
    //        };

    //        console.log("AccountSetup.CreateUnderOwner", dataJSON);

    //        // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
    //        let PosttoDB = {
    //            MutationType: "createSvcbotDb",
    //            DBData: DBInput
    //        };

    //        // Post to DB
    //        TriggerLambda(PosttoDB, "CreateUnderOwner");

    //    } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }

    //}

    //const CreateCompany = (data, OldConversation) => {
    //    try {

    //        let SearchWords = `${state.CompanyName} ${state.Zip}`;
    //        let PostId = NewPageId("Company");

    //        let AllItemForms = [
    //            {
    //                "sk1": data.CompanyId,
    //                "Category": "Company",
    //                "Documents": [
    //                ],
    //                "FormData": [
    //                    {
    //                        "Category": "Company",
    //                        "defaultValue": data.CompanyName,
    //                        "Editable": false,
    //                        "extra": "field1",
    //                        "key": 100,
    //                        "label": "Company Name",
    //                        "options": [
    //                        ],
    //                        "Order": 1,
    //                        "required": true,
    //                        "Searchable": true,
    //                        "TableColumn": true,
    //                        "type": "textline",
    //                        "VariableInput": data.CompanyName,
    //                        "VariableName": "CompanyName"
    //                    },
    //                    {
    //                        "Category": "Company",
    //                        "defaultValue": "",
    //                        "Editable": true,
    //                        "extra": "field2",
    //                        "key": 101,
    //                        "label": "Business Type",
    //                        "options": [
    //                        ],
    //                        "Order": 1,
    //                        "required": false,
    //                        "TableColumn": false,
    //                        "type": "textline",
    //                        "VariableInput": "",
    //                        "VariableName": "BusinessType"
    //                    }
    //                ],
    //                "FormName": "Company Infomation",
    //                "FormType": "Company",
    //                "key": data.CompanyId,
    //                "PhotoService": [
    //                ],
    //                "ValueAdded": true
    //            }
    //        ];

    //        //  console.log("AllItemForms 421", JSON.stringify(AllItemForms, null, 2));

    //        let FormdataJSON = {
    //            ...data,
    //            AllItemForms: AllItemForms,
    //            status: "Active",
    //            SearchWords: SearchWords,
    //            AllowSMSService: true,
    //            CompanyName: data.CompanyName,
    //            Category: "Company",
    //            CustomerType: data.CustomerType,
    //            id: PostId,
    //            key: data.CompanyId,
    //            field1: data.CompanyName,
    //            field2: "",
    //            pk1: "Developer::Account::0786",
    //            sk1: data.CompanyId,
    //            CompanyId: data.CompanyId,
    //            gsi1Pk2: "",
    //            gsi1Sk2: "",
    //            gsi2Pk2: "",
    //            gsi2Sk2: "",
    //        };

    //        console.log("AccountSetup.CreateCompany", FormdataJSON);

    //        let DBInput = {
    //            pk1: "Developer::Account::0786",
    //            sk1: data.CompanyId,
    //            //gsi1Pk2: data.CompanyId,
    //            //gsi1Sk2: data.ContractorId,
    //            //gsi2Pk2: data.ContractorId,
    //            //gsi2Sk2: data.CompanyId,
    //            dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
    //        };

    //        // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
    //        let PosttoDB = {
    //            MutationType: "createpksk",
    //            DBData: DBInput
    //        };

    //        // let Messagereceived = `Account created, please refresh the Person list on the app`;
    //        // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

    //        // Post to DB
    //        TriggerLambda(PosttoDB, "CreateCompany");

    //        CreateUnderOwner(data, FormdataJSON)


    //    } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }


    //}

    const TriggerLambda = (event, from) => {
        console.log("NewServiceCall.TriggerLambda", from, event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    const SetValues = (data, name) => {

        if (name === "Email" && data !== "") {
            data = data.toLowerCase();
        }

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

    }

    const handleNext = () => {

        
        let AlarmMessage = "";
        let OpenAlert = false;
        let NeedCompanyEmailAvailable = false;

       

        if (!state.Acknowledge) {
            OpenAlert = true;
            AlarmMessage += " * Please Acknowledge Policy Documents ";
        }

        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: OpenAlert,
                NeedCompanyEmailAvailable: NeedCompanyEmailAvailable
            }));

        } else {

                    if (state.receive) {
                        TriggerEmail();
                    }

            if (props.handleNext) {
                props.handleNext();
            }

        }

    }

    //const CheckIfPersonExist = async () => {

    //    let key = `Data::Company::Resource::Person::${state.Email}`;

    //    let getItemParams = {
    //        TableName: "svcbot_db",
    //        KeyConditionExpression: "#pk1 = :pk1 ",
    //        ScanIndexForward: false,
    //        ExpressionAttributeNames: {
    //            "#pk1": "pk1"
    //        },
    //        ExpressionAttributeValues: {
    //            ":pk1": key
    //        }
    //    };

    //    var PersonFound = await GetDBData(getItemParams);

    //    console.log("AccountSetup.ContractorPolicy", PersonFound)

    //    if (PersonFound.Count > 0) {
    //        let FormdataJSON = PersonFound.Items[0].dataJSON;
    //        CheckOnCompany(FormdataJSON);

    //    } else {
    //        CreatePerson();
    //    }

    //}

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Document Details",
            // SetupSubscription: true,
            DetailComponent: "ShowDetailsText",
            ItemData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        OpenDrawer(true);
        //}
    }

    const CloseDrawer = (c) => {
        //    GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }


    console.log("AccountSetup.state", state)
    console.log("AccountSetup.props", props)

    return (
        <>
            <Grid container spacing={1}>
                       
                {state.DataBack && state.DataBack.length > 0 ? (
                <Grid item xs={12} >

                    <Card>
                        <CardHeader
                            title="Policy Document List"
                            subheader="Please acknowledge"

                        />
                        <CardContent>
                            <Grid container spacing={1}>

                                        <Grid item xs={12} >
                                            <ListModule
                                                title="Policy Documents"
                                                //  ShowDetailLink={false}
                                                AllowDelete={false}
                                                ShowDetailLink={true}
                                                DetailLink={(e) => AddBox(e)}
                                                disabled={false}
                                                DataBack={state.DataBack}
                                            />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        value={state.Acknowledge}
                                                        onChange={(e) => SetValues(e.target.checked, "Acknowledge")} />} label="Acknowledge" />
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        value={state.receive}
                                                        onChange={(e) => SetValues(e.target.checked, "receive")} />} label="Receive Policy by Email" />
                                            </FormGroup>
                                        </Grid>
                                    
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
                ) : (
                
                        <Typography variant="h4" gutterBottom>
                            No Policy documents found. Please click on Next Button
                        </Typography>
                )}

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <DrawerShow
                LoadComponenet="ShowDetailsText"
                CloseDrawer={(e) => CloseDrawer(e)}
                Category="Policy"
                DrawerTitle="Policy Document"
                propss={{
                    Category: "Policy",
                    CompanyId: state.CompanyId,
                    CompanyName: state.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e),
                    //  TriggerUpdate: true,
                    //  SetupSubscription: state.SetupSubscription,
                    Data: state.ItemData,
                    // SubData: state.SubData,
                    id: state.DrawerKey,
                    // AddItem: () => AddItem("ItemAddPage", "Person")
                }}
                key={state.DrawerKey}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </>

    );
}
