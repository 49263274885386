import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
//import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { NewPageId } from '../utils/CommonGraphql';



export default function FullWidthTabs(props) {
  
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:600px)');
  const [DrawerStatus, OpenDrawer] = useState(false);
  const initialState = {
    DataBack: [],
    propss: {},
    DetailComponent: "Details",
  }
  const [state, setState] = useState(initialState);

  const handleChange = (event) => {

    let DetailComponent = "";
    switch (event.target.value) {
      case "Details":
        DetailComponent = "Details";
        break;
      case "Personal":
        DetailComponent = "Personal";
        break;
      case "Equipment":
        DetailComponent = "Equipment";
        break;
      case "Locations":
        DetailComponent = "Locations";
        break;
      case "Setup":
        DetailComponent = "NewServiceSetup";
        break;
      default:
        DetailComponent = "Details";
    }

    setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
      DetailComponent: DetailComponent
    }))

  };

  const AddBox = (add) => {
    console.log("AddBox", add);
    let NewKey = NewPageId("win");
    if (matches) {
      setState(prevState => ({
        ...prevState,
        DetailComponent: "Tabs",
        key: add.key || NewKey,
        ShowDetails: true,
        DetailsProps: {
          title: props.Category,
          ShowEditButton: false,
          PreviewEdit: false,
          Data: add
        },
      }))

    } else {
      //open Drawer
      setState(prevState => ({
        ...prevState,
        DetailComponent: "Tabs",
        ShowDetails: false,
        DetailsProps: {
          title: props.Category,
          ShowEditButton: false,
          PreviewEdit: false,
          Data: add
        },
        key: add.key || NewKey
      }))

      OpenDrawer(true);
    }
  }

  //
  console.log("CompanyDetails_props", props);
  return (
    <div >
      <Paper >
        <AppBar position="static" color="default">
          <Toolbar>
            <Typography variant="h5" >
              Company Details
          </Typography>
            <FormControl
              variant="outlined"
              
            >
              <InputLabel id="demo-simple-select-outlined-label">Options</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.Options}
                name="Options"
                fullWidth
                defaultValue="Details"
                onChange={handleChange}
                label="Options"
              >
                <MenuItem value="Details">Details</MenuItem>
                <MenuItem value="Person">Personal</MenuItem>
                <MenuItem value="Equipment">Equipment</MenuItem>
                <MenuItem value="Locations">Locations</MenuItem>
                <MenuItem value="Setup">Setup</MenuItem>
              </Select>
            </FormControl>
            
          </Toolbar>
        </AppBar>

        <RenderModule
          AddBox={(e) => AddBox(e)}
          LoadComponenet={state.DetailComponent}
          Data={props.Data}
        />

        <DrawerShow
          LoadComponenet={state.DetailComponent}
          CloseDrawer={() => OpenDrawer(false)}
          DrawerTitle="change this"
          propss={state.DetailsProps}
          key={state.key}
          DrawerStatus={DrawerStatus}
          OpenDrawer={(e) => OpenDrawer(e)}
          anchor={state.anchor || "left"}
        />

      </Paper>
    </div>
  );
}
