import React, { useState, useEffect } from 'react';
import { Storage } from "aws-amplify";
import FilePdfOutlined from '@mui/icons-material/PictureAsPdf';
import FileZipOutlined from '@mui/icons-material/Description';
import VideoCameraOutlined from '@mui/icons-material/Videocam';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import aws_exports from '../aws-exports';
import { CheckInDocumentType } from '../utils/Documents';
import {
    Grid, LinearProgress, Paper, Snackbar,
    Stack,
    FormControl, InputLabel, Select, MenuItem, Button,
    TextField,
    Card, CardMedia, Box, CardContent, Typography, CardActions,
    InputAdornment,
    SvgIcon,
} from '@mui/material';
//import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";



function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 150,
    },
    boxx: {
        padding: theme.spacing(2),
        margin: 'auto',
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    input: {
        display: 'none',
    }
}));


const Uploadtest = (props) => {
    const classes = useStyles();
    const initialState = {
        selectedFile: null,
        filess: null,
        loadings: 0,
        alartmessage: "",
        ImageDisplay: [],
        ImageList: [],
        AlartMessage: "",
        DocList: [],
        open: false,
        increment: 1,
        DocumentType: "Service"
    }
    const [state, setState] = useState(initialState);
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);

    useEffect(() => {
        //setState(...props.ImageStateSet)
        if (props.ImageStateSet) {
            props.ImageStateSet(state)
        }

    }, [state.ImageDisplay])



    useEffect(() => {
        console.log("UploadEachDocument.props", props)
        setState(prevState => ({ ...prevState, ...props.ImageStateSetBack }))
    }, [])

    const onChangeHandler = (event) => {
        try {
            // var loading = 0;
            // var loadProgress = 0;
            var filetypes = 0;
            var uuuid = "";
            var fileUploadedarr = {};
            let MediaContentType = "";
            let DocumentType = state.DocumentType;
            const fileUploaded = event.target.files[0];

            fileUploadedarr.name = fileUploaded.name;

            var ext = getExtension(fileUploaded.name);
            MediaContentType = ext.toLowerCase();

            let AlarmMessage = "";
            fileUploadedarr.caption = fileUploaded.name;

            if (state.Title !== "") {
                fileUploadedarr.caption = state.Title;
                fileUploadedarr.name = state.Title;
            }
            

            fileUploadedarr.MediaContentType = MediaContentType;
            //toast.dismiss();
            console.log("fileUploadedarr", fileUploadedarr);
            switch (ext.toLowerCase()) {
                case "zip":
                    filetypes = 1;
                   // fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "7z":
                    filetypes = 1;
                  //  fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "tar":
                    filetypes = 1;
                   // fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "gz":
                    filetypes = 1;
                   // fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "wim":
                    filetypes = 1;
                   // fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FileZipOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "pdf":
                    filetypes = 2;
                  //  fileUploadedarr.caption = fileUploaded.name;
                    fileUploadedarr.blob = (
                        <FilePdfOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "m4v":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "avi":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "mpg":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "mp4":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "wmv":
                    filetypes = 3;
                    fileUploadedarr.blob = (
                        <VideoCameraOutlined style={{ fontSize: "5em" }} />
                    );
                    break;
                case "jpg":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "gif":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "bmp":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "png":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                default:
                    AlarmMessage += "* Please Upload Correct File ";
            }

            console.log("fileUploadedarr", fileUploadedarr);
            if (fileUploaded.size > 20 * 1048576) {
                AlarmMessage += "* File is Larger then 20M ";
            }

            if (props.fileallowed === "image") {
                AlarmMessage += "* Only Image File allowed ";
            }

            if (AlarmMessage !== "") {

                setState((prevState) => ({
                    ...prevState,
                    open: true, AlartMessage: AlarmMessage
                }))
                return;

            }
            console.log("fileUploadedarr", fileUploadedarr);
            const cleanfile_name = fileUploaded.name.replace(/[^a-zA-Z0-9._]/g, "");

            if (filetypes > 2) {
                DocumentType = "Pictures";
            }

            fileUploadedarr.filetypes = filetypes;
            fileUploadedarr.dir = `public/`;
            // fileUploadedarr.Uploaddir = Uploaddir;
            fileUploadedarr.bucket = "app.contractorcheckin.com";
            fileUploadedarr.region = aws_exports.aws_project_region;

            const file_name = `${Date.now()}-${cleanfile_name}`;
            const file_name_dir = file_name;
            fileUploadedarr.filename = file_name;
            fileUploadedarr.thum = "thum_" + file_name;
            fileUploadedarr.width = 100;
            fileUploadedarr.type = fileUploaded.type;

            fileUploadedarr.ImageFullPath = `https://s3.amazonaws.com/app.contractorcheckin.com/public/${file_name}`;
            // `https://${HQData.Bucket}.s3.amazonaws.com/public/${file_name}`

            console.log("fileUploadedarr.final", fileUploadedarr);
            Storage.put(file_name_dir, fileUploaded, {
                bucket: "app.contractorcheckin.com",
                progressCallback: (progress) => {
                    handleprocessbar(progress);
                },
            }).then((ret) => {
                console.log("image back", ret);
                fileUploadedarr.inbucket = "public/" + ret.key;
            });

            uuuid = `rug-${Date.now()}-${state.increment}`;
            setState(prevState => ({ ...prevState, increment: state.increment + 1 }))

            fileUploadedarr.uid = uuuid;

            fileUploadedarr.cat = props.Category;
            fileUploadedarr.DocumentType = DocumentType;
            fileUploadedarr.SearchWords = `${state.DocumentType}, ${fileUploadedarr.caption}`;
            fileUploadedarr.field2 = DocumentType;
            fileUploadedarr.field1 = fileUploadedarr.caption;

            setState(prevState => ({
                ...prevState,
                ImageDisplay: [fileUploadedarr, ...state.ImageDisplay]
            }))

            console.log("fileUploadedarr", fileUploadedarr);

            if (filetypes > 2) {
                let ImageList = [...state.ImageList, fileUploadedarr]
                console.log("ImageList", ImageList);
                props.ImageList(ImageList);
                // props.ImageStateGet(state)
                setState(prevState => ({
                    ...prevState,
                    ImageList: ImageList,
                    Title: ""
                }))
            }

            if (filetypes < 3) {
                let DocList = [...state.DocList, fileUploadedarr]
                console.log("DocList", DocList);
                props.DocumentList(DocList);
                // props.ImageStateGet(state)
                setState(prevState => ({
                    ...prevState,
                    DocList: DocList,
                    Title: ""
                }))
            }

        } catch (err) { console.log("Image err", err); }
    };

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            AlartMessage: "",
            open: false
        }))
    };

    const onRemoveItem = (i) => {
        const list = state.list.filter((item, j) => i !== j);
        setState(prevState => ({ ...prevState, list: list }))
    };


    const onUpdateItems = () => {
        const list = state.list.map((item) => item + 1);
        setState(prevState => ({ ...prevState, list: list }))
    };

    const onAddItem = () => {
        const list = state.list.concat(state.value);
        setState(prevState => ({ ...prevState, list: list, value: "" }));
    };

    const captionText = (e, index, uid) => {
        try {
            let emailtemparr = state.ImageDisplay;
            let filetypes = emailtemparr[index].filetypes;
            emailtemparr[index].caption = e.target.value;
            let uuid = emailtemparr[index].uid;
            setState(prevState => ({ ...prevState, ImageDisplay: emailtemparr }));

            console.log("emailtemparr", emailtemparr)

            if (filetypes > 2) {
                //let ImageList = [...state.ImageList, fileUploadedarr]

                let toUpdate = state.ImageList;
                let newArray = toUpdate.findIndex(obj => obj.uid === uuid);
                console.log("ImageList-newArray", uuid, newArray, toUpdate);
                if (newArray > -1) {
                    toUpdate[newArray].caption = e.target.value;
                    props.ImageList(toUpdate);
                    // props.ImageStateGet(state)
                    setState(prevState => ({ ...prevState, ImageList: toUpdate }));
                    //setState({ ImageList: toUpdate });
                }
            }

            if (filetypes < 3) {
                let toUpdate = state.DocList;
                let newArray = toUpdate.findIndex(obj => obj.uid === uuid);
                console.log("ImageList-newArray", uuid, newArray, toUpdate);
                if (newArray > -1) {
                    toUpdate[newArray].caption = e.target.value;
                    props.DocumentList(toUpdate);
                    // props.ImageStateGet(state)
                    setState(prevState => ({ ...prevState, DocList: toUpdate }));
                    //setState({ DocList: toUpdate });
                }

            }
        } catch (err) { console.log("captionText err", err) }
    };

    const onDeleteImage = (e, index, uid) => {
        try {
            let emailtemparr = state.ImageDisplay;
            let filetypes = emailtemparr[index].filetypes;
            let uuid = emailtemparr[index].uid;
            emailtemparr.splice(index, 1);
            setState(prevState => ({ ...prevState, ImageDisplay: emailtemparr }));

            if (filetypes > 2) {
                //let ImageList = [...state.ImageList, fileUploadedarr]

                let toDelete = new Set([uuid]);
                let newArray = state.ImageList.filter(obj => !toDelete.has(obj.uid));

                console.log("ImageList-newArray", newArray);
                props.ImageList(newArray);
                // props.ImageStateGet(state)
                setState(prevState => ({ ...prevState, ImageList: newArray }));
                //setState({ ImageList: newArray });
            }

            if (filetypes < 3) {

                let toDelete = new Set([uuid]);
                let newArray = state.DocList.filter(obj => !toDelete.has(obj.uid));

                console.log("DocList-newArray", newArray);
                props.DocumentList(newArray);
                // props.ImageStateGet(state)
                setState(prevState => ({ ...prevState, DocList: newArray }));
                //setState({ DocList: newArray });
            }

            //props.imagearr(emailtemparr);
            //props.returnemails(emailtemparr);
        } catch (err) { console.log("onDeleteImage err", err) }
    };

    const handleprocessbar = (process) => {
        let loadiss = Math.round((process.loaded / process.total) * 100);
        setState(prevState => ({ ...prevState, loadings: loadiss }));
    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }


    //    <Card
    //      extra={
    //        <a onClick={(e) => onDeleteImage(e, index, post.uid)}>
    //          Delete
    //          </a>
    //      }
    //      key={post.uid}
    //      size="small"
    //      style={{ width: 150 }}
    //      bordered={true}
    //      cover={
    //        post.filetypes === 4 ? (
    //          <img alt="preview" src={post.blob} />
    //        ) : (
    //            post.blob
    //          )
    //      }
    //    >
    //      <div key={index}>

    //      </div>
    //    </Card>
    //  </div>
    //));

    console.log("UploadEachDocument.state", state)

    return (
        <div className={classes.root}>
            <Paper className={classes.boxx}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid xs={12} item>
                        <TextField
                            fullWidth
                            label="Document Title"
                            variant="outlined"
                            placeholder="Title"
                            value={state.Title}
                            onChange={(e) => SetValues(e.target.value, "Title")}
                        />

                    </Grid>
                    <Grid xs={12} item>
                        <FormControl variant="outlined" style={{ width: '300px' }} >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Document Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                defaultValue="Service_Report"
                                error={state.ErrorMessage}
                                required={true}
                                disabled={state.disabled}
                                autoWidth={false}
                                value={state.DocumentType}
                                onChange={(e) => {
                                    SetValues(e.target.value, "DocumentType");
                                }}
                                label="Document Type"
                            >
                                
                                {CheckInDocumentType.map((o, indd) => (
                                    <MenuItem key={indd + o.key} value={o.key}>
                                        {o.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl >
                    </Grid>
                    <Grid xs={12} item>
                        <input
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={onChangeHandler}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="contained" color="primary" component="span"
                                startIcon={<CloudUploadIcon />}
                            >
                                Add Document / Image
                            </Button>

                        </label>

                    </Grid>
                    <Grid xs={12} item>
                        <LinearProgress variant="determinate" value={state.loadings} />
                    </Grid>
                    <Grid xs={12} item>
                        <Grid container spacing={2}>
                            {state.ImageDisplay.map((post, index) => (
                                <Grid key={"grdd" + index} item xs={12}>
                                    
                                    <Card sx={{ display: 'flex' }} key={"cardgrdd" + index}>

                                        {post.filetypes === 4 ? (
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 151 }}
                                                image={post.blob}
                                                alt={post.caption}
                                        />
                                        ) : (post.blob)}

                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                <Typography component="div" variant="h5">
                                                    {post.caption}
                                                </Typography>
                                                
                                            </CardContent>
                                            <CardActions>
                                                <Button key={"btn" + index} size="small" color="primary" onClick={(e) => onDeleteImage(e, index, post.uid)} >
                                                    Delete
                                                </Button>
                                            </CardActions>
                                        </Box>

                                        

                                    </Card>

                                    {/* 
                                    <Paper className={classes.paper}>
                                        <Grid container justifyContent="center" spacing={1}>
                                            <Grid item>
                                                {post.filetypes === 4 ? (
                                                    <img
                                                        key={"img" + index}
                                                        className={classes.img}
                                                        alt="complex"
                                                        src={post.blob}
                                                    />
                                                ) : (post.blob)}

                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    key={"cptn" + index}
                                                    label="Caption"
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="Caption"
                                                    value={post.caption}
                                                    onChange={(e) => captionText(e, index, post.uid)}
                                                />

                                            </Grid>
                                            <Grid item>
                                                <Button key={"btn" + index} size="small" color="primary" onClick={(e) => onDeleteImage(e, index, post.uid)} >
                                                    Delete
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    */}

                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={state.open}
                onClose={handleClose}
                message={state.AlartMessage}
                key="w87482ows"
            />
        </div>
    );
}



export default Uploadtest;
