import React, { useState } from 'react';
import {
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,

    IconButton,
    Stack,
    Link
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachEmailIcon from '@mui/icons-material/ForwardToInbox';
import { red } from '@mui/material/colors';

import { Buffer } from 'buffer';

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

export default function ClipboardCopy(props) {
    const [isCopied, setIsCopied] = useState(false);
    let copyText = "";
    let mainstring = "";

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    if (props.ActiveKey && props.ActiveKey !== "") {
        let CompanyN = Buffer.from(props.Activefield1).toString('base64');
        let CID = Buffer.from(props.ActiveKey).toString('base64');
        copyText = `https://app.servicewithtext.com/Invite/${CompanyN}/${CID}`;
    }

    // onClick handler function for the copy button
    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

   

    if (props.SMSList && props.SMSList.length > 0) {
        mainstring = "mailto:?subject=";
        mainstring += encodeURIComponent("Sign Up Link");
        mainstring += "&body=";
        mainstring += encodeURIComponent("New way to request service with a simple text message.  Please save folloiwng number(s) in your contact list. Simply send us a text message to this number when service is needed.");
        mainstring += encodeURIComponent(" Also, please forward this email to other people in your organization who are authorized to place service calls.\n\n");

        props.SMSList.map(each => {
            mainstring += encodeURIComponent(formatPhoneNumber(each) + "\n");
        });

        mainstring += encodeURIComponent("\nUse following link to setup your account.  Please keep in mind that the system recognizes you by your mobile number. Make sure the mobile number is correct.\n\n");
        mainstring += copyText
    }

    console.log("InviteCompanyPersonnel.props", props)

    return (
        <div>
            <Card>

                <CardContent>
                    <Typography sx={{ color: red[500] }} variant="h4" gutterBottom component="div">
                        {copyText}
                    </Typography>

                </CardContent>
                <CardActions>
                    <Stack
                        direction={{ xs: 'row' }}
                        spacing={2}
                    >
                        <a href={mainstring}>
                            <Button
                                startIcon={<AttachEmailIcon />}
                            >
                                Email Link
                            </Button>
                        </a>

                        <Button
                            onClick={()=>handleCopyClick(copyText)}
                            startIcon={<ContentCopyIcon />}
                        >
                            {isCopied ? `Copied!` : `Copy Link`}
                        </Button>

                    </Stack>
                </CardActions>

            </Card>
        </div>
    );
}
