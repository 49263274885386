import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
    FormControl, Typography,
    RadioGroup, FormControlLabel, Radio
} from '@mui/material';


export default function RadioButtonsGroup(props) {
    const initialState = {
        FormData: [],
        id: "",
        KeyId: "",
        loading: false,
        AlarmMessage: "",
        OpenAlert: false,
        CountryCode: "us",
        Message: "",
        AreaCode: "",
        NumberList: [],
        SelectedNumber: "",
        AccountType: "Individual"
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let StepValueArray = {};

        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);

            setState(prevState => ({
                ...prevState,
                id: props.id,
                Category: props.Category,
                StepValueArray: StepValueArray,
                KeyId: props.KeyId,
                title: props.title,
                loading: true,
            }));
        }

    }, [props.StepValueArray])



    const handleNext = () => {

        if (props.AccountType) {
            props.AccountType(state.AccountType);
        }

        if (props.handleNext) {
                props.handleNext();
            }
    }
    
    const KeyArray = (data, cat) => {

        let newdata = data.target.value;

        setState(prevState => ({
            ...prevState,
            [cat]: newdata
        }));


    }

    console.log("SignUpWizStep.state", state)
    console.log("SignUpWizStep.props", props)
    
    return (
        <div key={props.KeyId}>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <Typography variant="h4" gutterBottom>Account Type</Typography>

                </Grid>

                <Grid item xs={12}>

                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="AccountType"
                            // defaultValue="High"
                            name="radio-buttons-group"
                            //onChange={(e) => KeyArray(e, "Priority")}
                            onChange={(data) => KeyArray(data, "AccountType")}
                            value={state.AccountType || ""}
                        >

                            <FormControlLabel value="Individual" control={<Radio />} label="Individual - you control who has access to your account from your company" />

                            <FormControlLabel value="Corporate" control={<Radio />} label="Corporate Branch - anyone from your company with the same email domain and zip code will be added to same account, and will have access to the data." />

                            
                        </RadioGroup>
                    </FormControl>

                </Grid>
               
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
                        

        </div>

    );
}
