
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, Container, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import GlobalStyles from './components/GlobalStyles';
//import 'react-perfect-scrollbar/dist/css/styles.css';
//import './mixins/chartjs';
//import Amplify, { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { Amplify } from 'aws-amplify';
import theme from './theme';
import routes from './routes';
import awsconfig from './aws-exports';
//import { TestRegister } from './serviceWorker'
import { GlobalDebug } from "./RemoveConsole";
import ContractorSignUpWiz from './MainWebsite/ContractorSignUpWiz';


Amplify.configure(awsconfig);

const App = (props) => {
    const dispatch = useDispatch()
    const Auth = useSelector(state => state.auth);

   
    //useEffect(() => {
    //    (process.env.NODE_ENV === "production" ||
    //        process.env.REACT_APP_ENV === "STAGING") &&
    //        (window.dev && window.dev !== 786) &&
    //        GlobalDebug(false);
    //}, []);

    useEffect(() => {

        if (window.WebsiteType && window.WebsiteType !== "") {
            dispatch({ type: 'WEBSITE_TYPE', payload: window.WebsiteType });

            let HQData = {
                Bucket: "app.servicewithtext.com",
                SubDomain: "app.servicewithtext.com",
                avatar: "https://www.servicewithtext.com/images/Logo_SD_White3.png",
                Logo: "https://www.servicewithtext.com/images/Logo_SD_Black3.png"
                //   Logo: "https://www.ServiceWithText.com/images/Logo_svc_Black.png"
                // avatar: "https://www.ServiceWithText.com/images/Logo_svc_White.png",
            };

            switch (window.WebsiteType) {
                case "ContractorCheckIn":
                    HQData = {
                        Bucket: "app.contractorcheckin.com",
                        SubDomain: "app.contractorcheckin.com",
                        avatar: "https://www.contractorcheckin.com/images/Logo_checkin_White.png",
                        Logo: "https://www.contractorcheckin.com/images/Logo_checkin_Black.png"
                    }
                    break;
                case "ServiceWithText":
                    HQData = {
                        Bucket: "app.servicewithtext.com",
                        SubDomain: "app.servicewithtext.com",
                        avatar: "https://www.servicewithtext.com/images/Logo_SD_White3.png",
                        Logo: "https://www.servicewithtext.com/images/Logo_SD_Black3.png"
                        //   Logo: "https://www.ServiceWithText.com/images/Logo_svc_Black.png"
                        // avatar: "https://www.ServiceWithText.com/images/Logo_svc_White.png",
                        
                    }
                    break;
                default:
                    HQData = {
                        Bucket: "app.servicewithtext.com",
                        SubDomain: "app.servicewithtext.com",
                        avatar: "https://www.servicewithtext.com/images/Logo_SD_White3.png",
                        Logo: "https://www.servicewithtext.com/images/Logo_SD_Black3.png"
                        //   Logo: "https://www.ServiceWithText.com/images/Logo_svc_Black.png"
                        // avatar: "https://www.ServiceWithText.com/images/Logo_svc_White.png",
                    }
            }

            dispatch({ type: 'HQ_DATA', payload: HQData });

        }
        
    }, []);

    const routing = useRoutes(routes(Auth));

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                {/*
                <ContractorSignUpWiz />
                */}
                <Container maxWidth="xl" >
                    <Box mt={2}>
                        {routing}
                        </Box>
                </Container>
                
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

//TestRegister();

export default App;
