import React, { useState } from "react";
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';


const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

const Newpageiddd = NewPageId("toolbar")

const Toolbar = ({ className, ...rest }) => {
    const classes = useStyles();
    const [SelectCompany, SetSelectCompany] = useState(false);
    //const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    //const cid = useSelector(state => state.cid);
    const [DrawerStatus, OpenDrawer] = useState(false);

    const initialState = {
        SearchWords: ""
    }
    const [state, setState] = useState(initialState);

    //const [UserCid, SetUserCid] = useState(UserData);

    //const initialState = {
    //  CurrentCompany: UserCid.CompanyName,
    //};

    // const [state, setState] = useState(initialState);

    //const GetCompanyName = (data) => {
    //  //console.log("data", data);
    //  let CurrentCompany = data.field1;

    //  //if (rest.AddCompany) {
    //  //  CurrentCompany = `${state.CurrentCompany} + ${data.field1}`;
    //  //}

    //  setState(prevState => ({
    //    ...prevState,
    //    CurrentCompany: CurrentCompany,
    //  }));

    //  SetSelectCompany(false);
    //  rest.CompanyData(data);

    //}

    const CommonState = (itemvalue) => {

        setState(prevState => ({
            ...prevState,
            SearchWords: itemvalue
        }));

        if (rest.NarrowSearch) {
            rest.NarrowSearch(itemvalue)
        }
    }

    const AddItem = (data) => {
        console.log("ListItems.AddItem", data);
        let DetailComponent = "AddToGlobal";

        //if (data === "ESC") {
        //    DetailComponent = "ESCSetupPage"
        //}

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            DrawerKey: NewPageId("DrawerKey"),
            DetailsProps: [],
        }))

        OpenDrawer(true);
    }

    const AddBulk = (data) => {
        console.log("ListItems.AddItem", data);
        let DetailComponent = "UploadExcel";

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            DrawerKey: NewPageId("DrawerKey"),
            DetailsProps: [],
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        // GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    console.log('ToolbarHeading.state', state);
    console.log('ToolbarHeading.props', rest);

    return (
        <div
            className={clsx(classes.root, className)}
            key={"key" + Newpageiddd}
            id={"id" + Newpageiddd}
        >
            <Box mt={2}>
                <Grid container spacing={1} >
                    {!rest.HideHeading && (
                        <>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="h4" component="span">
                                    {rest.Headings}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} >

                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    {UserData.CustomerType === "Contractor" ? (
                                        <>
                                    {/* 
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => AddBulk(rest.Category)}
                                        disabled={rest.disabled}
                                        sx={{mr: 1}}
                                    >
                                        Export Excel
                                    </Button>
                                   */}

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => AddItem(rest.Category)}
                                        disabled={rest.disabled}
                                    >
                                        Add/Edit ACCOUNTS
                                    </Button>
                                    </>
                                    ):null }
                                </Box>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} >

                        <Card>
                            <CardContent>
                                <Grid container spacing={1} >
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        fullWidth
                                        disabled={rest.disabled}
                                        onChange={(e) => CommonState(e.target.value)}
                                        placeholder={`Search ${rest.Category}`}
                                        variant="outlined"
                                    />
                                </Grid>
                                    <Grid item xs={12} sm={6} >

                                        {/* 
                                        <FormControl variant="outlined" >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Branch
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                //id={`select-${Item.key}-${index}`}
                                                //defaultValue={90}
                                                //  error={state.ErrorMessage}
                                               // required={true}
                                                //  disabled={state.disabled}
                                                autoWidth={true}
                                                value={state.days}
                                                onChange={(e) => {
                                                    ChangeLength(e.target.value);
                                                }}
                                                label="Branch"
                                            >

                                                <MenuItem key={0} value={30}>
                                                    Last 30 days
                                                </MenuItem>
                                                <MenuItem key={1} value={60}>
                                                    Last 60 days
                                                </MenuItem>
                                                <MenuItem key={2} value={90}>
                                                    Last 90 days
                                                </MenuItem>
                                            </Select>
                                        </FormControl >
                                        */}

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>
                </Grid>
            </Box>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerKey={state.DrawerKey}
                DrawerTitle={state.Category}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    Category: rest.Category,
                    key: state.DrawerKey,
                    id: state.DrawerKey,
                    CompanyId: rest.CompanyId,
                    GlobalData: rest.GlobalData,
                    DataUpdate: (e) => rest.DataUpdate(e)
                    //TriggerUpdate: true,
                    //CompanyName: rest.CompanyName,
                    //CustomerType: rest.CustomerType,
                    //ImageUpload: true,
                    //...state.DetailsProps
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>
    );
};

export default Toolbar;
