/*
 * 
 * 
 * Add new Urgency, Trade, Area, etc.
 * 
 * 
 * 
 */

import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import DynamicForms from '../Forms/FormShow';
import ItemAddForms from '../Add/ItemAddForms';
import { schedule, emergency, todo, quote } from '../utils/UrgencyOptions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const Account = (props) => {
  const classes = useStyles();
  const params = useParams();
  let query = useQuery();
  const UUid = NewPageId(props.Category);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  console.log('cid cid ', cid)
  const initialState = {
    Category: "",
    //FormJSON: [],
    FormData: [],
    AlarmMessage: "",
    Title: "",
    Description: "",
    OpenAlert: false,
    ImagePic: ""
  }
  const [state, setState] = useState(initialState);

  
  const SaveData = (FormData) => {
    console.log('SaveData', FormData);
    try {

      //let FormData = state.FormData;
      let key = `Data::Online::Lambda::${props.Category}#${UUid}`;

      

      var currentUnixTime = new Date();
      var currentTime = currentUnixTime.toISOString();

      //   let posterId = User;

      let Field1 = "";
      let Field2 = "";
      let SearchWords = "";
      let MissingInfoFlag = false;
      let dataJSON = "";
      let FormdataJSON = {};
      let PhotoService = [];
      let Documents = [];
      let AlarmMessage = 'Please enter ';
      let DBInput = {};
      let DBInput2 = {};
      let Phone = "";
      let Email = "";
      let InputArray = [];
      let CompanyId = "";
      let CompanyName = "";
      let FormDD = {};
      let avatar = "";
      let UrgencyExtra = {};
      let UrgencyType = "";
      let ServiceSteps = emergency;

      

      //make sure cid and companyid exist
      //if (cid && cid.CompanyId && cid.CompanyId !== "") {
      //  //console.log('CIDDDDDD', cid)
      //  CompanyId = cid.CompanyId;
      //  CompanyName = cid.CompanyName;
      //} else {
      //  MissingInfoFlag = true;
      //  AlarmMessage += `* Please select Company `;
      //}

      // put each form data in db
      if (FormData && FormData.length > 0) {
        FormData.map((EachForm, index) => {

          console.log('EachForm', EachForm);

          if (!EachForm.ValueAdded) {
            MissingInfoFlag = true;
            AlarmMessage += `* Please complete ${EachForm.FormName} `;
          }

          EachForm.FormData.map((each, ind) => {
            //console.log('AddSetupSelection.each', each)
            if (each.required) {
              if (each.VariableInput === "") {
                //trigger alarm message if input value missing
                MissingInfoFlag = true;
                AlarmMessage += `* ${each.label} `;
              } 
            }
            
              if (each.extra === "field1") {
                Field1 = each.VariableInput;
              }
              if (each.extra === "field2") {
                Field2 = each.VariableInput;
              }

            if (each.extra === "Urgency") {
              //putting the urgency type - todo, emergency, schedule, quote
              key = `Data::Online::Lambda::${props.Category}::${each.SelectedId}#${UUid}`;
              UrgencyType = each.SelectedId;
            }
          
            SearchWords += `${each.VariableInput} | `;

          })
        })

        if (props.Category === "Urgency" && UrgencyType !== "") {

          //schedule, emergency, todo, quote
          switch (UrgencyType) {
            case "emergency":
              ServiceSteps = emergency;
              break;
            case "schedule":
              ServiceSteps = schedule;
              break;
            case "todo":
              ServiceSteps = todo;
              break;
            case "quote":
              ServiceSteps = quote;
              break;
            default:
              ServiceSteps = emergency;
          }

          UrgencyExtra = {
            ServiceSteps: ServiceSteps,
          }
        }

        FormdataJSON = {
          ...UrgencyExtra,
          FormData: FormData,
          SearchWords: SearchWords,
          CompanyId: CompanyId,
          CompanyName: CompanyName,
          //avatar: avatar,
          id: UUid,
          key: key,
          field1: Field1,
          field2: Field2,
          ContractorId: ContractorData.ContractorId,
          date: currentTime,
          posterId: UserData,
          //PhotoService: PhotoService,
          //Documents: Documents,
          //phone: Phone,
          //email: Email,
          pk1: ContractorData.ContractorId,
          sk1: key,
          //gsi1Pk2: "Notify::" + ContractorData.ContractorId,
          //gsi1Sk2: "3000-01-01",
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: key,
        }

        

        dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))


        FormDD = {
          ...FormData,
          SearchWords: SearchWords,
        }

        console.log('AddSetupselection.FormdataJSON', FormdataJSON)

      }

      //if alerts
      if (MissingInfoFlag) {
        setState(prevState => ({
          ...prevState,
          AlarmMessage: AlarmMessage,
          OpenAlert: true
        }));
      } else {
        //if no alerts

        DBInput = {
            pk1: ContractorData.ContractorId,
            sk1: key,
            //gsi1Pk2: "Notify::" + ContractorData.ContractorId,
            //gsi1Sk2: "3000-01-01",
            gsi2Pk2: ContractorData.ContractorId,
            gsi2Sk2: key,
            dataJSON: dataJSON
          }

        InputArray.push(removeEmptyStringElements(DBInput));

        console.log('FormdataJSON', FormdataJSON)
        console.log('DBInput', DBInput)
        
        if (InputArray.length > 0) {
          API.graphql(graphqlOperation(createBatch, { input: InputArray }))
            .then((results) => {
              console.log('createBatch Data ', results);

              if (props.CloseDrawer) {
                props.CloseDrawer(FormDD);
              }
            })

          if (props.CloseDrawerAdd) {
            props.CloseDrawerAdd(FormdataJSON);
          }
        }

      } // end of no alerts

    } catch (err) { console.log("SaveData err", err); }
  }

  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));
    //Snackbar, Alert
  };

  //const OnFormSubmit = (e) => {
  //  setState(prevState => ({ ...prevState, FormData: [e] }));
  //}

  const ReturnBackData = form => {
    console.log('formItemAddForm', form);
    setState(prevState => ({
      ...prevState,
      FormData: form
    }));
  }


  return (
    <Page
      className={classes.root}
      title={props.Category}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >

            <ItemAddForms
              ReturnBackData={e => ReturnBackData(e)}
              Category={props.Category}
              SaveData={(e) => SaveData(e)}
            />

            {/*  
            <DynamicForms
              key={"keyy"+props.Category}
              id={"iddd" + props.Category}
              //SaveDetails={props.SaveDetails}
              Category={props.Category}
              FormType="Setup"
              //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
              title={props.Category}
              ShowEditButton={false}
              UpdateFormData={e => console.log("UpdateFormData", e)}
              PreviewEdit={false}
              EditItem={e => console.log("EditItem", e)}
              FormData={FormJSON}
              onSubmit={(model) => OnFormSubmit(model)}
            />
             */}
          </Grid>
        </Grid>
      </Container>

          <Snackbar
              open={state.OpenAlert}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              message={state.AlarmMessage}
          />
          {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}
    </Page>
  );
};

export default Account;
