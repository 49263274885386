import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import {
  //TextField,
  //Collapse,
  //Typography,
  Paper,
  Grid,
  Button,
  //Backdrop,
  //CircularProgress,
  //Card,
  //FormGroup,
  //CardContent,
  //CardActions,
  //InputLabel, FormControl, MenuItem, Checkbox, 
  //RadioGroup,
  //FormControlLabel,
  //Radio, IconButton, CardHeader,
  //Switch,
  //Tooltip, Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));


const ColorPicker = props => {
  const classes = useStyles();
  let initialState = {
    background: '#fff',
  };

  const [state, setState] = useState(initialState);

  const handleChangeComplete = (color) => {
    
    setState(prevState => ({
      ...prevState,
      background: color.hex
    }));

    console.log("color", color)

   // props.ColorPickerHax(color.hex)

    let NewColor = props.Colors;
    NewColor[props.index].color = color.hex;

    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(NewColor)
    }

  };

  return (

    <div >
      <Paper className={classes.paper}>

        <Grid container spacing={2}>

          
          <Grid item xs={12}>
      <SketchPicker
        color={ state.background }
        onChangeComplete={ handleChangeComplete }
            />
          </Grid>

          <Grid item xs={12}>

            <Button
              variant="contained"
              color="primary"
              onClick={() => props.CloseDrawer()}
              size="small">
              Submit</Button>

          </Grid>

        </Grid>
      </Paper>
      </div>
    );
  
}

export default ColorPicker;
