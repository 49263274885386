/*
 * 
 * List items in a Card
 * show Avator or first letter of the text
 * 
 <ListModule
                    AddBox={(e) => AddBox(e)}
                    SearchWords={state.SearchWords}
                    field1="Email/API Notification Triggers"
                    AllowAdd={true}
                    AddItem={(e) => AddItem(e)}
                    title={state.Category}
                    disabled={false}
                    LoadComponenet={state.ListType}
                    propss={state.propss}
                  />
 * 
 */




import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
//import { useNavigate } from "react-router-dom";
import {
    Box, List, Button,
    ListItem, IconButton,
    ListItemAvatar, Avatar,
    ListItemText, Typography
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { red } from '@mui/material/colors';
import Skeleton from '../components/Skeleton';
//import ListLines from './ListLines';
import { NewPageId, FindCategoryIcon, isNotEmptyObject } from '../utils/CommonGraphql';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMediaQuery from '@mui/material/useMediaQuery';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { API, graphqlOperation } from "aws-amplify";
import { deleteSvcbotDb } from '../graphql/mutations';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


//const useStyles = {
//    root: {
//        width: "100%",

//    },
//    item: {
//        padding: 1
//    },
//    avatar: { marginRight: 5 },
//    paginator: {
//        justifyContent: "center",
//        padding: "10px"
//    }
//};

const AllProjects = props => {
    // const classes = useStyles;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);

    const initialState = {
        DataBack: [],
        propss: {},
        DeleteItem: "",
        ShowDetailLink: true
    }
    // let location = useLocation();
    const [state, setState] = useState(initialState);
    const itemsPerPage = 10;
    const [page, setPage] = useState(1);
    const [noOfPages, SetnoOfPages] = useState(0);

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        let DataBack = props.DataBack;
        let uniqueId = NewPageId("ListModule");
        if (DataBack && DataBack.length > 0) {
            console.log("ListModule.Props", DataBack);
            setState(prevState => ({
                ...prevState,
                DataBack: DataBack,
                uniqueId: uniqueId
            }));
            SetnoOfPages(Math.ceil(DataBack.length / itemsPerPage))
        }

    }, [props.DataBack])

    useEffect(() => {

        if (props.ShowDetailLink != null) {
            setState(prevState => ({
                ...prevState,
                ShowDetailLink: props.ShowDetailLink,
            }));
        }

    }, [props.ShowDetailLink])

    const handleClick = (event) => {
        props.AddBox(event);
    };

    const NarrowSearch = (search) => {
        try {
            let Newdata = [];
            let RawData = props.DataBack;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.SearchWords) {
                        if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                            Newdata.push(str);
                        }
                    }
                })
                //
                console.log("Newdata search", Newdata);
            } else {
                Newdata = RawData;
            }
            SetnoOfPages(Math.ceil(Newdata.length / itemsPerPage))
            setState(prevState => ({ ...prevState, DataBack: Newdata }));
        } catch (err) { console.log("NarrowSearch err", err); }
    }

    useEffect(() => {
        if (props.SearchWords && props.SearchWords !== "") {
            NarrowSearch(props.SearchWords);
        }
    }, [props.SearchWords])

    console.log("ListModule.props", props);
    console.log("ListModule.state", state);

    const HandleDeleteItem = () => {

        if (state.DeleteItem !== "" && isNotEmptyObject(state.DeleteItem)) {
            console.log("ListModule.DeleteItem1", state.DeleteItem);
            let NewDBinput = {
                pk1: state.DeleteItem.pk1,
                sk1: state.DeleteItem.sk1
            }
            console.log("ListModule.DeleteItem.NewDBinput", NewDBinput);
            API.graphql(graphqlOperation(deleteSvcbotDb, { input: NewDBinput }))
                .then((results) => {

                    let DataBack = state.DataBack;
                    let indd = DataBack.findIndex(x => x.sk1 === state.DeleteItem.sk1)
                    DataBack.splice(indd, 1);
                    setState(prevState => ({
                        ...prevState,
                        DataBack: DataBack,
                        DeleteItem: ""
                    }));
                    console.log("ListModule.DeleteItem2", indd, DataBack);
                    console.log("ListModule.DeleteItem.results", results);
                })
        }
        setOpen(false)
    }

    const ConfirmDelete = (item) => {
        setOpen(true)
        setState(prevState => ({ ...prevState, DeleteItem: item }));
    }

    const handleClose = () => {
        setOpen(false);
    };

   // console.log("ListModule.state.DataBack", state.DataBack, props.DataBack);

    return (
        <>
            {state.DataBack && state.DataBack.length > 0 ? (
                <List key={state.uniqueId} sx={{ width: '100%' }}>
                    {state.DataBack.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((each, i) => (
                            <>

                                <ListItem
                                    key={state.uniqueId + i + "listItem"}
                                    secondaryAction={
                                        <>
                                            {state.ShowDetailLink && (
                                                <IconButton
                                                    onClick={() => props.DetailLink(each)}
                                                    edge="end" aria-label="Info">
                                                    <MoreVertIcon />
                                                </IconButton>
                                            )}
                                            {props.AllowDelete && (
                                                <IconButton
                                                    onClick={() => ConfirmDelete(each)}
                                                    edge="end" aria-label="Info">
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            )}
                                        </>
                                    }
                                    alignItems="flex-start">
                                    {matches && (
                                        <ListItemAvatar
                                            key={state.uniqueId + i + "list2Item"}
                                        >
                                            {each.avatar ?
                                                (<Avatar alt="Logo" src={each.avatar} />) : (
                                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                                        {FindCategoryIcon(each.Category)}
                                                    </Avatar>
                                                )}
                                        </ListItemAvatar>
                                    )}

                                    <ListItemText
                                        key={state.uniqueId + i + "listtexxxt"}
                                        primary={each.field1}
                                        secondary={
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {each.field2}
                                                </Typography>                                            
                                        }
                                    />
                                </ListItem>
                                {/*  
                                <Divider key={state.uniqueId + i + "divider"} variant="inset" component="li" />
                                */}
                            </>
                        )
                        )}
                </List>
            ) : (<Skeleton />)
            }
            
            {
                noOfPages > 1 && (
                    <Box sx={{ alignItems: 'center' }}>
                        <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChange}
                            defaultPage={1}
                            color="primary"
                            size="small"
                            showFirstButton
                            showLastButton

                        />
                    </Box>
                )
            }

            <Dialog
               fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Delete Item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={HandleDeleteItem} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
};

export default AllProjects;
