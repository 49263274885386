import React, { useState, useEffect } from 'react';
import { Drawer, Hidden, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
//import RenderModule from './RenderModule';
import DynamicForms from '../Forms/FormShow';
import { NewPageId } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';

const FormId = NewPageId("form");
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  field: {
    margin: '30px 0',
  },
}));

const AllForms = (props) => {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = useState(false);
  const initialState = {
    Category: "",
    FormData: {},
    ExistingId: "",
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      Category: props.Category,
      FormData: props.FormData.FormData,
      ExistingId: props.FormData.key,
      PageId: FormId,
    }))
  }, [props])

  console.log("props", props)

  const PreviewForms = () => {
    SetDeawerOpen(true);
  }

  const ClosingDrawer = (data) => {

    setState(prevState => ({
      ...prevState,
      Category: data.Category,
      FormData: data.FormData,
      ExistingId: data.key,
    }))

    SetDeawerOpen(false);
  }

  return (
    <div className={classes.root} key={state.PageId}>
      <Grid
        container
        spacing={1}
      >
        <Grid item xs={12}>
          <DynamicForms
            key={FormId}
            title={`Preview ${state.Category}`}
            ShowEditButton={false}
            UpdateFormData={e => console.log("UpdateFormData", e)}
            PreviewEdit={false}
            EditItem={e => console.log("EditItem", e)}
            FormData={state.FormData}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => PreviewForms()}
          >
            Edit / Customize
                  </Button>
        </Grid>
      </Grid>

      <DrawerShow
        LoadComponenet="FormCreatorClass"
        CloseDrawer={() => ClosingDrawer()}
        propss={{
          CloseDrawer: (e) => ClosingDrawer(e),
          Category: state.Category,
          FormData: state.FormData,
          ExistingId: state.ExistingId,
        }}
        DrawerStatus={DeawerOpen}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="right"
      />
     
    </div>
  );
};

export default AllForms;

