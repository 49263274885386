import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
  TextField,
  Collapse,
  Paper, Grid, Button,
  Backdrop, 
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
import { TotalList, schedule, emergency, todo, quote } from '../utils/UrgencyOptions';
import StepBox from '../Setup/StepBox';

//import Page from '../components/Page';

const UUid = NewPageId("Urgency");

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

var dateToday = new Date();
let currentTime = dateToday.toISOString().replace("Z", "");

export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);

  const initialState = {
    TotalStepList: TotalList,
    IncludedStepList: [],
    NotIncludedStepList: [],
    title: ""
  }
  const [state, setState] = useState(initialState);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    let CompanyId = ContractorData.ContractorId;
    let CompanyName = ContractorData.ContractorName;
    let IncludedStepList = TotalList.filter(x => x.include === true);
    let NotIncludedStepList = TotalList.filter(x => x.include === false);

    if (props.CompanyId) {
      CompanyId = props.CompanyId;
      CompanyName = props.CompanyName;
    }
      
      setState(prevState => ({
        ...prevState,
        TotalStepList: TotalList,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        IncludedStepList: IncludedStepList,
        NotIncludedStepList: NotIncludedStepList,
      }));

  }, [])

  useEffect(() => {
    if (props && props.UrgencyJSON) {

      let TotalStepList = props.UrgencyJSON;
      let IncludedStepList = TotalStepList.filter(x => x.include === true);
      let NotIncludedStepList = TotalStepList.filter(x => x.include === false);

      setState(prevState => ({
        ...prevState,
        TotalStepList: TotalStepList,
        IncludedStepList: IncludedStepList,
        NotIncludedStepList: NotIncludedStepList,
      }));

    }
  }, [props.UrgencyJSON])

  const handleInclude = (add, idd) => {

    let TotalStepList = state.TotalStepList;
    let itemindex = TotalStepList.findIndex(x => x.key === idd);
    console.log("SelectStepBox.add, idd", add, idd);
    TotalStepList[itemindex].include = add;
    let IncludedStepList = TotalStepList.filter(x => x.include === true);
    let NotIncludedStepList = TotalStepList.filter(x => x.include === false);

    setState(prevState => ({
      ...prevState,
      TotalStepList: TotalStepList,
      IncludedStepList: IncludedStepList,
      NotIncludedStepList: NotIncludedStepList,
    }));

  }

  console.log("props", props)

  const ClosingDrawer = (e) => {
    console.log("ClosingDrawer", e)
    SetDeawerOpen(false)
  }

  const handleChange = (event, name) => {
    setState({
      ...state,
      [name]: event.target.checked,
    });
  };

  const MoveUp = (index, Arr) => {
    let arr = state[Arr];
    let indexB = index - 1;
    if (indexB > -1 && indexB < arr.length) {
      let temp = arr[index];
      arr[index] = arr[indexB];
      arr[indexB] = temp;
      console.log("Up arr", arr);
      setState((prevState) => ({ ...prevState, [Arr]: arr }));
    }
    //rest.UpdateFormData(arr);
    //setLocalStorage(arr);
  };

  const MoveDown = (index, Arr) => {
    let arr = state[Arr];
    let indexB = index + 1;
    if (indexB > -1 && indexB < arr.length) {
      let temp = arr[index];
      arr[index] = arr[indexB];
      arr[indexB] = temp;
      console.log("Down arr", arr);
      setState((prevState) => ({ ...prevState, [Arr]: arr }));
    }
    //rest.UpdateFormData(arr);
    //setLocalStorage(arr);
  };

  const SaveData = () => {

    try {

      let posterId = {
        pk1: UserData.pk1,
        sk1: UserData.sk1,
        field1: UserData.filed1,
        field2: UserData.filed2,
        avatar: UserData.avatar,
      }

      let TotalSteps = {
        ServiceSteps: state.TotalStepList,
      }
      
      state.TotalStepList.map(eachstep => {
        if (eachstep.Preset) {
          TotalSteps[eachstep.DataReceivedArrayName] = state[eachstep.DataReceivedArrayName]
        }
      })

      const key = `Data::Online::Lambda::Urgency::Steps::#${UUid}`;
      //var currentUnixTime = new Date();
      //var currentTime = currentUnixTime.toISOString();

      const FormdataJSON = {
        date: currentTime,
        TotalSteps: TotalSteps,
        field1: state.title,
        field2: state.CompanyName,
        id: UUid,
        CompanyId: state.CompanyId,
        CompanyName: state.CompanyName,
        ContractorId: ContractorData.ContractorId,
        posterId: posterId,
      }

      let dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON));

      let DBInput = {
        pk1: state.CompanyId,
        sk1: key,
        //gsi1Pk2: "Notify::" + ContractorData.ContractorId,
        //gsi1Sk2: "3000-01-01",
        gsi2Pk2: ContractorData.ContractorId,
        gsi2Sk2: key,
        dataJSON: dataJSON
      }

      console.log("SelectStepBox.DBInput", DBInput);
      console.log("SelectStepBox.FormdataJSON", FormdataJSON);

      API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput })).then(
        (results) => {
          console.log("createBatch Data ", results);
          if (props.CloseDrawer) {
            props.CloseDrawer();
          }

        }
      );

    } catch (err) {
      console.log("SelectStepBox.err", err);
    }


  }

  const SelectedValues = (item, name) => {

    setState({
      ...state,
      [name]: item.target.value,
    });
    // props.handleInclude(change, props.step.key);
  };

 
  return (
    <Paper className={classes.paper}>

      <Grid container spacing={2}>
        

        <Grid item xs={12}>
          <TextField
            required={true}
            label="Service Action"
            name="title"
            value={state.title || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => SelectedValues(e, "title")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="div">
            Selected Steps
            </Typography>
        </Grid>

        <Grid item xs={12}>

          <Card className={classes.root}>
           
            <CardContent>

              <Grid container spacing={2}>
                {state.IncludedStepList.map((eachstep, indd) => (
                  <Grid item xs={12} key={"eachstepbox"+indd}>
                    <StepBox
                      key={"eachstep" + indd}
                      MoveUp={e => MoveUp(indd, "IncludedStepList")}
                      MoveDown={e => MoveDown(indd, "IncludedStepList")}
                      handleInclude={(add, idd) => handleInclude(add, idd)}
                      CompanyId={state.CompanyId}
                      step={eachstep}
                      StepNumber={` (step ${indd + 1})`}
                   // UpdatedValue={(vv, ind) => console.log("StepBox3", vv, ind)}
                  />
                </Grid>
                ))}
              </Grid>
            </CardContent>

            <CardActions>

              <Button
                color="primary"
                variant="contained"
                onClick={() => SaveData()}
              >
                Save details
          </Button>

            </CardActions>

          </Card>
        </Grid>

        <Grid item xs={12}>

          <Typography variant="h5" gutterBottom component="div">
            Non Selected Steps
            </Typography>
        </Grid>

        <Grid item xs={12}>

          <Card className={classes.root}>
            
            <CardContent>

              <Grid container spacing={2}>
                {state.NotIncludedStepList.map((eachstep, indd) => (
                  <Grid item xs={12} key={"eachstepbox2" + indd}>
                    <StepBox
                      key={"eachstep2" + indd}
                      MoveUp={e => MoveUp(indd, "NotIncludedStepList")}
                      MoveDown={e => MoveDown(indd, "NotIncludedStepList")}
                      handleInclude={(add, idd) => handleInclude(add, idd)}
                      CompanyId={state.CompanyId}
                      step={eachstep}
                      StepNumber=""
                      //UpdatedValue={(vv, ind) => console.log("StepBox3", vv, ind)}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>

          </Card>
        </Grid>
      </Grid>

      <DrawerShow
        LoadComponenet={props.NextLoadComponenet}
        CloseDrawer={() => ClosingDrawer()}
        DrawerTitle="change this"
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawer: (e) => ClosingDrawer(e),
          ...props
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </Paper>
  );
}
