import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Checkbox, Typography, Drawer, Link } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const che = [
  {
    key: 1,
    field2: "6 AM - 6 PM",
    field1: "Office Hours",
    value: "123",
    checked: true
  },
  {
    key: 2,
    field2: "6 PM - 11:30 PM",
    field1: "Second Shift Second Shift Second Shift Second Shift",
    value: "123",
    checked: true
  },
  {
    key: 3,
    field2: "11:30 PM - 6 AM",
    field1: "Third Shift",
    value: "123",
    checked: false
  },
]

const DefaultChecked = [2, 1]

export default function NestedGrid(props) {
  const classes = useStyles();
  let init = {
    CheckList: che,
    open: false,
    ItemId: ""
  }
  const [state, setState] = React.useState(init);

  React.useEffect(() => {

    let NewCheckList = [];
    if (props.Data && props.Data.length > 0) {
      NewCheckList = props.Data;
    }

    if (props.DefaultChecked && props.DefaultChecked.length > 0) {
      NewCheckList.map((each, index) => {
        if (DefaultChecked.indexOf(each.key) > -1) {
          NewCheckList[index].checked = true;
        } else {
          NewCheckList[index].checked = false;
        }
      })
    }

    setState({ ...state, CheckList: NewCheckList });

  }, [props])

  const OpenDrawer = (DrawerStatus, ItemId) => {
    console.log(DrawerStatus, ItemId)
    setState({ ...state, open: DrawerStatus, ItemId: ItemId });
  }

  const handleChange = (event, ind) => {
    let NewCheckList = state.CheckList;
    let KeyArray = [];
    NewCheckList[ind].checked = event.target.checked;
    setState({ ...state, CheckList: NewCheckList });
    //console.log(NewCheckList)
    NewCheckList.map(each => {
      if (each.checked) {
        KeyArray.push(each.key)
      }
    })
    if (props.KeyArray) {
      props.KeyArray(KeyArray)
    }
    console.log("keys", KeyArray)
  };

  function FormRow(props) {
    return (
      <React.Fragment>
        <Grid item xs={2} sm={1}>
          <Checkbox
            checked={props.checked}
            onChange={(event) => props.handleChange(event, props.ind)}
            name="" />
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography>
            {props.ShowLink ? (
            <Link href="#" onClick={() => props.OpenDrawer(true, props.ind)}>
              {props.label}
              </Link>
            ) : props.label}
          </Typography>
        </Grid>

      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container  >
        {state.CheckList.map((each, ind) => (
          <Grid
            key={`grd-${ind}`}
            container
            item
            xs={props.Size || 12}
            alignItems="center">
            <FormRow
              key={`grdfrm-${ind}`}
              checked={each.checked}
              ind={ind}
              handleChange={(event) => handleChange(event, ind)}
              label={`${each.field1} ${each.field2}`}
              OpenDrawer={(e, id) => OpenDrawer(e, id)}
              ShowLink={props.ShowLink}
            />
          </Grid>
        ))}

      </Grid>
      <Drawer
        anchor='right'
        open={state.open}
        onClose={() => OpenDrawer(false, "")}
      >
        {state.ItemId}
      </Drawer>
    </div>
  );
}
