import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Card, CardContent,
    Button,
    Typography,
    Grid, Backdrop, CircularProgress,
    CardHeader, Stepper, Step, StepLabel, StepContent,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import SelectAssets from '../components/SelectAssets';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import { NewPageId, removeEmptyStringElements, UpdateData } from '../utils/CommonGraphql';
//import AutoComplateBox from '../components/AutoComplete';
//import Page from '../components/Page';

function EleminateDuplicateObjects(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}

var dateToday = new Date();
let currentTime = dateToday.toISOString();

const Profile = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    //const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const [SelectCompany, SetSelectCompany] = useState(false);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [AddPersonal, SetAddPersonal] = useState(false);
    const [Upload, SetUpload] = useState(false);
    const [loading, SetLoading] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        NewId: "",
        Accounts: [],
        BranchList: [],
        BranchId: "",
        BranchName: "",
        GlobalPersonal: []
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let NewId = NewPageId("Glbl");
        let GlobalPersonal = [];
        let BranchList = [];
        //let DB = [];


        if (rest.Data
            && rest.Data.length > 0) {
            GlobalPersonal = rest.Data;
        }

        let posterId = {
            pk1: UserData.pk1,
            sk1: UserData.sk1,
            field1: UserData.field1,
            field2: UserData.field2,
            avatar: UserData.avatar,
        }            

        setState(prevState => ({
            ...prevState,
            NewId: NewId,
            GlobalPersonal: GlobalPersonal,
            BranchList: BranchList,
            GlobalData: rest.GlobalData,
            GlobalId: rest.GlobalData.sk1,
            GlobalPk1: rest.GlobalData.pk1,
            GlobalName: rest.GlobalData.field1,
            posterId: posterId
        }));

    }, [rest.Data, rest.GlobalData])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                if (rest.CloseDrawer) {
                    rest.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const ProcessEachTeam = () => {
        
        try {


            let DBInput1 = {};
            let DBInput2 = {};
            let InputArray = [];
            let GlobalPersonal = EleminateDuplicateObjects(state.GlobalPersonal, "sk1")
            let StepJson = {};

            console.log('AddtoGlobalPerson.GlobalPersonal', GlobalPersonal);

            if (GlobalPersonal && GlobalPersonal.length > 0) {
                GlobalPersonal.map(each => {
                    if (each) {

                        DBInput1 = {
                            //find all shift for person
                            pk1: state.GlobalId,
                            sk1: each.sk1,
                            //find all personal assigned for this shift
                            gsi1Pk2: each.sk1,
                            gsi1Sk2: state.GlobalId,
                            //contractor
                            gsi2Pk2: state.GlobalPk1,
                            gsi2Sk2: state.GlobalId
                        }

                        StepJson = {
                            field1: each.field1,
                            field2: each.field2,
                            pk1: each.pk1,
                            sk1: each.sk1,
                            avatar: each.avatar,
                            BranchId: each.BranchId,
                            BranchName: each.BranchName,
                            CompanyId: each.CompanyId,
                            CompanyName: each.CompanyName,
                            CustomerType: each.CustomerType,
                            HQId: each.HQId,
                            HQName: each.HQName,
                            SearchWords: each.SearchWords,
                            posterId: state.posterId,
                            ...DBInput1
                        };

                        console.log('AddtoGlobalPerson.StepJson', StepJson);

                        DBInput2 = {
                            ...DBInput1,
                            dataJSON: JSON.stringify(removeEmptyStringElements(StepJson))
                        }

                        InputArray.push(removeEmptyStringElements(DBInput2));
                    }
                })
            }
           

            if (InputArray.length > 0) {
                BatchLimitPush(InputArray);
            }

        } catch (err) { console.log("SaveData err", err); }
    }

    const BatchLimitPush = (data) => {

        let item_count = 0;
        let current_batch = [];

        for (var i = 0; i < data.length; i++) {
            item_count++
            current_batch.push(data[i])
            if (item_count % 25 === 0) {
                BatchSendData(current_batch);
                current_batch = []
            }
        }

        // Add the last batch if it has records and is not equal to 25
        if (current_batch.length > 0 && current_batch.length !== 25) {
            BatchSendData(current_batch);
        }

        //if (props.CloseDrawer) {
        //    props.CloseDrawer();
        //}

        setState(prevState => ({
            ...prevState,
            DoneDone: true
        }));

    }

    const BatchSendData = (data) => {
        console.log('ESCMinSetup.BatchSendData2', data);

        API.graphql(graphqlOperation(createBatch, { input: data }))
            .then((results) => {
                console.log('createBatch Data ', results);
                
            }).catch(error => console.log('createBatch error ', error));


    }

    
    console.log("AddtoGlobalPerson.rest", rest)
    console.log("AddtoGlobalPerson.state", state)

    return (

        <>
        <Stepper activeStep={activeStep} orientation="vertical">

            <Step key={1}>
                <StepLabel>Select Global Locations</StepLabel>
                <StepContent>


                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            {rest.GlobalData &&
                                rest.GlobalData.Accounts
                                && rest.GlobalData.Accounts.length > 0 ? (
                                <FormControl variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Global Locations
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        required={true}
                                        sx={{ width: 300 }}
                                        autoWidth={true}
                                        value={state.BranchId}
                                        onChange={(e) => {
                                            SetValues(e.target.value, "BranchId");
                                        }}
                                        label="Global Locations"
                                    >

                                        {rest.GlobalData.Accounts.map((o, indd) => (
                                            <MenuItem key={indd} value={o.sk1}>
                                                {o.field1}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl >
                            ) : (
                                <Typography>
                                    No Global Locations Selected
                                </Typography>

                            )}


                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={state.BranchId === ""}
                                    onClick={() => handleNext()}
                                >
                                    Next
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </StepContent>
            </Step>

            <Step key={2}>
                <StepLabel>Select Personnel</StepLabel>
                <StepContent>

                    <div>
                        {(state.BranchId !== "") ? (
                            <>
                                <Grid item xs={12} >
                                    <Typography>
                                        Select Personnel
                                    </Typography>
                                </Grid>

                                <Grid xs={12} item>

                                    <SelectAssets
                                        SelectedAsset={(e) => SetValues(e, "GlobalPersonal")}
                                        CompanyId={state.BranchId}
                                        CompanyName={state.BranchName}
                                        title="Select Personnel"
                                        Category="Person"
                                        Personal={state.GlobalPersonal}
                                        AssetList={state.GlobalPersonal}
                                    />
                                </Grid>
                            </>
                        ) : (

                            <Typography>Please Select Global Location First</Typography>
                        )}
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button color="primary"
                                variant="contained"
                                disabled={state.BranchId === ""}
                                onClick={() => ProcessEachTeam()}>
                                Save List
                            </Button>
                        </div>
                    </div>
                </StepContent>
            </Step>

        </Stepper>
            
        <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </>
    );
};


export default Profile;
