import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
  
  Grid,
  CardHeader
} from '@mui/material';
//import { makeStyles } from '@mui/styles';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import { NewPageId } from '../utils/CommonGraphql';
//import AutocompleteModule from '../components/AutoComplete';
//import { useDispatch } from 'react-redux';

const NewKey = NewPageId("Requestor")

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const AddRequestor = props => {
  let Message = "";
  if (props.CustomerType === "Contractor") {
    if (props.PersonId === "") {
      if (props.cid === "") {
        Message = "Select Company";
      } else {
        Message = "Select Person";
      }
    }
  }
}




const Profile = ({ className, ...rest }) => {
 // const classes = useStyles();
  //const [Requestor, SetRequestor] = useState(false);
  //const ContractorData = useSelector(state => state.ContractorData);
  //const UserData = useSelector(state => state.UserData);
  //const cid = useSelector(state => state.cid);
  //const dispatch = useDispatch()

  const initialState = {
    Message: "",
    MessageNo: "",
    Personal: [],
  }
  const [state, setState] = useState(initialState);

  

  useEffect(() => {

    if (rest.MessageNo) {
      setState(prevState => ({
        ...prevState,
        MessageNo: `# ${rest.MessageNo}`
      }));
    }

  }, [rest.MessageNo])

  const SendValuesBack = () => {

    let TotalMessage = {
      Message: state.Message,
      Person: state.Personal,
      PrivateMessage: rest.Trigger1
    }

    if (rest && rest.SelectedValues) {
      rest.SelectedValues(TotalMessage)
    }

  }

  const SetValues = (e, name) => {
    setState(prevState => ({
      ...prevState,
      [name]: e
    }));

  }

  //console.log("Requestor.ContractorData", ContractorData)
  //console.log("Requestor.UserData", UserData)
  //console.log("Requestor.cid", cid)
  //console.log("Requestor.state", state)
  console.log("ExtraNotification.props", rest)

  return (
   
        <Grid container spacing={1}>

          {rest.Trigger1 ? (
              <>
              <Grid xs={12} item>
            <Typography variant="h4" gutterBottom component="div">
              {`Private Message ${state.MessageNo}`}
              </Typography>
                </Grid>

            <Grid xs={12} item>
                <Typography>Following Message will be displayed only for the selected personal</Typography>
                </Grid>
              </>
      ) : (
          <>
            <Grid xs={12} item>
                  <Typography variant="h4" gutterBottom component="div">Add Message</Typography>
                </Grid>
              
            </>
            )}

      <Grid xs={12} item>
        {/*  
              <Quill
                label="Message"
                name="Message"
                value={state.Message || ""}
                fullWidth
                variant="outlined"
                SelectedValues={(e) => {
                  SetValues(e, "Message");
                }}
        />
        */}

        <TextField
          label="Message"
          name="Message"
          value={state.Message || ""}
          //fullWidth
          //variant="outlined"
          //SelectedValues={(e) => {
          //  SetValues(e, "Message");
          //}}

          fullWidth
          multiline
          rows={2}
          variant="outlined"
          onChange={(e) => SetValues(e.target.value, "Message")}
        />
              </Grid>
              

          <Grid xs={12} item>
            <SelectAssets
              SelectedAsset={(e) => SetValues(e, "Personal")}
              CompanyId={rest.CompanyId}
              CompanyName={rest.CompanyName}
              title="Select Personal"
              Category={rest.Category}
              FormData={rest.FormData}
            />
          </Grid>


        </Grid>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
