import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import {
    Card, Typography, CardContent, Grid, CardHeader, Box, Button,
    Backdrop, CircularProgress, Collapse, IconButton
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//import moment from 'moment';
import Toolbar from '../components/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
//import { Hours, Dayss, WeekOfMonth, MonthsOfYear, DateTodaySlash, currentTime } from '../utils/DateTime'
import {
    removeEmptyStringElements,
    UpdateData, getAutoCompletedata,
    EleminateDuplicateObjects,
    isNotEmptyObject
} from '../utils/CommonGraphql';
import PersonalCheckList from '../components/PersonalCheckList';
//import DateRange from '../components/DateRange';
import DrawerShow from '../utils/DrawerModule';
import SelectAssets from '../components/SelectAssets';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const isEmptyObject = (value) => {
    return value && Object.keys(value).length > 0 && value.constructor === Object;
}

const RecurringSchedule = (props) => {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const UserData = useSelector(state => state.UserData);
    const [open, setOpen] = React.useState(false);
    // const NowEditing = useSelector(state => state.NowEditing)
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [expanded, setExpanded] = useState(false);
    //const UserData = useSelector(state => state.UserData);
    //const cid = useSelector(state => state.cid);
    const initialState = {
        CurrentCompany: props.CompanyName,
        ContractorId: ContractorData.ContractorId,
        SearchWords: "",
        StepPersonalList: [],
        CompanyId: props.CompanyId,
        CompanyName: props.CompanyName,
        pk1: props.CompanyId,
        ShowEditButton: props.ShowEditButton || false,
        disabled: props.disabled || false,
        OpenAlert: false,
        DateErr: false,
        EndTimeMessage: "",
        EditKey: "",
        DataBack: [],
        AssetList: [],
        CurrentCheckIn: [],
        AllowOpenEdit: false,
        AllowDelete: true,
    };

    const [state, setState] = useState(initialState);
    const [loading, SetLoading] = useState(false);

    const CloseDrawer = (c) => {
        // GetDatafromDB("Person", state.CompanyId);
        GetDatafromDB("Person", state.CompanyId, [], state.CompanyName, "CloseDrawer");
        OpenDrawer(false);
    }

    useEffect(() => {
        try {

            if (CurrentCompany && CurrentCompany.CheckIn && CurrentCompany.CheckIn.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    CurrentCheckIn: CurrentCompany.CheckIn
                }))
            }

        } catch (err) { }
    }, []);

    //edit items
    useEffect(() => {

        console.log("CheckIn.props", props);

        try {

            if (Object.entries(props.EditItem).length > 0) {
                console.log("props.EditItem", props.EditItem);
                let PersonalList = props.EditItem.StepPersonalList;
                GetDatafromDB("Person", props.EditItem.pk1, PersonalList, props.CompanyName, "props.EditItem");
                setState(prevState => ({
                    ...prevState,
                    StepTeam: props.EditItem.StepTeam,
                    CompanyId: props.EditItem.pk1,
                    CompanyName: props.EditItem.CompanyName,
                    EditKey: props.EditItem.key,
                    pk1: props.EditItem.pk1
                }))
            } else {
                setState(prevState => ({
                    ...prevState,
                    StepTeam: props.StepTeam,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    EditKey: "",
                    pk1: props.CompanyId
                }))
                GetDatafromDB("Person", props.CompanyId, [], props.CompanyName, "else.props.EditItem");
            }



        } catch (err) { }
    }, [props.CompanyId]);

    useEffect(() => {

        if (TriggerUpdate === true) {

            let FindCompany = -1;
            let Personnel = [];
            if (state.StepPersonalList && state.StepPersonalList.length > 0) {
                FindCompany = state.StepPersonalList.findIndex(e => e.CompanyId === state.CompanyId);

                if (FindCompany > -1) {
                    Personnel = state.StepPersonalList[FindCompany].Personnel;
                }
            }

            GetDatafromDB("Person", state.CompanyId, Personnel, state.CompanyName, "TriggerUpdate");
            dispatch({ type: 'TRIGGER_UPDATE', payload: false });
        }

    }, [TriggerUpdate]);

    const GetDatafromDB = async (Category, cid, PersonalList = [], CompanyName = "", from = "") => {
        try {
            SetLoading(true);
            //console.log("ESCAddPersonal.CompanyData1.GetDatafromDB", Category, cid);
            const DataString = {
                Category: Category,
                FindArray: [
                    {
                        ArrayName: "DataBack",
                        //DuplicateArray: "RawData",
                        ArraySearchString: ":",
                    }
                ]
            };

            let NewData = await getAutoCompletedata(DataString, cid);

            //replace existing selection in to new list
            if (NewData && NewData.DataBack.length > 0 && PersonalList.length > 0) {
                NewList(NewData, PersonalList);
            }

            console.log("CheckIn.NewData", CompanyName, from, NewData);
            console.log("CheckIn.NewData.PersonalList", PersonalList);

            setState(prevState => ({ ...prevState, ...NewData }))

            SetLoading(false);
        } catch (err) { }
    }

    const NewList = (NewData, PersonalList) => {

        let StepPersonalList = [];
        var foundIndex = "";
        PersonalList.forEach((element, index) => {
            foundIndex = NewData.DataBack.findIndex(x => x.key == element.key);
            NewData.DataBack[foundIndex].emailcheck = element.emailcheck;
            NewData.DataBack[foundIndex].phonecheck = element.phonecheck;
            NewData.DataBack[foundIndex].smscheck = element.smscheck;
            StepPersonalList.push(NewData.DataBack[foundIndex]);
        });

        setState(prevState => ({ ...prevState, StepPersonalList: StepPersonalList }))

    }

    const CompanyData = (data) => {
        console.log("CheckIn.CompanyData", data.CompanyId, data.CompanyName);

        let FindCompany = -1;
        let Personnel = [];
        if (state.StepPersonalList && state.StepPersonalList.length > 0) {
            FindCompany = state.StepPersonalList.findIndex(e => e.CompanyId === state.CompanyId);

            if (FindCompany > -1) {
                Personnel = state.StepPersonalList[FindCompany].Personnel;
            }

        }

        GetDatafromDB("Person", data.CompanyId, Personnel, data.CompanyName, "CompanyData");

        setState(prevState => ({
            ...prevState,
            CurrentCompany: data.CompanyName,
            CompanyId: data.CompanyId,
            CustomerType: data.CustomerType,
            pk1: data.CompanyId
        }));
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };


    const SaveData22 = () => {
        //console.log('SaveData', state);
        try {

            var currentUnixTime = new Date();
            var currentTime = currentUnixTime.toISOString();
            let SearchWords = "";
            let PersonalDB = [];
            let Eachentry = {};
            let InputArray = [];
            let EachDB = {};

            state.StepPersonalList.map((eachCompany, indc) => {

                eachCompany.Personnel.map(eachperson => {
                    SearchWords += ` | ${eachperson.field1} ${eachperson.email}`;
                    PersonalDB.push({
                        pk1: eachperson.pk1,
                        sk1: eachperson.sk1
                    })
                })

                let key = `Data::Online::Lambda::CheckIn::${eachCompany.CompanyId}`;

                Eachentry = {
                    pk1: props.CompanyId,
                    sk1: key,
                    //gsi1Pk2: `Post::${each.key}`,
                    //gsi1Sk2: event.ServiceRequest.sk1,
                    gsi2Pk2: ContractorData.ContractorId,
                    gsi2Sk2: key,

                };

                let StepPersonal = {
                    ...Eachentry,
                    key: key,
                    pk1: state.pk1,
                    SearchWords: SearchWords,
                    field1: `Checkin list - ${eachCompany.CompanyName}`,
                    field2: props.CompanyName,
                    DB: PersonalDB,
                    date: currentTime,
                    CompanyId: eachCompany.CompanyId,
                    Personnel: eachCompany.Personnel,
                    CompanyName: eachCompany.CompanyName,
                    CustomerType: eachCompany.CustomerType
                }

                console.log('CheckIn.StepPersonal', StepPersonal);

                SearchWords = "";
                PersonalDB = [];

                EachDB = {
                    ...Eachentry,
                    dataJSON: JSON.stringify(removeEmptyStringElements(StepPersonal))
                }


                InputArray.push(EachDB);

            })

            console.log('CheckIn.InputArray', InputArray);

            if (InputArray.length > 1000) {
                API.graphql(graphqlOperation(createBatch, { input: InputArray }))
                    .then((results) => {
                        if (props.CloseDrawer) {
                            props.CloseDrawer();
                        }
                    })
            }



        } catch (err) { console.log("SaveData err", err); }
    }

    const SaveDetails = () => {
        try {

            let CheckIn = [];
            let FindLocation = -1;

            if (CurrentCompany && CurrentCompany.CheckIn && CurrentCompany.CheckIn.length > 0) {
                CheckIn = CurrentCompany.CheckIn;
            }

            if (state.AssetList && state.AssetList.length > 0) {

                state.AssetList.map((eachLoc, indd) => {

                    FindLocation = CheckIn.findIndex(e => e.sk1 === eachLoc.sk1);

                    if (FindLocation > -1) {

                        CheckIn[FindLocation] = {
                            sk1: eachLoc.sk1,
                            field1: eachLoc.field1,
                            field2: eachLoc.field2,
                            pk1: eachLoc.pk1,
                            Location: eachLoc.Location,
                            StepPersonalList: state.StepPersonalList
                        }

                    } else {

                        CheckIn.push({
                            sk1: eachLoc.sk1,
                            field1: eachLoc.field1,
                            field2: eachLoc.field2,
                            pk1: eachLoc.pk1,
                            Location: eachLoc.Location,
                            StepPersonalList: state.StepPersonalList
                        })
                    }

                })

                SaveData(CheckIn)

            } else {

                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: "Please select Locations",
                    OpenAlert: true
                }));

            }

        } catch (err) { console.log("SaveData err", err); }
    }

    const SaveData = (CheckIn) => {

        try {

            let EditCommpny = CurrentCompany;

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.field1,
                field2: UserData.field2,
                avatar: UserData.avatar,
            }

            const FormdataJSON = {
                ...EditCommpny,
                CheckIn: CheckIn,
                posterId: posterId,
            }

            let DBInput2 = {
                pk1: EditCommpny.pk1,
                sk1: EditCommpny.sk1,
                dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
            }

            console.log("CheckIn.DBInput", DBInput2);
            console.log("CheckIn.FormdataJSON", FormdataJSON);


            dispatch({ type: 'CURRENT_COMPANY', payload: FormdataJSON });

            UpdateData(DBInput2)
                .then((up) => {
                    console.log("SRDetails.Update.results", up)

                    if (props.CloseDrawer) {
                        props.CloseDrawer();
                    }
                    // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                })
                .catch((err) => console.log("SRDetails.failed", err));

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const handleChange = (event, ind, Arrayy, SelectedValues) => {
        let NewCheckList = state[Arrayy];
        let KeyArray = [];
        NewCheckList[ind].checked = event.target.checked;

        //console.log(NewCheckList)
        NewCheckList.map(each => {
            if (each.checked) {
                KeyArray.push(each.key)
            }
        })

        setState({
            ...state,
            [Arrayy]: NewCheckList,
            [SelectedValues]: KeyArray
        });


        // console.log("keys", KeyArray)
    };

    const DisableItems = () => {
        setState(prevState => ({ ...prevState, disabled: !state.disabled }));
    }


    const SetValueState = (e, name) => {
        console.log("CheckIn", name, e)
        setState(prevState => ({ ...prevState, [name]: e }));
    }

    const AddItem = (data) => {

        let DetailComponent = "ItemAddPage";

        if (data === "ESC") {
            DetailComponent = "ESCSetupPage"
        }

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
        }))

        OpenDrawer(true);
    }

    const SelectedPersonalList = (Personnel) => {

        let StepPersonalList = state.StepPersonalList;
        let FindCompany = -1;
        if (state.StepPersonalList && state.StepPersonalList.length > 0) {
            FindCompany = state.StepPersonalList.findIndex(e => e.CompanyId === state.CompanyId);

            if (FindCompany > -1) {
                StepPersonalList[FindCompany].Personnel = Personnel;
            } else {
                StepPersonalList.push({
                    CompanyId: state.CompanyId,
                    Personnel: Personnel,
                    CompanyName: state.CurrentCompany,
                    CustomerType: state.CustomerType,
                    pk1: state.CompanyId
                })
            }

        } else {

            StepPersonalList.push({
                CompanyId: state.CompanyId,
                Personnel: Personnel,
                CompanyName: state.CurrentCompany,
                CustomerType: state.CustomerType,
                pk1: state.CompanyId
            })

        }

        setState(prevState => ({ ...prevState, StepPersonalList: StepPersonalList }))
    }

    const ProcessSearch = (e) => {
        setState(prevState => ({ ...prevState, SearchWords: e }))
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const OpenEdit = Loc => {
        console.log("CheckIn.Loc", Loc);

        setState(prevState => ({
            ...prevState,
            AssetList: [Loc],
            StepPersonalList: Loc.StepPersonalList,
        }))

        let Personnel = [];
        let FindCompany = -1;
        if (Loc.StepPersonalList && Loc.StepPersonalList.length > 0) {
            FindCompany = Loc.StepPersonalList.findIndex(e => e.CompanyId === state.CompanyId);
            if (FindCompany > -1) {
                Personnel = Loc.StepPersonalList[FindCompany].Personnel;
            }
        }

        GetDatafromDB("Person", Loc.pk1, Personnel, props.CompanyName, "CompanyData");

    }

    const HandleDeleteItem = (indd) => {

        if (state.DeleteItem !== "") {
            console.log("CheckIn.DeleteItem1", state.DeleteItem);
            let DataBack = state.CurrentCheckIn;
                  //  let indd = DataBack.findIndex(x => x.sk1 === state.DeleteItem.sk1)
            DataBack.splice(state.DeleteItem, 1);
            console.log("CheckIn.DeleteItem2", DataBack);
            SaveData(DataBack)

                    setState(prevState => ({
                        ...prevState,
                        CurrentCheckIn: DataBack,
                        DeleteItem: ""
                    }));
                   
                  //  console.log("ListModule.DeleteItem.results", results);
              //  })
        }
        setOpen(false)
    }

    const ConfirmDelete = (item) => {

        console.log("CheckIn.item", item);

        setOpen(true)
        setState(prevState => ({ ...prevState, DeleteItem: item }));
    }

    const handleCloseDialog = () => {
        setOpen(false);
    };

    console.log("CheckIn.state", state);

    return (

        <div key={props.CompanyId}>
            <Card>
                <CardHeader
                    title="Check In/Out Setup"
                    action={
                        state.ShowEditButton && (<EditIcon
                            fontSize="small"
                            onClick={() => DisableItems()} />)
                    }
                />
                <CardContent>


                    <Grid container spacing={2} >

                        {state.CurrentCheckIn && state.CurrentCheckIn.length > 0 ? (
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent style={{
                                        border: '2px solid #f30e39'
                                    }} >

                                        <CardHeader
                                            title={
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={handleExpandClick}
                                                >
                                                    Current List
                                                </Button>
                                            }

                                        />

                                        <Collapse in={expanded} timeout="auto">
                                            <CardContent>
                                                <Grid container spacing={2} >
                                                    {state.CurrentCheckIn.map((eachLoc, LocInd) => (
                                                        <Grid item xs={12} key={`loc-${LocInd}`}>
                                                            <Card key={`locCard-${LocInd}`}>
                                                                <CardHeader
                                                                    title={eachLoc.field1}
                                                                    subheader={eachLoc.field2}                                                                    
                                                                    action={state.AllowDelete && (
                                                                        <IconButton
                                                                            onClick={() => ConfirmDelete(LocInd)}
                                                                            edge="end" aria-label="Info">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    )}

                                                                    
                                                                />
                                                                <CardContent>
                                                                    <Grid container spacing={1} key={`locCard2-${LocInd}`}>
                                                                        {eachLoc.StepPersonalList.map((eachComp, CompInd) => (
                                                                            <>
                                                                                <Grid item xs={12} key={`locCardstep-${LocInd}-${CompInd}`}>
                                                                            <Typography variant="h4" gutterBottom component="div">
                                                                                        {eachComp.CompanyName && eachComp.CompanyName}
                                                                            </Typography>
                                                                        </Grid>
                                                                                {eachComp.Personnel.map((eachPer, PerInd) => (
                                                                                    <Grid item xs={12} key={`locCardstep-${LocInd}-${CompInd}-${PerInd}`}>
                                                                            <Typography>
                                                                                            {eachPer.field1}
                                                                            </Typography>
                                                                                    </Grid>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                    </Grid>

                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </CardContent>
                                        </Collapse>
                                    </CardContent>
                                </Card>
                            </Grid>

                        ) : null}



                        <Grid item xs={12} >
                            <Card>
                                <CardHeader
                                    title="Select Location"
                                />
                                <CardContent>

                                    <SelectAssets
                                        SelectedAsset={(e) => SetValueState(e, "AssetList")}
                                        CompanyId={props.CompanyId}
                                        CompanyName={props.CompanyName}
                                        title={`Select Location`}
                                        Category="Location"
                                        AssetList={state.AssetList}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} >

                            <Card>
                                <CardHeader
                                    title="Select Personnel"
                                />
                                <CardContent>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} >
                                            <Toolbar
                                                Headings="Person"
                                                Category="Person"
                                                ProcessSearch={(e) => ProcessSearch(e)}
                                                CompanyData={(e) => CompanyData(e)}
                                                AddCompany={false}
                                                AddItem={() => AddItem()}
                                                disabled={props.disabled}
                                                CompanyName={state.CompanyName}
                                                CompanyId={state.CompanyId}
                                                CloseDrawerToolBar={(e) => CloseDrawer(e)}
                                            />

                                        </Grid>

                                        {state.DataBack && (
                                            <Grid item xs={12} >
                                                <PersonalCheckList
                                                    Category="Person"
                                                    CompanyId={state.CompanyId}
                                                    CompanyName={state.CompanyName}
                                                    key={state.pk1 + "showpersonal"}
                                                    DetailComponent="Person"
                                                    SearchWords={state.SearchWords}
                                                    PerPage={10}
                                                    StepPersonalList={(e) => SelectedPersonalList(e)}
                                                    DataBack={state.DataBack}
                                                    disabled={state.disabled}
                                                />

                                            </Grid>
                                        )}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => SaveDetails()}
                    disabled={state.disabled}
                >
                    Save details
                </Button>
            </Box>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <Dialog
                //  fullScreen={fullScreen}
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Delete Item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseDialog}>
                        No
                    </Button>
                    <Button onClick={HandleDeleteItem} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={(e) => CloseDrawer(e)}
                propss={{
                    Category: "Person",
                    CompanyId: state.CompanyId,
                    CompanyName: state.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e)
                }}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>

    )
}

export default RecurringSchedule;
