import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Card, CardContent,
    Button,
    Typography,
    Grid,
    CardHeader, Stepper, Step, StepLabel, StepContent
} from '@mui/material';
import SelectAssets from '../components/SelectAssets';
import { NewPageId, removeEmptyStringElements, UpdateData } from '../utils/CommonGraphql';
import AutoComplateBox from '../components/AutoComplete';
import Page from '../components/Page';

function EleminateDuplicateObjects(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}



const Profile = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    //const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const [SelectCompany, SetSelectCompany] = useState(false);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [AddPersonal, SetAddPersonal] = useState(false);
    const [Upload, SetUpload] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        NewId: "",
        Accounts: [],
        BranchList: [],
        BranchId: "",
        BranchName: ""
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let NewId = NewPageId("Glbl");
        let Accounts = [];
        let BranchList = [];
        //let DB = [];


        if (rest.GlobalData && rest.GlobalData.Accounts && rest.GlobalData.Accounts.length > 0) {
            Accounts = rest.GlobalData.Accounts;
        }

        if (rest.GlobalData && rest.GlobalData.BranchList && rest.GlobalData.BranchList.length > 0) {
            BranchList = rest.GlobalData.BranchList;
        }

        //if (rest.GlobalData && rest.GlobalData.DB && rest.GlobalData.DB.length > 0) {
        //    DB = rest.GlobalData.DB;
        //}

        setState(prevState => ({
            ...prevState,
            NewId: NewId,
            Accounts: Accounts,
            BranchList: BranchList,
            GlobalData: rest.GlobalData
        }));

    }, [rest.GlobalData])

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const GetCompanyName = (data) => {

        setState(prevState => ({
            ...prevState,
            BranchName: data.CompanyName,
            BranchId: data.CompanyId,
        }));

        //  SetSelectCompany(false);

    }

    const handleNext = () => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const ProcessMessage = () => {

        let DistributionList = [];
        let DB = [];
        let BranchList = [];
        //  let CurrentAccount = state.CurrentAccount;


        console.log("SubscriptionAdd.Accounts", state.Accounts)

        if (state.Accounts.length > 0) {
            state.Accounts.map(each => {

                DistributionList.push({
                    field1: each.field1,
                    field2: each.field2,
                    pk1: each.pk1,
                    sk1: each.sk1,
                    BranchId: each.BranchId,
                    BranchName: each.BranchName,
                    CompanyId: each.CompanyId,
                    CompanyName: each.CompanyName,
                    CustomerType: each.CustomerType,
                    HQId: each.HQId,
                    HQName: each.HQName,
                    SearchWords: `${each.SearchWords}, ${each.BranchName}`,
                })

                DB.push({
                    pk1: each.pk1,
                    sk1: each.sk1
                })

                BranchList.push({
                    BranchId: each.BranchId,
                    BranchName: each.BranchName,
                    HQId: each.HQId,
                    HQName: each.HQName,
                })

            })
        }
        console.log("SubscriptionAdd.DistributionList", DistributionList)

        let total_DistributionList = EleminateDuplicateObjects(DistributionList, "sk1")
        let total_DB = EleminateDuplicateObjects(DB, "sk1");
        let total_BranchList = EleminateDuplicateObjects([...BranchList, ...state.BranchList], "BranchId");

        SaveData(total_DistributionList, total_DB, total_BranchList);
    }

    const SaveData = (DistributionList, DB, BranchList) => {

        try {

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            var dateToday = new Date();
            let currentTime = dateToday.toISOString();

            let newFormdataJSON = {
                ...rest.GlobalData,
                posterId: posterId,
                Accounts: DistributionList,
                BranchList: BranchList,
                DB: DB,
                date: currentTime,
                pk1: rest.GlobalData.pk1,
                sk1: rest.GlobalData.sk1,
            }

            let UpdateDataaa = {
                pk1: rest.GlobalData.pk1,
                sk1: rest.GlobalData.sk1,
                dataJSON: JSON.stringify(removeEmptyStringElements(newFormdataJSON))
            }


            console.log('AddtoGlobal.total', newFormdataJSON)

            if (rest.DataUpdate) {
                rest.DataUpdate(newFormdataJSON);
            }

            UpdateData(UpdateDataaa)
                .then((up) => {
                    // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                    if (rest.CloseDrawer) {
                        rest.CloseDrawer();
                    }

                })
                .catch((err) => console.log("SRDetails.failed", err));

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    console.log("AddtoGlobal.rest", rest)
    console.log("AddtoGlobal.state", state)

    return (


        <Stepper activeStep={activeStep} orientation="vertical">

            <Step key={1}>
                <StepLabel>Select Branch</StepLabel>
                <StepContent>


                    <Grid container spacing={2}>

                        <Grid item xs={12}>

                            <AutoComplateBox
                                //SaveDetails={rest.SaveDetails}
                                //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                Category="Branch"
                                FormType="Branch"
                                title="Branch"
                                // id={Newpageiddd}
                                ShowEditButton={false}
                                CompanyId={ContractorData.HQId}
                                ReturnBackData={e => console.log(e)}
                                PreviewEdit={false}
                                EditItem={e => console.log("EditItem", e)}
                                //FormData={each.FormData}
                                onSubmit={(model) => GetCompanyName(model)}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext()}
                                >
                                    Next
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </StepContent>
            </Step>

            <Step key={2}>
                <StepLabel>Select Accounts</StepLabel>
                <StepContent>

                    <div>
                        {(state.BranchId !== "") ? (
                            <>
                                <Grid item xs={12} >
                                    <Typography>
                                        Select Branch Accounts associated with Global Customer
                                    </Typography>
                                </Grid>

                                <Grid xs={12} item>

                                    <SelectAssets
                                        SelectedAsset={(e) => SetValues(e, "Accounts")}
                                        CompanyId={state.BranchId}
                                        CompanyName={state.BranchName}
                                        title="Select Accounts"
                                        Category="Company"
                                        Personal={state.Accounts}
                                        AssetList={state.Accounts}
                                    />
                                </Grid>
                            </>
                        ) : (

                            <Typography>Please select Branch first</Typography>
                        )}
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button color="primary"
                                variant="contained"
                                disabled={state.BranchId === ""}
                                onClick={() => ProcessMessage()}>
                                Save List
                            </Button>
                        </div>
                    </div>
                </StepContent>
            </Step>

        </Stepper>

    );
};


export default Profile;
