import React, { useState, useEffect } from 'react';

import {
    NewPageId,
    Pk1SingelItem,
} from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import {
    Box, Grid, Typography,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import ContactBox from '../components/ContactBox';
import Skeleton from '../components/Skeleton';

//import Page from '../components/Page';

//var dateToday = new Date();
//let currentTime = dateToday.toISOString().replace("Z", "");

//const UUid = NewPageId("Urgency");





export default function SimpleCard(props) {
    
    //const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    //const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    //const UserData = useSelector(state => state.UserData);
    //const cid = useSelector(state => state.cid);

    const initialState = {
        ContactList: [],
        Repeats: true
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("ShowContactUs.props", props);
        GetDatafromDB(props.CompanyId);

    }, [props])

    const GetDatafromDB = async (cid) => {
        SetLoading(true);
        let ContactList = []
        let NewData2 = await Pk1SingelItem(cid, `Data::Online::Lambda::PreSelect::Contractor`);
        let NewData = await NewData2;

        if (NewData && NewData.dataJSON) {
            let ContactList2 = JSON.parse(NewData.dataJSON);
            ContactList = ContactList2.ContactList;
        }

        console.log("ShowContactUs.NewData", ContactList);

        if (ContactList.length === 0 && state.Repeats) {
            GetDatafromDB(ContractorData.CompanyId)
            setState(prevState => ({
                ...prevState,
                Repeats: false
            }))
        } else {
            setState(prevState => ({
                ...prevState,
                ContactList: ContactList,
                Repeats: false
            }))
        }        

        SetLoading(false);

    }


    console.log("ShowContactUs.state", state);

    return (
        <Box sx={{mt:3}}>

            <Grid container spacing={1}>
                
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Contact Us
                    </Typography>
                </Grid>


                {state.ContactList && state.ContactList.length > 0 ? (
                    <>
                        {state.ContactList.map((eachstep, indd) => (
                            <Grid item xs={12} key={"eachstepbox" + indd}>
                                <ContactBox
                                    key={"eachstep" + indd}
                                    CompanyId={props.CompanyId}
                                    data={eachstep}
                                    ShowButtons={false}
                                />
                            </Grid>
                        ))}


                    </>

                ) : (<Skeleton />) }


            </Grid>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
