import React, { useEffect, useState } from 'react';
import {
    Grid,
    FormGroup, FormControlLabel, Switch, Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';



export default function RadioButtonsGroup(props) {
  //  const UserData = useSelector(state => state.UserData);
    const NowEditing = useSelector(state => state.NowEditing);
    const initialState = {
        AllowSMSService: true,
        disabled: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        let AllowSMSService = true;
        let disabled = false;
        if (NowEditing !== "Company") {
            AllowSMSService = false;
            disabled = true;
        }

        setState(prevState => ({
            ...prevState,
            AllowSMSService: AllowSMSService,
            disabled: disabled
        }));

    }, [])


    const SetValues = (data, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

        if (props.AddressObj){
            props.AddressObj(data);
        }

    }

    console.log("AllowSMS.state", state)
    console.log("AllowSMS.props", props)

    return (
        
            <Grid container lg={12} xs={12} alignItems="center" >
                <Grid 
                    item
                    xs
                    style={{ display: "flex", justifyContent: "flex-start", padding: "6px" }}
                >
                    <Typography>Allow SMS/Text Service Call</Typography>
                </Grid>
                <Grid 
                    item
                    alignItems="flex-end"
                    justifyContent="flex-start"
                >
                    <Switch
                        onChange={(event) => SetValues(event.target.checked, "AllowSMSService")}
                        checked={state.AllowSMSService}
                        disabled={state.disabled}
                    />
                </Grid>
            </Grid>

    );
}
