
export const CountryList = [
  //{
  //  "id": 32,
  //  "alpha2": "ar",
  //  "alpha3": "arg",
  //  "name": "Argentina"
  //},
  //{
  //  "id": 36,
  //  "alpha2": "au",
  //  "alpha3": "aus",
  //  "name": "Australia"
  //},
  //{
  //  "id": 40,
  //  "alpha2": "at",
  //  "alpha3": "aut",
  //  "name": "Austria"
  //},  {
  //  "id": 44,
  //  "alpha2": "bs",
  //  "alpha3": "bhs",
  //  "name": "Bahamas"
  //},
  //{
  //  "id": 48,
  //  "alpha2": "bh",
  //  "alpha3": "bhr",
  //  "name": "Bahrain"
  //},    
  //{
  //  "id": 56,
  //  "alpha2": "be",
  //  "alpha3": "bel",
  //  "name": "Belgium"
  //},
  //{
  //  "id": 84,
  //  "alpha2": "bz",
  //  "alpha3": "blz",
  //  "name": "Belize"
  //},
  //{
  //  "id": 76,
  //  "alpha2": "br",
  //  "alpha3": "bra",
  //  "name": "Brazil"
  //},
  {
    "id": 124,
    "alpha2": "ca",
    "alpha3": "can",
    "name": "Canada"
  },
  //{
  //  "id": 152,
  //  "alpha2": "cl",
  //  "alpha3": "chl",
  //  "name": "Chile"
  //},
  //{
  //  "id": 156,
  //  "alpha2": "cn",
  //  "alpha3": "chn",
  //  "name": "China"
  //},
  //{
  //  "id": 170,
  //  "alpha2": "co",
  //  "alpha3": "col",
  //  "name": "Colombia"
  //},
  //{
  //  "id": 188,
  //  "alpha2": "cr",
  //  "alpha3": "cri",
  //  "name": "Costa Rica"
  //},
  //{
  //  "id": 208,
  //  "alpha2": "dk",
  //  "alpha3": "dnk",
  //  "name": "Denmark"
  //},
  //{
  //  "id": 242,
  //  "alpha2": "fj",
  //  "alpha3": "fji",
  //  "name": "Fiji"
  //},
  //{
  //  "id": 246,
  //  "alpha2": "fi",
  //  "alpha3": "fin",
  //  "name": "Finland"
  //},
  //{
  //  "id": 250,
  //  "alpha2": "fr",
  //  "alpha3": "fra",
  //  "name": "France"
  //},
  {
    "id": 276,
    "alpha2": "de",
    "alpha3": "deu",
    "name": "Germany"
  },
  //{
  //  "id": 300,
  //  "alpha2": "gr",
  //  "alpha3": "grc",
  //  "name": "Greece"
  //},
  //{
  //  "id": 348,
  //  "alpha2": "hu",
  //  "alpha3": "hun",
  //  "name": "Hungary"
  //},
  //{
  //  "id": 352,
  //  "alpha2": "is",
  //  "alpha3": "isl",
  //  "name": "Iceland"
  //},
  //{
  //  "id": 356,
  //  "alpha2": "in",
  //  "alpha3": "ind",
  //  "name": "India"
  //},
  //{
  //  "id": 360,
  //  "alpha2": "id",
  //  "alpha3": "idn",
  //  "name": "Indonesia"
  //},

  //{
  //  "id": 372,
  //  "alpha2": "ie",
  //  "alpha3": "irl",
  //  "name": "Ireland"
  //},
  //{
  //  "id": 376,
  //  "alpha2": "il",
  //  "alpha3": "isr",
  //  "name": "Israel"
  //},
  //{
  //  "id": 380,
  //  "alpha2": "it",
  //  "alpha3": "ita",
  //  "name": "Italy"
  //},
  //{
  //  "id": 388,
  //  "alpha2": "jm",
  //  "alpha3": "jam",
  //  "name": "Jamaica"
  //},
  //{
  //  "id": 392,
  //  "alpha2": "jp",
  //  "alpha3": "jpn",
  //  "name": "Japan"
  //},

  //{
  //  "id": 410,
  //  "alpha2": "kr",
  //  "alpha3": "kor",
  //  "name": "Korea, Republic of"
  //},
  //{
  //  "id": 414,
  //  "alpha2": "kw",
  //  "alpha3": "kwt",
  //  "name": "Kuwait"
  //},

  //{
  //  "id": 458,
  //  "alpha2": "my",
  //  "alpha3": "mys",
  //  "name": "Malaysia"
  //},

  {
    "id": 484,
    "alpha2": "mx",
    "alpha3": "mex",
    "name": "Mexico"
  },

  //{
  //  "id": 492,
  //  "alpha2": "mc",
  //  "alpha3": "mco",
  //  "name": "Monaco"
  //},

  //{
  //  "id": 499,
  //  "alpha2": "me",
  //  "alpha3": "mne",
  //  "name": "Montenegro"
  //},

  //{
  //  "id": 528,
  //  "alpha2": "nl",
  //  "alpha3": "nld",
  //  "name": "Netherlands"
  //},
  //{
  //  "id": 554,
  //  "alpha2": "nz",
  //  "alpha3": "nzl",
  //  "name": "New Zealand"
  //},

  //{
  //  "id": 578,
  //  "alpha2": "no",
  //  "alpha3": "nor",
  //  "name": "Norway"
  //},
  //{
  //  "id": 512,
  //  "alpha2": "om",
  //  "alpha3": "omn",
  //  "name": "Oman"
  //},

  //{
  //  "id": 608,
  //  "alpha2": "ph",
  //  "alpha3": "phl",
  //  "name": "Philippines"
  //},
  //{
  //  "id": 616,
  //  "alpha2": "pl",
  //  "alpha3": "pol",
  //  "name": "Poland"
  //},
  //{
  //  "id": 620,
  //  "alpha2": "pt",
  //  "alpha3": "prt",
  //  "name": "Portugal"
  //},
  //{
  //  "id": 634,
  //  "alpha2": "qa",
  //  "alpha3": "qat",
  //  "name": "Qatar"
  //},
  //{
  //  "id": 642,
  //  "alpha2": "ro",
  //  "alpha3": "rou",
  //  "name": "Romania"
  //},
  //{
  //  "id": 643,
  //  "alpha2": "ru",
  //  "alpha3": "rus",
  //  "name": "Russian Federation"
  //},

  //{
  //  "id": 682,
  //  "alpha2": "sa",
  //  "alpha3": "sau",
  //  "name": "Saudi Arabia"
  //},

  //{
  //  "id": 688,
  //  "alpha2": "rs",
  //  "alpha3": "srb",
  //  "name": "Serbia"
  //},

  //{
  //  "id": 702,
  //  "alpha2": "sg",
  //  "alpha3": "sgp",
  //  "name": "Singapore"
  //},

  //{
  //  "id": 710,
  //  "alpha2": "za",
  //  "alpha3": "zaf",
  //  "name": "South Africa"
  //},

  //{
  //  "id": 724,
  //  "alpha2": "es",
  //  "alpha3": "esp",
  //  "name": "Spain"
  //},

  //{
  //  "id": 752,
  //  "alpha2": "se",
  //  "alpha3": "swe",
  //  "name": "Sweden"
  //},
  //{
  //  "id": 756,
  //  "alpha2": "ch",
  //  "alpha3": "che",
  //  "name": "Switzerland"
  //},

  //{
  //  "id": 792,
  //  "alpha2": "tr",
  //  "alpha3": "tur",
  //  "name": "Türkiye"
  //},

  //{
  //  "id": 804,
  //  "alpha2": "ua",
  //  "alpha3": "ukr",
  //  "name": "Ukraine"
  //},
  //{
  //  "id": 784,
  //  "alpha2": "ae",
  //  "alpha3": "are",
  //  "name": "United Arab Emirates"
  //},
  {
    "id": 826,
    "alpha2": "gb",
    "alpha3": "gbr",
    "name": "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    "id": 840,
    "alpha2": "us",
    "alpha3": "usa",
    "name": "United States of America"
  },
  //{
  //  "id": 858,
  //  "alpha2": "uy",
  //  "alpha3": "ury",
  //  "name": "Uruguay"
  //}

];