/*
 * 
 * 
 * progress show box is to disply the optins for end user to show progress in the work
 * this is not for setup
 * 
  * {
    key: "workaccepted",
    label: "Work Accepted",
    stepcomplete: false,
    include: false,
    showdate: true,
    date: "",
    datelabel: "Accepted Date",
    showoptions: false,
    options: "tom, dick, jerry",
    optionlabel: "check optins",
    optionsSelected: "",
    Notes: ""
  },
   *
   * props:
   * Step
   * disabled
   * NewStep
 * 
 */

import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
//import { styled } from '@mui/material/styles';
import {
    TextField, Grid, FormGroup,
    Button, Select, FormControl, MenuItem, Checkbox, Typography,
    FormControlLabel
} from '@mui/material';
import { useSelector } from 'react-redux';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
//import EditIcon from '@mui/icons-material/Edit';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
//import DateFnsUtils from '@date-io/date-fns';
//import DateFnsAdapter from '@date-io/date-fns';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTodaySlash, formatDate } from '../utils/DateTime'

//const dateFns = new DateFnsAdapter();


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const DateFormat = 'MM/dd/yyyy';

export default function SimpleCard(props) {
    const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const UserData = useSelector(state => state.UserData);
    const [expanded, setExpanded] = React.useState(false);

    const initialState = {
        //key: "workaccepted",
        label: "",
        stepcomplete: false,
        include: false,
        showdate: true,
        date: "",
        datelabel: "",
        showoptions: false,
        options: "",
        optionlabel: "",
        optionsSelected: "",
        Notes: "",
        OptionArraytrue: false,
        ShowMessageButton: false,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("ProgressShowBox.props", props)
        let DisableItems = false;
        let Message = "";
        var dateToday = new Date();
        let currentTime = dateToday.toISOString()
        //RequestorCompanyId = { state.RequestorCompanyId }
        //RequestorCompanyName = { state.RequestorCompanyName }

        if (props && props.Step) {

            let ShowMessageButton = false;
            if (props.Step.key === "workaccepted" && UserData.CompanyId !== props.RequestorCompanyId) {
                DisableItems = true;
                Message = `Only Authorized Person from ${props.RequestorCompanyName} can accept the work`;
                ShowMessageButton = true;
            }


            setState(prevState => ({
                ...prevState,
                ...props.Step,
                DisableItems: DisableItems,
                Message: Message,
                dateNow: currentTime,
                ShowMessageButton: ShowMessageButton,
            }));
        }

        if (props.Step.options && props.Step.options !== "") {
            let OptionArray = props.Step.options.split(",");
            setState(prevState => ({
                ...prevState,
                OptionArray: OptionArray,
                OptionArraytrue: true,
            }));
        }

    }, [])

    useEffect(() => {


        let NewSteps = {
            //key: props.Step.key,
            //label: props.Step.label,
            //labelTop: props.Step.labelTop,
            //include: props.Step.include,
            //  showdate: props.Step.showdate,
            //  datelabel: props.Step.datelabel,
            //  showoptions: props.Step.showoptions,
            //  options: props.Step.options,
            //  optionlabel: props.Step.optionlabel,

            ...props.Step,
            date: formatDate(state.dateNow),
           // optionsSelected: state.optionsSelected,
            Notes: state.Notes,
            stepcomplete: state.stepcomplete,
            required: props.Step.include
        }

        if (props.NewStep) {
            props.NewStep(NewSteps)
        }

    }, [state.dateNow, state.optionsSelected, state.Notes, state.stepcomplete])


    const SelectedValues = (item, name) => {

        setState({
            ...state,
            [name]: item,
        });
    };

    const SelectedValuesCheck = (item, name) => {
        setState({
            ...state,
            [name]: item,
        });

        if (props.StepChecked) {
            props.StepChecked(item);
        }
    };

    console.log("ProgressShowBox.state", state)

    return (
        
            <Grid container spacing={1}>

                {state.Message !== "" && (
                    <Grid item xs={12}>
                        <Typography>
                            {state.Message}
                        </Typography>
                    </Grid>
                )}

                {state.ShowMessageButton && (
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={state.ManagerActiondisabled}
                            onClick={() => props.AddMessage()}
                        >
                            Request Work Acceptance
                        </Button>
                    </Grid>
                )}


                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                checked={state.stepcomplete}
                                disabled={state.DisableItems}
                                //value={}
                                onChange={event => SelectedValuesCheck(event.target.checked, "stepcomplete")}
                            />}
                            label={props.Step.label}
                        />
                    </FormGroup>
                </Grid>



                {/*   
        {state.OptionArray && state.OptionArraytrue && (
            <Grid item lg={12} md={12} >
              <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                <InputLabel id="demo-simple-select-outlined-label">{props.Step.optionlabel}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id={`select-${props.Step.key}`}
                  defaultValue={props.Step.optionsSelected}
                  disabled={props.disabled}
                  autoWidth={true}
                  value={state.optionsSelected || ""}
                  onChange={(e) => {
                    SelectedValues(e.target.value, "optionsSelected");
                  }}
                  label={props.Step.optionlabel}
                >
                {state.OptionArray.map((opt, indd) => (
                    <MenuItem key={indd} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl >

            </Grid>
          )}

        {props.Step.showdate && (
          <Grid item xs={12}>
            <Grid container spacing={2} >
              <Grid
                key="1"
                item
                xs={4}>
                <Typography variant="h5" component="h2">
                  {props.Step.datelabel}
                </Typography>

              </Grid>
              <Grid
                key="2"
                item
                xs={8}>

                <DatePicker
                  value={state.dateNow || ""}
                  disabled={props.disabled}
                  fullWidth
                  onChange={event => SelectedValues(event, "dateNow")}
                  inputVariant="outlined"
                  format={DateFormat}
                />

              </Grid>
            </Grid>
          </Grid>
        )}

        */}

                <Grid item xs={12}>
                    <TextField
                        disabled={state.DisableItems}
                        label="Memo / Notes"
                        name="notes"
                        value={state.Notes || ""}
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        onChange={(e) => SelectedValues(e.target.value, "Notes")}
                    />
                </Grid>
            </Grid>

        
    );
}
