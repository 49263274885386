import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import YouTubeIcon from '@mui/icons-material/YouTube';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip'; //
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player'

function BasicCard(props) {
	return (
		<Card sx={{ minWidth: 275 }}>
			<CardContent>

				<Typography variant="h5" component="div">
					{props.title}
				</Typography>

				<Typography variant="body2">
					{props.description}

				</Typography>
			</CardContent>
			{props.ShowVideo && (
				<>
				<CardActions>
					<Button
						// onClick={() => props.ShowVideoNow()}
							href={props.VideoId}
							target="_blank"
					><YouTubeIcon fontSize="large" /> See Video</Button>
				</CardActions>

					<Typography sx={{m:1}} variant="caption" display="block" gutterBottom>
					the video will open in a new window. You can move the new window next to the main service portal tab and follow the instructions in the video
					</Typography>
				</>
			)}
		</Card>
	);
}

export default function ControlledTooltips(props) {
	const [open, setOpen] = React.useState(false);
	const ShowHelp = useSelector(state => state.ShowHelp);
	const [Videoopen, VideosetOpen] = React.useState(false);
	//const [Videoshow, SetVideoshow] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const ShowVideo = () => {
		// console.log("Gallery.image", image)
		//SetDisplayVideo(true)
		//SetVideoshow(image)
		VideosetOpen(true);
	};

	const VideohandleClose = () => {
		//stopVideo();
		VideosetOpen(false);
	};

	//const stopVideo = () => {
	//	let video = document.getElementById("Helpvidid")
	//	video.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*');
	//}
	//// to pause the video
	//const pauseVideo = () => {
	//	let video = document.getElementById("Helpvidid")
	//	video.contentWindow.postMessage('{"event":"command", "func":"pauseVideo", "args":""}', '*');
	//}

	return ShowHelp ? (
		<>
			<Tooltip
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
				placement="right-start"
				title={<BasicCard
					title={props.title}
					description={props.description}
					ShowVideo={props.ShowVideo}
					VideoId={props.VideoId}
					ShowVideoNow={() => ShowVideo()}
				/>
				}
				arrow >
				<HelpOutlineIcon fontSize="small" />
				
			</Tooltip>

			
			<Backdrop
				sx={{
					color: '#fff',
					zIndex: 35001
				}}
				style={{ zIndex: 35001 }}
				open={Videoopen}
				onClick={VideohandleClose}
			>

				<ReactPlayer
					url={props.VideoId}
					controls={true}
					stopOnUnmount={true}
					playing={Videoopen}
				/>
				<Button
					onClick={VideohandleClose}
				>stop</Button>
			</Backdrop>
		</>
	) : null;
}
