import React, { useState, useEffect } from 'react';
import {
    //Box,
    //Grid,
    Container,
    //Snackbar,
    //Link,
    //TextField,
    //Typography,
    //LinearProgress,

} from '@mui/material';
import { useDispatch } from 'react-redux';
//import { FindLatestDate, removeEmptyStringElements, UpdateData } from '../utils/CommonGraphql';
import { useParams } from 'react-router-dom';
//import { currentTime } from '../utils/DateTime';
//import { createBatch } from '../graphql/mutations';
//import { strengthIndicator, strengthColor } from "../utils/PasswordStrength";
//import { Auth, API, graphqlOperation } from "aws-amplify";
//import Page from '../components/Page';
//import { FormSchema, CommonFormUpdated, FormPrefArray } from '../Forms/FormList';
//import StartPage from '../views/auth/LoginView';
import PaymentExpense from '../Payment/PaymentExpense';
//import { Buffer } from 'buffer';


//const AWS = require("aws-sdk");
//// Initialize the Amazon Cognito credentials provider
//AWS.config.region = "us-east-1"; // Region
//AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//    IdentityPoolId: process.env.REACT_APP_IdentityPoolId
//});
//const dynamodb = new AWS.DynamoDB.DocumentClient();

//var GetDBData = (params) => {
//    return new Promise((resolve, reject) => {
//        dynamodb.query(params, function (err, data) {
//            if (err) {
//                console.log("errrr", err, err.stack);
//                // an error occurred
//            } else {
//                console.log("databack DB", data)
//                resolve(data);
//            }// successful response
//        });
//    });
//};

//const GetData = async (pk1, sk1) => {
//    try {
//        let getItemParams2 = {
//            //IndexName: "gsi1Pk2-gsi1Sk2-index",
//            TableName: "svcbot_db",
//            KeyConditionExpression: "#pk1 = :pk1 and #sk1 = :sk1",
//            ExpressionAttributeNames: {
//                "#pk1": "pk1",
//                "#sk1": "sk1",
//            },
//            ExpressionAttributeValues: {
//                ":pk1": pk1,
//                ":sk1": sk1,
//            }
//        };

//        let EscPolicy = await GetDBData(getItemParams2);
//        return EscPolicy.Items;

//    } catch (err) {
//        console.log('GetData err', err);
//    }
//};


const LoginView = (props) => {

  
//const dispatch = useDispatch()
    
    const params = useParams();
    console.log('LandingPage.params', params);
    console.log('LandingPage.props', props);
    const initialState = {
        ShowLogin: true,
        PostData: "",
    };

    const [state, setState] = React.useState(initialState);

    useEffect(() => {
        console.log('LandingPage.props', props);

        //if (pk !== "" && sk !== "") {
        //           LoadPostData(pk, sk);
        //}

        //let usernamecode = process.env.REACT_APP_SIGNINUSER; 
        //usernamecode = usernamecode.toLocaleLowerCase();
        //let PPw = process.env.REACT_APP_PPW; 
        //console.log("LoginPage.SignUp.SignIn", usernamecode, PPw);
        //Auth.signIn(usernamecode, PPw).then(user => {
        //    console.log("LandingPage.user", user);
        // //   LoadDataRedux();

        //}).catch(err => {
        //    console.log("LandingPage.err", err);
        //    setState((prevState) => ({
        //        ...prevState,
        //        toDashboard: false,
        //        confirmcodemessage: `Sorry The Sign In failed. Please try again or contact ${state.ContractorName}`,
        //        step: "SignInEmail"
        //    }))
        //})
       
    }, [])

    useEffect(() => {

        let pk = "";
        let sk = "";
        let dir = "";

        //let ButtonLinkpk = Buffer.from("Conversation::+15627601914").toString('base64');
        //let ButtonLinksk = Buffer.from("2023-08-26T01:53:34.137Z").toString('base64');
        //let Direction = Buffer.from("accountsetup").toString('base64');
        //let ActionButton = `https://app.ContractorCheckIn.com/checkin/${ButtonLinkpk}/${ButtonLinksk}/${Direction}`;



        if (params.pk) {
            pk = atob(params.pk);
            sk = atob(params.sk);
            dir = atob(params.dir);
        }

        //pk = "Conversation::+15627601914";
        //sk = "2023-09-11T00:27:59.555Z";
        //dir = "CheckOut";
            
        console.log('LandingPage.pk', pk);
        console.log('LandingPage.sk', sk);
        console.log('LandingPage.dir', dir);

        if (pk !== "" && sk !== "") {
     //       LoadPostData(pk, sk);
        }
            
            setState(prevState => ({
                ...prevState,
             //   ShowLogin: true,
                NextPk: pk,
                NextSk: sk,
                dir: dir
            }));

       // }
    }, [params.pk])

  
    //const LoadPostData = async (pk, sk) => {

    //    try {


    //        let Paymentstring = `Payment::Due::${pk}`;
    //        let Amount = 0;
    //        let PostData = [];
    //        console.log('Paymentstring', Paymentstring);

    //        let getItemParams2 = {
    //            IndexName: "gsi1Pk2-gsi1Sk2-index",
    //            TableName: "svcbot_db",
    //            ScanIndexForward: false,
    //            KeyConditionExpression: "#gsi1Pk2 = :gsi1Pk2",
    //            ExpressionAttributeNames: {
    //                "#gsi1Pk2": "gsi1Pk2",
    //            },
    //            ExpressionAttributeValues: {
    //                ":gsi1Pk2": Paymentstring
    //            }
    //        };

    //        let PersonFound = await GetDBData(getItemParams2);
    //        console.log('CheckPesonBalance', JSON.stringify(PersonFound, null, 2));

    //        if (PersonFound.Count > 0) {
    //            Amount = PersonFound.Count * 3;
    //            PostData = PersonFound.Items;
    //        }

    //            setState(prevState => ({
    //                ...prevState,
    //                PostData: PostData, 
    //                Amount: Amount
    //            }));

            

    //    } catch (err) {
    //        console.log('LoadPref err', err);
    //    }
    //}

    console.log('LandingPage.state', state);

    return (
        <Container maxWidth="md">
                 
           {state.PostData && (
                <PaymentExpense
                    pk={state.NextPk}
                    sk={state.NextSk}
                    SREvent={state.PostData}
                    dir={state.dir}
                    Amount={state.Amount}
                />
            )}

        </Container>
    );
};

export default LoginView;
