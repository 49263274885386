import React, { useState, useEffect } from 'react';
import {
    Pk1SingelItem,
} from '../utils/CommonGraphql';
import SRDetails from '../List/SRDetails';

export default function SimpleCard(props) {

    const initialState = {
        Data: [],
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("SRDetailsPkSk.props", props)

        if (props.Data && props.Data.ServiceRequest && props.Data.ServiceRequest.pk1) {
                console.log("SRDetailsPkSk.ServiceRequest", props.Data.ServiceRequest)
                GetDatafromDB(props.Data.ServiceRequest.pk1, props.Data.ServiceRequest.sk1);
            }
        

    }, [props.Data])

    const GetDatafromDB = async (pk, sk) => {
        let NewData2 = await Pk1SingelItem(pk, sk);
        let NewData = await NewData2;
        
        if (NewData) {
            let SREvent = JSON.parse(NewData.dataJSON);

            console.log("SRDetailsPkSk.NewData", SREvent)

            setState(prevState => ({
                ...prevState,
                SREvent: SREvent
            }))
        }
    }

    //const UpdateDatabase = () => {

    //    // UpdateSREvent(data);

    //    let data = state.UpdateSREvent;

    //    if (data && data.ServiceRequest && data.ServiceRequest.pk1) {

    //        var ValueToUpdate = {
    //            pk1: data.ServiceRequest.pk1,
    //            sk1: data.ServiceRequest.sk1,
    //            gsi1Pk2: data.gsi1Pk2,
    //            gsi2Pk2: data.gsi2Pk2,
    //            dataJSON: JSON.stringify(removeEmptyStringElements(data)),
    //        };

    //        console.log("SRDetails.1.pksk", ValueToUpdate)
    //        console.log("SRDetails.1.datajson", data)

    //        UpdateData(ValueToUpdate, "381-SRDetails")
    //            .then((up) => {
    //                console.log("SRDetails.Update.results", up)
    //                // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
    //            })
    //            .catch((err) => console.log("SRDetails.failed", err));
    //    }
    //}

    const SelectedValues = (item, name) => {
        console.log("SRBox.UpdateSREvent", item)
        setState({
            ...state,
            [name]: item,
        });
    };

    console.log("SRDetailsPkSk.state", state)

    return state.SREvent && (
        <SRDetails
            SREvent={state.SREvent}
            DisableAll={true}
           // UpdateSREvent={data => SelectedValues(data, "UpdateSREvent")}
        />
    )
}
