// eslint-disable
// this is an auto generated file. This will be overwritten

export const createSvcbotDb = `mutation CreateSvcbotDb($input: CreateSvcbotDbInput!) {
  createSvcbotDb(input: $input) {
    gsi1Pk2
    gsi1Sk2
    gsi2Pk2
    gsi2Sk2
    pk1
    sk1
    dataJSON
   }
}
`;

export const createBatch = `mutation CreateBatch($input: [CreateSvcbotDbInput!]) {
  createBatch(input: $input) {
    pk1
    sk1
  }
}
`;

export const createPost = `mutation CreateSvcbotDb($input: CreateSvcbotDbInput!) {
  createSvcbotDb(input: $input) {
    gsi1Pk2
    gsi1Sk2
    gsi2Pk2
    gsi2Sk2
    pk1
    sk1
    dataJSON
  }
}
`;

export const deleteSvcbotDb = `mutation DeleteSvcbotDb($input: DeleteSvcbotDbInput!) {
  deleteSvcbotDb(input: $input) {
    pk1
    sk1
  }
}
`;
export const updateSvcbotDb = `mutation UpdateSvcbotDb($input: UpdateSvcbotDbInput!) {
  updateSvcbotDb(input: $input) {
    pk1
    sk1
gsi1Pk2
gsi2Pk2
    dataJSON
  }
}
`;
