import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import DynamicForms from '../Forms/FormShow';
import { NewPageId } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import AutoComplateBox from '../components/AutoCompleteBox'

const FormId = NewPageId("ItemShowDetails");
//import Snackbar from '@mui/material/Snackbar';
//import MuiAlert from '@mui/lab/Alert';
//import { useSelector } from 'react-redux';
//import { FormSchema, FormGroups } from '../Forms/FormList';
//import { PrimaryKeyDataGet } from '../utils/CommonGraphql';
//import ComboForm from '../Forms/ComboForm';
////import AutocompleteBox from '../components/AutoComplete';

//function Alert(props) {
//  return <MuiAlert elevation={6} variant="filled" {...props} />;
//}



let NexText = [];

const ProfileDetails = ({ className, ...rest }) => {

    //const [savedetails, Setsavedetails] = useState(false);
    //const FormPrefData = useSelector(state => state.Pref)

    //console.log("ItemShowDetail", rest);

    let initialState = {
        FormJSON: [],
        FormValueArray: [],
        FromArry: [],
        FormJSONBack: [],
        ImagePic: "",
    };

    const [state, setState] = useState(initialState);
    const [DrawerStatus, OpenDrawer] = useState(false);

    useEffect(() => {
        console.log("ShowDetails.props", rest);
        if (rest.Data) {
            SeperateData(rest.Data);
        }
    }, [rest.Data])

    const SeperateData = data => {
        console.log("ShowDetails.enterdata", data);
        try {

            let pk = "";
            let sk = "";
            let Category = "";
            let NewFromArry = [];
            let FormExtra = [];
            let FormJSON = [];
            let PhotoService = [];

            if (data && data.AllItemForms) {
              //  console.log("ShowDetails.data.AllItemForms", data.AllItemForms);
                if (data.AllItemForms.length > 0) {
                    //  console.log("ShowDetails.NewFromArry", NewFromArry);
                    data.AllItemForms.map((eachForm, ind) => {
                        if (eachForm && eachForm !== null) {
                           // console.log("ShowDetails.eachForm", eachForm);
                            //find if second layer
                            if (eachForm.AllItemForms) {
                                SeperateData(eachForm)

                            } else {

                                if (eachForm.FormData) {
                                    FormJSON.push(eachForm)
                                    NewFromArry.push({
                                        pk1: data.pk1,
                                        sk1: data.sk1,
                                        Category: data.Category,
                                        FormData: eachForm.FormData
                                    })

                                } else {

                                    NewFromArry.push({
                                        pk1: eachForm.pk1,
                                        sk1: eachForm.sk1,
                                        Category: eachForm.Category,
                                        FormData: eachForm
                                    })

                                }
                            }
                        }
                    })
                }
            }


            if (data && data.FormExtra && data.FormExtra.length > 0) {
                data.FormExtra.map(each => {
                    if (each && each !== null) {
                        FormExtra.push(each)
                    }
                })
            }

            if (data && data.PhotoService && data.PhotoService.length > 0) {
                PhotoService = data.PhotoService[0];
            }

            console.log("ShowDetails.FormJSON", FormJSON);
            console.log("ShowDetails.FormJSON2", FormExtra);

            setState(prevState => ({
                ...prevState,
                FormJSON: NewFromArry,
                UpdateFormJSON: [...FormJSON, ...FormExtra],
                FormExtra: FormExtra,
                Category: data.Category,
                ImagePic: PhotoService,
                pk1: data.pk1,
                sk1: data.sk1,
                CompanyId: data.CompanyId,
                CompanyName: data.CompanyName
            }));

        } catch (err) { }
    }

    //const CreateFormArray = (data) => {

    //  try {

    //    let NewFromArry = state.FromArry;
    //    NewFromArry.push({

    //    })

    //  } catch (err) { }
    //}

    //const getdata = async (Data) => {
    //  try {
    //    let DataList = [];
    //    let 
    //    const DataBack = await PrimaryKeyDataGet(Data.pk1, Data.sk1);
    //    const NewData = await DataBack;
    //    console.log("DataBack", DataBack)
    //    if (NewData) {
    //      DataList = ObjData(NewData);
    //      setRaw(DataList);

    //      setState(prevState => ({
    //        ...prevState,
    //        FormValueArray: projects
    //      }));

    //    }
    //    console.log(`DataList`, DataList);

    //  } catch (err) {
    //    console.log(err)
    //  }
    //}

    //const FormArray = (Category) => {

    //  try {

    //    //formgroup contains forms that will be presented together
    //    let FormGroupSelect = FormGroups(Category);
    //    let FormData = [];
    //    let FormJSON = [];
    //    console.log("FormGroupSelect Cat", Category, FormGroupSelect)
    //    FormGroupSelect.map((each, index) => {

    //      if (each.FormType === "AutoComplate") {
    //        FormData = [];
    //      } else {
    //        FormData = FormSchema.filter(x => x.Category === each.Category);
    //      }

    //      console.log("FormData Cat", each, FormData);

    //      FormJSON.push({
    //        FormType: each.FormType,
    //        key: each.FormType + index,
    //        FormName: each.FormName,
    //        Category: each.Category,
    //        FormData: FormData,
    //        ValueAdded: false
    //      })

    //    })

    //    console.log("FormJSONCat", FormJSON);
    //    setState(prevState => ({
    //      ...prevState,
    //      FormJSON: FormJSON,
    //      FormJSONBack: FormJSON
    //    }));

    //  } catch (err) { console.log("FormArray err", err) }
    //};

    //const SaveDetails = () => {

    //  let ValueAdded = true;
    //  let AlarmMessage = "";
    //  state.FormJSONBack.map(each => {
    //    if (!each.ValueAdded) {
    //      ValueAdded = false;
    //      AlarmMessage += `* Please complete ${each.FormName} `;
    //      console.log("AlarmMessage", AlarmMessage)
    //    }
    //  })
    //  Setsavedetails(ValueAdded);
    //  console.log("state.FormJSONBack", ValueAdded, state.FormJSONBack)
    //  if (ValueAdded) {

    //    rest.ReturnBackData(state.FormJSONBack);
    //    rest.SaveData(state.FormJSONBack);

    //  } else {
    //    setState(prevState => ({
    //      ...prevState,
    //      AlarmMessage: AlarmMessage,
    //      OpenAlert: true
    //    }));
    //  }

    //}

    //const handleClose = (event, reason) => {
    //  setState(prevState => ({
    //    ...prevState,
    //    AlarmMessage: "",
    //    OpenAlert: false
    //  }));

    //};

    //const ReturnBackData = (e) => {
    //  console.log("ReturnBackData", e)
    //  setState(prevState => ({
    //    ...prevState,
    //    FormJSONBack: e
    //  }));
    //};

    const EditDetails = () => {
        OpenDrawer(true);
    }

    const OnFormSubmit = (model, key) => {
        try {

            console.log("model", key, model);

            let projects = state.FormValueArray;
            projects[key] = model;
            projects[key].ValueAdded = true;

            setState(prevState => ({
                ...prevState,
                FormValueArray: projects
            }));
            console.log("ReturnFormData", projects)
            // props to send the data back
            if (rest.ReturnBackData) {
                rest.ReturnBackData(projects);
            }

        } catch (err) { }
    };

    const CloseDrawer = (c) => {
        // GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }


    return (

        <div key={FormId + rest.key}>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h3">
                        {rest.title}
                    </Typography>
                </Grid>

                {state.FormJSON
                    && state.FormJSON.length > 0
                    && state.FormJSON.map((each, index) => (
                        <Grid
                            item
                            xs={12}
                            key={index + "Showdd"}
                        >
                            <DynamicForms
                                key={index + "Showdd2"}
                                id={index}
                                //SaveDetails={props.SaveDetails}
                                Category={each.Category}
                                FormType={each.FormType}
                                //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                title={each.Category}
                                ShowEditButton={false}
                                UpdateFormData={e => console.log("UpdateFormData", e)}
                                PreviewEdit={false}
                                EditItem={e => console.log("EditItem", e)}
                                FormData={each.FormData}
                                onSubmit={(model) => OnFormSubmit(model, index)}
                            />

                        </Grid>
                    ))}

                {state.FormExtra && state.FormExtra.length > 0 && state.FormExtra.map((each, index) => {
                    console.log("ShowDetails.FormExtra.each", each);
                    if (each && each !== null) {
                        NexText = each.SearchWords.split(",");
                        return (
                            <Grid
                                item
                                xs={12}
                                key={index + "Showdd3"}
                            >

                                <Card>
                                    <CardHeader
                                        //subheader="The information can be edited"
                                        title={each.field1}
                                    />
                                    <Divider />
                                    <CardContent>
                                        {NexText.length > 0 && NexText.map((eachline, line) => (
                                            <Grid item xs={12} key={line + each.pk1}>
                                                <Typography>{eachline}</Typography>
                                            </Grid>
                                        ))}

                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                        NexText = [];
                    }
                }
                )}
            </Grid>

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => EditDetails()}
                >
                    Edit
                </Button>
            </Box>


            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    UpdateFormJSON: state.UpdateFormJSON,
                    Category: state.Category,
                    //UpdateFormExtra: state.FormExtra,
                    UpdateExisting: true,
                    CompanyId: state.CompanyId,
                    UpdatePk1: state.pk1,
                    UpdateKey: state.sk1,
                    CompanyName: state.CompanyName,
                    ImageUpload: true,
                    ImagePic: state.ImagePic,
                    //Category: state.Category,
                    //key: state.key,
                    //CompanyId: state.CompanyId,
                    //CompanyName: state.CompanyName,
                    //CustomerType: state.CustomerType,
                    //...state.DetailsProps
                    //ESCId: props.ESCId
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>

    );
};

ProfileDetails.propTypes = {
    className: PropTypes.string
};

export default ProfileDetails;

