//import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/material/styles';
////import { styled } from '@mui/material/styles';
//import {
//  TextField,
//  Collapse,
//  Paper, Grid,
//  Backdrop,
//  CircularProgress, FormGroup,
//  Card,
//  CardContent,
//  CardActions,
//  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
//  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
//  Switch, Tooltip, Divider
//} from '@mui/material';
////import Card from '@mui/material/Card';
////import CardActions from '@mui/material/CardActions';
////import CardContent from '@mui/material/CardContent';
////import Button from '@mui/material/Button';
////import Typography from '@mui/material/Typography';
////import Check from './CheckBoxGroup'
//import EditIcon from '@mui/icons-material/Edit';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';

import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  TextField,
  Button,
  Grid,
  Card, Switch,
  CardContent, Typography, Snackbar, FormControlLabel, FormGroup
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ESCSelectContractorAndCompany from '../Setup/ESCSelectContractorAndCompany';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const initialState = {
    title: "",
    message: "",
    include: false,
    label: "",
    showdate: "",
    AlarmMessage: "",
    OpenAlert: false,
    showoptions: false,
    datelabel: "",
    optionlabel: "",
    options: ""
  }
  const [state, setState] = useState(initialState);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  useEffect(() => {
    console.log("ProgressBox.props", props)
    if (props && props.step) {

      setState(prevState => ({
        ...prevState,
        ...props.step,
      }));

    }

  }, [])

  const handleClose = (event, reason) => {
    // handle close of Alert

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));

  };

  const Updatedata = () => {

    if (state.field1 !== "") {
    let NewSteps = {
      key: props.step.key,
      label: state.field1,
      field1: state.field1,
      labelTop: state.field1,
      include: state.include,
      showdate: state.showdate,
      date: props.step.date,
      datelabel: state.datelabel,
      showoptions: state.showoptions,
      options: state.options,
      optionlabel: state.optionlabel,
      optionsSelected: props.step.optionsSelected,
      Notes: props.step.Notes,
      stepcomplete: props.step.required,
      required: props.step.required
    }
    console.log("ProgressBox.NewSteps", NewSteps)
      props.handleInclude(NewSteps);

      if (props.CloseDrawer) {
        props.CloseDrawer();
      }

    } else {
      setState(prevState => ({
        ...prevState,
        AlarmMessage: "Please enter Progress Step Name",
        OpenAlert: true
      }));
    }

  }

  const handleChange = (event, name) => {
    let change = event.target.checked;
    console.log("ProgressBox.handleChange", change, props.step.key)
    setState({
      ...state,
      [name]: change,
    });

  };


  const SelectedValues = (item, name) => {
    setState({
      ...state,
      [name]: item.target.value,
    });
  };



  //const Additem = (DetailComponent, Category, CompanyId, CompanyName) => {
  //  setState(prevState => ({
  //    ...prevState,
  //    DetailComponent: DetailComponent,
  //    ShowDetails: false,
  //    DetailsProps: {
  //      Category: Category,
  //      CompanyId: CompanyId,
  //      CompanyName: CompanyName,
  //      CloseDrawer: () => CloseDrawer()
  //    }
  //  }))
  //  SetDeawerOpen(true);
  //}


  return (
    <div key={"ddivgfk"} >
      <Card key={'lklkjkh'} className={classes.root}>
        <CardContent>

          {/*   
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={state.include}
                onChange={e => handleChange(e, "include")} />}
              label={`${state.label}`}
            />

          </FormGroup>
          <CardActions>
            <IconButton aria-label="settings" onClick={() => handleExpandClick()}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="settings" onClick={() => props.MoveDown()}>
              <ExpandMoreIcon />
            </IconButton>
            <IconButton aria-label="settings" onClick={() => props.MoveUp()}>
              <ExpandLessIcon />
            </IconButton>
          </CardActions>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
           */}
          

            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  required={true}
                  label="Progress Step Name"
                  name="label"
                  value={state.field1 || ""}
                  fullWidth
                  variant="outlined"
                onChange={(e) => SelectedValues(e, "field1")}
                />
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={state.showdate}
                      onChange={e => handleChange(e, "showdate")} />}
                    label="Show Date"
                  />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={true}
                label="Date Label"
                name="datelabel"
                value={state.datelabel || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => SelectedValues(e, "datelabel")}
              />
            </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={state.showoptions}
                      onChange={e => handleChange(e, "showoptions")} />}
                    label="Show Options"
                  />
                </FormGroup>
              </Grid>
              
              
              <Grid item xs={12}>
                <TextField
                  required={true}
                  label="Dropdown Options Label"
                  name="optionlabel"
                  value={state.optionlabel || ""}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => SelectedValues(e, "optionlabel")}
                />
              </Grid>
            <Grid item xs={12}>
              Please seperate each option with a comma
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  label="Dropdown Options"
                  name="options"
                  value={state.options || ""}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => SelectedValues(e, "options")}
                />
              </Grid>
              
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => Updatedata()}
              >
                Submit
                </Button>
            </Grid>

            </Grid>
          </CardContent>
      </Card>
          <Snackbar
              open={state.OpenAlert}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              message={state.AlarmMessage}
          />
          {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}
    </div>
  );
}
