import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Typography,
    IconButton, Avatar

} from '@mui/material';
//import DynamicForms from '../Forms/FormShow';
import {
    NewPageId,
    FindCategoryIcon,
    isNotEmptyObject,
    removeEmptyStringElements
} from '../utils/CommonGraphql';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb, deleteSvcbotDb } from '../graphql/mutations';
import { PrimaryKeyData } from "../graphql/queries";
import DrawerShow from '../utils/DrawerModule';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import MaterialGallery from '../Gallery/MaterialGallery';
import DocumentList from '../List/DocumentList';
import { useDispatch, useSelector } from 'react-redux';
import HTMLtoReact from 'html-react-parser';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import useMediaQuery from '@mui/material/useMediaQuery';

//const HtmlToReactParser = require('html-to-react').Parser;

//const HTMLtoReact = (htmlInput) => {
//    var htmlToReactParser = new HtmlToReactParser();
//    var reactElement = htmlToReactParser.parse(htmlInput);
//    return reactElement;
//}


let NexText = [];

const ProfileDetails = ({ className, ...rest }) => {

    const dispatch = useDispatch();
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData);
    const [open, setOpen] = useState(false);
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    let initialState = {
        FormJSON: [],
        FormId: "kjsadjhsdaf",
        FormValueArray: [],
        FromArry: [],
        FormJSONBack: [],
        UpdateFormJSON: [],
        ImagePic: "",
        Category: "",
        ShowEditButton: false,
        UnBlock: false,
    };

    const [state, setState] = useState(initialState);
    const [DrawerStatus, OpenDrawer] = useState(false);

    useEffect(() => {
        console.log("ShowDetails.props", rest);
        let FormId = NewPageId("ItemShowDetails");
        let ShowEditButton = false;
        let AllowBlock = false;
        let NewData = {};
        if (rest.Data) {
            NewData = rest.Data;
            //  NewData.key = FormId;
            SeperateData(NewData);
        }

        if (UserData.Level > 1000) {
            ShowEditButton = true;
        }

        // allow contractor/owner to block a person
        if (UserData.CustomerType === "Contractor"
            && rest.ActiveCategory === "Person"
        ) {
            AllowBlock = true;
        }

        if (rest.ActiveKey === UserData.sk1) {
            ShowEditButton = true;
        }

        if (rest.ShowEditButton) {
            ShowEditButton = true;
        }

        setState(prevState => ({
            ...prevState,
            FormId: FormId,
            ShowEditButton: ShowEditButton,
            AllowBlock: AllowBlock
        }));

        CheckIfBlocked();

    }, [rest.Data])

    const CheckIfBlocked = async () => {

        try {

            if (rest.Data && rest.Data.sk1 !== "") {
                const inputtofind = {
                    pk1: ContractorData.CompanyId,
                    sk1: `Block::${rest.Data.sk1}`
                };

                const response = await API.graphql(
                    graphqlOperation(PrimaryKeyData, inputtofind)
                );

                let Datta = response.data.PrimaryKeyData.items[0];

                console.log("DB.Response.PrimaryKeyData", inputtofind, response);

                if (Datta && Datta.pk1 && Datta.sk1 !== "") {
                    setState(prevState => ({
                        ...prevState,
                        UnBlockData: Datta,
                        UnBlock: true,
                    }));
                }

            }

        } catch (error) {
            console.log("PrimaryKeyData", error);
        }
    }

    const SeperateData = data => {
        console.log("ShowDetails.data", data);
        try {

            let pk = "";
            let sk = "";
            let Category = "";
            let NewFromArry = [];
            let FormExtra = [];
            let FormJSON = [];
            let PhotoService = [];
            let Documents = [];
            let avatar = "";
            let AllItemForms = [];

            if (data && data.avatar && data.avatar !== "") {
                avatar = data.avatar;
            }

            if (data && data.AllItemForms) {

                AllItemForms = data.AllItemForms;
                dispatch({ type: 'CURRENT_EDIT', payload: data });

                console.log("ShowDetails.data.AllItemForms", data.AllItemForms);

            }

            console.log("ShowDetails.data.FormJSON", FormJSON);
            console.log("ShowDetails.data.NewFromArry", NewFromArry);

            if (data && data.PhotoService && data.PhotoService.length > 0) {
                PhotoService = data.PhotoService;
            }

            if (data && data.Documents && data.Documents.length > 0) {
                Documents = data.Documents;
            }



            let NewDDD = {
                AllItemForms: AllItemForms,
                //  UpdatedataJSON: data,
                //  UpdateFormJSON: AllItemForms,
                FormExtra: FormExtra,
                Category: data.Category,
                field1: data.field1,
                ImagePic: PhotoService,
                PhotoService: PhotoService,
                Documents: Documents,
                avatar: avatar,
                pk1: data.pk1,
                sk1: data.sk1,
                CompanyId: data.CompanyId,
                CompanyName: data.CompanyName
            }

            console.log("ShowDetails.edit.NewDDD", NewDDD);

            setState(prevState => ({
                ...prevState,
                ...NewDDD
            }));

        } catch (err) { }
    }

    const EditDetails = () => {

        OpenDrawer(true);
    }

    const HandleDeleteItem = () => {

        if (state.DeleteItem !== "" && isNotEmptyObject(state.DeleteItem)) {
            console.log("ListModule.DeleteItem1", state.DeleteItem);

            let NewDBinput = {
                pk1: ContractorData.CompanyId,
                sk1: `Block::${state.DeleteItem.sk1}`,
                field1: state.DeleteItem.field1,
                field2: state.DeleteItem.field2,
            }

            let DBInput = {
                pk1: ContractorData.CompanyId,
                sk1: `Block::${state.DeleteItem.sk1}`,
                dataJSON: JSON.stringify(removeEmptyStringElements(NewDBinput))
                //gsi1Pk2: key,
                //gsi1Sk2: BranchId,
                //gsi2Pk2: HQId,
                //gsi2Sk2: key
            }

            console.log("ListModule.DeleteItem.NewDBinput", NewDBinput);
            API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput }))
                .then((results) => {
                    if (results && results.pk1 !== "") {
                        setState(prevState => ({
                            ...prevState,
                            UnBlockData: DBInput,
                            UnBlock: true,
                        }));
                    }                    
                })
        }
        setOpen(false)
    }

    const UnBlock = async () => {

        if (state.UnBlockData && state.UnBlockData.pk1 && state.UnBlockData.sk1 !== "") {

            console.log("ListModule.DeleteItem1", state.UnBlockData);
            let NewDBinput = {
                pk1: state.UnBlockData.pk1,
                sk1: state.UnBlockData.sk1
            }
            console.log("ListModule.DeleteItem.NewDBinput", NewDBinput);
            API.graphql(graphqlOperation(deleteSvcbotDb, { input: NewDBinput }))
                .then((results) => {
                    console.log("ListModule.DeleteItem.results", results);
                    setState(prevState => ({
                        ...prevState,
                        UnBlockData: "",
                        UnBlock: false,
                    }));
                })
        }
    }


    const ConfirmDelete = (item) => {
        setOpen(true)
        setState(prevState => ({ ...prevState, DeleteItem: item }));
    }

    const handleClose = () => {
        setOpen(false);
    };


    const CloseDrawer = (c) => {
        // GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    const EachLine = (eachline, index) => {

        let VariableInput = "";

        if (eachline.ShowValue) {
            VariableInput = eachline.ShowValue;

            let checkhtml = /<\/?[a-z][\s\S]*>/i.test(eachline.ShowValue);

            if (checkhtml) {
                console.log("ShowDetails.eachline", checkhtml, eachline);
                VariableInput = HTMLtoReact(eachline.ShowValue);
            }

        } else {

            if (typeof eachline.VariableInput === 'string') {
                VariableInput = eachline.VariableInput;
            } else {
                if (eachline.VariableInput && eachline.VariableInput.label) {
                    VariableInput = eachline.VariableInput.label;
                } else if (eachline.VariableInput && eachline.VariableInput.value) {
                    VariableInput = eachline.VariableInput.value;
                }
            }
        }

        if (VariableInput !== "") {
            return (
                <>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        key={index + 1}
                        sx={{ mb: 1 }}
                    >
                        <Typography variant="h5">
                            {eachline.label}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        key={index + 2}
                        sx={{ mb: 1 }}
                    >
                        <Typography>
                            {VariableInput}
                        </Typography>
                    </Grid>
                </>
            )
        }

    }

    console.log("Testing.Form.Edit.1.Showdetails.state", state);
    console.log("Testing.Form.Edit.Showdetails.props", rest);

    return (

        <div key={state.FormId}>

            <Grid container spacing={2}>


                <Grid item xs={12}>
                    <Card key={"crd1" + state.FormId}>
                        <CardHeader
                            title={rest.Activefield1}
                            subheader="Information"
                            action={state.ShowEditButton && (
                                <IconButton aria-label="settings"
                                    onClick={() => EditDetails()}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            )
                            }
                            avatar={state.avatar !== "" ? (
                                <Avatar aria-label="recipe" src={state.avatar} />
                            ) : (
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon(state.Category)}
                                </Avatar>
                            )
                            }

                        />

                        <CardContent>
                            <Grid container spacing={0} >

                                {rest.Data && rest.Data.AllItemForms
                                    && rest.Data.AllItemForms.length > 0
                                    && rest.Data.AllItemForms.map((each, index) => (
                                        each.FormType === "AutoComplate" ? (

                                            <Grid
                                                item
                                                xs={12}
                                                key={index + "Showdd4"}
                                            >

                                                <Card
                                                    key={index + "carddssww"}
                                                >
                                                    <CardHeader
                                                        //subheader="The information can be edited"
                                                        title={each.Category}
                                                    />
                                                    <Divider />
                                                    <CardContent>
                                                        {/*
                                                            NexText.length > 0 && NexText.map((eachline, line) => (
                                                            <Grid item xs={12} key={line + each.pk1}>
                                                                <Typography>{eachline}</Typography>
                                                            </Grid>
                                                            ))
                                                        */}

                                                        <Typography>{each.SearchWords}</Typography>

                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        ) : (

                                            each.FormData.map((eachline, line) => (
                                                EachLine(eachline, line + index + eachline.VariableName)
                                            ))

                                        )
                                    ))}

                                {/*
                                    state.FormExtra && state.FormExtra.length > 0
                                    && state.FormExtra.map((each, index) => {
                                        console.log("ShowDetails.FormExtra.each", each);
                                        if (each && each !== null && each.SearchWords) {
                                            NexText = each.SearchWords.split(",");
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={index + "Showdd4"}
                                                >

                                                    <Card
                                                        key={index + "carddssww"}
                                                    >
                                                        <CardHeader
                                                            //subheader="The information can be edited"
                                                            title={each.Category}
                                                        />
                                                        <Divider />
                                                        <CardContent>
                                                            {NexText.length > 0 && NexText.map((eachline, line) => (
                                                                <Grid item xs={12} key={line + each.pk1}>
                                                                    <Typography>{eachline}</Typography>
                                                                </Grid>
                                                            ))}

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                            NexText = [];
                                        }
                                    }
                                    )
                                

                                <Grid item xs={12} key={'282389239'}>
                                    Block person
                                </Grid>
                                */}

                                {state.AllowBlock && !state.UnBlock ? (
                                    <Grid item xs={12} key={'282389239'}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => ConfirmDelete(rest.Data)}
                                        //  className={classes.button}
                                        >
                                            Block Person
                                        </Button>
                                    </Grid>
                                ) : null} 

                                {state.UnBlock ? (
                                    <Grid item xs={12} key={'2823ddsas89239'}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => UnBlock()}
                                        //  className={classes.button}
                                        >
                                            Un-Block Person
                                        </Button>
                                    </Grid>
                                ) : null} 

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {state.PhotoService && state.PhotoService.length > 0 && (
                    <Grid xs={12} item>
                        <Card key={"creedsdfff1"}>
                            <CardHeader
                                title="Images / Video"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Image")}
                                    </Avatar>
                                }
                            />
                            <CardContent>
                                <MaterialGallery
                                    PhotoService={state.PhotoService}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {state.Documents && state.Documents.length > 0 && (
                    <Grid xs={12} item>
                        <DocumentList
                            DocumentList={state.Documents}
                        />
                    </Grid>
                )}

            </Grid>
            {/*
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => EditDetails()}
                >
                    Edit
                </Button>
            </Box>
            */}

            <Dialog
                //  fullScreen={}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Block Person
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={HandleDeleteItem} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    // UpdateFormJSON: state.UpdateFormJSON,
                    Category: state.Category,
                    //UpdateFormExtra: state.FormExtra,
                    UpdateExisting: true,
                    CompanyId: state.CompanyId,
                    // UpdatePk1: state.pk1,
                    //  UpdateKey: state.sk1,
                    CompanyName: state.CompanyName,
                    ImageUpload: true,
                    ImagePic: state.ImagePic,
                    UpdatedataJSON: rest.Data,
                    id: state.sk1,
                    avatar: state.avatar,
                    //Category: state.Category,
                    //key: state.key,
                    //CompanyId: state.CompanyId,
                    //CompanyName: state.CompanyName,
                    //CustomerType: state.CustomerType,
                    //...state.DetailsProps
                    //ESCId: props.ESCId
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>

    );
};

ProfileDetails.propTypes = {
    className: PropTypes.string
};

export default ProfileDetails;

