import React, { useEffect, useState } from 'react';
import {
    TextField,
    Backdrop, CircularProgress,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    Snackbar,
    CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import UploadEachDocument from './UploadEachDocument';
import { API } from "aws-amplify";
import { NewPageId } from '../utils/CommonGraphql';
//import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    removeEmptyStringElements,
} from '../utils/CommonGraphql';
import { DateTime } from "luxon";
import { FormArray } from '../Forms/FormList';
import SelectAssets from '../components/SelectAssets';
import DynamicForms from '../Forms/FormShow';
import DropZone from '../Upload/DropZone';
//import { CheckInDocumentType } from '../utils/Documents';


export default function RadioButtonsGroup(props) {
    //  const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        AssetList: [],
        DoneDone: false,
        CompanyId: "",
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);


    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        let UploadDocForm = GetDeveloperForm();
        if (UploadDocForm) {
            setState(prevState => ({
                ...prevState,
                UploadDocForm: UploadDocForm
            }));
        }

        //  console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])

    useEffect(() => {

        if (props.SREvent
            && props.SREvent.ContractorData
            && props.SREvent.ContractorData.CompanyId) {
            setState(prevState => ({
                ...prevState,
                ContractorId: props.SREvent.ContractorData.CompanyId,
                ContractorName: props.SREvent.ContractorData.CompanyName,
            }));
        }

    }, [props.SREvent]);

    const GetDeveloperForm = () => {

        let DevEdit = FormArray("uploadDocument", NewPageId("uploadDocument"));
        if (DevEdit && DevEdit[0].FormData) {
            return DevEdit[0].FormData
        } else {
            return []
        }
    }

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                handleNext();
                SetLoading(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    useEffect(() => {
        let Documents = [];
        let PhotoService = [];
        if (state.uploadDocument
            && state.uploadDocument.FormData
            && state.uploadDocument.FormData.length > 0) {

            if (state.uploadDocument.PhotoService.length > 0
                || state.uploadDocument.Documents.length > 0) {

                if (state.uploadDocument.Documents.length > 0) {
                    Documents = state.uploadDocument.Documents;
                }

                if (state.uploadDocument.PhotoService.length > 0) {
                    PhotoService = state.uploadDocument.PhotoService;
                }
            }

            setState(prevState => ({
                ...prevState,
                PhotoService: PhotoService,
                Documents: Documents
            }));

        }

    }, [state.uploadDocument]);


    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    //const PutInDB = () => {

    //    let event = props.SREvent;
    //    let ContractorId = "Data::Corp::Branch::Info::2023-09-06T01:48:39.253Z"; //event.ContractorData.CompanyId

    //    let offset = 0;
    //    let timeZoneName = "Pacific Standard Time";


    //    if (event.ContractorData && event.ContractorData.TimeZone) {
    //        timeZoneName = event.ContractorData.TimeZone.value;
    //        offset = parseFloat(event.ContractorData.TimeZone.offset);
    //    }

    //    let UTCTume = DateTime.utc().toString();
    //    let ShowDate = DateTime.now().setZone(timeZoneName).toLocaleString(DateTime.DATETIME_MED);

    //    let ImageStateSet = state.ImageStateSet;
    //    let MiniDataJSON = {};
    //    let InputArray = [];
    //    let SearchWords = "";
    //    let field1 = "";
    //    let field2 = "";
    //    let DocData = {};
    //    let AllItemForms = [];

    //    if (ImageStateSet
    //        && ImageStateSet.ImageDisplay
    //        && ImageStateSet.ImageDisplay.length > 0) {           


    //        let MiniDataJSON2 = {
    //            CompanyId: event.posterId.CompanyId,
    //            CompanyName: event.posterId.CompanyName,
    //            ContractorId: event.ContractorData.CompanyId,
    //            ContractorName: event.ContractorData.CompanyName,
    //        }

    //        if (state.PhotoService && state.PhotoService.length > 0) {

    //            state.PhotoService.map(eaaaa => {
    //                SearchWords += `${eaaaa.SearchWords}, `;
    //                field1 += `${eaaaa.field1}, `;
    //                field2 += `${eaaaa.field2}, `;
    //            })

    //            AllItemForms = [
    //                {
    //                    "sk1": `CheckIn::Doc::${UTCTume}::pic`,
    //                    "Category": "Person",
    //                    "Documents": [],
    //                    "field1": "Document Infomation",
    //                    "FormData": [
    //                        {
    //                            "Category": "Document",
    //                            "defaultValue": field1,
    //                            "Editable": false,
    //                            "extra": "field1",
    //                            "key": "ff_p6ZcrMay10",
    //                            "label": "File Name",
    //                            "ListInfo": true,
    //                            "options": [
    //                            ],
    //                            "Order": 1,
    //                            "required": true,
    //                            "Searchable": true,
    //                            "ShowValue": field1,
    //                            "TableColumn": true,
    //                            "type": "textline",
    //                            "VariableInput": field1,
    //                            "VariableName": "field1"
    //                        },
    //                        {
    //                            Order: 1,
    //                            //options: [],
    //                            VariableName: "field2",
    //                            VariableInput: "",
    //                            Editable: false,
    //                            TableColumn: true,
    //                            extra: "field2",
    //                            message: "",
    //                            type: "textline",
    //                            key: 1001,
    //                            Category: "Document",
    //                            Searchable: true,
    //                            required: true,
    //                            label: "File Type",
    //                           // DataSourse: "options",
    //                            defaultValue: field2,
    //                            options: [],
    //                        },
    //                    ],
    //                    "FormName": "Document Infomation",
    //                    "FormType": "Document",
    //                    "key": `CheckIn::Doc::${UTCTume}::pic1`,
    //                    "PhotoService": [
    //                    ],
    //                    "SearchWords": "x",
    //                    "ValueAdded": true
    //                },

    //            ];

    //            MiniDataJSON = {
    //                ...MiniDataJSON2,
    //                AllItemForms: AllItemForms,
    //                field1: field1,
    //                field2: "Pictures",
    //                PhotoService: state.PhotoService,
    //                SearchWords: `${event.SearchWords}, ${SearchWords}`,
    //                pk1: `CheckIn::${event.PostId}`,
    //                sk1: `CheckIn::Doc::${UTCTume}::pic`,
    //                gsi1Pk2: `Doc::${event.posterId.CompanyId}`,
    //                gsi1Sk2: UTCTume,
    //                gsi2Pk2: `Doc::${ContractorId}`,
    //                gsi2Sk2: UTCTume,
    //            }

    //            DocData = {
    //                pk1: `CheckIn::${event.PostId}`,
    //                sk1: `CheckIn::Doc::${UTCTume}::pic`,
    //                gsi1Pk2: `Doc::${event.posterId.CompanyId}`,
    //                gsi1Sk2: UTCTume,
    //                gsi2Pk2: `Doc::${ContractorId}`,
    //                gsi2Sk2: UTCTume,
    //                dataJSON: JSON.stringify(removeEmptyStringElements(MiniDataJSON))
    //            };

    //            InputArray.push(DocData);
    //        }

    //        if (state.Documents && state.Documents.length > 0) {

    //            state.Documents.map((each, indd) => {

    //                AllItemForms = [
    //                    {
    //                        "sk1": `CheckIn::Doc::${UTCTume}::${indd}`,
    //                        "Category": "Person",
    //                        "Documents": [
    //                        ],
    //                        "field1": "Document Infomation",
    //                        "FormData": [
    //                            {
    //                                "Category": "Document",
    //                                "defaultValue": each.field1,
    //                                "Editable": false,
    //                                "extra": "field1",
    //                                "key": "ff_p6ZcrMay10",
    //                                "label": "File Name",
    //                                "ListInfo": true,
    //                                "options": [
    //                                ],
    //                                "Order": 1,
    //                                "required": true,
    //                                "Searchable": true,
    //                                "ShowValue": each.field1,
    //                                "TableColumn": true,
    //                                "type": "textline",
    //                                "VariableInput": each.field1,
    //                                "VariableName": "field1"
    //                            },
    //                            {
    //                                "Category": "Document",
    //                                "defaultValue": each.field2,
    //                                "Editable": false,
    //                                "extra": "field2",
    //                                "key": "ff_p6ZcrMay10",
    //                                "label": "File Type",
    //                                "ListInfo": true,
    //                                "options": [
    //                                ],
    //                                "Order": 1,
    //                                "required": true,
    //                                "Searchable": true,
    //                                "ShowValue": each.field2,
    //                                "TableColumn": true,
    //                                "type": "textline",
    //                                "VariableInput": each.field2,
    //                                "VariableName": "field2"
    //                            },
    //                        ],
    //                        "FormName": "Document Infomation",
    //                        "FormType": "Document",
    //                        "key": `CheckIn::Doc::${UTCTume}::${indd}`,
    //                        "PhotoService": [
    //                        ],
    //                        "SearchWords": "x",
    //                        "ValueAdded": true
    //                    },

    //                ];
    //                MiniDataJSON = {
    //                    ...MiniDataJSON2,
    //                    Documents: [each],
    //                    AllItemForms: AllItemForms,
    //                    field1: each.field1,
    //                    field2: each.field2,
    //                    SearchWords: `${event.SearchWords}, ${each.SearchWords}`,
    //                    pk1: `CheckIn::${event.PostId}`,
    //                    sk1: `CheckIn::Doc::${UTCTume}::${indd}`,
    //                    gsi1Pk2: `Doc::${event.posterId.CompanyId}`,
    //                    gsi1Sk2: UTCTume,
    //                    gsi2Pk2: `Doc::${ContractorId}`,
    //                    gsi2Sk2: UTCTume,
    //                }

    //                DocData = {
    //                    pk1: `CheckIn::${event.PostId}`,
    //                    sk1: `CheckIn::Report::${UTCTume}::${indd}`,
    //                    gsi1Pk2: `Doc::${event.posterId.CompanyId}`,
    //                    gsi1Sk2: UTCTume,
    //                    gsi2Pk2: `Doc::${ContractorId}`,
    //                    gsi2Sk2: UTCTume,
    //                    dataJSON: JSON.stringify(removeEmptyStringElements(MiniDataJSON))
    //                };

    //                InputArray.push(DocData);
    //            })
    //        }

    //        setState(prevState => ({
    //            ...prevState,
    //            DoneDone: true
    //        }))


    //    } else {

    //        handleNext();

    //    }

    //    console.log("UploadEachBox.InputArray", InputArray);    

    //    if (InputArray && InputArray.length > 0) {

    //        let PosttoDB = {
    //            MutationType: "createBatch",
    //            DBData: InputArray
    //        };

    //        // Post to DB
    //        TriggerLambda(PosttoDB);

    //    }
    //}

    const TriggerLambda = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    const SaveData = () => {

        try {

            let event = props.SREvent;
            let UserData = event.posterId;

            let offset = 0;
            let timeZoneName = "Pacific Standard Time";

            if (event.ContractorData && event.ContractorData.TimeZone) {
                timeZoneName = event.ContractorData.TimeZone.value;
                offset = parseFloat(event.ContractorData.TimeZone.offset);
            }

            let currentTime = DateTime.utc().toString();
            let ShowDate = DateTime.now().setZone(timeZoneName).toLocaleString(DateTime.DATETIME_MED);

            // let currentTime = DateTime.utc().toString();


            // var currentUnixTime = new Date();
            // var currentTime = currentUnixTime.toISOString();
            // let ShowDate = formatDate(currentUnixTime);  //new Date().toLocaleString()
            let UUID = NewPageId("Doc")
            let InputArray = [];
            let Location = "";
            let NewFormJSON = {};
            let Description = ""
            let Field1 = "";
            let Field2 = "";
            let DocumentType = "";
            let avatar = "";
            let SearchWords = `${ShowDate}, `;
            let AssetData = {};
            let PhotoService = [];
            let Documents = [];

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }



            if (state.uploadDocument
                && state.uploadDocument.FormData
                && state.uploadDocument.FormData.length > 0) {

                if (state.uploadDocument.PhotoService.length > 0
                    || state.uploadDocument.Documents.length > 0) {

                    if (state.uploadDocument.Documents.length > 0) {
                        Documents = state.uploadDocument.Documents;
                    }

                    if (state.uploadDocument.PhotoService.length > 0) {
                        PhotoService = state.uploadDocument.PhotoService;
                    }

                    state.uploadDocument.FormData.map((EachForm, indd) => {
                        switch (EachForm.VariableName) {
                            case "name":
                                Field1 = EachForm.ShowValue
                                break;
                            case "DocumentType":
                                Field2 = `${EachForm.ShowValue} ${ShowDate}`;
                                DocumentType = EachForm.key;
                                break;
                            case "Description":
                                Description = EachForm.VariableInput
                                break;
                            default:
                                Field1 = EachForm.ShowValue
                        }

                        // SearchWords += `${EachForm.label}: ${EachForm.ShowValue}, `;
                        if (EachForm.ShowValue && EachForm.ShowValue !== "") {
                            SearchWords += `${EachForm.ShowValue}, `;
                        }

                    })

                    if (DocumentType === "") {
                        DocumentType = "pictures"
                    }

                    if (Field1 === "") {
                        Field1 = "Pic/Doc"
                    }

                    if (Field2 === "") {
                        Field2 = `Doc/Pic: ${ShowDate}`;
                    }

                    let Dockey = `Doc::${DocumentType}::${UUID}`

                    let Dataentry = {
                        pk1: state.ContractorId,
                        sk1: Dockey,
                        gsi1Pk2: `Doc::${state.ContractorId}`,
                        gsi1Sk2: currentTime,
                        gsi2Pk2: state.ContractorId,
                        gsi2Sk2: Dockey
                    }

                    let Mini = {
                        field1: Field1,
                        field2: Field2,
                        AllItemForms: [state.uploadDocument],
                        PhotoService: PhotoService,
                        Documents: Documents,
                        Description: Description,
                        date: currentTime,
                        SearchWords: SearchWords,
                        Category: "Documents",
                        posterId: posterId,
                        key: Dockey,
                        ...Dataentry
                    };

                    let Dataentry2 = {
                        ...Dataentry,
                        dataJSON: JSON.stringify(removeEmptyStringElements(Mini))
                    }

                    InputArray.push(Dataentry2)

                    if (state.AssetList.length > 0) {
                        state.AssetList.map((each, indd) => {

                            if (each.Location) {
                                Location = each.Location;
                            } else {
                                Location = "";
                            }

                            if (each.avatar) {
                                avatar = each.avatar;
                            } else {
                                avatar = "";
                            }

                            AssetData = {
                                pk1: Dockey,
                                sk1: each.key,
                                gsi1Pk2: each.key,
                                gsi1Sk2: Dockey,
                                gsi2Pk2: state.ContractorId,
                                gsi2Sk2: Dockey
                            }

                            NewFormJSON = {
                                ...Mini,
                                field1: each.field1,
                                field2: `${Field2}: ${Field1}`,
                                Location: Location,
                                avatar: avatar,
                                key: each.key,
                                ...AssetData
                            }

                            InputArray.push({
                                ...AssetData,
                                dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                            })

                            Location = "";
                        })
                    }

                    console.log("uploadDocument.InputArray", InputArray);
                    console.log("uploadDocument.Mini", Mini);
                    console.log("uploadDocument.NewFormJSON", NewFormJSON);

                    if (InputArray && InputArray.length > 0) {

                        let PosttoDB = {
                            MutationType: "createBatch",
                            DBData: InputArray
                        };

                        setState(prevState => ({
                            ...prevState,
                            DoneDone: true
                        }))

                        // Post to DB
                        TriggerLambda(PosttoDB);

                    }

                } else {

                    handleNext();

                }

                //else {
                //    //alart upload document

                //    setState(prevState => ({
                //        ...prevState,
                //        AlarmMessage: "Please Select Files for Upload",
                //        OpenAlert: true
                //    }));

                //}
            } else {
                handleNext();
            }

            //else {
            //    //alart upload document

            //    setState(prevState => ({
            //        ...prevState,
            //        AlarmMessage: "Please Complete the form",
            //        OpenAlert: true
            //    }));

            //}






        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const handleNext = () => {

        let Body = "No Attachments";
        if (state.PhotoService.length > 0 || state.Documents.length > 0) {
            Body = "(see Picture/Document)";
        }

        let SelectedDataReturn = {
            ...state,
            Body: Body,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("UploadBox.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    console.log("UploadEachBox.state", state)
    console.log("UploadEachBox.props", props)

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title={`Select ${props.Category}`}
                        />

                        <CardContent>
                            <Grid container spacing={2}>

                                {
                                    state.UploadDocForm && state.UploadDocForm.length > 0 && (
                                    <Grid item xs={12} >

                                        <DynamicForms
                                            key={"uploadDocument11"}
                                            id={"uploadDocument33"}
                                            SaveDetails={true}
                                            Category="uploadDocument"
                                            FormType="uploadDocument"
                                            DisableForm={false}
                                            //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                            //  CompanyId={state.CompanyId}
                                            title="Add Document"
                                            ShowEditButton={false}
                                            UpdateFormData={e => console.log("UpdateFormData", e)}
                                            PreviewEdit={false}
                                            EditItem={e => console.log("EditItem", e)}
                                            FormData={state.UploadDocForm}
                                            onSubmit={(model) => SetValues(model, "uploadDocument")}
                                        />


                                    </Grid>
                                    )
                                }

                                {/* 
                                <Grid item xs={12} >
                                    <Card>
                                        <CardHeader
                                            title="Link Documents to Assets"
                                        />

                                        <CardContent>

                                            <SelectAssets
                                                SelectedAsset={(e) => SetValues(e, "AssetList")}
                                                CompanyId={state.ContractorId}
                                                CompanyName={state.ContractorName}
                                                title={`Select Asset`}
                                                Category="Asset"
                                                AssetList={state.AssetList}
                                            />

                                        </CardContent>


                                    </Card>
                                </Grid>
                                */}

                            </Grid>

                        </CardContent>


                    </Card>

                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => SaveData()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </>

    );
}
