import React, { useState, useEffect } from 'react';
import {
    Grid, Stepper, Step, StepLabel, StepContent, TextField,
    Backdrop, Chip,
    CircularProgress,
    Paper, Snackbar,
    Card, Typography, CardContent, FormControl,
    RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Divider, Collapse, CardActions,
    Select, MenuItem, InputLabel, CardHeader, Box, Button,
    IconButton
} from '@mui/material';
//import { makeStyles } from '@mui/styles';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import { updateSvcbotDb, createSvcbotDb, createBatch } from '../graphql/mutations';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import CardBox from '../components/ShowCheckBoxGroup';
import {
    removeEmptyStringElements,
    NewPageId,
    PrimaryKeyDataGet,
    MiddleTeamSearch,
    UpdateData,
    querySvcbotDbsByGsi1Pk2
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import ListCheckBox from '../List/ListCheckbox';
import ESCEachStepDetails from '../Setup/ESCEachStepDetails';
import {
    Hours, Dayss, WeekOfMonth, MonthsOfYear, DateTodaySlash, currentTime,
    formatDate, TimeStampEndofDay
} from '../utils/DateTime';
import DateRange from '../components/DateRange';
import ESCPerStep from '../Setup/ESCPerStep';
import CheckBoxGroup from '../components/CheckBoxGroup'
import grey from "@mui/material/colors/grey";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';


//const useStyles = makeStyles((theme) => ({
//    root: {
//        backgroundColor: theme.palette.background.dark,
//        minHeight: '100%',
//        paddingBottom: theme.spacing(3),
//        paddingTop: theme.spacing(3)
//    },
//    Cardroot: {
//        width: '100%',
//    },
//    heading: {
//        fontSize: theme.typography.pxToRem(15),
//        fontWeight: theme.typography.fontWeightRegular,
//    },
//    paper: {
//        padding: theme.spacing(2),
//        color: theme.palette.text.secondary,
//    },
//    button: {
//        marginTop: theme.spacing(1),
//        marginRight: theme.spacing(1),
//    },
//    actionsContainer: {
//        marginBottom: theme.spacing(2),
//    },
//    resetContainer: {
//        padding: theme.spacing(3),
//    },
//    formControl: {
//        //margin: theme.spacing(1),
//    },
//    card: {
//        // backgroundColor: primary800
//    }

//}));


let DateOption = [
    {
        label: "Start Date",
        VariableName: "StartDate",
        value: DateTodaySlash,
    },
    {
        label: "End Date",
        VariableName: "EndDate",
        value: DateTodaySlash,
    },

];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const defaultdays = [0, 1, 2, 3, 4, 5, 6];
const DefaultWeekOfMonth = [0, 1, 2, 3, 4];
const DefaultMonthsOfYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

let ContinueLoop = true;
let LastStepIndex = 0;


const Account = (props) => {
    //  const classes = useStyles();
    const params = useParams();
    let query = useQuery();
    const ContractorData = useSelector(state => state.ContractorData);
    const [expanded, setExpanded] = useState(false);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        StartTime: "",
        EndTime: "",
        Dayss: "",
        WeekOfMonth: "",
        MonthsOfYear: "",
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log('ShiftDetails.props', props);
        let DataFile = props.Event;
        SetDayValues(Dayss, DataFile.Days, "Dayss" )
        SetDayValues(WeekOfMonth, DataFile.WeekOfMonth, "WeekOfMonth")
        SetDayValues(MonthsOfYear, DataFile.MonthsOfYear, "MonthsOfYear")

        setState(prevState => ({
            ...prevState,
            StartTime: DataFile.StartTime,
            EndTime: DataFile.EndTime
        }));

    }, [props])



    const SetDayValues = (Arrayy, SelectedValues, Name) => {
        let NewCheckList = Arrayy;
        let ShowList = "";

        NewCheckList.map((each, ind) => {
            if (SelectedValues.includes(each.key)) {
                ShowList += `${each.field1}, `;
            }
        })

        ShowList = ShowList.replace(/,([^,]*)$/, '$1');

        setState(prevState => ({
            ...prevState,
            [Name]: ShowList,
        }));
    };

    console.log('ShiftDetails.state', state);
    return (

          
                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={4}>
                        <Typography variant="body2"  component="div">
                            Shift Start Time:
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={8}>
                        <Typography variant="body2"  component="div">
                            {state.StartTime}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2"  component="div">
                            Shift End Time:
                        </Typography>
                    </Grid>
                    <Grid  item xs={8}>
                        <Typography variant="body2"  component="div">
                            {state.EndTime}
                        </Typography>
                    </Grid>

                    <Grid  item xs={4}>
                        <Typography variant="body2"  component="div">
                            Days of Week:
                        </Typography>
                    </Grid>
                    <Grid  item xs={8}>
                        <Typography variant="body2"  component="div">
                            {state.Dayss}
                        </Typography>
                     
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="body2"  component="div">
                            Week of the Month:
                        </Typography>
                    </Grid>
                    <Grid  item xs={8}>
                        <Typography variant="body2"  component="div">
                            {state.WeekOfMonth}
                        </Typography>
                     
                    </Grid>

                    <Grid  item xs={4}>
                        <Typography variant="body2"  component="div">
                            Months of the Year:
                        </Typography>
                    </Grid>
                    <Grid  item xs={8}>
                        <Typography variant="body2"  component="div">
                            {state.MonthsOfYear}
                        </Typography>
                    
                    </Grid>
                </Grid>
     
    );
};

export default Account;
