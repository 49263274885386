import React, { useState, useEffect } from 'react';
import ReactToPrint from "react-to-print";
import {
    Paper, Grid, Button, Box,
    Backdrop,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel, FormGroup,
    Snackbar, FormControlLabel, Switch, TextField
} from '@mui/material';

import { FunctionalComponentToPrint } from "./PrintBody";

let date = new Date();
let localdate = date.toLocaleDateString()

const FunctionalComponent = (props) => {
    const componentRef = React.useRef(null);
    const initialState = {
        AlarmMessage: "",
        OpenAlert: false,
        PaymentMade: false,
        SelectedNumber: "",
        donedone: false,
        Amount: 29,
        Title: `${props.ReportName} - ${localdate}`,
        Description: "",
        SelectedItems: props.SelectedItems
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("SRDetails.props", props)

        setState({
            ...state,
            SelectedItems: props.SelectedItems.sort()
        });
        
    }, [props.SelectedItems])

    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called");
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {        
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        
        // Good
        return (<Button
            variant="contained"
            color="primary"
        >
            Print
        </Button>);
    }, []);

    const SelectedValues = (item, name) => {
        setState({
            ...state,
            [name]: item,
        });
    };

    const SetValues = (item, name) => {
        console.log("PrintReport.item", item);
        let SelectedItems = state.SelectedItems;
        if (item) {
            SelectedItems.reverse();
        } else {
            SelectedItems.sort();
        }

        setState({
            ...state,
            [name]: item,
            SelectedItems: SelectedItems
        });
    };

    console.log("PrintReport.state", state);

    return (
        <div>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography >Add Title and short Description to the Report</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        value={state.Title}
                        disabled={props.disabled}
                        label="Title"
                        fullWidth
                        onChange={(e) => SelectedValues(e.target.value, "Title")}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={state.Description}
                        disabled={props.disabled}
                        label="Description"
                        multiline
                        rows={2}
                        fullWidth
                        onChange={(e) => SelectedValues(e.target.value, "Description")}
                        variant="outlined"
                    />
                </Grid>

                <Grid xs={12} item>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            //disabled={state.Priority === "Emergency"}
                            checked={state.Order || ""}
                            onChange={(e) => SetValues(e.target.checked, "Order")}
                        />} label="Ascending/Descending order by Date" />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
            <ReactToPrint
                content={reactToPrintContent}
                documentTitle="Report"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={reactToPrintTrigger}
                    />
                </Grid>

                {state.SelectedItems && (
                <Grid item xs={12}>
                    <FunctionalComponentToPrint
                        ref={componentRef}
                        SelectedItems={state.SelectedItems}
                        DataBack={props.DataBack}
                        Title={state.Title}
                        Description={state.Description}
                    />
                    </Grid>
                )}

            </Grid>
            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default FunctionalComponent;

