/*
 * 
 * Shows company ESC options
 * check the boxes for each.  this is what will be used in triggering ESC
 * 
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/material/styles';
//import { API, graphqlOperation } from "aws-amplify";
//import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
//import Accordion from '../components/Accordion';
import {
    //TextField,
    //Collapse,
    //Paper,
    Grid, Button,
    Backdrop,
    CircularProgress,
    //Card, FormGroup,
    //CardContent,
    //CardActions,
    //InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';
import {
  //  removeEmptyStringElements,
 //   NewPageId,
 //   EleminateDuplicateObjects,
    getAutoCompletedata
} from '../utils/CommonGraphql';
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
//import { ServiceEsc } from '../temp/ESCTeams';
//import Page from '../components/Page';

// const UUid = NewPageId("Urgency");

//const useStyles = makeStyles((theme) => ({
//    root: {
//        // backgroundColor: theme.palette.background.dark,
//        //minHeight: '100%',
//        padding: theme.spacing(2),
//    },
//    paper: {
//        padding: theme.spacing(2),
//        color: theme.palette.text.secondary,
//    },
//}));



export default function SimpleCard(props) {
   // const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        //title: "",
        TotalProgress: [],
        ShowCompany: true,
        ShowContractor: false,
       // UseCustomESC: true,
        ContractorESC: [],
        CompanyESCDefault: [],
        DrawerTitle: "On-Call Rotation Shift Setup",
        CompanyESC: [],
        TotalESC: [],
        ESC: [],
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        GetDatafromDB("Sub", ContractorData.CompanyId, "Company");

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
            /// }
            console.log("Priority.StepValueArray", StepValueArray)
            //if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }


    }, [])

    const handleNext = () => {

        let NewData = [];
        if (state.CompanyESCDefault && state.CompanyESCDefault.length > 0) {
            NewData = MakeDB(state.CompanyESCDefault, props.CompanyId);
        }

        let SelectedDataReturn = {
          //  UseCustomESC: state.UseCustomESC,
            CompanyESC: NewData,
            CompanyESCDefault: state.CompanyESCDefault,
            id: props.id,
            KeyId: props.KeyId
        }
        //console.log("SMSListQuestions.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    //make it DB pk1/sk1
    const MakeDB = (sk1array, pk1) => {
        let CompanyDB = [];
        sk1array.forEach(sk1 => {
            CompanyDB.push({
                sk1: sk1,
                pk1: pk1
            })
        })
        return CompanyDB;
    }

    const GetDatafromDB = async (Category, CompanyId, ArrayName) => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId);
        setState(prevState => ({ ...prevState, ...NewData }))

        SetLoading(false);
        console.log("UrgencyDetails.NewData", NewData);
    }

    const CloseDrawer = () => {
        GetDatafromDB("Sub", ContractorData.CompanyId, "Company");
        SetDeawerOpen(false)
    }



    const Additem = (DetailComponent, Category, CompanyId, CompanyName) => {
        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            ShowDetails: false,
            DetailsProps: {
                Category: Category,
                CompanyId: CompanyId,
                CompanyName: CompanyName,
                CloseDrawer: () => CloseDrawer()
            }
        }))
        SetDeawerOpen(true);
    }


    const KeyArray = (data, cat) => {
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));

        //  NewValues(data)
    }


    return (
        <div
           // className={classes.paper}
          //  {/*id={props.NewId}*/}
          //  {/*key={"key" + props.NewId}*/}
        >

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <ShowCheckBoxGroup
                        field1="Sub-Contractor"
                        field2="List"
                        disabled={false}
                        AllowDelete={false}
                        DeleteItem={(e) => console.log("DeleteItem", e)}
                        AllowEdit={false}
                        EditItem={(e) => console.log("EditItem", e)}
                        AllowAdd={true}
                        AddItem={() => Additem("ESCSetupPage", "ESC", props.CompanyId, props.CompanyName)}
                        Data={state.Company}
                        ShowUpDown={false}
                        ShowEdit={false}
                        AddBox={(e) => console.log("EditItem", e)}
                        DefaultChecked={state.CompanyESCDefault || []}
                        NextLoadComponenet="ESCSetupPage"
                        Category="ESC"
                        KeyArray={(e) => KeyArray(e, "CompanyESCDefault")}
                        PerPage={5}
                        id={"CompanyESC"}

                    />
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Backdrop
               // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => SetDeawerOpen(false)}
                DrawerTitle={state.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => CloseDrawer(e),
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </div>
    );
}
