import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
//import * as Yup from 'yup';
//import { Formik } from 'formik';
import {
    Box, Button, Container, Snackbar, Grid, //Link,
    TextField, Typography, LinearProgress,
    Backdrop, CircularProgress, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch as UseDispt, useSelector } from 'react-redux';
import {
    removeEmptyStringElements,
    ConfirmationNumber,
    UpdateData
} from '../../utils/CommonGraphql'
//import { currentTime } from '../../utils/DateTime';
//import { createBatch } from '../../graphql/mutations';
import { strengthIndicator, strengthColor } from "../../utils/PasswordStrength";
import { Auth, API, graphqlOperation } from "aws-amplify";
import Page from '../../components/Page';
//import { FormSchema, CommonFormUpdated, FormPrefArray } from '../../Forms/FormList';
import PhoneInput from 'react-phone-input-2';
import { SendEmail } from '../../utils/SendEmail';
//import { useCookies } from "react-cookie";
//import awsconfig from '../../aws-exports';

//var store = require('store');
var validator = require("email-validator");
//const ContractorId = window.ContractorId;

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
});

const dynamodb = new AWS.DynamoDB.DocumentClient();


const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    field: {
        margin: '10px 0',
    },
    countryList: {
        ...theme.typography.body1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));




function LinearProgressWithLabel(props) {

    console.log("LinearProgressWithLabel", props);

    let Display = 0;
    let percentvalue = Math.round(props.value, 0);
    if (percentvalue && percentvalue > 0) {
        Display = percentvalue;
    }
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Display}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const UpdateDB = (event) => {
    try {
        console.log(`Loginpage.UpdateDB`, event);
        var params = {

            Key: {
                'pk1': event.pk1,
                'sk1': event.sk1,
            },
            ExpressionAttributeNames: {
                "#gsi1Pk2": "gsi1Pk2",
                "#gsi1Sk2": "gsi1Sk2",
                "#gsi2Pk2": "gsi2Pk2",
                "#gsi2Sk2": "gsi2Sk2",
                "#dataJSON": "dataJSON"
            },
            TableName: "svcbot_db",
            UpdateExpression: "set #gsi1Pk2 = :gsi1Pk2, #gsi1Sk2 = :gsi1Sk2, #gsi2Pk2 = :gsi2Pk2, #gsi2Sk2 = :gsi2Sk2, #dataJSON = :dataJSON",
            ExpressionAttributeValues: {
                ":gsi1Pk2": event.gsi1Pk2,
                ":gsi1Sk2": event.gsi1Sk2,
                ":gsi2Pk2": event.gsi2Pk2,
                ":gsi2Sk2": event.gsi2Sk2,
                ":dataJSON": event.dataJSON
            },
        };

        dynamodb.update(params, function (err, data) {
            if (err) {
                //callback(err);
                console.log("Loginpage.UpdateDB.err:", err);
            } else {
                //callback(null, data);
                console.log(`Loginpage.UpdateDB.success`);
            }
        });

    } catch (err) {
        console.log('PutInDB err', err);
    }

}; // end PutInDB

var GetDBData = (params) => {
    return new Promise((resolve, reject) => {
        dynamodb.query(params, function (err, data) {
            if (err) {
                console.log("errrr", err, err.stack);
                // an error occurred
            } else {
                console.log("databack DB", data)
                resolve(data);
            }// successful response
        });
    });
};

const GetData = async (CompanyId, sk1) => {
    try {
        let getItemParams2 = {
            //IndexName: "gsi1Pk2-gsi1Sk2-index",
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1)",
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1",
            },
            ExpressionAttributeValues: {
                ":pk1": CompanyId,
                ":sk1": sk1
            }
        };

        let EscPolicy = await GetDBData(getItemParams2);

        console.log("LoginPage.ContractorId.EscPolicy", sk1, EscPolicy)

        return EscPolicy.Items;

    } catch (err) {
        console.log('GetData err', err);
    }
};

const BatchWrite = (InputArray) => {
    try {
        let params = {
            RequestItems: {
                "svcbot_db": InputArray
            }
        };
        //  console.log(`BatchWrite.params: ${JSON.stringify(params)}`);
        dynamodb.batchWrite(params, function (err, data) {
            if (err) {
                //callback(err);
                console.log(`BatchWrite.err: ${err}`);
                // return false;
            } else {
                console.log("BatchWrite.worked:", data);
                //  return true;
            }
        });
    } catch (err) {
        console.log('BatchWrite err', err);
    }
}


const LoginView = (props) => {
    const classes = useStyles();
    const dispatch = UseDispt()
    const navigate = useNavigate();
    const HQData = useSelector(state => state.HQData);
    const SetLogo = useSelector(state => state.SetLogo);
    const WebsiteType = useSelector(state => state.WebsiteType);
    console.log("LoginPage.props", props)
    const initialState = {
        username: "",
        ContractorArray: [],
        password: "",
        confirm: "",
        email: "",
        mobile: "",
        WebsiteType: window.WebsiteType,
        step: "SignInEmail",
        AlartMessage: "",
        StrengthLevel: 0,
        authcode: "",
        open: false,
        confirmmessage: "",
        toDashboard: false,
        ContractorName: "", //window.ContractorName,
        ContractorId: "", //window.ContractorId,
        usernamecode: "",
        Direction: "",
        cid: window.WebsiteType, //"1668207562084_",
        loggo: "",
        DoneDone: false,
        DenyMessge: "",
    };

    const [state, setState] = React.useState(initialState);
    const [loading, SetLoading] = useState(false);
    //const [cookies, setCookie] = useCookies(["contractor"]);

    useEffect(() => {
        console.log("window.WebsiteType", WebsiteType)
        if (window.WebsiteType === "ServiceWithText") {

            setState((prevState) => ({
                ...prevState,
                ContractorName: "ServiceWithText.com",
                ContractorId: "Developer::Account::0786",
             //   loggo: "https://www.ServiceWithText.com/images/Logo_svc_Black.png",
                loggo: "https://www.servicewithtext.com/images/Logo_SD_Black3.png",
                cid: "ServiceWithText",
                step: "SignInEmail",
            }))

        } else {

            setState((prevState) => ({
                ...prevState,
                ContractorName: "ContractorCheckIn.com",
                ContractorId: "Developer::Account::0786",
                loggo: "https://www.contractorcheckin.com/images/Logo_checkin_Black.png",
                cid: "ContractorCheckIn",
                step: "SignInEmail",
            }))
        }

    }, []);

    const SetValues = (name, value) => {

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const onAddValue = (names, values) => {
        // console.log("new Input", [names], values)

        let TheValue = values;
        // let usernamecode = "";

        if (names === "email") {
            TheValue = values.toLowerCase();

        }

        setState((prevState) => ({
            ...prevState,
            [names]: TheValue,
            // usernamecode: usernamecode
        }))

        if (names === "password") {
            PasswordStrength(values);
        }
        if (names === "confirm") {
            if (state.password !== values) {

                setState((prevState) => ({
                    ...prevState,
                    confirmmessage: "Two passwords do not match"
                }))

            } else {

                setState((prevState) => ({
                    ...prevState,
                    confirmmessage: ""
                }))

            }
        }
    };

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                dispatch({ type: 'LOGGED_IN', payload: true });
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);    

    useEffect(() => {
        if (props.LandingPage) {
            console.log("LoginPage.props.LandingPage", props.LandingPage);
            dispatch({ type: 'SERVICE_LANDING_PAGE', payload: true });
        }
    }, [props.LandingPage]);

   

   

    const FindContractor = async () => {

        try {

            if (state.ContractorSMS !== "") {

                SetLoading(true)

                //get contractor info
                let getItemParams = {
                    TableName: "svcbot_db",
                    KeyConditionExpression: "#pk1 = :pk1",
                    ScanIndexForward: false,
                    ExpressionAttributeNames: {
                        "#pk1": "pk1",
                    },
                    ExpressionAttributeValues: {
                        ":pk1": "phone::" + state.ContractorSMS
                    }
                };

                var ContractorFound = await GetDBData(getItemParams);

                SetLoading(false)

                //Contractor found
                if (ContractorFound.Count > 0) {
                    let ContractorData = ContractorFound.Items[0];

                    if (ContractorData && ContractorData.dataJSON.status === "Active") {

                        setState((prevState) => ({
                            ...prevState,
                            ContractorName: ContractorData.dataJSON.ContractorName,
                            ContractorId: ContractorData.dataJSON.ContractorId,
                            loggo: ContractorData.dataJSON.loggo,
                           // cid: ContractorData.dataJSON.cid,
                            ContractorSMS: state.ContractorSMS,
                            DenyMessge: "",
                            step: "SignInEmail",
                        }))

                    } else {

                        setState((prevState) => ({
                            ...prevState,
                            DenyMessge: "Service Provider Not Found",
                            step: "ContractorSMS",
                        }))

                    }

                } else {

                    setState((prevState) => ({
                        ...prevState,
                        DenyMessge: "Service Provider Not Found",
                        step: "ContractorSMS",
                    }))

                }
            } else {

                setState((prevState) => ({
                    ...prevState,
                    open: true,
                    AlartMessage: "* Please enter correct Number "
                }))

            }

        } catch (err) { console.log("error in FindContractor") }

    }; // end FindContractor

   

    const PasswordStrength = (pwd) => {
        const strength = strengthIndicator(pwd);
        const color = strengthColor(strength);
        var StrengthLevel = Math.round((strength / 5) * 100);
        if (strength < 5) {

            setState((prevState) => ({
                ...prevState,
                ProgressStatus: "exception"
            }))
        } else {

            setState((prevState) => ({
                ...prevState,
                ProgressStatus: "success"
            }))
        }

        setState((prevState) => ({
            ...prevState,
            StrengthColor: color,
            StrengthLevel: StrengthLevel,
        }))

    };

    const PasswordCheck = () => {

        if (state.Direction === "Forgot") {

            if (state.confirm === state.password && state.password !== "") {
                OpenSystem();
                SignIn(state.password);
            } else {
                setState((prevState) => ({
                    ...prevState,
                    AlartMessage: "Passwords did not match, please re-enter",
                    step: "Waiting",
                    open: true
                }))
            }

        } else {
            ConfirmContact();
        }

    }

    const ConfirmContact = () => {

        if (state.confirm === state.password && state.password !== "") {

            let Emailcode = ConfirmationNumber();
            let SMScode = ConfirmationNumber();

            console.log(
                `SMS_Code: ${SMScode} -- email ${Emailcode}`
            );

            if (state.email !== "") {
                //send email with code
                SendConfirmationEmail(Emailcode);
            }

            if (state.mobile !== "") {
                //send sms to confirm
                SendConfirmationSMS(SMScode);
            }

            setState((prevState) => ({
                ...prevState,
                skjdls: Emailcode,
                alsiw: SMScode,
                step: "confirmcode"
            }))

        } else {
            setState((prevState) => ({
                ...prevState,
                AlartMessage: "Passwords did not match, please re-enter",
                open: true
            }))
        }

    }

    const SendConfirmationEmail = (code) => {

        try {

            //let LocalDate = new Date(currentTime).toLocaleString();
            //let ContractorSubDomain = `https://${ContractorData.SubDomain}.service-call-cloud.com`;
            ////let title = `${state.SubscriptionName} - ${LocalDate}`;

            let SendEvent = {

                PhotoService: [],
                Documents: [],
                SpecialInstructions: "",
                PreSelect: [],
                title: "Confirm Email",
                postHTML: `<p>Please confirm email</p><p>Email: ${state.email}</p><p>Confirmation Code: ${code}</p>`,
                PersonList: [state.email],
                postTEXT: `Please confirm email \n Email: ${state.email} \n Confirmation Code: ${code}`,
                ReplyEmail: "support@sninc.us",
                PostId: "checkingemail",

                ContractorAddress: "30 N Gould St, Suite 37310, Sheridan, WY 82801",
                ContractorPhone: "(440) 643-8889",
                //ContractorLogo: state.ContractorDataActive.avatar,
                avatar: state.loggo,
                ContractorName: state.ContractorName,

                UnsubscribeLink: `https://app.${state.WebsiteType}.com/Unsubscribe`,
                ActionButton: `https://app.${state.WebsiteType}.com`,
                ActionButtonTitle: "Go Online",
                ActionButtonShow: false
            }


            console.log(
                "LoginPage.test.2.SendEmail",
                SendEvent
            );

            SendEmail(SendEvent);



        } catch (err) { }

    }

    const SendConfirmationSMS = (code) => {

        try {

            var SMSstring = {
                Body: `Please confirm mobile # ${state.mobile}. Confirmation Code: ${code}`,
                FromSMS: state.mobile,
                From: "+15623936334",
                To: [state.mobile],
                //PostId: PostId,
                mediaUrl: []
            };

            console.log(
                "LoginPage.test.2.SMSstring",
                SMSstring
            );

            API.post("SMStoTwilio", "", {
                body: { Records: SMSstring }
            });

        } catch (err) { }

    }


    const SignUp = async () => {

        let usernamecode = `${state.cid}::${state.email}`;
        usernamecode = usernamecode.toLocaleLowerCase();
        let PPw = state.PersonActive.id;
        console.log("Loginpage.SignUp.IN", usernamecode)
        //UpdatePassword();

        await Auth.signUp({
            username: usernamecode,
            password: PPw,
            attributes: {},
            validationData: [], // optional
        })
            .then((data) => {
                SignIn(state.password);

            })
            .catch((err) => console.log("Loginpage.SignUp.err", err));
    };


    const handleClose = () => {
        //setState({ ...state, open: false });
        setState((prevState) => ({
            ...prevState,
            open: false
        }))
    };

    const ConfirmSignUp = async () => {

        //emailauthcode smsauthcode
        let emailConfirmed = false;
        let smsConfirmed = false;

        if (state.emailauthcode === state.skjdls) {
            emailConfirmed = true;
        }

        if (state.smsauthcode === state.alsiw) {
            smsConfirmed = true;
        }

        setState((prevState) => ({
            ...prevState,
            emailConfirmed: emailConfirmed,
            smsConfirmed: smsConfirmed
        }))

        if (smsConfirmed || emailConfirmed) {

            if (state.Direction === "Forgot") {
                //show new password setup
                setState((prevState) => ({
                    ...prevState,
                    step: "Waiting"
                }))

            } else {
                OpenSystem();
                SignUp();
            }

        } else {
            //message - not match, re-enter or resend code.

            setState((prevState) => ({
                ...prevState,
                open: true,
                AlartMessage: "Code did not match, please re-enter"
            }))

        }

    };

    const SignIn = async (smpnumber) => {

      //  SendConfirmationEmail("1234");

        if (smpnumber === state.password) {
            
            let usernamecode = `${state.cid}::${state.email}`;
            usernamecode = usernamecode.toLocaleLowerCase();
            let PPw = state.PersonActive.id;
            console.log("LoginPage.SignUp.SignIn", usernamecode, PPw);
            Auth.signIn(usernamecode, PPw).then(user => {
                console.log("LoginPage.SignUp.SignIn.user", user);
                LoadDataRedux();

            }).catch(err => {
                console.log("Sign In err codes", err);
                setState((prevState) => ({
                    ...prevState,
                    toDashboard: false,
                    confirmcodemessage: `Sorry The Sign In failed. Please try again or contact ${state.ContractorName}`,
                    step: "SignInEmail"
                }))
            })

        } else {

            setState((prevState) => ({
                ...prevState,
                toDashboard: false,
                confirmcodemessage: `Please enter correct password`,
                step: "SignInEmail"
            }))
        }
    };

    const OpenSystem = () => {

        try {

            var PersonD = state.PersonData;

            LoadDataRedux();

            let NewDataJSON = {
                ...state.PersonActive,
                status: "Active",
                smpnumber: state.password,
                mobile: state.mobile,
                email: state.email,
                emailConfirmed: state.emailConfirmed,
                smsConfirmed: state.smsConfirmed
            }

            console.log("LoginPage.NewDataJSON", NewDataJSON);

            var ValueToUpdate = {
                //pk1: PersonD.sk1,
                //sk1: PersonD.sk1,
                //gsi1Pk2: "phone::" + state.mobile,
                //gsi1Sk2: PersonD.sk1,
                //gsi2Pk2: state.PersonActive.CompanyId,
                //gsi2Sk2: PersonD.sk1,   
                ...PersonD,
                dataJSON: removeEmptyStringElements(NewDataJSON)
            };

            UpdateDB(ValueToUpdate);

            dispatch({ type: 'USER_DATA', payload: NewDataJSON });
           // dispatch({ type: 'SHOW_HELP', payload: true });

            setState((prevState) => ({
                ...prevState,
                toDashboard: true,
                PersonData: ValueToUpdate,
                PersonActive: NewDataJSON
            }))
            
        } catch (err) {
            console.log("Sign In err codes", err);
        }
    };

    const FindGlobal = async (data) => {

        let getItemParams = {
            TableName: "svcbot_db",
            IndexName: "gsi1Pk2-gsi1Sk2-index",
            KeyConditionExpression:
                "#gsi1Pk2 = :gsi1Pk2 and begins_with(#gsi1Sk2, :gsi1Sk2)",
            ExpressionAttributeNames: {
                "#gsi1Pk2": "gsi1Pk2",
                "#gsi1Sk2": "gsi1Sk2",
            },
            ExpressionAttributeValues: {
                ":gsi1Pk2": data.sk1,
                ":gsi1Sk2": "Data::Company::Global::",
            },
        };

        var PersonFound = await GetDBData(getItemParams);
        // console.log("LoginPage.Global", getItemParams, PersonFound);
        if (PersonFound) {
            var PersonData = PersonFound.Items;
            dispatch({ type: 'GLOBAL', payload: PersonData });
        }
    }


    const VerifyAccount = async () => {
        try {

            let NewEmail = state.email.toLowerCase();
            
            //alarm message if no email
            if (!NewEmail && !validator.validate(NewEmail)) {
                setState((prevState) => ({
                    ...prevState,
                    open: true,
                    AlartMessage: "* Please enter correct email "
                }))
                return;
            }
                      
            let key = `Data::Company::Resource::Person::${WebsiteType}::${NewEmail}`;
            console.log("LoginPage.Global", key);
            let getItemParams = {
                TableName: "svcbot_db",
                KeyConditionExpression: "#pk1 = :pk1 ",
                ScanIndexForward: false,
                ExpressionAttributeNames: {
                    "#pk1": "pk1"
                },
                ExpressionAttributeValues: {
                    ":pk1": key
                }
            };

            var PersonFound = await GetDBData(getItemParams);

            if (PersonFound.Count > 0) {

                var PersonData = PersonFound.Items[0];
                var PersonActive = PersonData.dataJSON;


                setState((prevState) => ({
                    ...prevState,
                    PersonData: PersonData,
                    PersonActive: PersonActive
                }))

                console.log(
                    "LoginPage.test.1.PersonData",
                    PersonData
                );

                setState((prevState) => ({
                    ...prevState,
                    PersonData: PersonData,
                }))

                //Load Company
                    LoadContractorInfo(PersonActive)


                if (PersonActive.mobile && PersonActive.mobile !== "") {
                    setState((prevState) => ({
                        ...prevState,
                        mobile: PersonActive.mobile
                    }))
                }

                //if person has been invited and waiting for account setup
                if (PersonActive.status === "Waiting") {
                    setState((prevState) => ({
                        ...prevState,
                        step: "Waiting"
                    }))

                    //send email and sms
                    ConfirmContact();

                }

                //if person has created the accout and is active
                if (PersonActive.status === "Active") {

                    setState((prevState) => ({
                        ...prevState,
                        step: "Active",
                    }))
                }

                //if person has created the accout and is active
                if (PersonActive.status === "Removed") {

                    setState((prevState) => ({
                        ...prevState,
                        step: "Removed"
                    }))
                }
            } else {
                dispatch({ type: 'LOGGED_OUT' });
                setState((prevState) => ({
                    ...prevState,
                    confirmcodemessage: `Sorry, your email is not in the system. Please try again or contact ${state.ContractorName}`,
                    step: "SignInEmail"
                }))
            }

        } catch (err) { }
    };

    const LoadDataRedux = (PersonActive) => {

        console.log("LoginPage.redux", state.PersonActive);        

        let MenuHeader = "Main";
        let lengthss = 0;
        if (state.PersonActive && state.PersonActive.CompanyName && state.PersonActive.CompanyName !== "") {
            MenuHeader = state.PersonActive.CompanyName.substring(0, 18);
            lengthss = state.PersonActive.CompanyName.length;

            if (lengthss >= 18) {
                MenuHeader = state.PersonActive.CompanyName.substring(0, 23);
            } else {
                MenuHeader = state.PersonActive.CompanyName.substring(0, 18);
                MenuHeader = `${MenuHeader} Menu`;
            }
        }

        // open system with logged in true
        dispatch({ type: 'USER_DATA', payload: state.PersonActive });
        dispatch({ type: 'NOW_EDITING', payload: state.PersonActive.CustomerType });
        dispatch({ type: 'MENU_HEADER', payload: MenuHeader });

        setState(prevState => ({
            ...prevState,
            DoneDone: true
        }));

    }

    //const LoadSubContractor = async (Person) => {

    //    var Sub = await GetData(Person.ContractorId, Person.CompanyId);

    //    if (Sub) {
    //        var ContractorData = Sub[0];
    //        var ContractorDataActive = ContractorData.dataJSON;
    //        dispatch({ type: 'ACCOUNT_ACCESS', payload: ContractorDataActive });
    //    }

    //}

    //const LoadCurrentCompany = async (Person) => {

    //    var Sub = await GetData(Person.ContractorId, Person.CompanyId);

    //    if (Sub) {
    //        var ContractorData = Sub[0];
    //        var ContractorDataActive = ContractorData.dataJSON;
    //        dispatch({ type: 'CURRENT_COMPANY', payload: ContractorDataActive });
    //        // dispatch({ type: 'ACCOUNT_ACCESS', payload: ContractorDataActive });
    //    }

    //}

    const LoadContractorInfo = async (Persondata) => {

        try {

            var ContractorFound = await GetData("Developer::Account::0786", Persondata.CompanyId);
           
            console.log("LoginPage.ContractorId.ContractorFound", ContractorFound)
           
            if (ContractorFound) {
                var ContractorData = ContractorFound[0];
                var ContractorDataActive = ContractorData.dataJSON;

                if (Persondata.CustomerType === "Contractor") {
                    dispatch({ type: 'CONTRACTOR_DATA', payload: ContractorDataActive });

                    if (ContractorDataActive.avatar && ContractorDataActive.avatar.includes("https:")){
                        dispatch({ type: 'SET_LOGO', payload: ContractorDataActive.avatar });
                    }
                }

                if (Persondata.CustomerType === "Company") {
                    dispatch({ type: 'CURRENT_COMPANY', payload: ContractorDataActive });
                }

                setState((prevState) => ({
                    ...prevState,
                    ContractorDataActive: ContractorDataActive
                }))

            }

            

            //if (HQFound) {
            //    var HQFounddd = HQFound[0];
            //    var HQdataJSON = HQFounddd.dataJSON;
            //    dispatch({ type: 'HQ_DATA', payload: HQdataJSON });

            //    setState((prevState) => ({
            //        ...prevState,
            //        HQdataJSON: HQdataJSON
            //    }))
            //}

        } catch (err) { }
    };

    const SignInStart = () => {
        if (state.PersonActive && state.PersonActive.smpnumber !== "") {
            SignIn(state.PersonActive.smpnumber)
        } else {
            setState((prevState) => ({
                ...prevState,
                AlartMessage: "Account Not Found",
                open: true
            }))
        }

    }

    const ForgotPassword = () => {

        let Emailcode = ConfirmationNumber();
        let SMScode = ConfirmationNumber();

        console.log(
            `SMS_Code: ${SMScode} -- email ${Emailcode}`
        );

        if (state.email) {
            //send email with code
                SendConfirmationEmail(Emailcode);
        }

        if (state.mobile) {
            //send sms to confirm
                 SendConfirmationSMS(SMScode);
        }

        setState((prevState) => ({
            ...prevState,
            skjdls: Emailcode,
            alsiw: SMScode,
            step: "confirmcode",
            Direction: "Forgot"
        }))
    }

    const SetServiceProvider = (data) => {

        let FindServiceProvider = state.ContractorArray.find(x => x.sk1 === data)

        setState(prevState => ({
            ...prevState,
            ServiceProviderId: data,
            ContractorName: FindServiceProvider.CompanyId,
            ContractorId: FindServiceProvider.CompanyName,
            loggo: FindServiceProvider.avatar,
            cid: FindServiceProvider.cid,
            step: "SignInEmail",
        }));
    }


    console.log("LoginPage.state", state)

    return (
        
            <Page
                className={classes.root}
                title="Login"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                >
                    <Container maxWidth="sm">

                       
                            <Box my={3}
                                sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                            >
                        <img src={SetLogo} alt="Logo" height="100" />

                            </Box>
                    

                    {state.step === "ContractorSMS" && (
                        <>

                            <Box mb={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Contractor Text Number
                                </Typography>

                                {state.DenyMessge !== "" ? (
                                    <Typography
                                        color="textPrimary"
                                        variant="h4"
                                    >
                                        {state.DenyMessge}
                                    </Typography>
                                ): null }
                            </Box>

                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <InputLabel id="phone1121">Contractor Text Number</InputLabel>
                                    <PhoneInput
                                        country={'us'}
                                        key="phonekeyysak"
                                        id="phonekeyysassak"
                                        //   dropdownClass={classes.countryList}
                                        disabled={false}
                                        label="Contractor Text Number"
                                        inputProps={{
                                            name: state.ContractorSMS,
                                            label: "Contractor Text Number",
                                            required: true,
                                            style: { width: '100%' },
                                            //value: Item.VariableInput || ""
                                            //error: ErrorMessage
                                        }}
                                        isValid={(value, country) => {
                                            if (value.match(/12345/)) {
                                                return 'Invalid value: ' + value + ', ' + country.name;
                                            } else if (value.match(/1234/)) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        }}
                                        value={state.Mobile}
                                        onChange={(e) => SetValues("ContractorSMS",'+' + e)}

                                    />
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    //disabled={isSubmitting}
                                    onClick={FindContractor}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Next
                                </Button>
                            </Box>
                        </>
                    )}

                    {state.step === "SelectContractor" && (
                        <>
                            
                            <Box mb={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Select
                                </Typography>
                            </Box>
                            
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    {state.ContractorArray
                                        && state.ContractorArray.length > 0 ? (
                                        <FormControl variant="outlined" >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Select Service Provider
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                required={true}
                                                sx={{ width: 400 }}
                                                autoWidth={true}
                                                value={state.ServiceProvider}
                                                onChange={(e) => {
                                                    SetServiceProvider(e.target.value);
                                                }}
                                                    label="Select Service Provider"
                                            >

                                                    {state.ContractorArray.map((o, indd) => (
                                                    <MenuItem key={indd} value={o.sk1}>
                                                        {o.field1}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl >
                                    ) : (
                                        <Typography>
                                            No Contractor Selected
                                        </Typography>

                                    )}


                                </Grid>
                            </Grid>

                        </>
                    )}


                        {state.step === "SignInEmail" && (
                            <>

                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Sign in
                                    </Typography>
                                </Box>
                                {state.confirmcodemessage}
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    //onBlur={onAddValue}
                                    onChange={e => onAddValue("email", e.target.value)}
                                    type="email"
                                    value={state.email || ""}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        //disabled={isSubmitting}
                                        onClick={() => VerifyAccount("Account")}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Next
                                    </Button>
                                </Box>

                            </>
                        )}
                        {state.step === "Active" && (
                            <>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Sign in
                                    </Typography>
                                </Box>
                                {`Email: ${state.email}`}
                                <TextField
                                    fullWidth
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    //onBlur={onAddValue}
                                    onChange={e => onAddValue("password", e.target.value)}
                                    type="password"
                                    value={state.password || ""}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        //disabled={isSubmitting}
                                        onClick={SignInStart}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Sign in now
                                    </Button>
                                </Box>

                                <Box my={2}>
                                    <Button
                                        onClick={() => ForgotPassword()}
                                        type="submit"
                                    >
                                        Forgot Password
                                    </Button>
                                </Box>
                            </>
                        )}

                        {state.step === "Removed" && (
                            <>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Not Active
                                    </Typography>
                                </Box>
                                <Typography>{`Email: ${state.email}`}</Typography>
                                <Typography color="textPrimary" variant="h3">{`This account has been Disabled from the system. Please contact ${state.ContractorName}`}</Typography>

                            </>
                        )}

                        {state.step === "Waiting" && (
                            <>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        New Password Setup
                                    </Typography>
                                </Box>
                                <Typography>{`Email: ${state.email}`}</Typography>

                                <TextField
                                    fullWidth
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    //onBlur={onAddValue}
                                    onChange={e => onAddValue("password", e.target.value)}
                                    type="password"
                                    //value={values.password}
                                    value={state.password || ""}
                                    variant="outlined"
                                />

                                <TextField
                                    fullWidth
                                    label="Confirm Password"
                                    margin="normal"
                                    name="confirm"
                                    //onBlur={onAddValue}
                                    onChange={e => onAddValue("confirm", e.target.value)}
                                    type="password"
                                    //value={values.password}
                                    value={state.confirm || ""}
                                    variant="outlined"
                                />

                                {state.confirmmessage}


                                <LinearProgressWithLabel value={state.StrengthLevel} color={state.StrengthColor} />
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="body2"
                                >
                                    Min 8 letters, One Uppercase, One Lowercase, One Special Charactor (!#@$%^&*), One Number
                                </Typography>

                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        //disabled={isSubmitting}
                                        onClick={() => PasswordCheck()}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </>
                        )}

                        {state.step === "confirmcode" && (
                            <>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Activate Account
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="h4"
                                    >
                                        Please enter either Phone and/or Email code to activate the account.
                                    </Typography>
                                </Box>

                                <Typography>{`Email: ${state.email}`}</Typography>

                                <TextField
                                    fullWidth
                                    label="Email Authentication Code"
                                    margin="normal"
                                    name="authcode"
                                    //onBlur={onAddValue}
                                    onChange={e => onAddValue("emailauthcode", e.target.value)}
                                    type="text"
                                    value={state.emailauthcode || ""}
                                    variant="outlined"
                                />

                                <Typography>{`Or, Mobile: ${state.mobile}`}</Typography>

                                <TextField
                                    fullWidth
                                    label="Mobile Authentication Code"
                                    margin="normal"
                                    name="authcode"
                                    //onBlur={onAddValue}
                                    onChange={e => onAddValue("smsauthcode", e.target.value)}
                                    type="text"
                                    value={state.smsauthcode || ""}
                                    variant="outlined"
                                />

                                {state.confirmcodemessage}
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        //disabled={isSubmitting}
                                        onClick={ConfirmSignUp}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Confirmation Code
                                    </Button>
                                </Box>

                                <Typography>
                                    <a href="#" onClick={ConfirmContact}>
                                        Resend Code
                                    </a>
                                </Typography>
                            </>
                        )}


                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={state.open}
                            onClose={handleClose}
                            message={state.AlartMessage}
                            key="w87482ows"
                        />

                    </Container>
                </Box>
                <Backdrop
                    open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Page>
       
    );
};

export default LoginView;
