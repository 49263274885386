import React, { useState, useEffect } from 'react';
import { Box, Container, Backdrop, CircularProgress, Grid } from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import TableCheckIn from '../components/TableCheckIn';
import CheckInToolbarHeading from '../components/CheckInToolbarHeading';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import {
    NewPageId,
    MiddlePostSearch,
    ContreactorPostSearch,
    CustomerPostSearch
} from '../utils/CommonGraphql';
import SRList from '../List/SRColumn';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime';
import CheckInList from '../List/CheckInList';
import { API, graphqlOperation } from "aws-amplify";
import {
    onCreateSvcbotDbgsi1,
    onCreateSvcbotDbgsi2, onCreateSvcbotDb,
    onUpdateCompany, onUpdateContractor
} from '../graphql/subscriptions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


//let Todaydate = new Date();
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = adjustedTime(1, "End");

//console.log("SRPage.Date.olddate", olddate);
//console.log("SRPage.Date.Todaydatestring", Todaydatestring);

const ListItems = (props) => {

    let query = useQuery();
    console.log("query", query);

    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch();
    const NowEditing = useSelector(state => state.NowEditing)
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const AccountAccess = useSelector(state => state.AccountAccess);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:1060px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 12,
        //ListType: "ListModule",
        ListType: "ListModule",
        CompanyId: UserData.CompanyId,
        pk1: UserData.CompanyId,
        propss: {},
        key: NewPageId("SRList"),
        SMSType: "Any",
        StartDate: "",
        EndDate: "",
        Sort: "Progress",
        Data: [],
        FullData: [],
        FullDataNew: [],
        NewSubscriptionData: "",
        SearchTerms: "",
        AverageResponse: [],
        Refresh: 0
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('SRPage.params', params);
        console.log('CompanyPage.TabList.SRPage.props', props);

        let Category = "Company";
        let DetailComponent = "Details"
        let ListType = "ListModule";
        let CompanyId = UserData.CompanyId;  //ContractorData.ContractorId;
        let CompanyName = UserData.CompanyName;
        let startdatt = olddate;
        let enddatt = Todaydatestring;

        if (params.CompanyId) {
            CompanyId = params.CompanyId
        }

        if (props.CompanyId) {
            CompanyId = props.CompanyId;
        }

        console.log("SRPage.Date.startdatt", startdatt);
        console.log("SRPage.Date.enddatt", enddatt);

        const UUid = NewPageId(Category);
        setState(prevState => ({
            ...prevState,
            Category: Category,
            UUid: UUid,
            StartDate: startdatt,
            EndDate: enddatt,
            ListType: ListType,
            CompanyId: CompanyId
        }));


        //get data
        console.log("SRPage.DBinput1", CompanyId, startdatt, enddatt);

        GetDatafromDB(CompanyId, "useEffect-s", startdatt, enddatt);

    }, [])

    const GetDatafromDB = async (pk1, from = "", startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let ShowString = {};
        let AverageResponse = [];
        let ResponseObj = [];

        if (startDate == null || startDate === "") {
            startDate = state.StartDate
        }

        if (endDate == null || endDate === "") {
            endDate = state.EndDate
        }

        let UTCdate = new Date(endDate).toISOString();
        let StartDate = new Date(startDate).toISOString();
        let Substrigger = "gsi2Pk2";
        let Subspk = `CheckIn::${ContractorData.CompanyId}`;

        console.log("SRPage.NowEditing", UserData.CustomerType, props.NowEditing, props.ActiveKey);

        if (UserData.CustomerType === "Contractor") {
            if (props.NowEditing === "Contractor") {
                NewData = await ContreactorPostSearch(`CheckIn::${ContractorData.CompanyId}`, StartDate, UTCdate);
                Substrigger = "gsi2Pk2";
                Subspk = `CheckIn::${ContractorData.CompanyId}`;
            }

            if (props.NowEditing === "Company") {
                NewData = await ContreactorPostSearch(`CheckIn::${ContractorData.CompanyId}::${props.ActiveKey}`, StartDate, UTCdate);
                Substrigger = "gsi1Pk2";
                Subspk = `CheckIn::${ContractorData.CompanyId}::${props.ActiveKey}`;
            }

            if (props.NowEditing === "Person") {
                NewData = await MiddlePostSearch(`CheckIn::${ContractorData.CompanyId}::${props.ActiveKey}`, StartDate, UTCdate);
                Substrigger = "gsi1Pk2";
                Subspk = `CheckIn::${ContractorData.CompanyId}::${props.ActiveKey}`;
            }
        }

        if (UserData.CustomerType === "Company") {
            if (props.NowEditing === "Company") {
                NewData = await MiddlePostSearch(`CheckIn::${props.ActiveKey}`, StartDate, UTCdate);
                Substrigger = "gsi1Pk2";
                Subspk = `CheckIn::${props.ActiveKey}`;
            }

            if (props.NowEditing === "Contractor") {
                NewData = await ContreactorPostSearch(`CheckIn::${props.OwnerId}::${props.ActiveKey}`, StartDate, UTCdate);
                Substrigger = "gsi1Pk2";
                Subspk = `CheckIn::${props.OwnerId}::${props.ActiveKey}`;
            }

            if (props.NowEditing === "Person") {
                NewData = await CustomerPostSearch(`CheckIn::Person::${props.ActiveKey}`, StartDate, UTCdate);
                Substrigger = "pk1";
                Subspk = `CheckIn::Person::${props.ActiveKey}`;
            }
        }

        console.log("SRPage.NewData", NewData);

        if (NewData && NewData.length > 0) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                if (ShowString
                    && ShowString.posterId
                    && ShowString.posterId.sk1
                    && ShowString.posterId.sk1 !== "") {
                    AutoSelectData.push(ShowString);
                }
            })
        }

        setState(prevState => ({
            ...prevState,
            AverageResponse: ResponseObj,
            Refresh: 1,
            Substrigger: Substrigger,
            Subspk: Subspk
        }))

        SeperateSubAccounts(AutoSelectData, state.SMSType)

        SetLoading(false);

    }

    useEffect(() => {

        let datacomplete = {};

        if (state.Substrigger === "gsi2Pk2") {

            const subscribe1 = API.graphql({
                query: onCreateSvcbotDbgsi2,
                variables: {
                    gsi2Pk2: state.Subspk
                }
            })
                .subscribe({
                    next: data => {
                        console.log("subscribing.Contractor.new");
                        datacomplete = JSON.parse(data.value.data.onCreateSvcbotDb.dataJSON);
                        ProcessSubscription(datacomplete);
                    }
                })

            const subscribe2 = API.graphql({
                query: onUpdateContractor,
                variables: {
                    gsi2Pk2: state.Subspk
                }
            })
                .subscribe({
                    next: data => {
                        console.log("subscribing.Contractor.update");
                        datacomplete = JSON.parse(data.value.data.onUpdateContractor.dataJSON);
                        ProcessSubscription(datacomplete);
                        // GetDatafromDB(state.CompanyId, "subscribe2", "", "");
                    }
                })

            if (subscribe1) {
                console.log("subscribing.subscribe1", state.Substrigger);
                return () => {
                    subscribe1.unsubscribe();
                }
            }
            if (subscribe2) {
                console.log("subscribing.subscribe2", state.Substrigger);
                return () => {
                    subscribe2.unsubscribe();
                }
            }

        }

        if (state.Substrigger === "gsi1Pk2") {
            const subscribe3 = API.graphql({
                query: onCreateSvcbotDbgsi1,
                variables: {
                    gsi1Pk2: state.Subspk
                }
            })
                .subscribe({
                    next: data => {
                        console.log("subscribing.Company.new");
                        datacomplete = JSON.parse(data.value.data.onCreateSvcbotDb.dataJSON);
                        ProcessSubscription(datacomplete);
                        //  GetDatafromDB(state.CompanyId, "subscribe3", "", "");
                    }
                })

            const subscribe4 = API.graphql({
                query: onUpdateCompany,
                variables: {
                    gsi1Pk2: state.Subspk
                }
            })
                .subscribe({
                    next: data => {
                        console.log("subscribing.company.update");
                        datacomplete = JSON.parse(data.value.data.onUpdateCompany.dataJSON);
                        ProcessSubscription(datacomplete);
                        //  GetDatafromDB(state.CompanyId, "subscribe4", "", "");
                    }
                })

            if (subscribe4) {
                console.log("subscribing.subscribe4", state.Substrigger);
                return () => {
                    subscribe4.unsubscribe();
                }
            }
            if (subscribe3) {
                console.log("subscribing.subscribe3", state.Substrigger);
                return () => {
                    subscribe3.unsubscribe();
                }
            }
        }


    }, [state.Substrigger]);

    const ProcessSubscription = data => {

        setState(prevState => ({
            ...prevState,
            NewSubscriptionData: data
        }))

    }

    useEffect(() => {

        if (state.NewSubscriptionData !== "") {
            // console.log("subscribing.NewSubscriptionData", state.NewSubscriptionData);
            ProcessSubscriptionData(state.NewSubscriptionData, state.FullData, state.SMSType);
        }

    }, [state.NewSubscriptionData]);

    //SearchWords
    useEffect(() => {
        SortPref(state.SMSType);
    }, [state.SearchWords]);

    const ProcessSubscriptionData = (DataJson, FullData, SMSType) => {

        //console.log("subscribing.ProcessSub.DataJson", DataJson);
        //console.log("subscribing.ProcessSub.FullData", FullData);

        let NewDataJson = -1;
        let NewFullData = FullData;

        if (DataJson && DataJson.PostId) {
            if (DataJson.posterId
                && DataJson.posterId.sk1
                && DataJson.posterId.sk1 !== "") {
                NewDataJson = NewFullData.findIndex(x => x.PostId === DataJson.PostId);
                console.log("subscribing.NewDataJson", NewDataJson);
                if (NewDataJson > -1) {
                    NewFullData[NewDataJson] = DataJson;
                } else {
                    NewFullData = [DataJson, ...FullData];
                }
            }
        }

        NarrowDates(NewFullData, SMSType);

    }

    const NarrowDates = (FullData, SMSType) => {

        //let EndDate = new Date(state.EndDate).toISOString();
        //let StartDate = new Date(state.StartDate).toISOString();
        let EndDatetime = new Date(state.EndDate).getTime();
        let Starttime = new Date(state.StartDate).getTime();
        let CurrentTime = "";
        let NewData = [];

        // console.log("subscribing.Dates", state.EndDate, state.StartDate);

        FullData.map(each => {
            CurrentTime = new Date(each.UTCTime).getTime();

            if (each.UTCTime
                && CurrentTime >= Starttime
                && CurrentTime <= EndDatetime) {
                NewData.push(each);
            }
            CurrentTime = "";
        })

        console.log("subscribing.NarrowDates", NewData);

        SeperateSubAccounts(NewData, SMSType);

    }

    const SeperateSubAccounts = (NewData, SMSType) => {

        let FullData = NewData;

        if (NowEditing === "Sub") {
            if (AccountAccess && AccountAccess.AccountsList && AccountAccess.AccountsList.length > 0) {
                let AccountDB = AccountAccess.AccountsList;
                FullData = NewData.filter(o => AccountDB.some(({ Post }) => o.gsi1Pk2 === Post));
            } else {
                FullData = [];
            }
        }

        console.log("subscribing.SeperateSubAccounts", SMSType, FullData);

        SortPref(SMSType, FullData)

    }

    const SortPref = (SMSType, ExistingFullData = []) => {

        let Data = [];
        let ExistingData = [];

        if (ExistingFullData && ExistingFullData.length > 0) {
            ExistingData = ExistingFullData;
        } else {
            ExistingData = state.FullData;
        }

        let AccountDB = [];
        if (UserData && UserData.AccountsList && UserData.AccountsList.length > 0) {
            AccountDB = UserData.AccountsList;
        }

        console.log("subscribing.1.ExistingData", ExistingData);

        switch (SMSType) {
            case "Any":
                Data = ExistingData;
                break;
            case "Quote":
                Data = ExistingData.filter(x => x.Quote === true)
                break;
            case "Assigned":
                Data = ExistingData.filter(o => AccountDB.some(({ Post }) => o.gsi1Pk2 === Post));
                break;
            default:
                Data = ExistingData.filter(x => x.ContractorSMS === SMSType)
                break;
        }

        if (state.SearchWords !== "") {
            Data = Data.filter(x => x.SearchWords.toLowerCase().includes(state.SearchWords.toLowerCase()))
        }

        console.log("subscribing.1.SortPref", SMSType, Data);

        setState(prevState => ({
            ...prevState,
            SMSType: SMSType,
            Data: Data,
            FullData: ExistingData
        }));

    }

    const SetEndDate = date => {
        let EndDate = new Date(date).toISOString();
        console.log("SRPage.Date.EndDate", EndDate);
        setState(prevState => ({
            ...prevState,
            EndDate: EndDate,
        }));

        GetDatafromDB(state.CompanyId, "SetEndDate", state.StartDate, EndDate);

    }

    const SetStartDate = date => {
        let StartDate = new Date(date).toISOString();
        console.log("SRPage.Date.StartDate", StartDate);
        setState(prevState => ({
            ...prevState,
            StartDate: StartDate,
        }));

        GetDatafromDB(state.CompanyId, "SetStartDate", StartDate, state.EndDate);

    }

    const SetThreeColumn = value => {
        setState(prevState => ({
            ...prevState,
            Sort: value,
        }));

    }

    const SearchTerms = value => {
        setState(prevState => ({
            ...prevState,
            SearchWords: value,
        }));
    }

    const CloseDrawer = (c) => {
        OpenDrawer(false);
    }

    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Document Details",
            // SetupSubscription: true,
            DetailComponent: "ShowDetailsText",
            ItemData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        OpenDrawer(true);
        //}
    }

    const NarrowSearch = (search) => {
        try {
            let Newdata = [];
            let RawData = state.DataBack;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.SearchWords) {
                        if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                            Newdata.push(str);
                        }
                    }
                })
                //
                console.log("Newdata search", Newdata);
            } else {
                Newdata = RawData;
            }
            /*SetnoOfPages(Math.ceil(Newdata.length / itemsPerPage))*/
            setState(prevState => ({ ...prevState, DataBack: Newdata }));
        } catch (err) { console.log("NarrowSearch err", err); }
    }

    useEffect(() => {
        if (state.SearchWords && state.SearchWords !== "") {
            NarrowSearch(state.SearchWords);
        }
    }, [state.SearchWords])

    console.log("CheckInPage.state", state);
    console.log("CheckInPage.props", props);

    return (
        <Page
            title={state.Category}
        >
            <Container maxWidth={false}>


                <CheckInToolbarHeading
                    SortPref={sort => SortPref(sort)}
                    SetStartDate={sort => SetStartDate(sort)}
                    SetEndDate={sort => SetEndDate(sort)}
                    SetThreeColumn={sort => SetThreeColumn(sort)}
                    SearchTerms={sort => SearchTerms(sort)}
                    StartDate={state.StartDate}
                    EndDate={state.EndDate}
                // AverageResponse={state.AverageResponse}
                />
                {/* state.Refresh && ()  */}

                <Box mt={2}>

                    {state.Data && (
                        matches ? (
                            <TableCheckIn
                                Sort={state.Sort}
                                // Data={state.Data}
                                title="History"
                                ShowDetailLink={false}
                                AllowDelete={false}
                                disabled={false}
                                DataBack={state.Data}
                                SearchWords={state.SearchWords}
                                DetailLink={(e) => AddBox(e)}
                            />
                        ) : (
                            <CheckInList
                                Sort={state.Sort}
                                // Data={state.Data}
                                title="History"
                                ShowDetailLink={false}
                                AllowDelete={false}
                                disabled={false}
                                DataBack={state.Data}
                                SearchWords={state.SearchWords}
                                DetailLink={(e) => AddBox(e)}
                            />

                        )
                    )}

                </Box>
            </Container>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.Category}

                propss={state.DetailsProps}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </Page>
    );
};

export default ListItems;
