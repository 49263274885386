import {
    AlertCircle as AlertCircleIcon,
    Aperture as ApertureIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Truck as TruckIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    Map as MapIcon,
    Box as BoxIcon,
    Home as HomeIcon,
    PieChart as piechartIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    Phone as PhoneCall,
    Calendar as CalendarIcon,
    FileText as FileTextIcon
} from 'react-feather';

export const CompanyTabs = (props) => [

    {
        label: "History",
        LoadComponenet: "CheckInPage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Checkin",
        HelpBox: {
            ShowHelp: true,
            title: "List of Service Calls",
            description: "List of service calls by stages. Service detail page links",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Service_List.mp4",
        },
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "CheckInPage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: "Company",
                    CustomerType: "Contractor",
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CompanyName: props.CompanyName,

                }
            ]
        }

    },

    {
        label: "Personnel",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel from each company.",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: "Person",
                    DetailLink: true,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },

            ]
        }
    },
    {

        label: "Setup",
        LoadComponenet: "SetupPage",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },

];

export const SmallCompanyTabs = (props) => [

    {
        label: "History",
        LoadComponenet: "ServicePage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "ServicePage",
        HelpBox: {
            ShowHelp: true,
            title: "List of Service Calls",
            description: "List of service calls by stages. Service detail page links",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Service_List.mp4",
        },
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "ServicePage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: "Company",
                    CustomerType: "Company",
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CompanyName: props.CompanyName,

                }
            ]
        }

    },

    {
        label: "Personnel",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel from each company.",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: "Person",
                    DetailLink: true,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },

            ]
        }
    },
    {

        label: "Assets",
        href: `/app/page/Personal`,
        icon: BoxIcon,
        key: "Assets",
        HelpBox: {
            ShowHelp: true,
            title: "Assets",
            description: "List of Assets from each company.",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Assets.mp4",
        },
        propss: {
            Category: "Assets",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Assets",
                    title: "Assets",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },

                {
                    LoadComponenet: "SRFrequencyAssets",
                    Category: "Assets",
                    title: "Assets",
                    size: 6,
                    CompanyId: props.CompanyId,
                    DetailLink: true,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Assets",
                    title: "Assets",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }
    },
    {

        label: "Setup",
        LoadComponenet: "SetupPage",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },

];

export const OwnerTabs = (props) => [

    {
        label: "History",
        LoadComponenet: "CheckInPage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Checkin",
        HelpBox: {
            ShowHelp: true,
            title: "List of Service Calls",
            description: "List of service calls by stages. Service detail page links",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Service_List.mp4",
        },
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "CheckInPage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.ContractorId,
                    UserEditor: props.UserEditor,
                    NowEditing: "Contractor",
                    CustomerType: "Contractor",
                    ActiveCategory: "Company",
                    Activefield1: props.ContractorName,
                    ActiveKey: props.ContractorId,
                    CompanyName: props.ContractorName,

                }
            ]
        }

    },

    {
        label: "KPI",
        LoadComponenet: "SRPage",
        href: `/app/page/Service`,
        icon: piechartIcon,
        key: "Service",
        HelpBox: {
            ShowHelp: true,
            title: "Key performance indicators",
            description: "Summary of service calls by stages, calls per equipment, calls by person, etc.",
            ShowVideo: false,
            VideoId: "https://www.youtube.com/watch?v=R4Kp5Ze3eok",
        },
        propss: {
            Category: "Service",
            PopulatePage: [

                {
                    LoadComponenet: "SRFrequencyAssets",
                    Category: "Assets",
                    title: "Assets",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: "Contractor",
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "SRFrequency",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Person",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: "Contractor",
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                //{
                //    LoadComponenet: "Chart1",
                //    Category: "Branch",
                //    title: "Branch",
                //    size: 6,
                //    CompanyId: props.CompanyId,
                //    NowEditing: props.NowEditing,
                //    UserEditor: props.UserEditor,
                //    CustomerType: props.CustomerType,
                //    CompanyName: props.CompanyName,
                //},

            ]
        }

    },



    {
        label: "Personnel",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel from each company.",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: "Contractor",
                    ActiveCategory: "Person",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    DetailLink: true,
                    ActiveCategory: "Person",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                //{
                //    LoadComponenet: "PersonalAddInstructions",
                //    Category: "Person",
                //    title: "Person",
                //    size: 6,
                //    CompanyId: props.CompanyId,
                //    UserEditor: props.UserEditor,
                //    NowEditing: props.NowEditing,
                //    ActiveCategory: "Company",
                //    Activefield1: props.CompanyName,
                //    ActiveKey: props.CompanyId,
                //    CustomerType: props.CustomerType,
                //    CompanyName: props.CompanyName,
                //}
            ]
        }
    },
    {

        label: "Assets",
        href: `/app/page/Personal`,
        icon: BoxIcon,
        key: "Assets",
        HelpBox: {
            ShowHelp: true,
            title: "Assets",
            description: "List of Assets from each company.",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Assets.mp4",
        },
        propss: {
            Category: "Assets",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Assets",
                    title: "Assets",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },

                {
                    LoadComponenet: "SRFrequencyAssets",
                    Category: "Assets",
                    title: "Assets",
                    size: 6,
                    CompanyId: props.CompanyId,
                    DetailLink: true,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Assets",
                    title: "Assets",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }
    },

    {
        label: "Documents",
        href: `/app/page/Personal`,
        icon: FileTextIcon,
        key: "Documents",
        HelpBox: {
            ShowHelp: true,
            title: "Documents and Pictures",
            description: "All documents and pictures uploaded for specific customers.  Sort by document type (reports, proposals, manuals, drawings, etc.).  Subscription setup for preselected list of personnel who will receive email with documents.",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Documents.mp4",
        },
        propss: {
            Category: "Documents",
            PopulatePage: [
                {
                    LoadComponenet: "DocumentPage",
                    Category: "Documents",
                    title: "Documents",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }

    },

    {
        label: "Sub Contractor",
        LoadComponenet: "ListItems",
        href: `/app/page/Accounts`,
        icon: ApertureIcon,
        key: "Sub-Contractor",
        HelpBox: {
            ShowHelp: true,
            title: "Sub Contractor Setup",
            description: "The sub-contractors can be setup to be part of notification process. Sub-contrctors will have limited access to the system. See the video for more details",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/SubContractor.mp4",
        },
        propssdd: {
            CompanyId: props.ContractorId,
            NowEditing: props.NowEditing,
            UserEditor: props.UserEditor,
            CompanyName: props.ContractorName,
            Category: "Sub-Contractor"
        },

        propss: {
            Category: "Sub-Contractor",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Sub-Contractor",
                    title: "Sub Contractor",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Sub-Contractor",
                    title: "Sub-Contractor",
                    size: 6,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                }
            ]
        }
    },

    {
        label: "Shift",
        LoadComponenet: "ListItems",  //"ListSetupOptions",
        href: `/app/page/Shift`,
        icon: CalendarIcon,
        key: "Shift",
        HelpBox: {
            ShowHelp: true,
            title: "Assign personnal by shift",
            description: "On-call personnel assigned to different shifts.  Escalation policy to notify additional personnel if on-call personnel do not respond on time",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Shift_intro.mp4",
        },
        propss: {
            Category: "ESC",
            PopulatePage: [
                {
                    LoadComponenet: "CalendarBox",
                    ListType: "CalendarBox",
                    Category: "ESC",
                    title: "ESC",
                    size: 12,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    disabled: false,
                    Heading: `${props.CompanyName} On-Call Shift`
                }
            ]
        }
    },

    {

        label: "Setup",
        LoadComponenet: "SetupPage",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: "Contractor",
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },

];

export const PersonServiceTabs = (props) => [
    {
        label: "History",
        LoadComponenet: "HistoryPerson",
        href: `/app/page/History`,
        icon: AlertCircleIcon,
        key: "History",
        propss: {
            Category: "History",
            PopulatePage: [
                {
                    LoadComponenet: "ServicePage", //"HistoryPerson", //"ShowDetailsPkSk",
                    ListType: "History",
                    Category: "History",
                    title: "History",
                    size: 12,
                    CompanyId: props.CompanyId,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    NowEditing: "Person",
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },

    {

        label: "Setup",
        LoadComponenet: "SetupPage",  //"ListSetupOptions",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.CompanyId,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },
    ]


export const PersonTabs = (props) => [
    {
        label: "History",
        LoadComponenet: "HistoryPerson",
        href: `/app/page/History`,
        icon: AlertCircleIcon,
        key: "History",
        propss: {
            Category: "History",
            PopulatePage: [
                {
                    LoadComponenet: "CheckInPage", //"HistoryPerson", //"ShowDetailsPkSk",
                    ListType: "History",
                    Category: "History",
                    title: "History",
                    size: 12,
                    CompanyId: props.CompanyId,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    NowEditing: "Person",
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },

    {

        label: "Setup",
        LoadComponenet: "SetupPage",  //"ListSetupOptions",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.CompanyId,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },

    //{
    //    label: "My Shift",
    //    LoadComponenet: "ListItems",  //"ListSetupOptions",
    //    href: `/app/page/Shift`,
    //    icon: CalendarIcon,
    //    key: "Shift",
    //    propss: {
    //        Category: "ESC",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "PersonCalendarBox",
    //                ListType: "PersonCalendarBox",
    //                Category: "shift",
    //                title: "shift",
    //                size: 12,
    //                CompanyId: props.CompanyId,
    //                NowEditing: props.NowEditing,
    //                ActiveCategory: props.ActiveCategory,
    //                Activefield1: props.Activefield1,
    //                ActiveKey: props.ActiveKey,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName,
    //                disabled: false,
    //                Heading: `${props.Activefield1} On-Call Shift`
    //            },
    //            {
    //                LoadComponenet: "CalendarBox",
    //                ListType: "CalendarBox",
    //                Category: "ESC",
    //                title: "ESC",
    //                size: 12,
    //                CompanyId: props.CompanyId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                ActiveCategory: props.ActiveCategory,
    //                Activefield1: props.Activefield1,
    //                ActiveKey: props.ActiveKey,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName,
    //                disabled: false,
    //                Heading: `${props.Activefield1} On-Call Shift`
    //            }
    //        ]
    //    }
    //},





];



export const HQTabs = (props) => [

    {
        label: "Branch Locations",
        href: `/app/page/Branch`,
        icon: MapIcon,
        key: "Branch",
        propss: {
            Category: "Branch",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Branch",
                    title: "Branch",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Branch",
                    title: "Branch",
                    size: 6,
                    CompanyId: props.HQId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                },
                {
                    LoadComponenet: "Chart1",
                    Category: "Branch",
                    title: "Branch",
                    size: 6,
                    CompanyId: props.HQId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                },
                {
                    LoadComponenet: "RatingChart",
                    Category: "Branch",
                    title: "Branch",
                    size: 6,
                    CompanyId: props.HQId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    //{
    //    label: "Global Customer",
    //    href: `/app/page/Branch`,
    //    icon: MapIcon,
    //    key: "Global",
    //    propss: {
    //        Category: "Global",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "ToolbarHeading",
    //                Category: "Global",
    //                title: "Global",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName
    //            },
    //            {
    //                LoadComponenet: "ListItems",
    //                Category: "Global",
    //                title: "Global",
    //                size: 6,
    //                CompanyId: props.HQId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //            },

    //        ]
    //    }
    //},

    //{
    //    label: "Global Customer",
    //    href: `/app/page/Branch`,
    //    icon: MapIcon,
    //    key: "Global",
    //    propss: {
    //        Category: "Global",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "ToolbarHeading",
    //                Category: "Global",
    //                title: "Global",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName
    //            },
    //            {
    //                LoadComponenet: "ListItems",
    //                Category: "Global",
    //                title: "Global",
    //                size: 6,
    //                CompanyId: props.HQId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //            }

    //        ]
    //    }
    //},

];


export const AssetTabs = (props) => [


    {
        label: "Details",
        LoadComponenet: "ShowDetailsPkSk",
        href: `/app/page/History`,
        icon: AlertCircleIcon,
        key: "History",
        propss: {
            Category: "History",
            PopulatePage: [
                {
                    LoadComponenet: "ShowDetailsPkSk",
                    ListType: "History",
                    Category: "History",
                    title: "History",
                    size: 12,
                    ShowEditButton: true,
                    CompanyId: props.CompanyId,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },
    //{

    //    label: "Setup",
    //    LoadComponenet: "SetupPage",  //"ListSetupOptions",
    //    href: `/app/page/Setup`,
    //    icon: SettingsIcon,
    //    key: "Setup",
    //    propss: {
    //        Category: "Setup",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "SetupPage",
    //                Category: "Setup",
    //                title: "Setup",
    //                size: 12,
    //                CompanyId: props.CompanyId,
    //                ActiveCategory: props.ActiveCategory,
    //                Activefield1: props.Activefield1,
    //                ActiveKey: props.ActiveKey,
    //                UserEditor: props.UserEditor,
    //                NowEditing: props.NowEditing,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName,
    //                ItemData: props.ItemData
    //            }
    //        ]
    //    }
    //},

];

export const GlobalTabs = (props) => [

    {
        label: "Global Locations",
        LoadComponenet: "ProgressArray",
        href: `/app/page/Global`,
        icon: AlertCircleIcon,
        key: "GlobalLocations",
        propss: {
            Category: "Global",
            PopulatePage: [
                {
                    LoadComponenet: "ProgressArray",
                    ListType: "Charts",
                    Category: "Global",
                    title: "Global Locations",
                    size: 12,
                    ShowEditButton: true,
                    CompanyId: props.CompanyId,
                    ActiveCategory: "Global",
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    NowEditing: "Global",
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData,
                    //  DetailLink: true
                }
            ]
        }
    },

    //{
    //    label: "Personnel ",
    //    href: `/app/page/Personal`,
    //    icon: UsersIcon,
    //    key: "Personnel",
    //    HelpBox: {
    //        ShowHelp: true,
    //        title: "Personnel",
    //        description: "List of personnel from each company.",
    //        ShowVideo: true,
    //        VideoId: "https://www.service-opportunity.com/video/Person.mp4",
    //    },
    //    propss: {
    //        Category: "Person",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "ToolbarHeading",
    //                Category: "Person",
    //                title: "Person",
    //                size: 12,
    //                CompanyId: props.CompanyId,
    //                UserEditor: props.UserEditor,
    //                NowEditing: "Global",
    //                ActiveCategory: "Company",
    //                Activefield1: props.CompanyName,
    //                ActiveKey: props.CompanyId,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName,
    //            },
    //            {
    //                LoadComponenet: "ListItems",
    //                Category: "Person",
    //                title: "Person",
    //                size: 6,
    //                CompanyId: props.CompanyId,
    //                UserEditor: props.UserEditor,
    //                NowEditing: "Global",
    //                DetailLink: true,
    //                ActiveCategory: "Company",
    //                Activefield1: props.CompanyName,
    //                ActiveKey: props.CompanyId,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName,
    //            },
    //            //{
    //            //    LoadComponenet: "PersonalAddInstructions",
    //            //    Category: "Person",
    //            //    title: "Person",
    //            //    size: 6,
    //            //    CompanyId: props.CompanyId,
    //            //    UserEditor: props.UserEditor,
    //            //    NowEditing: props.NowEditing,
    //            //    ActiveCategory: "Company",
    //            //    Activefield1: props.CompanyName,
    //            //    ActiveKey: props.CompanyId,
    //            //    CustomerType: props.CustomerType,
    //            //    CompanyName: props.CompanyName,
    //            //}
    //        ]
    //    }
    //},
    {
        label: "Personnel Access",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel from each company.",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "GlobalPersonList",
                    ListType: "GlobalLocations",
                    Category: "Global",
                    title: "Global Person",
                    size: 12,
                    ShowEditButton: true,
                    CompanyId: props.CompanyId,
                    ActiveCategory: "Global",
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    NowEditing: "Global",
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData,
                    //  DetailLink: true
                }
            ]
        }
    },

];

//SubTabs
export const SubTabs = (props) => [
    {
        label: "Service",
        LoadComponenet: "SRPage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Service",
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "SRPage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    //{
    //    label: "Map View",
    //    LoadComponenet: "MapPage",
    //    href: `/app/page/Service`,
    //    icon: MapIcon,
    //    key: "Service",
    //    propss: {
    //        Category: "MapView",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "MapPage",
    //                Category: "MapView",
    //                title: "Map View",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //            }
    //        ]
    //    }
    //},
    {
        label: "Personnel",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel in the company",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person_contractor.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Sub",
                    Activefield1: props.Activefield1,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Sub",
                    Activefield1: props.Activefield1,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }
    },
    {
        label: "Accounts",
        LoadComponenet: "ListItems",
        href: `/app/page/Accounts`,
        icon: ApertureIcon,
        key: "Accounts",
        propssdd: {
            CompanyId: props.ContractorId,
            NowEditing: props.NowEditing,
            UserEditor: props.UserEditor,
            CompanyName: props.ContractorName,
            Category: "Company"
        },

        propss: {
            Category: "Company",
            PopulatePage: [
                //{
                //    LoadComponenet: "ToolbarHeading",
                //    Category: "Company",
                //    title: "Company",
                //    size: 12,
                //    CompanyId: props.BranchId,
                //    NowEditing: props.NowEditing,
                //    UserEditor: props.UserEditor,
                //    CustomerType: props.CustomerType,
                //    CompanyName: props.ContractorName
                //},
                {
                    LoadComponenet: "ListSelectedAccounts",
                    Category: "Company",
                    title: "Company",
                    size: 6,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                }
            ]
        }
    },

    //{
    //    label: "Setup",
    //    LoadComponenet: "SetupPage",
    //    href: `/app/page/Setup`,
    //    icon: SettingsIcon,
    //    key: "Setup",
    //    propss: {
    //        Category: "Setup",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "SetupPage",
    //                Category: "Setup",
    //                title: "Setup",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                ActiveCategory: "AddSMS",
    //                Activefield1: props.CompanyName,
    //                ActiveKey: props.CompanyId,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //                ItemData: props.ItemData
    //            }
    //        ]
    //    }
    //},
    //{
    //    label: "Shift",
    //    LoadComponenet: "ListItems",
    //    href: `/app/page/Shift`,
    //    icon: CalendarIcon,
    //    key: "Shift",
    //    propss: {
    //        Category: "ESC",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "CalendarBox",
    //                ListType: "CalendarBox",
    //                Category: "AddSMS",
    //                title: "ESC",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //                disabled: false,
    //                Heading: `${props.ContractorName} On-Call Shift`
    //            }
    //        ]
    //    }
    //},

    {
        label: "Shift",
        LoadComponenet: "ListItems",  //"ListSetupOptions",
        href: `/app/page/Shift`,
        icon: CalendarIcon,
        key: "Shift",
        HelpBox: {
            ShowHelp: true,
            title: "Assign personnal by shift",
            description: "On-call personnel assigned to different shifts.  Escalation policy to notify additional personnel if on-call personnel do not respond on time",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Shift_intro.mp4",
        },
        propss: {
            Category: "ESC",
            PopulatePage: [
                {
                    LoadComponenet: "CalendarBox",
                    ListType: "CalendarBox",
                    Category: "ESC",
                    title: "ESC",
                    size: 12,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Sub",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    disabled: false,
                    Heading: `${props.CompanyName} On-Call Shift`
                }
            ]
        }
    }


];

export const SubCheckinTab = (props) => [
    {
        label: "Work History",
        LoadComponenet: "CheckInPage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Checkin",
        HelpBox: {
            ShowHelp: true,
            title: "List of Service Calls",
            description: "List of service calls by stages. Service detail page links",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Service_List.mp4",
        },
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "CheckInPage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CompanyName: props.CompanyName
                }
            ]
        }

    },
    //{
    //    label: "Map View",
    //    LoadComponenet: "MapPage",
    //    href: `/app/page/Service`,
    //    icon: MapIcon,
    //    key: "Service",
    //    propss: {
    //        Category: "MapView",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "MapPage",
    //                Category: "MapView",
    //                title: "Map View",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //            }
    //        ]
    //    }
    //},
    {
        label: "Personnel",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel in the company",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person_contractor.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                //{
                //    LoadComponenet: "ToolbarHeading",
                //    Category: "Person",
                //    title: "Person",
                //    size: 12,
                //    CompanyId: props.CompanyId,
                //    NowEditing: props.NowEditing,
                //    UserEditor: props.UserEditor,
                //    ActiveCategory: "Sub-Contractor",
                //    Activefield1: props.Activefield1,
                //    ActiveKey: props.CompanyId,
                //    CustomerType: props.CustomerType,
                //    CompanyName: props.CompanyName,
                //},
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Sub-Contractor",
                    Activefield1: props.Activefield1,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }
    },
    //{
    //    label: "Accounts",
    //    LoadComponenet: "ListItems",
    //    href: `/app/page/Accounts`,
    //    icon: ApertureIcon,
    //    key: "Accounts",
    //    propssdd: {
    //        CompanyId: props.ContractorId,
    //        NowEditing: props.NowEditing,
    //        UserEditor: props.UserEditor,
    //        CompanyName: props.ContractorName,
    //        Category: "Company"
    //    },

    //    propss: {
    //        Category: "Company",
    //        PopulatePage: [
    //            //{
    //            //    LoadComponenet: "ToolbarHeading",
    //            //    Category: "Company",
    //            //    title: "Company",
    //            //    size: 12,
    //            //    CompanyId: props.BranchId,
    //            //    NowEditing: props.NowEditing,
    //            //    UserEditor: props.UserEditor,
    //            //    CustomerType: props.CustomerType,
    //            //    CompanyName: props.ContractorName
    //            //},
    //            {
    //                LoadComponenet: "ListSelectedAccounts",
    //                Category: "Company",
    //                title: "Company",
    //                size: 6,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName
    //            }
    //        ]
    //    }
    //},

    //{
    //    label: "Setup",
    //    LoadComponenet: "SetupPage",
    //    href: `/app/page/Setup`,
    //    icon: SettingsIcon,
    //    key: "Setup",
    //    propss: {
    //        Category: "Setup",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "SetupPage",
    //                Category: "Setup",
    //                title: "Setup",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                ActiveCategory: "AddSMS",
    //                Activefield1: props.CompanyName,
    //                ActiveKey: props.CompanyId,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //                ItemData: props.ItemData
    //            }
    //        ]
    //    }
    //},
    //{
    //    label: "Shift",
    //    LoadComponenet: "ListItems",
    //    href: `/app/page/Shift`,
    //    icon: CalendarIcon,
    //    key: "Shift",
    //    propss: {
    //        Category: "ESC",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "CalendarBox",
    //                ListType: "CalendarBox",
    //                Category: "AddSMS",
    //                title: "ESC",
    //                size: 12,
    //                CompanyId: props.BranchId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.ContractorName,
    //                disabled: false,
    //                Heading: `${props.ContractorName} On-Call Shift`
    //            }
    //        ]
    //    }
    //},

    //{
    //    label: "Shift",
    //    LoadComponenet: "ListItems",  //"ListSetupOptions",
    //    href: `/app/page/Shift`,
    //    icon: CalendarIcon,
    //    key: "Shift",
    //    HelpBox: {
    //        ShowHelp: true,
    //        title: "Assign personnal by shift",
    //        description: "On-call personnel assigned to different shifts.  Escalation policy to notify additional personnel if on-call personnel do not respond on time",
    //        ShowVideo: true,
    //        VideoId: "https://www.service-opportunity.com/video/Shift_intro.mp4",
    //    },
    //    propss: {
    //        Category: "ESC",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "CalendarBox",
    //                ListType: "CalendarBox",
    //                Category: "ESC",
    //                title: "ESC",
    //                size: 12,
    //                CompanyId: props.CompanyId,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                ActiveCategory: "Sub",
    //                Activefield1: props.CompanyName,
    //                ActiveKey: props.CompanyId,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName,
    //                disabled: false,
    //                Heading: `${props.CompanyName} On-Call Shift`
    //            }
    //        ]
    //    }
    //}


];


export const ContractorTabs = (props) => [
    {
        label: "Service",
        LoadComponenet: "SRPage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Service",
        HelpBox: {
            ShowHelp: true,
            title: "List of Service Request",
            description: "Service request received from customers",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Service_List.mp4",
        },
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "SRPage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    {
        label: "Map View",
        LoadComponenet: "MapPage",
        href: `/app/page/Service`,
        icon: MapIcon,
        key: "Service",
        HelpBox: {
            ShowHelp: true,
            title: "Service call by location",
            description: "View service calls based on location. Make sure to select the correct location in each service request to accurately show the service locations by dates",
            ShowVideo: false,
            VideoId: "https://www.service-opportunity.com/video/Person_contractor.mp4",
        },
        propss: {
            Category: "MapView",
            PopulatePage: [
                {
                    LoadComponenet: "MapPage",
                    Category: "MapView",
                    title: "Map View",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    {
        label: "Personnel",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel in the company",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person_contractor.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.BranchId,
                    DetailLink: true,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    {
        label: "Customers",
        LoadComponenet: "ListItems",
        href: `/app/page/Customers`,
        icon: ApertureIcon,
        key: "Customers",

        propssdd: {
            CompanyId: props.ContractorId,
            NowEditing: props.NowEditing,
            UserEditor: props.UserEditor,
            CompanyName: props.ContractorName,
            Category: "Company"
        },

        propss: {
            Category: "Customers",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Customers",
                    title: "Customers",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Company",
                    title: "Company",
                    size: 6,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                }
            ]
        }
    },

    {
        label: "Setup",
        LoadComponenet: "SetupPage",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "AddSMS",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                    ItemData: props.ItemData
                }
            ]
        }
    },
    {
        label: "Shift",
        LoadComponenet: "ListItems",
        href: `/app/page/Shift`,
        icon: CalendarIcon,
        key: "Shift",
        HelpBox: {
            ShowHelp: true,
            title: "Assign personnal by shift",
            description: "On-call personnel assigned to different shifts.  Escalation policy to notify additional personnel if on-call personnel do not respond on time",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Shift_intro.mp4",
        },
        propss: {
            Category: "ESC",
            PopulatePage: [
                {
                    LoadComponenet: "CalendarBox",
                    ListType: "CalendarBox",
                    Category: "AddSMS",
                    title: "ESC",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                    disabled: false,
                    Heading: `${props.ContractorName} On-Call Shift`
                }
            ]
        }
    },

    {
        label: "Sub Contractor",
        LoadComponenet: "ListItems",
        href: `/app/page/Accounts`,
        icon: ApertureIcon,
        key: "Sub",
        HelpBox: {
            ShowHelp: true,
            title: "Sub Contractor Setup",
            description: "The sub-contractors can be setup to be part of notification process. Sub-contrctors will have limited access to the system. See the video for more details",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/SubContractor.mp4",
        },
        propssdd: {
            CompanyId: props.ContractorId,
            NowEditing: props.NowEditing,
            UserEditor: props.UserEditor,
            CompanyName: props.ContractorName,
            Category: "Sub"
        },

        propss: {
            Category: "Sub",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Sub",
                    title: "Sub Contractor",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Sub",
                    title: "Sub",
                    size: 6,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                }
            ]
        }
    },

];

export const SmallContractorTabs = (props) => [
    {
        label: "Service",
        LoadComponenet: "ServicePage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Service",
        HelpBox: {
            ShowHelp: false,
            title: "List of Service Request",
            description: "Service request received from customers",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Service_List.mp4",
        },
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "ServicePage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: "Contractor",
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },

    {
        label: "KPI",
        LoadComponenet: "SRPage",
        href: `/app/page/Service`,
        icon: piechartIcon,
        key: "Service",
        HelpBox: {
            ShowHelp: true,
            title: "Key performance indicators",
            description: "Summary of service calls by stages, calls per equipment, calls by person, etc.",
            ShowVideo: false,
            VideoId: "https://www.youtube.com/watch?v=R4Kp5Ze3eok",
        },
        propss: {
            Category: "Service",
            PopulatePage: [

               
                {
                    LoadComponenet: "SRFrequency",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Person",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: "Contractor",
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "SRFrequencyParts",
                    Category: "Parts",
                    title: "Parts",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    DetailLink: true,
                    ActiveCategory: "Parts",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: "Contractor",
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                //{
                //    LoadComponenet: "Chart1",
                //    Category: "Branch",
                //    title: "Branch",
                //    size: 6,
                //    CompanyId: props.CompanyId,
                //    NowEditing: props.NowEditing,
                //    UserEditor: props.UserEditor,
                //    CustomerType: props.CustomerType,
                //    CompanyName: props.CompanyName,
                //},

            ]
        }

    },
    {
        label: "Map View",
        LoadComponenet: "MapPage",
        href: `/app/page/Service`,
        icon: MapIcon,
        key: "Service",
        propss: {
            Category: "MapView",
            PopulatePage: [
                {
                    LoadComponenet: "MapPage",
                    Category: "MapView",
                    title: "Map View",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                }
            ]
        }
    },
   
    {
        label: "Personnel",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        HelpBox: {
            ShowHelp: true,
            title: "Personnel",
            description: "List of personnel in the company",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Person_contractor.mp4",
        },
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.BranchId,
                    DetailLink: true,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    {
        label: "Customers",
        LoadComponenet: "ListItems",
        href: `/app/page/Customers`,
        icon: ApertureIcon,
        key: "Customers",

        propssdd: {
            CompanyId: props.ContractorId,
            NowEditing: props.NowEditing,
            UserEditor: props.UserEditor,
            CompanyName: props.ContractorName,
            Category: "Company"
        },

        propss: {
            Category: "Customers",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Customers",
                    title: "Customers",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Customers",
                    title: "Customers",
                    size: 6,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                }
            ]
        }
    },

    
    {
        label: "Shift",
        LoadComponenet: "ListItems",
        href: `/app/page/Shift`,
        icon: CalendarIcon,
        key: "Shift",
        HelpBox: {
            ShowHelp: true,
            title: "Assign personnal by shift",
            description: "On-call personnel assigned to different shifts.  Escalation policy to notify additional personnel if on-call personnel do not respond on time",
            ShowVideo: true,
            VideoId: "https://www.service-opportunity.com/video/Shift_intro.mp4",
        },
        propss: {
            Category: "ESC",
            PopulatePage: [
                {
                    LoadComponenet: "CalendarBox",
                    ListType: "CalendarBox",
                    Category: "AddSMS",
                    title: "ESC",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                    disabled: false,
                    Heading: `${props.ContractorName} On-Call Shift`
                }
            ]
        }
    },

    {
        label: "Setup",
        LoadComponenet: "SetupPage",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                    ItemData: props.ItemData
                }
            ]
        }
    },   

];


export const HQFrontPage = (props) => [

    {
        LoadComponenet: "ToolbarHeading",
        Category: "Branch",
        title: "Branch",
        size: 12,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName
    },
    {
        LoadComponenet: "ListItems",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    {
        LoadComponenet: "BranchCharts",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    }
];

export const DeveloperFrontPage = (props) => [

    {
        LoadComponenet: "ToolbarHeading",
        Category: "Owners",
        title: "Owners",
        size: 12,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName
    },
    {
        LoadComponenet: "ListItems",
        Category: "Owners",
        title: "Owners",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    //{
    //    LoadComponenet: "BranchCharts",
    //    Category: "Branch",
    //    title: "Branch",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //}
];

export const ContractorFrontPage = (props) => [
    {
        LoadComponenet: "SRPage",
        Category: "Service",
        title: "Service",
        size: 12,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    }
];

export const OwnerFrontPage = (props) => [
    {
        LoadComponenet: "SRFrequencyAssets",
        Category: "Assets",
        title: "Assets",
        size: 6,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        ActiveCategory: "Company",
        Activefield1: props.CompanyName,
        DetailLink: true,
        ActiveKey: props.CompanyId,
        NowEditing: props.NowEditing,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    {
        LoadComponenet: "SRFrequency",
        Category: "Person",
        title: "Person",
        size: 6,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        DetailLink: true,
        ActiveCategory: "Company",
        Activefield1: props.CompanyName,
        ActiveKey: props.CompanyId,
        NowEditing: props.NowEditing,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    //{
    //    LoadComponenet: "Chart1",
    //    Category: "Branch",
    //    title: "Branch",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},
];

export const SmallOwnerFrontPage = (props) => [
   
    {
        LoadComponenet: "SRFrequency",
        Category: "Person",
        title: "Person",
        size: 6,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        DetailLink: true,
        ActiveCategory: "Company",
        Activefield1: props.CompanyName,
        ActiveKey: props.CompanyId,
        NowEditing: props.NowEditing,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    //{
    //    LoadComponenet: "Chart1",
    //    Category: "Branch",
    //    title: "Branch",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},
];

export const ServiceOwnerFrontPage = (props) => [

    {
        LoadComponenet: "ServicePage",
        Category: "Service",
        title: "Service",
        size: 12,
        DetailLink: true,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        NowEditing: props.NowEditing,
        CustomerType: props.CustomerType,
        ActiveCategory: props.ActiveCategory,
        Activefield1: props.CompanyName,
        ActiveKey: props.CompanyId,
        CompanyName: props.CompanyName,
    },

   
    //{
    //    LoadComponenet: "ServicePage",
    //    Category: "Service",
    //    title: "Service",
    //    size: 12,
    //    DetailLink: true,
    //    CompanyId: props.CompanyId,
    //    UserEditor: props.UserEditor,
    //    NowEditing: "Company",
    //    CustomerType: "Company",
    //    ActiveCategory: "Company",
    //    Activefield1: props.CompanyName,
    //    ActiveKey: props.CompanyId,
    //    CompanyName: props.CompanyName,
    //},
];

export const MultipleAccountFrontPage = (props) => [

    //{
    //    LoadComponenet: "ServicePage",
    //    Category: "Service",
    //    title: "Service",
    //    size: 12,
    //    DetailLink: true,
    //    CompanyId: props.CompanyId,
    //    UserEditor: props.UserEditor,
    //    NowEditing: props.NowEditing,
    //    CustomerType: props.CustomerType,
    //    ActiveCategory: props.ActiveCategory,
    //    Activefield1: props.CompanyName,
    //    ActiveKey: props.CompanyId,
    //    CompanyName: props.CompanyName,
    //},

    {
        LoadComponenet: "ListItems",
        Category: "AccountList",
        title: "Account List",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },

    //{
    //    LoadComponenet: "ServicePage",
    //    Category: "Service",
    //    title: "Service",
    //    size: 12,
    //    DetailLink: true,
    //    CompanyId: props.CompanyId,
    //    UserEditor: props.UserEditor,
    //    NowEditing: "Company",
    //    CustomerType: "Company",
    //    ActiveCategory: "Company",
    //    Activefield1: props.CompanyName,
    //    ActiveKey: props.CompanyId,
    //    CompanyName: props.CompanyName,
    //},
];

export const CheckinSubFrontPage = (props) => [
    {
        LoadComponenet: "CheckInPage",
        Category: "Service",
        title: "Service",
        size: 12,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        NowEditing: "Company",
        CustomerType: "Company",
        ActiveCategory: "Company",
        Activefield1: props.CompanyName,
        ActiveKey: props.CompanyId,
        CompanyName: props.CompanyName,
    },
    //{
    //    LoadComponenet: "Chart1",
    //    Category: "Branch",
    //    title: "Branch",
    //    size: 6,
    //    CompanyId: props.ContractorId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},
    //{
    //    LoadComponenet: "RatingChart",
    //    Category: "Branch",
    //    title: "Branch",
    //    size: 6,
    //    CompanyId: props.ContractorId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},
    //{
    //    LoadComponenet: "SRPage",
    //    Category: "Service",
    //    title: "Service",
    //    size: 12,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //}
];

export const SmallCheckinSubFrontPage = (props) => [
    {
        LoadComponenet: "CheckInPage",
        Category: "Service",
        title: "Service",
        size: 12,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        NowEditing: "Company",
        CustomerType: "Company",
        ActiveCategory: "Company",
        Activefield1: props.CompanyName,
        ActiveKey: props.CompanyId,
        CompanyName: props.CompanyName,
    },
   
];

export const ServiceLanding = (props) => [
    {
        LoadComponenet: "SRShowSingle",
        Category: "Service",
        title: "Service",
        size: 12,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName
    }
];

export const CompanyFrontPage = (props) => [


    {
        LoadComponenet: "SRFrequencyAssets",
        Category: "Assets",
        title: "Assets",
        size: 6,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        ActiveCategory: "Company",
        Activefield1: props.CompanyName,
        DetailLink: true,
        ActiveKey: props.CompanyId,
        NowEditing: props.NowEditing,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    {
        LoadComponenet: "SRFrequency",
        Category: "Person",
        title: "Person",
        size: 6,
        CompanyId: props.CompanyId,
        UserEditor: props.UserEditor,
        DetailLink: true,
        ActiveCategory: "Company",
        Activefield1: props.CompanyName,
        ActiveKey: props.CompanyId,
        NowEditing: props.NowEditing,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    {
        LoadComponenet: "Chart1",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    //{
    //    LoadComponenet: "ShowRating",
    //    Category: "Rating",
    //    title: "Rating",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    UserEditor: props.UserEditor,
    //    ActiveCategory: "Company",
    //    Activefield1: props.CompanyName,
    //    ActiveKey: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},




    //{
    //    LoadComponenet: "Chart1",
    //    Category: "Branch",
    //    title: "Branch",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},


    //{
    //    LoadComponenet: "SRFrequency",
    //    Category: "Equipment",
    //    title: "Equipment",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    UserEditor: props.UserEditor,
    //    ActiveCategory: "Company",
    //    Activefield1: props.CompanyName,
    //    ActiveKey: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},


];

export const SubFrontPage = (props) => [
    //{
    //    LoadComponenet: "ListItems",
    //    Category: "ServiceCalls",
    //    title: "Recent Service Calls",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},
    {
        LoadComponenet: "Chart1",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.ContractorId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    {
        LoadComponenet: "RatingChart",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.ContractorId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    //{
    //    LoadComponenet: "SRPage",
    //    Category: "Service",
    //    title: "Service",
    //    size: 12,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //}
];

