import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
import {
  NewPageId,
  Pk1SingelItem,
  removeEmptyStringElements,
  FindLatestDate
} from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
  TextField,
  Collapse,
  Paper, Grid, Button,
  Backdrop, 
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
import { TotalList, schedule, emergency, todo, quote, SMSQuestions } from '../utils/UrgencyOptions';
import ContactBox from '../components/ContactBox';

//import Page from '../components/Page';

const UUid = NewPageId("Urgency");

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

var dateToday = new Date();
let currentTime = dateToday.toISOString();  //.replace("Z", "");

export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const [loading, SetLoading] = useState(false);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);

  const initialState = {
    TotalStepList: SMSQuestions,
    ContactList: [],
    title: `Contact List of ${UserData.CompanyName} Personal`,
    NewLoadComponent: "CreateContactUs"
  }
  const [state, setState] = useState(initialState);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    //let CompanyId = ContractorData.ContractorId;
    //let CompanyName = ContractorData.ContractorName;
    //let IncludedStepList = SMSQuestions.filter(x => x.include === true);
    //let NotIncludedStepList = SMSQuestions.filter(x => x.include === false);

    //if (props.CompanyId) {
    //  CompanyId = props.CompanyId;
    //  CompanyName = props.CompanyName;
    //}
      
    //  setState(prevState => ({
    //    ...prevState,
    //    TotalStepList: SMSQuestions,
    //    CompanyId: CompanyId,
    //    CompanyName: CompanyName,
    //    IncludedStepList: IncludedStepList,
    //    NotIncludedStepList: NotIncludedStepList,
    //  }));
    console.log("ContactList.props", props);
    console.log("ContactList.state", state);

    if (props.Data & props.Data.LoadComponenet) {
         setState(prevState => ({
        ...prevState,
           NewLoadComponent: props.Data.NewLoadComponent
      }));
    }

    //let GetBack = ["ContactList"];
    GetDatafromDB(props.CompanyId);

  }, [])

  const GetDatafromDB = async (cid) => {
    SetLoading(true);
    
    let NewData2 = await Pk1SingelItem(cid, `Data::Online::Lambda::Contact::${UserData.CompanyId}`);
    let NewData = await NewData2;
    let ContactList2 = JSON.parse(NewData.dataJSON);
    let ContactList = ContactList2.ContactList;
    
    console.log("ContactList.NewData", ContactList);
    setState(prevState => ({ ...prevState, ContactList: ContactList }))

    SetLoading(false);


  }

  //useEffect(() => {
  //  if (props && props.UrgencyJSON) {

  //    let TotalStepList = props.UrgencyJSON;
  //    let IncludedStepList = TotalStepList.filter(x => x.include === true);
  //    let NotIncludedStepList = TotalStepList.filter(x => x.include === false);

  //    setState(prevState => ({
  //      ...prevState,
  //      TotalStepList: TotalStepList,
  //      IncludedStepList: IncludedStepList,
  //      NotIncludedStepList: NotIncludedStepList,
  //    }));

  //  }
  //}, [props.UrgencyJSON])

  //const handleInclude = (add, idd) => {

  //  let TotalStepList = state.TotalStepList;
  //  let itemindex = TotalStepList.findIndex(x => x.key === idd);
  //  console.log("SelectStepBox.add, idd", add, idd);
  //  TotalStepList[itemindex].include = add;
  //  let IncludedStepList = TotalStepList.filter(x => x.include === true);
  //  let NotIncludedStepList = TotalStepList.filter(x => x.include === false);

  //  setState(prevState => ({
  //    ...prevState,
  //    TotalStepList: TotalStepList,
  //    IncludedStepList: IncludedStepList,
  //    NotIncludedStepList: NotIncludedStepList,
  //  }));

  //}

  //console.log("props", props)

  const CloseDrawer = () => {
    //console.log("CloseDrawer", e)
    SetDeawerOpen(false)
  }

  const handleChange = (event, name) => {
    setState({
      ...state,
      [name]: event.target.checked,
    });
  };

  const DeleteItem = (index, Arr) => {
    let arr = state[Arr];
    arr.splice(index, 1);
    setState((prevState) => ({ ...prevState, [Arr]: arr }));
    //setState((prevState) => ({
    //  ...prevState,
    //  RawData: arr,
    //  TotalCount: arr.length,
    //}));
    //DataPerPage(arr);
    //if (props.SelectedDataReturn) {
    //  props.SelectedDataReturn(arr);
    //}
  }

  const MoveUp = (index, Arr) => {
    let arr = state[Arr];
    let indexB = index - 1;
    if (indexB > -1 && indexB < arr.length) {
      let temp = arr[index];
      arr[index] = arr[indexB];
      arr[indexB] = temp;
      console.log("Up arr", arr);
      setState((prevState) => ({ ...prevState, [Arr]: arr }));
    }
    //rest.UpdateFormData(arr);
    //setLocalStorage(arr);
  };

  const MoveDown = (index, Arr) => {
    let arr = state[Arr];
    let indexB = index + 1;
    if (indexB > -1 && indexB < arr.length) {
      let temp = arr[index];
      arr[index] = arr[indexB];
      arr[indexB] = temp;
      console.log("Down arr", arr);
      setState((prevState) => ({ ...prevState, [Arr]: arr }));
    }
    //rest.UpdateFormData(arr);
    //setLocalStorage(arr);
  };

  const SaveData = () => {

    try {

      let posterId = {
        pk1: UserData.pk1,
        sk1: UserData.sk1,
        field1: UserData.filed1,
        field2: UserData.filed2,
        avatar: UserData.avatar,
      }

      let TotalSteps = {
        ServiceSteps: state.TotalStepList,
      }
      
      state.TotalStepList.map(eachstep => {
        if (eachstep.Preset) {
          TotalSteps[eachstep.DataReceivedArrayName] = state[eachstep.DataReceivedArrayName]
        }
      })

      const key = `Data::Online::Lambda::Contact::${UserData.CompanyId}`;
      //var currentUnixTime = new Date();
      //var currentTime = currentUnixTime.toISOString();

      const FormdataJSON = {
        date: currentTime,
        ContactList: state.ContactList,
        field1: `Contact List of ${UserData.CompanyName} Personal`,
        field2: props.CompanyName,
        id: UUid,
        CompanyId: props.CompanyId,
        CompanyName: props.CompanyName,
        ContractorId: ContractorData.ContractorId,
        SearchWords: `Contact List of ${UserData.CompanyName} Personal`,
        posterId: posterId,
        key: key,
        pk1: props.CompanyId,
        sk1: key,
        gsi1Pk2: key,
        gsi1Sk2: currentTime,
        gsi2Pk2: ContractorData.ContractorId,
        gsi2Sk2: key,
      }

      let dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON));

      let DBInput = {
        pk1: props.CompanyId,
        sk1: key,
        gsi1Pk2: key,
        gsi1Sk2: currentTime,
        gsi2Pk2: ContractorData.ContractorId,
        gsi2Sk2: key,
        dataJSON: dataJSON
      }

      console.log("ContactList.DBInput", DBInput);
      console.log("ContactList.FormdataJSON", FormdataJSON);

      API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput })).then(
        (results) => {
          console.log("createBatch Data ", results);
          if (props.CloseDrawer) {
            props.CloseDrawer();
          }

        }
      );

    } catch (err) {
      console.log("SelectStepBox.err", err);
    }


  }

  const AddPerson = () => {

    setState({
      ...state,
      NextLoadComponenet: props.NextLoadComponenet,
    });

    SetDeawerOpen(true)

  };

  const handleInclude = (data) => {

    console.log("ContactList.data", data)

    let ContactList = state.ContactList;
    let findindex = ContactList.findIndex(x => x.key === data.key);

    if (findindex && findindex > -1) {
      ContactList[findindex] = data;
    } else {
      // if key does not exist, then add as new
      ContactList = [...state.ContactList, data];
    }

    console.log("ContactList.handleInclude", ContactList)

    setState(prevState => ({
      ...prevState,
      ContactList: ContactList
    }));
  }

 
  return (
    <Paper className={classes.paper}>

      <Grid container spacing={1}>

        <Grid item xs={12}>
          <Typography variant="h4">
            {state.title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => AddPerson()}
          >
            Add Person
          </Button>
        </Grid>

        
          {state.ContactList && state.ContactList.length > 0 && (
          <>
              
                

                {state.ContactList.map((eachstep, indd) => (
                  <Grid item xs={12} key={"eachstepbox"+indd}>
                    <ContactBox
                            key={"eachstep" + indd}
                            MoveUp={e => MoveUp(indd, "ContactList")}
                            MoveDown={e => MoveDown(indd, "ContactList")}
                            DeleteItem={() => DeleteItem(indd, "ContactList")}
                            CompanyId={props.CompanyId}
                            data={eachstep}
                            ShowButtons={true}
                    //  StepNumber={` (Question ${indd + 1})`}
                   // UpdatedValue={(vv, ind) => console.log("StepBox3", vv, ind)}
                  />
                  </Grid>

                  
                ))}
              

              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => SaveData()}
                >
                  Save List
          </Button>
              </Grid>


           </>

          )}
       

      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DrawerShow
        LoadComponenet={state.NewLoadComponent}
        CloseDrawer={() => CloseDrawer()}
        DrawerTitle="Personnel"
        //LoadComponenet="ItemAddPage"
        propss={{
          Category: "Person",
          CloseDrawer: (e) => CloseDrawer(e),
          //step: state.DetailsProps,
          handleInclude: (e) => handleInclude(e),
          CompanyId: props.CompanyId,
          CompanyName: props.CompanyName,
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </Paper>
  );
}
