import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
//import { styled } from '@mui/material/styles';
import {
  TextField,
  Collapse,
  Paper, Grid,
  Backdrop, 
  CircularProgress, FormGroup, 
  Card,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const initialState = {
    title: "",
    message: "",
    include: false
  }
  const [state, setState] = useState(initialState);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  useEffect(() => {
    if (props && props.step) {

      setState(prevState => ({
        ...prevState,
        label: props.step.label,
        message: props.step.message,
        required: props.step.required,
        include: props.step.include,
      }));

    }
  }, [props.step])

  console.log("props", props)

  const ClosingDrawer = (e) => {
    console.log("ClosingDrawer", e)
    SetDeawerOpen(false)
  }

  const handleChange = (event, name) => {
    let change = event.target.checked;
    setState({
      ...state,
      [name]: event.target.checked,
    });
    props.handleInclude(change, props.step.key);
  };

  const handleRequired = (event, name) => {
    let change = event.target.checked;
    setState({
      ...state,
      [name]: event.target.checked,
    });
   // props.handleInclude(change, props.step.key);
  };

  const SelectedValues = (item, name) => {
    
    setState({
      ...state,
      [name]: item.target.value,
    });
    // props.handleInclude(change, props.step.key);
  };

  /*
  <StepBox
    MoveUp={e => MoveUp(e)}
    MoveDown={e => MoveDown(e)}
    steps={""}
    UpdatedValue={(vv, ind) => UpdatedValue(vv, ind)}
    />

{
        "key": "title",
        "label": "Title",
        "ModuleTitle": "Title",
        "Preset": false,
        "message": "Please enter heading / title",
        "LoadComponenet": "Title",
        "LoadComponenet2": "",
        "LoadComponenet3": "",
        "Trigger1": false,
        "Trigger2": false,
        "Trigger3": false,
        "required": false,
        "DataReceivedArrayName": "title",
        "DataSendArrayName": "",
        "disabled": false,
        "Category": "",
        "VariableInput": "get the service started",
        "ShowHTML": "get the service started",
        "field1": "get the service started",
        "SearchWords": "get the service started",
        "pk1": "",
        "sk1": ""
    },

*/

  //console.log("SelectStepBox.step", props.step);

  const Additem = (DetailComponent, Category, CompanyId, CompanyName) => {
    setState(prevState => ({
      ...prevState,
      DetailComponent: DetailComponent,
      ShowDetails: false,
      DetailsProps: {
        Category: Category,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        CloseDrawer: () => ClosingDrawer()
      }
    }))
    SetDeawerOpen(true);
  }
  
  
  return <>
    <Card className={classes.root}>
      <CardContent>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={state.include}
          onChange={e => handleChange(e, "include")} />}
          label={`${state.label} ${props.StepNumber}`}
        />
        
      </FormGroup>
      <CardActions>
          <IconButton aria-label="settings" onClick={() => handleExpandClick()} size="large">
          <EditIcon />
        </IconButton>
          <IconButton aria-label="settings" onClick={() => props.MoveDown()} size="large">
          <ExpandMoreIcon />
        </IconButton>
          <IconButton aria-label="settings" onClick={() => props.MoveUp()} size="large">
          <ExpandLessIcon />
        </IconButton>
      </CardActions>
        </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

          <Grid container spacing={2}>

            <Grid item xs={12}>
              <TextField
                required={true}
                label="Edit Question"
                name="label"
                value={state.label || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => SelectedValues(e, "label")}
              />
            </Grid>
          
            {/*    
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom component="div">
                Notification Based on KeyWords: 
          </Typography>
              <Typography component="div">
                Seperate each keyword with a comma
          </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Keywords 1"
                name="Keywords"
                value={state.keywords1 || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => SelectedValues(e, "keywords1")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Keywords 1"
                name="Keywords"
                value={state.keywords1 || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => SelectedValues(e, "keywords1")}
              />
            </Grid>
            <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => AddItem()}
            >
              Add
              </Button>
            </Grid>
          */}

          </Grid>
        </CardContent>
      </Collapse>
    </Card>
    <DrawerShow
      LoadComponenet={props.NextLoadComponenet}
      CloseDrawer={() => ClosingDrawer()}
      DrawerTitle="change this"
      //LoadComponenet="ItemAddPage"
      propss={{
        CloseDrawer: (e) => ClosingDrawer(e),
        ...props
      }}
      DrawerStatus={DeawerOpen}
      // CloseDrawer={(e) => SetDeawerOpen(false)}
      OpenDrawer={(e) => SetDeawerOpen(false)}
      anchor="left"
    />
  </>;
}
