import React from 'react';
//import { makeStyles } from '@mui/material/styles';
//import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import {
  Container,
  Grid, Button,
} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RenderModule from '../utils/RenderModule';

//const useStyles = makeStyles((theme) => ({
//  root: {
//    width: '100%',
//  },
//  heading: {
//    fontSize: theme.typography.pxToRem(15),
//    fontWeight: theme.typography.fontWeightRegular,
//  },
//}));


export default function SimpleAccordion(props) {
 // const classes = useStyles();
  console.log("Accordian.props", props);

  return (
    <div >

      {props.Blocks.map((block, index) => (
        <Accordion TransitionProps={{ unmountOnExit: false }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={index + block.title}
          >
            <Typography >{block.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth={true}>

              <Grid>
                <Typography >{block.message}</Typography>
              </Grid>

              {block.ShowAdd && (
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => block.Additem(block.DetailComponent)}
                  >
                    Add
                </Button>
                </Grid>
              )}

              <Grid>
                <RenderModule LoadComponenet={block.LoadComponenet} {...block.propss} />
              </Grid>

            </Container>

          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
