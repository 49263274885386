import React, { useState, useEffect } from 'react';

//import image from "../../test_image.png";

let NewData = {};
let field1 = "";
let CompanyName = "";
let TimeStamp = "";

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = { checked: false };
    }

    handleCheckboxOnChange = () =>
        this.setState({ checked: !this.state.checked });

    setRef = (ref) => (this.canvasEl = ref);

    render() {
        const { SelectedItems, DataBack, Title, Description } = this.props;

        console.log(`Printpage.this.props`, this.props);

        return (
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @media all { .page-break {display: none; } ; table, tr, td {padding-top: 10px;}}\
                        @media print { html, body { height: initial !important; overflow: initial !important; -webkit-print-color-adjust: exact; }}\
                        @media print { .page-break {margin-top: 1rem; display: block; page-break-before: auto;}}\
                        @page {size: auto; margin: 20mm;}\
                        @media print {table, tr, td {padding-top: 10px;}}\
                        @media print {.imaggg {border-style: outset;}}\
                    "
                    }
                </style>
                <div className="flash" />
                <table className="testClass" >
                    <thead>
                        <h1>{Title}</h1>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3>{Description}</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                &nbsp;
                            </td>
                        </tr>

                    </tbody>
                </table>
                {SelectedItems.map(each => {
                    NewData = DataBack.find(x => x.sk1 === each)

                    if (NewData.posterId && NewData.posterId.field1 !== "") {
                        field1 = NewData.posterId.field1;
                    } else {
                        field1 = "";
                    }

                    if (NewData.posterId && NewData.posterId.CompanyName !== "") {
                        CompanyName = NewData.posterId.CompanyName;
                    } else {
                        CompanyName = "";
                    }
                                       
                    console.log("print.NewData", NewData)
                    if (NewData && NewData.EventData && NewData.EventData.length > 0) {
                        return (
                            <>

                                <table className="testClass" key={each}>
                                    <thead>
                                        <h3>{NewData.title}</h3>
                                    </thead>
                                    <thead>
                                        &nbsp;
                                    </thead>
                                    <tbody>
                                        {NewData.EventData.map((eachbody, ind) => (
                                            <>
                                                {/* 
                                                <tr key={each + ind + 14}>
                                                    
                                                                <td key={each + ind + 31}>
                                                                    {eachbody.TimeStamp && new Date(eachbody.TimeStamp).toLocaleString()}
                                                                </td>
                                                                
                                                </tr>
                                                
                                                <tr key={each + ind + 1}>
                                                    <td>
                                                        <table width="100%" key={each + ind + 21}>
                                                            <tr>
                                                                <td width="50%" key={each + ind + 31}>{(eachbody.SenderName === "Question") ? "Question:" : (eachbody.TimeStamp && new Date(eachbody.TimeStamp).toLocaleString())}</td>
                                                                <td width="50%" key={each + ind + 41}>{eachbody.Body}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr> 
                                                */}

                                                <tr key={each + ind + 1}>
                                                    <td>
                                                        {(eachbody.SenderName === "Question") ?
                                                            (<h3>Question:</h3>)
                                                            : (
                                                                eachbody.TimeStamp && `${field1}, ${CompanyName}: (${new Date(eachbody.TimeStamp).toLocaleString()})`
                                                            )}                                                        
                                                        
                                                        <p>{eachbody.Body}</p>
                                                            
                                                    </td>
                                                </tr> 

                                                {eachbody.PhotoService &&
                                                    eachbody.PhotoService.length > 0 ? (
                                                    <tr key={each + ind + 3}>
                                                        <td>
                                                            {eachbody.PhotoService.map((eachpic, picind) => (
                                                                <table key={each + ind + 3 + picind} width="500px">
                                                                    <tr>
                                                                        <td>
                                                                            <img className="imaggg" width="500px" src={eachpic.ImageFullPath} alt="img" />
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {eachbody.SenderName === "Question" ? null : (
                                                <tr>
                                                    <td>
                                                        <hr />
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="page-break" />

                            </>
                        )
                    }
                })

                }


            </div>
        );
    }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
    // eslint-disable-line max-len
    console.log("PrintReport.props2", props)

    const [SelectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        setSelectedItems(props.SelectedItems)
    }, [props.SelectedItems]);

    console.log("PrintReport.SelectedItems", SelectedItems)

    return SelectedItems && <ComponentToPrint
        ref={ref}
        Title={props.Title}
        Description={props.Description}
        SelectedItems={SelectedItems}
        DataBack={props.DataBack} />;


});
