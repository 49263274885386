import React from "react";
import ReactPlayer from 'react-player'

const YoutubeEmbed = ({ embedId }) => {

    //https://www.youtube.com/playlist?list=PLoTt7uES3154kpdcGvYny8dsj1EAhe2Jo

    //https://www.youtube.com/embed/${embedId}

    return (
        <div className="video-responsive">
            {/* 
            <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/playlist?list=PLoTt7uES3154kpdcGvYny8dsj1EAhe2Jo`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />

            service opportunity playlist
            https://www.youtube.com/playlist?list=PLoTt7uES3154kpdcGvYny8dsj1EAhe2Jo

            contractor check in list
            https://www.youtube.com/playlist?list=PLoTt7uES3155qw9Gw7y-ticjr0C-6ZUri

            */}

            <ReactPlayer
                url={`https://www.youtube.com/playlist?list=PLoTt7uES3155qw9Gw7y-ticjr0C-6ZUri`}
                controls={true}
                stopOnUnmount={true}
                width={'100%'}
                height={600}
              //  playing={Videoopen}
                config={{
                    youtube: {
                        playerVars: { showinfo: 1 }
                    }
                }}
            />

        </div>
    )
}    ;

//YoutubeEmbed.propTypes = {
//    embedId: PropTypes.string.isRequired
//};

export default YoutubeEmbed;