import React, { useState, useEffect } from "react";
//import 'date-fns';
//import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
//import clsx from 'clsx';
import {
    TextField, Backdrop, Box,
    CircularProgress,
    Grid,
    Card,
    CardContent,
    InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
    RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    Switch, Tooltip, Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from 'react-phone-input-2';
//import 'react-phone-input-2/lib/style.css'
//import 'react-phone-input-2/lib/material.css'
import 'react-phone-input-2/lib/bootstrap.css'
//import Quill from '../components/quill'
import 'react-phone-input-2/lib/bootstrap.css'
import GoogleAddressAutoComplete from '../views/modules/GoogleMapPlace';
import GoogleGetGPS from '../views/modules/GoogleGetGPS';
import UploadMain from '../Upload/UploadMain'
import { getAutoCompletedata } from '../utils/CommonGraphql';
import { Hours, Dayss, WeekOfMonth, MonthsOfYear } from '../utils/DateTime';
import Priviliges from '../Setup/Priviliges';
import AllowSMS from '../Setup/AllowSMS';
import TimeZone from '../components/TimeZone';
import CountrySelect from '../utils/CountrySelect';
//import GoogleGetGPS from '../views/modules/GoogleGetGPS';


var validator = require("email-validator");

//var store = require('store');

/*
 * todo - add upload, mobile, and autocomplete
 * 
 */

//const useStyles = makeStyles((theme) => ({
//    root: {
//        width: '100%',
//    },
//    heading: {
//        fontSize: theme.typography.pxToRem(15),
//        fontWeight: theme.typography.fontWeightRegular,
//    },
//    field: {
//        margin: '10px 0',
//    },
//    countryList: {
//        ...theme.typography.body1,
//    },
//    backdrop: {
//        zIndex: theme.zIndex.drawer + 1,
//        color: '#fff',
//    },
//}));

const Blocks = [
    {
        title: "Block 1",
        message: "this is the message for this module",
        LoadComponenet: "LoginView",
        propss: {
            pk: "123",
            sk: "9349"
        }
    },
    {
        title: "Block 2",
        message: "this is the message for this module 2",
        LoadComponenet: "ListCompanies",
        propss: {
            pk: "123",
            sk: "9349"
        }
    },
];

const FormJSON = [
    {
        Order: 1,
        options: [],
        label: "Name",
        VariableName: "name",
        VariableInput: "",
        message: "",
        type: "textline",
        key: "jvUSKJYef",
        required: true,
    },
    {
        Order: 100,
        options: [],
        label: "Password",
        VariableName: "password",
        VariableInput: "",
        message: "",
        type: "password",
        key: "jvUSKJYef",
        required: true,
    },
    {
        Order: 1,
        options: [],
        label: "Email",
        VariableName: "email",
        VariableInput: "",
        message: "",
        type: "email",
        key: "jvUSKJYef",
        required: true,
    },
    {
        Order: 30,
        options: [],
        label: "Address",
        VariableName: "address",
        VariableInput: "",
        defaultValue: "",
        message: "",
        type: "GoogleAddressAutoComplete",
        key: "jvUSwlsksKJYef",
        required: true,
    },
    {

        Order: 2,
        options: [],
        label: "switch",
        VariableName: "name",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "switch",
        key: "jvUSKJYef",
        required: true

    },
    {

        Order: 9,
        options: [],
        label: "check box",
        VariableName: "checkbox",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "checkbox",
        key: "jvUSKJYessf",
        required: true

    },
    {

        Order: 10,
        options: [],
        label: "date picker",
        VariableName: "date picker",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "datepicker",
        key: "jvUSKJYwwsessf",
        required: true,
        message: "what is this"

    }, {

        Order: 10,
        options: [],
        label: "time picker",
        VariableName: "time picker",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "timepicker",
        key: "jvUSKJYwwsessf",
        required: true,
        message: "what is this"

    },
    {
        Order: 3,
        options: [],
        label: "Mobile Phone",
        VariableName: "Mobile",
        VariableInput: "+15627601614",
        message: "tom be top",
        type: "phone",
        key: "iho7AcjlhjgfaW",
        required: true
    },

    {
        Order: 7,
        VariableName: "payment",
        VariableInput: "",
        message: "",
        type: "select",
        key: "iho7AcjaW33",
        required: false,
        label: "Payment Method",
        DataSourse: "options",
        defaultValue: ["NewPO"],
        options: [
            { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
            { key: "NewPO", label: "New PO", value: "NewPO" },
            { key: "warranty", label: "Warranty", value: "warranty" },
            { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
        ]
    },
    {
        Order: 20,
        VariableName: "Radio",
        VariableInput: "",
        message: "",
        type: "radio",
        key: "iho7AcjeesaWfds33",
        required: false,
        label: "Radio Method",
        DataSourse: "options",
        defaultValue: ["NewPO"],
        options: [
            { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
            { key: "NewPO", label: "New PO", value: "NewPO" },
            { key: "warranty", label: "Warranty", value: "warranty" },
            { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
        ]
    }
];

const DBdata = [
    { key: "existingservice", label: "DN Service", value: "existingservice" },
    { key: "NewPO", label: "DD PO", value: "NewPO" },
    { key: "warranty", label: "DDD Warranty", value: "warranty" },
    { key: "blanketPO", label: "KLL Existing Blanket PO", value: "blanketPO" }
]

//const GetData = {
//  pk: ReduxState.Contractor.ContractorId,
//  sk: "Company::",
//  DbIndex: "gsi2"
//};

//const UseDB = true;

//const useStyles = makeStyles(({

//  PhoneInput: {
//    /* This is done to stretch the contents of this component. */
//    height: 20
//  },
//  PhoneInputInput: {
//    height: 20
//}


//}));




const EditFields = (props) => {
    return (
        <Grid
            container
            direction="row"
            alignItems="center"
        >
            {props.Editable && (
                <>
                    <Grid item xs={1}>
                        <Tooltip title="Edit" aria-label="Edit">
                            <EditIcon fontSize="small" onClick={() => props.EditItem(props.Indexx)} />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Delete" aria-label="Delete">
                            <DeleteForeverIcon fontSize="small" onClick={() => props.DeleteItem(props.Indexx)} />
                        </Tooltip>
                    </Grid>
                </>
            )}
            {props.Indexx !== 0 && (
                <Grid item xs={1}>
                    <Tooltip title="Move Up" aria-label="Move Up">
                        <ExpandLessIcon fontSize="small" onClick={() => props.MoveUp(props.Indexx)} />
                    </Tooltip>
                </Grid>
            )}
            <Grid item xs={1}>
                {props.TotalLength !== (props.Indexx + 1) && (
                    <Tooltip title="Move Down" aria-label="Move Down">
                        <ExpandMoreIcon fontSize="small" onClick={() => props.MoveDown(props.Indexx)} />
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    )
}


//const FormShow = ({ className, ...rest }) => {
const FormShow = (props) => {
   // const classes = useStyles();
    const ContractorData = useSelector(state => state.ContractorData);
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
   // console.log("FormShow props", props)
    let initialState = {
        FormJSON: [],
        disabled: true,
        selectedDate: "",
        showeditbutton: true,
        Category: "",
        PhotoService: [],
        OptionsListArray: [],
        Documents: [],
        Location: [],
        //VerifiedComplete: false
    };
    //const [VerifiedComplete, SetVerifiedComplete] = useState(false);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("FormShow.props", props)

        let Category = "";
        if (props.Category) {
            Category = props.Category;
        }

        setState(prevState => ({
            ...prevState,
            disabled: props.DisableForm,
            showeditbutton: props.ShowEditButton,
            FormJSON: props.FormData,
            Category: Category,
            UrgencySelected: props.UrgencySelected,
            TradeSelected: props.TradeSelected
        }));

        if (props.FormData && props.FormData.length > 0) {
            props.FormData.map(Item => {
                if (Item.type === "select") {
                    if (Item.DataSourse !== "options") {
                        GetOptions(Item.DataSourse);
                    }
                }
            })
        }



    }, []);

    useEffect(() => {

        let NewFormJSON = state.FormJSON;
        if (state.PhotoService.length > 0 || state.Documents.length > 0) {
            props.onSubmit({
                key: props.id,
                FormName: props.title,
                FormType: props.FormType,
                Category: state.Category,
                PhotoService: state.PhotoService,
                Location: state.Location,
                Documents: state.Documents,
                FormData: NewFormJSON,
            });
        }

    }, [state.PhotoService, state.Documents]);

    //useEffect(() => {
    //    console.log("props.UrgencySelected", props.UrgencySelected);
    //    //setState(prevState => ({
    //    //    ...prevState,
    //    //    UrgencySelected: props.UrgencySelected,
    //    //    TradeSelected: props.TradeSelected
    //    //}));
    //    setState(initialState);

    //}, [props.id]);


    //const GetDatafromDB = async (d) => {
    //  let NewData = await getAutoCompletedata(GetData);
    //  setState(prevState => ({ ...prevState, ...NewData }))
    //  console.log("DB data", NewData);
    //}

    const DisableItems = () => {
        setState(prevState => ({ ...prevState, disabled: !state.disabled }));
    }

    const SetAddressValues = (VariableInput, name, key, label, Order, IndexOf) => {
        try {
            if (VariableInput && VariableInput.description !== "") {
                let NewFormJSON = state.FormJSON;
                NewFormJSON[IndexOf].VariableInput = VariableInput.address;
                NewFormJSON[IndexOf].defaultValue = VariableInput.address;
                NewFormJSON[IndexOf].Details = VariableInput;
                NewFormJSON[IndexOf].ShowValue = VariableInput.address;
                setState(prevState => ({
                    ...prevState,
                    FormJSON: NewFormJSON,
                    Location: VariableInput
                }));
                props.onSubmit({
                    key: props.id,
                    FormName: props.title,
                    FormType: props.FormType,
                    Category: state.Category,
                    PhotoService: state.PhotoService,
                    Location: VariableInput,
                    Documents: state.Documents,
                    FormData: NewFormJSON,
                });

                console.log("NewFormJSON", NewFormJSON[IndexOf]);
            }
        } catch (err) { console.log("SetAddressValues", err) }
    }

    const SetMapit = (VariableInput, name, key, label, Order, IndexOf) => {
        try {
            if (VariableInput && VariableInput.gpslink !== "") {
                let Maplink = `<a href="${VariableInput.gpslink}" target="_blank">Map it</a>`;
                let NewFormJSON = state.FormJSON;
                NewFormJSON[IndexOf].VariableInput = VariableInput.gpslink;
                NewFormJSON[IndexOf].defaultValue = VariableInput.Location;
                NewFormJSON[IndexOf].Details = VariableInput;
                NewFormJSON[IndexOf].ShowValue = Maplink;
                setState(prevState => ({
                    ...prevState,
                    FormJSON: NewFormJSON,
                    Location: VariableInput
                }));
                props.onSubmit({
                    key: props.id,
                    FormName: props.title,
                    FormType: props.FormType,
                    Category: state.Category,
                    PhotoService: state.PhotoService,
                    Location: VariableInput.Location,
                    Documents: state.Documents,
                    FormData: NewFormJSON,
                });

                console.log("NewFormJSON", NewFormJSON[IndexOf]);
            }
        } catch (err) { console.log("SetAddressValues", err) }
    }

    const SetTimeZone = (VariableInput, name, key, label, Order, IndexOf) => {
        try {
            if (VariableInput && VariableInput.value !== "") {
                let NewFormJSON = state.FormJSON;
                NewFormJSON[IndexOf].VariableInput = VariableInput.value;
                NewFormJSON[IndexOf].defaultValue = VariableInput.value;
                NewFormJSON[IndexOf].Details = VariableInput;
                NewFormJSON[IndexOf].ShowValue = VariableInput.value;
                setState(prevState => ({ ...prevState, FormJSON: NewFormJSON }));
                props.onSubmit({
                    key: props.id,
                    FormName: props.title,
                    FormType: props.FormType,
                    Category: state.Category,
                    PhotoService: state.PhotoService,
                   // Location: VariableInput,
                    Documents: state.Documents,
                    FormData: NewFormJSON,
                });

                console.log("NewFormJSON", NewFormJSON[IndexOf]);
            }
        } catch (err) { console.log("SetAddressValues", err) }
    }

    const SetCountry = (VariableInput, name, key, label, Order, IndexOf) => {
        try {
            if (VariableInput && VariableInput.value !== "") {
                let NewFormJSON = state.FormJSON;
                NewFormJSON[IndexOf].VariableInput = VariableInput.code;
                NewFormJSON[IndexOf].defaultValue = VariableInput.code;
                NewFormJSON[IndexOf].Details = VariableInput;
                NewFormJSON[IndexOf].ShowValue = VariableInput.code;
                setState(prevState => ({ ...prevState, FormJSON: NewFormJSON }));
                props.onSubmit({
                    key: props.id,
                    FormName: props.title,
                    FormType: props.FormType,
                    Category: state.Category,
                    PhotoService: state.PhotoService,
                    // Location: VariableInput,
                    Documents: state.Documents,
                    FormData: NewFormJSON,
                });

               // console.log("NewFormJSON", NewFormJSON[IndexOf]);
            }
        } catch (err) { console.log("SetAddressValues", err) }
    }

    const SetLabel = (VariableInput, name, key, label, Order, IndexOf) => {
        try {
            if (VariableInput && VariableInput.value !== "") {
                let NewFormJSON = state.FormJSON;
                NewFormJSON[IndexOf].VariableInput = VariableInput.value;
                NewFormJSON[IndexOf].defaultValue = VariableInput.value;
                NewFormJSON[IndexOf].Details = VariableInput;
                NewFormJSON[IndexOf].ShowValue = VariableInput.label;
                setState(prevState => ({ ...prevState, FormJSON: NewFormJSON }));
                props.onSubmit({
                    key: props.id,
                    FormName: props.title,
                    FormType: props.FormType,
                    Category: state.Category,
                    PhotoService: state.PhotoService,
                  //  Location: VariableInput,
                    Documents: state.Documents,
                    FormData: NewFormJSON,
                });

                console.log("NewFormJSON", NewFormJSON[IndexOf]);
            }
        } catch (err) { console.log("SetAddressValues", err) }
    }

    const SetDateValues = (VariableInput, name, key, label, Order, IndexOf) => {
        try {

            let Datess = VariableInput.split("-");
            let NewDate = `${Datess[1]}-${Datess[2]}-${Datess[0]}`;
            console.log("Onchange Datess", Datess, NewDate)
            if (VariableInput) {
                let NewFormJSON = state.FormJSON;
                NewFormJSON[IndexOf].VariableInput = NewDate;
                NewFormJSON[IndexOf].defaultValue = VariableInput;
                NewFormJSON[IndexOf].ShowValue = NewDate;
                setState(prevState => ({ ...prevState, FormJSON: NewFormJSON }));

                props.onSubmit({
                    key: props.id,
                    FormName: props.title,
                    FormType: props.FormType,
                    Category: state.Category,
                    PhotoService: state.PhotoService,
                    Documents: state.Documents,
                    FormData: NewFormJSON
                });
                console.log("NewFormJSON", NewFormJSON[IndexOf]);
            }
        } catch (err) { console.log("SetAddressValues", err) }
    }

    const SetValues = (VariableInput, name, key, label, Order, IndexOf) => {
        try {
            //console.log("Onchange form", VariableInput, name, key, label, Order)
            let NewFormJSON = state.FormJSON;
            NewFormJSON[IndexOf].VariableInput = VariableInput;
            NewFormJSON[IndexOf].defaultValue = VariableInput;
            NewFormJSON[IndexOf].ShowValue = VariableInput;
            //NewFormJSON[IndexOf].SelectedId = VariableInput;
            //console.log("NewFormJSON[IndexOf]", NewFormJSON[IndexOf])
            setState(prevState => ({ ...prevState, FormJSON: NewFormJSON }));
            props.onSubmit({
                key: props.id,
                FormName: props.title,
                FormType: props.FormType,
                Category: state.Category,
                PhotoService: state.PhotoService,
                Documents: state.Documents,
                FormData: NewFormJSON
            });
            //console.log("NewFormJSON", NewFormJSON);
        } catch (err) { console.log("SetValues", err) }
    }

    const SetValuesEmail = (VariableInput, name, key, label, Order, IndexOf) => {
        try {
            //console.log("Onchange form", VariableInput, name, key, label, Order)
            let NewFormJSON = state.FormJSON;
            NewFormJSON[IndexOf].VariableInput = VariableInput.toLowerCase();
            NewFormJSON[IndexOf].defaultValue = VariableInput.toLowerCase();
            NewFormJSON[IndexOf].ShowValue = VariableInput.toLowerCase();
            //NewFormJSON[IndexOf].SelectedId = VariableInput;
            //console.log("NewFormJSON[IndexOf]", NewFormJSON[IndexOf])
            setState(prevState => ({ ...prevState, FormJSON: NewFormJSON }));
            props.onSubmit({
                key: props.id,
                FormName: props.title,
                FormType: props.FormType,
                Category: state.Category,
                PhotoService: state.PhotoService,
                Documents: state.Documents,
                FormData: NewFormJSON
            });
            //console.log("NewFormJSON", NewFormJSON);
        } catch (err) { console.log("SetValues", err) }
    }

    const OptionSelected = (VariableInput, name, key, label, Order, IndexOf, TotalOptions) => {
        try {

            // let SelectedObject = {};
            // //if (TotalOptions && TotalOptions.length > 0) {
            //   SelectedObject = state.OptionsListArray.find(x => x.key === VariableInput);
            //// }

           // console.log("FormShow.OptionSelected", VariableInput, name, key, label, Order)

            let VariableInputSplit = VariableInput.split("||");

            let NewFormJSON = state.FormJSON;
            NewFormJSON[IndexOf].VariableInput = VariableInputSplit[1];
            NewFormJSON[IndexOf].defaultValue = VariableInput;
            NewFormJSON[IndexOf].SelectedObject = VariableInput;
            NewFormJSON[IndexOf].key = VariableInputSplit[0];
            NewFormJSON[IndexOf].ShowValue = VariableInputSplit[1];
            //console.log("NewFormJSON[IndexOf]", NewFormJSON[IndexOf])
            setState(prevState => ({ ...prevState, FormJSON: NewFormJSON }));
            props.onSubmit({
                key: props.id,
                FormName: props.title,
                FormType: props.FormType,
                Category: state.Category,
                PhotoService: state.PhotoService,
                Documents: state.Documents,
                FormData: NewFormJSON
            });
            //console.log("NewFormJSON", NewFormJSON);
        } catch (err) { console.log("SetValues", err) }
    }

    const GetDatafromDB = async (Category, GetBack, cid) => {
        //SetLoading(true);
        let GetBackArray = [];
        let DataBack = []
        GetBack.map(each => {
            GetBackArray.push({
                ArrayName: each,
                ArraySearchString: `::${each}`
            })
        })
        const DataString = {
            Category: Category,
            FindArray: GetBackArray
        };

        let NewData = await getAutoCompletedata(DataString, cid);
        let NewCatValue = NewData[Category]
        console.log("GetDatafromDB", NewCatValue);
        //if (NewData && NewData.length > 0) {
        //  NewData.map(each => {
        //    DataBack.push({
        //      key: each.key,
        //      value: each.key,
        //      label: each.field1,
        //      field1: each.field1,
        //      pk1: each.pk1,
        //    })
        //  })
        //}

        //SetLoading(false);
        //   console.log("DataBack", DataBack);
        return NewCatValue;
    }

    //const GetDatafromLocalStorage = (SelectedItem) => {
    //    //SetLoading(true);


    //    let DataBack = [];
    //    let StoredItem = "";
    //    // let SelectedItem = [];
    //    //let NewServiceData = "";
    //    //let NewServiceNumber = "";
    //    //NewServiceNumber = store.get('NEW_SVC_POLICY::' + ContractorData.ContractorId);
    //    //NewServiceData = store.get(NewServiceNumber);
    //    //SelectedItem = NewServiceData[Category];

    //    console.log("SelectedItem", SelectedItem);

    //    if (SelectedItem && SelectedItem.length > 0) {
    //        SelectedItem.forEach(each => {
    //            StoredItem = store.get(each);
    //            console.log("StoredItem", StoredItem);
    //            DataBack.push({
    //                key: StoredItem.key,
    //                value: StoredItem.key,
    //                label: StoredItem.field1,
    //                field1: StoredItem.field1,
    //                pk1: StoredItem.pk1,
    //            })
    //        })
    //    }
    //    console.log("DataBack", DataBack);
    //    //SetLoading(false);
    //    return DataBack;
    //}


    const GetOptions = async (options) => {
        //return DBdata;
        let DataOptions = [];
        switch (options) {
            case "ContractorPersonal":
                DataOptions = await GetDatafromDB(options, ["Personal"], ContractorData.ContractorId);
                break;
            case "CompanyPersonal":
                DataOptions = await GetDatafromDB(options, ["Personal"], cid.CompanyId);
                break;
            case "Equipment":
                DataOptions = await GetDatafromDB(options, ["Equipment"], cid.CompanyId);
                break;
            case "CompanyLocations":
                DataOptions = await GetDatafromDB(options, ["Locations"], cid.CompanyId);
                break;
            case "Area":
                DataOptions = await GetDatafromDB(options, ["Area"], ContractorData.ContractorId);
                break;
            case "UrgencySelected":
                DataOptions = state.UrgencySelected;
                break;
            case "TradeSelected":
                DataOptions = state.TradeSelected;
                break;
            default:
                DataOptions = []
                break;
        }



        //getting the select options
        setState((prevState) => ({ ...prevState, [options]: DataOptions }));

        //let NewData = await DataOptions;
        // console.log("DataOptions", DataOptions);
        // return DataOptions;

    }

    const EditItem = (e) => {
        console.log("EditItem", e)
        props.EditItem(e)
    }

    const DeleteItem = (index) => {
        console.log("DeleteItem", index)
        let arr = state.FormJSON;
        arr.splice(index, 1);
        console.log("Remove", arr);
        setState((prevState) => ({ ...prevState, FormJSON: arr }));
        props.UpdateFormData(arr);
    }

    const MoveUp = (index) => {
        let arr = state.FormJSON;
        let indexB = index - 1;
        let temp = arr[index];
        arr[index] = arr[indexB];
        arr[indexB] = temp;
        console.log("Up arr", arr);
        setState((prevState) => ({ ...prevState, FormJSON: arr }));
        props.UpdateFormData(arr);
        //setLocalStorage(arr);
    };

    const MoveDown = (index) => {
        let arr = state.FormJSON;
        let indexB = index + 1;
        let temp = arr[index];
        arr[index] = arr[indexB];
        arr[indexB] = temp;
        console.log("Down arr", arr);
        setState((prevState) => ({ ...prevState, FormJSON: arr }));
        props.UpdateFormData(arr);
        //setLocalStorage(arr);
    };

    //const UpdateVerifyForm = verify => {
    //  console.log("verify", verify)
    //  props.FormCompleteVerify({ verify: verify, id: props.id })
    //  //SetVerifiedComplete(verify);
    //}

    console.log("FormShow.state.FormJSON", state.FormJSON)
    

    const FormInputFields = () => {
        //console.log("ShowEditButton", state.showeditbutton, props.ShowEditButton)
        try {
            let FormField = "";
            let SelectOptions = "";
            let SelectOptionsRadio = "";
            let ErrorMessage = false;
            let EmailError = false;
            let VerifyFormMessage = true;
            let OptionsListArray = [];
            let OptionArrrr = []
            let TotalLength = state.FormJSON.length;
          //  console.log("state.FormJSON", rest, state.FormJSON)
            let formUI = state.FormJSON.map((Item, index) => {
                FormField = "";
                SelectOptions = "";
                SelectOptionsRadio = "";
                ErrorMessage = false;
                OptionsListArray = [];
                //SaveDetails = { savedetails }
                //VerifyForm = {(e) = VerifyForm(e)}

                //check if Submit button is pressed and all required fields are filled
                if (props.SaveDetails) {
                    if (Item.required) {
                        if (Item.VariableInput === "") {
                            ErrorMessage = true;
                            //VerifyFormMessage = false;
                            //console.log("VerifyFormMessage top", props.SaveDetails, props.id, Item.required, VerifyFormMessage)
                        }
                    }
                }

                if (Item.type === "email") {
                  //  console.log("FormShow.email.validation", ErrorMessage, EmailError)
                    if (!validator.validate(Item.VariableInput)) {
                        console.log("FormShow.Email1", Item.type, Item.VariableInput);
                        EmailError = true;
                    } else {
                        console.log("FormShow.Email2", Item.type, Item.VariableInput);
                        EmailError = false;
                    }
                }

                // get select options
                if (Item.type === "select") {
                    if (Item.DataSourse === "options") {
                        SelectOptions = Item.options.map((o, indd) => (
                            <MenuItem key={indd} value={`${o.value}||${o.label}`}>
                                {o.label}
                            </MenuItem>
                        ));
                    } else {
                        // the GetOptions function can used to get Database data and return as options
                        //OptionsListArray =

                        OptionsListArray = state[Item.DataSourse];
                        //
                        console.log("OptionsListArray", OptionsListArray)
                        if (OptionsListArray && OptionsListArray.length > 0) {
                            // OptionArrrr = [...OptionArrrr, ...OptionsListArray];
                            //
                            SelectOptions = OptionsListArray.map((o, indd) => (
                                <MenuItem key={indd} value={`${o.key}||${o.field1}`}>
                                    {o.field1}
                                </MenuItem>
                            ));
                        }
                        //else {
                        //  return (
                        //    <MenuItem key={1} value={1}>
                        //      {"label"}
                        //    </MenuItem>
                        //  )
                        //}
                    }
                }
                // get select options
                if (Item.type === "radio") {
console.log("FormShow.Item.DataSourse", Item.DataSourse)
                    if (Item.DataSourse === "options") {
                        SelectOptionsRadio = Item.options.map((o, indd) => (
                            <FormControlLabel key={Item.key + indd + indd} id={Item.key + indd} disabled={state.disabled} value={o.value || ""} control={<Radio key={Item.key + indd + indd} />} label={o.label} />
                        ));
                    } else {
                        // the GetOptions function can used to get Database data and return as options
                        SelectOptionsRadio = GetOptions(Item.DataSourse).map((o, indd) => (
                            <FormControlLabel key={Item.key + indd + indd} id={Item.key + indd} disabled={state.disabled} value={o.value || ""} control={<Radio key={Item.key + indd + indd} />} label={o.label} />
                        ));
                    }
                }

                switch (Item.type) {

                    case "textline":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <TextField
                                    required={Item.required}
                                    error={ErrorMessage}
                                    disabled={state.disabled}
                                    id={`textline-${Item.key}-${index}`}
                                    key={`textlinek-${Item.key}-${index}`}
                                    //defaultValue={Item.defaultValue}
                                    label={Item.label}
                                    name={Item.VariableName}
                                    value={Item.VariableInput ? Item.VariableInput : ""}
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => {
                                        SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />

                            </Grid>
                        )
                        break;

                    case "Quill":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item xs={12} >

                                <TextField
                                    id={`textlineq-${Item.key}-${index}`}
                                    key={`ktextline-${Item.key}-${index}`}
                                    required={Item.required}
                                    error={ErrorMessage}
                                    disabled={state.disabled}
                                    autoComplete="off"
                                    //defaultValue={Item.defaultValue}
                                    label={Item.label}
                                    variant="outlined"
                                    name={Item.VariableName}
                                    value={Item.VariableInput || ""}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    onChange={(e) => {
                                        SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />

                                {/*   
                <Quill
                  required={Item.required}
                  disabled={state.disabled}
                  error={ErrorMessage}
                  id={`textline-${Item.key}-${index}`}
                  //defaultValue={Item.defaultValue}
                  label={Item.label}
                  name={Item.VariableName}
                  value={Item.VariableInput || ""}
                  fullWidth
                  variant="outlined"
                  SelectedValues={(e) => {
                    SetValues(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                  }}
                />
                */}

                            </Grid>
                        )
                        break;

                    case "password":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <TextField
                                    required={Item.required}
                                    disabled={state.disabled}
                                    error={ErrorMessage}
                                    id={`textline-${Item.key}-${index}`}
                                    key={`password-${Item.key}-${index}`}
                                    defaultValue={Item.defaultValue}
                                    type={Item.type}
                                    label={Item.label}
                                    name={Item.VariableName}
                                    value={Item.VariableInput || ""}
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => {
                                        SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />
                                {Item.message !== "" ? <Typography>{Item.message}</Typography> : null}
                            </Grid>
                        )
                        break;

                    case "email":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <TextField
                                    required={Item.required}
                                    disabled={state.disabled}
                                    error={ErrorMessage || EmailError}
                                    id={`textline-${Item.key}-${index}`}
                                    key={`textlinekey-${Item.key}-${index}`}
                                    //defaultValue={Item.defaultValue}
                                    type={Item.type}
                                    label={Item.label}
                                    name={Item.VariableName}
                                    value={Item.VariableInput || ""}
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => {
                                        SetValuesEmail(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />
                                {Item.message !== "" ? <Typography>{Item.message}</Typography> : null}
                            </Grid>
                        )
                        break;

                    case "textbox":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <TextField
                                    id={`textline-${Item.key}-${index}`}
                                    key={`textliddnekey-${Item.key}-${index}`}
                                    required={Item.required}
                                    error={ErrorMessage}
                                    disabled={state.disabled}
                                    autoComplete="off"
                                    //defaultValue={Item.defaultValue}
                                    label={Item.label}
                                    variant="outlined"
                                    name={Item.VariableName}
                                    value={Item.VariableInput || ""}
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    onChange={(e) => {
                                        SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />
                                {Item.message !== "" ? <Typography>{Item.message}</Typography> : null}
                            </Grid>
                        )
                        break;

                    case "message":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <Typography variant="h5" gutterBottom component="span">{Item.label}</Typography>
                                <Typography>{Item.message}</Typography>
                            </Grid>
                        )
                        break;

                    case "switch":

                        FormField = (
                            <Grid key={`grd2-${Item.key}-${index}`} container lg={12} xs={12} alignItems="center" >
                                <Grid key={`grd-${Item.key}-${index}`}
                                    item
                                    xs
                                   // className={classes.outerColumn}
                                    style={{ display: "flex", justifyContent: "flex-start", padding: "6px" }}
                                >
                                    <Typography>{Item.label}</Typography>
                                </Grid>
                                <Grid key={`grd3-${Item.key}-${index}`}
                                    item
                                    //lg={12} xs={12}
                                    //xs
                                   // className={classes.outerColumn}
                                    alignItems="flex-end"
                                    justifyContent="flex-start"
                                >
                                    <Switch
                                        checked={Item.defaultValue}
                                        error={ErrorMessage}
                                        required={Item.required}
                                        value={Item.VariableInput}
                                        disabled={state.disabled}
                                        id={`seitch-${Item.key}-${index}`}
                                        key={`sasda-${Item.key}-${index}`}
                                        onChange={(e) => {
                                            SetValues(e.target.checked, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                        }}
                                        name={Item.VariableName}
                                    />
                                </Grid>
                            </Grid>

                        )
                        break;

                    case "checkbox":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} container lg={12} xs={12} alignItems="center" >
                                <Grid key={`grd2-${Item.key}-${index}`}
                                    item
                                    xs
                                   // className={classes.outerColumn}
                                    style={{ display: "flex", justifyContent: "flex-start", padding: "6px" }}
                                >
                                    <Typography>{Item.label}</Typography>
                                </Grid>
                                <Grid key={`grd3-${Item.key}-${index}`}
                                    item
                                   // className={classes.outerColumn}
                                    alignItems="flex-end"
                                    justifyContent="flex-start"
                                >
                                    <Checkbox
                                        checked={Item.defaultValue || ""}
                                        value={Item.VariableInput || ""}
                                        id={`check-${Item.key}-${index}`}
                                        key={`chkkk-${Item.key}-${index}`}
                                        required={Item.required}
                                        error={ErrorMessage}
                                        disabled={state.disabled}
                                        name={Item.VariableName}
                                        onChange={(e) => {
                                            SetValues(e.target.checked, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                        }}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />

                                </Grid>
                            </Grid>
                        )
                        break;

                    case "select":

                        //construct the drop down
                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <FormControl variant="outlined"
                                   // className={classes.formControl}
                                    style={{ width: '100%' }} >
                                    <InputLabel id="demo-simple-select-outlined-label">{Item.label}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id={`select-${Item.key}-${index}`}
                                        key={`selle-${Item.key}-${index}`}
                                        defaultValue={Item.defaultValue}
                                        error={ErrorMessage}
                                        required={Item.required}
                                        //multiple
                                        disabled={state.disabled}
                                        autoWidth={true}
                                        value={Item.SelectedObject || ""}
                                        onChange={(e) => {
                                            OptionSelected(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index, OptionsListArray);
                                        }}
                                        label={Item.label}
                                    >
                                        {SelectOptions}
                                    </Select>
                                </FormControl >
                                {Item.message !== "" ? <Typography>{Item.message}</Typography> : null}
                            </Grid>
                        )
                        break;

                    case "radio":

                        //construct the drop down
                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <FormControl variant="outlined"
                                   // className={classes.formControl}
                                    style={{ width: '100%' }} >
                                    <FormLabel component="legend">{Item.label}</FormLabel>
                                    <RadioGroup
                                        aria-label={Item.label}
                                        id={`radio-${Item.key}-${index}`}
                                        key={`radd-${Item.key}-${index}`}
                                        name={Item.VariableName}
                                        error={ErrorMessage}
                                        value={Item.VariableInput ? Item.VariableInput : ""}
//                                        {Item.VariableInput || ""}
                                        required={Item.required}
                                        onChange={(e) => {
                                            SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                        }}
                                    >
                                        {SelectOptionsRadio}
                                    </RadioGroup>
                                </FormControl >

                            </Grid>
                        )
                        break;

                    case "datepicker":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <TextField
                                    id={`datwe-${Item.key}-${index}`}
                                    key={`datees-${Item.key}-${index}`}
                                    label={Item.label}
                                    name={Item.VariableName}
                                    required={Item.required}
                                    error={ErrorMessage}
                                    disabled={state.disabled}
                                    //value=""
                                    format="MM/dd/yyyy"
                                    fullWidth
                                    type="date"
                                    //defaultValue={Item.defaultValue}
                                    variant="outlined"
                                   // className={classes.textField}
                                    onChange={(e) => {
                                        SetDateValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        )
                        break;

                    case "timepicker":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                {/*
                <TextField
                  id={`time-${Item.key}-${index}`}
                  label={Item.label}
                  name={Item.VariableName}
                  required={Item.required}
                  disabled={state.disabled}
                  error={ErrorMessage}
                  fullWidth
                  type="time"
                  value={Item.VariableInput}
                  defaultValue={Item.defaultValue}
                  variant="outlined"
                  onChange={(e) => {
                    SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                  }}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                */}

                                <FormControl variant="outlined"
                                   // className={classes.formControl}
                                    style={{ width: '100%' }} >
                                    <InputLabel id="demo-simple-select-outlined-label">{Item.label}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id={`time-${Item.key}-${index}`}
                                        key={`timess-${Item.key}-${index}`}
                                        //id={`select-${Item.key}-${index}`}
                                        //defaultValue={state.StartTime}
                                        //error={ErrorMessage}
                                        //required={Item.required}
                                        disabled={state.disabled}
                                        autoWidth={true}
                                        value={Item.VariableInput || ""}
                                        onChange={(e) => {
                                            SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                        }}
                                        label={Item.label}
                                    >
                                        {Hours.map((o, indd) => (
                                            <MenuItem key={indd} value={o.value}>
                                                {o.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl >

                            </Grid>
                        )
                        break;

                    case "phone":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item xs={12} >
                                {/*  
                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                */}
                                <InputLabel id={`pho-${Item.key}-${index}`}>{Item.label}</InputLabel>
                                <PhoneInput
                                    country={'us'}
                                    key={`phones-${Item.key}-${index}`}
                                    id={`pho-${Item.key}-${index}`}
                                 //   dropdownClass={classes.countryList}
                                    disabled={state.disabled}
                                    label={Item.label}
                                    inputProps={{
                                        name: Item.VariableName,
                                        label: Item.label,
                                        required: Item.required,
                                        style: { width: '100%' },
                                        //value: Item.VariableInput || ""
                                        //error: ErrorMessage
                                    }}
                                    isValid={(value, country) => {
                                        if (value.match(/12345/)) {
                                            return 'Invalid value: ' + value + ', ' + country.name;
                                        } else if (value.match(/1234/)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    value={Item.VariableInput}
                                    onChange={(e) => {
                                        SetValues('+' + e, Item.VariableName, Item.key, Item.label, Item.Order, index
                                        );
                                    }}
                                />
                                {/* 
                </FormControl >
              */}

                            </Grid>

                        )
                        break;

                    case "GoogleAddressAutoComplete":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <InputLabel id={`pho-${Item.key}-${index}`}>{Item.label}</InputLabel>
                                <GoogleAddressAutoComplete
                                    key={`ggl-${Item.key}-${index}`}
                                    defaultValue={Item.defaultValue || ""}
                                    error={ErrorMessage}
                                    autocomplete="off"
                                    disabled={state.disabled}
                                    label={Item.label}
                                    AddressObj={(e) => {
                                        SetAddressValues(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />

                            </Grid>
                        )
                        break;
                        //

                    //case "GoogleGetGPS":

                    //    FormField = (
                    //        <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                    //            <InputLabel id={`pho-${Item.key}-${index}`}>{Item.label}</InputLabel>
                    //            <GoogleGetGPS
                    //                key={`ggl-${Item.key}-${index}`}
                    //                defaultValue={Item.defaultValue || ""}
                    //                error={ErrorMessage}
                    //                autocomplete="off"
                    //                disabled={state.disabled}
                    //                label={Item.label}
                    //                AddressObj={(e) => {
                    //                    SetAddressValues(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                    //                }}
                    //            />

                    //        </Grid>
                    //    )
                    //    break;

                    case "Mapit":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <InputLabel id={`pho-${Item.key}-${index}`}>{Item.label}</InputLabel>
                                <GoogleGetGPS
                                    key={`ggl-${Item.key}-${index}`}
                                    defaultValue={Item.defaultValue || ""}
                                    error={ErrorMessage}
                                    autocomplete="off"
                                    disabled={state.disabled}
                                    label={Item.label}
                                    GPSfound={(e) => {
                                        SetMapit(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />

                            </Grid>
                        )
                        break;

                    case "Priviliges":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >

                                <Priviliges
                                    key={`privvs-${Item.key}-${index}`}
                                    defaultValue={Item.defaultValue}
                                    error={ErrorMessage}
                                    autocomplete="off"
                                    disabled={state.disabled}
                                    label={Item.label}
                                    AddressObj={(e) => {
                                        SetLabel(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />

                            </Grid>
                        )
                        break;

                    case "TimeZone":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <InputLabel id={`pho-${Item.key}-${index}`}>{`${Item.label} *`}</InputLabel>

                                <Box
                                    style={{ zIndex: 'tooltip' }}
                                >

                                <TimeZone
                                    key={`privvs-${Item.key}-${index}`}
                                    defaultValue={Item.defaultValue}
                                    error={ErrorMessage}
                                    autocomplete="off"
                                    disabled={state.disabled}
                                    label={Item.label}
                                    TimeZoneValue={(e) => {
                                        SetTimeZone(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />
                                </Box>
                            </Grid>
                        )
                        break;

                    case "AllowSMS":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >

                                <AllowSMS
                                    key={`smss-${Item.key}-${index}`}
                                    //defaultValue={Item.defaultValue}
                                    error={ErrorMessage}
                                    autocomplete="off"
                                    disabled={state.disabled}
                                    label={Item.label}
                                    AddressObj={(e) => {
                                        SetValues(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />

                            </Grid>
                        )
                        break;

                    case "CountrySelect":

                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >

                                <CountrySelect
                                    key={`smss-${Item.key}-${index}`}
                                    //defaultValue={Item.defaultValue}
                                    error={ErrorMessage}
                                    autocomplete="off"
                                    disabled={state.disabled}
                                    label={Item.label}
                                    AddressObj={(e) => {
                                        SetCountry(e, Item.VariableName, Item.key, Item.label, Item.Order, index);
                                    }}
                                />

                            </Grid>
                        )
                        break;

                    case "upload":
                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item xs={12} >

                                <UploadMain
                                    id={`upl-${Item.key}-${index}`}
                                    key={`upllod-${Item.key}-${index}`}
                                    title="Upload Images, PDF, or Zip"
                                    ImageList={(e) => setState(prevState => ({ ...prevState, PhotoService: e }))}
                                    DocumentList={(e) => setState(prevState => ({ ...prevState, Documents: e }))}
                                    coid={ContractorData.ContractorId}
                                    fileallowed="any"
                                    cat="PhotoNamePlate"
                                />

                            </Grid>
                        )
                        break;

                    default:
                        FormField = (
                            <Grid key={`grd-${Item.key}-${index}`} item lg={12} xs={12} >
                                <TextField
                                    id={`textline-${Item.key}-${index}`}
                                    key={`stsajd-${Item.key}-${index}`}
                                    label={Item.label}
                                    name={Item.VariableName}
                                    required={Item.required}
                                    error={ErrorMessage}
                                    value={Item.VariableInput}
                                    disabled={state.disabled}
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => {
                                        SetValues(e.target.value, Item.VariableName, Item.key, Item.label, Item.Order, index
                                        );
                                    }}
                                />
                                {Item.message !== "" ? <Typography>{Item.message}</Typography> : null}
                            </Grid>
                        )
                }


                if (props.PreviewEdit) {
                    FormField = (
                        <>
                            {FormField}
                            <EditFields
                                Indexx={index}
                                key={`editts-${Item.key}-${index}`}
                                Editable={Item.Editable}
                                TotalLength={TotalLength}
                                EditItem={(e) => EditItem(e)}
                                DeleteItem={(e) => DeleteItem(e)}
                                MoveUp={(e) => MoveUp(e)}
                                MoveDown={(e) => MoveDown(e)}
                            />
                        </>)
                }


                return FormField;

            })

            //if forms required field are filled VerifyFormMessage = true, else false
            //console.log("VerifyFormMessage bottom", VerifyFormMessage, props.id)
            //UpdateVerifyForm(VerifyFormMessage);



            return formUI
        } catch (err) { console.log("FormInputFields", err) }
    }

    return props.PlaneData ? (
        <Grid key={props.id} container spacing={2}>
            {FormInputFields()}
        </Grid>
    ) : (
            <div key={props.id}
            //className={classes.root}
            >
            <Card
                   // className={classes.root}
                    key={"carrdd" + props.id}

            >
                <CardHeader
                    title={props.title}
                    action={
                        state.showeditbutton && <EditIcon fontSize="small" onClick={() => DisableItems()} />
                    }
                />
                <Divider />
                <CardContent>
                        <Grid key={"ggrrdd" + props.id} container spacing={1}>
                        {FormInputFields()}
                    </Grid>
                </CardContent>
            </Card>
                <Backdrop
                    //className={classes.backdrop}
                    open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default FormShow;
