import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    TextField,
    Collapse,
    Paper, Grid, Button,
    Backdrop,
    CircularProgress,
    Card, FormGroup,
    CardContent,
    CardActions,
    InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
    RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    Switch, Tooltip, Divider
} from '@mui/material';
import {
    removeEmptyStringElements,
    NewPageId,
    EleminateDuplicateObjects,
    getAutoCompletedata
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import { ServiceEsc } from '../temp/ESCTeams';
import ToolbarHeading from '../components/ToolbarHeading';

const UUid = NewPageId("Urgency");

const itemsPerPage = 10;

export default function SimpleCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        //title: "",
        TotalProgress: [],
        showcustom: true,
        ShowContractor: false,
        UseCustomESC: "Default",
        ContractorESC: [],
        ContractorESCDefault: [],
        DrawerTitle: "On-Call Rotation Shift Setup",
        CompanyESC: [],
        TotalESC: [],
        ESC: [],
    }
    const [state, setState] = useState(initialState);
    const [noOfPages, SetnoOfPages] = useState(0);

    console.log("SelectCompanies.ESC-contractor.state", state)

    useEffect(() => {

        console.log("SelectCompanies.ESC-contractor.props", props)

        // get contractor ESC
        GetDatafromDB("Company", ContractorData.ContractorId, "DataBack");


    }, [])

    //useEffect(() => {



    //}, [props.defaultAccountsList])

    const handleNext = () => {

        let AccountsList = [];
        let AccountDB = [];
        let defaultAccountsList = [];

        if (state.SelectedFullJsonBack && state.SelectedFullJsonBack.length > 0) {
            
            state.SelectedFullJsonBack.forEach(each => {
                AccountDB.push({
                    sk1: each.sk1,
                    pk1: each.pk1
                })

                defaultAccountsList.push(each.sk1);

                AccountsList.push({
                    sk1: each.sk1,
                    pk1: each.pk1,
                    field1: each.field1,
                    field2: each.field2,
                    Post: `Post::${each.sk1}`
                })
            })
        }

        let SelectedData = {
            AccountDB: AccountDB,
            defaultAccountsList: defaultAccountsList,
            AccountsList: AccountsList,
            Refresh: true
        }

        console.log("SelectCompanies.Selected", SelectedData)

        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedData)
        }

        if (props.CloseDrawer) {
            props.CloseDrawer();
        }

    }

    const GetDatafromDB = async (Category, CompanyId, ArrayName) => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    DuplicateArray: "SecondArray"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId);
        setState(prevState => ({ ...prevState, ...NewData }))

        SetLoading(false);
        console.log("SelectCompanies.NewData", NewData);
    }

    const CommonState = (item) => {

        setState(prevState => ({
            ...prevState,
            [item.itemname]: item.itemvalue
        }));

    }

    const handleChange = (data, name) => {
        setState({
            ...state,
            [name]: data,
        });

    };   

    console.log("SelectCompanies.state", state);

    return (
        <div>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h4" component="span">
                        Select Accounts
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                      //  disabled={rest.disabled}
                        onChange={(e) => handleChange(e.target.value, "SearchWords")}
                        placeholder={`Search Accounts`}
                        variant="outlined"
                    />
                </Grid>
                {state.DataBack && (
                    <Grid item xs={12}>
                        <ShowCheckBoxGroup
                            field1={ContractorData.CompanyName}
                            field2="Account List"
                            disabled={false}
                            AllowDelete={false}
                            DeleteItem={(e) => console.log("DeleteItem", e)}
                            AllowEdit={false}
                            EditItem={(e) => console.log("EditItem", e)}
                            AllowAdd={false}
                            //  AddItem={() => Additem("ESCSetupPage", "ESC", ContractorData.ContractorId, ContractorData.CompanyName)}
                            Data={state.DataBack}
                            ShowUpDown={false}
                            ShowEdit={false}
                            SearchWords={state.SearchWords}
                            AddBox={(e) => console.log("EditItem", e)}
                            DefaultChecked={props.defaultAccountsList || []}
                            //  NextLoadComponenet="ESCSetupPage"
                            //  Category="ESC"
                            SelectedFullJsonBack={(e) => handleChange(e, "SelectedFullJsonBack")}
                            KeyArray={(e) => handleChange(e, "ContractorESCDefault")}
                            PerPage={20}
                            id={"contratoocf"}

                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNext()}
                    //  className={classes.button}
                    >
                        Select
                    </Button>
                </Grid>

            </Grid>

            <Backdrop
                //className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* 
      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={() => SetDeawerOpen(false)}
        DrawerTitle={state.DrawerTitle}
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawer: (e) => CloseDrawer(e),
          ...state.DetailsProps
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
          />
          */}
        </div>
    );
}
