import React, { useState, useEffect } from 'react';
import {
    NewPageId,
    Pk1SingelItem,
    MiddlePostSearch, ContreactorPostSearch
} from '../utils/CommonGraphql';
//import { useSelector } from 'react-redux';
import {
    Grid,
    Backdrop, Box,
    CircularProgress,
    Card, CardHeader, Button, CardContent
} from '@mui/material';
import ListItems from '../List/ListItems';
import ListModule from '../List/ListModule';
import ShowDetailsText from '../Add/ShowDetailsText';
import { adjustedTime, TimeStampEndofDay, formatDate } from '../utils/DateTime';
import DrawerShow from '../utils/DrawerModule';
import NarrowDatesToolBar from '../components/NarrowDatesToolBar';

const UUid = NewPageId("shwdtl");
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = adjustedTime(1, "End");

export default function SimpleCard(props) {

    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    //const [expanded, setExpanded] = React.useState(false);
    //const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    //const UserData = useSelector(state => state.UserData);
    //const cid = useSelector(state => state.cid);

    const initialState = {
        Data: [],
        id: UUid,
        StartDate: olddate,
        EndDate: Todaydatestring,
        ShowEditButton: true,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("ShowDetailsPkSk.props1", props)

        let AssetPkSk = "*";
        let ItemDatask1 = "*";

        if (props.ItemData && props.ItemData.AssetPkSk && props.ItemData.AssetPkSk.pk1) {

            setState(prevState => ({
                ...prevState,
                Category: props.ItemData.Category,
                AssetId: props.ItemData.AssetPkSk.sk1
            }))

            AssetPkSk = props.ItemData.AssetPkSk.sk1;

            if (props.ItemData.AssetPkSk && props.ItemData.AssetPkSk.pk1) {

                GetDatafromDB(props.ItemData.AssetPkSk.pk1, props.ItemData.AssetPkSk.sk1);

                GetDatafromNarrow(props.ItemData.AssetPkSk.sk1, olddate, Todaydatestring);
            }
        } else if (props.ItemData && props.ItemData.pk1 && props.ItemData.sk1) {

            setState(prevState => ({
                ...prevState,
                Category: props.ItemData.Category,
                AssetId: props.ItemData.sk1,
                ServiceData: props.ItemData
            }))

            ItemDatask1 = props.ItemData.sk1;

            GetDatafromNarrow(props.ItemData.sk1, olddate, Todaydatestring);

        }

        console.log("ShowDetailsPkSk.ItemDatask1.AssetPkSk", ItemDatask1, AssetPkSk)

        let LocalStart = formatDate(olddate);
        let LocalEnd = formatDate(Todaydatestring);

        setState(prevState => ({
            ...prevState,
            ShowDates: `${LocalStart} to ${LocalEnd}`
        }));


    }, [props.ItemData])

    useEffect(() => {

        //let ShowEditButton = true;

        //if (props.ShowEditButton) {
        //    ShowEditButton = props.ShowEditButton;
        //}
        if (props.ShowEditButton != null) {
            setState(prevState => ({
                ...prevState,
                ShowEditButton: props.ShowEditButton,
            }));
        }

    }, [props.ShowEditButton])

    useEffect(() => {

        console.log("ShowDetailsPkSk.props2", props)

        if (props.ActiveKey) {

            setState(prevState => ({
                ...prevState,
                Category: props.ActiveCategory,
                AssetId: props.ActiveKey,
            }))

            if (props.CompanyId && props.ActiveKey) {
                GetDatafromDB(props.CompanyId, props.ActiveKey);
            }
        }

    }, [props.ActiveKey])

    const GetDatafromDB = async (pk, sk) => {
        SetLoading(true);

        let NewData2 = await Pk1SingelItem(pk, sk);
        let NewData = await NewData2;
        console.log("ShowDetailsData.NewData", NewData)

        if (NewData) {
            let Data = JSON.parse(NewData.dataJSON);
            console.log("ShowDetailsData.NewData3", Data)
            setState(prevState => ({
                ...prevState,
                Data: Data
            }))
        }

        SetLoading(false);
    }

    const GetDatafromNarrow = async (AssetId, startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let UTCdate = new Date(endDate).toISOString()

        console.log("ShowDetailsData.historydata", AssetId, startDate, endDate);
        //console.log("SRPage.endDate.UTC", UTCdate);

        //if (NowEditing === "Contractor") {
        //    NewData = await ContreactorPostSearch("Post::" + pk1, startDate, UTCdate);
        //} else {
        NewData = await MiddlePostSearch(`Post::${AssetId}`, startDate, UTCdate);
        //}

        // console.log("SRPage.NewData1", NewData);
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                AutoSelectData.push(ShowString);
            })
        }

        setState(prevState => ({
            ...prevState,
            AssetData: AutoSelectData,
            //  FullData: AutoSelectData
        }))

        SetLoading(false);
        console.log("ShowDetailPkSk.NewData2", AutoSelectData);
    }

    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Service History",
            // SetupSubscription: true,
            DetailComponent: "SRDetailsPkSk",
            ServiceData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        SetDeawerOpen(true);
        //}
    }



    //const SortPref = (StartDate, EndDate) => {
    //    console.log("AssetId, startDate, endDate2", state.AssetId, StartDate, EndDate);
    //    if (StartDate !== state.StartDate || EndDate !== state.EndDate) {

    //        let NewStartDate = TimeStampEndofDay(StartDate, "Start");
    //        let NewEndDate = TimeStampEndofDay(EndDate, "End");

    //        GetDatafromNarrow(state.AssetId, NewStartDate, NewEndDate);

    //        let LocalStart = formatDate(NewStartDate);
    //        let LocalEnd = formatDate(NewEndDate);

    //        setState(prevState => ({
    //            ...prevState,
    //            StartDate: NewStartDate,
    //            EndDate: NewEndDate,
    //            ShowDates: `${LocalStart} to ${LocalEnd}`
    //        }));
    //    }

    //}

    const CloseDrawer = () => {
        //console.log("CloseDrawer", e)
        SetDeawerOpen(false)
    }

    //const handleChange = (event, name) => {
    //    setState({
    //        ...state,
    //        [name]: event.target.checked,
    //    });
    //};


    //const handleInclude = (data) => {

    //  console.log("ContactList.data", data)

    //  let ContactList = state.ContactList;
    //  let findindex = ContactList.findIndex(x => x.key === data.key);

    //  if (findindex && findindex > -1) {
    //    ContactList[findindex] = data;
    //  } else {
    //    // if key does not exist, then add as new
    //    ContactList = [...state.ContactList, data];
    //  }

    //  console.log("ContactList.handleInclude", ContactList)

    //  setState(prevState => ({
    //    ...prevState,
    //    ContactList: ContactList
    //  }));
    //  }


    console.log("ShowDetailsData.state", state)

    return (
        <Box mt={2}>
           

                {state.Data && (
                    
                        <ShowDetailsText
                            title={state.field1}
                            Category={state.Category}
                            ShowEditButton={state.ShowEditButton}
                            PreviewEdit={false}
                            Data={state.Data}
                        />
                    
                )}



            
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: state.Category,
                    CloseDrawer: (e) => CloseDrawer(),
                    Data: state.ServiceData
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </Box>
    );
}
