import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

export default function Animations() {
    return (
        <Box sx={{ width: '100%' }}>
            <Skeleton animation="wave" />
            <Skeleton />
            <Typography align='center'>
                No Data
            </Typography>
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
        </Box>
    );
}