import React, { useState, useEffect } from 'react';
import {
    Grid, Stepper, Step, StepLabel, StepContent, TextField,
    Backdrop, Chip,
    CircularProgress,
    Paper, Snackbar,
    Card, Typography, CardContent, FormControl,
    RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Divider, Collapse, CardActions,
    Select, MenuItem, InputLabel, CardHeader, Box, Button,
    IconButton
} from '@mui/material';
//import { makeStyles } from '@mui/styles';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import { updateSvcbotDb, createSvcbotDb, createBatch } from '../graphql/mutations';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import CardBox from '../components/ShowCheckBoxGroup';
import {
    removeEmptyStringElements,
    NewPageId,
    PrimaryKeyDataGet,
    MiddleTeamSearch,
    UpdateData,
    querySvcbotDbsByGsi1Pk2
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import ListCheckBox from '../List/ListCheckbox';
import ESCEachStepDetails from '../Setup/ESCEachStepDetails';
import {
    Hours, Dayss, WeekOfMonth, MonthsOfYear, DateTodaySlash, currentTime,
    formatDate, TimeStampEndofDay
} from '../utils/DateTime';
import DateRange from '../components/DateRange';
//import ESCPerStep from '../Setup/ESCPerStep';
import PMPersonal from '../Setup/PMPersonal';
import CheckBoxGroup from '../components/CheckBoxGroup'
import grey from "@mui/material/colors/grey";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';


//const useStyles = makeStyles((theme) => ({
//    root: {
//        backgroundColor: theme.palette.background.dark,
//        minHeight: '100%',
//        paddingBottom: theme.spacing(3),
//        paddingTop: theme.spacing(3)
//    },
//    Cardroot: {
//        width: '100%',
//    },
//    heading: {
//        fontSize: theme.typography.pxToRem(15),
//        fontWeight: theme.typography.fontWeightRegular,
//    },
//    paper: {
//        padding: theme.spacing(2),
//        color: theme.palette.text.secondary,
//    },
//    button: {
//        marginTop: theme.spacing(1),
//        marginRight: theme.spacing(1),
//    },
//    actionsContainer: {
//        marginBottom: theme.spacing(2),
//    },
//    resetContainer: {
//        padding: theme.spacing(3),
//    },
//    formControl: {
//        //margin: theme.spacing(1),
//    },
//    card: {
//        // backgroundColor: primary800
//    }

//}));


let DateOption = [
    {
        label: "Start Date",
        VariableName: "StartDate",
        value: DateTodaySlash,
    },
    {
        label: "End Date",
        VariableName: "EndDate",
        value: DateTodaySlash,
    },
 
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const defaultdays = [0, 1, 2, 3, 4, 5, 6];
const DefaultWeekOfMonth = [0, 1, 2, 3, 4];
const DefaultMonthsOfYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

let ContinueLoop = true;
let LastStepIndex = 0;


const Account = (props) => {
  //  const classes = useStyles();
    const params = useParams();
    let query = useQuery();
    const ContractorData = useSelector(state => state.ContractorData);
    const [expanded, setExpanded] = useState(false);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        StartTime: '07:00',
        EndTime: "17:00",
        StartDate: "",
        EndDate: "",
        ShiftName: "",
        showeditbutton: true,
        DateErr: false,
        EndTimeMessage: "",
        DateRange: DateOption,
        DaysSelected: defaultdays,
        WeekOfMonthSelected: DefaultWeekOfMonth,
        MonthsOfYearSelected: DefaultMonthsOfYear,
        ScheduleType: "Recurring",
        Dayss: Dayss,
        WeekOfMonth: WeekOfMonth,
        MonthsOfYear: MonthsOfYear,
        steps: [],
        Category: "",
        FormJSON: [],
        FormData: [],
        counter: 0,
        AlarmMessage: "",
        ESCId: "",
        StepTitle: "Step 1",
        StepTeam: [],
        StepNumber: 0,
        step: 1,
        delay: '0',
        ShiftSelected: "",
        AreaSelected: "",
        UrgencySelected: "",
        TradeSelected: "",
        OpenAlert: false,
        ShowNewServiceForm: false,
        ImagePic: "",
        AllowDelete: false,
        AllowOpenEdit: false,
        ESCEvent: "",
        disabled: true,
        NotificationStepsList: [],
        status: "Active",
        message: "",
        Adddisabled: false,
        AllowEdit: false,
       // AllowDelete: false,
        delay: [],
        DoneDone: false,
    }
    const [state, setState] = useState(initialState);
    const [counter, setCounter] = useState(0);
    const [activeStep, setActiveStep] = useState(0);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {

        let AllowDelete = false;
        let AllowOpenEdit = false;
        let AllowEdit = false;

        var dateToday1 = new Date("1/1/3000");
        let currentTime1 = dateToday1.toISOString();

        var dateToday2 = new Date("12/30/2021");
        let currentTime2 = dateToday2.toISOString();

        if (UserData.Level > 190) {
            AllowDelete = true;
            AllowOpenEdit = true;
            AllowEdit = true;
        }

        console.log('ESCMinSetup.CombineDateAndTime.end', currentTime1);
        console.log('ESCMinSetup.CombineDateAndTime.now', currentTime2);

        GetDatafromDB(props.Event);
        console.log('ESCMinSetup.props', props);
        setState(prevState => ({
            ...prevState,
            counter: counter,
            CompanyId: props.CompanyId,
            CompanyName: props.CompanyName,
            AllowDelete: AllowDelete,
            AllowOpenEdit: AllowOpenEdit,
            AllowEdit: AllowEdit
        }));
    }, [])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    const GetDatafromDB = async (Event) => {

        let pk = Event.CompanyId;
        let sk = Event.key;

        SetLoading(true);
        let DataFile = "";
        let data = await PrimaryKeyDataGet(pk, sk);
        let NewData = await data[0];

      //  console.log("ESEditTeam.NewData", pk, sk, NewData);

        if (NewData && NewData.dataJSON) {

            DataFile = JSON.parse(NewData.dataJSON);
            console.log("ESCTeamEdit.DB.back", DataFile);
            console.log("ESCTeamEdit.DB.event", Event);
          //  GetEachTeam(`ESCTeams::${DataFile.id}`, DataFile.steps, Event);

            setState(prevState => ({
                ...prevState,
                counter: counter,
                ESCEvent: DataFile,
                ESCId: DataFile.id,
                UpdatePk: pk,
                Updatesk: sk,
                ShiftName: DataFile.field1,
                StartTime: DataFile.StartTime,
                EndTime: DataFile.EndTime,
                StepValueArray: DataFile.StepValueArray,
                DaysSelected: DataFile.Days,
                WeekOfMonthSelected: DataFile.WeekOfMonth,
                MonthsOfYearSelected: DataFile.MonthsOfYear,
            }));

            SetDayValues("Dayss", DataFile.Days)
            SetDayValues("WeekOfMonth", DataFile.WeekOfMonth)
            SetDayValues("MonthsOfYear", DataFile.MonthsOfYear)

        }


        SetLoading(false);

    }

    const GetEachTeam = async (pk, steps, Event) => {

        SetLoading(true);
        let DataFile = [];
        //let data = await querySvcbotDbsByGsi1Pk2(pk); MiddleTeam
        //let todayDate = new Date(Event.end);

        let NewActiveDate = new Date(Event.end);
        let y = NewActiveDate.getFullYear();
        let m = NewActiveDate.getMonth();
        //let first = new Date(y, m, 1).getDay();
        let last = 32 - new Date(y, m, 32).getDate();
        //let EndDate = `${y}/${m}/${last}`;

        let lastdayy = new Date(y, m, last);
        lastdayy.setHours(23, 59, 0, 0);
        let EndDate = lastdayy.toISOString();

        let data = await MiddleTeamSearch(pk, EndDate);
        let NewData = await data;

        if (NewData) {
            NewData.map(each => {
                DataFile.push(JSON.parse(each.dataJSON));
            })

            console.log("ESEditTeam.GetEachTeam.DataFile", DataFile);

            SetSteps(DataFile, steps);
        }
        SetLoading(false);
    }

    const SetSteps = (DataFile, steps) => {

        console.log("ESEditTeam.steps", steps, DataFile);

        let NewSteps = [];  
        let data = [];
        steps.map((each, indd) => {
            data = DataFile.filter(x => x.StepNumber === indd + 1)
            NewSteps.push({
                ...each,
                TeamData: data,
                step: indd + 1,
            })
            data = [];
        })
        console.log("ESEditTeam.SetSteps", NewSteps);
        setState(prevState => ({
            ...prevState,
            counter: counter,
            NotificationStepsList: NewSteps
        }));
    }

    const SetDayValues = (Arrayy, SelectedValues) => {
        let NewCheckList = state[Arrayy];
        let NewCheckList2 = state[Arrayy];

        console.log("ESEditTeam.NewCheckList2-1", NewCheckList2)
        NewCheckList.map((each, ind) => {
            if (SelectedValues.includes(each.keyy)) {
                NewCheckList2[ind].checked = true;
            } else {
                NewCheckList2[ind].checked = false;
            }
        })
        console.log("ESEditTeam.NewCheckList2-2", NewCheckList2)

        setState(prevState => ({
            ...prevState,
            counter: counter,
            [Arrayy]: NewCheckList2,
        }));
 };


    //const Image = imgg => {
    //    setState(prevState => ({
    //        ...prevState,
    //        ImagePic: imgg
    //    }));
    //}

    //const ReturnBackData = form => {
    //    console.log('formItemAddForm', form);
    //    setState(prevState => ({
    //        ...prevState,
    //        FormData: form
    //    }));
    //}

    //const GetSearchTerms = (selected, list) => {

    //    try {
    //        console.log("selected, list", selected, list)
    //        let SearchWords = "";
    //        let Temp = "";
    //        //  if (selected.length > 0)
    //        selected.map(each => {
    //            Temp = list.find(x => x.key === each);
    //            SearchWords += ` ${Temp.field1} |`;
    //        })
    //        return SearchWords;
    //    } catch (err) { console.log('GetSearchTerms err', err) }

    //}

    //const SetValueState = (e, name) => {
    //    setState(prevState => ({ ...prevState, [name]: e }));
    //}


    const EditShift = () => {

        try {

            let Field1 = "";
            let Field2 = "";
            let SearchWords = state.ShiftName;
            let MissingInfoFlag = false;
            let dataJSON = "";
            let FormdataJSON = {};
            let PhotoService = [];
            let Documents = [];
            let AlarmMessage = 'Please enter ';
            let DBInput = {};
            let DBInput2 = {};
            let Phone = "";
            let Email = "";
            let InputArray = [];
            let CompanyId = props.CompanyId;
            let CompanyName = props.CompanyName;
            let FormDD = {};
            let post = "";
            let title = "";
            let EndDate = "";
            let steps = [];
            let TotalPersonal = [];
            let EndDatetime = "";

            console.log('ESCMinSetup.CompanyId', CompanyId)

            let NewTeamId = `${state.ESCId}::${NewPageId("Team")}`;
           // let temparray = [];

            state.StepValueArray.map((each, indexx) => {
                if (each && each.NotificationStepsList && each.NotificationStepsList.length > 0) {
                   // temparray =
                        ProcessEachTeam(NewTeamId, each.NotificationStepsList, indexx);
                    //if (temparray && temparray.length > 0) {
                    //    InputArray = [...InputArray, ...temparray]
                    //}
                }
            })

            FormdataJSON = {
                ...state.ESCEvent,
                posterId: {
                    pk1: UserData.pk1,
                    sk1: UserData.sk1,
                    field1: UserData.filed1,
                    field2: UserData.filed2,
                    avatar: UserData.avatar,
                    email: UserData.email,
                    mobile: UserData.mobile,
                    phone: UserData.phone,
                },
                date: currentTime,
                NewTeamId: NewTeamId,
                Days: state.DaysSelected,
                WeekOfMonth: state.WeekOfMonthSelected,
                MonthsOfYear: state.MonthsOfYearSelected,
                StepValueArray: state.StepValueArray,
                StartTime: state.StartTime,
                EndTime: state.EndTime,
                status: state.status
            }

            dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))

                DBInput = {
                    pk1: state.UpdatePk,
                    sk1: state.Updatesk,
                    dataJSON: dataJSON
                }

            console.log('ESCMinSetup.BatchSendData0', DBInput)
            console.log('CalanderValues.ESCTeamEdit.FormdataJSON', FormdataJSON)

            
                API.graphql(graphqlOperation(updateSvcbotDb, { input: DBInput }))
                    .then((results) => {
                        console.log('ESCMinSetup.updateSvcbotDb ', results);
                        //if (props.CloseDrawer) {
                        //    props.CloseDrawer();
                        //}
                    }).catch(error => alert(error.message));
           

           

        } catch (err) { console.log("SaveData err", err); }
    }


    const BatchLimitPush = (data) => {

        let item_count = 0;
        let current_batch = [];

        for (var i = 0; i < data.length; i++) {
            item_count++
            current_batch.push(data[i])
            if (item_count % 25 === 0) {
                BatchSendData(current_batch);
                current_batch = []
            }
        }

        // Add the last batch if it has records and is not equal to 25
        if (current_batch.length > 0 && current_batch.length !== 25) {
            BatchSendData(current_batch);
        }

        //if (props.CloseDrawer) {
        //    props.CloseDrawer();
        //}

        setState(prevState => ({
            ...prevState,
            DoneDone: true
        }));

    }

    const BatchSendData = (data) => {
        console.log('ESCMinSetup.BatchSendData2', data);
        
        API.graphql(graphqlOperation(createBatch, { input: data }))
            .then((results) => {
                console.log('createBatch Data ', results);
            }).catch(error => console.log('createBatch error ', error));
        

    }

    const ProcessEachTeam = (NewTeamId, data, indexx) => {
        console.log('ESCMinSetup.ProcessEachTeam', NewTeamId, data);
        try {


            let DBInput1 = {};
            let DBInput2 = {};
            let InputArray = [];
            let CompanyId = state.CompanyId;
            let EndDatetime = "";
            let StepJson = {};

            data.map((Step) => {
                // console.log('each.NotificationStepsList', Step)

                Step.StepPersonalList.map(eachstep => {
                    if (eachstep) {

                        if (Step.ScheduleType === "Recurring") {
                            EndDatetime = "3000-01-01T23:59:59.000Z";
                        } else {
                            EndDatetime = TimeStampEndofDay(Step.EndDate);                            
                        }

                        DBInput1 = {
                            //find all shift for person
                            pk1: `${eachstep.key}::${CompanyId}::${NewTeamId}`,
                            sk1: `${EndDatetime}-${Step.StepNumber}-${Step.StepTeam}`,
                            //find all personal assigned for this shift
                            gsi1Pk2: NewTeamId,
                            gsi1Sk2: EndDatetime,
                            //contractor
                            gsi2Pk2: ContractorData.ContractorId,
                            gsi2Sk2: Step.key
                        }

                        StepJson = {
                            ...Step,
                            StepPersonalList: eachstep,
                            posterId: {
                                pk1: UserData.pk1,
                                sk1: UserData.sk1,
                                field1: UserData.filed1,
                                field2: UserData.filed2,
                                avatar: UserData.avatar,
                                email: UserData.email,
                                mobile: UserData.mobile,
                                phone: UserData.phone,
                            },
                            ...DBInput1
                        };

                        console.log('ESCMinSetup.ProcessEachTeam.StepJson', StepJson);

                        DBInput2 = {
                            ...DBInput1,
                            dataJSON: JSON.stringify(removeEmptyStringElements(StepJson))
                        }

                        InputArray.push(removeEmptyStringElements(DBInput2));
                    }
                })

            });

            if (InputArray.length > 0) {
                BatchLimitPush(InputArray);
            }

        } catch (err) { console.log("SaveData err", err); }
    }


    const handleClose = (event, reason) => {
        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
    };

    const CloseDrawer = () => {
        SetDeawerOpen(false);
    }

    const AddStep = (last) => {
        let StepValueArray = state.StepValueArray;
        let laststep = 0;
        let End = StepValueArray.find(x => x.NotificationStepsList === undefined)

        if (End) {
            laststep = End.StepNumber
        }

        console.log("ESCTeamEdit.AddStep", last, laststep)

        setState(prevState => ({
            ...prevState,
            NextStep: laststep
        }));

        SetDeawerOpen(true);
    }

    const KeyArray = (data, cat) => {
        console.log("KeyArray", data, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));
    }


    //const AddNextStepOrEnd = (data) => {
    //    try {
    //        console.log("AddNextStepOrEnd", state.StepNumber, data)
    //        let NotificationStepsList = state.NotificationStepsList;
    //        let StepIndex = NotificationStepsList.findIndex((post) => post.step === data.StepNumber);
    //        let NewStepNumber = state.StepNumber;

    //        if (StepIndex > -1) {
    //            NotificationStepsList[StepIndex] = {
    //                ScheduleType: data.ScheduleType,
    //                StartDate: data.StartDate,
    //                EndDate: data.EndDate,
    //                StepPersonalList: data.StepPersonalList,
    //                StepName: data.StepName,
    //                field1: data.field1,
    //                field2: data.field2,
    //                delay: data.delay,
    //                step: data.StepNumber,
    //                SearchWords: data.SearchWords,
    //                ESCId: data.ESCId
    //            }

    //        } else {

    //            NotificationStepsList.push({
    //                ScheduleType: data.ScheduleType,
    //                StartDate: data.StartDate,
    //                EndDate: data.EndDate,
    //                StepPersonalList: data.StepPersonalList,
    //                StepName: data.StepName,
    //                field1: data.field1,
    //                field2: data.field2,
    //                delay: data.delay,
    //                SearchWords: data.SearchWords,
    //                step: NewStepNumber,
    //                ESCId: data.ESCId
    //            })

    //            NewStepNumber = state.StepNumber + 1;

    //        }
    //        console.log("NotificationStepsList", StepIndex, NewStepNumber, NotificationStepsList);

    //        setState(prevState => ({
    //            ...prevState,
    //            NotificationStepsList: NotificationStepsList,
    //            StepNumber: NewStepNumber
    //        }));
    //    } catch (err) { }
    //};

    //const AddNewStep = (delay) => {
    //    let NotificationStepsList = state.NotificationStepsList;
    //    let NewStepNumber = state.StepNumber + 1;

    //    NotificationStepsList.push({
    //        step: NewStepNumber,
    //        Teams: [],
    //        delay: delay,
    //        ESCId: state.ESCId
    //    })

    //    setState(prevState => ({
    //        ...prevState,
    //        NotificationStepsList: NotificationStepsList,
    //        delay: delay,
    //        StepNumber: NewStepNumber
    //    }));

    //}

    //const AddStepData = (teams, index) => {
    //  console.log("NotificationStepsList", teams, index)
    //  console.log("NotificationStepsList2", state.NotificationStepsList)
    //  let NotificationStepsList = state.NotificationStepsList;
    //  NotificationStepsList[index] = teams;
    //  console.log("NotificationStepsList3", NotificationStepsList)
    //  //{ ...NotificationStepsList[index], Teams: teams }
    //  setState(prevState => ({
    //    ...prevState,
    //    NotificationStepsList: NotificationStepsList
    //  }));
    //}

    const AddStepData = (teams, index) => {
        console.log("NotificationStepsList", teams, index)
        console.log("NotificationStepsList.companid", state.CompanyId)
        console.log("NotificationStepsList2", state.NotificationStepsList)
        let NotificationStepsList = state.NotificationStepsList;

        NotificationStepsList[index] = {
            ...NotificationStepsList[index],
            TeamData: teams
        };
        console.log("NotificationStepsList3", NotificationStepsList)
        //{ ...NotificationStepsList[index], Teams: teams }
        setState(prevState => ({
            ...prevState,
            NotificationStepsList: NotificationStepsList
        }));
    }

    //const SelectedValues = (name, item) => {
    //    console.log("DateRange", name, item)
    //    setState((prevState) => ({
    //        ...prevState,
    //        [name]: item,
    //    }))
    //}

    //const SetDelay = (delay, index) => {
    //    let NotificationStepsList = state.NotificationStepsList;
    //    NotificationStepsList[index].delay = delay;
    //    console.log("ESCminSetup.steps", NotificationStepsList)
    //    setState((prevState) => ({
    //        ...prevState,
    //        NotificationStepsList: NotificationStepsList
    //    }))

    //    if (delay === "end") {
    //        console.log("ESCminSetup.Going to save", delay)
    //        //setActiveStep(8);
    //        //SaveData();
    //    } else {
    //        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //    }

    //}

    //const SaveStep = step => {

    //    //if (state.NotificationStepsList[step].delay === 'end') {
    //    //  setActiveStep(8);
    //    //} else {
    //    //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //    //}
    //    console.log("NotificationStepsList[step].delay", step, state.NotificationStepsList[step].delay)
    //    switch (state.NotificationStepsList[step].delay) {
    //        case "end":
    //            setActiveStep(8);
    //            break;
    //        case undefined:
    //        case "":
    //            setState(prevState => ({
    //                ...prevState,
    //                AlarmMessage: "Please select Delay before next step",
    //                OpenAlert: true
    //            }));
    //            break;
    //        default:
    //            setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //            break;
    //    }

    //}

    const handleChange = (event, ind, Arrayy, SelectedValues) => {
        let NewCheckList = state[Arrayy];
        let KeyArray = [];
        NewCheckList[ind].checked = event.target.checked;

        //console.log(NewCheckList)
        NewCheckList.map(each => {
            if (each.checked) {
                KeyArray.push(each.key)
            }
        })

        setState(prevState => ({
            ...prevState,
            [Arrayy]: NewCheckList,
            [SelectedValues]: KeyArray
        }));

    };

    const EndTimeCheck = (e) => {
        let Endtimemessage = ""
        if (e < state.StartTime) {
            Endtimemessage = "Next Day";
        }
        setState(prevState => ({ ...prevState, EndTime: e, EndTimeMessage: Endtimemessage }));
    }

    const StartTimeCheck = (e) => {
        let Endtimemessage = ""
        if (e > state.EndTime) {
            Endtimemessage = "Next Day";
        }
        setState(prevState => ({ ...prevState, StartTime: e, EndTimeMessage: Endtimemessage }));
    }

    const OpenEdit = () => {
        setState(prevState => ({
            ...prevState,
            disabled: !state.disabled
        }));

        setExpanded(true);
    }

    const DeleteItem = () => {
        setState(prevState => ({
            ...prevState,
            status: "Deactive",
            message: "The shift will be removed from the calender upon Save Shift Updates"
        }));
        setExpanded(true);
    }

    const SaveSelection = (data) => {

        let StepValueArray1 = [];

        let Indexx = -1;

        console.log("UrgencyDetails.step.5a - 352 - data", data);
        console.log("UrgencyDetails.step.5 - 353 - SaveSelection", state.StepValueArray);

        if (state.StepValueArray && state.StepValueArray.length > 0) {
            Indexx = state.StepValueArray.findIndex(x => x.StepNumber === data.StepNumber);
            StepValueArray1 = state.StepValueArray;
            //  console.log("UrgencyDetails.SelectStepBox.Update1a", state.StepValueArray);
        }

        //   console.log("UrgencyDetails.SelectStepBox.Update2", StepValueArray1);

        if (Indexx > -1) {

            StepValueArray1[Indexx] = data;

        } else {

            if (data && data.StepNumber && data.StepNumber !== "") {
                StepValueArray1[data.StepNumber] = data;
            } else {
                StepValueArray1.push(data);
            }

        }

        console.log("UrgencyDetails.step.6 - 377 - SaveSelection", StepValueArray1);

        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray1
        }))
    }

    const SetDelay = (delayback, index) => {
        console.log("ESCPerStep.return data 2", delayback, index)
        let LastStepAdd = false;
        let StepValueArray = state.StepValueArray;
        StepValueArray[index].delay = delayback;
        console.log("ESCPerStep.return data 3", delayback, index, StepValueArray)
        

        if (delayback === "end") {
            console.log("ESCminSetup.Going to save", delayback)
            LastStepAdd = false
        } else {
          //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
            LastStepAdd = true
        }

        setState((prevState) => ({
            ...prevState,
            StepValueArray: StepValueArray,
            LastStepAdd: LastStepAdd
        }))

    }

    const AddNextStepOrEnd = (data) => {
        try {
            console.log("ESCPerStep.AddNextStepOrEnd", data)
            let NotificationStepsList = state.NotificationStepsList;
            let StepIndex = NotificationStepsList.findIndex((post) => post.key === data.key);

            if (StepIndex > -1) {

                NotificationStepsList[StepIndex] = { StepNumber: state.StepNumber, ...data }
            } else {

                NotificationStepsList.push({
                    StepNumber: state.StepNumber,
                    ...data
                })
            }
            console.log("ESCPerStep.NotificationStepsList", NotificationStepsList);

            //if (props.AddStepData) {
            //    props.AddStepData(NotificationStepsList);
            //}

            setState(prevState => ({
                ...prevState,
                NotificationStepsList: NotificationStepsList,
            }));

        } catch (err) { }
    };

    console.log("/*ESCTeamEdit*/.state", state);
    console.log('CalanderValues.ESCTeamEdit.state', state)

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                <Card>
                    <CardContent style={{
                        border: '2px solid #f30e39'
                    }} >
                        
                        <CardHeader
                            title={
                                <Button
                                color="primary"
                                variant="contained"
                                onClick={handleExpandClick}
                                >
                                    {`Shift Details`}
                            </Button>
                                }
                            action={
                            <>
                                {state.AllowOpenEdit && (
                                        <IconButton aria-label="settings" onClick={() => OpenEdit()} size="large">
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                    {state.AllowDelete && (
                                        <IconButton aria-label="settings" onClick={() => DeleteItem()} size="large">
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                             </>
                            }
                        />

                       
                        <Collapse in={expanded} timeout="auto">
                            <CardContent>

                                <Grid container spacing={1}>

                                    {state.message !== "" && (
                                    <Grid item xs={12}>
                                            <Typography variant="h3"
                                                sx={{ color: red[500] }}
                                                gutterBottom
                                                component="div">
                                                {state.message}
                                            </Typography>
                                    </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Typography variant="h4" gutterBottom component="div">Schedule Details</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography>Shift Start & End Times</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>

                                            <Grid
                                                key="5"
                                                item
                                                xs={4}>
                                                <Typography variant="h5" component="h2">
                                                    Shift Start Time:
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                key="6"
                                                item
                                                xs={8}>

                                                <FormControl variant="outlined"
                                                   // className={classes.formControl}
                                                    style={{ width: '100%' }} >
                                                    <InputLabel id="demo-simple-select-outlined-label">Start Time</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        //id={`select-${Item.key}-${index}`}
                                                        defaultValue={state.StartTime}
                                                        //error={ErrorMessage}
                                                        //required={Item.required}
                                                        disabled={state.disabled}
                                                        autoWidth={true}
                                                        value={state.StartTime || ""}
                                                        onChange={e => StartTimeCheck(e.target.value)}
                                                        label="Start Time"
                                                    >
                                                        {Hours.map((o, indd) => (
                                                            <MenuItem key={indd} value={o.value}>
                                                                {o.label}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl >


                                            </Grid>
                                            <Grid
                                                key="7"
                                                item
                                                xs={4}>

                                                <Typography variant="h5" component="h2">
                                                    Shift End Time:
                                                </Typography>
                                                {state.EndTimeMessage !== "" && (
                                                    <Typography color='error'>
                                                        {state.EndTimeMessage}
                                                    </Typography>
                                                )}

                                            </Grid>
                                            <Grid
                                                key="8"
                                                item
                                                xs={8}
                                            >


                                                <FormControl variant="outlined"
                                                   // className={classes.formControl}
                                                    style={{ width: '100%' }} >
                                                    <InputLabel id="demo-simple-select-outlined-label">End Time</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        //id={`select-${Item.key}-${index}`}
                                                        defaultValue={state.EndTime}
                                                        //error={ErrorMessage}
                                                        //required={Item.required}
                                                        disabled={state.disabled}
                                                        autoWidth={true}
                                                        value={state.EndTime || ""}
                                                        onChange={e => EndTimeCheck(e.target.value)}
                                                        //onChange={e => setState((prevState) => ({ ...prevState, EndTime: e.target.value }))}
                                                        label="End Time"
                                                    >
                                                        {Hours.map((o, indd) => (
                                                            <MenuItem key={indd} value={o.value}>
                                                                {o.label}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl >


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Active Days, Weeks, and Months</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" component="h2">
                                            Days of Week
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {state.Dayss.map((each, ind) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={each.checked}
                                                        onChange={(event) => handleChange(event, ind, "Dayss", "DaysSelected")}
                                                        name={each.field1}
                                                        color="primary"
                                                    />
                                                }
                                                disabled={state.disabled}
                                                label={each.field1}
                                                key={`grdfrm-${ind}`}
                                            />
                                        ))}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h5" component="h2">
                                            Week of the Month
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {state.WeekOfMonth.map((each, ind) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={each.checked}
                                                        onChange={(event) => handleChange(event, ind, "WeekOfMonth", "WeekOfMonthSelected")}
                                                        name={each.field1}
                                                        color="primary"
                                                    />
                                                }
                                                disabled={state.disabled}
                                                label={each.field1}
                                                key={`grdfrmWeekOfMonth-${ind}`}
                                            />
                                        ))}
                                    </Grid>

                                    <Grid item key="20dehbhddws20" xs={12} >
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h5" component="h2">
                                            Months of the Year
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {state.MonthsOfYear.map((each, ind) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={each.checked}
                                                        onChange={(event) => handleChange(event, ind, "MonthsOfYear", "MonthsOfYearSelected")}
                                                        name={each.field1}
                                                        color="primary"
                                                    />
                                                }
                                                disabled={state.disabled}
                                                label={each.field1}
                                                key={`grdfrmMonthsOfYear-${ind}`}
                                            />
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                onClick={() => EditShift()}
                                                disabled={state.disabled}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Save Shift Updates
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Collapse>
                    </CardContent>
                </Card>
                    </Grid>
                        <Grid item xs={12}>
                {state.ESCId && state.StepValueArray
                    && state.StepValueArray.map((eachStep, stepInd) => {
                        if (eachStep && eachStep.NotificationStepsList
                            && eachStep.NotificationStepsList.length > 0
                            && ContinueLoop
                        ) {
                            LastStepIndex = eachStep.StepNumber;
                            console.log("ESCTeamEdit.loop", ContinueLoop, LastStepIndex, eachStep)
                            return (
                                <Card
                                    key={stepInd + "cardd"}
                                >
                                    <CardHeader
                                        title={
                                        < Typography variant="h3" gutterBottom component="div">
                                                {`${state.ShiftName} Personal`}
                                        </Typography>}
                                        key={stepInd + "carddhead"}
                                    />
                                    <CardContent>
                                        <PMPersonal
                                            StepNumber={eachStep.StepNumber}
                                            AllowDelete={state.AllowDelete}
                                            AllowEdit={state.AllowEdit}
                                            key={stepInd + "keyy"}
                                            SelectedDataReturn={(e) => SaveSelection(e)}
                                            ShiftName={state.ShiftName || ""}
                                            ErrorMessage={false}
                                            // AllowDelete={true}
                                            delay={(e) => SetDelay(e, stepInd)}
                                            ESCId={state.ESCId}
                                            //  Teams={state.NotificationStepsList[0]}
                                            CompanyId={props.CompanyId}
                                            CompanyName={props.CompanyName}
                                            StepValueArray={state.StepValueArray}
                                            AllowNext={false}
                                        //  delayValue={state.NotificationStepsList[0] && state.NotificationStepsList[0].delay !== "" ? state.NotificationStepsList[0].delay : ""}

                                        />
                                    </CardContent>
                                </Card>
                            )

                            if (eachStep.delay === "end") {
                                ContinueLoop = false;
                            }
                        }
                       // console.log("ESCTeamEdit.loop", ContinueLoop, LastStepIndex, eachStep)
                    }

                    )}
                    </Grid>

                    {/*

                        LastStepIndex < 5 && !ContinueLoop && (
                    <Grid item xs={12}>
                             <Card
                                    key={"ca4393rdd"}
                                >
                                    <CardHeader
                                    title={`Add Step ${LastStepIndex + 1}`}
                                        key={"carddheaewe343d"}
                                    />
                                    <CardContent>
                        <ESCPerStep
                                StepNumber={LastStepIndex + 1}
                            AllowDelete={state.AllowDelete}
                            AllowEdit={state.AllowEdit}
                            key={"keyy2392"}
                            SelectedDataReturn={(e) => SaveSelection(e)}
                            ShiftName={state.ShiftName || ""}
                            ErrorMessage={false}
                            // AllowDelete={true}
                                delay={(e) => SetDelay(e, LastStepIndex)}
                            ESCId={state.ESCId}
                            //  Teams={state.NotificationStepsList[0]}
                            CompanyId={props.CompanyId}
                            CompanyName={props.CompanyName}
                            StepValueArray={state.StepValueArray}
                        //  delayValue={state.NotificationStepsList[0] && state.NotificationStepsList[0].delay !== "" ? state.NotificationStepsList[0].delay : ""}

                        />
                                </CardContent>
                            </Card>
                    </Grid>
                        )

                    */}
                   
                </Grid>
            </Paper>

            {state.LastStepAdd ? (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ m: 1 }}
                    >
                        <Button
                        onClick={() => AddStep(LastStepIndex)}
                            disabled={state.disabledAddStep}
                            color="primary"
                            variant="contained"
                        >
                            Add Step
                        </Button>
                    </Box>
                
            ) : (
                
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ m: 1 }}
                    >
                        <Button
                            onClick={() => EditShift()}
                            disabled={state.disabledAddStep}
                            color="primary"
                            variant="contained"
                        >
                            Save Shift Updates
                        </Button>
                    </Box>
                
            )}

            <Backdrop
               // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
         
            {/*            <DrawerShow
                LoadComponenet="ESCSetupPage"
                //LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle="Edit Shift"
                propss={{
                    //CloseDrawer: () => ClosingDrawer(),
                    //StepPersonal: (e) => AddNextStepOrEnd(e),
                    //StepNumber: state.StepNumber,
                    //EditStep: state.EditStep,
                    //StepTeam: state.StepTeam[state.EditStep],
                    //ESCId: state.ESCId,
                    CloseDrawer: (e) => CloseDrawer(e),
                    ESCPolicyEdit: state.ESCEvent,
                    //Category: state.Category,
                    //FormData: state.FormData
                }}
                DrawerStatus={DeawerOpen}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
            */}
            <DrawerShow
                LoadComponenet="ESCPerStep" //{state.NextLoadModule}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle="Personal"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    CloseEditDrawer: () => CloseDrawer(),
                    StepPersonal: (e) => AddNextStepOrEnd(e),
                    StepNumber: state.NextStep,
                   // StepTeam: state.StepTeam,
                    ShiftName: state.ShiftName,
                    ShowEditButton: true,
                    disabled: false,
                    SelectedDataReturn: (e) => SaveSelection(e),
                   // EditItem: state.EditItem,
                    CompanyId: state.CompanyId,
                    CompanyName: state.CompanyName,
                    ESCId: state.ESCId,
                    delay: (e) => SetDelay(e, state.NextStep - 1),
                    StepValueArray: state.StepValueArray
                 }}
                DrawerStatus={DeawerOpen}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </>
    );
};

export default Account;
