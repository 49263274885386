import React, { useState, useEffect } from 'react';
//import { Link as RouterLink, useNavigate } from 'react-router-dom';
//import * as Yup from 'yup';
//import { Formik } from 'formik';
import {
    Box,
    Grid,
    Container,
    Snackbar,
    //Link,
    TextField,
    Typography,
    LinearProgress,
    Button
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import StartPage from '../views/auth/LoginView';
import CheckInDetails from '../List/CheckInDetails';
import ServiceDetails from '../List/ServiceDetails';
import VpnKeyIcon from '@mui/icons-material/VpnKey';


const AWS = require("aws-sdk");
// Initialize the Amazon Cognito credentials provider
AWS.config.region = "us-east-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:85823b95-577e-4643-aadf-86ec38a0c989",
});
const dynamodb = new AWS.DynamoDB.DocumentClient();

var GetDBData = (params) => {
    return new Promise((resolve, reject) => {
        dynamodb.query(params, function (err, data) {
            if (err) {
                console.log("errrr", err, err.stack);
                // an error occurred
            } else {
                console.log("databack DB", data)
                resolve(data);
            }// successful response
        });
    });
};

const GetData = async (pk1, sk1) => {
    try {
        let getItemParams2 = {
            //IndexName: "gsi1Pk2-gsi1Sk2-index",
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and #sk1 = :sk1",
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1",
            },
            ExpressionAttributeValues: {
                ":pk1": pk1,
                ":sk1": sk1,
            }
        };

        let EscPolicy = await GetDBData(getItemParams2);
        return EscPolicy.Items;

    } catch (err) {
        console.log('GetData err', err);
    }
};

//const BatchWrite = (InputArray) => {
//    try {
//        let params = {
//            RequestItems: {
//                "svcbot_db": InputArray
//            }
//        };
//        console.log(`db params: ${JSON.stringify(params)}`);
//        dynamodb.BatchWriteItem(params, function (err, data) {
//            if (err) {
//                //callback(err);
//                console.log(`BatchWrite err: ${err}`);
//            } else {
//                //callback(null, data);
//                console.log(`BatchWrite worked: ${data}`);
//            }
//        });
//    } catch (err) {
//        console.log('BatchWrite err', err);
//    }
//}


const LoginView = (props) => {
    const [AddPersonal, SetAddPersonal] = useState(false);
    // const Auth = useSelector(state => state.auth);
    const dispatch = useDispatch();


    const params = useParams();
    console.log('LandingPage.params', params);
    console.log('LandingPage.props', props);
    const initialState = {
        ShowLogin: true,
        PostData: "",
    };

    const [state, setState] = React.useState(initialState);

    useEffect(() => {

        let pk = "";
        let sk = "";

        if (params.pk) {
            pk = atob(params.pk);
            sk = atob(params.sk);
        }

        console.log('LandingPage.pksk', pk, sk);

        if (pk !== "" && sk !== "") {
            LoadPostData(pk, sk);
        }

        //setState(prevState => ({
        //    ...prevState,
        //    ShowLogin: false,
        //}));

        // } else {
        //show message to login

        setState(prevState => ({
            ...prevState,
            //   ShowLogin: true,
            NextPk: pk,
            NextSk: sk
        }));

        // }
    }, [params.pk])

    useEffect(() => {

        let ContractorArray = JSON.parse(localStorage.getItem('browserinfo'));

        if (state.PostData && state.PostData.ContractorData) {
            if (ContractorArray && ContractorArray.length > 0) {
                let Conn = state.PostData.ContractorData

                let FindList = ContractorArray.findIndex(x => x.CompanyId === Conn.CompanyId);

                if (FindList > -1) {
                    console.log('LandingPage.cookies.Notadd');
                } else {
                    let newCookieArry = [...ContractorArray, Conn];
                    localStorage.setItem('browserinfo', JSON.stringify(newCookieArry));
                }

            } else {

                let NewContractorAdd = [state.PostData.ContractorData];
                localStorage.setItem('browserinfo', JSON.stringify(NewContractorAdd));
            }
        }

    }, [state.PostData])

    const LoadPostData = async (pk, sk) => {

        try {

            let NewData = [];
            //  let ShowLogin = true;
            let PostData = await GetData(pk, sk);
            console.log('LandingPage.PostData', PostData);
            if (PostData) {
                NewData = PostData[0].dataJSON;
                dispatch({ type: 'SINGLE_SERVICE_DATA', payload: NewData });
            }

            setState(prevState => ({
                ...prevState,
                //    ShowLogin: true,
                PostData: NewData,
            }));



        } catch (err) {
            console.log('LoadPref err', err);
        }
    }

    console.log('LandingPage.state', state);

    return (

        <Container maxWidth="md">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button
                        onClick={() => SetAddPersonal(!AddPersonal)}
                        //variant="contained"
                        //color="primary"
                        color="secondary"
                        startIcon={<VpnKeyIcon />}
                    >
                        Login
                    </Button>
                </Grid>

                {AddPersonal ? (
                    <Grid item xs={12}>
                        <StartPage
                            LandingPage={true}
                            NextPk={state.NextPk}
                            NextSk={state.NextSk}
                        />
                    </Grid>
                ) : null}

                <Grid item xs={12}>
                    {state.PostData && (
                        window.WebsiteType === "ServiceWithText" ? (
                            <ServiceDetails
                                ProgressList={false}
                                ContractorActionButtons={true}
                                CompanyActionButtons={true}
                                SREvent={state.PostData}
                                DisableAll={true}
                            //  Auth={Auth}
                            />
                        ): (
                        <CheckInDetails
                            ProgressList={false}
                            ContractorActionButtons={true}
                            CompanyActionButtons={true}
                            SREvent={state.PostData}
                            DisableAll={true}
                        //  Auth={Auth}
                        />
                        ))}
                </Grid>
            </Grid>
        </Container>

    );
};

export default LoginView;
