import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/material/styles';
//import Paper from '@mui/material/Paper';
import {
  TextField,
  //Paper, Grid,
  //Backdrop, 
  //CircularProgress,
  //Card,
  //CardContent,
  //InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  //Switch, Tooltip, Divider
} from '@mui/material';
//import Quill from '../components/quill'
//import InputBase from '@mui/material/InputBase';
//import Divider from '@mui/material/Divider';
//import IconButton from '@mui/material/IconButton';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//import DeleteIcon from '@mui/icons-material/Delete';

//const useStyles = makeStyles((theme) => ({
//  root: {
//    padding: '2px 4px',
//    display: 'flex',
//    alignItems: 'center',
//    // width: 400,
//  },
//  input: {
//    marginLeft: theme.spacing(1),
//    flex: 1,
//  },
//  iconButton: {
//    padding: 10,
//  },
//  divider: {
//    height: 28,
//    margin: 4,
//  },
//}));

export default function CustomizedInputBase(props) {
  //const classes = useStyles();
  const [title, setTitle] = useState(null);

  useEffect(() => {
    setTitle(props.VariableInput)
  }, [props.VariableInput])

  const SelectedValues = e => {
    setTitle(e.target.value);
    props.SelectedValues(e.target.value)
  }

  return (
    <TextField
      required={true}
      //error={ErrorMessage}
      disabled={props.disabled}
      id={`textline-${props.id}`}
      //defaultValue={Item.defaultValue}
      label="Title"
      name="title"
      value={title || ""}
      fullWidth
      variant="outlined"
      onChange={(e) => SelectedValues(e)}
    />
  );
}
