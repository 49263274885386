import Chart from 'react-apexcharts';
import React, { useState, useEffect } from "react";
import { Box, Card, CardHeader, Divider, Grid, Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    NewPageId,
    MiddlePostSearch,
    ContreactorPostSearch
} from '../utils/CommonGraphql';
import { adjustedTime } from '../utils/DateTime';
import dayjs from 'dayjs'


let olddate = adjustedTime(-90, "Start");
let Todaydatestring = adjustedTime(2, "End"); //Todaydate.toISOString();

const ProductChannel = (props) => {
    const [loading, SetLoading] = useState(false);
    const NowEditing = useSelector(state => state.NowEditing);
    const initialState = {
        //ZeroBucket: 0,
        //ProgressBucket: 0,
        //CompletedBucket: 0,
        //AcceptedBucket: 0
        NewId: "92929383"
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        GetDatafromDB(props.CompanyId, olddate, Todaydatestring)

        setState(prevState => ({
            ...prevState,
            NewId: NewPageId("chart"),
        }))

        console.log("Charts.Props", props);

    }, [props.Data])

    const GetDatafromDB = async (pk1, startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let ZeroBucket = 0;
        let ProgressBucket = 0;
        let CompletedBucket = 0;
        let AcceptedBucket = 0;
        console.log("SRPage.DBinput", NowEditing, pk1, startDate, endDate);

        if (NowEditing === "Contractor") {
            NewData = await ContreactorPostSearch("Post::Progress::" + pk1, startDate, endDate);
        } else {
            NewData = await MiddlePostSearch("Post::Progress::" + pk1, startDate, endDate);
        }

        console.log("Charts.ShowString.NewData1", NewData);
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);

                if (ShowString.Progress === 0) {
                    ZeroBucket += 1;
                    // console.log("Charts.ShowString.prog.0", ZeroBucket);
                } else if (ShowString.Progress > 0 && ShowString.Progress < 99) {
                    ProgressBucket += 1;
                    // console.log("Charts.ShowString.prog.50", ProgressBucket);
                } else if (ShowString.Progress === 100) {
                    CompletedBucket += 1;
                    // console.log("Charts.ShowString.prog.100", CompletedBucket);
                }

                if (ShowString.status === "Accepted") {
                    AcceptedBucket += 1;
                    console.log("Charts.ShowString.prog.>100", AcceptedBucket);
                }

                console.log("Charts.ShowString.Progress", ShowString.Progress, ZeroBucket);
            })
        }

        CreateChartOptions(ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket)


        SetLoading(false);
        // console.log("SRPage.NewData2", AutoSelectData);
    }

    const CreateChartOptions = (ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket) => {

        //var options = {
        //    chart: {
        //        height: 300,
        //        width: 100%,
        //        type: "bar",
        //        stacked: false,
        //        foreColor: "#333",
        //        background: 'transparent',
        //        toolbar: {
        //            show: false
        //        },
        //        zoom: {
        //            enabled: false
        //        }
        //    },
        //    dataLabels: {
        //        enabled: false
        //    },
        //    colors: ["#FF1654", "#247BA0"],
        //    series: [
        //        {
        //            name: "Work Progress",
        //            data: [ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket]
        //        },
        //    ],
        //    stroke: {
        //        width: [4, 4]
        //    },
        //    plotOptions: {
        //        bar: {
        //            horizontal: true
        //        }
        //    },
        //    xaxis: {
        //        categories: ["Call Received", "In Progress", "Completed", "Accepted"]
        //    },
        //    title: {
        //        text: "Progress",
        //        align: "center",
        //        margin: 5,
        //        offsetY: 20,
        //        style: {
        //            fontSize: "25px"
        //        }
        //    },
        //};

        var options2 = {

            chart: {
                type: 'candlestick',
                height: 400,
                width: '100%',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                width: [4, 4]
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            colors: ["#FF1654", "#247BA0"],
            title: {
                text: "Check In/Out",
                align: "center",
                margin: 5,
                offsetY: 20,
                style: {
                    fontSize: "25px"
                }
            },
            xaxis: {
                type: ["1/1", "1/2", "1/3", "1/4", "1/5", "1/6"]
            },
            yaxis: {
                tooltip: {
                    enabled: false
                }
            }
        };

        //let series = [
        //    {
        //        name: "Work Progress",
        //        data: [ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket]
        //    },
        //];

        let series2 = [{
            data: [{
                x: "1/1",
                y: [12.33, 19.5, 12.33, 19.5]
            },
            {
                x: "1/2",
                y: [11.33, 18.5, 11.33, 18.5]
            },
            {
                x: "1/3",
                y: [10.33, 19.5, 10.33, 19.5]
            },
            {
                x: "1/4",
                y: [12.33, 19.5, 12.33, 19.5]
            },
            {
                x: "1/5",
                y: [11.33, 18.5, 11.33, 18.5]
            },
            {
                x: "1/6",
                y: [10.33, 19.5, 10.33, 19.5]
            }

            ]
        }];

        var options = {

            chart: {
                height: 350,
                type: 'candlestick',
            },
            title: {
                text: 'CandleStick Chart - Category X-axis',
                align: 'left'
            },
            annotations: {
                xaxis: [
                    {
                        x: 'Oct 06 14:00',
                        borderColor: '#00E396',
                        label: {
                            borderColor: '#00E396',
                            style: {
                                fontSize: '12px',
                                color: '#fff',
                                background: '#00E396'
                            },
                            orientation: 'horizontal',
                            offsetY: 7,
                            text: 'Annotation Test'
                        }
                    }
                ]
            },
            tooltip: {
                enabled: true,
            },
            xaxis: {
                type: 'category',
                labels: {
                    formatter: function (val) {
                        return dayjs(val).format('MMM DD')
                    }
                }
            },
            yaxis: {
                tooltip: {
                    enabled: true
                }
            }
        };

        let series = [{
            name: 'candle',
            data: [
                {
                    x: new Date(1538778600000),
                    y: [6629.81, 6650.5, 6623.04, 6633.33]
                },
                {
                    x: new Date(1538780400000),
                    y: [6632.01, 6643.59, 6620, 6630.11]
                },
                {
                    x: new Date(1538782200000),
                    y: [6630.71, 6648.95, 6623.34, 6635.65]
                },
                {
                    x: new Date(1538784000000),
                    y: [6635.65, 6651, 6629.67, 6638.24]
                },
                {
                    x: new Date(1538785800000),
                    y: [6638.24, 6640, 6620, 6624.47]
                },
                {
                    x: new Date(1538787600000),
                    y: [6624.53, 6636.03, 6621.68, 6624.31]
                },
                {
                    x: new Date(1538789400000),
                    y: [6624.61, 6632.2, 6617, 6626.02]
                },
                {
                    x: new Date(1538791200000),
                    y: [6627, 6627.62, 6584.22, 6603.02]
                },
                {
                    x: new Date(1538793000000),
                    y: [6605, 6608.03, 6598.95, 6604.01]
                },
                {
                    x: new Date(1538794800000),
                    y: [6604.5, 6614.4, 6602.26, 6608.02]
                },
                {
                    x: new Date(1538796600000),
                    y: [6608.02, 6610.68, 6601.99, 6608.91]
                },
                {
                    x: new Date(1538798400000),
                    y: [6608.91, 6618.99, 6608.01, 6612]
                },
                {
                    x: new Date(1538800200000),
                    y: [6612, 6615.13, 6605.09, 6612]
                },
                {
                    x: new Date(1538802000000),
                    y: [6612, 6624.12, 6608.43, 6622.95]
                },
                {
                    x: new Date(1538803800000),
                    y: [6623.91, 6623.91, 6615, 6615.67]
                },
                {
                    x: new Date(1538805600000),
                    y: [6618.69, 6618.74, 6610, 6610.4]
                },
                {
                    x: new Date(1538807400000),
                    y: [6611, 6622.78, 6610.4, 6614.9]
                },
                {
                    x: new Date(1538809200000),
                    y: [6614.9, 6626.2, 6613.33, 6623.45]
                },
                {
                    x: new Date(1538811000000),
                    y: [6623.48, 6627, 6618.38, 6620.35]
                },
                {
                    x: new Date(1538812800000),
                    y: [6619.43, 6620.35, 6610.05, 6615.53]
                },
                {
                    x: new Date(1538814600000),
                    y: [6615.53, 6617.93, 6610, 6615.19]
                },
                {
                    x: new Date(1538816400000),
                    y: [6615.19, 6621.6, 6608.2, 6620]
                },
                {
                    x: new Date(1538818200000),
                    y: [6619.54, 6625.17, 6614.15, 6620]
                },
                {
                    x: new Date(1538820000000),
                    y: [6620.33, 6634.15, 6617.24, 6624.61]
                },
                {
                    x: new Date(1538821800000),
                    y: [6625.95, 6626, 6611.66, 6617.58]
                },
                {
                    x: new Date(1538823600000),
                    y: [6619, 6625.97, 6595.27, 6598.86]
                },
                {
                    x: new Date(1538825400000),
                    y: [6598.86, 6598.88, 6570, 6587.16]
                },
                {
                    x: new Date(1538827200000),
                    y: [6588.86, 6600, 6580, 6593.4]
                },
                {
                    x: new Date(1538829000000),
                    y: [6593.99, 6598.89, 6585, 6587.81]
                },
                {
                    x: new Date(1538830800000),
                    y: [6587.81, 6592.73, 6567.14, 6578]
                },
                {
                    x: new Date(1538832600000),
                    y: [6578.35, 6581.72, 6567.39, 6579]
                },
                {
                    x: new Date(1538834400000),
                    y: [6579.38, 6580.92, 6566.77, 6575.96]
                },
                {
                    x: new Date(1538836200000),
                    y: [6575.96, 6589, 6571.77, 6588.92]
                },
                {
                    x: new Date(1538838000000),
                    y: [6588.92, 6594, 6577.55, 6589.22]
                },
                {
                    x: new Date(1538839800000),
                    y: [6589.3, 6598.89, 6589.1, 6596.08]
                },
                {
                    x: new Date(1538841600000),
                    y: [6597.5, 6600, 6588.39, 6596.25]
                },
                {
                    x: new Date(1538843400000),
                    y: [6598.03, 6600, 6588.73, 6595.97]
                },
                {
                    x: new Date(1538845200000),
                    y: [6595.97, 6602.01, 6588.17, 6602]
                },
                {
                    x: new Date(1538847000000),
                    y: [6602, 6607, 6596.51, 6599.95]
                },
                {
                    x: new Date(1538848800000),
                    y: [6600.63, 6601.21, 6590.39, 6591.02]
                },
                {
                    x: new Date(1538850600000),
                    y: [6591.02, 6603.08, 6591, 6591]
                },
                {
                    x: new Date(1538852400000),
                    y: [6591, 6601.32, 6585, 6592]
                },
                {
                    x: new Date(1538854200000),
                    y: [6593.13, 6596.01, 6590, 6593.34]
                },
                {
                    x: new Date(1538856000000),
                    y: [6593.34, 6604.76, 6582.63, 6593.86]
                },
                {
                    x: new Date(1538857800000),
                    y: [6593.86, 6604.28, 6586.57, 6600.01]
                },
                {
                    x: new Date(1538859600000),
                    y: [6601.81, 6603.21, 6592.78, 6596.25]
                },
                {
                    x: new Date(1538861400000),
                    y: [6596.25, 6604.2, 6590, 6602.99]
                },
                {
                    x: new Date(1538863200000),
                    y: [6602.99, 6606, 6584.99, 6587.81]
                },
                {
                    x: new Date(1538865000000),
                    y: [6587.81, 6595, 6583.27, 6591.96]
                },
                {
                    x: new Date(1538866800000),
                    y: [6591.97, 6596.07, 6585, 6588.39]
                },
                {
                    x: new Date(1538868600000),
                    y: [6587.6, 6598.21, 6587.6, 6594.27]
                },
                {
                    x: new Date(1538870400000),
                    y: [6596.44, 6601, 6590, 6596.55]
                },
                {
                    x: new Date(1538872200000),
                    y: [6598.91, 6605, 6596.61, 6600.02]
                },
                {
                    x: new Date(1538874000000),
                    y: [6600.55, 6605, 6589.14, 6593.01]
                },
                {
                    x: new Date(1538875800000),
                    y: [6593.15, 6605, 6592, 6603.06]
                },
                {
                    x: new Date(1538877600000),
                    y: [6603.07, 6604.5, 6599.09, 6603.89]
                },
                {
                    x: new Date(1538879400000),
                    y: [6604.44, 6604.44, 6600, 6603.5]
                },
                {
                    x: new Date(1538881200000),
                    y: [6603.5, 6603.99, 6597.5, 6603.86]
                },
                {
                    x: new Date(1538883000000),
                    y: [6603.85, 6605, 6600, 6604.07]
                },
                {
                    x: new Date(1538884800000),
                    y: [6604.98, 6606, 6604.07, 6606]
                },
            ]
        }];

        setState(prevState => ({
            ...prevState,
            options: options,
            series: series
            //ZeroBucket: ZeroBucket,
            //ProgressBucket: ProgressBucket,
            //CompletedBucket: CompletedBucket,
            //AcceptedBucket: AcceptedBucket
        }))

    }



    console.log("Charts.state", state);
    // console.log("Charts.state.options", options);

    return state.options && (
        <div key={state.NewId}>
            <Card
                variant="outlined"
                sx={{ height: '100%' }}

            >
                <CardHeader
                    title={props.CompanyName}
                    subheader="Last 90 days"
                />
                <Divider />
                <Grid
                    alignItems="center"
                    container
                    sx={{
                        justifyContent: {
                            xs: 'center'
                        },
                        py: 1
                    }}
                >
                    <Grid item>
                        <Chart
                            options={state.options}
                            series={state.series}
                            type="candlestick"
                            width={500}
                        />
                    </Grid>

                </Grid>
            </Card>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ProductChannel;