import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import {
  Card, Typography, CardContent, TextField, FormControl,
  RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox, Divider,
  Select, MenuItem, InputLabel, CardHeader, Box, Button
} from '@mui/material';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import moment from 'moment';
//import DateFnsUtils from '@date-io/date-fns';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { Hours, Dayss, WeekOfMonth, MonthsOfYear } from '../utils/DateTime'
import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import MobileDatePicker from '@mui/lab/MobileDatePicker';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';


const DateFormat = 'MM/DD/YYYY';
const TimeFormat = 'HH:mm';
const TimeFormatShow = 'h:mm A';
//const startdate = new Date() //.format(dateFormat) + "T23:59:59.000";
//const enddate = dayjs() //.format(dateFormat) + "T23:59:59.000";

//var d = new Date();
//var year = d.getFullYear();
//var month = d.getMonth();
//var day = d.getDate();
//let startdate = `${month}/${day}/${year}`;
//let e = new Date(year, month, day + 1);
//var year2 = e.getFullYear();
//var month2 = e.getMonth();
//var day2 = e.getDate();
//let enddate = `${month2}/${day2}/${year2}`;

const UUid = NewPageId("Shift");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const defaultdays = [1, 2, 3, 4, 5, 6, 7];
const DefaultWeekOfMonth = [1, 2, 3, 4, 5];
const DefaultMonthsOfYear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
}));



const RecurringSchedule = (props) => {
  const classes = useStyles();
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  const initialState = {
    StartTime: '07:00',
    EndTime: "17:00",
    StartDate: "",
    EndDate: "",
    ShiftName: "",
    showeditbutton: true,
    disabled: true,
    OpenAlert: false,
    DateErr: false,
    EndTimeMessage: "",
    DaysSelected: defaultdays,
    WeekOfMonthSelected: DefaultWeekOfMonth,
    MonthsOfYearSelected: DefaultMonthsOfYear,
    ScheduleType: "Recurring",
    Dayss: Dayss,
    WeekOfMonth: WeekOfMonth,
    MonthsOfYear: MonthsOfYear,
  };

  const [state, setState] = useState(initialState);

  //useEffect(() => {
  //  console.log("initialState", state)
  //}, [state]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));

  };


  const SaveData = () => {
    //console.log('SaveData', FormData);
    try {

      const key = `Data::Online::Lambda::Shift#${UUid}`;
      var currentUnixTime = new Date();
      var currentTime = currentUnixTime.toISOString();

      let FarEndDate = "";

      let MissingInfoFlag = false;
      let dataJSON = "";
      let FormdataJSON = {};
      let AlarmMessage = 'Please enter ';
      let DBInput = {};
      let InputArray = [];
      let CompanyId = "";
      let CompanyName = "";

      let StartTimeShow = Hours.find(x => x.value === state.StartTime);
      let EndTimeShow = Hours.find(x => x.value === state.EndTime);
      let Field2 = `${StartTimeShow.label} - ${EndTimeShow.label}`;

      //make sure cid and companyid exist
      if (cid && cid.CompanyId && cid.CompanyId !== "") {
        //console.log('CIDDDDDD', cid)
        CompanyId = cid.CompanyId;
        CompanyName = cid.CompanyName;
      } else {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Company `;
      }

      if (state.ScheduleType === "Recurring") {
        FarEndDate = "3000-01-01"
      } else {

        if (state.StartDate === "" || state.EndDate === "") {
          MissingInfoFlag = true;
          AlarmMessage += `* Please select Dates `;
          setState(prevState => ({ ...prevState, DateErr: true }));
        } else {

          if (new Date(state.StartDate) > new Date(state.EndDate)) {
            MissingInfoFlag = true;
            AlarmMessage += `* The End Date should be after Start Date `;
            setState(prevState => ({ ...prevState, DateErr: true }));
          }

          FarEndDate = state.EndDate;
          Field2 = `${moment(state.StartDate).format(DateFormat)} to ${moment(state.EndDate).format(DateFormat)} - ${Field2}`;
          setState(prevState => ({ ...prevState, DateErr: false }));
        }
      }

      if (!state.ShiftName || state.ShiftName === "") {
        MissingInfoFlag = true;
        AlarmMessage += `* Please Enter Shift Name `;
        setState(prevState => ({ ...prevState, ShiftNameErr: true }));
      } else {
        setState(prevState => ({ ...prevState, ShiftNameErr: false }));
      }


      FormdataJSON = {
        FormData: state,
        SearchWords: `${state.ShiftName} ${StartTimeShow.label} - ${EndTimeShow.label}`,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        ScheduleType: state.ScheduleType,
        StartDate: state.StartDate,
        EndDate: state.EndDate,
        id: UUid,
        key: key,
        field1: state.ShiftName,
        field2: Field2,
        ContractorId: ContractorData.ContractorId,
        date: currentTime,
        posterId: UserData,
        Days: state.DaysSelected,
        WeekOfMonth: state.WeekOfMonthSelected,
        MonthsOfYear: state.MonthsOfYearSelected,
        pk1: CompanyId,
        sk1: key,
        gsi1Pk2: "Notify::" + CompanyId,
        gsi1Sk2: FarEndDate,
        gsi2Pk2: ContractorData.ContractorId,
        gsi2Sk2: key
      }



      dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))

      //if alerts
      if (MissingInfoFlag) {
        setState(prevState => ({
          ...prevState,
          AlarmMessage: AlarmMessage,
          OpenAlert: true
        }));
      } else {
        //if no alerts


        DBInput = {
          pk1: CompanyId,
          sk1: key,
          gsi1Pk2: "Notify::" + CompanyId,
          gsi1Sk2: FarEndDate,
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: key,
          dataJSON: dataJSON
        }

        InputArray.push(removeEmptyStringElements(DBInput));

        //console.log('FormdataJSON', FormdataJSON)
        //console.log('input', InputArray)

        if (InputArray.length > 0) {
          API.graphql(graphqlOperation(createBatch, { input: InputArray }))
            .then((results) => {
              console.log('createBatch Data ', results);

              if (props.CloseDrawer) {
                props.CloseDrawer(FormdataJSON);
              }
            })
        }
      } // end of no alerts

    } catch (err) { console.log("SaveData err", err); }
  }

  const handleChange = (event, ind, Arrayy, SelectedValues) => {
    let NewCheckList = state[Arrayy];
    let KeyArray = [];
    NewCheckList[ind].checked = event.target.checked;

    //console.log(NewCheckList)
    NewCheckList.map(each => {
      if (each.checked) {
        KeyArray.push(each.key)
      }
    })

    setState({
      ...state,
      [Arrayy]: NewCheckList,
      [SelectedValues]: KeyArray
    });


   // console.log("keys", KeyArray)
  };

  const DisableItems = () => {
    setState(prevState => ({ ...prevState, disabled: !state.disabled }));
  }

  const EndTimeCheck = (e) => {
    let Endtimemessage = ""
    if (e < state.StartTime) {
      Endtimemessage = "Next Day";
    }
    setState(prevState => ({ ...prevState, EndTime: e, EndTimeMessage: Endtimemessage }));
  }

  const StartTimeCheck = (e) => {
    let Endtimemessage = ""
    if (e > state.EndTime) {
      Endtimemessage = "Next Day";
    }
    setState(prevState => ({ ...prevState, StartTime: e, EndTimeMessage: Endtimemessage }));
  }

  const SetValueState = (e) => {
    setState(prevState => ({ ...prevState, ShiftName: e }));
  }

  return (
    
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="Shift Setup"
            action={
              state.showeditbutton && <EditIcon fontSize="small" onClick={() => DisableItems()} />
            }
          />
          <CardContent>

            <Grid container spacing={2} >

              <Grid item key="20dws20" xs={12} >
                <TextField
                  required={true}
                  error={state.ShiftNameErr}
                  disabled={state.disabled}
                  id="ShiftName"
                  //defaultValue={Item.defaultValue}
                  label="Shift Name"
                  name="ShiftName"
                  value={state.ShiftName}
                  fullWidth
                  variant="outlined"
                  //onChange={e => setState((prevState) => ({ ...prevState, ShiftName: e.target.value }))}
                  onChange={e => SetValueState(e.target.value)}

                />

              </Grid>
              <Grid item key="20dddws20" xs={12} >
                <Divider />
              </Grid>
              <Grid item key="2020" xs={12} >
                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                  <FormLabel component="legend">Schedule Type</FormLabel>
                  <RadioGroup
                    //aria-label={Item.label}
                    //id={`radio-${Item.key}-${index}`}

                    name="ScheduleType"
                    //error={ErrorMessage}
                    value={state.ScheduleType}
                    required={true}
                    onChange={e => setState((prevState) => ({ ...prevState, ScheduleType: e.target.value }))}
                  >
                    <FormControlLabel disabled={state.disabled} id="ScheduleType1" value="Recurring" control={<Radio />} label="Recurring Schedule" />
                    <FormControlLabel disabled={state.disabled} id="ScheduleType2" value="DateRange" control={<Radio />} label="Special Dates/Holiday" />
                  </RadioGroup>
                </FormControl >

              </Grid>

              <Grid item key="20ddddws20" xs={12} >
                <Divider />
              </Grid>

              {state.ScheduleType === "DateRange" && (
                <>


                  <Grid
                    key="1"
                    item
                    xs={4}>
                    <Typography variant="h5" component="h2">
                      Start Date:
            </Typography>

                  </Grid>
                  <Grid
                    key="2"
                    item
                    xs={8}>


                    <TextField
                      id="datepickkkerr"
                      label="Start Date"
                      name="StartDate"
                      //required={Item.required}
                      error={state.DateErr}
                      disabled={state.disabled}
                      //value={moment(state.StartDate, DateFormat) || ""}
                      format="MM/dd/yyyy"
                      fullWidth
                      type="date"
                      //defaultValue={moment(state.StartDate, DateFormat)}
                      variant="outlined"
                      className={classes.textField}
                      onChange={e => setState((prevState) => ({ ...prevState, StartDate: e.target.value }))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </Grid>
                  <Grid
                    key="3"
                    item
                    xs={4}>
                    <Typography variant="h5" component="h2">
                      End Date:
            </Typography>
                  </Grid>
                  <Grid
                    key="4"
                    item
                    xs={8}>


                    <TextField
                      id="datepickkkedddrr"
                      label="End Date"
                      name="EndDate"
                      //required={Item.required}
                      error={state.DateErr}
                      disabled={state.disabled}
                      //value={state.EndDate || ""}
                      format="MM/dd/yyyy"
                      fullWidth
                      type="date"
                      defaultValue={state.EndDate}
                      variant="outlined"
                      className={classes.textField}
                      // onChange={e => console.log("endd", e.target.value, moment(e.target.value).format(DateFormat))}
                      onChange={e => setState((prevState) => ({ ...prevState, EndDate: e.target.value }))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </Grid>
                  <Grid item key="20dddjjws20" xs={12} >
                    <Divider />
                  </Grid>

                </>
              )}


              <Grid
                key="5"
                item
                xs={4}>
                <Typography variant="h5" component="h2">
                  Shift Start Time:
            </Typography>
              </Grid>
              <Grid
                key="6"
                item
                xs={8}>

                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                  <InputLabel id="demo-simple-select-outlined-label">Start Time</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    //id={`select-${Item.key}-${index}`}
                    defaultValue={state.StartTime}
                    //error={ErrorMessage}
                    //required={Item.required}
                    disabled={state.disabled}
                    autoWidth={true}
                    value={state.StartTime || ""}
                    onChange={e => StartTimeCheck(e.target.value)}
                    label="Start Time"
                  >
                    {Hours.map((o, indd) => (
                      <MenuItem key={indd} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))
                    }
                  </Select>
                </FormControl >


              </Grid>
              <Grid
                key="7"
                item
                xs={4}>

                <Typography variant="h5" component="h2">
                  Shift End Time:
              </Typography>
                {state.EndTimeMessage !== "" && (
                  <Typography color='error'>
                    {state.EndTimeMessage}
                  </Typography>
                )}

              </Grid>
              <Grid
                key="8"
                item
                xs={8}
              >


                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                  <InputLabel id="demo-simple-select-outlined-label">End Time</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    //id={`select-${Item.key}-${index}`}
                    defaultValue={state.EndTime}
                    //error={ErrorMessage}
                    //required={Item.required}
                    disabled={state.disabled}
                    autoWidth={true}
                    value={state.EndTime || ""}
                    onChange={e => EndTimeCheck(e.target.value)}
                    //onChange={e => setState((prevState) => ({ ...prevState, EndTime: e.target.value }))}
                    label="End Time"
                  >
                    {Hours.map((o, indd) => (
                      <MenuItem key={indd} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))
                    }
                  </Select>
                </FormControl >


              </Grid>
              <Grid item key="20dddwseed20" xs={12} >
                <Divider />
              </Grid>
            </Grid>
            <Grid container spacing={2} >

              <Grid
                key="sdadjdsh"
                item
                xs={12}
              >

                <Typography variant="h5" component="h2">
                  Days of Week
            </Typography>
              </Grid>
              <Grid
                key="wss"
                item
                xs={12}
              >
                {state.Dayss.map((each, ind) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={each.checked}
                        onChange={(event) => handleChange(event, ind, "Dayss", "DaysSelected")}
                        name={each.field1}
                        color="primary"
                      />
                    }
                    disabled={state.disabled}
                    label={each.field1}
                    key={`grdfrm-${ind}`}
                  />
                ))}
              </Grid>
              <Grid item key="20ddddddws20" xs={12} >
                <Divider />
              </Grid>
              <Grid
                key="sjkdj"
                item
                xs={12}
              >


                <Typography variant="h5" component="h2">
                  Week of the Month
            </Typography>
              </Grid>
              <Grid
                key="wddsss"
                item
                xs={12}
              >
                {state.WeekOfMonth.map((each, ind) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={each.checked}
                        onChange={(event) => handleChange(event, ind, "WeekOfMonth", "WeekOfMonthSelected")}
                        name={each.field1}
                        color="primary"
                      />
                    }
                    disabled={state.disabled}
                    label={each.field1}
                    key={`grdfrmWeekOfMonth-${ind}`}
                  />
                ))}
              </Grid>

              <Grid item key="20dehbhddws20" xs={12} >
                <Divider />
              </Grid>

              <Grid
                key="8dssdddfssf"
                item
                xs={12}
              >

                <Typography variant="h5" component="h2">
                  Months of the Year
            </Typography>
              </Grid>

              <Grid
                key="wdsssaasss"
                item
                xs={12}
              >
                {state.MonthsOfYear.map((each, ind) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={each.checked}
                        onChange={(event) => handleChange(event, ind, "MonthsOfYear", "MonthsOfYearSelected")}
                        name={each.field1}
                        color="primary"
                      />
                    }
                    disabled={state.disabled}
                    label={each.field1}
                    key={`grdfrmMonthsOfYear-${ind}`}
                  />
                ))}
              </Grid>

            </Grid>
          </CardContent>
        </Card>

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => SaveData()}
            disabled={state.disabled}
          >
            Save details
          </Button>
        </Box>



          <Snackbar
              open={state.OpenAlert}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              message={state.AlarmMessage}
          />
          {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

      </div>
    
  )
}

export default RecurringSchedule;
