import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
} from '@mui/material';
// import { isMobile } from 'react-device-detect';
import DrawerShow from '../utils/DrawerModule';


const ProfileDetails = (props) => {

    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const UserData = useSelector(state => state.UserData)
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [CheckIn, SetCheckIn] = useState(false);
    const isMobile = true;

    useEffect(() => {

        if (CurrentCompany && CurrentCompany.CheckIn && CurrentCompany.CheckIn.length > 0) {
            SetCheckIn(true)
        }

    }, [CurrentCompany]);

    const EditDetails = () => {
        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        OpenDrawer(false);
    }


    return isMobile
        && CheckIn
        && (UserData.CustomerType === "Contractor" || UserData.CustomerType === "Sub") ? (

        <>
            <Box
                display="flex"
                justifyContent="center"
                p={2}
            >
                <Button
                    color="success"
                    variant="contained"
                    onClick={() => EditDetails()}
                >
                    Check In / Check Out
                </Button>
            </Box>


            <DrawerShow
                LoadComponenet="CheckInProcess"
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle="Check In / Check Out"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={"left"}
            />

        </>

    ) : null;
};


export default ProfileDetails;

