import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Typography,
    Grid, Card, CardContent
} from '@mui/material';

import Page from '../components/Page';
import CloudUpload from '@mui/icons-material/CloudUpload';
import RefreshIcon from '@mui/icons-material/Refresh';


const Profile = (props) => {

    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const UserData = useSelector(state => state.UserData);
    const initialState = {
        SelectedSMS: []
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let SelectedSMS = [];

        if (CurrentCompany
            && CurrentCompany.SelectedSMSArray
            && CurrentCompany.SelectedSMSArray.length > 0
        ) {
            SelectedSMS = CurrentCompany.SelectedSMSArray;
        } else if (ContractorData
            && ContractorData.SelectedSMSArray
            && ContractorData.SelectedSMSArray.length > 0
        ) {
            SelectedSMS = ContractorData.SelectedSMSArray;
        }

        setState(prevState => ({
            ...prevState,
            SelectedSMS: SelectedSMS
        }));

    }, [])


    return UserData.CustomerType !== "Contractor" ? (
        <Card>
            <CardContent>
            <Grid container spacing={3}>
                <Grid xs={12} item>

                    <Typography variant="h3">
                        Quickly Add Person
                    </Typography>
                </Grid>

                <Grid item xs={12} >

                    <Typography>
                        Simply share/text the contact from your Mobile Device to one of the following SMS number(s). 
                        Please make sure that Name, Phone, Mobile, and Email are filled and in right categories.
                        Refresh personal list after sharing the contact.
                    </Typography>

                </Grid>

                <Grid xs={12} item>

                    <Typography variant="h5">
                        SMS number(s):
                    </Typography>
                </Grid>
                {state.SelectedSMS.length > 0 && (
                    state.SelectedSMS.map(each => (
                        <Grid item xs={12}>
                            <Typography>
                                {each.mobile}
                            </Typography>
                        </Grid>
                    ))
                )}

                <Grid item xs={12}>
                    <Button
                        onClick={() => props.PutinCommonState({ itemvalue: true, itemname: "PersonalReload" })}
                        color="secondary"
                        startIcon={<RefreshIcon />}
                    >
                        Refresh Personnel List
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <img
                        src="https://s3.amazonaws.com/bomrng.com/public/contactcardsample.jpg"
                 
                        width={350} />
                </Grid>

            </Grid>
            </CardContent>
        </Card>
    ) : null
};

export default Profile;
