import React from 'react'
import { API, graphqlOperation } from "aws-amplify";
import {
    getFullCompanyInfo,
    ContractorDataPerCategory,
    ContractorPost,
    MiddlePost,
    CustomerPost,
    PrimaryKeyData,
    listSvcbotDbs,
    CompanyDataPerCategory,
    querySvcbotDbsByGsi1Pk2Gsi1Sk2Index,
    getSvcbotDb,
    MiddleTeam,
    batchGet
} from "../graphql/queries";
import PersonIcon from '@mui/icons-material/Person';
import SmsIcon from '@mui/icons-material/Sms';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { createSvcbotDb, updateSvcbotDb } from "../graphql/mutations";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ImageIcon from '@mui/icons-material/Image';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RuleIcon from '@mui/icons-material/Rule';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Feedback from '@mui/icons-material/ThumbUpAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from '@mui/icons-material/Share';

export const getAutoCompletedata = async (DataString, cid, AssetType = "", Id = "", first = 100, bbatch =[]) => {

    try {
        let Data = DataBaseKey(DataString.Category, cid, AssetType, Id);
        let pk = Data.pk;
        let sk = Data.sk;
        let DbIndex = Data.DbIndex;
        let Final = {};
        let AutoSelectData = [];
        let data = [];

        if (DataString.Category === "Company") {
            console.log("CommonGraphql.Company", cid)
        }

        switch (DbIndex) {
            case "pk1":
                data = await PrimaryKeyDataGet(pk, sk, first);
                break;
            case "gsi1":
                data = await MiddleCategories(pk, sk, first);
                break;
            case "gsi2":
                data = await ContractorDataPerCat(pk, sk, first); 
                break;
            case "batch":
                data = await GetBatch(bbatch); 
                break;
            default:
                data = await PrimaryKeyDataGet(pk, sk, first);
                break;
        }

        const NewData = await data;
        let NarrowArray = [];
        let ShowString = "";

        console.log("Testing.CommonGraphql.Data", NewData)

        if (NewData && NewData.length > 0) {

            DataString.FindArray.map(findsk => {
                NarrowArray = NewData.filter(x => x.sk1.indexOf(findsk.ArraySearchString) > -1);
                console.log("Testing.CommonGraphql.findsk", findsk)
                console.log("Testing.CommonGraphql.NarrowArray", NarrowArray)
                if (NarrowArray.length > 0) {
                    ShowString = "";
                    NarrowArray.map(d => {
                        ShowString = JSON.parse(d.dataJSON);
                        AutoSelectData.push(ShowString);
                    })
                    console.log("Testing.CommonGraphql.AutoSelectData1", AutoSelectData)
                    //narrow with variable value - like status === Active
                    if (findsk.extraVar && findsk.extraVar !== "" && findsk.extraValue !== "") {
                        AutoSelectData = AutoSelectData.filter(x => x[findsk.extraVar] === findsk.extraValue);
                    }
                    console.log("Testing.CommonGraphql.AutoSelectData2", AutoSelectData)
                    //sort the array based on field Sortby
                    //if (findsk.Sort && AutoSelectData.length > 0 && AutoSelectData[0].field1 && AutoSelectData[0].field1 !== "") {
                    //    AutoSelectData.sort((a, b) => (a[findsk.Sortby].toLowerCase() > b[findsk.Sortby].toLowerCase()) ? 1 : ((b[findsk.Sortby].toLowerCase() > a[findsk.Sortby].toLowerCase()) ? -1 : 0))
                    //}  descending

                    if (findsk.Order && findsk.Order !== "" && findsk.Order === "descending") {
                        AutoSelectData = AutoSelectData.reverse();
                    }

                    console.log("Testing.CommonGraphql.AutoSelectData3", AutoSelectData)
                    Final[findsk.ArrayName] = AutoSelectData;
                    if (findsk.DuplicateArray && findsk.DuplicateArray !== "") {
                        Final[findsk.DuplicateArray] = AutoSelectData;
                    }
                    

                    console.log("Testing.CommonGraphql.AutoSelectData4", AutoSelectData)
                    console.log("Testing.CommonGraphql.Final", Final)

                    AutoSelectData = [];
                    NarrowArray = [];

                }
            })

        } else {

            DataString.FindArray.map(findsk => {
                Final[findsk.ArrayName] = []
            })
        }
        console.log("Testing.CommonGraphql.Final", Final)
        return Final;

    } catch (err) {

    }
}


//what to find
export const DataBaseKey = (Category, cid, AssetType = "", Id = "") => {
    let DataStringReturn = {};

    switch (Category) {

        // companies listed under branch  
        case "Company":
            DataStringReturn = {
                pk: cid,
                sk: `Under::`,
                DbIndex: "pk1"
            };
            break;

        case "Sub-Contractor":
            DataStringReturn = {
                pk: cid,
                sk: `Under::`,
                DbIndex: "pk1"
            };
            break;

        case "Customers":
            DataStringReturn = {
                pk: cid,
                sk: `Under::`,
                DbIndex: "pk1"
            };
            break;
        //case "Company":
        //    DataStringReturn = {
        //        pk: cid,
        //        sk: `Data::Company::Info::${Id}`,
        //        DbIndex: "pk1"
        //    };
        //    break;

        //case "Sub-Contractor":
        //    DataStringReturn = {
        //        pk: cid,
        //        sk: `Data::Company::Info::${Id}`,
        //        DbIndex: "pk1"
        //    };
        //    break;

        case "OwnerContractor":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Owner::`,
                DbIndex: "gsi2"
            };
            break;

        case "Owners":
            DataStringReturn = {
                pk: "Developer::Account::0786",
                sk: `Data::Owner::${window.WebsiteType}`,
                DbIndex: "pk1"
            };
            break;

        case "Global":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Global::${Id}`,
                DbIndex: "pk1"
            };
            break;

        case "GlobalAccounts":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Global::Person::${Id}`,
                DbIndex: "batch"
            };
            break;

        case "Sub":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Sub::Info::${Id}`,
                DbIndex: "pk1"
            };
            break;

        case "Policy":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Policy::${Id}`,
                DbIndex: "pk1"
            };
            break;

        // companies listed under HQ
        case "HQCompany":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Info::${Id}`,
                DbIndex: "gsi2"
            };
            break;

        case "PostId":
            DataStringReturn = {
                pk: `Post::${cid}`,
                sk: `Post::`,
                DbIndex: "pk1"
            };
            break;

        case "CheckInId":
            DataStringReturn = {
                pk: `Post::${cid}`,
                sk: `Post::`,
                DbIndex: "pk1"
            };
            break;

        case "PostMessages":
            DataStringReturn = {
                pk: `Post::${cid}`,
                sk: `Post::Message::sent`,
                DbIndex: "pk1"
            };
            break;

        //case "ProgressChartCompany":
        //    DataStringReturn = {
        //        pk: `Data::Cont::Info::${cid}`,
        //      //  sk: `Data::Cont::Info::${Id}`,
        //        DbIndex: "gsi1"
        //    };
        //    break;

        //customer / company personal
        case "Person":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Resource::Person::${Id}`,
                DbIndex: "gsi2"
            };
            break;

        //customer / company Location
        case "Location":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Asset::Location::${Id}`,
                DbIndex: "pk1"
            };
            break;

           // Subscription
        case "Subscription":
            DataStringReturn = {
                pk: cid,
                sk: `Doc::Subscription::${Id}`,
                DbIndex: "pk1"
            };
            break;

        //Documents
        case "Documents":
            DataStringReturn = {
                pk: cid,
                sk: `Doc::${Id}`,
                DbIndex: "gsi1"
            };
            break;

        //Zap
        case "Zap":
            DataStringReturn = {
                pk: cid,
                sk: `Zapier::${Id}`,
                DbIndex: "gsi1"
            };
            break;

        //all locations under Branch
        case "BranchLocation":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Asset::Location::${Id}`,
                DbIndex: "gsi2"
            };
            break;

        case "MapLocations":
            DataStringReturn = {
                pk: cid,
                sk: `Post::Location::${Id}`,
                DbIndex: "gsi2"
            };
            break;

        //customer / company Equipment
        case "Equipment":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Asset::Equipment::${Id}`,
                DbIndex: "pk1"
            };
            break;

        //all equipment under Branch //Assets::
        case "BranchEquipment":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Asset::Equipment::${Id}`,
                DbIndex: "gsi2"
            };
            break;

        case "Assets":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Asset::${Id}`,
                DbIndex: "pk1"
            };
            break;

        //all personal under headquarters - employees and customer
        case "HQPersonal":
            DataStringReturn = {
                pk: cid,
                sk: `email::${Id}`,
                DbIndex: "gsi2"
            };
            break;

        //all personal under headquarters - employees and customer
        case "Branding":
            DataStringReturn = {
                pk: cid,
                sk: cid,
                DbIndex: "pk1"
            };
            break;

        //all personal under Branch - employees and customer
        case "BranchPersonal":
            DataStringReturn = {
                pk: cid,
                sk: `phone::${Id}`,
                DbIndex: "gsi1"
            };
            break;

        //list all Branch's - cid = HQId
        case "Branch":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Corp::Branch::Info::`,
                DbIndex: "pk1"
            };
            break;
               
        case "CompanyPref":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Pref::Policy`,
                DbIndex: "pk1"
            };
            break;

        case "SMSCustom":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Pref::SMS::`,
                DbIndex: "pk1"
            };
            break;

        case "SMSCompany":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Pref::SMS::`,
                DbIndex: "gsi1"
            };
            break;

        case "SMSContractor":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Pref::SMS::`,
                DbIndex: "gsi2"
            };
            break;

        case "ESC":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::ESC::Time`,
                DbIndex: "pk1"
            };
            break;

        case "PMSchedule":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::PM::Time`,
                DbIndex: "pk1"
            };
            break;

        case "ESCCompany":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::ESC::Time`,
                DbIndex: "gsi1"
            };
            break;

        case "ESCContractor":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::ESC::Time`,
                DbIndex: "gsi2"
            };
            break;

        case "ServiceCalls":
            DataStringReturn = {
                pk: "Post::" + cid,
                //sk: `Data::Company::Resource::Person::${Id}`,
                DbIndex: "gsi1"
            };
            break;
        case "AllServiceCalls":
            DataStringReturn = {
                pk: "Post::" + cid,
                //sk: `Data::Company::Resource::Person::${Id}`,
                DbIndex: "gsi2"
            };
            break;
        case "Teams":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Resource::Teams::${Id}`,
                DbIndex: "pk1"
            };
            break;
        case "Asset":
            DataStringReturn = {
                pk: cid,
                sk: "Data::Company::Asset",
                DbIndex: "pk1"
            };
            break;
        case "AssetType":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Asset::${AssetType}::${Id}`,
                DbIndex: "pk1"
            };
            break;

        case "Lambda":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::`,
                DbIndex: "pk1"
            };
            break;

        case "ContractorLambda":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::`,
                DbIndex: "gsi2"
            };
            break;

        // get list of SMS under a branch
        case "AddSMS":
            DataStringReturn = {
                pk: cid,
                sk: `phone::`,
                DbIndex: "gsi2"
            };
            break;

        // get list of SMS under a HQ
        case "HQSMS":
            DataStringReturn = {
                pk: cid,
                sk: `phone::`,
                DbIndex: "gsi1"
            };
            break;

        case "AssetHistory":
            //cid = asset id
            DataStringReturn = {
                pk: cid,
                sk: `Post::${AssetType}`,
                DbIndex: "gsi1"
            };
            break;

        case "AssetDocuments":
            //cid = asset id
            DataStringReturn = {
                pk: cid,
                sk: `Doc::`,
                DbIndex: "gsi1"
            };
            break;

        case "EmailTrigger":
            DataStringReturn = {
                pk: cid,
                sk: `EmailTrigger::`,
                DbIndex: "gsi1"
            };
            break;

        case "UrgencySetup":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Pref::SMS::${Id}`,
                DbIndex: "gsi2"
            };
            break;

        case "Online":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::`,
                DbIndex: "pk1"
            };
            break;

        case "Pref":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Pref::`,
                DbIndex: "pk1"
            };
            break;

        case "Area":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Area::`,
                DbIndex: "pk1"
            };
            break;

        case "ContactList":
            DataStringReturn = {
                pk: cid,
                sk: `Data::Online::Lambda::Contact::${cid}`,
                DbIndex: "pk1"
            };
            break;

        //list all Head Quarters under developer
        case "ListHQ":
            DataStringReturn = {
                pk: "Developer::Account::0786",
                sk: `Data::Corp::HQ::Info::${cid}`,
                DbIndex: "gsi2"
            };
            break;

        default:
            DataStringReturn = {
                pk: cid,
                sk: `Data::Company::Asset::${Category}::${Id}`,
                DbIndex: "pk1"
            };
            break;
    }

    return DataStringReturn;

}

//is object empty 
export const isNotEmptyObject = (value) => {
    return value && value.constructor === Object && Object.keys(value).length > 0;
}

//find if string is empty or null
export const isEmpty = (value) =>
    value === null || value === "" || value === undefined;

export const CategoryKeys = (Category, Option = "") => {
    try {
        let sk1 = "Data::Company::Items";
        switch (Category) {
            case "Items":
                sk1 = "Data::Company::Items::";
                break;
            case "Person":
                sk1 = "Data::Company::Person::";
                break;
            case "Company":
                sk1 = "Data::Company::Info::";
                break;
            case "PagePref":
                sk1 = `Data::Setup::Pref::${Option}`;
                break;
            case "AddCompany":
                sk1 = "Data::Setup::Pref::";
                break;
            default:
                sk1 = "Data::Company::Items::";
                break;
        }
        return sk1;
    } catch (error) {
        console.log("CategoryKeys", error, Category);
    }
};

const Paganation = async (DBB, inputtofind, nextToken, MoreData, rounds) => {
    console.log("DB.Response.Pag.rounds", rounds, MoreData);
   // console.log("DB.Response.Paganation", DBB, nextToken);
    let GoDB = PrimaryKeyData;
    switch (DBB) {
        case "PrimaryKeyData":
            GoDB = PrimaryKeyData;
            break;
        case "CompanyDataPerCategory":
            GoDB = CompanyDataPerCategory;
            break;
        case "ContractorDataPerCategory":
            GoDB = ContractorDataPerCategory;
            break;
        default:
            GoDB = PrimaryKeyData;
            break;
    }

    //...inputtofind, 

    const response = await API.graphql(graphqlOperation(
        listSvcbotDbs, { limit: 2, nextToken: nextToken }
    ));

    let newData = response.data[DBB];
    console.log("DB.Response.newData.items", newData.items);
    let AddData = [...MoreData, ...newData.items]

    if (newData) {
        if (rounds < 3 && newData.nextToken && newData.nextToken !== null) {            
            rounds = rounds + 1;            
            Paganation(DBB, inputtofind, newData.nextToken, AddData, rounds);
        } else {
            console.log("DB.Response.Pag.rounds.final", rounds);
            return AddData
        }
    }

}

//find things in pk1 and sk1
export const PrimaryKeyDataGet = async (CompanyId, Category, first = 100) => {
    try {
        const inputtofind = { pk1: CompanyId, sk1: Category };

        const response = await API.graphql(
            graphqlOperation(PrimaryKeyData, inputtofind)
        );
        console.log("DB.Response.PrimaryKeyData", inputtofind, response);

        let AdditionalData = [];
        let MoreData = []; // 
        let Datta = response.data.PrimaryKeyData.items;
        let rounds = 0;
        
        //if (response.data &&
        //    response.data.PrimaryKeyData
        //    && response.data.PrimaryKeyData.nextToken) {
        //        //AdditionalData = await 
        //    Paganation("PrimaryKeyData", inputtofind, response.data.PrimaryKeyData.nextToken, MoreData, rounds)
        //}

      //  let Totaldata = [...Datta, ...AdditionalData];

       // console.log("DB.Response.Total", Totaldata.length);

        return Datta;  // response.data.PrimaryKeyData.items;

    } catch (error) {
        console.log("PrimaryKeyData", error, CompanyId, Category);
    }
};

//find things in gsi1
export const MiddleCategories = async (CompanyId, Category, first = 100) => {
    try {
        const inputtofind = { gsi1Pk2: CompanyId, gsi1Sk2: Category, first: first };
        const response = await API.graphql(
            graphqlOperation(CompanyDataPerCategory, inputtofind)
        );
        console.log("DB.Response.CompanyDataPerCategory", inputtofind, response);
        return response.data.CompanyDataPerCategory.items;
    } catch (error) {
        console.log("CompanyDataPerCategory", error, CompanyId, Category);
    }
};

export const GetBatch = async (List) => {
    try {

        console.log("CheckInProcess.Batch.List", List)

        const inputtofind = { list: List };
        const response = await API.graphql(
            graphqlOperation(batchGet, inputtofind)
        );
       // console.log("DB.Response.batchGet", inputtofind, response);
        console.log("CheckInProcess.Batch.response", response.data.batchGet)
        return response.data.batchGet;
    } catch (error) {
        console.log("batchGet", error);
    }
};

//find things in company with category start with string
export const ContractorDataPerCat = async (ContractorId, Category) => {
    console.log("ContractorDataPerCat 1", ContractorId, Category);
    try {
        const inputtofind = { gsi2Pk2: ContractorId, gsi2Sk2: Category };
        const response = await API.graphql(
            graphqlOperation(ContractorDataPerCategory, inputtofind)
        );
        console.log("DB.Response.ContractorDataPerCat", inputtofind, response);
        return response.data.ContractorDataPerCategory.items;
    } catch (error) {
        console.log("CompanyDataPerCategory", error, ContractorId, Category);
    }
};

//find single item in pk1 and sk1
export const Pk1SingelItem = async (CompanyId, Category) => {
    try {
        const inputtofind = { pk1: CompanyId, sk1: Category };
        const response = await API.graphql(
            graphqlOperation(getSvcbotDb, inputtofind)
        );
        console.log("Pk1SingelItem", inputtofind, response.data.getSvcbotDb);
        return response.data.getSvcbotDb;
    } catch (error) {
        console.log("Pk1SingelItem", error);
    }
};

//update with pk1 and sk1 value
export const UpdateData = async (valuess, where="") => {
    try {
        console.log('UpdateData.response.where', where);
        //const inputtofind = { pk1: pk1, sk1: sk1, first: first }
        const response = await API.graphql(
            graphqlOperation(updateSvcbotDb, { input: valuess })
        );
        console.log('UpdateData.response', where, response);
        return response;
    } catch (error) {
        console.log("UpdateData.err", error, valuess);
    }
};

//find things in company with category start with string
export const CompanyDataPerCat = async (CompanyId) => {
    try {
        const inputtofind = { pk1: CompanyId };
        const response = await API.graphql(
            graphqlOperation(getFullCompanyInfo, inputtofind)
        );
        console.log("CompanyDataPerCat", CompanyId, response);
        return response.data.getFullCompanyInfo.items;
    } catch (error) {
        console.log("CompanyDataPerCat", error, CompanyId);
    }
};

//find and narrow search customer post
export const CustomerPostSearch = async (pk1, startdate, enddate) => {
    console.log("CustomerPostSearch input", pk1, startdate, enddate);
    try {
        const inputtofind = {
            pk1: pk1,
            startdate: startdate,
            enddate: enddate
        };
        const response = await API.graphql(
            graphqlOperation(CustomerPost, inputtofind)
        );
        console.log("DB.Response.pk", response);
        return response.data.CustomerPost.items;
    } catch (error) {
        console.log("CustomerPostSearch", error, pk1, startdate, enddate);
    }
};

//find and narrow search customer post
export const ContreactorPostSearch = async (gsi2Pk2, startdate, enddate) => {
    console.log("ContreactorPostSearch input", gsi2Pk2, startdate, enddate);
    try {
        const inputtofind = {
            gsi2Pk2: gsi2Pk2,
            startdate: startdate,
            enddate: enddate,
        };
        const response = await API.graphql(
            graphqlOperation(ContractorPost, inputtofind)
        );
        console.log("DB.Response.gsi2", response);
        return response.data.ContractorPost.items;
    } catch (error) {
        console.log("ContreactorPostSearch.err", error, gsi2Pk2, startdate, enddate);
    }
};

//find and narrow search customer post
export const MiddlePostSearch = async (gsi1Pk2, startdate, enddate) => {
    console.log("MiddlePost input", gsi1Pk2, startdate, enddate);
    try {
        const inputtofind = {
            gsi1Pk2: gsi1Pk2,
            startdate: startdate,
            enddate: enddate,
        };
        const response = await API.graphql(
            graphqlOperation(MiddlePost, inputtofind)
        );
        console.log("DB.Response.gsi1", response);
        return response.data.MiddlePost.items;
    } catch (error) {
        console.log("MiddlePost", error, gsi1Pk2, startdate, enddate);
    }
};

//find and narrow search customer post
export const MiddleTeamSearch = async (gsi1Pk2, enddate) => {
    console.log("MiddleTeam input", gsi1Pk2, enddate);
    try {
        const inputtofind = {
            gsi1Pk2: gsi1Pk2,
            enddate: enddate,
        };
        const response = await API.graphql(
            graphqlOperation(MiddleTeam, inputtofind)
        );
        console.log("MiddlePost", response.data.MiddleTeam.items);
        return response.data.MiddleTeam.items;
    } catch (error) {
        console.log("MiddlePost", error, gsi1Pk2, enddate);
    }
};

export const querySvcbotDbsByGsi1Pk2 = async (gsi1Pk2, first) => {
    try {
        const inputtofind = { gsi1Pk2: gsi1Pk2, first: first };
        const response = await API.graphql(
            graphqlOperation(querySvcbotDbsByGsi1Pk2Gsi1Sk2Index, inputtofind)
        );
        return response.data.querySvcbotDbsByGsi1Pk2Gsi1Sk2Index.items;
    } catch (error) {
        console.log("querySvcbotDbsByGsi1Pk2", error, gsi1Pk2, first);
    }
};

// sort
export const SortObjectArray = (AutoSelectData, find) => {

    AutoSelectData.sort((a, b) => (a[find].toLowerCase() > b[find].toLowerCase()) ? 1 : ((b[find].toLowerCase() > a[find].toLowerCase()) ? -1 : 0))

    return AutoSelectData
};

function makeid(length, Prefix) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    var final = Prefix + "_" + result;
    return final;
}

export const NewPageId = (x) => {
    try {
        const PostId = makeid(8, x);
        return PostId;
    } catch (error) {
        console.log("PostId err", error);
    }
};

function CreateContractorIdmakeid(length, Prefix) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    var final = Prefix + "_" + result;
    return final;
}

export const CreateContractorId = (x) => {
    try {
        const PostId = CreateContractorIdmakeid(6, x);
        return PostId;
    } catch (error) {
        console.log("PostId err", error);
    }
};

export const ConfirmationNumber = () => {
    var result = '';
    var characters = '123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

//remove empty from object
export function removeEmptyStringElements(obj) {
    for (var prop in obj) {
        if (typeof obj[prop] === "object") {
            // dive deeper in
            //console.log('obj', obj[prop])
            removeEmptyStringElements(obj[prop]);
        } else if (obj[prop] === "" || obj[prop] === undefined) {
            // delete elements that are empty strings

            delete obj[prop];
        }
    }
    return obj;
}

//find value (findthis) from object array with object property = ObjPropty
export function FindValueInObjectArray(findthis, ObjPropty, myArray) {
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i][ObjPropty] === findthis) {
            return myArray[i];
        }
    }
}

//find index of (findthis) from object array with object property = ObjPropty
export function FindIndexOfObjectArray(findthis, ObjPropty, myArray) {
    var index = myArray
        .map(function (e) {
            return e[ObjPropty];
        })
        .indexOf(findthis);
    //const index = myArray.findIndex(item => item[ObjPropty] === findthis);
    return index;
}

//eleminate duplicates from array
export function EleminateDuplicates(oldArray) {
    return [...new Set(oldArray)];
}

//eleminate duplicates from Object array
export function EleminateDuplicateObjects(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}


//Combine two array and eleminate duplicates
export function CombineArrayEleminateDuplicates(arry1, arry2) {
    return [...new Set([...arry1, ...arry2])];
}

//find common in two arrays
export function FindCommonInArray(arry1, arry2) {
    var index = arry1.filter((x) => arry2.includes(x));
    return index;
}

//find what is not common in two arrays
export function FindNotCommonInArray(arry1, arry2) {
    var index = arry1
        .filter((x) => !arry2.includes(x))
        .concat(arry2.filter((x) => !arry1.includes(x)));
    return index;
}

export const LoadCustomerData = async (CompanyId) => {
    let equpdata = [];
    let CustomerEsc = [];
    let EquipRawData = [];
    let CustomerLocations = [];
    let CustomerForms = [];
    let CustomerCompany = [];
    let CustomerTeam = [];
    let CompanyPrefTrade = "";
    let CompanyPref = [];
    let exdata = "";
    //console.log('LoadCustomerData companyid---', CompanyId)
    return PrimaryKeyDataGet(CompanyId, "Data::").then((res) => {
        console.log("res", res);

        if (res) {
            if (res.length > 0) {
                res.forEach((eqq) => {
                    if (eqq.sk1.indexOf("Data::Equip::") > -1) {
                        exdata = JSON.parse(eqq.dataJSON);
                        try {
                            EquipRawData.push(exdata);
                            // equpdataraw.push(exdata.EquipTag)
                            //equpdata.push(exdata.EquipTag.tag)
                        } catch (err) {
                            console.log("equpdata err", err);
                        }
                    }

                    if (eqq.sk1.indexOf("Data::Company::Location::") > -1) {
                        exdata = JSON.parse(eqq.dataJSON);
                        try {
                            CustomerLocations.push({
                                Building_Room: exdata.Building_Room,
                                Street_Address: exdata.Street_Address,
                                id: eqq.sk1,
                            });
                        } catch (err) {
                            console.log("equpdata err", err);
                        }
                    }

                    if (eqq.sk1.indexOf("Data::Team::") > -1) {
                        exdata = JSON.parse(eqq.dataJSON);
                        try {
                            CustomerTeam.push({
                                TeamName: exdata.TeamName,
                                TeamDescription: exdata.TeamDescription,
                                id: eqq.sk1,
                            });
                        } catch (err) {
                            console.log("equpdata err", err);
                        }
                    }

                    if (eqq.sk1.indexOf("Data::Pref::Esc::") > -1) {
                        exdata = JSON.parse(eqq.dataJSON);
                        try {
                            CustomerEsc.push({
                                PolicyName: exdata.PolicyName,
                                PolicyNumber: exdata.PolicyNumber,
                                id: eqq.sk1,
                            });
                        } catch (err) {
                            console.log("equpdata err", err);
                        }
                    }

                    if (eqq.sk1.indexOf("Data::Company::") > -1) {
                        exdata = JSON.parse(eqq.dataJSON);
                        try {
                            CustomerCompany.push(exdata);
                        } catch (err) {
                            console.log("equpdata err", err);
                        }
                    }

                    if (eqq.sk1.indexOf("Data::Pref::") > -1) {
                        exdata = JSON.parse(eqq.dataJSON);
                        try {
                            CompanyPref.push(exdata);
                        } catch (err) {
                            console.log("equpdata err", err);
                        }
                    }

                    if (eqq.sk1.indexOf("Data::Company::Form::") > -1) {
                        exdata = JSON.parse(eqq.dataJSON);
                        try {
                            CustomerForms.push(exdata);
                        } catch (err) {
                            console.log("equpdata err", err);
                        }
                    }
                });
            }
        }

        //console.log('Comp data', EquipRawData, CustomerLocations, CustomerForms, CustomerCompany)
        return {
            EquipRawData,
            CompanyPref,
            CustomerLocations,
            CustomerForms,
            CustomerCompany,
            CustomerEsc,
            CustomerTeam,
        };
    });
};

export function FindLatestDate(a) {
    try {
        let maxB = a.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
        if (maxB === undefined) {
            maxB = [];
        }
        console.log("FindLatestDate", maxB);
        return maxB;
    } catch (err) {
        console.log("FindLatestDate err", err);
    }
}

export const FindCategoryIcon = (Category) => {
    switch (Category) {
        case "Person":
            return <PersonIcon />
        case "AddSMS":
            return <SmsIcon />
        case "Company":
            return <CorporateFareIcon />
        case "EmailTrigger":
            return <EmailIcon />
        case "Equipment":
            return <PrecisionManufacturingIcon />
        case "Progress":
            return <LibraryAddCheckIcon />
        case "Location":
            return <LocationOnIcon />
        case "Branch":
            return <AccountTreeIcon />
        case "HQ":
            return <CorporateFareIcon />
        case "shift":
            return <CalendarMonthIcon />
        case "direction":
            return <AssistantDirectionIcon />
        case "decision":
            return <SwapHorizontalCircleIcon />
        case "Subscription":
            return <AttachEmailIcon />
        case "Documents":
            return <ArticleIcon />
        case "Policy":
            return <ArticleIcon />
        case "Image":
            return <ImageIcon />
        case "pdf":
            return <PictureAsPdfIcon />
        case "zip":
            return <FolderZipIcon />
        case "RuleIcon":
            return <RuleIcon />
        case "AssignmentIcon":
            return <AssignmentIcon />
        case "Feedback":
            return <Feedback />
        case "ThumbUpIcon":
            return <ThumbUpIcon />
        case "PanToolIcon":
            return <PanToolIcon />
        case "ShareIcon":
            return <ShareIcon />
        default:
            return <SettingsIcon />
    }
}


export function LoadPersonl(CompanyId) {
    //var PersonalListData = [];
    return PrimaryKeyDataGet(CompanyId, "Data::Company::Person::").then((res) => {
        if (res) {
            console.log("resddd -", res, CompanyId);
            if (res.length > 0) {
                let PersonalListData = [];
                res.forEach((Per) => {
                    PersonalListData.push(JSON.parse(Per.dataJSON));
                });
                return PersonalListData;
            }
        }
    });
    // .then((PersonalListData) => {
    //   console.log("ppersonal -", PersonalListData)
    //   return PersonalListData})
}



export function detectmob() {
    if (window.innerWidth <= 800 && window.innerHeight <= 600) {
        return 400;
    } else {
        return 640;
    }
}
