import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import {
    Box,
    Button,
    FormControl, InputLabel, Select, MenuItem,
    Backdrop, CircularProgress,
    TextField,
    Stack,
} from '@mui/material';
//import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
import { getAutoCompletedata } from '../utils/CommonGraphql';
//import DateRange from '../components/DateRange';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";



const Toolbar = (props) => {
    //const ContractorData = useSelector(state => state.ContractorData);
    //const [loading, SetLoading] = useState(false);
    const initialState = {
        StartDate: adjustedTime(-30, "Start"),
        EndDate: adjustedTime(1, "End"),
        Sort: "Progress",
        SMSType: "Any"
    };

    const [state, setState] = useState(initialState);
   
    useEffect(() => {

        setState((prevState) => ({
            ...prevState,
            StartDate: props.StartDate,
            EndDate: props.EndDate,
        }))

    }, [props.StartDate, props.EndDate]);

    const SelectedValues = (name, item) => {

        setState((prevState) => ({
            ...prevState,
            [name]: item,
        }))
        console.log("SelectedValues", name,  item);
        //if start date > end date = change the end date
        if (name === "StartDate") {
            if (new Date(item) > new Date(state.EndDate)) {
                setState((prevState) => ({
                    ...prevState,
                    EndDate: item,
                }))
            }

            props.SortPref(item, state.EndDate);

        } else {

            props.SortPref(state.StartDate, item);

        }
    }

    console.log("AssetId, startDate, endDate3", state);

    return (

        <Box mt={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                    direction={{ xs: 'column', lg: 'row' }}
                    spacing={2}
                >

                    <DatePicker
                        value={dayjs(state.StartDate)}
                        label="Start Date"
                        onChange={item => SelectedValues("StartDate", item)}
                        renderInput={(props) => (
                            <TextField variant="outlined" {...props} />
                        )}
                    />

                    <DatePicker
                        label="End Date"
                        value={dayjs(state.EndDate)}
                        onChange={item => SelectedValues("EndDate", item)}
                        renderInput={(props) => (
                            <TextField variant="outlined" {...props} />
                        )}
                    />


                </Stack>

            </LocalizationProvider>


        </Box>

    );
};


export default Toolbar;
