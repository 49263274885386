/*
 * 
 * get urgency id from ServiceActionList.js
 * allow rename Urgency
 * allow Ungency Copy
 * 
 * Colleps box for Steps, Progress, and ESC 
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch, createSvcbotDb } from '../graphql/mutations';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
    Paper, Grid, Button, Box,
    Backdrop,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar, FormControlLabel, Switch, TextField, Radio,
    FormControl, FormLabel, RadioGroup, List, ListItem, IconButton, ListItemText, Stack
} from '@mui/material';

import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import {
    removeEmptyStringElements,
    EleminateDuplicateObjects,
    GetBatch,
    PrimaryKeyDataGet,
    ContractorDataPerCat,
    NewPageId,
    FindLatestDate,
    getAutoCompletedata
} from '../utils/CommonGraphql';
import { SendEmail } from '../utils/SendEmail';
//import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
//import MuiAlert from '@mui/material/Alert';
//import SelectAssets from '../components/SelectAssets';
//import { Buffer } from 'buffer';
//import { DateTime } from "luxon";


const containerStyle = {
    width: '100%',
    height: '300px'
};

const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

//var currentUnixTime = new Date();
//var currentTime = currentUnixTime.toISOString();

const toISOStringWithTimezone = date => {
    //const tzOffset = -date.getTimezoneOffset();
    //const diff = tzOffset >= 0 ? '+' : '-';
    const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds())
        //diff + pad(tzOffset / 60) +
        //':' + pad(tzOffset % 60);
};

export default function SimpleCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [expanded, setExpanded] = React.useState(false);
    const [userAddress, setUserAddress] = useState(null);
    const [BranchAddress, setBranchAddress] = useState(null); 
    const [BranchInfo, setBranchInfo] = useState(null);
    const [GGPS, setGPS] = useState(null);
    const [map, setMap] = React.useState(null);
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const UserData = useSelector(state => state.UserData);
    const HQData = useSelector(state => state.HQData);
    const [loading, SetLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    
    const initialState = {
        NewId: NewPageId("CheckIn"),
        DoneDone: false
    }
    const [state, setState] = useState(initialState);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey:  process.env.REACT_APP_googleMapsApiKey
    })

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const mapRef = React.useRef();

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    useEffect(() => {
        try {

            if (CurrentCompany && CurrentCompany.CheckIn && CurrentCompany.CheckIn.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    CurrentCheckIn: CurrentCompany.CheckIn
                }))
            }

        } catch (err) { }
    }, [CurrentCompany]);

    const DatabaseData = (event) => {

        console.log("CheckInProcess.event", event);

        try {

            var currentUnixTime = new Date();
            var currentTime = toISOStringWithTimezone(currentUnixTime);
            var LocalTime = currentUnixTime.toLocaleString();
            let BranchAddress = "Branch Address"
            let Branch = "";
            let LocationLink = "";

            if (BranchInfo !== null && BranchInfo.field1 !== "") {
                Branch = BranchInfo.field1;
                BranchAddress = BranchInfo.field2;
            }

            if (GGPS && GGPS !== null && GGPS.gpslink) {
                LocationLink = GGPS.gpslink
            }

            

            let field1 = `${event}- ${props.CompanyName} - ${Branch}`;
            let field2 = `${LocalTime} - ${UserData.field1} - ${UserData.CompanyName}`;
            let Subject = `${event} - ${LocalTime} - ${UserData.field1} - ${UserData.CompanyName}`;
            let Searchwords = `${event} ${LocalTime} \n${UserData.field1} ${UserData.CompanyName} \nCompany: ${props.CompanyName} \nLocation: ${Branch} \nAddress: ${BranchAddress} \nMessage: ${state.Message} \nMapit: ${LocationLink}`;
            let postHTML = `<p>${event} - ${UserData.field1} ${UserData.CompanyName} </p><p>${LocalTime} <br/>Company - ${props.CompanyName}<br/>Location - ${Branch}<br/>Address - ${BranchAddress}<br/>Message - ${state.Message}`;
            //<br/>Mapit - ${LocationLink}
            let NewEvent = {
                Branch: Branch,
                BranchInfo: BranchInfo,
                LocationLink: LocationLink,
                BranchAddress: BranchAddress,
                Searchwords: Searchwords,
                Subject: Subject,
                postHTML: postHTML,
                Message: state.Message,
                currentTime: LocalTime,
                PostId: state.NewId,
                Action: event,
                field1: field1,
                field2: field2,
                pk1: `CheckIn::${UserData.sk1}`,
                sk1: currentTime,
                gsi1Pk2: `CheckIn::${props.CompanyId}`,
                gsi1Sk2: currentTime,
                gsi2Pk2: `CheckIn::${ContractorData.CompanyId}`,
                gsi2Sk2: currentTime,
            }

            const inputthiss = {
                pk1: `CheckIn::${UserData.sk1}`,
                sk1: currentTime,
                gsi1Pk2: `CheckIn::${props.CompanyId}`,
                gsi1Sk2: currentTime,
                gsi2Pk2: `CheckIn::${ContractorData.CompanyId}`,
                gsi2Sk2: currentTime,
                dataJSON: JSON.stringify(removeEmptyStringElements(NewEvent)),
            };

            console.log("CheckInProcess.NewEvent", NewEvent)

            PersonnelUpdate(NewEvent)

            API.graphql(graphqlOperation(createSvcbotDb, { input: inputthiss }))

        } catch (err) {
            console.error("error from api", err);
        }
    };

    const PersonnelUpdate = (NewEvent) => {

        let PersonBatch = [];
        let PersonalFound = [];
        if (BranchInfo !== null && BranchInfo.StepPersonalList.length > 0) {
            BranchInfo.StepPersonalList.map((eachComp, indCom) => {
                if (eachComp.Personnel && eachComp.Personnel.length > 0) {

                    PersonalFound = [...PersonalFound, ...eachComp.Personnel];
                    eachComp.Personnel.map((eachPerson, indPer) => {

                        if (eachPerson.pk1 && eachPerson.pk1) {
                            PersonBatch.push({
                                pk1: eachPerson.pk1,
                                sk1: eachPerson.sk1,
                            })
                        }
                    })
                }
            })
        }

        if (PersonalFound.length > 0 && PersonBatch.length > 0) {
            GetBatchs(NewEvent, PersonalFound, PersonBatch)
        }

    }

    const GetBatchs = async (NewEvent, PersonalFound, PersonBatch) => {
        let FreshData = [];
        let dataJson = {};
        let FreshPeson = await GetBatch(PersonBatch);
        let NewFresh = await FreshPeson;
        console.log("CheckInProcess.FreshPeson", FreshPeson)

        if (NewFresh && NewFresh.length > 0) {

            NewFresh.map(each => {
                dataJson = JSON.parse(each.dataJSON)
                FreshData.push(dataJson)
            })

            SendingNotificationLambda(NewEvent, PersonalFound, FreshData)
        }
    }

    
    const SendingNotificationLambda = (event, PersonData, FreshPeson) => {
        try {
            
            var EmailList = []; 
            var SMSList = []; 
            var PhoneList = [];
            let receiveEmail = true;
            let receiveSMS = true;
            let receivePhone = true;
            var PersonDataFound = [];
            let emailPersonList = [];
            let SMSPersonList = [];
            
                       
            PersonData.map(pperson => {
                PersonDataFound = FreshPeson.find(val => val.sk1 === pperson.sk1);
                console.log("CheckInProcess.PersonDataFound", pperson, PersonDataFound)
                if (PersonDataFound) {
                    
                    receiveEmail = true;
                    if (PersonDataFound.receiveEmail !== undefined && PersonDataFound.receiveEmail === false) {
                        receiveEmail = false;
                    }

                    receiveSMS = true;
                    if (PersonDataFound.receiveSMS !== undefined && PersonDataFound.receiveSMS === false) {
                        receiveSMS = false;
                    }

                    receivePhone = true;
                    if (PersonDataFound.receivePhone !== undefined && PersonDataFound.receivePhone === false) {
                        receivePhone = false;
                    }                    

                    if (pperson.emailcheck && receiveEmail) {
                            EmailList.push(PersonDataFound);
                    }

                    if (pperson.smscheck && receiveSMS) {
                            SMSList.push(PersonDataFound);
                    }

                    if (pperson.phonecheck && receivePhone) {
                        PhoneList.push(PersonDataFound);
                    }

                }

            });

            //PhoneList = [
            //    {
            //        "mobile": "+15627601914",
            //        "phone": "+15627601914",
            //        "PersonId": "Person::1bcece30-2097-11ea-b0e5-edc93eb2eb6f",
            //        "PersonName": "First Person"
            //    }
            //]

            //email
            if (EmailList.length > 0) {

                console.log(`CheckInProcess.message.Email:`, EmailList);
                let PersonList = EleminateDuplicateObjects(EmailList, "email")
                PersonList.map(each => {
                    emailPersonList.push(each.email)
                })

               // emailPersonList = ["ali_rehan@msn.com", "ali.rehan.jci@gmail.com"];
                
               SendingEmail(event, emailPersonList);
                
            }

            //SMS 
            if (SMSList.length > 0) {

                console.log(`CheckInProcess.message.SMS:`, SMSList);

                let SSSMSList = EleminateDuplicateObjects(EmailList, "mobile")
                SSSMSList.map(each => {
                    SMSPersonList.push(each.mobile)
                })

               // SMSPersonList = ["+15627601914"];
               
                let SMSstring = {
                    Body: event.Searchwords,
                    From: "+15623936334",
                    To: SMSPersonList,
                    mediaUrl: [],
                };

                console.log("CheckInProcess.Send.SMS", SMSstring)

                API.post("SMStoTwilio", "", {
                    body: { Records: SMSstring }
                });
                
            }

            //phone
            if (PhoneList.length > 0) {          
                
                let PhoneLisst = EleminateDuplicateObjects(PhoneList, "mobile")

                let testdata = {
                    "title": event.Subject,
                    "FromSMS": "+15622491800",
                    "PostId": event.PostId,
                    "pk1": `Post::${event.PostId}`,
                    "UpdateDb": "No",
                    "CompanyName": props.CompanyName,
                    "ContractorName": ContractorData.CompanyName,
                    "ContractorPhone": "+12565783219",
                    "CompanyId": props.CompanyId,
                    "ContractorId": ContractorData.CompanyId,
                    "PhoneList": PhoneLisst
                }

                console.log(`CheckInProcess.message.Phone:`, testdata);

                API.post("PinPhone", "/PinPhone", { body: { Records: testdata }});
               
            }

            setState(prevState => ({
                ...prevState,
                DoneDone: true
            }));


        } catch (err) { console.log("PersonSplit.map err", err); }
    };// end SendingNotificationLambda

    const SendingEmail = (event, PersonList) => {

        var emailstring = {

            PhotoService: [],
            Documents: [],
            SpecialInstructions: state.Message,
            PreSelect: [],
            title: event.Subject,
            postHTML: event.postHTML,
            postTEXT: event.Searchwords,
            PersonList: PersonList,
            ReplyEmail: `${ContractorData.CompanyName} <no-reply@service-call-cloud.com>`, //UserData.email,
            PostId: state.NewId,
            ContractorAddress: ContractorData.Address,
            ContractorPhone: ContractorData.phone,
            ContractorLogo: HQData.avatar,
            ContractorName: ContractorData.CompanyName,
            ContractorId: ContractorData.CompanyId,
            CompanyId: "",
            avatar: HQData.avatar,
            UnsubscribeLink: `https://${HQData.SubDomain}/Unsubscribe`,
            ActionButton: GGPS.gpslink,
            ActionButtonTitle: "See Location",
            ActionButtonShow: true,
        };

        console.log("CheckInProcess.Send.SendEmail", emailstring)
        SendEmail(emailstring);    

    }

    const handleNext = () => {
        
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ClosingDrawer = (e) => {
        console.log("ClosingDrawer", e)
        SetDeawerOpen(false)
    }
       
    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }


    const CheckRequestor = () => {
        if (selectedValue && selectedValue !== null) {
            handleNext();
        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please select Location",
                OpenAlert: true
            }));

        }
    }

    const BranchLocation = (LocationId) => {

        let LocationObj = state.CurrentCheckIn.find(x => x.sk1 === LocationId)
        console.log("CheckInProcess.Location", LocationObj);

            if (LocationObj) {
                setBranchInfo(LocationObj);
                if (LocationObj.Location && LocationObj.Location.lat) {
                    setBranchAddress({ lat: LocationObj.Location.lat, lng: LocationObj.Location.lng });
                    GetGPS();
                }
            }

    }

    const GetGPS = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            let gpslink = `http://www.google.com/maps/place/${latitude},${longitude}`;

            let Location = {
                "lng": longitude,
                "lat": latitude
            }

            setGPS({ Location: Location, gpslink: gpslink })
            setUserAddress({ lat: latitude, lng: longitude })
        });
    }

    const handleChange = (event) => {
        let LocationId = event.target.value;
        console.log("CheckInProcess.Value", LocationId);

        BranchLocation(LocationId);

        setSelectedValue(LocationId);
    };

    

    console.log("CheckInProcess.state", state);

    return (
        <Paper id={state.id} key={state.UUid} sx={{ p: 1 }}>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography>{state.StatusMessage}</Typography>
                </Grid>

                <Grid item xs={12}>

                    <Stepper activeStep={activeStep} orientation="vertical">

                        <Step key={1}>
                            <StepLabel>Location</StepLabel>
                            <StepContent>
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Typography >Please select Location</Typography>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <FormControl variant="outlined" style={{ width: '100%' }} >
                                            <FormLabel component="legend">{props.title}</FormLabel>
                                            <RadioGroup
                                                aria-label="Select Location"
                                                name="RadioSelect"
                                                // error={props.ErrorMessage}
                                                value={selectedValue}
                                                required={true}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                <List sx={{ width: '100%' }}>
                                                    {state.CurrentCheckIn &&
                                                        state.CurrentCheckIn.map((each, ii) => (

                                                            <ListItem
                                                                key={each.sk1 + ii + 2}
                                                                disablePadding
                                                            >

                                                                <FormControlLabel
                                                                    id={each.sk1 + ii}
                                                                    value={each.sk1}
                                                                    control={<Radio />}
                                                                />

                                                                <ListItemText
                                                                    id={each.sk1 + ii + ii}
                                                                    primary={each.field1}
                                                                    secondary={each.field2}
                                                                />

                                                            </ListItem>
                                                        ))}
                                                </List>
                                            </RadioGroup>
                                        </FormControl >

                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                            //  className={classes.button}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => CheckRequestor()}
                                            //  className={classes.button}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>



                        <Step key={2}>
                            <StepLabel>Message (optional)</StepLabel>
                            <StepContent>

                                <div>
                                    <Box sx={{ mt: 1, mb: 1 }}>
                                        <TextField
                                            label="Message (optional)"
                                            name="Message"
                                            value={state.Message || ""}
                                            multiline
                                            minRows={3}
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => SetValues(e.target.value, "Message")}
                                        />
                                    </Box>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        //  className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => CheckRequestor()}
                                        //  className={classes.button}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>


                        <Step key={3}>
                            <StepLabel>Check-In / Check-Out</StepLabel>
                            <StepContent>
                                <Stack
                                    direction={{ xs: 'column' }}
                                    spacing={2}
                                >
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        variant="outlined"
                                    //  className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => DatabaseData("Checking In")}
                                    >
                                        Check In (Arrived)
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() => DatabaseData("Checking Out")}
                                    >
                                        Check Out (Leaving)
                                    </Button>

                                </Stack>
                            </StepContent>
                        </Step>

                    </Stepper>
                </Grid>

                {(BranchAddress !== null || userAddress !== null) && isLoaded ? (
                    <>
                    <Grid item xs={12} >

                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={BranchAddress}
                            zoom={12}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={options}
                            onLoad={onMapLoad}
                        >

                            {userAddress !== null ? (
                            <Marker
                                key="you"
                                    position={userAddress}
                                    label={{
                                        text: "U",
                                        color: "#fff",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                />
                            ) : null}


                            {BranchAddress !== null ? (
                            <Marker
                                key="Branch"
                                    position={BranchAddress}
                                    label={{
                                        text: "C",
                                        color: "#fff",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                />
                            ) : null}

                        </GoogleMap>

                    </Grid>

                    <Grid item xs={12} >
                            <Typography >U = Your Location</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography >C = Customer Location</Typography>
                        </Grid>
                    </>
                ) : null}

            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            


        </Paper>
    );
}
