import React, { useState, useEffect } from 'react';
import {
    NewPageId,
} from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import {
    Grid,
    Backdrop, Box,
    CircularProgress,
    Card, CardHeader, Button, CardContent,
    TextField,
    Typography,
} from '@mui/material';
import ListModule from '../List/ListModule';
import DrawerShow from '../utils/DrawerModule';
import PaymentSystem from '../Payment/PaypalModule4';
import { SendEmail } from '../utils/SendEmail';
import {
    removeEmptyStringElements,
} from '../utils/CommonGraphql';

var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
});

const dynamodb = new AWS.DynamoDB.DocumentClient();


const UUid = NewPageId("shwdtl");

export default function SimpleCard(props) {

    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Data: [],
        id: UUid,
        ShowEditButton: true,

        Email: "",
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("HistoryPerson.props1", props)

        Processdata(props.SREvent)

        setState(prevState => ({
            ...prevState,
            Email: props.sk
        }));


    }, [props.SREvent])

    const Processdata = data => {
        let NewData = [];
        data.map(each => {
            NewData.push({
                ...each.dataJSON
            })
        })

        setState(prevState => ({
            ...prevState,
            AssetData: NewData,
        }))
    }

    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Service History",
            // SetupSubscription: true,
            DetailComponent: "SRDetailsPkSk",
            ServiceData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        SetDeawerOpen(true);
    }


    const CloseDrawer = () => {
        SetDeawerOpen(false)
    }

    const SetValues = (item, name) => {

        setState({
            ...state,
            [name]: item
        });

    };

    const ConfirmPayment = details => {

        let currency_code = details.purchase_units[0].amount.currency_code;
        let amount_paid = details.purchase_units[0].amount.value;

        let postHTML = `<h1>Amount Paid: ${amount_paid} ${currency_code}</h1>`;
        postHTML += `<table>`;
        let postTEXT = "Expense Report\n\n";
        postTEXT += `Amount Paid: ${amount_paid} ${currency_code}\n\n`;
        let newString = "";
        let Replace = "";
        let BackData = {};
        props.DataBack.map((each, indd) => {
            postHTML += `<tr align="left"><th >${indd + 1}</th><th >${each.field1} - ${each.field2}</th></tr>`;
            postTEXT += `${indd + 1} - ${each.field1} - ${each.field2}\n`
            newString = JSON.stringify(each);
            Replace = newString.replace(/::Due/g, "::Paid");
            BackData = JSON.parse(Replace);
            UpdateDB(BackData)
        })

        postHTML += "</table>";

        EmailNotification(postHTML, postTEXT)
    }

    const UpdateDB = (event) => {
        try {
            console.log(`Loginpage.UpdateDB`, event);
            var params = {

                Key: {
                    'pk1': event.pk1,
                    'sk1': event.sk1,
                },
                ExpressionAttributeNames: {
                    "#gsi1Pk2": "gsi1Pk2",
                    "#gsi1Sk2": "gsi1Sk2",
                    "#gsi2Pk2": "gsi2Pk2",
                    "#gsi2Sk2": "gsi2Sk2",
                    "#dataJSON": "dataJSON"
                },
                TableName: "svcbot_db",
                UpdateExpression: "set #gsi1Pk2 = :gsi1Pk2, #gsi1Sk2 = :gsi1Sk2, #gsi2Pk2 = :gsi2Pk2, #gsi2Sk2 = :gsi2Sk2, #dataJSON = :dataJSON",
                ExpressionAttributeValues: {
                    ":gsi1Pk2": event.gsi1Pk2,
                    ":gsi1Sk2": currentTime,
                    ":gsi2Pk2": event.gsi2Pk2,
                    ":gsi2Sk2": currentTime,
                    ":dataJSON": removeEmptyStringElements(event)
                },
            };

            dynamodb.update(params, function (err, data) {
                if (err) {
                    //callback(err);
                    console.log("Loginpage.UpdateDB.err:", err);
                } else {
                    //callback(null, data);
                    console.log(`Loginpage.UpdateDB.success`);
                }
            });

        } catch (err) {
            console.log('PutInDB err', err);
        }

    }; // end PutInDB

    const EmailNotification = (postHTML, postTEXT) => {

        let title = `Expense Receipt`;
        let PersonList = [state.Email];

        let ContractorSubDomain = "https://app.ContractorCheckIn.com/";

        let SendEvent = {
            SpecialInstructions: "",
            PreSelect: [],
            title: title,
            postHTML: postHTML,
            postTEXT: postTEXT,
            PersonList: PersonList,
            ReplyEmail: "support@sninc.us",
            PostId: "expense",
            ContractorAddress: "30 N Gould St, Suite 37310, Sheridan, WY 82801",
            ContractorPhone: "",
            ContractorLogo: "",
            ContractorName: "ContractorCheckIn",
            avatar: "",
            UnsubscribeLink: `${ContractorSubDomain}/Unsubscribe`,
            ActionButton: `${ContractorSubDomain}`,
            ActionButtonTitle: "Log In",
            ActionButtonShow: false
        }

        console.log("SubscriptionAdd1.SendEmail", SendEvent)

        SendEmail(SendEvent);

    }

    console.log("HistoryPerson.state", state)

    return (
        <Box mt={2}>

            <Card>
                <CardHeader
                    title="Payment"
                    subheader="Expense Report Receipt"
                />
                <CardContent>
                    <Grid container spacing={2} >
                        {props.Amount && props.Amount > 0 ? (
                            <Grid xs={12} item>
                                <PaymentSystem
                                    Amount={props.Amount}
                                    ConfirmPayment={e => ConfirmPayment(e)}
                                />
                            </Grid>
                        ) : null}

                        <Grid xs={12} item>
                            <Typography variant="h4" gutterBottom>
                                Expense Report
                            </Typography>
                            <Typography gutterBottom>
                                Upon completion of the payment, the system will send you an email receipt for your expense report. Please confirm your email below:
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                name="Email"
                                value={state.Email || ""}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => SetValues(e.target.value, "Email")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ListModule
                                //  AddBox={(add) => AddBox(add)}
                                title="Expense Report"
                                ShowDetailLink={state.ShowEditButton}
                                disabled={false}
                                DetailLink={(e) => AddBox(e)}
                                DataBack={state.AssetData}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>


            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: state.Category,
                    CloseDrawer: (e) => CloseDrawer(),
                    Data: state.ServiceData
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </Box>
    );
}
