import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
    Snackbar, FormControl, InputLabel, Select, MenuItem,
    TextField, Stack, Typography,
    Backdrop, CircularProgress,
} from '@mui/material';
import { CountryList } from '../Twilio/countries';
import { FindNumber } from '../Twilio/AvailablePhoneNumber';
import ListRadio from '../List/ListRadio';


export default function RadioButtonsGroup(props) {
    const initialState = {
        FormData: [],
        id: "",
        KeyId: "",
        loading: false,
        AlarmMessage: "",
        OpenAlert: false,
        CountryCode: "us",
        Message: "",
        AreaCode: "",
        NumberList: [],
        SelectedNumber: "",
    }

    const [state, setState] = useState(initialState);
    const [loading, SetLoading] = useState(false);

    useEffect(() => {

        let StepValueArray = {};

        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);

            setState(prevState => ({
                ...prevState,
                id: props.id,
                Category: props.Category,
                StepValueArray: StepValueArray,
                KeyId: props.KeyId,
                title: props.title,
                loading: true,
            }));
        }

    }, [props.StepValueArray])



    const handleNext = () => {

        let AlarmMessage = "";
        let OpenAlert = false;

        if (state.SelectedNumber === "") {
            OpenAlert = true;
            AlarmMessage += " * Please a Select Number ";
        } //


        if (AlarmMessage !== "") {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: true
            }));

        } else {

            //let SelectedDataReturn = {
            //    ...state,
            //    id: props.id,
            //    KeyId: props.KeyId
            //}
            //console.log("SignUpWizStep.Next", SelectedDataReturn)
           

            if (props.handleNext) {
                props.handleNext();
            }
        }

    }
    

    const ProcessNumbers = async () => {

        let AlarmMessage = "";
        let OpenAlert = false;

        if (state.CountryCode === "") {
            OpenAlert = true;
            AlarmMessage += " * Please Select Country ";
        }
        
        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: OpenAlert,
            }));

        } else {
            SetLoading(true)
            let CountryCode = state.CountryCode.toUpperCase();

            let NumberList = await FindNumber(state.AreaCode, CountryCode);
            console.log("SignUpWizStep.FormData.Filled", NumberList)

            ProcessNewNumbers(NumberList)
            SetLoading(false)
        }

    }

    const ProcessNewNumbers = (list) => {

        let NumberList = [];
        let Message = "";
        if (list && list.length > 0) {
            list.map(each => {
                NumberList.push({
                    field1: each.friendlyName,
                    //  field2: each.postalCode,
                    key: each.phoneNumber
                })
            })
        } else {
            Message = "No numbers found"
        }

        setState(prevState => ({
            ...prevState,
            NumberList: NumberList,
            Message: Message
        }));
    }

    const SetValues = (e, name) => {

        if (props.SelectedNumber && name === "SelectedNumber") {
            props.SelectedNumber(e)
        }

        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };

    console.log("SignUpWizStep.state", state)
    console.log("SignUpWizStep.props", props)

    return (
        <div key={props.KeyId}>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Stack
                        direction={{ xs: 'column', lg: 'row' }}
                        spacing={2}
                    >

                        <FormControl variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Select Country
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                required={true}
                                sx={{ width: 300 }}
                                autoWidth={true}
                                value={state.CountryCode || "us"}
                                onChange={(e) => {
                                    SetValues(e.target.value, "CountryCode");
                                }}
                                label="Select Country"
                            >

                                {CountryList.map((o, indd) => (
                                    <MenuItem key={indd} value={o.alpha2}>
                                        {o.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl >

                        <TextField
                            disabled={props.disabled}
                            //  fullWidth
                            onChange={(e) => SetValues(e.target.value, "AreaCode")}
                            value={state.AreaCode || ""}
                            // placeholder={`Area Code`}
                            label="Area Code"
                            variant="outlined"
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            disabled={state.readOnly}
                            onClick={() => ProcessNumbers()}
                        >
                            Find Numbers
                        </Button>

                    </Stack>

                </Grid>
                <Grid item xs={12}>
                    {state.NumberList && state.NumberList.length > 0 ? (

                        <ListRadio
                            // AddBox={(add) => AddBox(add)}
                            title="Available Numbers"
                            SearchWords=""
                            ShowEdit={false}
                            disabled={false}
                            SelectedKeyArray={(e) => SetValues(e, "SelectedNumber")}
                            //  defaultValue={state.SelectedArrayValues[each.mobile] !== "" ? state.SelectedArrayValues[each.mobile] : ""}
                            DetailLink={(e) => console.log(e)}
                            DataBack={state.NumberList}
                        />

                    ) : (
                        <>
                            <Typography >
                                Please select Country. Not all area codes are available
                            </Typography>
                            {state.Message !== "" ? (
                                <>
                                        <br />
                                        <Typography variant="h4" gutterBottom> {state.Message} </Typography>
                                </>
                            ) : null}
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Backdrop
                //  className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            

        </div>

    );
}
