import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
//import { makeStyles, useTheme } from '@mui/material/styles';
//import { makeStyles, useTheme, ThemeProvider } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
//import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import RenderModule from '../utils/RenderModule';
//import TextField from '@mui/material/TextField';
//import SearchIcon from '@mui/icons-material/Search';
//import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { NewPageId } from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import {
    Grid
} from '@mui/material';
import HelpBox from '../components/HelpBox';

const Newpageiddd = NewPageId("page")

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function FullWidthTabs(props) {
    // const classes = useStyles();
    let query = useQuery();
    const tabb = query.get("tabb");
    const ShowHelp = useSelector(state => state.ShowHelp);
    // const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const initialState = {
        SearchWords: "",
        ShiftShow: [],  //shift data from PersonCalendarBox going to CalendarBox
        PersonalReload: false,
        MenuHeader: "Sub"
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        setValue(0);

        let MenuHeader = "Sub";
        if (props.MenuHeader && props.MenuHeader !== "") {
            MenuHeader = props.MenuHeader.substring(0, 20);
            setState(prevState => ({
                ...prevState,
                MenuHeader: MenuHeader
            }));
        }

    }, [props.id])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        console.log('CompanyPage.Small.Menu', index);
        setValue(index);
        setAnchorElNav(null);
    };

    const handleOpenNavMenu = (event) => {
        console.log('CompanyPage.Small.Menu', event.currentTarget);
        setAnchorElNav(event.currentTarget);
    };

    //const handleSearchWords = (event) => {
    //    setState(prevState => ({ ...prevState, SearchWords: event.target.value }))
    //};

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const CommonState = (item) => {

        setState(prevState => ({
            ...prevState,
            [item.itemname]: item.itemvalue
        }));

    }

    console.log('CompanyPage.Tabs.value', value);
    console.log('CompanyPage.Tabs.query', tabb);
    console.log("CompanyPage.Tabs.props", props);
    console.log('CompanyPage.Tabs.state1', state);

    return (
        <div
            key={props.id}
        >

            <AppBar position="static" color="primary">

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"

                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                        }}
                    >
                        {props.TabList.map((page, indd) => (
                            <MenuItem key={indd + "mmenn"} onClick={() => handleChangeIndex(indd)}>
                                <Typography textAlign="center">{page.label}</Typography>
                            </MenuItem>
                        ))}

                    </Menu>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ m: 1.5, flexGrow: 1, display: { xs: 'block', lg: 'none' } }}
                    >
                        {`${state.MenuHeader} Menu` }
                    </Typography>


                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        allowScrollButtonsMobile
                    >
                        {props.TabList.map((list, indexx) => (
                            <Tab key={"tabss" + indexx} label={list.label} {...a11yProps(indexx)} />
                        ))}
                    </Tabs>


                </Box>


            </AppBar>
            <SwipeableViews

                axis={'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {props.TabList.map((tbb, inddx) => (
                    <TabPanel value={value} index={inddx} key={inddx + "tabs"} dir={'ltr'}>
                        <Grid container spacing={1} >
                            {ShowHelp && tbb.HelpBox && (
                                <Grid item xs={2} key={Newpageiddd + "help" + inddx} >
                                    <HelpBox
                                        title={tbb.HelpBox.title}
                                        description={tbb.HelpBox.description}
                                        ShowVideo={tbb.HelpBox.ShowVideo}
                                        VideoId={tbb.HelpBox.VideoId}
                                        ShowWords={true}
                                        key={Newpageiddd + "help2" + inddx}
                                        id={Newpageiddd + "help3" + inddx}
                                    />
                                </Grid>
                            )
                            }
                            {tbb.propss.PopulatePage.map((each, indd) => (
                                <Grid item xs={12} lg={each.size} key={Newpageiddd + "grd" + indd} >
                                    {each.Category && (<RenderModule
                                        key={Newpageiddd + "page" + indd}
                                        id={Newpageiddd + "idd" + indd}
                                        SearchWords={state.SearchWords}
                                        PersonalReload={state.PersonalReload}
                                        ShiftShow={state.ShiftShow}
                                        PutinCommonState={(e) => CommonState(e)}
                                        Category={each.Category}
                                        {...each}
                                    />)}
                                </Grid>
                            ))}
                        </Grid>


                    </TabPanel>
                ))}
            </SwipeableViews>

        </div>
    );
}
