/*
 * 
 * Shows company ESC options
 * check the boxes for each.  this is what will be used in triggering ESC
 * 
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import Accordion from '../components/Accordion';
import {
    //TextField,
    //Collapse,
    //Paper,
    Grid, Button,
    Backdrop,
    CircularProgress,
    Snackbar,
    FormGroup,
    FormControlLabel,
    Switch,
    //Card, FormGroup,
    //CardContent,
    //CardActions,
    //InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';
import {
    removeEmptyStringElements,
    NewPageId,
    EleminateDuplicateObjects,
    getAutoCompletedata
} from '../utils/CommonGraphql';
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import { ServiceEsc } from '../temp/ESCTeams';
import MuiAlert from '@mui/material/Alert';
//import Page from '../components/Page';

const UUid = NewPageId("Urgency");

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//const useStyles = makeStyles((theme) => ({
//  root: {
//    // backgroundColor: theme.palette.background.dark,
//    //minHeight: '100%',
//    padding: theme.spacing(2),
//  },
//  paper: {
//    padding: theme.spacing(2),
//    color: theme.palette.text.secondary,
//  },
//}));



export default function SimpleCard(props) {
    // const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        //title: "",
        TotalProgress: [],
        ShowCompany: true,
        ShowContractor: false,
        UseCustomESC: false,
        ContractorESC: [],
        CompanyESCDefault: [],
        DrawerTitle: "On-Call Rotation Shift Setup",
        CompanyESC: [],
        TotalESC: [],
        ESC: [],
      //  NoNotification: false,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        GetDatafromDB("ESC", props.CompanyId, "Company");

        //if (props && props.StepValueArray) {
        //  setState(prevState => ({
        //    ...prevState,
        //    ...props.StepValueArray[props.id],
        //  }));
        //}

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
            /// }
            console.log("Priority.StepValueArray", StepValueArray)
            //if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }


    }, [])

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const handleNext = () => {

        let NewData = [];
        //let Proceed = true;
        //let CompanyESCDefault = state.CompanyESCDefault;
        //if (state.CompanyESCDefault && state.CompanyESCDefault.length > 0) {
        //    Proceed = true;
        //    NewData = MakeDB(state.CompanyESCDefault, props.CompanyId);
        //    setState(prevState => ({
        //        ...prevState,
        //        AlarmMessage: "",
        //        OpenAlert: false
        //    }));

        //} else {
        //    if (state.NoNotification) {
        //        Proceed = true;
        //        CompanyESCDefault = [];
        //        NewData = [];
        //    } else {
        //        Proceed = false;
        //    }
        //}

        //if (Proceed) {

        if (state.CompanyESCDefault && state.CompanyESCDefault.length > 0) {
            NewData = MakeDB(state.CompanyESCDefault, props.CompanyId);
        }

            let SelectedDataReturn = {
                UseCustomESC: state.UseCustomESC,
                CompanyESC: NewData,
                CompanyESCDefault: state.CompanyESCDefault,
               // NoNotification: state.NoNotification,
                id: props.id,
                KeyId: props.KeyId
            }
            //console.log("SMSListQuestions.key", SelectedDataReturn)
            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedDataReturn)
            }

            if (props.handleNext) {
                props.handleNext();
            }
        //} else {

        //    setState(prevState => ({
        //        ...prevState,
        //        AlarmMessage: "Please select Policies",
        //        OpenAlert: true
        //    }));

        //}

    }


    //const NewValues = (CompanyESC) => {

    //  let NewData = [];
    //  if (CompanyESC && CompanyESC.length > 0) {
    //    NewData = MakeDB(CompanyESC, props.CompanyId);
    //  }

    //  let SelectedDataReturn = {
    //    CompanyESC: NewData,
    //    CompanyESCDefault: CompanyESC,
    //    id: props.id
    //  }

    //  if (props.SelectedDataReturn) {
    //    props.SelectedDataReturn(SelectedDataReturn)
    //  }

    //}

    //make it DB pk1/sk1
    const MakeDB = (sk1array, pk1) => {
        let CompanyDB = [];
        sk1array.forEach(sk1 => {
            CompanyDB.push({
                sk1: sk1,
                pk1: pk1
            })
        })
        return CompanyDB;
    }

    const GetDatafromDB = async (Category, CompanyId, ArrayName) => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId);
        setState(prevState => ({ ...prevState, ...NewData }))

        SetLoading(false);
        console.log("UrgencyDetails.NewData", NewData);
    }

    const CloseDrawer = () => {
        GetDatafromDB("ESC", props.CompanyId, "Company");
        SetDeawerOpen(false)
    }



    const Additem = (DetailComponent, Category, CompanyId, CompanyName) => {
        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            ShowDetails: false,
            DetailsProps: {
                Category: Category,
                CompanyId: CompanyId,
                CompanyName: CompanyName,
                CloseDrawer: () => CloseDrawer()
            }
        }))
        SetDeawerOpen(true);
    }


    const KeyArray = (data, cat) => {
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));

        //  NewValues(data)
    }

    const Switchvalue = (data, cat) => {
        let newdata = data.target.checked
        //let CompanyESCDefault = state.CompanyESCDefault;        

        //if (!newdata) {
        //    CompanyESCDefault = [];
        //}

        //console.log("ESCContractorDefault.data", newdata)

        //console.log("ESCContractorDefault.data2", CompanyESCDefault);

        setState(prevState => ({
            ...prevState,
            [cat]: newdata,
          //  CompanyESCDefault: CompanyESCDefault
        }));

        // NewValues(newdata, state.ManagerShift);
    }

    console.log("ESCContractorDefault.state", state);
    console.log("ESCContractorDefault.props", props);

    return (
        <div
        //  className={classes.paper}
         id={props.NewId}
        // key={"key" + props.NewId}
        >

            <Grid container spacing={2}>

                {/* 
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            disabled={state.Priority === "Emergency"}
                            checked={state.NoNotification || ""}
                            onChange={(e) => Switchvalue(e, "NoNotification")}
                        />} label="No Notification Triggers (Used for to-do list option)" />
                    </FormGroup>
                </Grid>
                */}

                <Grid item xs={12}>
                    <ShowCheckBoxGroup
                        field1={props.CompanyName}
                        field2="On-Call Rotation Shift List"
                        disabled={false}
                        AllowDelete={false}
                        DeleteItem={(e) => console.log("DeleteItem", e)}
                        AllowEdit={false}
                        EditItem={(e) => console.log("EditItem", e)}
                        AllowAdd={true}
                        AddItem={() => Additem("ESCSetupPage", "ESC", props.CompanyId, props.CompanyName)}
                        Data={state.Company}
                        ShowUpDown={false}
                        ShowEdit={false}
                        AddBox={(e) => console.log("EditItem", e)}
                        DefaultChecked={state.CompanyESCDefault || []}
                        NextLoadComponenet="ESCSetupPage"
                        Category="ESC"
                        KeyArray={(e) => KeyArray(e, "CompanyESCDefault")}
                        PerPage={5}
                        id={"CompanyESC"}

                    />
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}
            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => SetDeawerOpen(false)}
                DrawerTitle={state.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => CloseDrawer(e),
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </div>
    );
}
