import Chart from 'react-apexcharts';
import React, { useState, useEffect } from "react";
import {
    Box,
    Card, CardHeader, Divider, Grid,
    Backdrop, CircularProgress, IconButton,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
    NewPageId,
    MiddlePostSearch,
    ContreactorPostSearch
} from '../utils/CommonGraphql';
import { adjustedTime } from '../utils/DateTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';


let olddate = adjustedTime(-90, "Start");
let Todaydatestring = adjustedTime(2, "End"); //Todaydate.toISOString();

const ProductChannel = (props) => {
    const [loading, SetLoading] = useState(false);
    const NowEditing = useSelector(state => state.NowEditing);
    const initialState = {
        NewId: "92929383",
        days: 90,
        AllowCompanyPage: true
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        GetDatafromDB(props.CompanyId, olddate, Todaydatestring)

        setState(prevState => ({
            ...prevState,
            NewId: NewPageId("chart"),
            AllowCompanyPage: props.AllowCompanyPage
        }))

        console.log("Charts.Props", props);

    }, [props.CompanyId])

    const GetDatafromDB = async (pk1, startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let ZeroBucket = 0;
        let ProgressBucket = 0;
        let CompletedBucket = 0;
        let AcceptedBucket = 0;
       // console.log("SRPage.DBinput", NowEditing, pk1, startDate, endDate);

        //if (NowEditing === "Contractor") {
        //    NewData = await ContreactorPostSearch("Post::Progress::" + pk1, startDate, endDate);
        // } else {
        NewData = await MiddlePostSearch("Post::Progress::" + pk1, startDate, endDate);
        // }

        console.log("Charts.ShowString.NewData1", NewData);
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);

                if (ShowString.Progress === 0) {
                    ZeroBucket += 1;
                    // console.log("Charts.ShowString.prog.0", ZeroBucket);
                } else if (ShowString.Progress > 0 && ShowString.Progress < 99) {
                    ProgressBucket += 1;
                    // console.log("Charts.ShowString.prog.50", ProgressBucket);
                } else if (ShowString.Progress === 100) {
                    CompletedBucket += 1;
                    // console.log("Charts.ShowString.prog.100", CompletedBucket);
                }

                if (ShowString.status === "Accepted") {
                    AcceptedBucket += 1;
                    console.log("Charts.ShowString.prog.>100", AcceptedBucket);
                }

                console.log("Charts.ShowString.Progress", ShowString.Progress, ZeroBucket);
            })
        }

        CreateChartOptions(ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket)


        SetLoading(false);
        // console.log("SRPage.NewData2", AutoSelectData);
    }

    const CreateChartOptions = (ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket) => {

        var options = {
            chart: {
                height: 300,
                width: 300,
                type: "bar",
                stacked: false,
                foreColor: "#333",
                background: 'transparent',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ["#FF1654", "#247BA0"],
            series: [
                {
                    name: "Work Progress",
                    data: [ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket]
                },
            ],
            stroke: {
                width: [4, 4]
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                categories: ["Call Received", "In Progress", "Completed", "Accepted"]
            },
            title: {
                text: "Progress",
                align: "center",
                margin: 5,
                offsetY: 20,
                style: {
                    fontSize: "25px"
                }
            },
        };

        let series = [
            {
                name: "Work Progress",
                data: [ZeroBucket, ProgressBucket, CompletedBucket, AcceptedBucket]
            },
        ];

        setState(prevState => ({
            ...prevState,
            options: options,
            series: series
        }))

    }

    const ChangeLength = (days) => {

        let Starttt = adjustedTime(-1 * days, "Start");
        GetDatafromDB(props.CompanyId, Starttt, Todaydatestring)
        setState(prevState => ({
            ...prevState,
            days: days
        }))

    }

    const CompanyPage = (data) => {
        if (props.DetailLink) {
            props.DetailLink(data)
        }
    }



    console.log("Charts.state", state);
    // console.log("Charts.state.options", options);

    return state.options && (
        <div key={state.NewId}>
            <Card
                variant="outlined"
                sx={{ height: '100%' }}

            >
                <CardHeader
                    title={props.CompanyName}
                    subheader={props.BranchName}
                    action={state.AllowCompanyPage && (
                        <IconButton aria-label="settings"
                            onClick={() => CompanyPage(props.SR)} size="large">
                            <MoreVertIcon />
                        </IconButton>
                    )}
                />
                <Divider />
                <Grid
                    alignItems="center"
                    container
                    sx={{
                        justifyContent: {
                            xs: 'center'
                        },
                        py: 1
                    }}
                >
                    <Grid item>
                        <FormControl variant="outlined" >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Duration
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                defaultValue={90}
                                //  error={state.ErrorMessage}
                                required={true}
                                //  disabled={state.disabled}
                                autoWidth={true}
                                value={state.days}
                                onChange={(e) => {
                                    ChangeLength(e.target.value);
                                }}
                                label="Duration"
                            >

                                <MenuItem key={0} value={30}>
                                    Last 30 days
                                </MenuItem>
                                <MenuItem key={1} value={60}>
                                    Last 60 days
                                </MenuItem>
                                <MenuItem key={2} value={90}>
                                    Last 90 days
                                </MenuItem>
                            </Select>
                        </FormControl >
                    </Grid>
                    <Grid item>
                        <Chart
                            options={state.options}
                            series={state.series}
                            type="bar"
                            width={350}
                        />
                    </Grid>

                </Grid>
            </Card>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ProductChannel;