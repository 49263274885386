import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Storage } from "aws-amplify";
import clsx from 'clsx';
//import moment from 'moment';
import {
    Avatar,
    Box,
    Button,
    Card,
    Grid,
    CardActions,
    LinearProgress,
    CardContent,
    Divider,
    Snackbar,

} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import aws_exports from '../aws-exports'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    city: 'Los Angeles',
    country: 'USA',
    jobTitle: 'Senior Developer',
    name: 'Katarina Smith',
    timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        height: 100,
        width: 100
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    input: {
        display: 'none',
    }
}));

function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
}

const Profile = ({ className, ...rest }) => {
    const classes = useStyles();
   // const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    let initialState = {
        AlartMessage: "",
        open: false,
        loadings: 0,
        ImageFile: "",
        newfile: false
    };

    const [state, setState] = useState(initialState);

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            AlartMessage: "",
            open: false,
        }))
    };

    useEffect(() => {

        console.log("fileUploadedarr.props", rest);

        if (rest.DefaultImage) {
            setState((prevState) => ({
                ...prevState,
                ImageFile: rest.DefaultImage
            }))
        }

    }, [rest.DefaultImage]);

    const onChangeHandler = (event) => {
        try {
            var filetypes = 0;
            var fileUploadedarr = {};
            let AlarmMessage = "";
            const fileUploaded = event.target.files[0];
            fileUploadedarr.name = fileUploaded.name;
            console.log("fileUploadedarr", fileUploadedarr);
            var ext = getExtension(fileUploaded.name);

            switch (ext.toLowerCase()) {
                case "jpg":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "gif":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "bmp":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                case "png":
                    filetypes = 4;
                    fileUploadedarr.blob = URL.createObjectURL(event.target.files[0]);
                    break;
                default:
                    AlarmMessage += "* Please Upload Image File ";
            }

            if (fileUploaded.size > 5 * 1048576) {
                AlarmMessage += "* File is Too Larger ";
            }

            if (filetypes !== 4) {
                AlarmMessage += "* Only Image File allowed ";
            }

            if (AlarmMessage !== "") {
                setState((prevState) => ({
                    ...prevState,
                    open: true, AlartMessage: AlarmMessage
                }))
                return;

            }

            const cleanfile_name = fileUploaded.name.replace(/[^a-zA-Z0-9._]/g, "");

            
            fileUploadedarr.dir = `public/`;
            fileUploadedarr.Uploaddir = "/";
            fileUploadedarr.filetypes = filetypes;

            const file_name = `${Date.now()}-${cleanfile_name}`;
            const file_name_dir = file_name;
            fileUploadedarr.filename = file_name;
            fileUploadedarr.thum = "thum_" + file_name;
            fileUploadedarr.width = 100;
            fileUploadedarr.type = fileUploaded.type;

            fileUploadedarr.ImageFullPath = `https://s3.amazonaws.com/${HQData.Bucket}/public/${file_name}`
               // `https://${HQData.Bucket}.s3.amazonaws.com/public/${file_name}`

            console.log("fileUploadedarr.final", fileUploadedarr);
            Storage.put(file_name_dir, fileUploaded, {
                bucket: HQData.Bucket,
                progressCallback: (progress) => {
                    handleprocessbar(progress);
                },
            }).then((ret) => {

                console.log("fileUploadedarr.final.return", ret);

                fileUploadedarr.inbucket = "public/" + ret.key;
            });

            setState((prevState) => ({
                ...prevState,
                ImageFile: fileUploadedarr,
                newfile: true
            }))

            rest.ImagePic(fileUploadedarr);

        } catch (err) { console.log("Image err", err); }
    };

    const handleprocessbar = (process) => {
        let loadiss = Math.round((process.loaded / process.total) * 100);
        setState(prevState => ({ ...prevState, loadings: loadiss }));
    }

    const ShowAvatar = ImageFile => {
        if (!state.newfile && ImageFile.ImageFullPath && checkURL(ImageFile.ImageFullPath.toLowerCase())) {
            return (<Avatar alt="Logo"
                src={ImageFile.ImageFullPath}
                className={classes.avatar} />)
        } else if (state.newfile && ImageFile.blob !== "") {
            return (<Avatar alt="Logo"
                src={ImageFile.blob}
                className={classes.avatar} />)
        } else {
            return (<Avatar alt="Logo" className={classes.avatar} >
                <AccountCircleIcon fontSize="large" /></Avatar>)
        }
    }

    function checkURL(url) {
        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    console.log("fileUploadedarr.state", state);

    return (
        <>
            <Card
                className={clsx(classes.root, className)}

            >
                <CardContent>
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                    >

                        {ShowAvatar(state.ImageFile)}

                    </Box>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid xs={12} item>
                            <input
                                className={classes.input}
                                id="contained-button-file"
                                //multiple
                                accept={rest.AcceptFileType}
                                //"image/*"
                                type="file"
                                onChange={onChangeHandler}
                            />
                            <label htmlFor="contained-button-file">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    variant="text"
                                    style={{ justifyContent: "center" }}
                                    startIcon={<AccountCircleIcon />}
                                >
                                    Upload Profile Picture / Logo
                                </Button>
                            </label>
                        </Grid>
                        <Grid xs={12} item>
                            <LinearProgress variant="determinate" value={state.loadings} />
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={state.open}
                onClose={handleClose}
                message={state.AlartMessage}
                key="w87482ows"
            />
        </>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
