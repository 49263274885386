/*
 * 
 * Demo
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Container, Card, CardHeader, TextField, CardContent, Typography
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { ServiceBlock, CheckInBlock } from './DemoInputEachRole';
import { useParams } from 'react-router-dom';
import { API } from "aws-amplify";
import GoogleGetGPSAuto from '../views/modules/GoogleGetGPSAuto';
import { useSelector } from 'react-redux';
//const NewBlock = CheckInBlock;

let BackUpServiceBlock = JSON.parse(JSON.stringify(ServiceBlock));
let BackUpCheckInBlock = JSON.parse(JSON.stringify(CheckInBlock));

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

const PutQuestionsInDB = async (event) => {
    try {
        console.log(`PutQuestionsInDB`, event);
        var params = {

            Key: {
                'pk1': event.pk1,
                'sk1': event.sk1,
            },

            TableName: "svcbot_db",
            UpdateExpression: "set dataJSON = :dataJSON, gsi1Pk2 = :gsi1Pk2, gsi2Sk2 = :gsi2Sk2",
            ExpressionAttributeValues: {
                ":dataJSON": event.dataJSON,
                ":gsi1Pk2": event.gsi1Pk2,
                ":gsi2Sk2": event.gsi2Sk2,
            },
        };

        dynamodb.update(params, function (err, data) {
            if (err) {
                console.log(`Database info err: ${err}`);
            } else {
                console.log(`Database info success`, event.gsi2Sk2);
            }
        });

    } catch (err) {
        console.log('PutInDB err', err);
    }

}; // end PutInDB

export default function SimpleCard() {

    const params = useParams();
    console.log('LandingPage.params', params);
    const version = useSelector(state => state.version);

    const initialState = {
        Block: [],
        Updated: "",
        Location: {},
        ResetFlag: true,
    }
    const [state, setState] = useState(initialState);
    
    useEffect(() => {

        let type = "service";
        let Block = ServiceBlock;
        let BackUpBlock = BackUpServiceBlock;
        let WebsiteType = "ServiceWithText"
                
        if (params.type) {
            type = params.type
        }
        
        if (type === "checkin") {
            Block = CheckInBlock;
            BackUpBlock = BackUpCheckInBlock;
            WebsiteType = "ContractorCheckIn"
        }
        
        setState(prevState => ({
            ...prevState,
            type: type,
            Block: Block,
            BackUpBlock: BackUpBlock,
            WebsiteType: WebsiteType
        }));

    }, [params.type])

    const SaveData = () => {

        try {

            state.Block.map((block) => {
                PutQuestionsInDB(block)
            })

            setState(prevState => ({
                ...prevState,
                Updated: "System Updated"
            }));

            if (!state.ResetFlag) {
                SendSMS();
            }

        } catch (err) {
            console.log("ContractorSignup.SaveData", err)
        }
    }
       
    const AddValue = (item, Indexx, position, pro) => {

        console.log("ContractorSignup.1AddValue", item, Indexx, position, pro)

        let Block = state.Block;
        let Techemail = "";
        let Owneremail = "";

        if (pro === "email") {
            Block[Indexx].dataJSON.email = item;
        }

        if (pro === "mobile") {

            Block[Indexx].dataJSON.mobile = item;
            Block[Indexx].dataJSON.phone = item;
            Block[Indexx].dataJSON.Location = state.Location;
            Block[Indexx].gsi1Pk2 = `phone::${state.WebsiteType}::${item}`;

            if (position === "sub") {
                setState(prevState => ({
                    ...prevState,
                    Techmobile: item,
                    ResetFlag: false,
                }));
            }

            if (position === "owner") {
                setState(prevState => ({
                    ...prevState,
                    Ownermobile: item,
                    ResetFlag: false,
                }));
            }

        } 
        
        setState(prevState => ({
            ...prevState,
            Block: Block
        }));

    }

    const SendSMS = () => {
               
        let EndMessage = "Please send a text message to following number. The text message can contain a request for service. \n\n(951) 499-0960"
        let Messageto = state.Ownermobile
        let FromSMS = "+19514990960";
        let sendmessage = false;
        
        if (state.type === "checkin" && state.Techmobile !== "+15555555555") {

            EndMessage = "Please send a text message to following number and simply say that you have arrived at the job-site. \n\n(562) 444-1785"
            EndMessage += "\n\nTest the Service Checkin system\n\n(301) 679-7643"
            Messageto = state.Techmobile
            FromSMS = "+15624441785"
            sendmessage = true;
        }

        if (state.type === "service" && state.Ownermobile !== "+15555555555") {
            sendmessage = true;
        }

        console.log("ContractorSignup.1send", sendmessage, state.Ownermobile)

        if (sendmessage && Messageto) {

            let SMSstring = {
                Body: EndMessage,
                FromSMS: FromSMS,
                From: FromSMS,
                To: [Messageto],
                mediaUrl: [],
                pk1: "",
                CompanyId: ""
            };

            console.log("ItemAddPage.Invite.SMS", SMSstring)

            API.post("SMStoTwilio", "", {
                body: { Records: SMSstring }
            });

        }

    }

    const SetCoordinates = (gps) => {

        if (gps && gps.gpslink && gps.Location) {

            console.log("SelectAssetBox.gps", gps)

            let Body = gps.gpslink
            if (state.Location_Description !== "") {
                Body = `${state.Location_Description}, ${gps.gpslink}`
            }

            setState(prevState => ({
                ...prevState,
                Location: gps.Location,
                gpslink: gps.gpslink,
                Body: Body,
                gps: gps
            }));
        }
    }

    const Reset = () => {
       
        setState(prevState => ({
            ...prevState,
            Block: state.BackUpBlock,
            ResetFlag: true,
            Updated: "Ready for Reset, click on Save Data"
        }));
    }

    console.log("ContractorSignup.1state", state)    

    return state.Block && (

        <Container maxWidth="sm">

            <Grid container spacing={4}>

                {state.Block.map((block, index) => (
                   
                        <Grid key={`ggr-${index}`} item xs={12}>
                         
                            <Card key={block.dataJSON.id}>
                                <CardHeader
                                    title={block.dataJSON.field1}
                                />

                                <CardContent>
                                <Grid container spacing={2}>
                                    {/* 
                                        <Grid item xs={12}>

                                            <TextField
                                                label="Email"
                                                name="Email"
                                                key={`email-${block.dataJSON.id}`}
                                                id={`emm-${block.dataJSON.id}`}
                                                value={block.dataJSON.email || ""}
                                                fullWidth
                                                variant="outlined"
                                                onChange={(e) => AddValue(e.target.value, index, "email")}
                                            />

                                    </Grid>
                                        */}

                                        <Grid item xs={12}>

                                            <PhoneInput
                                                country={'us'}
                                                key={`phone-${block.dataJSON.id}`}
                                                id={`phoo-${block.dataJSON.id}`}
                                                value={block.dataJSON.mobile || ""}
                                                // disabled={state.disabled}
                                                label="Mobile"
                                                inputProps={{
                                                    name: "Mobile",
                                                    label: "Mobile",
                                                    style: { width: '100%' },
                                                }}
                                                isValid={(value, country) => {
                                                    if (value.match(/12345/)) {
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                            onChange={(e) => AddValue('+' + e, index, block.position, "mobile")}
                                            />

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>
                    
                ))}

                {state.type === "service" ? (
                <Grid item xs={12} >
                    <GoogleGetGPSAuto
                        GPSfound={(GPS) => SetCoordinates(GPS)}
                    />
                </Grid>
                ) : null} 

                <Grid item xs={12} >
                    <Typography gutterBottom>
                        {version}
                    </Typography>
                    
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => SaveData()}
                                sx={{ mr: 2}}
                            //  className={classes.button}
                            >
                                Save Data
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => Reset()}
                            //  className={classes.button}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom>
                        {state.Updated}
                    </Typography>
                </Grid>

            </Grid>

        </Container>
    );
}
