import React, { useState, useEffect } from 'react';
//import makeStyles from '@mui/styles/makeStyles';
import {
    TextField,
    Button, Stepper, Step, StepContent, StepLabel,
    //Grid,
    Card,
    CardContent,
    Typography,
    Snackbar,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';
import { CSVLink } from "react-csv";



const ListOptions = [
    {
        field1: "Person Name",
        ver: "Name",
        VariableInput: false,
        key: 0
    },
    {
        field1: "Company",
        ver: "Company",
        VariableInput: false,
        key: 1
    },
    {
        field1: "Person Email",
        ver: "Email",
        VariableInput: false,
        key: 2
    },
    {
        field1: "Person Mobile",
        ver: "Mobile",
        VariableInput: false,
        key: 3
    },
    {
        field1: "Shift Start Date",
        ver: "Date",
        VariableInput: false,
        key: 7
    },
    {
        field1: "Check in time",
        ver: "Check_in_time",
        VariableInput: false,
        key: 4
    },
    {
        field1: "Check out time",
        ver: "Check_out_time",
        VariableInput: false,
        key: 5
    },
    {
        field1: "Hours worked",
        ver: "Hours_worked",
        VariableInput: false,
        key: 6
    },
    {
        field1: "Shift Notes",
        ver: "Notes",
        VariableInput: false,
        key: 8
    },
]


export default function SimpleCard(props) {
    
    //const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    //const [expanded, setExpanded] = React.useState(false);

    let neww = new Date();
    let UTCTume = new Date(neww).toISOString();

    const [activeStep, setActiveStep] = useState(0); //
    const [csvData, setcsvData] = useState([]);
    const [utc, setutc] = useState(UTCTume);

    const initialState = {
        Fields: ListOptions,
        flag: false,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("SMSAddEdit.props", props)
        
    }, [])

    const handleClose = (event, reason) => {
        // handle close of Alert

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const handleNext = (verr) => {

        let flag = false;

        state.Fields.map(each => {
            if (each.VariableInput) {
                flag = true;
            }
        })

        if (!flag) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: ` * Please select Fields`,
                OpenAlert: true
            }));

        } else {

            RunData()
            setActiveStep((prevActiveStep) => prevActiveStep + 1);

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };    

    const RunData = () => {

        let SelectedData = props.SelectedItems;
        let FullData = props.DataBack;
        let SelectedItem = {};
        let NewObj = {};
        let NewArr = [];

        if (state.Fields.length > 0) {
            
            SelectedData.map(eachselected => {
                SelectedItem = FullData.find(x => x.sk1 === eachselected);

                state.Fields.map(eachfield => {
                    if (eachfield.VariableInput) {
                        switch (eachfield.ver) {
                            case "Name":
                                NewObj[eachfield.ver] = SelectedItem.posterId.field1;
                                break;
                            case "Company":
                                NewObj[eachfield.ver] = SelectedItem.posterId.CompanyName;
                                break;
                            case "Email":
                                NewObj[eachfield.ver] = SelectedItem.posterId.email;
                                break;
                            case "Mobile":
                                NewObj[eachfield.ver] = SelectedItem.posterId.mobile;
                                break;
                            case "Date":
                                NewObj[eachfield.ver] = SelectedItem.ShowDate;
                                break;
                            case "Check_in_time":
                                NewObj[eachfield.ver] = SelectedItem.CheckInTime;
                                break;
                            case "Check_out_time":
                                NewObj[eachfield.ver] = SelectedItem.CheckOutTime;
                                break;
                            case "Hours_worked":
                                NewObj[eachfield.ver] = SelectedItem.HoursWorked;
                                break;
                            case "Notes":
                                NewObj[eachfield.ver] = SelectedItem.postTEXT;
                                break;
                            default:
                        }
                    }
                })

                NewArr.push(NewObj);
                NewObj = {};                
            })

            console.log("SelectStepBox.NewArr", NewArr);

            setcsvData(NewArr)

        }

    }


    const SelectedValues = (item, name) => {

        setState({
            ...state,
            [name]: item.target.value,
        });

    };

    const ESCReturn = data => {
        console.log("SelectStepBox.ESCReturn", data);
        setState(prevState => ({
            ...prevState,
            ESC: data
        }))
    }

    const KeyArray = (SMSQuestions, cat) => {
        console.log("SMSListQuestions.KeyArray", SMSQuestions, cat)
       
        setState(prevState => ({
            ...prevState,
            [cat]: SMSQuestions,
        }));

        //  NewValues(state.UseCustomSMS, SMSQuestions)

    }

    console.log("SMSAddEdit.state", state);
    return (
        <>

            <Stepper activeStep={activeStep} orientation="vertical">

                <Step key={10}>
                    <StepLabel>Select Fields</StepLabel>
                    <StepContent>

                        <Typography>
                        Please select the fields.  Use up/down arrows to change order
                        </Typography>

                        <ListCheckboxMoveUpDown
                            Data={state.Fields}
                            key={"key2sakakla"}
                            title="Select Fields"
                            SearchWords=""
                            ShowUpDown={true}
                            ShowEdit={false}
                            AddBox={(e) => console.log(e)}
                            // DefaultChecked={state.SMSQuestions || []}
                            NextLoadComponenet="AddSetupSelection"
                            Category="SMS"
                            FullJSONBack={(e) => KeyArray(e, "Fields")}
                        />

                        <div >
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext("Fields")}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>

                <Step key={0}>
                    <StepLabel>DownLoad Data</StepLabel>
                    <StepContent>
                    
                        {csvData && csvData.length > 0 ? (
                            <Card>
                                <CardContent>
                                    <CSVLink
                                        data={csvData}
                                        filename={`Data_${utc}.csv`}
                                        className="btn btn-primary"
                                        onClick={() => props.CloseDrawer()}
                                    >
                               Click Here to Download Data
                                    </CSVLink>
                                </CardContent>
                            </Card>
                        ):null }
                        
                        <div >
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}                                    
                                >
                                    Back
                                </Button>
                               
                            </div>
                        </div>

                    </StepContent>
                </Step>

            </Stepper>
            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

        </>
    );
}
