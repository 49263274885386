/*
 * 
 * Shows both contractor and company ESC options
 * check the boxes for each.  this is what will be used in triggering ESC
 * 
 * Props:
 * 
 * {
      title: "Notification / Escalation Policy",
      message: "this is the message for this module 2",
      LoadComponenet: "ESCSelectContractorAndCompany",
      AddBox: (e) => AddBox(e),
      ShowAdd: false,
      DetailComponent: "ESCSetupPage",
      Additem: (add) => Additem(add),
      propss: {
        Data: "",
        CompanyId: props.CompanyId,
        CompanyName: props.CompanyName,
        SelectedDataReturn: (e) => ESCReturn(e),
        SearchWords: "",
        ShowEdit: true,

      }
    },
 * 
 */


import React, { useState, useEffect } from 'react';
//import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import Accordion from '../components/Accordion';
import {
  TextField,
  Collapse,
  Paper, Grid, Button,
  Backdrop, 
  CircularProgress,
  Card, FormGroup,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
import {
  removeEmptyStringElements,
  NewPageId,
  EleminateDuplicateObjects,
  getAutoCompletedata
} from '../utils/CommonGraphql';
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import { ServiceEsc } from '../temp/ESCTeams';
//import Page from '../components/Page';

const UUid = NewPageId("Urgency");

//const useStyles = makeStyles((theme) => ({
//  root: {
//    // backgroundColor: theme.palette.background.dark,
//    //minHeight: '100%',
//    padding: theme.spacing(2),
//  },
//  paper: {
//    padding: theme.spacing(2),
//    color: theme.palette.text.secondary,
//  },
//}));



export default function SimpleCard(props) {
 // const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  const [loading, SetLoading] = useState(false);
  const initialState = {
    //title: "",
    TotalProgress: [],
    ShowCompany: true,
    ShowContractor: false,
    UseCustomESC: false,
    ContractorESC: [],
    DrawerTitle: "On-Call Rotation Shift Setup",
    CompanyESC: [],
    TotalESC: [],
    ESC: [],
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {
    
    console.log("ESCSelectcontactoandcompany.props", props)

    // get contractor ESC
    GetDatafromDB("ESC", ContractorData.ContractorId, "Contractor");

    //get company ESC
    if (props.CompanyId) {
      GetDatafromDB("ESC", props.CompanyId, "Company");
    }

    //determin Contractor?
    let ShowContractor = false;
    let ShowCompany = true;
    if (UserData.CustomerType === "Contractor") {
      ShowContractor = true;

      // do not show company box when contractor is editing Defaults
      if (props.CompanyId === ContractorData.ContractorId) {
        ShowCompany = false;
      }
    }

    setState(prevState => ({
      ...prevState,
      ShowContractor: ShowContractor,
      ShowCompany: ShowCompany
    }))

    //let LocalTime = new Date();
    //FindApplicableTeams(ServiceEsc)
   
  }, [])

  //useEffect(() => {

  //  let SelectedDataReturn = {
  //    UseCustomESCESC: state.UseCustomESC,
  //    CompanyESC: MakeDB(state.CompanyESC, props.CompanyId),
  //    ContractorESC: MakeDB(state.ContractorESC, ContractorData.ContractorId)
  //  }

  //  if (props.SelectedDataReturn) {
  //    props.SelectedDataReturn(SelectedDataReturn)
  //  }

  //}, [state.ContractorESC, state.CompanyESC, state.UseCustomESC])

  const NewValues = (UseCustomESC, CompanyESC, ContractorESC) => {

    let SelectedDataReturn = {
      UseCustomESC: UseCustomESC,
      CompanyESC: MakeDB(CompanyESC, props.CompanyId),
      ContractorESC: MakeDB(ContractorESC, ContractorData.ContractorId),
      id: props.id
    }
    console.log("SMSListQuestions.key", SelectedDataReturn)
    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(SelectedDataReturn)
    }

  }

  //make it DB pk1/sk1
  const MakeDB = (sk1array, pk1) => {
    let CompanyDB = [];
    sk1array.forEach(sk1 => {
      CompanyDB.push({
        sk1: sk1,
        pk1: pk1
      })
    })
    return CompanyDB;
  }

  const GetDatafromDB = async (Category, CompanyId, ArrayName) => {
    SetLoading(true);

    const DataString = {
      Category: Category,
      FindArray: [
        {
          ArrayName: ArrayName,
          ArraySearchString: ":",
        },
      ]
    };

    let NewData = await getAutoCompletedata(DataString, CompanyId);
    setState(prevState => ({ ...prevState, ...NewData }))

    SetLoading(false);
    console.log("UrgencyDetails.NewData", NewData);
  }

  

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  
  const CloseDrawer = () => {
    
    GetDatafromDB("ESC", ContractorData.ContractorId, "Contractor");

    if (props.CompanyId) {
      GetDatafromDB("ESC", props.CompanyId, "Company");
    }

    SetDeawerOpen(false)
  }



  const Additem = (DetailComponent, Category, CompanyId, CompanyName) => {
    setState(prevState => ({
      ...prevState,
      DetailComponent: DetailComponent,
      ShowDetails: false,
      DetailsProps: {
        Category: Category,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        CloseDrawer: () => CloseDrawer()
      }
    }))
    SetDeawerOpen(true);
  }

  
  //const KeyArray = (data, cat) => {
  //  console.log("KeyArray", data, cat)
  //  setState(prevState => ({
  //    ...prevState,
  //    [cat]: data
  //  }));    

  //  if (cat === "CompanyESC") {
  //    NewValues(state.UseCustomESC, data, state.ContractorESC)
  //  }

  //  if (cat === "ContractorESC") {
  //    NewValues(state.UseCustomESC, state.CompanyESC, data)
  //  }
  //}

  const handleChange = (event, name) => {
    let UseCustomESC = event.target.checked;
    setState({
      ...state,
      [name]: UseCustomESC,
    });

    NewValues(UseCustomESC, state.CompanyESC, state.ContractorESC)
  };

  const KeyArray = (data, cat) => {

    let UseCustomESC = data.target.value;
    let ShowContractor = false;

    switch (UseCustomESC) {
      case "Default":
        ShowContractor = false;
        break;
      case "Custom":
        ShowContractor = true;
        break;
      case "None":
        ShowContractor = false;
        break;
      default:
        ShowContractor = false;
    }

    setState(prevState => ({
      ...prevState,
      ShowContractor: ShowContractor,
      [cat]: UseCustomESC
    }))

    NewValues(UseCustomESC, state.CompanyESC, state.ContractorESC)

  }


  //const FindApplicableTeams = (ServiceEsc, event = "") => {
  //  //const FindDayWeekMonth = (ServiceEsc, event) => {

  //    try {

  //      let PersonLocal = {
  //        "offset": "-7:00",
  //        "name": "America/Los_Angeles",
  //        "label": "Pacific Time"
  //      }

  //      //event.PersonData.Local;  // todo - insure Local is availabel

  //      let offset = 0;
  //      //let ServiceUrgencySelected = [];
  //      //let TradeServicesarr = [];

  //      //get trade and urgency keys
  //      //if (event.ServiceUrgency && event.ServiceUrgency.length > 0) {
  //      //  event.ServiceUrgency.forEach(ee => {
  //      //    ServiceUrgencySelected.push(ee.key);
  //      //  });
  //      //}
  //      //if (event.ServiceTrade && event.ServiceTrade.length > 0) {
  //      //  event.ServiceTrade.forEach(ee => {
  //      //    TradeServicesarr.push(ee.key);
  //      //  });
  //      //}


  //      if (PersonLocal && PersonLocal.offset) {
  //        offset = parseFloat(PersonLocal.offset);
  //      }

  //      var nows = new Date();
  //      var mppp = nows.getTime();
  //      var localOffset = offset * 60 * 60 * 1000;

  //      var LocalTime = new Date(mppp + localOffset);

  //      //console.log('LocalTime', JSON.stringify(LocalTime, null, 2));
  //      //console.log('nows', JSON.stringify(nows, null, 2));

  //      var month = LocalTime.getMonth() + 1;
  //      var date = LocalTime.getDate();   //dateToday.getDate()
  //      var WeekOfMonth = Math.ceil(date / 7);
  //      var day = LocalTime.getDay() + 1;
  //      var year = LocalTime.getFullYear();

  //      let TodayDate = `${year}-${month}-${date}`

  //      var hours = LocalTime.getHours();
  //      var mins = LocalTime.getMinutes();

  //      var CurrentTime2 = `${hours}.${mins}`;
  //      var CurrentTime = parseFloat(CurrentTime2);
  //      console.log('FindDayWeekMonth.CurrentTime', CurrentTime, hours, mins);

  //      let eachEsc = "";

  //      ServiceEsc.map((each) => {
  //        eachEsc = each.dataJSON;
  //        let policcy = eachEsc.sk1;

  //        console.log('FindDayWeekMonth.start', eachEsc);

  //        let StartTime = parseFloat(eachEsc.StartTime.replace(":", "."));
  //        let EndTime = parseFloat(eachEsc.EndTime.replace(":", "."));


  //        let IfTimeWithInSchedule = false;
  //        console.log('FindDayWeekMonth.regular day', StartTime, EndTime, CurrentTime, day, WeekOfMonth);
  //        // regular day schedule
  //        if (EndTime > StartTime) {
  //          if (CurrentTime > StartTime && CurrentTime < EndTime) {
  //            IfTimeWithInSchedule = true;
  //            console.log('FindDayWeekMonth.regular day', StartTime, EndTime, IfTimeWithInSchedule);
  //          }
  //        } else {
  //          //third shift
  //          //current time before mightnight?
  //          if (CurrentTime > StartTime && CurrentTime < 23.59) {
  //            //use todays day of the week
  //            IfTimeWithInSchedule = true;
  //            console.log('FindDayWeekMonth.3rd shift same day', StartTime, EndTime, IfTimeWithInSchedule);
  //          }

  //          if (CurrentTime < EndTime && CurrentTime < 12.00) {
  //            //this is 3rd shift - use day before
  //            day = day - 1;
  //            //adjust week of the month
  //            if (day === 0) {
  //              day = 7;
  //              WeekOfMonth = WeekOfMonth - 1;
  //            }
  //            IfTimeWithInSchedule = true;
  //            console.log('FindDayWeekMonth.3rd shift next day', StartTime, EndTime, IfTimeWithInSchedule);
  //          }
  //        }

          
  //        let FindDay = eachEsc.Days.includes(day);
  //        console.log('FindDayWeekMonth.FindDay', eachEsc.Days, day);
  //        let FindMonth = eachEsc.MonthsOfYear.includes(month);
  //        console.log('FindDayWeekMonth.FindMonth', eachEsc.MonthsOfYear, month);
  //        let FindWeekOfMonth = eachEsc.WeekOfMonth.includes(WeekOfMonth);
  //        console.log('FindDayWeekMonth.FindWeekOfMonth', eachEsc.WeekOfMonth, WeekOfMonth);

  //        // Select the Policy
  //        if (FindWeekOfMonth &&
  //          IfTimeWithInSchedule &&
  //          FindMonth &&
  //          FindDay) {
  //          console.log('FindDayWeekMonth.Result', eachEsc);
  //          //SelctedEscPolicy.push(eachEsc);
  //         // FindTeamsPerESC(eachEsc, event, LocalTime, TodayDate, policcy);
  //        }
  //      });

  //      //GetTeamandPersonal(SelctedEscPolicy, event, LocalTime);
  //      //PutData(PersonInfo, ContractorData, event, SelctedEscPolicy, check);
  //    } catch (err) { console.log("FindDayWeekMonth.err", err) }
  ////  };
  //}

 
  return (
      <div
        //  className={classes.paper} id={props.NewId} key={"key" + props.NewId}
      >

      <Grid container spacing={2}>

        {props.showcustom && (
        <Grid item xs={12}>

            {/*   
          <FormGroup>
            <FormControlLabel
              control={<Switch
              onClick={() => handleChange(event, "UseCustomESC")}
              value={state.UseCustomESC || ""} />}
              label="Use following Custom Notifications" />
            </FormGroup>
            */}

            <FormControl component="fieldset">

              <RadioGroup
                aria-label="Notification Selection"
                defaultValue="Default"
                name="radio-buttons-group"
                //onChange={(e) => KeyArray(e, "Priority")}
                onChange={(data) => KeyArray(data, "UseCustomESC")}
                value={state.UseCustomESC || ""}
              >

                <FormControlLabel value="Default" control={<Radio />} label={`Use all ${ContractorData.ContractorName} Default Notification Policies`} />

                <FormControlLabel value="Custom" control={<Radio />} label={`Only following selected 
${ContractorData.ContractorName} Notification Policies`} />

                <FormControlLabel value="None" control={<Radio />} label={`No ${ContractorData.ContractorName} Notification Policies`} />

              </RadioGroup>
            </FormControl>

            
          </Grid>
        )}

        {state.ShowContractor && (
        <Grid item xs={12}>
          <ShowCheckBoxGroup
            field1={ContractorData.ContractorName}
              field2="On-Call Rotation Shift List"
            disabled={false}
            AllowDelete={false}
            DeleteItem={(e) => console.log("DeleteItem", e)}
            AllowEdit={false}
            EditItem={(e) => console.log("EditItem", e)}
            AllowAdd={true}
            AddItem={() => Additem("ESCSetupPage", "ESC", ContractorData.ContractorId, ContractorData.ContractorName)}
            Data={state.Contractor}
            ShowUpDown={false}
            ShowEdit={false}
            AddBox={(e) => console.log("EditItem", e)}
            DefaultChecked={state.DefaultChecked || []}
            NextLoadComponenet="ESCSetupPage"
            Category="ESC"
            KeyArray={(e) => KeyArray(e, "ContractorESC")}
            PerPage={5}
            id={"contratoocf"}

            />
        </Grid>
        )}

        {state.ShowCompany && (
        <Grid item xs={12}>
          <ShowCheckBoxGroup
            field1={props.CompanyName}
              field2="On-Call Rotation Shift List"
            disabled={false}
            AllowDelete={false}
            DeleteItem={(e) => console.log("DeleteItem",e)}
            AllowEdit={false}
            EditItem={(e) => console.log("EditItem", e)}
            AllowAdd={true}
            AddItem={() => Additem("ESCSetupPage", "ESC", props.CompanyId, props.CompanyName)}
            Data={state.Company}
            ShowUpDown={false}
            ShowEdit={false}
            AddBox={(e) => console.log("EditItem", e)}
            DefaultChecked={state.DefaultChecked || []}
            NextLoadComponenet="ESCSetupPage"
            Category="ESC"
            KeyArray={(e) => KeyArray(e, "CompanyESC")}
            PerPage={5}
            id={"CompanyESC"}

          />
          </Grid>
        )}       

      </Grid>

          <Backdrop
              //className={classes.backdrop}
              open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={() => SetDeawerOpen(false)}
        DrawerTitle={state.DrawerTitle}
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawer: (e) => CloseDrawer(e),
          ...state.DetailsProps
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </div>
  );
}
