/*
 * 
 * get urgency id from ServiceActionList.js
 * allow rename Urgency
 * allow Ungency Copy
 * 
 * Colleps box for Steps, Progress, and ESC 
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch, createSvcbotDb } from '../graphql/mutations';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
    Paper, Grid, Button, Box,
    Backdrop,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar, FormControlLabel, Switch, TextField
} from '@mui/material';
import {
    removeEmptyStringElements,
    PrimaryKeyDataGet,
    ContractorDataPerCat,
    NewPageId,
    FindLatestDate,
    getAutoCompletedata
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';
import MuiAlert from '@mui/material/Alert';
import SelectAssets from '../components/SelectAssets';
import { Buffer } from 'buffer';
import { DateTime } from "luxon";
//import { open } from 'node:fs';
//import { ContractorBlocks, CompanyBlocks, EmailTriggerBlocks } from '../Setup/UrgencyDetailOptions';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();

export default function SimpleCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const NowEditing = useSelector(state => state.NowEditing)
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        SMSQuestionsNew: [],
        StepValueArray: [],
        RequestorInfo: false,
        SenderName: "",
        SenderCompany: "",
        Quote: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        try {        

            //let timeZoneName = 'America/Los_Angeles';
            //let ShowDate = DateTime.now().setZone(timeZoneName).toLocaleString(DateTime.DATETIME_MED);
            //let currentTime = DateTime.now().setZone(timeZoneName).toString();
            //let UTCTume = DateTime.utc().toString();

            //console.log("DateStuff.ShowDate", ShowDate);
            //console.log("DateStuff.currentTime", currentTime);
            //console.log("DateStuff.UTCTume", UTCTume);

            console.log("UrgencyDetails.step.1 - 83 - props", props);
            let field1 = "";
            let field2 = "";
            let mobile = "";
            let SenderCompany = "";
            let SenderName = "";
            let posterId = {
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
            }
            let RequestorInfo = false;

            if (UserData.CustomerType === "Company") {
                SenderCompany = UserData.CompanyName;
                SenderName = UserData.field1
                RequestorInfo = true;

                posterId = {
                    name: UserData.name,
                    phone: UserData.phone,
                    mobile: UserData.mobile,
                    email: UserData.email,
                    pk1: UserData.pk1,
                    sk1: UserData.sk1,
                    CompanyId: UserData.CompanyId,
                    key: UserData.key,
                    field1: UserData.field1,
                    CompanyName: UserData.CompanyName,
                    CustomerType: UserData.CustomerType,
                    avatar: UserData.avatar,
                    TimeZone: UserData.TimeZone,
                    Location: UserData.Location,
                };
            }


            if (props.Data) {

                if (props.Data.mobile) {
                    mobile = props.Data.mobile;
                }

                if (props.Data.field1) {
                    field1 = props.Data.field1;
                }

                if (props.Data.field2) {
                    field2 = props.Data.field2;
                }



                GetEachPolicy(mobile, posterId)

                setState(prevState => ({
                    ...prevState,
                    mobile: mobile,
                    field1: field1,
                    field2: field2,
                    SenderName: SenderName,
                    SenderCompany: SenderCompany,
                    posterId: posterId,
                    RequestorInfo: RequestorInfo
                }))
            }

        } catch (err) { console.log("UrgencyDetails.useEffect.err", err) }

    }, [props.Data, UserData])

    const TriggerLambda = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("TriggerESCData", "/ESCData", {
            body: { Records: event }
        });
    }

    

    const GetEachPolicy = async (TwilioSMSData, posterId) => {

        try {

            let ContractorPolicyFound = "";
            let PolicyId = "";
            let FindPolicyraw = {};
            let CompanyPolicyFound = "";
            let PersonPolicyFound = "";
            let FinalPolicy = "";
            let SMSQuestions = [];
            let SMSQuestionsNew = [{
                SenderCompany: posterId.SenderCompany,
                SenderName: posterId.SenderName,
                LoadComponenet: "DescriptionBox",
                Category: "Reason",
                PhotoService: [],
                label: "Service Needed",
                field1: "Service Needed",
                Documents: [],
                TimeStamp: currentTime,
                SenderCompanyType: "Company",
                To: TwilioSMSData,
                Body: "",
                KeyId: "Description"
            }];
            let LoadComponenet = "";
            let SenderCompany = props.CompanyName;
            let SenderName = UserData.field1;
            let From = UserData.email;
            let PolicyFlag = true;

            //get contractor policies
            let ContractorPolicyList = await PrimaryKeyDataGet(
                ContractorData.ContractorId, `Data::Online::Lambda::Pref::Policy`
            );

            //let ContractorInfo = {
            //    ContractorId: ContractorData.CompanyId,
            //    CompanyName: ContractorData.CompanyName,
            //    ContractorName: ContractorData.CompanyName,
            //    CompanyId: ContractorData.CompanyId,
            //    avatar: HQData.avatar,
            //    ContractorLogo: HQData.avatar,
            //    SubDomain: HQData.SubDomain,
            //    phone: ContractorData.phone,
            //    field1: ContractorData.field1,
            //    field2: ContractorData.field2
            //}

            if (ContractorPolicyList && ContractorPolicyList.length > 0) {
                let ContractorDataJson = JSON.parse(ContractorPolicyList[0].dataJSON);

                console.log("NewServiceCall.ContractorDataJson", ContractorDataJson);

                FindPolicyraw = await GetApplicablePolicies(
                    ContractorDataJson.SelectedArray,
                    ContractorData.ContractorId,
                    TwilioSMSData,
                    ContractorData.ContractorId
                )

                if (FindPolicyraw && FindPolicyraw.PolicyId !== "") {
                    ContractorPolicyFound = FindPolicyraw.Policy;
                    PolicyId = FindPolicyraw.PolicyId;

                    //if (ContractorPolicyFound.DB && ContractorPolicyFound.DB.length > 0) {
                    //    ContractorData = FindContractorInfo(ContractorPolicyFound);
                    //}

                }

                console.log("NewServiceCall.FindPolicyraw", FindPolicyraw);
            }

            //get contractor policies
            let CompanyPolicyList = await PrimaryKeyDataGet(posterId.CompanyId, `Data::Online::Lambda::Pref::Policy`);

            if (CompanyPolicyList && CompanyPolicyList.length > 0) {
                // console.log("CompanyPolicyList", JSON.stringify(CompanyPolicyList[0].dataJSON, null, 2));

                let CompanyDataJson = JSON.parse(CompanyPolicyList[0].dataJSON);

                //person policy
                FindPolicyraw = await GetApplicablePolicies(
                    CompanyDataJson.SelectedArray,
                    posterId.key,
                    TwilioSMSData,
                    ContractorData.ContractorId
                )

                if (FindPolicyraw && FindPolicyraw.PolicyId !== "") {
                    PersonPolicyFound = FindPolicyraw.Policy;
                    PolicyId = FindPolicyraw.PolicyId;
                }

                console.log("NewServiceCall.FindPolicyraw", PersonPolicyFound);

                if (PersonPolicyFound && Object.keys(PersonPolicyFound).length > 0) {
                    FinalPolicy = PersonPolicyFound;
                    PolicyFlag = false;
                } else {
                    //get company policy if person not present
                    FindPolicyraw = await GetApplicablePolicies(
                        CompanyDataJson.SelectedArray,
                        posterId.CompanyId,
                        TwilioSMSData,
                        ContractorData.ContractorId)

                    if (FindPolicyraw && FindPolicyraw.PolicyId !== "") {
                        CompanyPolicyFound = FindPolicyraw.Policy;
                        PolicyId = FindPolicyraw.PolicyId;
                    }

                    console.log("NewServiceCall.CompanyPolicyFound", CompanyPolicyFound);

                    if (CompanyPolicyFound && Object.keys(CompanyPolicyFound).length > 0) {
                        FinalPolicy = CompanyPolicyFound;
                        PolicyFlag = false;
                    } else {
                        FinalPolicy = ContractorPolicyFound;
                        PolicyFlag = true;
                    }
                }
            } else {
                FinalPolicy = ContractorPolicyFound;
                PolicyFlag = true;
            }

            console.log("NewServiceCall.FinalPolicy", FinalPolicy);



            if (FinalPolicy) {

                let SMSListQuestions2 = FinalPolicy.find(x => x.KeyId === "SMSListQuestions");
                if (SMSListQuestions2) {
                    SMSQuestions = SMSListQuestions2.SMSQuestions.filter(x => x.VariableInput === true);
                }

                if (SMSQuestions.length > 0) {
                    SMSQuestions.map((each, indd) => {

                        switch (each.Category) {
                            case "Location":
                                LoadComponenet = "SelectAssetBox";
                                break;
                            case "Equipment":
                                LoadComponenet = "SelectAssetBox";
                                break;
                            case "Images":
                                LoadComponenet = "UploadBox";
                                break;
                            case "Problem":
                                LoadComponenet = "UploadBox";
                                break;
                            case "Reason":
                                LoadComponenet = "DescriptionBox";
                                break;
                            case "PO":
                                LoadComponenet = "DescriptionBox";
                                break;
                            case "Additional":
                                LoadComponenet = "DescriptionBox";
                                break;
                            case "Project":
                                LoadComponenet = "DescriptionBox";
                                break;
                            case "Warranty":
                                LoadComponenet = "DescriptionBox";
                                break;
                            case "Quote":
                                LoadComponenet = "YesNoBox";
                                break;
                            case "YesNo":
                                LoadComponenet = "YesNoBox";
                                break;
                            default:
                                LoadComponenet = "DescriptionBox";
                                break;
                        }

                        SMSQuestionsNew.push({
                            ...each,
                            SenderCompany: state.SenderCompany,
                            SenderName: state.SenderName,
                            avatar: state.Senderavatar,
                            PhotoService: [],
                            Documents: [],
                            From: From,
                            TimeStamp: currentTime,
                            SenderCompanyType: "Company",
                            To: state.mobile,
                            Body: "",
                            LoadComponenet: LoadComponenet,
                            KeyId: each.key + indd
                        })
                    })
                }
            }

            setState(prevState => ({
                ...prevState,
                SMSQuestionsNew: SMSQuestionsNew,
                StepValueArray: SMSQuestionsNew,
                FinalPolicy: FinalPolicy,
                PolicyId: PolicyId,
                ContractorPolicyFound: ContractorPolicyFound,
                PolicyFlag: PolicyFlag
            }))

            //CombinedEvent(
            //    TwilioSMSData,
            //    ContractorData,
            //    posterId,
            //    FinalPolicy,
            //    ContractorPolicyFound,
            //    PolicyFlag,
            //    PolicyId
            //);

        } catch (err) { console.log("GetApplicablePolicies err", err) }
    };

    const GetApplicablePolicies = async (SelectedArray, id, TwilioSMSData, ContractorId) => {

        try {

            let ContractorPolicy = "";
            let returnpolicy = {};
            console.log("NewServiceCall.policy1", SelectedArray);
            console.log("NewServiceCall.policy2 ---", id, ContractorId);
            console.log("NewServiceCall.policy3 ---", TwilioSMSData);

            let SMSMessagesBack1 = SelectedArray.find(x => (
                x.ActiveKey === id
                && x.mobile === TwilioSMSData
            ));

            console.log("NewServiceCall.policy4 ---", id, ContractorId, SMSMessagesBack1, SelectedArray);

            if (SMSMessagesBack1 && SMSMessagesBack1.key) {

                let ContractorEsc = await ContractorDataPerCat(ContractorId, SMSMessagesBack1.key);
                console.log("NewServiceCall.ContractorEsc ---", ContractorEsc);

                if (ContractorEsc) {
                    ContractorPolicy = JSON.parse(ContractorEsc[0].dataJSON)
                    ContractorPolicy = ContractorPolicy.StepValueArray;
                }

                returnpolicy = {
                    Policy: ContractorPolicy,
                    PolicyId: SMSMessagesBack1.key
                }

            }



            console.log("NewServiceCall.returnpolicy ---", returnpolicy);

            return returnpolicy

        } catch (err) { console.log("GetApplicablePolicies err", err) }
    };

    //const FindContractorInfo = () => {

    //    /*
    //    consider using gsi1pk1 = phone::contractor SMS # and gsi1sk1 = phone::person sms number
    //    */
    //    try {

    //        let ContractorData = {
    //            ContractorId: ContractorData.CompanyId,
    //            CompanyName: ContractorData.CompanyName,
    //            ContractorName: ContractorData.CompanyName,
    //            CompanyId: ContractorData.CompanyId,
    //            avatar: HQData.avatar,
    //            ContractorLogo: HQData.avatar,
    //            SubDomain: HQData.SubDomain,
    //            phone: ContractorData.phone,
    //            field1: ContractorData.field1,
    //            field2: ContractorData.field2
    //        }

    //        if (ContractorData.Location) {
    //            ContractorData.ContractorAddress = ContractorData.Location.address;
    //            ContractorData.Address = ContractorData.Location.address;
    //        }

    //        if (HQinfo.avatar) {
    //            ContractorData.ContractorLogo = HQinfo.avatar;
    //            ContractorData.avatar = HQinfo.avatar;
    //        }

    //        if (HQinfo.SubDomain) {
    //            ContractorData.SubDomain = HQinfo.SubDomain;
    //        }

    //        if (HQinfo.CompanyName) {
    //            ContractorName = HQinfo.CompanyName;
    //            if (ContractorName !== Branchinfo.field1) {
    //                ContractorName = `${ContractorName} - ${Branchinfo.field1}`
    //            }
    //            ContractorData.CompanyName = ContractorName;
    //        }

    //        if (Branchinfo.phone) {
    //            ContractorData.ContractorPhone = Branchinfo.phone;
    //            ContractorData.phone = Branchinfo.phone;
    //        }
    //        if (Branchinfo.Location) {
    //            ContractorData.ContractorAddress = Branchinfo.Location.address;
    //            ContractorData.Address = Branchinfo.Location.address;
    //        }

    //        if (CompanyEmailPolicyData.field1) {
    //            ContractorData.field1 = CompanyEmailPolicyData.field1;
    //        }

    //        if (CompanyEmailPolicyData.field2) {
    //            ContractorData.field2 = CompanyEmailPolicyData.field2;
    //        }

    //        ContractorData.ContractorSMS = "";

    //        return ContractorData;

    //    } catch (err) { console.log("error in FindContractor", err) }

    //}; // end

    const AdjustData = () => {
        let StepValueArray1 = state.StepValueArray;
        let postHTML = "<p>";
        let postTEXT = "";
        let DBList = [];
        let PhotoService = [];
        let Documents = [];
        let NewStepValueArray = [];

        StepValueArray1.map((each, indd) => {

            postHTML += `<h4>${each.field1}</h4>${each.Body}`;
            postTEXT += `( Question - ${each.field1} ) ${each.Body}\n\n`;

            if (each.AssetList && each.AssetList.length > 0) {
                DBList = [...DBList, ...each.AssetList];
            }

            if (each.PhotoService && each.PhotoService.length > 0) {
                PhotoService = [...PhotoService, ...each.PhotoService];
            }

            if (each.Documents && each.Documents.length > 0) {
                Documents = [...Documents, ...each.Documents];
            }
            //question
            NewStepValueArray.push({
                ...each,
                Body: each.field1,
                SenderCompany: ContractorData.CompanyName,
                SenderName: "Question",
                avatar: ContractorData.avatar,
                AssetList: []
            })
            //answer
            NewStepValueArray.push({
                ...each,
                Body: each.Body,
                SenderCompany: state.SenderCompany,
                SenderName: state.SenderName,
                avatar: state.Senderavatar,
                AssetList: []
            })


        })

        postHTML += "</p>";

        CombinedEvent(postHTML, postTEXT, DBList, PhotoService, Documents, NewStepValueArray);
    }

    const CombinedEvent = (postHTML, postTEXT, DBList, PhotoService, Documents, NewStepValueArray) => {

        let posterId = state.posterId;
        let title = `${state.field1} - ${posterId.field1} - ${posterId.CompanyName}`;


        //setting PostId
        let PostId = NewPageId("SR");

        //set local time and sk for service request
        let offset = 0;
        let timeZoneName = 'America/New_York';
        let StartConversation = "Conversation::" + state.posterId.key;

        let DayLightSaving = 1;

        if (posterId.TimeZone) {
            timeZoneName = posterId.TimeZone.value;
            offset = parseFloat(posterId.TimeZone.offset) + DayLightSaving;
        }

        //var LocalTime = new Date();
        //let NewActiveDate = new Date(LocalTime.getTime() + ((offset + DayLightSaving) * 60 * 60000));
        let ShowDate = new Date().toLocaleString();
//            DateTime.now().setZone(timeZoneName).toLocaleString(DateTime.DATETIME_MED);
        let currentTime = new Date().toString();
        let UTCTume = new Date().toUTCString();

        let ButtonLinkpk = Buffer.from(StartConversation).toString('base64');
        let ButtonLinksk = Buffer.from(UTCTume).toString('base64');
        let ActionButton = `https://${HQData.SubDomain}/ServiceRequest/${ButtonLinkpk}/${ButtonLinksk}`;

        let ResponseStartTime = new Date(currentTime).getTime();
        
        let ProgressHistory = {
            Progress: 0,
            date: currentTime,
        }

        let event = {
            EventData: NewStepValueArray,
            postHTML: postHTML,
            ContractorSMS: state.mobile,
            RequestorSMS: posterId.mobile,
            ResponseStartTime: ResponseStartTime,
            ResponseEndTime: ResponseStartTime,
            postTEXT: postTEXT,
            PolicyId: state.PolicyId,
            SenderName: posterId.field1,
            SenderCompany: posterId.CompanyName,
            Location: posterId.Location,
            Documents: Documents,
            PhotoService: PhotoService,
            Read: [],
            timeZoneName: timeZoneName,
            offset: offset,
            // LocalTime: LocalTime,
            DBList: DBList,
            PostId: PostId,
            currentTime: currentTime,
            UTCTime: UTCTume,
            date: ShowDate,
            ServiceRequest: {
                sk1: UTCTume,
                pk1: StartConversation
            },
            SenderCompanyType: posterId.CustomerType,
            Senderavatar: posterId.avatar,
            SearchWords: title,
            //  ContractorEscData: ContractorEscData,
            //  SMSFeedbackMessages: SMSFeedbackMessages,
            title: title,
            status: "Triggered",
            CompanyStatus: "pending",
            ContractorStatus: "pending",
            ContractorData: [],
            Progress: 0,
            ProgressHistory: ProgressHistory,
            CurrentProgressStep: 0,
            posterId: posterId,
            ActionButton: ActionButton,
            ActionButtonTitle: "Check online",
            field1: state.field1,
            Urgency: state.field1
        };

        // GetApplicablePolicies(TwilioSMSData, ContractorData);


        console.log("Data to FindPolicy ---", event);

        SeperateData(event)

        //let Function = 'arn:aws:lambda:us-east-1:109857144948:function:FindEscPolicy';
        //TriggerLambda(NewEvent, Function);

        // MessageBackSMS(TwilioSMSData, PersonInfo, "415", SMSFeedbackMessages.AllowServiceMessage);


    }


    const SeperateData = async (event) => {
        try {

            let LastSMS = event.EventData.length - 1;
            event.ManagerApprovalNeeded = false;
            event.ManagerApprovalStatus = "Approved";
            event.ManagerShift = [];

            // let SMSQuestions = [];
            // let ContractorEscData = event.ContractorEscData;
            let CompanyESC = [];
            let ProgressList = [];
            let ContractorESC = [];
            let SMSFeedbackMessages = [];
            let CompanyESCarray = [];
            let ColorSelection = [];
            let SpecialInstructions = "";
            let Priority = "Medium";
            let AddContractorPolicy = {};
            let ContractorPolicyFound = state.ContractorPolicyFound;
            let ESCSubContractorList = [];
            let SubEscPolicy = [];
            let TotalSubPolicies = [];

            let CompanyEscData = ContractorPolicyFound;
            if (!state.PolicyFlag) {
                CompanyEscData = state.FinalPolicy;
            }

            let ESCSelectContractor = ContractorPolicyFound.find(x => x.KeyId === "ESCSelectContractor");
            if (ESCSelectContractor) {
                ContractorESC = ESCSelectContractor.CompanyESC;
            }

            if (CompanyEscData) {

                //let SMSListQuestions2 = CompanyEscData.find(x => x.KeyId === "SMSListQuestions");
                //if (SMSListQuestions2) {
                //    SMSQuestions = SMSListQuestions2.SMSQuestions.filter(x => x.VariableInput === true);
                //}

                let ProgressList2 = CompanyEscData.find(x => x.KeyId === "ProgressList");
                if (ProgressList2) {
                    ProgressList = ProgressList2.ProgressList.filter(x => x.VariableInput === true);
                }

                let SpecialInstructions1 = CompanyEscData.find(x => x.KeyId === "SpecialInstructions");
                if (SpecialInstructions1) {
                    SpecialInstructions = SpecialInstructions1.SpecialInstructions;
                }

                let PrioritySelection2 = CompanyEscData.find(x => x.KeyId === "PrioritySelection");
                if (PrioritySelection2) {
                    Priority = PrioritySelection2.Priority;
                }

                let ColorSelection2 = CompanyEscData.find(x => x.KeyId === "ColorSelection");
                if (ColorSelection2) {
                    ColorSelection = ColorSelection2.Colors;
                }

                let ESCSelectContractor2 = CompanyEscData.find(x => x.KeyId === "ESCSelectCompany");
                if (ESCSelectContractor2) {

                    if (ESCSelectContractor2.CompanyESC && ESCSelectContractor2.CompanyESC.length > 0) {
                        CompanyESCarray = ESCSelectContractor2.CompanyESC;
                    }
                }

                //sub contractor 
                
                let ESCSubContractorList2 = CompanyEscData.find(x => x.KeyId === "ESCSubContractorList");
                if (ESCSubContractorList2) {

                    if (ESCSubContractorList2.CompanyESC
                        && ESCSubContractorList2.CompanyESC.length > 0) {
                        ESCSubContractorList = ESCSubContractorList2.CompanyESC;

                        if (ESCSubContractorList2.CompanyESC
                            && ESCSubContractorList2.CompanyESC.length > 0) {
                            ESCSubContractorList = ESCSubContractorList2.CompanyESC;

                        }

                        //if (ESCSubContractorList
                        //    && ESCSubContractorList.length > 0) {
                        //    TotalSubPolicies = await Promise.all(ESCSubContractorList.map(async (each) => {
                        //        SubEscPolicy = await PrimaryKeyDataGet(each.sk1, "Data::Online::Lambda::ESC::Time");
                        //        return await SubEscPolicy;
                        //    }));
                        //}
                    }
                }

                console.log("ESCSubContractorList", TotalSubPolicies);

                let AddContractorPolicy = CompanyEscData.find(x => x.KeyId === "ESCSelectContractor");
                if (AddContractorPolicy) {

                    switch (AddContractorPolicy.UseCustomESC) {
                        case "Default":
                            CompanyESC = [...CompanyESCarray, ...ContractorESC];
                            break;
                        case "Custom":
                            CompanyESC = [...CompanyESCarray, ...AddContractorPolicy.ContractorESC];
                            break;
                        case "None":
                            CompanyESC = [...CompanyESCarray];
                            break;
                        default:
                            CompanyESC = [...CompanyESCarray, ...ContractorESC];
                    }
                } else {
                    CompanyESC = [...CompanyESCarray, ...ContractorESC]; //CompanyESCarray;
                }


                let ManagerApproval = CompanyEscData.find(x => x.KeyId === "ManagerApproval");

                if (ManagerApproval) {
                    if (ManagerApproval.ManagerApprovalNeeded) {
                        event.ManagerApprovalNeeded = true;
                        event.ManagerApprovalStatus = "Pending";
                        event.ManagerShift = ManagerApproval.ManagerShiftDB;
                    }
                }

                let SMSMessagesBack = CompanyEscData.find(x => x.KeyId === "SMSMessagesBack");
                if (SMSMessagesBack) {
                    SMSFeedbackMessages = SMSMessagesBack;
                }
            }

            if (CompanyESC.length === 0) {
                CompanyESC = ContractorESC;
            }

            console.log("Final Esc Policy Selected ---", JSON.stringify(CompanyESC, null, 2));

            event.ColorSelection = ColorSelection;
            event.SpecialInstructions = SpecialInstructions;
            event.EscPolicyFound = CompanyESC;            
            event.ESCSubContractor = ESCSubContractorList;
            event.ProgressList = ProgressList;
            event.Priority = Priority;
            event.Quote = state.Quote;
            // event.PhotoService = event.EventData[LastSMS].PhotoService;

            event.SMSFeedbackMessages = SMSFeedbackMessages;

            if (SMSFeedbackMessages && SMSFeedbackMessages.phone) {
                event.ContractorPhone = SMSFeedbackMessages.phone;
            }

            if (SMSFeedbackMessages && SMSFeedbackMessages.ReplyEmail) {
                event.ReplyEmail = SMSFeedbackMessages.ReplyEmail;
            } else {
                event.ReplyEmail = "Do-Not-Reply@service-call-cloud.com";
            }

            event.ContractorPhone = SMSFeedbackMessages.phone;


            GetPreSet(event);

            // let ESCData = "arn:aws:lambda:us-east-1:109857144948:function:ESCData";
            TriggerLambda(event);




        } catch (err) { console.log("SeperateData err", err); }
    };

    const GetPreSet = async (event) => {

        try {

            // let ContractorData = event.ContractorData;
            // let PersonInfo = event.posterId;
            let CompanyPreSelectList = [];
            let DBscema = "Data::Online::Lambda::PreSelect";

            let ContractorEsc1 = await PrimaryKeyDataGet(ContractorData.ContractorId, DBscema);
            if (ContractorEsc1 && ContractorEsc1.length > 0) {
                ContractorEsc1.map((each, indd) => {
                    CompanyPreSelectList.push(JSON.parse(each.dataJSON));
                });
            }
            //Data::Company::Info::2022-06-08T20:19:43.437Z
            let CompanyEsc1 = await PrimaryKeyDataGet(event.posterId.CompanyId, DBscema);
            if (CompanyEsc1 && CompanyEsc1.length > 0) {
                CompanyEsc1.map((each, indd) => {
                    CompanyPreSelectList.push(JSON.parse(each.dataJSON));
                });
            }

            //  console.log(`PreSelect Final -- 532 --`, JSON.stringify(CompanyPreSelectList, null, 2));

            event.PreSelect = CompanyPreSelectList;

            PutDefaultPreset(event);

        } catch (err) { console.log("GetPreSet err", err) }

    };

    const PutDefaultPreset = (event) => {
        let ContactList = "";
        let DBList = event.DBList;
        let FindDefaults = [];

        //Put the Pre-Selected items marked as Default in to the DB

        event.PreSelect.map(eachPreset => {
            FindDefaults = eachPreset.ContactList.filter(x => x.Default === true);
            if (FindDefaults && FindDefaults.length > 0) {
                DBList = [...DBList, ...FindDefaults];
            }
            FindDefaults = [];
        })

        event.DBList = DBList;

        DatabaseData(event);
    }



    const DatabaseData = (event) => {

        console.log("NewServiceCall.event", event);

        try {

            let InputArray = [];
            let EachDB = {};
            let newMini = "";
            let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let ServiceRequestTime = new Date(event.currentTime).toLocaleString();
            let field2 = `Service Call: ${ServiceRequestTime}`;
            let ResponseStartTime = new Date(event.currentTime).getTime();

            let NewEvent = {
                ...event,
                pk1: event.ServiceRequest.pk1,
                sk1: event.ServiceRequest.sk1,
                gsi1Pk2: `Post::${event.posterId.CompanyId}`,
                gsi1Sk2: event.currentTime,
                gsi2Pk2: "Post::" + ContractorData.ContractorId,
                gsi2Sk2: event.currentTime,
                ResponseStartTime: ResponseStartTime,
                ResponseEndTime: ResponseStartTime
            }

            const inputthiss = {
                pk1: event.ServiceRequest.pk1,
                sk1: event.ServiceRequest.sk1,
                gsi1Pk2: `Post::${event.posterId.CompanyId}`,
                gsi1Sk2: event.currentTime,
                gsi2Pk2: "Post::" + ContractorData.ContractorId,
                gsi2Sk2: event.currentTime,
                dataJSON: JSON.stringify(removeEmptyStringElements(NewEvent)),
            };

            //let PosttoDB1 = {
            //    MutationType: "createSvcbotDb",
            //    DBData: input
            //};

            //// Post to DB
            //TriggerLambda(PosttoDB1, PosttoDBLambda);
            console.log("NewServiceCall.InputArray.inputthiss", inputthiss)

            API.graphql(graphqlOperation(createSvcbotDb, { input: inputthiss }))

            const Mini = {
                posterId: event.posterId,
                //  post: event.postTEXT,  //change this to the initial call??
                title: event.title,
                field1: event.posterId.field1,
                avatar: event.posterId.avatar,
                AssetPkSk: {
                    pk1: event.posterId.CompanyId,
                    sk1: event.posterId.sk1
                },
                CustomerType: "Company",
                field2: field2,
                ServiceRequest: event.ServiceRequest,
                SearchWords: `${event.title} - ${event.postTEXT}`,
                PhotoService: event.PhotoService,
                Documents: event.Documents,
                date: event.currentTime,
                PostId: event.PostId,
                CompanyId: event.posterId.CompanyId,
                ContractorId: ContractorData.ContractorId,
                Category: "Person",
                pk1: `Post::${event.PostId}`,
                sk1: `Post::Person::${event.ServiceRequest.sk1}`,
                key: `Post::Person::${event.ServiceRequest.sk1}`,
            };

            const MiniDataJSON = removeEmptyStringElements(Mini);

            //person
            //const person = {

            //    pk1: `Post::${event.PostId}`,
            //    sk1: `Post::Person::${event.ServiceRequest.sk1}`,
            //    gsi1Pk2: event.posterId.key,
            //    gsi1Sk2: `Post::Person::${event.ServiceRequest.sk1}`,
            //    gsi2Pk2: event.posterId.CompanyId,
            //    gsi2Sk2: `Post::Person::${event.ServiceRequest.sk1}`,
            //    dataJSON: JSON.stringify(MiniDataJSON)

            //};
            //InputArray.push(person);


            const person = {

                pk1: `Post::${event.PostId}`,
                sk1: `Post::Person::${event.ServiceRequest.sk1}`,
                gsi1Pk2: `Post::${event.posterId.sk1}`,
                gsi1Sk2: event.ServiceRequest.sk1,
                gsi2Pk2: `Post::Person::${event.posterId.CompanyId}`,
                gsi2Sk2: event.ServiceRequest.sk1,
                dataJSON: JSON.stringify(MiniDataJSON)

            };
            InputArray.push(person);

            const progresstiny = {
                title: event.title,
                field1: event.title,
                ServiceRequest: event.ServiceRequest,
                date: event.currentTime,
                Progress: 0,
                ResponseStartTime: ResponseStartTime,
                ResponseEndTime: ResponseStartTime,
                PostId: event.PostId,
                key: `Post::Progress::${event.ServiceRequest.sk1}`,
                pk1: `Post::${event.PostId}`,
                sk1: `Post::Progress::${event.ServiceRequest.sk1}`,
                gsi1Pk2: `Post::Progress::${event.posterId.CompanyId}`,
                gsi1Sk2: event.currentTime,
                gsi2Pk2: `Post::Progress::${ContractorData.ContractorId}`,
                gsi2Sk2: event.currentTime,
            };

            const progresstinyJSON = removeEmptyStringElements(progresstiny);

            const Progress11 = {
                pk1: `Post::${event.PostId}`,
                sk1: `Post::Progress::${event.ServiceRequest.sk1}`,
                gsi1Pk2: `Post::Progress::${event.posterId.CompanyId}`,
                gsi1Sk2: event.currentTime,
                gsi2Pk2: `Post::Progress::${ContractorData.ContractorId}`,
                gsi2Sk2: event.currentTime,
                dataJSON: JSON.stringify(progresstinyJSON)
            };
            InputArray.push(Progress11);

            const ResponseTimeTrack = {
                //pk1: `Post::Response::${event.posterId.CompanyId}`,
                //sk1: event.ServiceRequest.sk1,
                ////gsi1Pk2: `Post::${event.PostId}`,
                ////gsi1Sk2: `Post::Response::Start`,
                //gsi2Pk2: `Post::Response::${ContractorData.ContractorId}`,
                //gsi2Sk2: event.currentTime,
                //dataJSON: JSON.stringify(progresstinyJSON)

                pk1: `Post::${event.PostId}`,
                sk1: `Post::ResponseTime::${event.ServiceRequest.sk1}`,
                gsi1Pk2: `Post::ResponseTime::${event.posterId.CompanyId}`,
                gsi1Sk2: event.currentTime,
                gsi2Pk2: `Post::ResponseTime::${event.ContractorData.ContractorId}`,
                gsi2Sk2: event.currentTime,
                dataJSON: JSON.stringify(progresstinyJSON)

            };
            InputArray.push(ResponseTimeTrack);

            //document / pic
            if (event.Documents && event.PhotoService) {
                if (event.PhotoService.length > 0 || event.Documents.length > 0) {

                    let MiniDataJSON2 = MiniDataJSON;
                    MiniDataJSON2.sk1 = "Post::Doc::" + event.ServiceRequest.sk1;
                    MiniDataJSON2.key = "Post::Doc::" + event.ServiceRequest.sk1;
                    MiniDataJSON2.Category = "Documents";
                   // MiniDataJSON2.avatar = "";
                    MiniDataJSON2.field1 = `Document Type: Post`;

                    const DocData = {
                        pk1: `Post::${event.PostId}`,
                        sk1: "Post::Doc::" + event.ServiceRequest.sk1,
                        gsi1Pk2: `Doc::${event.posterId.CompanyId}`,
                        gsi1Sk2: event.currentTime,
                        gsi2Pk2: event.posterId.CompanyId,
                        gsi2Sk2: `Doc::Post::${event.ServiceRequest.sk1}`,
                        dataJSON: JSON.stringify(MiniDataJSON2)
                    };
                    InputArray.push(DocData);
                }
            }

            //Each PreSelect item confirmed by customer
            if (event.DBList && event.DBList.length > 0) {
                event.DBList.map((each, index) => {

                    newMini = Mini;
                    if (each.KeyInformation) {
                        newMini = {
                            ...Mini,
                            ...each.KeyInformation,
                            field1: event.title,
                            field2: field2
                        };
                    }

                    newMini.sk1 = `Post::${each.Category}::${event.ServiceRequest.sk1}`;
                    newMini.pk1 = `Post::${event.PostId}`;
                    newMini.key = `Post::${each.key}::${event.ServiceRequest.sk1}`;
                    newMini.AssetPkSk = {
                        pk1: event.posterId.CompanyId,
                        sk1: each.key
                    }
                    newMini.Category = each.Category;
                    newMini.field1 = each.field1;
                    newMini.avatar = "";

                    if (each.Location) {
                        newMini.Location = each.Location;
                    }
                    console.log("NewServiceCall.newMini", newMini)
                    if (each.Category === "Location") {

                        EachDB = {
                            pk1: `Post::${event.PostId}`,
                            sk1: `Post::${each.Category}::${event.ServiceRequest.sk1}::${index}`,
                            gsi1Pk2: `Post::${each.key}`,
                            gsi1Sk2: event.ServiceRequest.sk1,
                            gsi2Pk2: `Post::Location::${ContractorData.ContractorId}`,
                            gsi2Sk2: event.ServiceRequest.sk1,
                            dataJSON: JSON.stringify(removeEmptyStringElements(newMini))
                        };

                    } else {

                        EachDB = {
                            pk1: `Post::${event.PostId}`,
                            sk1: `Post::${each.Category}::${event.ServiceRequest.sk1}::${index}`,
                            gsi1Pk2: `Post::${each.key}`,
                            gsi1Sk2: event.ServiceRequest.sk1,
                            gsi2Pk2: `Post::${each.Category}::${event.posterId.CompanyId}`,
                            gsi2Sk2: event.ServiceRequest.sk1,
                            dataJSON: JSON.stringify(removeEmptyStringElements(newMini))
                        };
                    }

                    InputArray.push(EachDB);
                });
            }

            console.log("NewServiceCall.InputArray", InputArray)
            //let PosttoDB = {
            //    MutationType: "createBatch",
            //    DBData: InputArray
            //};

            //// Post to DB
            //TriggerLambda(PosttoDB, PosttoDBLambda);

            if (InputArray.length > 0) {
                API.graphql(graphqlOperation(createBatch, { input: InputArray }))
                    .then((results) => {

                        //API.graphql(graphqlOperation(createSvcbotDb, { input: TriggerSubs }))

                        //if (rest.TriggerUpdate) {
                        //    dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                        //}

                        //if (rest.UpdateAsset) {
                        //    rest.UpdateAsset(true)
                        //}

                        if (props.CloseDrawer) {
                            props.CloseDrawer();
                        }

                    })
            }


        } catch (err) {
            console.error("error from api", err);
        }
    };


    const handleNext = () => {
        console.log("UrgencyDetails.handleNext", activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ClosingDrawer = (e) => {
        console.log("ClosingDrawer", e)
        SetDeawerOpen(false)
    }


    //const Additem = (data) => {
    //    setState(prevState => ({
    //        ...prevState,
    //        DetailComponent: data,
    //        ShowDetails: false,
    //        DetailsProps: {
    //            Category: state.Category,
    //            CompanyId: props.CompanyId,
    //            CompanyName: props.CompanyName,
    //        }
    //    }))
    //    SetDeawerOpen(true);
    //}



    const SaveSelection = (data) => {

        console.log("NewServiceCall.SaveSelection", data);

        let Quote = false;
        let QuoteQuestion = "";
        let Quoteanswe = "";
        let NewQuote = state.Quote;

        if (data && data.field1 && data.Body) {

            let CurrentQuestion = data.field1;
            let LatestAnswer = data.Body;
            console.log("NewServiceCall.qqq", CurrentQuestion, LatestAnswer);
            if (CurrentQuestion !== "" && LatestAnswer !== "") {

                QuoteQuestion = CurrentQuestion.toLowerCase();
                Quoteanswe = LatestAnswer.toLowerCase();

                if (QuoteQuestion.includes("quote")
                    || QuoteQuestion.includes("proposal")
                ) {
                    if (Quoteanswe.includes("yes")) {
                        Quote = true;
                    }
                }
            }
        }

        if (!state.Quote) {
            setState(prevState => ({
                ...prevState,
                Quote: Quote,
            }))
        }

        let StepValueArray1 = [];
        let Indexx = -1;

        if (state.StepValueArray && state.StepValueArray.length > 0) {
            Indexx = state.StepValueArray.findIndex(x => x.KeyId === data.KeyId);
            StepValueArray1 = state.StepValueArray;
        }

        if (Indexx > -1) {

            StepValueArray1[Indexx] = data;

        } else {

            //if (data && data.KeyId && data.KeyId !== "") {
            //    StepValueArray1[data.KeyId] = data;
            //} else {
            StepValueArray1.push(data);
            //}
        }



        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray1,
        }))

        //}
    }


    //const AddBox = data => {
    //    console.log("SelectStepBox.AddBox", data);
    //}

    //const ColorPickerHax = data => {
    //    console.log("SelectStepBox.ColorPickerHax", data);
    //}



    //const handleNextValidate = (item) => {
    //    if (state[item] && state[item].length > 0) {
    //        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //    } else {
    //        setState(prevState => ({
    //            ...prevState,
    //            AlarmMessage: "Please select items before proceeding to next step",
    //            OpenAlert: true
    //        }));
    //    }

    //};

    //const SelectedValues = (item) => {
    //    console.log("UrgencyDetails.item", item);
    //    let valueee = "Active";
    //    if (item) {
    //        valueee = "Active";
    //    } else {
    //        valueee = "Disabled";
    //    }

    //    setState({
    //        ...state,
    //        status: valueee,
    //        statusshow: item,
    //        StatusMessage: "Please go through all the steps and save the policy at the end to enable or disable the policy"
    //    });
    //    // props.handleInclude(change, props.step.key);
    //};

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const SetRequestor = (data) => {
        console.log("NewServiceCall.poster.data", data);

        let PersonInfo = data[0]

        if (PersonInfo && PersonInfo.field1 && PersonInfo.CompanyName) {

            let posterId = {
                name: PersonInfo.field1,
                phone: PersonInfo.phone,
                mobile: PersonInfo.mobile,
                email: PersonInfo.email,
                pk1: PersonInfo.pk1,
                sk1: PersonInfo.sk1,
                CompanyId: PersonInfo.CompanyId,
                key: PersonInfo.key,
                field1: PersonInfo.field1,
                CompanyName: PersonInfo.CompanyName,
                CustomerType: PersonInfo.CustomerType,
                avatar: PersonInfo.avatar,
                TimeZone: PersonInfo.TimeZone,
            };

            console.log("NewServiceCall.posterId", posterId);

            setState(prevState => ({
                ...prevState,
                SenderCompany: PersonInfo.CompanyName,
                SenderName: PersonInfo.field1,
                Senderavatar: PersonInfo.avatar,
                posterId: posterId,
                RequestorInfo: true
            }));

        }
    }

    const CheckRequestor = () => {
        if (state.RequestorInfo) {
            handleNext();
        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please select Requestor",
                OpenAlert: true
            }));

        }
    }

    const CheckReason = () => {
        let StepValueArray = state.StepValueArray;
        if (state.Description && state.Description !== "") {

            StepValueArray[0] = {

            }

            handleNext();
        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please state the reason for the service request",
                OpenAlert: true
            }));

        }
    }

    // console.log("UrgencyDetails.SelectStepBox.Update5", state.StepValueArray)
    console.log("UrgencyDetails.step.7 - 427 - state", state);
    console.log("SelectAssetBox.StepValueArray2", state.StepValueArray)

    return state.SMSQuestionsNew.length > 0 && (
        <Paper id={state.id} key={state.UUid} sx={{ p: 1 }}>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography>{state.StatusMessage}</Typography>
                </Grid>

                <Grid item xs={12}>

                    <Stepper activeStep={activeStep} orientation="vertical">

                        {UserData.CustomerType === "Contractor" ? (
                            <Step key={10011}>
                                <StepLabel>Person Requesting Service</StepLabel>
                                <StepContent>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography >Please select just one person</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectAssets
                                                SelectedAsset={(e) => SetRequestor(e)}
                                                CompanyId={props.CompanyId}
                                                CompanyName={props.CompanyName}
                                                title={`Select Person Requesting Service`}
                                                Category="Person"
                                                AssetList={state.Requestor}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                //  className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => CheckRequestor()}
                                                //  className={classes.button}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                        ) : null}

                        {/* 
                        <Step key={100}>
                            <StepLabel>Reason</StepLabel>
                            <StepContent>

                                <div>
                                    <Box sx={{ mt: 1, mb: 1 }}>
                                        <TextField
                                            label="Description"
                                            name="title"
                                            value={state.Description || ""}
                                            multiline
                                            minRows={3}
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => SetValues(e.target.value, "Description")}
                                        />
                                    </Box>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        //  className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => CheckReason()}
                                        //  className={classes.button}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                        */}


                        {state.SMSQuestionsNew.map((block, index) => {

                            return (

                                <Step key={index}>
                                    <StepLabel>{block.Category}</StepLabel>
                                    <StepContent>

                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <Typography >{block.field1}</Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <RenderModule
                                                    {...props}
                                                    LoadComponenet={block.LoadComponenet}
                                                    StepValueArray={state.StepValueArray}
                                                    Category={block.Category}
                                                    // SaveDataBack={state.SaveDataBack[index]}
                                                    SelectedDataReturn={(data) => SaveSelection(data)}
                                                    id={index}
                                                    activeStep={activeStep}
                                                    handleBack={() => handleBack()}
                                                    handleNext={() => handleNext(index)}
                                                    KeyId={block.KeyId}
                                                    key={index + block.id}

                                                />
                                            </Grid>

                                        </Grid>
                                    </StepContent>
                                </Step>

                            )
                        })}




                        <Step key={1000}>
                            <StepLabel>Request Service</StepLabel>
                            <StepContent>

                                <div
                                //className={classes.actionsContainer}
                                >
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        //  className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => AdjustData()}
                                        //  className={classes.button}
                                        >
                                            Request Service
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>

                    </Stepper>
                </Grid>

            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle="change this"
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => ClosingDrawer(e),
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />

        </Paper>
    );
}
