/*
 * 
 * 
 * 
 * 
 * 
 */



import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,

} from '@mui/material';
//import { ThemeProvider, useTheme, makeStyles } from '@mui/styles';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation, Storage } from "aws-amplify";
import { createBatch, createSvcbotDb } from '../graphql/mutations';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../components/Page';
import ItemProfilePicture from './ItemProfilePicture';
import ItemAddForms from './ItemAddForms';
import {
    removeEmptyStringElements,
    NewPageId,
    isNotEmptyObject,
    UpdateData
} from '../utils/CommonGraphql';
import awsconfig from '../aws-exports';
import { SendEmail } from '../utils/SendEmail';
import { getFormattedString } from '../components/vCardFormatter';
//import { isMobile } from 'react-device-detect';

// var vCardsJS = require('vcards-js');
//import * as fs from 'node:fs';

//Amplify.configure(awsconfig);
//API.configure(awsconfig);

//const fs = require('fs');


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//const useStyles = makeStyles((theme) => ({
//    root: {
//        backgroundColor: theme.palette.background.dark,
//        minHeight: '100%',
//        paddingBottom: theme.spacing(3),
//        paddingTop: theme.spacing(3)
//    }
//}));

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function buildTable(arr) {
    const rows = arr.map((row, i) => {
        return <tr style={{ backgroundColor: i % 2 ? '#F0FFF2' : 'white' }} key={i}>
            <td>{row[0]}</td><td>{row[1]}</td>
        </tr>
    })
    return <table><tbody>{rows}</tbody></table>
}

//const data = [["FieldA", "aaa"], ["FieldB", "bbb"], ["FieldC", "ccc"]];
//buildTable(data);

const Account = (props) => {
    // const classes = useStyles();
    const params = useParams();
    // let query = useQuery();
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const UserData = useSelector(state => state.UserData);
    const HQData = useSelector(state => state.HQData);
    const NowEditing = useSelector(state => state.NowEditing);
    const WebsiteType = useSelector(state => state.WebsiteType);
    //console.log('cid cid ', cid)
    const initialState = {
        Category: "",
        FormJSON: [],
        FormData: [],
        FormExtra: [],
        AlarmMessage: "",
        OpenAlert: false,
        ImagePic: "",
        UpdateFormJSON: [],
        DefaultImage: "",
        ContactCardFileFullPath: ""
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        let Category = "";
        let CompanyIdd = "";
        let title = "";
        let CompanyName = "";
        let CustomerType = "Company";
        let pk1 = "";
        let sk1 = "";
        let UpdateFormJSON = "";
        let UpdatedataJSON = "";
        let UpdateExisting = false;
        let ImagePic = [];
        let DisableForm = false;
        let AllItemForms = [];


        console.log('ItemAddPage.props', props);
        // console.log('ItemAddPage.ContractorData', ContractorData);

        if (params.Category) {
            Category = params.Category;
            //console.log('ItemAddPage.params.Category', params.Category);
        }

        if (props.Category) {
            Category = props.Category;
        }

        if (NowEditing) {
            CustomerType = NowEditing;
        }

        if (props.UpdatedataJSON && props.UpdatedataJSON.CustomerType) {
            CustomerType = props.UpdatedataJSON.CustomerType;
        }

        if (props.CompanyId && props.CompanyId !== "") {
            CompanyIdd = props.CompanyId;
            CompanyName = props.CompanyName;
        }


        if (props.Category === "Branding") {
            CompanyIdd = props.HQId;
            if (UserData.Level < 800) {
                DisableForm = true;
            }
        }

        
        if (props && props.Data) {
            title = props.Data.field1;
            Category = props.Data.Category;
        }

        if (props.UpdateExisting && props.UpdatedataJSON) {
            pk1 = props.UpdatedataJSON.pk1;
            sk1 = props.UpdatedataJSON.sk1;
            ImagePic = props.ImagePic;
            UpdateExisting = true;
        }

        if (props.avatar && props.avatar !== "") {
            ImagePic = {
                ImageFullPath: props.avatar
            };
        }

        console.log('ItemAddPage-CompanyId', CompanyIdd, Category)

      //  console.log('ItemAddPage-isMobile', isMobile)

        const UUid = NewPageId("IA");

        setState(prevState => ({
            ...prevState,
            Category: Category,
            title: title,
            UUid: UUid,
            CompanyId: CompanyIdd,  //props.CompanyId,
            CompanyName: CompanyName, //props.CompanyName,
            CustomerType: CustomerType,  //props.CustomerType,
            pk1Update: pk1,
            sk1Update: sk1,
            DisableForm: DisableForm,
            //  UpdateFormJSON: UpdateFormJSON,
            ImagePic: ImagePic,
            // AllItemForms: AllItemForms,
            //FormExtra: FormExtra,
            UpdateExisting: UpdateExisting,
        }));

    }, [])


    const Image = imgg => {
        console.log('ItemAddPage.Image', imgg);
        setState(prevState => ({
            ...prevState,
            ImagePic: imgg
        }));
    }

    //const ReturnBackData = form => {
    //    console.log('formItemAddForm', form);
    //    setState(prevState => ({
    //        ...prevState,
    //        FormData: form
    //    }));
    //}

    //const AutoCompleteReturn = form => {
    //    console.log('AutoCompleteReturn', form);
    //    setState(prevState => ({
    //        ...prevState,
    //        FormExtra: form
    //    }));
    //}

    ////////////////////////////////////////////
    const SaveData = async (FormDataBack) => {


        try {
            console.log("ItemAddPage.fix.Start", FormDataBack);

            let AllItemForms = FormDataBack.AllItemForms;
            // let FormExtra = FormDataBack.FormExtra;

            let Field1 = "";
            let Field2 = "";
            let SearchWords = "";
            let MissingInfoFlag = false;
            let dataJSON = "";
            let FormdataJSON = {};
            let Invitation = {};
            let SendEmailInvite = false;
            let SendSMSInvite = false;
            let AllowSMSService = true;
            let PhotoService = [];
            let Documents = [];
            let AlarmMessage = "";
            let DBInput = {};
            let DBInput2 = {};
            let Location = "";
            let Phone = "";
            let Email = "";
            let InputArray = [];
            let Address = "";
            let CompanyName = "";
            let FormDD = {};
            let avatar = "";
            let Mobile = "";
            let key = `Data::Company::Asset::${state.Category}::${state.UUid}`;
            let status = "Active";
            let Cat = "";
            let Privileges = { value: 100, label: "Field Resource" };
            let Level = 100;
            let BranchType = "";
            let TimeZone = "";
            let ContractorId = ContractorData.CompanyId;
            let CompanyId = state.CompanyId;
            let BranchId = ContractorData.CompanyId;
            let BranchName = ContractorData.CompanyName;
            let HQId = ContractorData.HQId;
            let SubDomain = "";
            let HQName = ContractorData.HQName;
            let postHTML = "<table>";
            let SMStype = "";
            let postTEXT = "";
            let SelectedSMS = [];
            let SelectedVideo = [];
            let mediaUrl = [];
            let ContactCardFileFullPath = "";
            let Access = "All";
            let CustomerType = "Company";
            let introVideo = {};
            let AuthorityLevelFound = false;
            let receiveEmail = false;
            let receiveSMS = false;
            let receivePhone = false;
            let DocumentType = "";
            let cid = "";
            let AccountId = "";

            if (state.CustomerType) {
                CustomerType = state.CustomerType;
            }

            if (props.UpdatedataJSON && props.UpdatedataJSON.CustomerType) {
                CustomerType = props.UpdatedataJSON.CustomerType;
            }

            postHTML += `<tr align="left"><th ></th><th >Information</th></tr>`;

            if (state.ImagePic && state.ImagePic.length > 0) {
                PhotoService.push(state.ImagePic)
            }

            //const key = `Data::Company::${Cat}#${state.UUid}`;
            var currentUnixTime = new Date();
            var currentTime = currentUnixTime.toISOString();
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            let ShowDate = currentUnixTime.toLocaleDateString('us-EN', options);
            let TimeStamp = currentUnixTime.getTime();

            //make sure cid and companyid exist
            if (state.CompanyId !== "") {
                //console.log('CIDDDDDD', cid)
                CompanyId = state.CompanyId;
                CompanyName = state.CompanyName;
            } else {
                MissingInfoFlag = true;
                AlarmMessage += `* Please select Company `;
            }

            // put each form data in db
            if (AllItemForms && AllItemForms.length > 0) {
                AllItemForms.map((EachForm, index) => {

                    console.log('ItemAddPage.fix.EachForm', EachForm);


                    //if (!EachForm.ValueAdded && !FormDataBack.UpdateExisting) {
                    //    MissingInfoFlag = true;
                    //    AlarmMessage += `* Please complete ${EachForm.FormName} `;
                    //}

                    if (EachForm.Location && isNotEmptyObject(EachForm.Location)) {
                        console.log('ItemAddPage-Location1', EachForm.Location)
                        Location = EachForm.Location;
                    }

                    if (EachForm.PhotoService && EachForm.PhotoService.length > 0) {
                        PhotoService = [...PhotoService, ...EachForm.PhotoService];
                    }

                    if (EachForm.Documents && EachForm.Documents.length > 0) {
                        Documents = [...Documents, ...EachForm.Documents];
                    }

                    if (EachForm && EachForm.FormData && EachForm.FormData.length > 0) {

                        if (EachForm.Category && EachForm.Category !== "") {
                            console.log('ItemAddPage.Base', EachForm.Category)
                            SearchWords += `${EachForm.Category}: `;
                        }

                        EachForm.FormData.map((each, ind) => {
                            console.log('ItemAddPage.fix-each', each)
                            if (each.required) {
                                if (each.VariableInput === "") {
                                    //trigger alarm message if input value missing
                                    MissingInfoFlag = true;
                                    AlarmMessage += `* ${each.label} `;
                                }
                            }

                            //if (each.required || each.Searchable) {
                            //    if (each.VariableInput && each.VariableInput !== "") {
                            //        if (Object.keys(each.VariableInput).length === 0) {
                            //            SearchWords += `${each.VariableInput}, `;
                            //        }
                            //    }
                            //} 

                            if (each.ShowValue) {
                                SearchWords += `${each.ShowValue}, `;
                            }

                            //console.log('each.Category', state.Category, EachForm.Category)
                            if (state.Category === EachForm.Category) {
                                if (each.extra === "field1") {
                                    Field1 = each.VariableInput;
                                }
                                if (each.extra === "field2") {
                                    Field2 = each.VariableInput;
                                }
                                AllItemForms[index].key = key;
                                AllItemForms[index].sk1 = key;
                            }

                            if (each.VariableName === "Address" && isNotEmptyObject(each.Details)) {
                                console.log('ItemAddPage-Address1', each.Details)
                                Location = each.Details;
                                Address = each.Details.address;
                                // console.log('ItemAddPage-Location2', EachForm.Location)
                            }

                            //if (each.Category === "Location" && isNotEmptyObject(each.Details)) {
                            //    console.log('ItemAddPage-Location', each.Details)
                            //    Location = each.Details;
                            //    Address = each.Details.address;
                            //    //console.log('ItemAddPage-Location2', EachForm.Location)
                            //}

                            if (isNotEmptyObject(each.Location)) {
                                // console.log('ItemAddPage-Location3', each.Location)
                                Location = each.Location;
                            }

                            //New SMS public or dedicated
                            if (each.VariableName === "public") {
                                SMStype = each.VariableInput;
                            }

                            //
                            if (each.VariableName === "DocumentType") {
                                DocumentType = each.key;
                            }
                            //TimeZone
                            if (each.VariableName === "TimeZone") {
                                TimeZone = each.Details;
                                //Invitation.Mobile = each.VariableInput;
                            }

                            //allow or denay SMS
                            if (each.VariableName === "AllowSMSService") {
                                AllowSMSService = each.VariableInput;
                                //Invitation.Mobile = each.VariableInput;
                            }

                            if (each.VariableName === "receiveEmail") {
                                receiveEmail = each.VariableInput;
                            }
                            if (each.VariableName === "receiveSMS") { //
                                receiveSMS = each.VariableInput;
                            }
                            if (each.VariableName === "receivePhone") { 
                                receiveSMS = each.VariableInput;
                            }

                            //allow or denay SMS
                            if (each.VariableName === "AuthorityLevel") {
                                if (each.Details && each.Details.value) {
                                    Privileges = each.Details;
                                    Level = Privileges.value;
                                    AuthorityLevelFound = true;
                                    //Invitation.Mobile = each.VariableInput;
                                }
                            }

                            if (each.VariableName === "status") {
                                // status = each.VariableInput;
                                if (each.VariableInput === true) {
                                    status = "Active";
                                } else {
                                    status = "Disabled";
                                }
                            }

                            if (each.VariableName === "mobile") {
                                Mobile = each.VariableInput;
                                Invitation.Mobile = each.VariableInput;
                            }

                            if (each.VariableName === "AccountId") {
                                AccountId = each.VariableInput;
                            }

                            if (each.VariableName === "phone") {
                                Phone = each.VariableInput;
                                Invitation.Phone = each.VariableInput;
                            }

                            if (each.VariableName === "CompanyName") {
                                CompanyName = each.VariableInput;
                            }

                            if (each.VariableName === "email") {
                                Email = each.VariableInput.toLowerCase();
                                Invitation.Email = each.VariableInput.toLowerCase();

                                if (state.Category === "Person") {
                                    key = `Data::Company::Resource::Person::${WebsiteType}::${Email}`;
                                }

                            }
                            // }

                            if (state.Category === "Company"
                                || state.Category === "Contractor"
                                || state.Category === "Branch"
                                || state.Category === "Sub"
                                || state.Category === "Global"
                            ) {
                                if (each.VariableName === "name") {
                                    CompanyName = each.VariableInput;
                                }
                                // CompanyId = key;
                            }

                            if (each.extra === "Invite") {
                                // console.log('ItemAddPage.Invite.each', each)
                                Invitation[each.VariableName] = each.VariableInput;
                                //SendInvite
                                if (each.VariableName === "Invite") {
                                    switch (each.VariableInput) {
                                        case "email":
                                            SendEmailInvite = true;
                                            SendSMSInvite = false;
                                            break;
                                        case "SMS":
                                            SendSMSInvite = true;
                                            SendEmailInvite = false;
                                            break;
                                        case "no":
                                            SendEmailInvite = false;
                                            SendSMSInvite = false;
                                            break;
                                        default:
                                          //SendEmailInvite = false;
                                          //SendSMSInvite = false;
                                    }
                                }
                            }

                            if (each.PhotoService && each.PhotoService.length > 0) {
                                PhotoService = [...PhotoService, ...each.PhotoService];
                            }

                            if (each.Documents && each.Documents.length > 0) {
                                Documents = [...Documents, ...each.Documents];
                            }

                            if (each.VariableInput && Object.keys(each.VariableInput).length === 0) {
                                postHTML += `<tr align="left"><th >${each.VariableName}</th><th >${each.VariableInput}</th></tr>`;
                                postTEXT += `${each.VariableName}: ${each.VariableInput} \n`;
                            }

                        })
                    }
                })

                //if (FormExtra && FormExtra.length > 0) {
                //    FormExtra.map(each => {
                //        if (each && each.SearchWords) {
                //            SearchWords += `-- ${each.SearchWords}`;
                //        }

                //        if (each && isNotEmptyObject(each.Location)) {
                //            console.log('ItemAddPage-Address3', each.Location)
                //            Location = each.Location;
                //        }
                //    })
                //}

                //  console.log('ItemAddPage-SearchWords', SearchWords, state.Category)

                if (state.ImagePic && state.ImagePic.ImageFullPath) {
                    avatar = state.ImagePic.ImageFullPath;
                }
                //
                switch (state.Category) {
                    case "Person":
                        //Cat = "Resource::Person";
                        key = `Data::Company::Resource::Person::${WebsiteType}::${Email}`;
                        break;
                    case "Policy":
                        //Cat = "Resource::Person";
                        key = `Data::Policy::${DocumentType}::${currentTime}`;
                        break;
                    case "Teams":
                        //Cat = "Resource::Teams";
                        key = `Data::Company::Resource::Teams::${currentTime}`;
                        break;
                    case "Location":
                        //Cat = "Resource::Teams";
                        key = `Data::Company::Asset::Location::${currentTime}`;
                        break;
                    case "Equipment":
                        //Cat = "Resource::Teams";
                        key = `Data::Company::Asset::Equipment::${currentTime}`;
                        break;
                    case "Area":
                        //Cat = "Resource::Teams";
                        key = `Data::Online::Lambda::Area::${currentTime}`;
                        break;
                    case "Branch":
                        //Cat = "Resource::Teams";
                        key = `Data::Corp::Branch::Info::${currentTime}`;
                        break;
                    case "Company":
                        //Cat = "Info";
                        key = `Data::Company::Info::${currentTime}`;
                        if (FormDataBack.UpdateExisting) {
                            CustomerType = props.UpdatedataJSON.CustomerType;
                            CompanyId = props.UpdatedataJSON.CompanyId;
                        } else {
                            CustomerType = "Company";
                            CompanyId = key;
                        }
                       
                        break;
                    case "Global":
                        //Cat = "Info";
                        key = `Data::Company::Global::${currentTime}`;
                        if (FormDataBack.UpdateExisting) {
                            CustomerType = props.UpdatedataJSON.CustomerType;
                            CompanyId = props.UpdatedataJSON.CompanyId;
                        } else {
                            CustomerType = "Company";
                            CompanyId = key;
                        }
                        break;
                    case "Sub":
                        //Cat = "Info";
                        key = `Data::Sub::Info::${currentTime}`;

                        if (FormDataBack.UpdateExisting) {
                            CustomerType = props.UpdatedataJSON.CustomerType;
                            CompanyId = props.UpdatedataJSON.CompanyId;
                        } else {
                            CustomerType = "Sub";
                            CompanyId = key;
                        }

                        
                        break;
                    default:
                        //Cat = `Asset::${state.Category}`;
                        key = `Data::Company::Asset::${state.Category}::${currentTime}`;
                }

                //update key
                if (FormDataBack.UpdateExisting) {
                    key = state.sk1Update;

                    //when updating Admin who signed up
                    if (!AuthorityLevelFound
                        && props.UpdatedataJSON.Privileges
                        && props.UpdatedataJSON.Level) {
                        Privileges = props.UpdatedataJSON.Privileges;
                        Level = props.UpdatedataJSON.Level;
                    }

                }

                switch (state.Category) {
                    case "Person":
                        //Cat = "Resource::Person";
                        key = `Data::Company::Resource::Person::${WebsiteType}::${Email}`;

                        if (FormDataBack.UpdateExisting) {
                            status = status;
                        } else {
                            status = "Waiting";
                        }

                        if (props.UpdatedataJSON
                            && props.UpdatedataJSON.status === "Waiting") {
                            status = "Waiting";
                        }

                        DBInput = {
                            pk1: key,
                            sk1: key,
                            gsi1Pk2: `phone::${WebsiteType}::${Mobile}`,
                            gsi1Sk2: key,
                            gsi2Pk2: CompanyId,
                            gsi2Sk2: key
                        }
                        break;

                    case "GlobalPerson":

                        if (FormDataBack.UpdateExisting) {
                            status = status;
                        } else {
                            status = "Waiting";
                        }

                        if (props.UpdatedataJSON
                            && props.UpdatedataJSON.status === "Waiting") {
                            status = "Waiting";
                        }

                        Level = 350;

                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: BranchId,
                            gsi1Sk2: `phone::${WebsiteType}::${Mobile}`,
                            gsi2Pk2: HQId,
                            gsi2Sk2: `email::${Email}`
                        }
                        break;

                    case "Teams":
                        //Cat = "Resource::Teams";
                        //   key = `Data::Company::Resource::Teams::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;


                    case "AddSMS":
                        avatar = ContractorData.avatar;
                       // avatar = `https://${HQData.SubDomain}/public/logo512.png`;
                        let NewVariableInput = formatPhoneNumber(Mobile);
                        Field2 = `SMS/Text # ${NewVariableInput}`;
                        SubDomain = HQData.SubDomain;
                        TimeZone = ContractorData.TimeZone;
                        cid = ContractorData.id;
                        Address = ContractorData.Address;
                        Phone = ContractorData.phone;
                        status = "Active";
                        ContactCardFileFullPath = await CreateContactCard(Field1, Field2, Mobile)
                        DBInput = {
                            pk1: `phone::${Mobile}`,
                            sk1: `phone::${Mobile}`,
                            gsi1Pk2: `Phone::Developer::Account::0786`,
                            gsi1Sk2: currentTime,
                            gsi2Pk2: ContractorData.CompanyId,
                            gsi2Sk2: `phone::${Mobile}`
                        }
                        break;

                    case "Location":
                        //Cat = "Resource::Teams";
                        //   key = `Data::Company::Asset::Location::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Equipment":
                        //Cat = "Resource::Teams";
                        //    key = `Data::Company::Asset::Equipment::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Area":
                        //Cat = "Resource::Teams";
                        //  key = `Data::Online::Lambda::Area::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Branch":
                        //Cat = "Resource::Teams";
                        //  key = `Data::Corp::Branch::Info::${currentTime}`;
                        //if (ContractorData.avatar
                        //    && ContractorData.avatar !== ""
                        //    && avatar === ""
                        //) {
                        //    avatar = ContractorData.avatar;
                        //}
                        BranchId = key;
                        ContractorId = key;
                        CompanyId = key;
                        BranchType = "Branch";
                        CustomerType = "Contractor";
                        BranchName = Field1;
                        SubDomain = HQData.SubDomain;
                        Field2 = Address;
                        DBInput = {
                            pk1: HQId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: HQId,
                            gsi2Pk2: HQId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Company":
                        //Cat = "Info";
                        //   key = `Data::Company::Info::${currentTime}`; 
                        //CustomerType = "Company";
                        //CompanyId = key;
                        DBInput = {
                            pk1: BranchId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: BranchId,
                            gsi2Pk2: HQId,
                            gsi2Sk2: key
                        }
                        break;

                    case "Policy":
                        Field2 = `${Field2} - ${ShowDate}`;
                        DBInput = {
                            pk1: BranchId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: BranchId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                        break;

                    case "Global":
                        //Cat = "Info";
                        //   key = `Data::Company::Info::${currentTime}`; Global
                        //CustomerType = "Company";
                        //CompanyId = key;
                        DBInput = {
                            pk1: HQId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: BranchId,
                            gsi2Pk2: HQId,
                            gsi2Sk2: key
                        }
                        break;
                    case "Sub":
                        //Cat = "Info";
                        //   key = `Data::Company::Info::${currentTime}`;
                        //CustomerType = "Company";
                        //CompanyId = key;
                        Access = "Restricted";
                        DBInput = {
                            pk1: BranchId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: BranchId,
                            gsi2Pk2: HQId,
                            gsi2Sk2: key
                        }
                        break;
                    default:
                        //Cat = `Asset::${state.Category}`;
                        //   key = `Data::Company::Asset::${state.Category}::${currentTime}`;
                        DBInput = {
                            pk1: CompanyId,
                            sk1: key,
                            gsi1Pk2: key,
                            gsi1Sk2: CompanyId,
                            gsi2Pk2: BranchId,
                            gsi2Sk2: key
                        }
                }


                // console.log('ItemAddPage.fix.FormExtra', state.FormExtra, state.Category)

                //   console.log('ItemAddPage.Invite.SendSMSInvite', SendSMSInvite, SendEmailInvite)

                // postHTML += `<tr align="left"><th >ID</th><th >${key}</th></tr>`;
                postHTML += "</table>";

                FormdataJSON = {
                    AllItemForms: AllItemForms,
                    cid: cid,
                    BranchId: BranchId,
                    BranchName: BranchName,
                    AccountId: AccountId,
                    SMStype: SMStype,
                    BranchType: BranchType,
                    HQId: HQId,
                    HQName: HQName,
                    SubDomain: SubDomain,
                    status: status,
                    SearchWords: SearchWords,
                    AllowSMSService: AllowSMSService,
                    ContactCardFileFullPath: ContactCardFileFullPath,
                    Category: state.Category,
                    CustomerType: CustomerType,
                    Access: Access,
                    TimeZone: TimeZone,
                    TimeStamp: TimeStamp,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    receiveEmail: receiveEmail,
                    receiveSMS: receiveSMS,
                    receivePhone: receivePhone,
                    Location: Location,
                    ShowDate: ShowDate,
                    Address: Address,
                    avatar: avatar,
                    id: state.UUid,
                    key: key,
                    Privileges: Privileges,
                    Level: Level,
                    field1: Field1,
                    field2: Field2,
                    ContractorId: ContractorId,
                    date: currentTime,
                    posterId: {
                        pk1: UserData.pk1,
                        sk1: UserData.sk1,
                        field1: UserData.filed1,
                        field2: UserData.filed2,
                        avatar: UserData.avatar,
                        email: UserData.email,
                        mobile: UserData.mobile,
                        phone: UserData.phone,
                    },
                    PhotoService: PhotoService,
                    Documents: Documents,
                    phone: Phone,
                    mobile: Mobile,
                    email: Email,

                }

                console.log('ItemAddPage.fix-DB-FormdataJSON', FormdataJSON)
                console.log('ItemAddPage.fix-DB-DBInput', DBInput)
                //adding new SMS for contractor
                //if (state.Category === "AddSMS") {
                //    FormdataJSON = {
                //        ...FormdataJSON,
                //        pk1: `phone::${Mobile}`,
                //        sk1: ContractorId,
                //        gsi2Pk2: ContractorId,
                //        gsi2Sk2: `phone::${Mobile}`,
                //    }
                //}

                // console.log('ItemAddPage-FormdataJSON', FormdataJSON)

                // dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))


                FormDD = {
                    // AllItemForms: FormData,
                    ExtraInfo: true,
                    SearchWords: SearchWords,
                    Category: state.Category,
                    FormType: "AutoComplate",
                    Location: Location,
                    field1: Field1,
                    field2: Field2,
                    pk1: CompanyId,
                    sk1: key,
                }

                //console.log('FormDD', FormDD, props.id, props.key)

            }

            //if alerts
            if (MissingInfoFlag) {

                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: AlarmMessage,
                    OpenAlert: true
                }));

            } else {

                //let NewDBinput = {
                //    ...DBInput,
                //    dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
                //}



                ////InputArray.push(NewDBinput);

                //console.log('ItemAddPage-DB-props.UpdatedataJSON', props.UpdatedataJSON)
                //console.log('ItemAddPage-DB-FormdataJSON', FormdataJSON)
                //console.log('ItemAddPage-DB-NewDBinput', NewDBinput)


                //image resize lambda
                if (PhotoService.length > 0) {
                    API.post("ImageResize", "/send", {
                        body: { Records: PhotoService }
                    });
                }

                if (SendSMSInvite) {

                    var SMSstring = {};
                    let SMSLoop = [
                        {
                            Body: `${Invitation.message}. Use following link to setup your system password and access https://${HQData.SubDomain}`,
                            FromSMS: Invitation.Mobile,
                            To: [Invitation.Mobile],
                            mediaUrl: [],
                        }
                    ]

                    if (CustomerType === "Company") {
                        if (CurrentCompany
                            && CurrentCompany.SelectedSMSArray
                            && CurrentCompany.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = CurrentCompany.SelectedSMSArray;
                        } else if (ContractorData
                            && ContractorData.SelectedSMSArray
                            && ContractorData.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = ContractorData.SelectedSMSArray;
                        }

                        if (CurrentCompany
                            && CurrentCompany.SelectedVideo
                            && CurrentCompany.SelectedVideo.length > 0
                        ) {
                            SelectedVideo = CurrentCompany.SelectedVideo;
                        } else if (ContractorData
                            && ContractorData.SelectedVideo
                            && ContractorData.SelectedVideo.length > 0
                        ) {
                            SelectedVideo = ContractorData.SelectedVideo;
                        }

                        

                        if (SelectedVideo.length > 0) {

                            SMSLoop.push({
                                Body: "Please see the following introductory video(s)",
                                mediaUrl: [],
                            })

                            SelectedVideo.map(each => {
                                SMSLoop.push({
                                    Body: each,
                                    mediaUrl: [],
                                })
                            })
                        }

                        if (SelectedSMS && SelectedSMS.length > 0) {

                            SMSLoop.push({
                                Body: "Please save each contact in your mobile device. Use the number to request service.",
                                mediaUrl: [],
                            })

                            SelectedSMS.map((each, indd) => {
                                // mediaUrl.push(each.ImageFullPath)
                                SMSLoop.push({
                                    Body: each.field1,
                                    mediaUrl: [each.ImageFullPath],
                                })

                            })
                        }
                    }

                    SMSLoop.map((each, insd) => {

                        SMSstring = {
                            Body: each.Body,
                            FromSMS: Invitation.Mobile,
                            From: "+15623936334",
                            To: [Invitation.Mobile],
                            mediaUrl: each.mediaUrl,
                            pk1: CompanyId,
                            CompanyId: CompanyId,
                            ContractorId: ContractorId,
                            SMSList: [{
                                PersonName: Field1,
                                PersonId: Field1,
                                phone: Invitation.Mobile
                            }]
                        };

                        console.log("ItemAddPage.Invite.SMS", SMSstring)

                        API.post("SMStoTwilio", "", {
                            body: { Records: SMSstring }
                        });

                        SMSstring = {};

                    })


                }

                if (SendEmailInvite) {

                    let InvitationMessage = Invitation.message;

                    InvitationMessage += ` Click on the button to access the system and setup password. `;
                    InvitationMessage += `Please use the links listed under Documents to access the service SMS numbers. Save the numbers to your mobile device. `;

                    if (CustomerType === "Company") {
                        if (CurrentCompany
                            && CurrentCompany.SelectedSMSArray
                            && CurrentCompany.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = CurrentCompany.SelectedSMSArray;
                        } else if (ContractorData
                            && ContractorData.SelectedSMSArray
                            && ContractorData.SelectedSMSArray.length > 0
                        ) {
                            SelectedSMS = ContractorData.SelectedSMSArray;
                        }

                        if (SelectedSMS.length > 0) {

                          //  InvitationMessage += `Please see the following introductory video(s) under Images `;

                            SelectedSMS.map((each, innn) => {
                                //introVideo = ContractorData.IntroVideoList.find(x => x.key === each);
                                //console.log("ItemAddPage.Invite1.introVideo", each, introVideo)
                                //if (Object.keys(introVideo).length > 0) {
                                    //  console.log("ItemAddPage.Invite.email", emailstring)
                                Documents.push(each);
                            //    }
                            //    introVideo = {};
                            })
                        }

                        console.log("ItemAddPage.Invite1.SelectedSMS", SelectedSMS)
                        if (CurrentCompany
                            && CurrentCompany.SelectedVideo
                            && CurrentCompany.SelectedVideo !== ""
                        ) {
                            SelectedVideo = CurrentCompany.SelectedVideo;
                        } else if (ContractorData
                            && ContractorData.SelectedVideo
                            && ContractorData.SelectedVideo.length > 0
                        ) {
                            SelectedVideo = ContractorData.SelectedVideo;
                        }

                        InvitationMessage += `<p><a href="https://youtu.be/IE4d-PX4WwY" target="_blank">
                            <img alt="SEO" src="https://www.service-opportunity.com/images/intro_video_still.png">
                        </a></p>`;

                        if (SelectedVideo.length > 0) {

                            InvitationMessage += ` See the additional introductory video(s) under Images `;

                            SelectedVideo.map((each, innn) => {
                                introVideo = ContractorData.IntroVideoList.find(x => x.key === each);
                                console.log("ItemAddPage.Invite1.introVideo", each, introVideo)
                                if (Object.keys(introVideo).length > 0) {
                                  //  console.log("ItemAddPage.Invite.email", emailstring)
                                    PhotoService.push(introVideo);
                                }
                                introVideo = {};
                            })
                        }
                    }

                    // let ContractorSubDomain = `https://${ContractorData.SubDomain}.service-call-cloud.com`;
                    var emailstring = {

                        PhotoService: PhotoService,
                        Documents: Documents,
                        SpecialInstructions: state.SpecialInstructions,
                        PreSelect: [],
                        title: `Invitation for ${ContractorData.CompanyName} Notification System`,
                        postHTML: InvitationMessage,
                        postTEXT: InvitationMessage,
                        PersonList: [Invitation.Email],
                        ReplyEmail: "noreply@sninc.us", //UserData.email,
                        PostId: state.NewId,

                        ContractorAddress: ContractorData.Address,
                        ContractorPhone: ContractorData.phone,
                        ContractorLogo: ContractorData.avatar,
                        ContractorName: ContractorData.CompanyName,
                        ContractorId: ContractorData.CompanyId,
                        CompanyId: CompanyId,
                        avatar: HQData.avatar,

                        UnsubscribeLink: `https://${HQData.SubDomain}/Unsubscribe`,
                        ActionButton: `https://${HQData.SubDomain}`,
                        ActionButtonTitle: "Setup Account",
                        ActionButtonShow: true,
                    };

                      console.log("ItemAddPage.Invite1.email", emailstring)
                    SendEmail(emailstring);

                }

                //if (SendEmailInvite) {
                //  API.post("TriggerEmail", "/post", {
                //    body: { Records: PhotoService }
                //  });
                //}

                //  if (InputArray.length > 0) {
                //updating
                if (FormDataBack.UpdateExisting) {

                    let newFormdataJSON = {
                        ...props.UpdatedataJSON,
                        ...FormdataJSON,
                        ...DBInput,
                        id: props.UpdatedataJSON.id,
                        key: state.sk1Update,
                        pk1: state.pk1Update,
                        sk1: state.sk1Update,
                    }

                    let UpdateDataaa = {
                        ...DBInput,
                        pk1: state.pk1Update,
                        sk1: state.sk1Update,
                        dataJSON: JSON.stringify(removeEmptyStringElements(newFormdataJSON))
                    }

                    //console.log('ItemAddPage-DB-Update--props.UpdatedataJSON', props.UpdatedataJSON)
                    //console.log('ItemAddPage-DB-Update--FormdataJSON', FormdataJSON)
                    console.log('ItemAddPage.fix-DB-Update--props.UpdatedataJSON', props.UpdatedataJSON)
                    console.log('ItemAddPage.fix-DB-Update--total', newFormdataJSON)
                    console.log('ItemAddPage.fix-DB-Update-pksk', UpdateDataaa)
                    console.log('ItemAddPage.fix-DB-state', state)

                    if (state.sk1 === UserData.sk1) {
                        dispatch({ type: 'USER_DATA', payload: newFormdataJSON });
                    }

                    UpdateData(UpdateDataaa)
                        .then((up) => {
                            // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                            if (props.CloseDrawer) {
                                props.CloseDrawer();
                            }

                        })
                        .catch((err) => console.log("SRDetails.failed", err));

                } else {

                    let Newentry = {
                        ...FormdataJSON,
                        ...DBInput
                    }

                    let NewDBinput = {
                        ...DBInput,
                        dataJSON: JSON.stringify(removeEmptyStringElements(Newentry))
                    }

                    console.log('ItemAddPage.fix-DB-New-DataJson', Newentry)

                    API.graphql(graphqlOperation(createSvcbotDb, { input: NewDBinput }))
                        .then((results) => {

                            console.log('ItemAddPage.createBatch ', results);

                            if (props.TriggerUpdate) {
                                dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                            }

                            //full data
                            if (props.ReturnBackData) {
                                props.ReturnBackData(Newentry);
                            }

                            //close
                            if (props.CloseDrawer) {
                                props.CloseDrawer();
                            }

                        })

                    if (props.CloseDrawer) {
                        props.CloseDrawer(Newentry);
                    }
                }


            } // end of no alerts

        } catch (err) { console.log("SaveData err", err); }
    }

    const CreateContactCard = (Field1, Field2, Mobile) => {

        let NewFileName = NewPageId(`SMSContactCard`) + ".vcf";

        //create a new vCard
        var vCard = {};

        //set properties
        vCard.firstName = Field1;
        vCard.middleName = "";
       // vCard.lastName = Field2;
        vCard.organization = ContractorData.CompanyName;
        vCard.cellPhone = Mobile;
        vCard.version = 3.0;
        vCard.title = Field2;
        vCard.workUrl = `https://${HQData.SubDomain}`;
        let Newcardfile = getFormattedString(vCard);

        Storage.put(NewFileName, Newcardfile, {
            bucket: HQData.Bucket,
            contentType: "text/vcard"

        }).then((ret) => console.log("ItemAddPage-Blob-image back", ret)
        ).catch(err => console.log("ItemAddPage-Blob-image err", err))

        let ContactCardFileFullPath = `https://s3.amazonaws.com/${HQData.Bucket}/public/${NewFileName}`;

        return ContactCardFileFullPath;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };

    console.log('ItemAddPage-state', state)
    console.log("Testing.Form.Edit.ItemAddPage.state", state);
    console.log("Testing.Form.Edit.ItemAddPage.props", props);

    return (
        <Page
            key={props.id}
            title="Account"
        >
            <Container >
                <Grid
                    container
                    spacing={1}
                >
                    
                    {props.ImageUpload && (
                        <Grid
                            item
                            lg={4}
                            md={6}
                            xs={12}
                        >
                            <ItemProfilePicture
                                ImagePic={imgg => Image(imgg)}
                                AcceptFileType="image/*"
                                DefaultImage={state.ImagePic}
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        lg={8}
                        md={6}
                        xs={12}
                    >
                       

                        <ItemAddForms
                            //  ReturnBackData={e => ReturnBackData(e)}
                            //  AutoCompleteReturn={e => AutoCompleteReturn(e)}
                            Category={state.Category}
                            SaveData={(e) => SaveData(e)}
                            CompanyId={state.CompanyId}
                            //  UpdatePk1={state.pk1}
                            //  UpdateKey={state.sk1}
                            key={props.id}
                            id={props.id}
                            // UpdateFormJSON={state.AllItemForms}
                            UpdatedataJSON={props.UpdatedataJSON}
                            DisableForm={state.DisableForm}
                            // FormExtra={state.FormExtra}
                            UpdateExisting={state.UpdateExisting}
                        />


                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>  
      </Snackbar>
          */}

        </Page>
    );
};

export default Account;
