import React, { useState, useEffect } from 'react';
import {
  Container,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  CardActions,
  Button,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import { removeEmptyStringElements, NewPageId, getAutoCompletedata } from '../utils/CommonGraphql';
import BuildIcon from '@mui/icons-material/Build';
import ListRadio from '../List/ListRadio';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';
//import DynamicForms from '../Forms/FormShow';
//import ItemAddForms from '../views/account/AccountView/ItemAddForms';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const Account = (props) => {
  const classes = useStyles();
  const params = useParams();
  let query = useQuery();
  const UUid = NewPageId("SvcOpt");
  const ContractorData = useSelector(state => state.ContractorData);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const HQData = useSelector(state => state.HQData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  console.log('cid cid ', cid)
  const initialState = {
    Category: "Company",
    //FormJSON: [],
    FormData: [],
    AlarmMessage: "",
    ServiceFormOptions: "",
    field2: cid.field1,
    field1: cid.field1,
    AppliesTo: "Company",
    NewLoadComponent: "NewServiceSetup",
    Selection: [],
    ItemId: "",
    CompanyId: "",
    PresentSelected: [],
    ShowAdd: true,
    Title: "",
    Description: "",
    OpenAlert: false,
    ImagePic: ""
  }
  const [state, setState] = useState(initialState);
  const [loading, SetLoading] = useState(false);


 /* let data = {
    LoadComponenet: loadcomponent,
    Category: Category,
    CompanyInfo: state.CompanyInfo,
    ListType: ListType,
    ItemInfo: state.ItemInfo,
    field2: state.CompanyInfo.field1,
    field1: state.ItemInfo.field1,
    NewLoadComponent: NewLoadComponent,
    AppliesTo: state.AppliesTo,
    key: NewPageId("key")
  }*/

  useEffect(() => {
    
    console.log("SelectNewprops", props);
    let ShowAdd = true;
    if (props.Data.NewLoadComponent === "FormSetup") {
      ShowAdd = false;
    }

    if (props && props.Data) {
      setState(prevState => ({
        ...prevState,
        AppliesTo: props.Data.AppliesTo,
        //AppliesTo: props.Data.AppliesTo,
        Category: props.Data.Category,
        CompanyInfo: props.Data.CompanyInfo,
        ShowAdd: ShowAdd,
        CompanyId: props.Data.CompanyInfo.key,
        ItemId: props.Data.ItemInfo.id,
        ItemInfo: props.Data.ItemInfo,
        field2: props.Data.field2,
        field1: props.Data.field1,
        FormType: props.Data.FormType,
        NewLoadComponent: props.Data.NewLoadComponent,
        ListType: props.Data.ListType,
      }))

      GetDatafromDB("Online", props.Data.Category);

    }

  }, [props])


  const GetDatafromDB = async (Category, LookUp) => {
    SetLoading(true);
    let key = "";
    switch (LookUp) {
      case "NewService":
        key = `Data::Online::NewService`;
        break;
      case "ESC":
        key = `Data::Online::Lambda::ESC`;
        break;
      case "SMS":
        key = `Data::Online::Lambda::SMS`;
        break;
      case "Form":
        key = `Data::Online::Form`;
        break;
      default:
        key = `Data::Online::NewService`;
    }

    const DataString = {
      Category: Category,
      FindArray: [
        {
          ArrayName: "ServiceOptions",
          ArraySearchString: key,
        }
      ]
    };

    let NewData = await getAutoCompletedata(DataString, ContractorData.ContractorId);
    setState(prevState => ({ ...prevState, ...NewData }))

    SetLoading(false);
    console.log("NewData", NewData);
  }

  const ClosingDrawer = (e) => {
    //console.log("ClosingDrawer", e);
    //let CategoryItems = state.ServiceOptions;
    //setState(prevState => ({ ...prevState, ServiceOptions: [e, ...CategoryItems] }))
    GetDatafromDB("Online", props.Data.Category);
    SetDeawerOpen(false)
  }


  ////////////////////////////////////////////
  const SaveData = () => {
    // console.log('SaveData', FormData);
    try {

      //let FormData = state.FormData;
      //const key = `Data::Notify::${props.Category}#${UUid}`;

      //CompanyInfo: props.Data.CompanyInfo,
      //  ItemInfo: props.Data.ItemInfo,

      //CompanyId: props.Data.CompanyInfo.key,
      //  ItemId: props.Data.ItemInfo.key,

      let key = "";

      switch (state.Category) {
        case "NewService":
          key = `Data::Online::Pref::NewService#${state.AppliesTo}#${state.ItemId}#${UUid}`;
          break;
        case "ESC":
          key = `Data::Online::Lambda::Pref::ESC#${state.AppliesTo}#${state.ItemId}#${UUid}`;
          break;
        case "SMS":
          key = `Data::Online::Lambda::Pref::SMS#${state.AppliesTo}#${state.ItemId}#${UUid}`;
          break;
        case "Form":
          key = `Data::Online::Pref::Form::${state.FormType}#${state.AppliesTo}#${state.ItemId}#${UUid}`;
          break;
        default:
          key = `Data::Online::Lambda::Pref::${state.Category}#${state.AppliesTo}#${state.ItemId}#${UUid}`;
      }

      var currentUnixTime = new Date();
      var currentTime = currentUnixTime.toISOString();

      //   let posterId = User;

      
      let SearchWords = `${state.field1} | ${state.field2} | `;
      let MissingInfoFlag = false;
      let dataJSON = "";
      let FormdataJSON = {};
      let AlarmMessage = 'Please enter ';
      //let DBInput = {};
      let InputArray = [];
      let CompanyId = state.CompanyId;
      let CompanyName = state.CompanyInfo.field1;
      let FormDD = {};

      let findselected = "";
      if (state.ServiceOptions) {
        state.Selection.map(each => {
          findselected = state.ServiceOptions.find(x => x.key === each);
          if (findselected) {
            SearchWords += `${findselected.field1} ${findselected.field2} | `;
          }
          
        })
      }

      FormdataJSON = {
        //FormData: FormData,
        SearchWords: SearchWords,
        Active: true,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        Category: state.Category,
        AppliesTo: state.AppliesTo,
          UserId: state.ItemInfo.id,
          HQId: HQData.HQId,
          HQName: HQData.HQName,
          SubDomain: HQData.SubDomain,
        id: UUid,
        key: key,
        field1: state.field1,
        field2: state.field2,
        ContractorId: ContractorData.ContractorId,
        date: currentTime,
        posterId: UserData,
        Selection: state.Selection,
        EditLoadComponent: state.NewLoadComponent,
        pk1: CompanyId,
        sk1: key,
        gsi1Pk2: state.ItemInfo.key,
        gsi1Sk2: key,
        gsi2Pk2: ContractorData.ContractorId,
        gsi2Sk2: key,
      }



      dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))


      //FormDD = {
      //  ...FormData,
      //  SearchWords: SearchWords,
      //}

      //console.log('FormDD', FormDD, props.id, props.key)



      //if alerts
      if (MissingInfoFlag) {
        setState(prevState => ({
          ...prevState,
          AlarmMessage: AlarmMessage,
          OpenAlert: true
        }));
      } else {
        //if no alerts

        let DBInput = {
          pk1: CompanyId,
          sk1: key,
          gsi1Pk2: state.ItemInfo.key,
          gsi1Sk2: key,
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: key,
          dataJSON: dataJSON
        }

        InputArray.push(removeEmptyStringElements(DBInput));

        let DBInput2 = {
          pk1: CompanyId,
          sk1: "Data::Online::Pref::Update",
          gsi1Pk2: ContractorData.ContractorId,
          gsi1Sk2: currentTime,
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: "Data::Online::Pref::Update",
          //dataJSON: dataJSON
        }

        InputArray.push(removeEmptyStringElements(DBInput2));

        console.log('FormdataJSON', FormdataJSON)
        console.log('DBInput', DBInput)

        if (InputArray.length > 0) {
          API.graphql(graphqlOperation(createBatch, { input: InputArray }))
            .then((results) => {
              console.log('createBatch Data ', results);

              if (props.CloseDrawer) {
                props.CloseDrawer(FormDD);
              }
            })

          if (props.CloseDrawerAdd) {
            props.CloseDrawerAdd(FormdataJSON);
          }
        }

      } // end of no alerts

    } catch (err) { console.log("SaveData err", err); }
  }


  const handleClose = (event, reason) => {
    //if (reason === 'clickaway') {
    //  return;
    //}

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));
    //Snackbar, Alert
  };

  //const OnFormSubmit = (e) => {
  //  setState(prevState => ({ ...prevState, FormData: [e] }));
  //}

  //const ReturnBackData = form => {
  //  console.log('formItemAddForm', form);
  //  setState(prevState => ({
  //    ...prevState,
  //    FormData: form
  //  }));
  //}

  //console.log("UUid", UUid)

  const KeyArray = (data, cat) => {
    console.log("KeyArray", data, cat)
    setState(prevState => ({
      ...prevState,
      [cat]: data
    }));
  }

  const ShowDetails = data => {

      setState(prevState => ({
        ...prevState,
        AppliesTo: props.Data.AppliesTo,
        CompanyInfo: props.Data.CompanyInfo,
        ItemInfo: data,
        //field2: props.Data.CompanyInfo.field1,
        //field1: props.Data.ItemInfo.field1,
        NewLoadComponent: "NewServicePage",
      }))
    
    SetDeawerOpen(true);
  }

  const AddNewService = () => {

      setState(prevState => ({
        ...prevState,
        AppliesTo: props.Data.AppliesTo,
        CompanyInfo: props.Data.CompanyInfo,
        ItemInfo: props.Data.ItemInfo,
        //field2: props.Data.CompanyInfo.field1,
        //field1: props.Data.ItemInfo.field1,
        NewLoadComponent: props.Data.NewLoadComponent,
      }))
    SetDeawerOpen(true);
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          //avatar={
          //  <Avatar>
          //      <BuildIcon />
          //  </Avatar>
          //}
          action={
            <Button variant="contained" color="primary" onClick={() => AddNewService(true)}>
              Add
              </Button>
          }
          title={state.field1}
          subheader={state.field2}
        />
        <CardContent>
          <RenderModule
            LoadComponenet={state.ListType}
            PerPage={10}
            //DataBack={state.ServiceOptions}
            propss={{
              DataBack: state.ServiceOptions,
              RawData: state.ServiceOptions,
              PresentSelected: state.PresentSelected
            }}
            SelectedKeyArray={(data) => KeyArray(data, "Selection")}
            AddBox={(data) => ShowDetails(data)}
            ShowUpDown={false}
            ShowEdit={true}
          />
        </CardContent>
        <CardActions>
         
            <Button
              color="primary"
            variant="contained"
            disabled={state.Selection && state.Selection.length < 1}
              onClick={() => SaveData()}
            >
              Save details
          </Button>
        
        </CardActions>
      </Card>


     

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DrawerShow
        LoadComponenet={state.NewLoadComponent}
        propss={{
          CloseDrawer: () => ClosingDrawer(), 
          CompanyInfo: state.CompanyInfo,
          AppliesTo: state.AppliesTo,
          ItemInfo: state.ItemInfo,
          FormType: state.FormType
        }}
        DrawerStatus={DeawerOpen}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />

      

          <Snackbar
              open={state.OpenAlert}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              message={state.AlarmMessage}
          />
          {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}
    </>
  );
};

export default Account;
