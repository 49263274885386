import React, { useEffect, useState } from 'react';
import {
  TextField,
  //Collapse,
  Typography,
  //Paper,
  Grid,
  Button,
  //Backdrop,
  //CircularProgress,
  Card,
  //FormGroup,
  CardContent,
  CardActions,
  //InputLabel, FormControl, MenuItem, Checkbox, 
  //RadioGroup,
  //FormControlLabel,
  //Radio, IconButton, CardHeader,
  //Switch,
  //Tooltip, Divider
} from '@mui/material';

export default function RadioButtonsGroup(props) {

  const initialState = {
    SpecialInstructions: "",
  }
  const [state, setState] = useState(initialState);
  const [DrawerStatus, OpenDrawer] = useState(false);

  useEffect(() => {

    //if (props && props.StepValueArray) {
    //  setState(prevState => ({
    //    ...prevState,
    //    ...props.StepValueArray[props.id],
    //  }));
    //} 

    let StepValueArray = {};
    if (props.KeyId && props.StepValueArray) {
      StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
    }
    console.log("Priority.StepValueArray", StepValueArray)
    if (props && props.StepValueArray) {
      setState(prevState => ({
        ...prevState,
        ...StepValueArray,
      }));
    }

  }, [])

  
  const SetValues = (NewColors) => {

    console.log("SpecialInstructions", NewColors)

    setState(prevState => ({
      ...prevState,
      SpecialInstructions: NewColors
    }));

    //let SelectedDataReturn = {
    //  SpecialInstructions: NewColors,
    //  id: props.id
    //}
    //// 
    //if (props.SelectedDataReturn) {
    //  props.SelectedDataReturn(SelectedDataReturn)
    //}

  }

  const handleNext = () => {

    let SelectedDataReturn = {
      //UseCustomSMS: UseCustomSMS,
      //SMSQuestions: SMSQuestions,
      ...state,
      id: props.id,
      KeyId: props.KeyId
    }
    console.log("SMSListQuestions.key", SelectedDataReturn)
    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(SelectedDataReturn)
    }

    if (props.handleNext) {
      props.handleNext();
    }

  }


  console.log("SpecialInstructions.state", state)
  console.log("SpecialInstructions.props", props)

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography >The Special Instructions will be shown on the email notificaion for the field techs</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="SpecialInstructions1"
            key="SpecialInstructions2"
            disabled={state.disabled}
            label="Special Instructions"
            variant="outlined"
            name="SpecialInstructions"
            value={state.SpecialInstructions || ""}
            fullWidth
            multiline
            rows={3}
            onChange={(e) => SetValues(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <div>
              <Button
                disabled={props.activeStep === 0}
                onClick={() => props.handleBack()}
              // className={classes.button}
              >
                Back
                              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNext()}
              //  className={classes.button}
              >
                {'Next'}
              </Button>
            </div>
          </div>
        </Grid>

      </Grid>

    </>

  );
}
