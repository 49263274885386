import React, { useState, useEffect } from 'react';
//import makeStyles from '@mui/styles/makeStyles';
import {
    Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
    ListItem, IconButton, ListItemText, List
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//const useStyles = makeStyles((theme) => ({
//    root: {
//        width: '100%',
//        //maxWidth: 360,
//        backgroundColor: theme.palette.background.paper,
//    },
//    paginator: {
//        justifyContent: "center",
//        padding: "10px"
//    }
//}));

export default function CheckboxList(props) {
    
    const [selectedValue, setSelectedValue] = useState(null);
    const [noOfPages, SetnoOfPages] = useState(0);

    const initialState = {
        DataBack: [],
        propss: {},
        TotalCount: 0
    }
    const [state, setState] = useState(initialState);
    const itemsPerPage = props.PerPage || 10;
    const [page, setPage] = useState(1);

    useEffect(() => {
        let DataBack = props.DataBack;
        if (DataBack) {
            console.log("ListRadio.Props", props);
            setState(prevState => ({ ...prevState, DataBack: DataBack }));
            SetnoOfPages(Math.ceil(DataBack.length / itemsPerPage))
        }

        //if (props.defaultValue) {
        //    setSelectedValue(props.defaultValue)
        //}

    }, [props.DataBack])

    useEffect(() => {
        if(props.defaultValue !== ""){
            setSelectedValue(props.defaultValue)
        }

    }, [props.defaultValue])

    const handleClick = (event) => {
        props.AddBox(event);
    };

    const NarrowSearch = (search) => {
        try {
            let Newdata = [];
            let RawData = props.DataBack;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.SearchWords) {
                        if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                            Newdata.push(str);
                        }
                    }
                })
                //
                console.log("Newdata search", Newdata);
            } else {
                Newdata = RawData;
            }
            SetnoOfPages(Math.ceil(Newdata.length / itemsPerPage))
            setState(prevState => ({ ...prevState, DataBack: Newdata }));
        } catch (err) { console.log("NarrowSearch err", err); }
    }

    useEffect(() => {
        if (props.SearchWords && props.SearchWords !== "") {
            NarrowSearch(props.SearchWords);
        }
    }, [props.SearchWords])

    const handleChange = (event) => {
        let New = event.target.value;
        setSelectedValue(New);
        if (props.SelectedKeyArray) {
            props.SelectedKeyArray(New)
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    console.log("ListRadio.state", state);
    console.log("ListRadio.selectedValue", selectedValue);

    return (
        <>
            
            
            <FormControl variant="outlined" style={{ width: '100%' }} >
                <FormLabel component="legend">{props.title}</FormLabel>
                <RadioGroup
                    aria-label={props.title}
                    name="RadioSelect"
                   // defaultValue={}
                    error={props.ErrorMessage}
                    value={selectedValue || ""}
                    required={props.required}
                    onChange={(e) => handleChange(e)}
                >
                    <List sx={{ width: '100%'}}>
                    {state.DataBack &&
                        state.DataBack.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((each, ii) => (

                            <ListItem
                                key={each.key + ii + 2}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() => props.DetailLink(each)}
                                        aria-label="comments">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                disablePadding
                            >
                                
                                <FormControlLabel
                                    id={each.key + ii}
                                  //  disabled={state.disabled}
                                    value={each.key}
                                    control={<Radio />}
                                    />
                                    
                                <ListItemText
                                    id={each.key + ii + ii}
                                    primary={each.field1}
                                    secondary={each.field2}
                                />
                                
                            </ListItem>
                        ))}
                    </List>
                </RadioGroup>
            </FormControl >

            {
                noOfPages > 1 && (
                    <Box >
                        <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handlePageChange}
                            defaultPage={1}
                            color="primary"
                            size="small"
                            showFirstButton
                            showLastButton

                        />
                    </Box>
                )
            }


        </>
    );
}
