import React, { useState, useEffect } from 'react';
import { Box, Container, Backdrop, CircularProgress, Grid } from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import ToolbarHeading from '../components/SRToolbarHeading';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, MiddlePostSearch, ContreactorPostSearch } from '../utils/CommonGraphql';
import SRList from '../List/SRColumn';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime';
import SRListCompany from '../List/SRListCompany';
import { API, graphqlOperation } from "aws-amplify";
import {
    onCreateSvcbotDbgsi1,
    onCreateSvcbotDbgsi2, onCreateSvcbotDb,
    onUpdateCompany, onUpdateContractor
} from '../graphql/subscriptions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


//let Todaydate = new Date();
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = adjustedTime(1, "End");

//console.log("SRPage.Date.olddate", olddate);
//console.log("SRPage.Date.Todaydatestring", Todaydatestring);

const ListItems = (props) => {

    let query = useQuery();
    console.log("query", query);

    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch();
    const NowEditing = useSelector(state => state.NowEditing)
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const AccountAccess = useSelector(state => state.AccountAccess);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 12,
        //ListType: "ListModule",
        ListType: "ListModule",
        CompanyId: UserData.CompanyId,
        pk1: UserData.CompanyId,
        propss: {},
        key: NewPageId("SRList"),
        SMSType: "Any",
        StartDate: "",
        EndDate: "",
        Sort: "Progress",
        Data: [],
        FullData: [],
        FullDataNew: [],
        NewSubscriptionData: "",
        SearchTerms: "",
        AverageResponse: [],
        Refresh: 0
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('SRPage.params', params);
        console.log('CompanyPage.TabList.SRPage.props', props);

        let Category = "Company";
        let DetailComponent = "Details"
        let ListType = "ListModule";
        let CompanyId = UserData.CompanyId;  //ContractorData.ContractorId;
        let CompanyName = UserData.CompanyName;
        let startdatt = olddate;
        let enddatt = Todaydatestring;

        if (params.CompanyId) {
            CompanyId = params.CompanyId
        }

        if (props.CompanyId) {
            CompanyId = props.CompanyId;
        }

        console.log("SRPage.Date.startdatt", startdatt);
        console.log("SRPage.Date.enddatt", enddatt);

        const UUid = NewPageId(Category);
        setState(prevState => ({
            ...prevState,
            Category: Category,
            UUid: UUid,
            StartDate: startdatt,
            EndDate: enddatt,
            ListType: ListType,
            CompanyId: CompanyId
        }));


        //get data
        console.log("SRPage.DBinput1", CompanyId, startdatt, enddatt);

        GetDatafromDB(CompanyId, "useEffect-s", startdatt, enddatt);

    }, [])

    const GetDatafromDB = async (pk1, from = "", startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let ShowString = {};
        let AverageResponse = [];
        let ResponseObj = [];

        if (startDate == null || startDate === "") {
            startDate = state.StartDate
        }

        if (endDate == null || endDate === "") {
            endDate = state.EndDate
        }

        let UTCdate = new Date(endDate).toISOString();
        let StartDate = new Date(startDate).toISOString();

        if (NowEditing === "Contractor" || NowEditing === "Sub") {
            NewData = await ContreactorPostSearch(`Post::${ContractorData.CompanyId}`, StartDate, UTCdate);
        } else {
            NewData = await MiddlePostSearch("Post::" + pk1, StartDate, UTCdate);
        }

        if (NewData && NewData.length > 0) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                AutoSelectData.push(ShowString);

                if (ShowString.ResponseStartTime
                    && ShowString.ResponseEndTime
                    && ShowString.ResponseEndTime > ShowString.ResponseStartTime) {
                    AverageResponse.push((ShowString.ResponseEndTime - ShowString.ResponseStartTime) / 60000);

                }
            })
        }

       // let AverageResponse = Math.round(ResponseTime / ResponseCount);

        if (AverageResponse.length > 0) {

            const sum = AverageResponse.reduce((a, b) => a + b, 0);
            const avg = Math.round((sum / AverageResponse.length)) || 0;

            var min = Math.round(Math.min(...AverageResponse));
            var max = Math.round(Math.max(...AverageResponse));

            ResponseObj = [
                {
                    label: "Average Response:",
                    value: avg
                },
                {
                    label: "Longest Response:",
                    value: max
                },
                {
                    label: "Shortest Response:",
                    value: min
                },
            ]

        }
        
        setState(prevState => ({
            ...prevState,
            AverageResponse: ResponseObj,
            Refresh: 1
        }))

        console.log("AverageResponse", min, max, AverageResponse);

        SeperateSubAccounts(AutoSelectData, state.SMSType)

        SetLoading(false);

    }

    useEffect(() => {

       let datacomplete = {};
        if (state.CompanyId) {

            let subscriptionPost = `Post::${state.CompanyId}`;

            if (NowEditing === "Contractor" || NowEditing === "Sub") {
                
               const subscribe1 = API.graphql({
                    query: onCreateSvcbotDbgsi2,
                    variables: {
                        gsi2Pk2: `Post::${ContractorData.CompanyId}`
                    }
                })
                    .subscribe({
                        next: data => {
                            console.log("subscribing.Contractor.new");
                            datacomplete = JSON.parse(data.value.data.onCreateSvcbotDb.dataJSON);
                            ProcessSubscription(datacomplete);
                        }
                    })

                const subscribe2 = API.graphql({
                    query: onUpdateContractor,
                    variables: {
                        gsi2Pk2: `Post::${ContractorData.CompanyId}`
                    }
                })
                    .subscribe({
                        next: data => {
                            console.log("subscribing.Contractor.update");
                            datacomplete = JSON.parse(data.value.data.onUpdateContractor.dataJSON);
                            ProcessSubscription(datacomplete);
                           // GetDatafromDB(state.CompanyId, "subscribe2", "", "");
                        }
                    })

                if (subscribe1) {
                    return () => {
                        subscribe1.unsubscribe();
                    }
                }
                if (subscribe2) {
                    return () => {                        
                        subscribe2.unsubscribe();
                    }
                }

            } else {
                const subscribe3 = API.graphql({
                    query: onCreateSvcbotDbgsi1,
                    variables: {
                        gsi1Pk2: subscriptionPost
                    }
                })
                    .subscribe({
                        next: data => {
                            console.log("subscribing.Company.new");
                            datacomplete = JSON.parse(data.value.data.onCreateSvcbotDb.dataJSON);
                            ProcessSubscription(datacomplete);
                          //  GetDatafromDB(state.CompanyId, "subscribe3", "", "");
                        }
                    })

                const subscribe4 = API.graphql({
                    query: onUpdateCompany,
                    variables: {
                        gsi1Pk2: subscriptionPost
                    }
                })
                    .subscribe({
                        next: data => {
                            console.log("subscribing.company.update");
                            datacomplete = JSON.parse(data.value.data.onUpdateCompany.dataJSON);
                            ProcessSubscription(datacomplete);
                          //  GetDatafromDB(state.CompanyId, "subscribe4", "", "");
                        }
                    })

                if (subscribe4) {
                    return () => {
                        subscribe4.unsubscribe();
                    }
                }
                if (subscribe3) {
                    return () => {
                        subscribe3.unsubscribe();
                    }
                }
            }
        }        

    }, [state.CompanyId]);

    const ProcessSubscription = data => {

        setState(prevState => ({
            ...prevState,
            NewSubscriptionData: data
        }))

    }

    useEffect(() => {

        if (state.NewSubscriptionData !== "") {
           // console.log("subscribing.NewSubscriptionData", state.NewSubscriptionData);
            ProcessSubscriptionData(state.NewSubscriptionData, state.FullData, state.SMSType);
        }

    }, [state.NewSubscriptionData]); 

    //SearchWords
    useEffect(() => {        
            SortPref(state.SMSType);
    }, [state.SearchWords]);

    const ProcessSubscriptionData = (DataJson, FullData, SMSType) => {

        //console.log("subscribing.ProcessSub.DataJson", DataJson);
        //console.log("subscribing.ProcessSub.FullData", FullData);

        let NewDataJson = -1;
        let NewFullData = FullData;      

        if(DataJson && DataJson.PostId) {
            NewDataJson = NewFullData.findIndex(x => x.PostId === DataJson.PostId);
            console.log("subscribing.NewDataJson", NewDataJson);
            if(NewDataJson > -1){
                NewFullData[NewDataJson] = DataJson;
            } else {
                NewFullData = [DataJson, ...FullData];
            }
        }

        NarrowDates(NewFullData, SMSType);

    }

    const NarrowDates = (FullData, SMSType) => {

        //let EndDate = new Date(state.EndDate).toISOString();
        //let StartDate = new Date(state.StartDate).toISOString();
        let EndDatetime = new Date(state.EndDate).getTime();
        let Starttime = new Date(state.StartDate).getTime();
        let CurrentTime = "";
        let NewData = [];

       // console.log("subscribing.Dates", state.EndDate, state.StartDate);

        FullData.map(each => { 
            CurrentTime = new Date(each.UTCTime).getTime();
            
            if (each.UTCTime
                && CurrentTime >= Starttime
                && CurrentTime <= EndDatetime) {
                NewData.push(each);
            }
            CurrentTime = "";
        })

        console.log("subscribing.NarrowDates", NewData);

        SeperateSubAccounts(NewData, SMSType);

    }

    const SeperateSubAccounts = (NewData, SMSType) => {

        let FullData = NewData;

        if (NowEditing === "Sub") {
            if (AccountAccess && AccountAccess.AccountsList && AccountAccess.AccountsList.length > 0) {
                let AccountDB = AccountAccess.AccountsList;
                FullData = NewData.filter(o => AccountDB.some(({ Post }) => o.gsi1Pk2 === Post));
            } else {
                FullData = [];
            }
        }

        console.log("subscribing.SeperateSubAccounts", SMSType, FullData);

        SortPref(SMSType, FullData)
        
    }  

    const SortPref = (SMSType, ExistingFullData = []) => {

        let Data = []; 
        let ExistingData = [];

        if (ExistingFullData && ExistingFullData.length > 0) {
            ExistingData = ExistingFullData;
        } else {
            ExistingData = state.FullData;
        }

        let AccountDB = [];
        if (UserData && UserData.AccountsList && UserData.AccountsList.length > 0) {
            AccountDB = UserData.AccountsList;
        }

        switch (SMSType) {
            case "Any":
                Data = ExistingData;
                break;
            case "Quote":
                Data = ExistingData.filter(x => x.Quote === true)
                break;
            case "Assigned":
                Data = ExistingData.filter(o => AccountDB.some(({ Post }) => o.gsi1Pk2 === Post));
                break;
            default:
                Data = ExistingData.filter(x => x.ContractorSMS === SMSType)
                break;
        }

        if (state.SearchWords !== "") {
            Data = Data.filter(x => x.SearchWords.toLowerCase().includes(state.SearchWords.toLowerCase()))
        }

        console.log("subscribing.SortPref", SMSType, Data);

        setState(prevState => ({
            ...prevState,
            SMSType: SMSType,
            Data: Data,
            FullData: ExistingData
        }));

    }

    const SetEndDate = date => {
        let EndDate = new Date(date).toISOString();
        console.log("SRPage.Date.EndDate", EndDate);
        setState(prevState => ({
            ...prevState,
            EndDate: EndDate,
        }));

        GetDatafromDB(state.CompanyId, "SetEndDate", state.StartDate, EndDate);

    }

    const SetStartDate = date => {
        let StartDate = new Date(date).toISOString();
        console.log("SRPage.Date.StartDate", StartDate);
        setState(prevState => ({
            ...prevState,
            StartDate: StartDate,
        }));

        GetDatafromDB(state.CompanyId, "SetStartDate", StartDate, state.EndDate );

    }

    const SetThreeColumn = value => {
        setState(prevState => ({
            ...prevState,
            Sort: value,
        }));

    }

    const SearchTerms = value => {
        setState(prevState => ({
            ...prevState,
            SearchWords: value,
        }));
    }

    const CloseDrawer = (c) => {
        OpenDrawer(false);
    }

    console.log("SRPage.state", state);

    return (
        <Page
            title={state.Category}
        >
            <Container maxWidth={false}>

                {state.Refresh && (
                <ToolbarHeading
                    SortPref={sort => SortPref(sort)}
                    SetStartDate={sort => SetStartDate(sort)}
                    SetEndDate={sort => SetEndDate(sort)}
                    SetThreeColumn={sort => SetThreeColumn(sort)}
                    SearchTerms={sort => SearchTerms(sort)}
                    StartDate={state.StartDate}
                    EndDate={state.EndDate}
                    AverageResponse={state.AverageResponse}
                />
                )}

                <Box mt={2}>

                    {state.Data && (
                        <SRListCompany
                            Sort={state.Sort}
                            Data={state.Data}
                        />
                    )}

                </Box>
            </Container>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.Category}

                propss={state.DetailsProps}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </Page>
    );
};

export default ListItems;
