import React, { useState, useEffect } from 'react';
//import { createStyles, makeStyles } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import {
    List, ListItem, IconButton,
    ListItemAvatar, Avatar,
    ListItemText, Typography, Divider, Tooltip, Grid
} from "@mui/material";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import GoogleShowLocation from '../views/modules/GoogleShowLocation';
import DocumentList from '../List/DocumentList';
import MaterialGallery from '../Gallery/MaterialGallery';

//import { TextInput } from "../components/ChatSMSTextInput";
//import { MessageLeft, MessageRight } from "../components/ChatSMSPosition";

import BuildIcon from '@mui/icons-material/Edit';
import DrawerShow from '../utils/DrawerModule';

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    ReadMessage: {
        backgroundColor: 'white',
    },
    UnReadMessage: {
        backgroundColor: 'yellow',
    },
}));


let Classsss = "";
let PersonIdexist = true;
let EventDataUpdate = [];
let medwidth = 12;
let showPictures = false;

// 
export default function ChatSMS(props) {
    const classes = useStyles();
    //const Auth = useSelector(state => state.auth)
    const UserData = useSelector(state => state.UserData);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const initialState = {
        EventData: [],
        UpdateEvent: [],
        UpdateFlag: false,
        Class: ""
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        let UpdateFlag = false;
        let Category = "Message";
        let ShowMessage = true;
        let NewEvent = [];
        let NewEventObj = {};
        let UpdateEvent = [];
        let MessageRead = [];
        let SREvent = props.SREvent;
        let EventData = SREvent.EventData;

        if (EventData && EventData.length > 0) {
            EventData.map((eachMessage, ind) => {

                if (eachMessage.PrivateMessage) {
                    if (eachMessage.PrivateMessageList.includes(UserData.key)) {
                        ShowMessage = true;
                    } else {
                        ShowMessage = false;
                    }
                }


                if (eachMessage.Category && eachMessage.Category !== "") {
                    Category = eachMessage.Category;
                } else {
                    Category = "Message";
                }

                if (eachMessage.SenderName === "System") {
                    Classsss = classes.ReadMessage;
                } else {
                    if (eachMessage.MessageRead) {
                        //if userid exist
                        if (eachMessage.MessageRead.includes(UserData.key)) {
                            Classsss = classes.ReadMessage;
                            MessageRead = eachMessage.MessageRead;
                        } else {
                            Classsss = classes.UnReadMessage;
                            MessageRead = [...eachMessage.MessageRead, UserData.key]
                            UpdateFlag = true;
                        }
                    }
                    // console.log("EventDataList.PersonIdexist", Classsss)
                }

                NewEventObj = {
                    ...eachMessage,
                    //  avatar: eachMessage.avatar,
                    //SenderName: eachMessage.SenderName,
                    //SenderCompany: eachMessage.SenderCompany,
                    //TimeStamp: eachMessage.TimeStamp,
                    //Body: eachMessage.Body,
                    Category: Category,
                    //  Class: Classsss,
                    key: ind
                }

                UpdateEvent.push({
                    ...eachMessage,
                    MessageRead: MessageRead
                })

                //Show message if person authorized if private
                if (ShowMessage) {
                    NewEvent.push(NewEventObj);
                }

            })

            //if (UpdateFlag) {
            //  setState(prevState => ({
            //    ...prevState,
            //    UpdateEvent: UpdateEvent,
            //  }));
            //}
        }

        setState(prevState => ({
            ...prevState,
            EventData: NewEvent,
            UpdateFlag: UpdateFlag,
            UpdateEvent: UpdateEvent,
        }));

        //if (props.UpdateFlag) {
        //  props.UpdateFlag(UpdateFlag);
        //}

        //  if (UpdateFlag && props.UpdateFlag) {
        //  SREvent.EventData = UpdateEvent;
        //      props.UpdateFlag(SREvent);
        //}

    }, [props.SREvent.EventData])

    const handleInclude = data => {
        console.log("EventDataList.databack", data)
    }

    const CloseDrawer = () => {
        SetDeawerOpen(false)
    }

    const AddUpdate = index => {

        SetDeawerOpen(true)
    }

   
    const ShowList = (eachMessage, ind) => {

        medwidth = 12;
        showPictures = false;
        if (eachMessage.PhotoService && eachMessage.PhotoService.length > 0) {
            medwidth = 6;
            showPictures = true;
        }

        let NewBody = "-";
        let IsLink = false;
        if (eachMessage.Body) {
            if (eachMessage.Body !== "") {
                NewBody = eachMessage.Body;
                if (NewBody.includes("https:")) {
                    IsLink = true;
                   // NewBody = `<a href="${eachMessage.Body}" target="_blank">${eachMessage.Body}</a>`;
                }
            } else {
                NewBody = "-";
            }
        } 

        switch (eachMessage.SenderName) {

            case "Question":
                return (
                    <>
                        <ListItem
                            key={ind + eachMessage.key}
                            alignItems="flex-start"
                        >
                            <ListItemText
                                primary={<Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="primary"
                                >{eachMessage.SenderName}</Typography>}
                                secondary={<Typography>{eachMessage.Body}</Typography>}
                            />
                        </ListItem>

                    </>
                )

            case "checkout":
                return (
                    <>
                        <ListItem
                            key={ind + eachMessage.key}
                            alignItems="flex-start"
                        >
                            <Divider />
                            <Typography variant="h3" align="center" gutterBottom>Check Out</Typography>
                            <Divider />
                        </ListItem>

                    </>
                )

            default:

                return (
                    <>
                        <ListItem
                            key={ind}
                        >
                            <Grid container spacing={2} >
                                <Grid xs={12} md={medwidth} item >
                                    <ListItemText
                                        // primary={`${eachMessage.SenderName} - ${eachMessage.SenderCompany}`}
                                        primary={<Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="primary"
                                        >
                                            {new Date(eachMessage.TimeStamp).toLocaleString()}
                                        </Typography>}


                                        secondary={
                                            <React.Fragment>

                                                {IsLink ? (
                                                    <Typography>
                                                        <a href={NewBody} target="_blank" rel="noreferrer">
                                                            {NewBody}
                                                        </a>
                                                    </Typography>

                                                ): (
                                                <Typography>
                                                    {NewBody}
                                                </Typography>
                                                )}

                                                {(eachMessage.Category === "Location"
                                                    || eachMessage.Category === "MarkLocation"
                                                    || eachMessage.Category === "GPS"
                                                )
                                                    && eachMessage.Location
                                                    && eachMessage.Location.lat !== "" ? (

                                                    <GoogleShowLocation
                                                        defaultValue={eachMessage.Location}
                                                        GPSfound={() => console.log("gps")}
                                                    />

                                                ) : null}

                                                {eachMessage.Documents
                                                    && eachMessage.Documents.length > 0 ? (
                                                    <DocumentList
                                                            DocumentList={eachMessage.Documents}
                                                        // AddAsset={() => AddAsset("Document", "AddDocument")}
                                                        DisableAll={true}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        }
                                    />

                                    

                                </Grid>
                                {showPictures ? (
                                    <Grid xs={12} md={medwidth} item >
                                        <MaterialGallery
                                            PhotoService={eachMessage.PhotoService}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </ListItem>
                    </>
                )
        }
    }

    console.log("EventDataList.props", props)
    console.log("EventDataList.state", state)

    return state.EventData && (
        <List>
            {state.EventData.map((eachMessage, ind) => (
                <>
                    <Divider />
                    {ShowList(eachMessage, ind)}

                </>
            ))}
        </List>

    );
}
