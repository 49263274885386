import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
    Snackbar,
    FormControlLabel,
    Switch,
    Button,
    TextField,
    Typography,
    Grid, Backdrop,
    CircularProgress,
    CardHeader,
    Card, CardContent,
    Stepper, Step, StepLabel, StepContent
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    NewPageId,
    removeEmptyStringElements,
    getAutoCompletedata,
    UpdateData,
    PrimaryKeyDataGet
} from '../utils/CommonGraphql';
import { API, graphqlOperation } from "aws-amplify";
import { deleteSvcbotDb } from '../graphql/mutations';
import Page from '../components/Page';
import { SendEmail } from '../utils/SendEmail';
import { Buffer } from 'buffer';

const NewId = NewPageId("AM")

//const useStyles = makeStyles((theme) => ({
//    root: {
//        // backgroundColor: theme.palette.background.dark,
//        //minHeight: '100%',
//        padding: theme.spacing(2),
//    },
//    paper: {
//        padding: theme.spacing(2),
//        color: theme.palette.text.secondary,
//    },
//}));




const Profile = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData)
    const [loading, SetLoading] = useState(false);

    const initialState = {
        DoneDone: false,
        ppw: "",
        AlarmMessage: "",
        OpenAlert: false
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                if (rest.CloseDrawer) {
                    rest.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    useEffect(() => {

        
        if (rest.Data && rest.Data.field1 !== "") {

            let RegistorDate = new Date(rest.Data.date).getTime();
            let TodaY = new Date().getTime();
            let DisableRemove = true;

            let Diff = ((TodaY - RegistorDate) / (60 * 60 * 1000 * 24));
            
            if (30 < Diff) {
                DisableRemove = false;
            }

            console.log("RegistorDate, TodaY,  Diff", rest.Data.date, RegistorDate, TodaY, Diff)

            setState(prevState => ({
                ...prevState,
                Rename: rest.Data.field1,
                DisableRemove: DisableRemove
            }));
        }

    }, [rest.Data])



    const UpdateSREvent = (SREvent) => {

        if (state.Rename !== "") {

            setState(prevState => ({
                ...prevState,
                DoneDone: true
            }));

            let NewDataJOON = rest.Data;
            NewDataJOON.field1 = state.Rename
            var ValueToUpdate = {
                pk1: rest.Data.pk1,
                sk1: rest.Data.sk1,
                dataJSON: JSON.stringify(removeEmptyStringElements(NewDataJOON)),
            };

            //console.log("SRDetails.1.pksk", ValueToUpdate)            

            UpdateData(ValueToUpdate, "AddDocument")
                .then((up) => {
                    console.log("SRDetails.Update.results", up)
                    
                })
                .catch((err) => console.log("SRDetails.failed", err));
        }
    }

    const SendSMS = (Message, event) => {

        try {
            console.log("AddMessage.sending.sms", event.RequestorSMS)
            let ButtonLinkpk = Buffer.from(event.ServiceRequest.pk1).toString('base64');
            let ButtonLinksk = Buffer.from(event.ServiceRequest.sk1).toString('base64');
            let ActionButton = `https://${HQData.SubDomain}/ServiceRequest/${ButtonLinkpk}/${ButtonLinksk}`;

            var SMSstring = {
                Body: `${Message}. Please click on following link ${ActionButton}`,
                //FromSMS: state.phone_number,
                From: event.ContractorSMS,
                To: [event.RequestorSMS],
                mediaUrl: []
            };

            console.log(
                "AddMessage.Completed",
                SMSstring
            );

            API.post("SMStoTwilio", "", {
                body: { Records: SMSstring }
            });

        } catch (err) { }

    }

    const handleCloseAlert = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };


    const HandleDeleteItem = () => {

        console.log("SMSModify.delete", rest.Data);
       
        if (rest.Data
            && rest.Data.subscriptionId
            && rest.Data.subscriptionId !== ""
            && state.ppw === UserData.smpnumber
        ) {

            setState(prevState => ({
                ...prevState,
                DoneDone: true
            }));

            //removing number from Twilio
             if (rest.Data.PhoneSid && rest.Data.PhoneSid !== "") {
             SendText(rest.Data.PhoneSid, "+13045911922");
            }

            SendText(`Subscraption cancelation ${rest.Data.mobile}`, "+15627601914")

            API.post("CancelStripe", "", {
                body: { Records: rest.Data.subscriptionId }
            })

        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Password / data did not match",
                OpenAlert: true,
            }));

        }
    }

    const ConfirmDelete = () => {
        setOpen(true)
        //   setState(prevState => ({ ...prevState, DeleteItem: item }));
    }

    const handleClose = () => {
        setOpen(false);
    };

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const SendText = (postTEXT, phone) => {

        console.log("SMSModify.SMS", postTEXT);

        let SMSstring = {
            Body: postTEXT,
            FromSMS: "+15627601914",
            From: "+15623936334",
            To: [phone],
            mediaUrl: [],
            pk1: "",
            CompanyId: "",
            ContractorId: "",
            SMSList: [{
                PersonName: "",
                PersonId: "",
                phone: "+15627601914"
            }]
        };

        // console.log("ItemAddPage.Invite.SMS", SMSstring)

        API.post("SMStoTwilio", "", {
            body: { Records: SMSstring }
        });

    }

    console.log("AddMessage.props", rest)
    console.log("AddMessage.state", state)

    return (
        <Page >

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Rename"
                        name="Rename"
                        value={state.Rename || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Rename")}
                    />
                </Grid>
                <Grid item xs={12}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => UpdateSREvent()}
                    >
                        Save Rename
                    </Button>

                </Grid>

                <Grid item xs={12}>

                    <Button
                        variant="contained"
                        color="error"
                        disabled={state.DisableRemove}
                        onClick={() => ConfirmDelete()}
                    >
                        {`Cancel ${rest.Data.mobile} Subscription` }
                    </Button>

                </Grid>
            </Grid>
            <Dialog
                //  fullScreen={}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Cancel ${rest.Data.mobile} Subscription?`}
                    </DialogContentText>
                    <DialogContentText>
                        <TextField
                            fullWidth
                            label="Enter Password"
                            margin="normal"
                            name="ppw"
                            //onBlur={onAddValue}
                            onChange={e => SetValues(e.target.value, "ppw")}
                            type="password"
                            value={state.ppw || ""}
                            variant="outlined"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={HandleDeleteItem} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
