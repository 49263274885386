import React, { useState, useEffect } from 'react';
import {
    //Box,
    Grid,
    Container,
    //Snackbar,
    //Link,
   // TextField,
    Typography,
    //LinearProgress,

} from '@mui/material';
import { useDispatch } from 'react-redux';
//import { FindLatestDate, removeEmptyStringElements, UpdateData } from '../utils/CommonGraphql';
import { useParams } from 'react-router-dom';
//import { currentTime } from '../utils/DateTime';
//import { createBatch } from '../graphql/mutations';
//import { strengthIndicator, strengthColor } from "../utils/PasswordStrength";
import { Auth, API, graphqlOperation } from "aws-amplify";
//import Page from '../components/Page';
//import { FormSchema, CommonFormUpdated, FormPrefArray } from '../Forms/FormList';
//import StartPage from '../views/auth/LoginView';
import PaymentExpense from '../Payment/PaymentExpense';
import { Buffer } from 'buffer';


const AWS = require("aws-sdk");
// Initialize the Amazon Cognito credentials provider
AWS.config.region = "us-east-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId
});
const dynamodb = new AWS.DynamoDB.DocumentClient();

var GetDBData = (params) => {
    return new Promise((resolve, reject) => {
        dynamodb.query(params, function (err, data) {
            if (err) {
                console.log("errrr", err, err.stack);
                // an error occurred
            } else {
                console.log("databack DB", data)
                resolve(data);
            }// successful response
        });
    });
};

const GetData = async (pk1, sk1) => {
    try {
        let getItemParams2 = {
            //IndexName: "gsi1Pk2-gsi1Sk2-index",
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and #sk1 = :sk1",
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1",
            },
            ExpressionAttributeValues: {
                ":pk1": pk1,
                ":sk1": sk1,
            }
        };

        let EscPolicy = await GetDBData(getItemParams2);
        return EscPolicy.Items;

    } catch (err) {
        console.log('GetData err', err);
    }
};


const LoginView = (props) => {

  
//const dispatch = useDispatch()
    
    const params = useParams();
    console.log('LandingPage.params', params);
    console.log('LandingPage.props', props);
    const initialState = {
        ShowLogin: true,
        PostData: "",
        CompanyData: ""
    };

    const [state, setState] = React.useState(initialState);

    //useEffect(() => {

    //    let usernamecode = process.env.REACT_APP_SIGNINUSER; 
    //    usernamecode = usernamecode.toLocaleLowerCase();
    //    let PPw = process.env.REACT_APP_PPW; 
    //    console.log("LoginPage.SignUp.SignIn", usernamecode, PPw);
    //    Auth.signIn(usernamecode, PPw).then(user => {
    //        console.log("LandingPage.user", user);
    //     //   LoadDataRedux();

    //    }).catch(err => {
    //        console.log("LandingPage.err", err);
    //        setState((prevState) => ({
    //            ...prevState,
    //            toDashboard: false,
    //            confirmcodemessage: `Sorry The Sign In failed. Please try again or contact ${state.ContractorName}`,
    //            step: "SignInEmail"
    //        }))
    //    })
       
    //}, [])

    useEffect(() => {

        let cid = "";

        if (params.cid) {
            cid = atob(params.cid);
           // cid = params.cid;
            LoadPostData(cid);

            setState(prevState => ({
                ...prevState,
                CLIENT_REFERENCE_ID: cid
            }));
        }

    }, [params.cid])

  
    const LoadPostData = async (CompanyId) => {

        try {


            let Paymentstring = `Subscription::${CompanyId}`;
            let Amount = 0;
            let PostData = [];
            console.log('Paymentstring', Paymentstring);

            let getItemParams2 = {
                IndexName: "gsi1Pk2-gsi1Sk2-index",
                TableName: "svcbot_db",
                ScanIndexForward: false,
                KeyConditionExpression: "#gsi1Pk2 = :gsi1Pk2 and #gsi1Sk2 = :gsi1Sk2",
                ExpressionAttributeNames: {
                    "#gsi1Pk2": "gsi1Pk2",
                    "#gsi1Sk2": "gsi1Sk2"
                },
                ExpressionAttributeValues: {
                    ":gsi1Pk2": Paymentstring,
                    ":gsi1Sk2": "Pending"
                }
            };

            let PersonFound = await GetDBData(getItemParams2);
            
            console.log('LandingPage.1CompanyData', PersonFound.Count);
            if (PersonFound.Count > 0) {
                let CompanyData = PersonFound.Items[0].dataJSON;

                console.log('LandingPage.2CompanyData', CompanyData);

                setState(prevState => ({
                    ...prevState,
                    CompanyData: CompanyData,
                    PersonEmail: CompanyData.PersonEmail,
                    SubscriptionPlan: "ServiceWithTextPlan"//CompanyData.SubscriptionPlan
                }));

            }
            

        } catch (err) {
            console.log('LoadPref err', err);
        }
    }

    const SelectedValues = (item, name) => {
               
        setState({
            ...state,
            [name]: item,
        });

    };
   

    console.log('LandingPage.state', state);

    return (
        <Container maxWidth="md">
                 
            {state.CompanyData !== "" ? (
                <Grid container spacing={2}>

                    {/* 
                    <Grid xs={12} item>
                        <TextField
                            value={state.PersonEmail}
                           // placeholder="Confirm Your Email"
                            label="Confirm Your Email"
                            disabled={props.disabled}
                            fullWidth
                            onChange={(e) => SelectedValues(e.target.value, "PersonEmail")}
                            variant="outlined"
                        />
                    </Grid>
                    */}

                    {state.SubscriptionPlan === "ServiceWithTextPlan" ? (
                        <Grid xs={12} item>
                            <stripe-pricing-table
                                pricing-table-id="prctbl_1PMLnWIaiZMWM6GQMSg8Iu6G"
                                publishable-key="pk_test_cSg05NT09FIN39iDgiCzZPAx"
                                client-reference-id={state.CLIENT_REFERENCE_ID}
                                customer-email={state.PersonEmail}
                            >
                            </stripe-pricing-table>
                        </Grid>
                    ) : null}

                    {state.SubscriptionPlan === "CheckInYouPay" ? (
                        <Grid xs={12} item>
                            <stripe-pricing-table
                                pricing-table-id="prctbl_1PNR3ZIaiZMWM6GQJLGsbszb"
                                publishable-key="pk_live_NU222DhVRWY91e5LXkDunENb"
                                client-reference-id={state.CLIENT_REFERENCE_ID}
                                customer-email={state.PersonEmail}
                            >
                            </stripe-pricing-table>
                        </Grid>
                    ) : null}

                    {state.SubscriptionPlan === "CheckInSubcontractorPaysAdd" ? (
                        <Grid xs={12} item>
                            <Typography variant="h5" gutterBottom>
                                Thanks, the system is ready.  You can pass the new number to personnel who will use to check in/out
                            </Typography>

                        </Grid>
                    ) : null}


                </Grid>
            ) : (

                    <Typography variant="h5" gutterBottom>
                        Sorry, your Subscription information was not found
                    </Typography>

            )}

        </Container>
    );
};

export default LoginView;
