import React, { useEffect, useState } from 'react';
import {
    TextField,
    Backdrop, CircularProgress,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    Snackbar,
    CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { API } from "aws-amplify";
import SelectAssets from '../components/SelectAssets'; 
import {
    removeEmptyStringElements,
} from '../utils/CommonGraphql';


export default function RadioButtonsGroup(props) {
  //  const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        AssetList: [],
        DoneDone: false,
        CompanyId: "",
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);


    useEffect(() => {
                
        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        //  console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])

    useEffect(() => {

        let event = props.SREvent;
        if (event && event.ContractorData.CompanyId) {
            setState(prevState => ({
                ...prevState,
                CompanyId: event.ContractorData.CompanyId,
                CompanyName: event.ContractorData.CompanyName
            }));
        }
       
    }, [props.SREvent])

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                handleNext();
                SetLoading(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);


    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const PutInDB = () => {

        let event = props.SREvent;
        let EachDB = {};
        
        let ImageStateSet = state.AssetList;
        let MiniDataJSON = {};
        let InputArray = [];
        let Field2 = `Work date: `;

        if (ImageStateSet
            && ImageStateSet.length > 0) {

            setState(prevState => ({
                ...prevState,
                DoneDone: true
            }))

            let MiniDataJSON2 = {
                CompanyId: event.ContractorData.CompanyId,
                CompanyName: event.ContractorData.CompanyName,
                ContractorId: event.ContractorData.CompanyId,
                ContractorName: event.ContractorData.CompanyName,
            }


            ImageStateSet.map((each, indd) => {

                MiniDataJSON = {
                    ...MiniDataJSON2,
                   // ...each,
                    field1: each.field1,
                    field2: Field2,
                    AssetPkSk: {
                        pk1: each.pk1,
                        sk1: each.sk1
                    },
                    SearchWords: `${event.SearchWords}, ${each.SearchWords}`,
                    pk1: `Post::${each.key}`,
                    sk1: event.ServiceRequest.sk1,
                    gsi1Pk2: `Post::${each.Category}::${event.posterId.CompanyId}`,
                    gsi1Sk2: event.ServiceRequest.sk1,
                    gsi2Pk2: `Post::${each.Category}::${event.ContractorData.CompanyId}`,
                    gsi2Sk2: event.ServiceRequest.sk1,
                    
                }

                //const DocData = {
                //    pk1: `CheckIn::${event.PostId}`,
                //    sk1: `CheckIn::Assets::${event.ServiceRequest.sk1}::${indd}`,
                //    gsi1Pk2: `CheckIn::Assets::${each.sk1}`,
                //    gsi1Sk2: event.currentTime,
                //    gsi2Pk2: `Post::Assets::${event.ContractorData.CompanyId}`,
                //    gsi2Sk2: event.currentTime,
                //    dataJSON: JSON.stringify(removeEmptyStringElements(MiniDataJSON))
                //};

                //InputArray.push(DocData);

                EachDB = {
                    pk1: `Post::${each.key}`,
                    sk1: event.ServiceRequest.sk1,
                    gsi1Pk2: `Post::${each.Category}::${event.posterId.CompanyId}`,
                    gsi1Sk2: event.ServiceRequest.sk1,
                    gsi2Pk2: `Post::${each.Category}::${event.ContractorData.CompanyId}`,
                    gsi2Sk2: event.ServiceRequest.sk1,
                    dataJSON: JSON.stringify(removeEmptyStringElements(MiniDataJSON))
                };


                InputArray.push(EachDB);

                //NewFormJSON.sk1 = `Post::Assets::${event.ServiceRequest.sk1}`;
                //NewFormJSON.pk1 = `Post::${event.PostId}`;

                EachDB = {

                    pk1: `Post::${event.PostId}`,
                    sk1: `Post::Assets::${event.ServiceRequest.sk1}`,
                    //gsi1Pk2: `CheckIn::${event.posterId.sk1}`,
                    //gsi1Sk2: event.ServiceRequest.sk1,
                    //gsi2Pk2: `Post::Person::${event.posterId.CompanyId}`,
                    //gsi2Sk2: event.ServiceRequest.sk1,
                    dataJSON: JSON.stringify(removeEmptyStringElements(MiniDataJSON))

                };

                InputArray.push(EachDB);

            })

            console.log("UploadEachBox.InputArray", InputArray);

            if (InputArray && InputArray.length > 0) {

                let PosttoDB = {
                    MutationType: "createBatch",
                    DBData: InputArray
                };

                // Post to DB
                TriggerLambda(PosttoDB);

            }

        } else {

            handleNext();

        }

       
    }

    const SaveData = () => {

        try {

            console.log('Addasset.state.AssetList', state.AssetList);

            let InputArray = [];
            let Location = "";
            let NewFormJSON = {};
            let EachDB = {};
            let event = props.SREvent;
            let avatar = "";

            var dateToday = new Date();
            let currentTime = dateToday.toISOString();

            let ServiceRequestTime = new Date(event.UTCTime).toLocaleString();

            
            let Mini = {
                // posterId: event.posterId,
                // post: event.postTEXT,  
                CompanyId: event.ContractorData.CompanyId,
                CompanyName: event.ContractorData.CompanyName,
                ContractorId: event.ContractorData.CompanyId,
                ContractorName: event.ContractorData.CompanyName,
                title: event.title,
                field1: event.posterId.field1,
                field2: event.title,
                ServiceRequest: event.ServiceRequest,
                PhotoService: event.PhotoService,
                Documents: event.Documents,
                date: currentTime,
                PostId: event.PostId,
                currentTime: event.currentTime,
                Category: "Assets",
                posterId: event.posterId
            };

            if (state.AssetList.length > 0) {
                state.AssetList.map((each, indd) => {

                    if (each.Location) {
                        Location = each.Location;
                    } else {
                        Location = "";
                    }

                    if (each.avatar) {
                        avatar = each.avatar;
                    } else {
                        avatar = "";
                    }

                    NewFormJSON = {
                        ...Mini,
                        field1: each.field1,
                        field2: `Work Date: ${ServiceRequestTime}`,
                        Location: Location,
                        AssetPkSk: {
                            pk1: each.pk1,
                            sk1: each.sk1
                        },
                        avatar: avatar,
                        SearchWords: `${each.field1} - ${event.title} - ${event.postTEXT}`,
                        pk1: `Post::${each.key}`,
                        sk1: event.ServiceRequest.sk1,
                        gsi1Pk2: `Post::Assets::${event.posterId.CompanyId}`,
                        gsi1Sk2: event.ServiceRequest.sk1,
                        gsi2Pk2: `Post::Assets::${event.ContractorData.CompanyId}`,
                        gsi2Sk2: event.ServiceRequest.sk1,
                        key: `Post::Assets::${each.key}::${event.ServiceRequest.sk1}`
                    }

                    //  console.log("ContactList.NewFormJSON", NewFormJSON);

                    //if (rest.Category === "Location") {

                    //    EachDB = {
                    //        pk1: `Post::${event.PostId}`,
                    //        sk1: `Post::${rest.Category}::${event.ServiceRequest.sk1}::${indd}`,
                    //        gsi1Pk2: `Post::${each.key}`,
                    //        gsi1Sk2: event.ServiceRequest.sk1,
                    //        gsi2Pk2: `Post::Location::${event.ContractorData.ContractorId}`,
                    //        gsi2Sk2: event.ServiceRequest.sk1,
                    //        dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                    //    };

                    //} else {

                    //     EachDB = {
                    //         pk1: `Post::${event.PostId}`,
                    //         sk1: `Post::${rest.Category}::${event.ServiceRequest.sk1}::${indd}`,
                    //         gsi1Pk2: `Post::${each.key}`,
                    //         gsi1Sk2: event.ServiceRequest.sk1,
                    //         gsi2Pk2: `Post::${rest.Category}::${event.posterId.CompanyId}`,
                    //         gsi2Sk2: event.ServiceRequest.sk1,
                    //         dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                    //     };
                    //// }

                    // InputArray.push(EachDB);

                    EachDB = {
                        pk1: `Post::${each.key}`,
                        sk1: event.ServiceRequest.sk1,
                        gsi1Pk2: `Post::Assets::${event.posterId.CompanyId}`,
                        gsi1Sk2: event.ServiceRequest.sk1,
                        gsi2Pk2: `Post::Assets::${event.ContractorData.CompanyId}`,
                        gsi2Sk2: event.ServiceRequest.sk1,
                        dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                    };


                    InputArray.push(EachDB);

                    NewFormJSON.sk1 = `Post::Assets::${event.ServiceRequest.sk1}`;
                    NewFormJSON.pk1 = `Post::${event.PostId}`;

                    EachDB = {

                        pk1: `Post::${event.PostId}`,
                        sk1: `Post::Assets::${event.ServiceRequest.sk1}`,
                        //gsi1Pk2: `CheckIn::${event.posterId.sk1}`,
                        //gsi1Sk2: event.ServiceRequest.sk1,
                        //gsi2Pk2: `Post::Person::${event.posterId.CompanyId}`,
                        //gsi2Sk2: event.ServiceRequest.sk1,
                        dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))

                    };

                    InputArray.push(EachDB);

                    console.log('Addasset.InputArray', InputArray);
                    //InputArray.push({
                    //    pk1: `Post::${event.PostId}`,
                    //    sk1: `Post::${rest.Category}::${each.key}::${event.ServiceRequest.sk1}`,
                    //    gsi1Pk2: each.key,
                    //    gsi1Sk2: `Post::${rest.Category}::${event.ServiceRequest.sk1}`,
                    //    gsi2Pk2: event.ContractorData.ContractorId,
                    //    gsi2Sk2: `Post::${rest.Category}::${event.ServiceRequest.sk1}`,
                    //    dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                    //})

                    Location = "";
                })

                setState(prevState => ({
                    ...prevState,
                    DoneDone: true
                }))

                if (InputArray && InputArray.length > 0) {

                    let PosttoDB = {
                        MutationType: "createBatch",
                        DBData: InputArray
                    };

                    // Post to DB
                    TriggerLambda(PosttoDB);

                }

            } else {

            handleNext();

        }

            //let TriggerSubs = {
            //    pk1: `Post::${event.PostId}`,
            //    sk1: `Trigger::Subscription`
            //}

            

            //if (InputArray.length > 0) {
            //    API.graphql(graphqlOperation(createBatch, { input: InputArray }))
            //        .then((results) => {

            //            API.graphql(graphqlOperation(createSvcbotDb, { input: TriggerSubs }))

            //            if (rest.TriggerUpdate) {
            //                dispatch({ type: 'TRIGGER_UPDATE', payload: true });
            //            }

            //            if (rest.UpdateAsset) {
            //                rest.UpdateAsset(true)
            //            }

            //            if (rest.CloseDrawer) {
            //                rest.CloseDrawer();
            //            }

            //        })
            //}

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const TriggerLambda = (event) => {
        console.log("NewServiceCall.TriggerLambda", event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    //const TriggerESCData = (event) => {
    //    console.log("NewServiceCall.TriggerLambda", event);

    //    API.post("TriggerESCData", "/ESCData", {
    //        body: { Records: event }
    //    });
    //}

    const handleNext = () => {

        let Body = "No Assets";
        if (state.AssetList.length > 0) {
            Body = "";
            state.AssetList.map(each => {
                Body += `${each.field1}, ${each.field2}, `;
            })            
        }

        let SelectedDataReturn = {
            ...state,
            Body: Body,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("UploadBox.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const SetValues = (data, name) => {

             
        setState(prevState => ({
            ...prevState,
            [name]: data,
        }));

    }

    console.log("UploadBox.state", state)
    console.log("UploadBox.props", props)

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title={`Select Assets`}
                        />

                        <CardContent>

                            {state.CompanyId && state.CompanyId !== "" && (
                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "AssetList")}
                                CompanyId={state.CompanyId}
                                CompanyName={state.CompanyName}
                                title={`Select Assets`}
                                Category="Assets"
                                AssetList={state.AssetList}
                            />
                            )}

                        </CardContent>


                    </Card>

                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => SaveData()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>
            
            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </>

    );
}
