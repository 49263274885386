import React, { useState, useEffect } from 'react';
import {
    Box, Container, Backdrop, CircularProgress, Grid,
    CardHeader, Card, Button, CardContent
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import DocumentToolbar from '../components/DocumentToolbar';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import {
    NewPageId,
    MiddlePostSearch,
    ContreactorPostSearch,
    getAutoCompletedata
} from '../utils/CommonGraphql';
//import SRList from '../List/SRColumn';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime'
//import { API, graphqlOperation } from "aws-amplify";
//import { onBatchInputs } from '../graphql/subscriptions';
import SRColumn from '../List/SRColumn';
//import SubscriptionList from '../Setup/SubscriptionList';
import ListItems from '../List/ListItems';
import ListModule from '../List/ListModule';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

//let DateNow = new Date();
var EndDate = adjustedTime(1, "End");
let StartDate = adjustedTime(-30, "Start");

const DocumentPage = (props) => {

    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const initialState = {
        SelectedSMS: []
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        let SelectedSMS = [];

        if (CurrentCompany
            && CurrentCompany.SelectedSMSArray
            && CurrentCompany.SelectedSMSArray.length > 0
        ) {
            SelectedSMS = CurrentCompany.SelectedSMSArray;
        } else if (ContractorData
            && ContractorData.SelectedSMSArray
            && ContractorData.SelectedSMSArray.length > 0
        ) {
            SelectedSMS = ContractorData.SelectedSMSArray;
        }

        console.log("NewServiceList.SelectedSMS", SelectedSMS);

        setState(prevState => ({
            ...prevState,
            SelectedSMS: SelectedSMS
        }))

    }, [])

   


    // Additional Information Box
    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: add.field1,
           // SetupSubscription: true,
           // DetailComponent: "ShowDetailsText",
            ItemData: add,
            DrawerKey: NewPageId("DrawerKey"),
          //  NewCategory: "ShowDetails",
        }))

            OpenDrawer(true);
        //}
    }

    

    //const AddItem = (DetailComponent, Category) => {

    //    setState(prevState => ({
    //        ...prevState,
    //        DrawerTitle: "Setup New Subscription",
    //        SetupSubscription: true,
    //        DetailComponent: DetailComponent,
    //        DrawerKey: NewPageId("DrawerKey"),
    //        NewCategory: Category,
    //    }))

    //    OpenDrawer(true);
    //}



    const CloseDrawer = (c) => {
        //    GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

   

    //const SetValue = (value, name) => {
    //    setState(prevState => ({
    //        ...prevState,
    //        [name]: value
    //    }));
    //}

    console.log("DocumentPage.state", state);

    return state.SelectedSMS && (
        <Box mt={2}>
                   
                            <Card>
                                <CardHeader
                            title="New Service"
                                />
                                <CardContent>
                            <ListModule
                                //  AddBox={(add) => AddBox(add)}
                                title="New Service"
                                disabled={false}
                                DetailLink={(e) => AddBox(e)}
                                DataBack={state.SelectedSMS}
                                    />
                                </CardContent>
                            </Card>
                       
           
            <DrawerShow
                LoadComponenet="NewServiceCall"
                CloseDrawer={(e) => CloseDrawer(e)}
                Category="NewServiceCall"
                DrawerTitle={state.DrawerTitle}
                propss={{
                    Category: "NewServiceCall",
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e),
                   // TriggerUpdate: true,
                  //  SetupSubscription: state.SetupSubscription,
                    Data: state.ItemData,
                  //  SubData: state.SubData,
                    id: state.DrawerKey,
                    // AddItem: () => AddItem("ItemAddPage", "Person")
                }}
                key={state.DrawerKey}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </Box>
    );
};

export default DocumentPage;
