export const TotalList = [
    {
        key: "title",
        label: "Title",
        labelPermanent: "Title",
        include: false,
        Preset: false,
        ModuleTitle: "Title",
        message: "Please enter heading / title",
        LoadComponenet: "Title",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "title",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "description",
        label: "Description",
        include: false,
        Preset: false,
        labelPermanent: "Description",
        ModuleTitle: "Description",
        message: "Please enter description and details",
        LoadComponenet: "Description",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "description",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },

    {
        key: "Trade",
        label: "Service Specielty Trade",
        labelPermanent: "Service Specielty Trade",
        include: false,
        Preset: true,
        ModuleTitle: "Trade",
        message: "Please select Service Specielty Trade",
        LoadComponenet: "ShowTradeOption",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "ShowTradeOption",
        DataSendArrayName: "TradeSelected",
        disabled: false,
        Category: "",
        VariableInput: "",
    },


    {
        key: "Location",
        label: "Location",
        labelPermanent: "Location",
        include: false,
        Preset: true,
        ModuleTitle: "Location",
        message: "Location of the work",
        LoadComponenet: "Autocomplete",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedLocation",
        DataSendArrayName: "",
        disabled: false,
        Category: "Location",
        VariableInput: "",
    },

    {
        key: "DateRange",
        label: "Dates",
        labelPermanent: "Dates",
        ModuleTitle: "Dates",
        Preset: false,
        include: false,
        message: "Select dates for service",
        LoadComponenet: "DateRange",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "DateRange",
        DataSendArrayName: "DateRange",
        disabled: false,
        Category: "Date",
        VariableInput: "",
    },

    //{
    //  key: "TimeRange",
    //  label: "Select Time",
    //  ModuleTitle: "TimeRange",
    //  message: "Select Time for service",
    //  LoadComponenet: "TimeRange",
    //  LoadComponenet2: "",
    //  LoadComponenet3: "",
    //  Trigger1: false,
    //  Trigger2: false,
    //  Trigger3: false,
    //  required: false,
    //  DataReceivedArrayName: "TimeRange",
    //  DataSendArrayName: "",
    //  disabled: false,
    //  Category: "Time",
    //  VariableInput: "",
    //},

    {
        key: "Upload",
        label: "Picture or documents",
        ModuleTitle: "Upload",
        labelPermanent: "Picture or documents",
        Preset: false,
        include: false,
        message: "Provide picture or documents",
        LoadComponenet: "Upload",
        LoadComponenet2: "ShowImage",
        LoadComponenet3: "ShowImage",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedDocuments",
        DataSendArrayName: "SelectedDocuments",
        disabled: false,
        Category: "Upload",
        VariableInput: "",
    },

    {
        key: "PrivateMessage",
        label: "Private Message",
        labelPermanent: "Private Message",
        Preset: false,
        ModuleTitle: "Private Message",
        include: false,
        message: "Add Private Message",
        LoadComponenet: "PrivateMessage",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: true, // trigger=true to activate text box for private message
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "PrivateMessage",
        DataSendArrayName: "",
        disabled: false,
        Category: "ExtraNotification",
        VariableInput: "",
    },

    {
        key: "ExtraNotification",
        label: "Extra Notification",
        labelPermanent: "Extra Notification",
        Preset: true,
        include: false,
        ModuleTitle: "Extra Notification",
        message: "The system will automatically notify the on-call personal.  Please use this step to add other personal that need to receive copy of this service request",
        LoadComponenet: "ExtraNotification",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false, // trigger=true to activate text box for private message
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "ExtraNotification",
        DataSendArrayName: "",
        disabled: false,
        Category: "ExtraNotification",
        VariableInput: "",
    },
];

export const schedule = [
    {
        key: "DateRange",
        label: "Dates",
        labelpermanent: "Dates",
        ModuleTitle: "Dates",
        message: "Select dates for service",
        LoadComponenet: "DateRange",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "DateRange",
        DataSendArrayName: "DateRange",
        disabled: false,
        Category: "Date",
        VariableInput: "",
    },
    {
        key: "title",
        label: "Title",
        labelpermanent: "Title",
        ModuleTitle: "Title",
        message: "Please enter heading / title",
        LoadComponenet: "Title",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "title",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "description",
        label: "Description",
        labelpermanent: "Description",
        ModuleTitle: "Description",
        message: "Please enter description and details",
        LoadComponenet: "Description",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "description",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "Trade",
        label: "Service Specielty Trade",
        ModuleTitle: "Trade",
        message: "Please select Service Specielty Trade",
        LoadComponenet: "ShowTradeOption",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "ShowTradeOption",
        DataSendArrayName: "TradeSelected",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "Location",
        label: "Location",
        ModuleTitle: "Location",
        message: "Location of the work",
        LoadComponenet: "Autocomplete",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedLocation",
        DataSendArrayName: "",
        disabled: false,
        Category: "Location",
        VariableInput: "",
    },
];

export const emergency = [
    {
        key: "title",
        label: "Title",
        ModuleTitle: "Title",
        message: "Please enter heading / title",
        LoadComponenet: "Title",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "title",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "description",
        label: "Description",
        labelpermanent: "Description",
        ModuleTitle: "Description",
        message: "Please enter description and details",
        LoadComponenet: "Description",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "description",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },

    {
        key: "Trade",
        label: "Service Specielty Trade",
        ModuleTitle: "Trade",
        message: "Please select Service Specielty Trade",
        LoadComponenet: "ShowTradeOption",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "ShowTradeOption",
        DataSendArrayName: "TradeSelected",
        disabled: false,
        Category: "",
        VariableInput: "",
    },


    {
        key: "Location",
        label: "Location",
        ModuleTitle: "Location",
        message: "Location of the work",
        LoadComponenet: "Autocomplete",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedLocation",
        DataSendArrayName: "",
        disabled: false,
        Category: "Location",
        VariableInput: "",
    },

    {
        key: "DateRange",
        label: "Dates",
        ModuleTitle: "Dates",
        message: "Select dates for service",
        LoadComponenet: "DateRange",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "DateRange",
        DataSendArrayName: "DateRange",
        disabled: false,
        Category: "Date",
        VariableInput: "",
    },

    //{
    //  key: "TimeRange",
    //  label: "Select Time",
    //  ModuleTitle: "TimeRange",
    //  message: "Select Time for service",
    //  LoadComponenet: "TimeRange",
    //  LoadComponenet2: "",
    //  LoadComponenet3: "",
    //  Trigger1: false,
    //  Trigger2: false,
    //  Trigger3: false,
    //  required: false,
    //  DataReceivedArrayName: "TimeRange",
    //  DataSendArrayName: "",
    //  disabled: false,
    //  Category: "Time",
    //  VariableInput: "",
    //},

    {
        key: "Upload",
        label: "Picture or documents",
        ModuleTitle: "Upload",
        message: "Provide picture or documents",
        LoadComponenet: "Upload",
        LoadComponenet2: "ShowImage",
        LoadComponenet3: "ShowImage",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedDocuments",
        DataSendArrayName: "SelectedDocuments",
        disabled: false,
        Category: "Upload",
        VariableInput: "",
    },

    {
        key: "ExtraNotification",
        label: "Private Message",
        ModuleTitle: "Private Message",
        message: "Add Private Message",
        LoadComponenet: "ExtraNotification",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: true, // trigger=true to activate text box for private message
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "PrivateMessage",
        DataSendArrayName: "",
        disabled: false,
        Category: "ExtraNotification",
        VariableInput: "",
    },

    {
        key: "ExtraNotification",
        label: "Extra Notification",
        ModuleTitle: "Extra Notification",
        message: "The system will automatically notify the on-call personal.  Please use this step to add other personal that need to receive copy of this service request",
        LoadComponenet: "ExtraNotification",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false, // trigger=true to activate text box for private message
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "ExtraNotification",
        DataSendArrayName: "",
        disabled: false,
        Category: "ExtraNotification",
        VariableInput: "",
    },
];

export const todo = [
    {
        key: "title",
        label: "Title",
        ModuleTitle: "Title",
        message: "Please enter heading / title",
        LoadComponenet: "Title",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: true,
        DataReceivedArrayName: "title",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "Upload",
        label: "Picture or documents",
        ModuleTitle: "Upload",
        message: "Provide picture or documents",
        LoadComponenet: "Upload",
        LoadComponenet2: "ShowImage",
        LoadComponenet3: "ShowImage",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedDocuments",
        DataSendArrayName: "SelectedDocuments",
        disabled: false,
        Category: "Upload",
        VariableInput: "",
    },
    {
        key: "description",
        label: "Description",
        ModuleTitle: "Description",
        labelpermanent: "Description",
        message: "Please enter description and details",
        LoadComponenet: "Description",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "description",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "Trade",
        label: "Service Specielty Trade",
        ModuleTitle: "Trade",
        message: "Please select Service Specielty Trade",
        LoadComponenet: "ShowTradeOption",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "ShowTradeOption",
        DataSendArrayName: "TradeSelected",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
    {
        key: "Equipment",
        label: "Equipment",
        ModuleTitle: "Equipment",
        message: "Please select the Equipment",
        LoadComponenet: "Autocomplete",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedEquipment",
        DataSendArrayName: "",
        disabled: false,
        Category: "Equipment",
        VariableInput: "",
    },
    {
        key: "Location",
        label: "Location",
        ModuleTitle: "Location",
        message: "Location of the work",
        LoadComponenet: "Autocomplete",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedLocation",
        DataSendArrayName: "",
        disabled: false,
        Category: "Location",
        VariableInput: "",
    },
];

export const quote = [
    {
        key: "title",
        label: "Title",
        ModuleTitle: "Title",
        message: "Please enter heading / title",
        LoadComponenet: "Title",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: true,
        DataReceivedArrayName: "title",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },

    {
        key: "Upload",
        label: "Picture or documents",
        ModuleTitle: "Upload",
        message: "Provide picture or documents",
        LoadComponenet: "Upload",
        LoadComponenet2: "ShowImage",
        LoadComponenet3: "ShowImage",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "SelectedDocuments",
        DataSendArrayName: "SelectedDocuments",
        disabled: false,
        Category: "Upload",
        VariableInput: "",
    },

    {
        key: "description",
        label: "Description",
        ModuleTitle: "Description",
        labelpermanent: "Description",
        message: "Please enter description and details",
        LoadComponenet: "Description",
        LoadComponenet2: "",
        LoadComponenet3: "",
        Trigger1: false,
        Trigger2: false,
        Trigger3: false,
        required: false,
        DataReceivedArrayName: "description",
        DataSendArrayName: "",
        disabled: false,
        Category: "",
        VariableInput: "",
    },
];

export const SMSQuestions = [

    {
        key: "Details",
        Category: "Details",
        label: "Please provide details of the work",
        field1: "Please provide details of the work",
        Question: "Please provide details of the work",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Emergency",
        Category: "Emergency",
        label: "Is this Emergency? (yes/no)",
        field1: "Is this Emergency? (yes/no)",
        Question: "Is this Emergency? (yes/no)",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Location",
        Category: "Location",
        label: "Please provide location, room, bldg, etc. where service is needed",
        field1: "Please provide location, room, bldg, etc. where service is needed",
        Question: "Please provide location, room, bldg, etc. where service is needed",
        PreSelect: false,
        include: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    }, 
    {
        key: "Equipment",
        Category: "Equipment",
        label: "Please provide the name/tag of the equipment or system that need service. You can include picture of the equipment nameplate",
        field1: "Please provide the name/tag of the equipment or system that need service. You can include picture of the equipment nameplate",
        Question: "Equipment Info",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Quote",
        Category: "Quote",
        label: "Would you like quote/proposal for the work? (yes/no)",
        field1: "Would you like quote/proposal for the work? (yes/no)",
        Question: "Would you like quote/proposal for the work? (yes/no)",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Images",
        Category: "Images",
        label: "Please include pictures, short video, or say no images",
        field1: "Please include pictures, short video, or say no images",
        Question: "Please include pictures, short video, or say no images",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },
    //{
    //    key: "MarkLocation",
    //    Category: "MarkLocation",
    //    label: "Click here to mark GPS location.",
    //    field1: "Click here to mark GPS location.",
    //    Question: "Click here to mark GPS location.",
    //    PreSelect: false,
    //    include: false,
    //    VariableInput: false,
    //    keywords: {
    //        words: "",
    //        ESC: []
    //    }
    //}, 
    //{
    //  key: "Warratny",
    //  Category: "Warratny",
    //  label: "Warranty or new service issue?",
    //  field1: "Warranty or new service issue?",
    //  PreSelect: false,
    //  include: false,
    //  VariableInput: false,
    //  keywords: {
    //    words: "",
    //    ESC: []
    //  }
    //},


];

export const CheckInQuestions = [

    {
        key: "Details",
        Category: "Details",
        label: "What is the plan for today?",
        field1: "What is the plan for today?",
        Question: "What is the plan for today?",
        PreSelect: false,
        VariableInput: true,
        include: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },


    //{
    //    key: "endtime",
    //    Category: "endtime",
    //    label: "What time will you be done for today? please make sure to checkout when you are leaving.",
    //    field1: "What time will you be done for today? please make sure to checkout when you are leaving.",
    //    include: false,
    //    PreSelect: false,
    //    VariableInput: false,
    //    keywords: {
    //        words: "",
    //        ESC: []
    //    }
    //},

    {
        key: "Location",
        Category: "Location",
        label: "Please share your location",
        field1: "Please share your location",
        Question: "Please share your location",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    //{
    //    key: "Images",
    //    Category: "Images",
    //    label: "Optional - include pictures/short video or say no images",
    //    field1: "Optional - include pictures/short video or say no images",
    //    include: false,
    //    PreSelect: false,
    //    VariableInput: false,
    //    keywords: {
    //        words: "",
    //        ESC: []
    //    }
    //},
    //{
    //  key: "Warratny",
    //  Category: "Warratny",
    //  label: "Warranty or new service issue?",
    //  field1: "Warranty or new service issue?",
    //  PreSelect: false,
    //  include: false,
    //  VariableInput: false,
    //  keywords: {
    //    words: "",
    //    ESC: []
    //  }
    //},


];

export const CheckOutQuestions = [

    {
        key: "end",
        Category: "Details",
        label: "Please provide a short summary of the work completed.",
        field1: "Please provide a short summary of the work completed.",
        Question: "Please provide a short summary of the work completed.",
        PreSelect: false,
        include: false,
        VariableInput: true,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "pictures",
        Category: "Images",
        label: "Upload Pictures of the work completed, or say no pictures",
        field1: "Upload Pictures of the work completed, or say no pictures",
        Question: "Upload Pictures of the work completed, or say no pictures",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Assets",
        Category: "Assets",
        label: "Equipment/Location where service was performed",
        field1: "Equipment/Location where service was performed",
        Question: "Equipment/Location where service was performed",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Location",
        Category: "Location",
        label: "Please share your location",
        field1: "Please share your location",
        Question: "Please share your location",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },


];

export const PartsQuestions = [

    {
        key: "PartNumber",
        Category: "PartNumber",
        label: "Please enter Part Number. You can also include picture of the part and nameplate",
        field1: "Please enter Part Number. You can also include picture of the part and nameplate",
        Question: "Please enter Part Number. You can also include picture of the part and nameplate",
        PreSelect: false,
        include: false,
        VariableInput: true,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Quantity",
        Category: "Quantity",
        label: "Please enter quantity",
        field1: "Please enter quantity",
        Question: "Please enter quantity",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Equipment",
        Category: "Equipment",
        label: "Is this part for some equipment? (yes/no). If yes, please provide equipment make, model, pictures & details. Else, say No",
        field1: "Is this part for some equipment? (yes/no). If yes, please provide equipment make, model, pictures & details. Else, say No",
        Question: "Is this part for some equipment? (yes/no). If yes, please provide equipment make, model, pictures & details. Else, say No",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },  

];

export const OrderQuestions = [

    {
        key: "Shipping",
        Category: "Shipping",
        label: "Pickup or Shipping? please enter address if shipping option is selected",
        field1: "Pickup or Shipping? please enter address if shipping option is selected",
        Question: "Pickup or Shipping? please enter address if shipping option is selected",
        PreSelect: false,
        include: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Payment",
        Category: "Payment",
        label: "Payment type? if PO, please enter PO number",
        field1: "Payment type? if PO, please enter PO number",
        Question: "Payment type? if PO, please enter PO number",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

    {
        key: "Notes",
        Category: "Notes",
        label: "Notes or Instructions?",
        field1: "Notes or Instructions?",
        Question: "Notes or Instructions?",
        include: false,
        PreSelect: false,
        VariableInput: false,
        keywords: {
            words: "",
            ESC: []
        }
    },

];

export const ProgressQuestions = [

    {
        key: "parts",
        label: "Parts",
        field1: "Parts",
        labelTop: "Parts",
        VariableInput: false,
        include: true,
        showdate: true,
        date: "",
        datelabel: "Parts expected date",
        showoptions: true,
        options: "Ordered, No Parts Needed, On Hold, Back Ordered, In Stock",
        optionlabel: "Parts Order",
        optionsSelected: "",
        Notes: "",
        stepcomplete: false,
        required: true
    },
    {
        key: "schedule",
        label: "Personal Scheduled",
        field1: "Personal Scheduled",
        labelTop: "Personal Scheduled",
        include: true,
        VariableInput: false,
        showdate: true,
        date: "",
        datelabel: "Work start date",
        showoptions: false,
        options: "",
        optionlabel: "",
        optionsSelected: "",
        Notes: "",
        stepcomplete: false,
        required: true
    },
    {
        key: "workcompleted",
        label: "Work Completed",
        field1: "Work Completed",
        labelTop: "Work Completed",
        include: true,
        VariableInput: false,
        showdate: true,
        date: "",
        datelabel: "Work Completed",
        showoptions: false,
        options: "",
        optionlabel: "",
        optionsSelected: "",
        Notes: "",
        stepcomplete: false,
        required: true
    },
    {
        key: "workaccepted",
        label: "Work Accepted",
        field1: "Work Accepted",
        labelTop: "Work Accepted",
        include: true,
        VariableInput: false,
        showdate: true,
        date: "",
        datelabel: "Accepted Date",
        showoptions: false,
        options: "",
        optionlabel: "",
        optionsSelected: "",
        Notes: "",
        stepcomplete: false,
        required: true
    },

];
