import React from 'react';
//import { makeStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="New Option"
        inputProps={{ 'aria-label': 'New Option' }}
        required={props.required}
        id={props.id}
        defaultValue={props.defaultValue}
        label={props.label}
        name={props.name}
        value={props.value}
        fullWidth
        variant="outlined"
        onChange={(e) => props.GetOptions(e)}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        size="large">
        {props.UseIcon === "Add" && <AddCircleOutlineIcon onClick={() => props.AddCircleOutlineIcon()} />}
        {props.UseIcon === "Delete" && <DeleteIcon onClick={() => props.DeleteIcon(props.indexx)} />}
      </IconButton>
    </Paper>
  );
}
