import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
    Typography,
    Snackbar,
    TextField, Box
} from '@mui/material';
import {
    ConfirmationNumber
} from '../utils/CommonGraphql'
import { SendEmail } from '../utils/SendEmail';
import { API } from "aws-amplify";

export default function RadioButtonsGroup(props) {
    const initialState = {
        AlarmMessage: "",
        OpenAlert: false
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        ConfirmContact();

    }, [props.PersonInfo])



    //const handleNext = () => {

    //    //if (props.AccountType) {
    //    //    props.AccountType(state.AccountType);
    //    //}

    //    if (props.handleNext) {
    //            props.handleNext();
    //        }
    //}
    
    const onAddValue = (name, data) => {

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));


    }

    const ConfirmSignUp = async () => {

        //emailauthcode smsauthcode
        let emailConfirmed = false;
        let smsConfirmed = false;

        if (state.emailauthcode === state.skjdls) {
            emailConfirmed = true;
        }

        if (state.smsauthcode === state.alsiw) {
            smsConfirmed = true;
        }

        setState((prevState) => ({
            ...prevState,
            emailConfirmed: emailConfirmed,
            smsConfirmed: smsConfirmed
        }))

        if (smsConfirmed || emailConfirmed) {

            //go to next step
            if (props.handleNext) {
                props.handleNext();
            }

        } else {
            //message - not match, re-enter or resend code.

            setState((prevState) => ({
                ...prevState,
                OpenAlert: true,
                AlarmMessage: "Code did not match, please re-enter"
            }))

        }

    };

    const SendConfirmationEmail = (code) => {

        try {

            //let LocalDate = new Date(currentTime).toLocaleString();
            //let ContractorSubDomain = `https://${ContractorData.SubDomain}.service-call-cloud.com`;
            ////let title = `${state.SubscriptionName} - ${LocalDate}`;

            
            let DvLogo = "https://www.contractorcheckin.com/images/Logo_checkin_White.png"
            if (props.WebsiteType === "ServiceWithText") {
                DvLogo = "https://www.servicewithtext.com/images/Logo_svc_White.png";
            }

           
            let SendEvent = {

                PhotoService: [],
                Documents: [],
                SpecialInstructions: "",
                PreSelect: [],
                title: "Confirm Email",
                postHTML: `<p>Please confirm email</p><p>Email: ${props.PersonInfo.email}</p><p>Confirmation Code: ${code}</p>`,
                PersonList: [props.PersonInfo.email],
                postTEXT: `Please confirm email \n Email: ${props.PersonInfo.email} \n Confirmation Code: ${code}`,
                ReplyEmail: "support@sninc.us",
                PostId: "checkingemail",

                ContractorAddress: "30 N Gould St, Suite 37310, Sheridan, WY 82801",
                ContractorPhone: "(440) 643-8889",
                //ContractorLogo: state.ContractorDataActive.avatar,
                avatar: DvLogo,
                ContractorName: `${props.WebsiteType}.com`,

                UnsubscribeLink: `https://app.${props.WebsiteType}.com/Unsubscribe`,
                ActionButton: `https://app.${props.WebsiteType}.com`,
                ActionButtonTitle: "Go Online",
                ActionButtonShow: false
            }


            console.log(
                "LoginPage.test.2.SendEmail",
                SendEvent
            );

            SendEmail(SendEvent);



        } catch (err) { }

    }

    const SendConfirmationSMS = (code) => {

        try {

            var SMSstring = {
                Body: `Please confirm mobile # ${props.PersonInfo.mobile}. Confirmation Code: ${code}`,
                FromSMS: props.PersonInfo.mobile,
                From: "+15623936334",
                To: [props.PersonInfo.mobile],
                //PostId: PostId,
                mediaUrl: []
            };

            console.log(
                "LoginPage.test.2.SMSstring",
                SMSstring
            );

            API.post("SMStoTwilio", "", {
                body: { Records: SMSstring }
            });

        } catch (err) { }

    }

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const ConfirmContact = () => {

        
            let Emailcode = ConfirmationNumber();
            let SMScode = ConfirmationNumber();

            console.log(
                `SMS_Code: ${SMScode} -- email ${Emailcode}`
            );

        if (props.PersonInfo.email !== "") {
                //send email with code
                SendConfirmationEmail(Emailcode);
            }

        if (props.PersonInfo.mobile !== "") {
                //send sms to confirm
                SendConfirmationSMS(SMScode);
            }

            setState((prevState) => ({
                ...prevState,
                skjdls: Emailcode,
                alsiw: SMScode,
                step: "confirmcode"
            }))

        //} else {
        //    setState((prevState) => ({
        //        ...prevState,
        //        AlarmMessage: "Passwords did not match, please re-enter",
        //        open: true
        //    }))
        //}

    }

    console.log("SignUpWizStep.state", state)
    console.log("SignUpWizStep.props", props)
    
    return (
        <div key={props.KeyId}>
            <Grid container spacing={2}>

                

                <Grid item xs={12}>
                    
                    <>
                        <Box mb={3}>
                            
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                Please enter either Phone and/or Email code to activate the account.
                            </Typography>
                        </Box>

                        <Typography>{`Email: ${props.PersonInfo.email}`}</Typography>

                        <TextField
                            fullWidth
                            label="Email Authentication Code"
                            margin="normal"
                            name="authcode"
                            //onBlur={onAddValue}
                            onChange={e => onAddValue("emailauthcode", e.target.value)}
                            type="text"
                            value={state.emailauthcode || ""}
                            variant="outlined"
                        />

                        <Typography>{`Or, Mobile: ${props.PersonInfo.mobile}`}</Typography>

                        <TextField
                            fullWidth
                            label="Mobile Authentication Code"
                            margin="normal"
                            name="authcode"
                            //onBlur={onAddValue}
                            onChange={e => onAddValue("smsauthcode", e.target.value)}
                            type="text"
                            value={state.smsauthcode || ""}
                            variant="outlined"
                        />

                        {state.confirmcodemessage}
 
                        <Box my={2}>
                            <Button                                
                                onClick={() => ConfirmContact()}
                            >
                                Resend Code
                            </Button>
                        </Box>
                        

                    </>

                </Grid>
               
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => ConfirmSignUp()}
                            //  className={classes.button}
                            >
                                {'Confirm'}
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
                        

        </div>

    );
}
