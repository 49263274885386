import React, { useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import {
    Grid,
    //Select, MenuItem,
    //Backdrop, Box,
    //CircularProgress,
    //Card, CardHeader, Button, CardContent,
    //TextField,
    Typography,
} from '@mui/material';

const Checkout = (props) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency);
    console.log("Checkout.options", options)
    console.log("Checkout.props", props)
    const onCurrencyChange = ({ target: { value } }) => {
        setCurrency(value);
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: value,
            },
        });
    }

    const onCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: props.Amount,
                    },
                },
            ],
        });
    }

    const onApproveOrder = (data, actions) => {

        console.log("CheckOut.data", data)
        console.log("CheckOut.actions", actions)

        return actions.order.capture().then((details) => {

            console.log("CheckOut.details", details)

            if (props.ConfirmPayment) {
                props.ConfirmPayment(details)
            }
        });
    }

   

    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                <>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >

                        <Grid xs={12} item>
                            <Typography variant="h4" gutterBottom>
                                {`Amount Due: $${props.Amount} USD` }
                            </Typography>
                        </Grid>

                        <Grid xs={12} item>
                            <Typography gutterBottom>
                                You can pay in your currency with the PayPal button
                            </Typography>
                        </Grid>
                        
                        <Grid xs={12} item>
                            <PayPalButtons
                                style={{ layout: "vertical" }}
                                createOrder={(data, actions) => onCreateOrder(data, actions)}
                                onApprove={(data, actions) => onApproveOrder(data, actions)}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
}

export default Checkout;