import React, { useState, useEffect } from 'react';
import {
  Radio, RadioGroup, FormControlLabel, FormLabel, FormControl,
  Grid,
  
  //Container, Divider, Button, Box, MenuItem, Menu
} from '@mui/material';
import { makeStyles } from '@mui/styles';
//import { useParams, useLocation } from 'react-router-dom';
//import { API, graphqlOperation } from "aws-amplify";
//import { createBatch } from '../graphql/mutations';
//import Snackbar from '@mui/material/Snackbar';
//import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
//import Page from '../components/Page';
//import Requestor from './Requestor';
//import ItemAddForms from '../Add/ItemAddForms';
import { Pk1SingelItem, PrimaryKeyDataGet, FindLatestDate } from '../utils/CommonGraphql';
//import { FormSchema } from '../Forms/FormList';
//import { currentTime } from '../utils/DateTime';
//import UploadMain from '../Upload/UploadMain';
//import ComboForm from '../Forms/ComboForm';
//import DynamicForms from '../Forms/FormShow';
//import Loadable from 'react-loadable';
//import DrawerShow from '../utils/DrawerModule';

//const ComboForm = Loadable({
//  loader: () => import('../Forms/ComboForm'),
//  loading() {
//    return <div>Loading...</div>
//  }
//});

//const DynamicForms = Loadable({
//  loader: () => import('../Forms/FormShow'),
//  loading() {
//    return <div>Loading...</div>
//  }
//});

//var ""  //store = require('""  //store');

//function Alert(props) {
//  return <MuiAlert elevation={6} variant="filled" {...props} />;
//}

//const UUid = NewPageId("NewSvc");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

//function useQuery() {
//  return new URLSearchParams(useLocation().search);
//}

//const isNotEmptyObject = (value) => {
//  return value && value.constructor === Object && Object.keys(value).length > 0;
//}

const Account = (props) => {
  const classes = useStyles();
  //const params = useParams();
  //let query = useQuery();
  //const ContractorData = useSelector(state => state.ContractorData);
  ////const [savedetails, Setsavedetails] = useState(false);
  //const UserData = useSelector(state => state.UserData);
  //const cid = useSelector(state => state.cid);
  //const [DeawerOpen, SetDeawerOpen] = useState(false);
  //const [anchorEl, setAnchorEl] = useState(null);
  //const initialState = {
  //  Category: "",
  //  FormJSON: [],
  //  FormData: [],
  //  AlarmMessage: "",
  //  UrgencySelected: [],
  //  Documents: [],
  //  PhotoService: [],
  //  DrawerCategory: "",
  //  DrawerTitle: "",
  //  DetailComponent: "",
  //  DrawerFormData: [],
  //  NewService: [],
  //  TradeSelected: [],
  //  TotalFormData: [],
  //  Requestor: {},
  //  OpenAlert: false,
  //  ShowNewServiceForm: false,
  //  ShowNewServiceBox: false,
  //  ImagePic: ""
  //}
  //const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState(props.VariableInput);
  //const [FormGroupSelectFound, SetFormGroupSelectFound] = useState();

  //useEffect(() => {

  //  // if contractor - get the policy based on person selected
  //  if (UserData.CustomerType === "Contractor") {
  //    setState(prevState => ({
  //      ...prevState,
  //      ShowNewServiceForm: false,
  //      CompanyId: cid.CompanyId,
  //      CompanyName: cid.CompanyName,
  //    }));
  //  }

  //  if (UserData.CustomerType === "Company") {
  //    setState(prevState => ({
  //      ...prevState,
  //      Requestor: UserData,
  //      CompanyId: UserData.CompanyId,
  //      CompanyName: UserData.CompanyName,
  //      ShowNewServiceForm: true,
  //    }));
  //  }

  //}, [])

  //useEffect(() => {
  //  FirstStep();
  //}, [state.Requestor])

  const SelectedValues = data => {
    //let total = props.DataSent.find(x => x.key === data);
    //console.log("ShowTradeOption", total)
    setSelected(data);
    props.SelectedValues(data);
  }


  return (
   
              <Grid container spacing={1}>
      {props.DataSent && (
        <Grid key={props.id+"trade"} item md={12} >
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
            {/*    
          <FormLabel component="legend">{props.label}</FormLabel>
            */}
          <RadioGroup
              aria-label={props.LoadComponenet}
              id={props.id + "trade2"}
              name={props.VariableName}
            //error={ErrorMessage}
              value={selected || ""}
            required={props.required}
            onChange={(e) => {
              SelectedValues(e.target.value);
            }}
          >
              {props.DataSent.map((o, indd) => (
              <FormControlLabel
                id={props.id + indd}
                disabled={props.disabled}
                value={o.value || ""}
                control={<Radio />}
                label={o.label}
              />
            ))}
          </RadioGroup>
        </FormControl >
                </Grid>
      )}
    </Grid>
  );
};

export default Account;

