import React, { useEffect, useState } from 'react';
import {
    TextField,
    //Collapse,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    Snackbar,
    CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import UploadMain from './UploadMain';
//import { NewPageId } from '../utils/CommonGraphql';
//import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function RadioButtonsGroup(props) {
    const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        AssetList: []
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);


    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        //  console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])


    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const handleNext = () => {

        let Body = "No Attachments";
        if (state.PhotoService.length > 0 || state.Documents.length > 0) {
            Body = "(see Picture/Document)";
        }

        let SelectedDataReturn = {
            ...state,
            Body: Body,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("UploadBox.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }


    console.log("UploadBox.state", state)
    console.log("UploadBox.props", props)

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title={`Select ${props.Category}`}
                        />

                        <CardContent>


                            <UploadMain
                                id="PhotoService"
                                key="PhotoService"
                                title="Upload Images, PDF, or Zip"
                                ImageList={(e) => SaveImage("PhotoService", e)}
                                DocumentList={(e) => SaveImage("Documents", e)}
                                coid={ContractorData.ContractorId}
                                ImageStateSet={(e) => SaveImage("ImageStateSet", e)}
                                ImageStateSetBack={state.ImageStateSet}
                                fileallowed="any"
                                cat="PhotoNamePlate"
                            />

                        </CardContent>


                    </Card>

                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>



        </>

    );
}
