import React, { useEffect, useState } from 'react';
import {
    //TextField,
    //Collapse,
    //Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    //InputLabel, FormControl, MenuItem, Checkbox, 
    //RadioGroup,
    //FormControlLabel,
    //Radio, IconButton, CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import DrawerShow from '../utils/DrawerModule';


let Colorss = [
    {
        "name": "Pending Manager Approval",
        "color": "#f5a623",
        "key": "PendingManagerApproval"
    },
    {
        "name": "Manager Approved",
        "color": "#7ed321",
        "key": "ManagerApproved"
    },
    {
        "name": "Priority: Emergency",
        "color": "#d0021b",
        "key": "Emergency"
    },
    {
        "name": "Priority: High",
        "color": "#bd10e0",
        "key": "High"
    },
    {
        "name": "Priority: Medium",
        "color": "#4a90e2",
        "key": "Medium"
    },
    {
        "name": "Priority: Low",
        "color": "#f8e71c",
        "key": "Low"
    },
    {
        "name": "Box Color",
        "color": "#ffffff",
        "key": "BoxColor"
    }
]

export default function RadioButtonsGroup(props) {

    const initialState = {
        Colors: Colorss,
    }
    const [state, setState] = useState(initialState);
    const [DrawerStatus, OpenDrawer] = useState(false);

    useEffect(() => {

        //if (props && props.StepValueArray) {
        //  setState(prevState => ({
        //    ...prevState,
        //    ...props.StepValueArray[props.id],
        //  }));
        //} 

        //if (props.StepValueArray[props.id] && props.StepValueArray[props.id].Colors.length > 0) {
        //  //do nothing
        //} else {
        //  SetNewColors(Colorss)
        //}

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }
        console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }


    }, [])

    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("SMSListQuestions.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const ChangeColor = index => {

        setState(prevState => ({
            ...prevState,
            index: index
        }));

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        //GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    const SetNewColors = (NewColors) => {

        console.log("NewColors", NewColors)

        setState(prevState => ({
            ...prevState,
            Colors: NewColors
        }));

    }


    console.log("Colors.state", state)
    console.log("Colors.props", props)

    return (
        <>
            <Grid container spacing={1}>
                {state.Colors.map((each, ind) => (
                    <Grid key={"clrr" + ind} item xs={6}>
                        <Card >
                            <CardContent style={{
                                border: `2px solid ${each.color}`
                            }}>
                                <CardActions>
                                    <Button onClick={() => ChangeColor(ind)} size="small">{each.name}</Button>
                                </CardActions>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <DrawerShow
                LoadComponenet="ColorPicker"
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle="Color Selection"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    index: state.index,
                    Colors: state.Colors,
                    SelectedDataReturn: (NewColor) => SetNewColors(NewColor)
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </>

    );
}
