import React, { useState, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
import { useSelector, useDispatch } from 'react-redux';


import {
    TextField,
    //Collapse, 
    Box,
    Button,
    Grid,
    Backdrop,
    CircularProgress,
    Stack,
    Card,
    //Card, FormGroup,
    CardContent,
    CardActions,
    IconButton, Avatar, CardHeader,

    //InputLabel, Select, FormControl, MenuItem, Checkbox,
    Typography,
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';
import CheckInStatusSummery from '../List/CheckInStatusSummery';
import {
    removeEmptyStringElements,
    getAutoCompletedata,
    NewPageId,
    UpdateData,
    Pk1SingelItem,
    FindCategoryIcon
} from '../utils/CommonGraphql';

import DrawerShow from '../utils/DrawerModule';
import CheckInInformation from '../List/CheckInInformation';
import EventDataList from '../components/EventDataList2';
import ShowAvatarMessage from '../components/ShowAvatarMessage';
import MaterialGallery from '../Gallery/MaterialGallery';
import ListModule from '../List/ListModule';
import ShowPreSelect from '../components/ShowPreSelect';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import Skeleton from '../components/Skeleton';
import DocumentList from '../List/DocumentList';
import { onUpdate, onCreatePk } from '../graphql/subscriptions';
import ClipboardCopy from '../components/ClipboardCopy';
import axios from 'axios';
//import { CSVLink, CSVDownload } from "react-csv";
//import { FindNumber } from '../Twilio/AvailablePhoneNumber';


const accountSid = "ACec79b28f2727af3f2a273b2873f9d6be";  //process.env.TWILIO_ACCOUNT_SID;
const authToken = "a9ce8123819f8624301695a06d4dca9a"; //process.env.TWILIO_AUTH_TOKEN;

const UUid = NewPageId("SRDetail");

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

const SimpleCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [Refresh, setRefresh] = React.useState(1);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const Auth = useSelector(state => state.auth);
    const [loading, SetLoading] = useState(false);
    const initialState = {

        DetailComponent: "AddMessage",
        DrawerTitle: "Add Message",
        NewNumber: UUid,
        AddPrivateMessage: [],
        UpdateNumber: 0,
        UpdateCategory: "Meassage",
        SREvent: {},
        EventData: [],
        ProgressList: [],
        PhotoService: [],
        ShowManagerApprovalButton: false,
        StatusSymbol: "RuleIcon",
        StatusHeading: "Status Bar",
        ShowManagerApproved: "",
        LastStep: [],
        EquipmentList: [],
        LocationList: [],
        DisableAll: !Auth,
       // Refresh: true,
        ProgressWidth: 6,
        DisableAcceptButton: false,
        dir: ""
    }
    const [state, setState] = useState(initialState);

    console.log("CheckinDetails.1.Refresh", Refresh)

    const GetDatafromDB = async (Category, cid) => {
        try {
            SetLoading(true);
            console.log("Category, cid", Category, cid)
            const DataString = {
                Category: Category,
                FindArray: [
                    {
                        ArrayName: "DataBack",
                        //DuplicateArray: "RawData",
                        ArraySearchString: ":",
                    }
                ]
            };

            let NewData = await getAutoCompletedata(DataString, cid);

            //group each type of PostId
            console.log("SRDetails.NewData", NewData)

            let Data = NewData.DataBack;

            let PersonList = [];
            let Response = []
            let AssetsList = [];
            let PolicyList = [];
            let LocationList = [];
            if (Data && Data.length > 0) {
                Data.map(each => {
                    if (each.sk1 && each.sk1.includes("Response")) {
                        Response.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Message")) {
                        PersonList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Assets")) {
                        AssetsList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Policy")) {
                        PolicyList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Location")) {
                        LocationList.push(each)
                    }
                })
            }

            setState(prevState => ({
                ...prevState,
                PersonList: PersonList,
                AssetsList: AssetsList,
                PolicyList: PolicyList,
                LocationList: LocationList,
                Response: Response
            }))

            SetLoading(false);
        } catch (err) { console.log("SRDetails.GetDatafromDB.err", err) }
    }

    const PopulteUpdate = (SREvent, from) => {
        console.log("SRDetails.Subscription.2.SRDetails", from, SREvent)
        let ShowManagerApprovalButton = false;
        let StatusSymbol = "RuleIcon";
        let StatusHeading = "Status Bar";
        let ShowManagerMessage = false;
        let ManagerApprovedBy = [];
        let Read = "";
        // let SREvent = props.SREvent;
        let AllowManagerApproval = false;
        let UpdatePk = "";
        let ShowProgressEdit = true;
        let ShowFeedBack = false;
        let ShowActionBox = true;
        let ShareLink = "";
        let ProgressWidth = 6;

        if (!SREvent.Read) {
            SREvent.Read = [];
        }

        if (SREvent.ManagerApprovalStatus &&
            SREvent.ManagerApprovedBy &&
            SREvent.ManagerApprovedBy.length > 0) {
            ShowManagerMessage = true;
        }

        if (UserData.CustomerType === "Company" && UserData.Level > 100 && Auth) {
            AllowManagerApproval = true;
        }

        if (SREvent.ServiceDetailLink && SREvent.ServiceDetailLink !== "") {
            ShareLink = SREvent.ServiceDetailLink;
        }

        if (SREvent && SREvent.ServiceRequest && SREvent.ServiceRequest.pk1) {
            UpdatePk = SREvent.ServiceRequest.pk1;
        }

        let newtitle = ""
        if (SREvent.title && SREvent.title !== "") {
            newtitle = encodeURIComponent(SREvent.title)
        }

        let PostId = SREvent.PostId;
        GetDatafromDB("CheckInId", PostId);

        setState(prevState => ({
            ...prevState,
            SREvent: SREvent,
            StatusSymbol: StatusSymbol,
            StatusHeading: StatusHeading,
            ThisPostId: PostId,
            ShowProgressEdit: ShowProgressEdit,
            ShowActionBox: ShowActionBox,
            UpdatePk: UpdatePk,
            ShowManagerApprovalButton: SREvent.ManagerApprovalNeeded,
            ShowManagerMessage: ShowManagerMessage,
            ManagerApprovedBy: ManagerApprovedBy,
            AllowManagerApproval: AllowManagerApproval,
            ShowFeedBack: ShowFeedBack,
            ShareLink: ShareLink,
            newtitle: newtitle,
            ProgressWidth: ProgressWidth,
            ...SREvent
        }))
    }

    //useEffect(() => {
    //    console.log("SRDetails.props", props)

    //  //  GetTwilio();

    //    let DisableAcceptButton = props.DisableAll;

    //    // use props to populate the page
    //    if (props.SREvent && props.SREvent.GetData && Refresh === 1) {
    //        console.log("CheckinDetails.1.GetData")
    //        if (props.SREvent.ServiceRequest && props.SREvent.ServiceRequest.pk1) {
    //            //get data from DB
    //            GetFreshData(props.SREvent.ServiceRequest.pk1, props.SREvent.ServiceRequest.sk1);
    //            //refresh only one time
    //            setRefresh(2)
    //        }
    //    } else {

    //        console.log("CheckinDetails.1.populating SRevent")

    //        PopulteUpdate(props.SREvent, "start");
    //    }
        
    //    setState(prevState => ({
    //        ...prevState,
    //        DisableAll: props.DisableAll,
    //        DisableAcceptButton: DisableAcceptButton,
            
    //    }))
                
    //}, [props.SREvent])

    const InitialSRvalue = () => {
        console.log("SRDetails.props", props)

        //  GetTwilio();

        let DisableAcceptButton = props.DisableAll;

        // use props to populate the page
        if (props.SREvent && props.SREvent.GetData && Refresh === 1) {
            console.log("CheckinDetails.1.GetData")
            if (props.SREvent.ServiceRequest && props.SREvent.ServiceRequest.pk1) {
                //get data from DB
                GetFreshData(props.SREvent.ServiceRequest.pk1, props.SREvent.ServiceRequest.sk1);
                //refresh only one time
                setRefresh(2)
            }
        } else {

            console.log("CheckinDetails.1.populating SRevent")

            PopulteUpdate(props.SREvent, "start");
        }

        setState(prevState => ({
            ...prevState,
            DisableAll: props.DisableAll,
            DisableAcceptButton: DisableAcceptButton,

        }))

       
    }

    const PuttingSREvent = useMemo(() => {
        InitialSRvalue();
    }, [props.SREvent])
   
    const GetFreshData = async (pk, sk) => {

        let NewData2 = await Pk1SingelItem(pk, sk);
        let NewData = await NewData2;

        if (NewData) {
            let SREvent = JSON.parse(NewData.dataJSON);

            console.log("SRDetailsPkSk.NewData", SREvent)            

            PopulteUpdate(SREvent, "GetFreshData");
        }
    }

   

    //useEffect(() => {
    //    let UpdatedData = {};
    //    let SREvent = {};
    //    // let PostId = props.SREvent.PostId;
    //   // console.log("SRDetails.Subscription.UpdatePk", props.SREvent.pk1)

    //   // if (props.SREvent && props.SREvent.pk1 !== "") {
            
    //        const subscribe1 = API.graphql({
    //            query: onUpdate,
    //            variables: {
    //                pk1: props.SREvent.pk1
    //            }
    //        })
    //            .subscribe({
    //                next: newdata => {
    //                    UpdatedData = newdata.value.data.onUpdate.dataJSON;
    //                    SREvent = JSON.parse(UpdatedData)
                        

    //                    if (SREvent && SREvent.pk1 === props.SREvent.pk1 && SREvent.sk1 === props.SREvent.sk1) {
    //                        console.log("SRDetails.Subscription.1.SRDetails", SREvent)
    //                        PopulteUpdate(SREvent, "subs");
    //                    }

    //                }
    //            })

    //        if (subscribe1) {
    //            return () => {
    //                subscribe1.unsubscribe();
    //            }
    //        }
    //  //  }

    //}, [props.SREvent.pk1]);
    

   


    const CloseDrawer = () => {
        console.log("SRDetails.CloseDrawer")
        SetDeawerOpen(false)
    }

    const UpdateAsset = data => {
        console.log("SRDetails.1.UpdateAsset", data)
        if (data) {
            let PostId = props.SREvent.PostId;
            GetDatafromDB("CheckInId", PostId);
        }
    }

    //const UpdateDatabase = (data) => {
    //    console.log("SRDetails.1.UpdateDatabase", data)
    //    // props.UpdateSREvent(data);

    //    //if (props.UpdateSREvent) {
    //    //    props.UpdateSREvent(data)
    //    //}

    //    //var ValueToUpdate = {
    //    //    pk1: data.ServiceRequest.pk1,
    //    //    sk1: data.ServiceRequest.sk1,
    //    //    gsi1Pk2: data.gsi1Pk2,
    //    //    gsi2Pk2: data.gsi2Pk2,
    //    //    dataJSON: JSON.stringify(removeEmptyStringElements(data)),
    //    //};

    //    //console.log("SRDetails.1.pksk", ValueToUpdate)
    //    //console.log("SRDetails.1.datajson", data)

    //    //UpdateData(ValueToUpdate, "381-SRDetails")
    //    //    .then((up) => {
    //    //        console.log("SRDetails.Update.results", up)
    //    //        // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
    //    //    })
    //    //    .catch((err) => console.log("SRDetails.failed", err));
    //}

    const UpdateEvent = data => {
        console.log("NewCheckIn.Step4.UpdateEvent", data)
        setState(prevState => ({
            ...prevState,
            SREvent: data,
            ...data
        }))

      //  UpdateDatabase(data);
    }

    //const UpdateEvent2 = data => {
    //    console.log("SRDetails.1.UpdateEvent2", data)
    //    setState(prevState => ({
    //        ...prevState,
    //        SREvent: data,
    //    }))

    //    //if (props.UpdateSREvent) {
    //    //    props.UpdateSREvent(state.SREvent)
    //    //}

    //    //if (props.UpdateSREventFlag) {
    //    //    props.UpdateSREventFlag(true)
    //    //}

    //}

    const AddMessage = (number, Category, Message = "") => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AddMessage",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    const AcceptDecline = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AcceptDecline",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    const ManagerApproval = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "NewCheckIn",
            dir: "CheckOut",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category,
        }))

        SetDeawerOpen(true)
    }


    const AdditemDone = () => {
        setState(prevState => ({
            ...prevState,
            TriggerAdd: false,
        }))
    }


    const KeyArray = (data, cat) => {
        console.log("KeyArray", data, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));
    }

    const handleChange = (event, name) => {
        //let change = event.target.checked;
        console.log("Switch", event.target.checked, name)
        setState({
            ...state,
            [name]: event.target.checked,
        });
        //props.handleInclude(change, props.step.key);
    };

    //const AddPrivateMessage = () => {

    //  let NewNumber = state.NewNumber + 1;
    //  let Newbox = {
    //    key: NewNumber,
    //    MessageJSON: {}
    //  }

    //  let AddingNewBox = [...state.AddPrivateMessage, Newbox];

    //  setState({
    //    ...state,
    //    AddPrivateMessage: AddingNewBox,
    //    NewNumber: NewNumber
    //  });
    //}

    //const SelectedValues = (e, key) => {
    //  let AddPrivateMessage = state.AddPrivateMessage;
    //  AddPrivateMessage[key] = e;
    //  setState({
    //    ...state,
    //    AddPrivateMessage: AddPrivateMessage
    //  });
    //}

    const ItemHistory = (item) => {

        setState({
            ...state,
            ItemData: item,
            DetailComponent: "ShowDetailsPkSk"
        });

        console.log("SRDetails.ItemHistory", item)

        SetDeawerOpen(true);

    }

    const AddBox = () => {

        setState(prevState => ({
            ...prevState,
            NextLoadModule: "Details",
        }));

        SetDeawerOpen(true);
    }

    const AddAsset = (Category, DetailComponent) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            AddCategory: Category
        }));

        SetDeawerOpen(true);
    }

   


    console.log("SRDetails.state", state)


    return (
        <div className={classes.paper}>
            
            <Grid container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
            >                

                <Grid xs={12} md={6} item >
                    <Card>
                        <CardHeader
                            title={state.StatusHeading}
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon(state.StatusSymbol)}
                                </Avatar>
                            }
                        />
                        <CardActions>
                            <CheckInStatusSummery
                                SR={state.SREvent}
                            />
                        </CardActions>
                    </Card>
                </Grid>


                <Grid
                    xs={12}
                    md={6}
                    item >
                    <Card key={"crd1"} >
                        <CardHeader
                            title="Action"
                            //subheader="Information"

                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("decision")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            <Stack
                                direction={{ xs: 'column' }}
                                spacing={2}
                            >
                                {state.status !== "CheckOut" ? (
                                    <Button
                                        onClick={() => ManagerApproval()}
                                        color="primary"
                                        variant="contained"
                                        disabled={state.status === "CheckOut"}
                                    >

                                        CheckOut
                                    </Button>

                                ) : (
                                    <Typography variant="h4" gutterBottom component="div">
                                        {state.status}
                                    </Typography>
                                )}


                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>





                {props.SREvent.posterId && (
                    <Grid item xs={12}>
                        <CheckInInformation
                            Data={props.SREvent.posterId}
                        />
                    </Grid>
                )}

                {state.EventData && (
                <Grid xs={12} item>
                    <Card key={"crdgff1"}>
                        <CardHeader
                            title="Check in/out Messages"
                            //subheader="Information"
                            action={
                                <IconButton
                                    aria-label="settings"
                                    disabled={true}
                                    onClick={() => AddMessage(state.EventData.length, "Message")}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("AddSMS")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            
                            <EventDataList
                                SREvent={state.SREvent}
                                AddMessage={(number, Category) => AddMessage(number, Category)}
                                UpdateEvent={data => UpdateEvent(data)}
                                />
                            
                        </CardContent>
                    </Card>
                </Grid>
                )}

                <Grid xs={12} md={6} item >
                    <Card key={"creedsdfff1"} >
                        <CardHeader
                            title="All Images / Videos"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Document", "AddDocument")}
                                    disabled={state.DisableAll}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Image")}
                                </Avatar>
                            }
                        />
                        <CardContent>

                            <MaterialGallery
                                PhotoService={state.PhotoService}
                            />

                        </CardContent>
                    </Card>
                </Grid>



                <Grid xs={12} md={6} item >
                    <DocumentList
                        DocumentList={state.Documents}
                        AddAsset={() => AddAsset("Document", "AddDocument")}
                        DisableAll={state.DisableAll}
                    />
                </Grid>

                {state.AssetsList && state.AssetsList.length > 0 ? (
                    <Grid item xs={12} >
                        <Card key={"creedsd1"} >
                            <CardHeader
                                title="Assets"
                                action={
                                    <IconButton aria-label="settings"
                                        onClick={() => AddAsset("Assets", "AddAsset")}
                                        disabled={state.DisableAll}
                                        size="large">
                                        <EditIcon />
                                    </IconButton>
                                }
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Equipment")}
                                    </Avatar>
                                }
                            />

                            <CardContent>

                                <ListModule
                                    DataBack={state.AssetsList}
                                    title="Assets List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={!state.DisableAll}
                                    ShowDetailLink={!state.DisableAll}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={true}
                                    disabled={state.DisableAll}
                                />


                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}
                {/* 
                <Grid item xs={12} md={6} >
                    <Card key={"crkkjhd1"}>
                        <CardHeader
                            title="Service Location"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Location")}
                                    disabled={state.DisableAll}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Location")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            {state.LocationList.length > 0 ? (
                                <ListModule
                                    DataBack={state.LocationList}
                                    title="Location List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={!state.DisableAll}
                                    ShowDetailLink={!state.DisableAll}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={true}
                                    disabled={state.DisableAll}
                                />
                            ) : (<Skeleton />)}

                        </CardContent>
                    </Card>
                </Grid>
                */}

                {state.SpecialInstructions && state.SpecialInstructions !== "" ? (
                    <Grid item xs={12} >
                        <Card key={"crkkjhd1"}>
                            <CardHeader
                                title="Special Instructions"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("AssignmentIcon")}
                                    </Avatar>
                                }
                            />

                            <CardContent>
                                <Typography >
                                    {state.SpecialInstructions}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}
       

                {state.ShareLink && state.ShareLink !== "" ? (
                    <Grid item xs={12}>
                        <ClipboardCopy
                            copyText={state.ShareLink}
                            newtitle={state.newtitle}
                        />
                    </Grid>
                ) : null}

                {state.PersonList && state.PersonList.length > 0 ? (
                    <Grid item xs={12} >
                        <Card key={"Personcreedsd1"} >
                            <CardHeader
                                title="People Notified"

                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Person")}
                                    </Avatar>
                                }
                            />

                            <CardContent>
                               
                                    <ListModule
                                        DataBack={state.PersonList}
                                        title="People Notified"
                                        AddBox={e => AddBox(e)}
                                        AllowDelete={false}
                                        ShowDetailLink={false}
                                        // DetailLink={(e) => ItemHistory(e)}
                                        AllowEdit={false}
                                        disabled={true}
                                    />                              

                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}

                {state.Response && state.Response.length > 0 ? (
                    <Grid item xs={12} >
                        <Card key={"Personcreedsd1"} >
                            <CardHeader
                                title="Person Responded"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Person")}
                                    </Avatar>
                                }
                            />

                            <CardContent>

                                <ListModule
                                    DataBack={state.Response}
                                    title="People Notified"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={false}
                                    ShowDetailLink={false}
                                    // DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={false}
                                    disabled={true}
                                />


                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}

                {state.PolicyList && state.PolicyList.length > 0 ? (
                    <Grid item xs={12} >
                        <Card key={"Policycreedsd1"} >
                            <CardHeader
                                title="Shift Notified"

                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Policy")}
                                    </Avatar>
                                }
                            />

                            <CardContent>

                                <ListModule
                                    DataBack={state.PolicyList}
                                    title="Policy List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={false}
                                    ShowDetailLink={false}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={false}
                                    disabled={true}
                                />


                            </CardContent>
                        </Card>
                    </Grid>
                ): null}

            </Grid>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={props.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    SREvent: state.SREvent,
                    UpdateEvent: data => UpdateEvent(data),
                    UpdateAsset: data => UpdateAsset(data),
                    CurrentProgressStep: state.CurrentProgressStep,
                    UpdateNumber: state.UpdateNumber,
                    UpdateCategory: state.UpdateCategory,
                    Category: state.AddCategory,
                    LastStep: state.LastStep,
                    DrawerTitle: state.DrawerTitle,
                    dir: state.dir,
                    ItemData: state.ItemData,
                    key: NewPageId("srdet"),
                    id: NewPageId("srdetil"),
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </div>
    );
}

export default React.memo(SimpleCard)