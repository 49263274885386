import React, { useState, useEffect } from 'react';
import {
    Paper,
    Grid,
    Backdrop,
    CircularProgress,
    List, ListItem, ListItemIcon, ListItemText, Divider

} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { NewPageId } from '../utils/CommonGraphql';
//import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TextsmsIcon from '@mui/icons-material/Textsms';
import InfoIcon from '@mui/icons-material/Info';
//import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import EmailIcon from '@mui/icons-material/Email';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import HelpBox from '../components/HelpBox';

export default function SimpleAccordion(props) {
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const NowEditing = useSelector(state => state.NowEditing) 
    const initialState = {
        DataBack: [],
        propss: {},
        CompanyName: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        UserCustomerType: UserData.CustomerType,
      //  CompanyInfo: cid,
       // ItemInfo: cid,
        Blocks: [],
        AppliesTo: "Company",
        SetupType: "Custom",
        Shift: [],
    }
    const [state, setState] = useState(initialState);
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);


    useEffect(() => {
        
       // console.log("CompanyPrefSetup.UserData", UserData);
        

        // change company when different company selection is made through Toolbar
       // if (props.CompanyId) {
            console.log("CompanyPrefSetup.props", props);
            if (ContractorData.ContractorId === props.CompanyId) {
                setState(prevState => ({
                    ...prevState,
                    SetupType: "Default"
                }))
            } else {
                setState(prevState => ({
                    ...prevState,
                    SetupType: "Custom",
                    
                }))
            }

            setState(prevState => ({
                ...prevState,
                NewKey: NewPageId("key"),
                AppliesTo: "Company",
                CompanyName: props.CompanyName,
                ...props
            }))
       // }

    }, [props.CompanyId])



    const handleChange = (event) => {
        //console.log("event.target.value", event.target.name, event.target.value)
        let vvaluue = event.target.value
        let name = event.target.name;
        setState(prevState => ({ ...prevState, [name]: vvaluue }))
    };

    const SelectedCat = (ItemInfo) => {
        //console.log("cat", cat)
        console.log("ItemInfo", ItemInfo)
        setState(prevState => ({
            ...prevState,
            NewKey: NewPageId("key"),
            ItemInfo: ItemInfo,
            field2: state.CompanyInfo.field1,
            field1: ItemInfo.field1,
        }))
    };

    const SetUpDrawer = (
        loadcomponent,
        Category,
        ListType = "",
        NewLoadComponent = "",
        message = "",
        FormType = "",
        title = "") => {

        if (Category === "AssignedAccounts") {
            dispatch({ type: 'ACCOUNT_ACCESS', payload: props.ItemData });
        }
        console.log("Listitems.SetUpDrawer.Category", Category, loadcomponent)
        let data = {
            ...props.ItemData,
            LoadComponenet: loadcomponent,
            Category: Category,
            ListType: ListType,
            FormType: FormType,
            NewLoadComponent: NewLoadComponent,
            AppliesTo: state.AppliesTo,
            title: title,
            Activefield1: props.Activefield1,
            ActiveCategory: props.ActiveCategory,
            ActiveKey: props.ActiveKey,
           // Data: props.ItemData,
          //  ItemData: props.ItemData,
           // field2: state.CompanyInfo.field1,
           // field1: message,
            //CompanyInfo: state.CompanyInfo,
            //CompanyId: props.CompanyId,
            //sk1: props.CompanyId,
            //CompanyName: props.CompanyName,
            //CustomerType: props.CustomerType,
            
        }
        console.log("Listitems.SetUpDrawer", data)
        props.AddBox(data);
    };

    let SubCompany = [
        {
            key: "Information",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                ShowEditButton: true,
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
       
        {
            key: "SMSTrigger",
            label: `Custom Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Customize Notification Policy`,
                FormType: "",
                title: `Customize Notification Policy`,
            }
        },          

        {
            key: "EmailTrigger",
            label: `Email Triggers for ${state.Activefield1}`,
            Icon: <EmailIcon />,
            SetUpDrawer: {
                loadcomponent: "EmailTriggerSetup",
                Category: "EmailTrigger",
                ListType: "SMSSetup",
                NewLoadComponent: "UrgencyDetails",
                message: `Email Trigger for ${state.Activefield1}`,
                FormType: "",
                title: `Email Trigger for ${state.Activefield1}`,
            }
        },
        //{
        //    key: "PaymentListApp",
        //    label: `Payment`,
        //    Icon: <ShoppingCartIcon />,
        //    ShowHelp: false,
        //    title: "Payment",
        //    description: "",
        //    ShowVideo: false,
        //    VideoId: "11111111",
        //    SetUpDrawer: {
        //        loadcomponent: "PaymentListApp",
        //        Category: "Payment",
        //        ListType: "Payment",
        //        NewLoadComponent: "",
        //        message: ``,
        //        FormType: "",
        //        title: `Payment`,
        //    }
        //},         
       
    ];

    let CheckinCompanyEditCompany = [
        {
            key: "Information",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                ShowEditButton: true,
                message: "",
                FormType: "",
                title: `Information`,
            }
        },

        {
            key: "SMSTrigger",
            label: `Custom Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Customize Notification Policy`,
                FormType: "",
                title: `Customize Notification Policy`,
            }
        },

        //{
        //    key: "EmailTrigger",
        //    label: `Email Triggers for ${state.Activefield1}`,
        //    Icon: <EmailIcon />,
        //    SetUpDrawer: {
        //        loadcomponent: "EmailTriggerSetup",
        //        Category: "EmailTrigger",
        //        ListType: "SMSSetup",
        //        NewLoadComponent: "UrgencyDetails",
        //        message: `Email Trigger for ${state.Activefield1}`,
        //        FormType: "",
        //        title: `Email Trigger for ${state.Activefield1}`,
        //    }
        //},
        //{
        //    key: "PaymentListApp",
        //    label: `Payment`,
        //    Icon: <ShoppingCartIcon />,
        //    ShowHelp: false,
        //    title: "Payment",
        //    description: "",
        //    ShowVideo: false,
        //    VideoId: "11111111",
        //    SetUpDrawer: {
        //        loadcomponent: "PaymentListApp",
        //        Category: "Payment",
        //        ListType: "Payment",
        //        NewLoadComponent: "",
        //        message: ``,
        //        FormType: "",
        //        title: `Payment`,
        //    }
        //},         

    ];

    let ContractorEditCompany = [
        {
            key: "Information",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                ShowEditButton: true,
                message: "",
                FormType: "",
                title: `Information`,
            }
        },

        {
            key: "SMSTrigger",
            label: `Custom Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Customize Notification Policy`,
                FormType: "",
                title: `Customize Notification Policy`,
            }
        },

        {
            key: "EmailTrigger",
            label: `Email Triggers for ${state.Activefield1}`,
            Icon: <EmailIcon />,
            SetUpDrawer: {
                loadcomponent: "EmailTriggerSetup",
                Category: "EmailTrigger",
                ListType: "SMSSetup",
                NewLoadComponent: "UrgencyDetails",
                message: `Email Trigger for ${state.Activefield1}`,
                FormType: "",
                title: `Email Trigger for ${state.Activefield1}`,
            }
        },
        //{
        //    key: "PaymentListApp",
        //    label: `Payment`,
        //    Icon: <ShoppingCartIcon />,
        //    ShowHelp: false,
        //    title: "Payment",
        //    description: "",
        //    ShowVideo: false,
        //    VideoId: "11111111",
        //    SetUpDrawer: {
        //        loadcomponent: "PaymentListApp",
        //        Category: "Payment",
        //        ListType: "Payment",
        //        NewLoadComponent: "",
        //        message: ``,
        //        FormType: "",
        //        title: `Payment`,
        //    }
        //},         

    ];

    let CheckInOwner = [
        {
            key: "Information",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Information",
            description: "Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                ShowEditButton: true,
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        
        {
            key: "SMSTrigger",
            label: `Text/SMS Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Text/SMS Policy",
            description: "Text/SMS Policy",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Text/SMS Policy`,
                FormType: "",
                title: `Text/SMS Policy`,

                

            }
        },
        {
            key: "policy",
            label: `Policy Documents`,
            Icon: <EmailIcon />,
            ShowHelp: false,
            title: "Policy Documents",
            description: "Policy Documents",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "PolicyPage",
                Category: "Policy",
                ListType: "ListModule",
                NewLoadComponent: "PolicyDoc",
                message: `Policy Documents`,
                FormType: "",
                title: `Policy Documents`,
                id: NewPageId("Policy")
            }
        },
        
        {
            key: "AddSMSNumber",
            label: `Add New Number`,
            Icon: <ShoppingCartIcon />,
            ShowHelp: false,
            title: "Add New Number",
            description: "Add New Number",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "AddSMSNumber",
                Category: "AddSMSNumber",
                ListType: "ListModule",
                NewLoadComponent: "PolicyDoc",
                message: `Policy Documents`,
                FormType: "",
                title: `Policy Documents`,
                id: NewPageId("Policy")
            }
        },

        {
            key: "Connections",
            label: `Connect to other Apps`,
            Icon: <CloseFullscreenIcon />,
            ShowHelp: false,
            title: "Connections",
            description: "Connections",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "Connections",
                Category: "API",
                ListType: "ListModule",
                NewLoadComponent: "PolicyDoc",
                message: `Policy Documents`,
                FormType: "",
                title: `Policy Documents`,
                id: NewPageId("Policy")
            }
        },
       

    ];

    let SmallService = [
        {
            key: "Information",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Information",
            description: "Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                ShowEditButton: true,
                message: "",
                FormType: "",
                title: `Information`,
            }
        },

        {
            key: "SMSTrigger",
            label: `Text/SMS Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Text/SMS Policy",
            description: "Text/SMS Policy",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Text/SMS Policy`,
                FormType: "",
                title: `Text/SMS Policy`,



            }
        },
        //{
        //    key: "policy",
        //    label: `Policy Documents`,
        //    Icon: <EmailIcon />,
        //    ShowHelp: false,
        //    title: "Policy Documents",
        //    description: "Policy Documents",
        //    ShowVideo: false,
        //    VideoId: "11111111",
        //    SetUpDrawer: {
        //        loadcomponent: "PolicyPage",
        //        Category: "Policy",
        //        ListType: "ListModule",
        //        NewLoadComponent: "PolicyDoc",
        //        message: `Policy Documents`,
        //        FormType: "",
        //        title: `Policy Documents`,
        //        id: NewPageId("Policy")
        //    }
        //},

       

        {
            key: "AddSMSNumber",
            label: `Add New Number`,
            Icon: <ShoppingCartIcon />,
            ShowHelp: false,
            title: "Add New Number",
            description: "Add New Number",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "AddSMSNumber",
                Category: "AddSMSNumber",
                ListType: "ListModule",
                NewLoadComponent: "PolicyDoc",
                message: `Policy Documents`,
                FormType: "",
                title: `Policy Documents`,
                id: NewPageId("Policy")
            }
        },

        {
            key: "Connections",
            label: `Connect to other Apps`,
            Icon: <CloseFullscreenIcon />,
            ShowHelp: false,
            title: "Connections",
            description: "Connections",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "Connections",
                Category: "API",
                ListType: "ListModule",
                NewLoadComponent: "PolicyDoc",
                message: `Policy Documents`,
                FormType: "",
                title: `Policy Documents`,
                id: NewPageId("Policy")
            }
        },

    ];

   
    let PersonOptions = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",   //"ShowDetailsPkSk", //
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
       
        {
            key: "Remove",
            label: `Remove Person`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Remove Person",
            description: "Remove Person",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "RemovePerson",
                Category: "RemovePerson",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: ``,
                FormType: "",
                title: `Payment Setup`,
            }
        },
      
    ];

   
  
    let SubPerson = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        //{
        //    key: "SMSTrigger",
        //    label: `Custom Policy`,
        //    Icon: <TextsmsIcon />,
        //    ShowHelp: false,
        //    title: "Branch Information",
        //    description: "Branch Information",
        //    ShowVideo: false,
        //    VideoId: "11111111",
        //    SetUpDrawer: {
        //        loadcomponent: "SMSSetup",
        //        Category: "AddSMS",
        //        ListType: "SMSSetup",
        //        NewLoadComponent: "ItemAddPage",
        //        message: `Customize Notification Policy`,
        //        FormType: "",
        //        title: `Customize Notification Policy`,
        //    }
        //},       
    ]

    let CompanyPerson = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        {
            key: "SMSTrigger",
            label: `Custom Policy`,
            Icon: <TextsmsIcon />,
            ShowHelp: false,
            title: "Branch Information",
            description: "Branch Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "SMSSetup",
                Category: "AddSMS",
                ListType: "SMSSetup",
                NewLoadComponent: "ItemAddPage",
                message: `Customize Notification Policy`,
                FormType: "",
                title: `Customize Notification Policy`,
            }
        },       
    ]

    let CompanyEditPerson = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Information",
            description: "Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Person",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },
        
    ]

    let CompanyEditCompany = [
        {
            key: "Details",
            label: `Information`,
            Icon: <InfoIcon />,
            ShowHelp: false,
            title: "Information",
            description: "Information",
            ShowVideo: false,
            VideoId: "11111111",
            SetUpDrawer: {
                loadcomponent: "ShowDetailsText",  //"ShowDetailsPkSk",
                Category: "Company",
                ListType: "",
                NewLoadComponent: "",
                message: "",
                FormType: "",
                title: `Information`,
            }
        },

    ]

    useEffect(() => {

        let TabList = CheckInOwner;
        let trii = 0;

        /*
        if (UserData.CustomerType === "Company") {
            switch (props.ActiveCategory) {
                case "Person":
                    Blocks = PersonOptions;
                    trii = 1;
                    break;
                case "Company":
                    Blocks = PersonOptions;
                    trii = 2;
                    break;
                default:
                    Blocks = PersonOptions;
                    trii = 3;
            }
        }

        if (UserData.CustomerType === "Contractor") {
            
            switch (props.ActiveCategory) {
                case "Person":
                    Blocks = SubPerson;
                    trii = 4;
                    break;
                case "Company":
                    if (NowEditing === "Contractor") {
                        Blocks = CheckInOwner;
                        trii = 5;
                    } else {
                        Blocks = SubCompany;
                        trii = 6;
                    }
                    break;
                case "Contractor":
                    Blocks = CheckInOwner; 
                    trii = 7;
                    break;
                default:
                    Blocks = CheckInOwner;
                    trii = 8;
            }

            if (WebsiteType === "ServiceWithText") {

                if (NowEditing === "Contractor") {
                    Blocks = SmallService;
                    trii = 9;
                } else {
                    Blocks = SubCompany;
                    trii = 10;
                }

            }
        }

        */

        /////////////////////////////

        if (WebsiteType === "ServiceWithText") {

            if (UserData.CustomerType === "Contractor") {
                switch (props.ActiveCategory) {
                    case "Company":
                        TabList = SmallService;

                        if (NowEditing === "Contractor") {
                            TabList = SmallService;
                            trii = 17;
                        } else {
                            TabList = ContractorEditCompany; // SubCompany;
                            trii = 18;
                        }

                       // trii = 1;
                        break;
                    case "Person":
                       // TabList = SubPerson;

                        if (NowEditing === "Contractor") {
                            TabList = SubPerson;
                            trii = 21;
                        } else {
                            TabList = CompanyPerson; // SubCompany;
                            trii = 22;
                        }

                        
                        break;
                    default:
                        TabList = SmallService;
                        trii = 4;
                }
            }

            if (UserData.CustomerType === "Company") {
                switch (props.ActiveCategory) {
                    case "Company":
                        TabList = CompanyEditCompany;
                        trii = 5;
                        break;
                    case "Person":
                        TabList = CompanyEditPerson;
                        trii = 6;
                        break;
                    default:
                        TabList = CompanyEditCompany;
                        trii = 8;
                }
            }

        }

        if (WebsiteType === "ContractorCheckIn") {

            if (UserData.CustomerType === "Contractor") {
                switch (props.ActiveCategory) {
                    case "Company":
                        TabList = CheckInOwner;

                        if (NowEditing === "Contractor") {
                            TabList = CheckInOwner;
                            trii = 20;
                        } else {
                            TabList = CheckinCompanyEditCompany;
                            trii = 21;
                        }
                        
                        break;
                    case "Person":
                        TabList = SubPerson;
                        trii = 10;
                        break;
                    default:
                        TabList = CheckInOwner;
                        trii = 12;
                }
            }

            if (UserData.CustomerType === "Company") {
                switch (props.ActiveCategory) {
                    case "Company":
                        TabList = CompanyEditCompany;
                        trii = 13;
                        break;
                    case "Person":
                        TabList = CompanyEditPerson;
                        trii = 14;
                        break;
                    default:
                        TabList = CompanyEditCompany;
                        trii = 16;
                }
            }

        }
        
        ///////////////////////////////
      
        console.log("CompanyPrefSetup.CustomerType, NowEditing",
            trii,
            props.ActiveCategory,
            UserData.CustomerType,
            NowEditing);


        setState(prevState => ({
            ...prevState,
            Blocks: TabList,
        }))

      
    }, [state.ActiveCategory, props.CompanyId])

    console.log("CompanyPrefSetup.state", state);

    return state.Blocks.length > 0 && (
        <div
            //className={classes.root}
        >
            <Paper
                sx={{p:1}}
                
            >
                
                <Grid container spacing={1}>
                   
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Setup Menu
                            </Typography>
                        </Grid>
                   

                </Grid>
               

                <Grid item xs={12} >
                    <List component="nav" aria-label="Setup">

                        {state.Blocks.map((eachSetup, indd) => (
                            <ListItem
                                onClick={() => SetUpDrawer(
                                    eachSetup.SetUpDrawer.loadcomponent,
                                    eachSetup.SetUpDrawer.Category,
                                    eachSetup.SetUpDrawer.ListType,
                                    eachSetup.SetUpDrawer.NewLoadComponent,
                                    eachSetup.SetUpDrawer.message,
                                    eachSetup.SetUpDrawer.FormType,
                                    eachSetup.SetUpDrawer.title
                                )}
                                key={indd + eachSetup.key}
                                button
                            >
                                <ListItemIcon>
                                    {eachSetup.Icon}
                                </ListItemIcon>
                                <ListItemText primary={eachSetup.label} />

                                {eachSetup.ShowHelp && (
                                    <ListItemIcon>
                                    <HelpBox
                                            title={eachSetup.title}
                                            description={eachSetup.description}
                                            ShowVideo={eachSetup.ShowVideo}
                                            VideoId={eachSetup.VideoId}
                                        />
                                    </ListItemIcon>
                                )}

                            </ListItem>
                        ))}

                   </List>

                    

                </Grid>

            </Paper>

            <Backdrop
               // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
          

        </div>
    );
}
