import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
import {
  NewPageId,
  Pk1SingelItem,
  removeEmptyStringElements,
  FindLatestDate
} from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
  TextField,
  Collapse,
  Paper, Grid, Button,
  Backdrop, 
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
import { TotalList, schedule, emergency, todo, quote, SMSQuestions } from '../utils/UrgencyOptions';
import ContactBox from '../components/ContactBox';

//import Page from '../components/Page';

const UUid = NewPageId("Urgency");

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

let CategoryList = [
  //{
  //  label: "Main Location",
  //  Category: "Location",
  //  key: "Main_Location"
  //},
  {
    label: "Service Location",
    Category: "Location",
    key: "Service_Location"
  },
  //{
  //  label: "Bill to Location",
  //  Category: "Location",
  //  key: "Bill_to_Location"
  //},
  {
    label: "Equipment",
    Category: "Equipment",
    key: "Equipment"
  },
  {
    label: "Person to Contact",
    Category: "Person",
    key: "Contact_Person"
  },
  //{
  //  label: "Person Approving Cost",
  //  Category: "Person",
  //  key: "Person_Approving_cost"
  //}
  //,
  //{
  //  label: "Person Issuing PO",
  //  Category: "Person",
  //  key: "Person_Issuing_PO"
  //}
]


export default function SimpleCard(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const [loading, SetLoading] = useState(false);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);

  const initialState = {
   // TotalStepList: SMSQuestions,
    ContactList: [],
    Category: "",
    title: "Pre Select Information",
    ErrorMessage: false,
    disabled: false,
    Selectedkey: "Location",
    NewLoadComponent: "CreateContactUs"
  }
  const [state, setState] = useState(initialState);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

      useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

          console.log("Preselectemail.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])


  const CloseDrawer = () => {
    //console.log("CloseDrawer", e)
    SetDeawerOpen(false)
  }

  const handleChange = (event, name) => {
    setState({
      ...state,
      [name]: event.target.checked,
    });
  };

  const DeleteItem = (index, Arr) => {
    let arr = state[Arr];
    arr.splice(index, 1);
    setState((prevState) => ({ ...prevState, [Arr]: arr }));
  }

  const MoveUp = (index, Arr) => {
    let arr = state[Arr];
    let indexB = index - 1;
    if (indexB > -1 && indexB < arr.length) {
      let temp = arr[index];
      arr[index] = arr[indexB];
      arr[indexB] = temp;
      console.log("Up arr", arr);
      setState((prevState) => ({ ...prevState, [Arr]: arr }));
    }
  };

  const MoveDown = (index, Arr) => {
    let arr = state[Arr];
    let indexB = index + 1;
    if (indexB > -1 && indexB < arr.length) {
      let temp = arr[index];
      arr[index] = arr[indexB];
      arr[indexB] = temp;
      console.log("Down arr", arr);
      setState((prevState) => ({ ...prevState, [Arr]: arr }));
    }
  };

  const SaveData = () => {

    try {

      const key = "tempkey" //`Data::Online::Lambda::PreSelect::${props.Data.ListType}`;

        const PreSelect = {
        ContactList: state.ContactList,
        field1: state.title,
        field2: props.CompanyName,
        CompanyId: props.CompanyId,
        CompanyName: props.CompanyName,
        key: key,
        pk1: props.CompanyId,
        sk1: key,
        }

        handleNext(PreSelect)


    } catch (err) {
      console.log("SelectStepBox.err", err);
    }


  }

  const AddPerson = () => {

    SetDeawerOpen(true)

  };

    const handleInclude = (data) => {

        let ContactList = state.ContactList;

    console.log("ContactList.data", data)

        if (state.ContactList && state.ContactList.length > 0) {

            let findindex = ContactList.findIndex(x => x.key === data.key);

            if (findindex && findindex > -1) {
                ContactList[findindex] = data;
            } else {
                // if key does not exist, then add as new
                ContactList = [...state.ContactList, data];
            }
        } else {
            ContactList = [data]
        }

    console.log("ContactList.handleInclude", ContactList)

    setState(prevState => ({
      ...prevState,
      ContactList: ContactList
    }));
  }

  const SetValues = (data) => {

    console.log("ContactList.data", data)

    //let ContactList = state.ContactList;
    let findindex = CategoryList.find(x => x.key === data);

    if (findindex) {
      setState(prevState => ({
        ...prevState,
        Category: findindex.Category,
        field1: findindex.label,
        Selectedkey: findindex.key,
        title: `Select ${findindex.label}`
      }));
    }

    }

    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            field2: props.CompanyName,
            field1: "Pre Selected Asset & Contact",
            pk1: props.CompanyId,
            CompanyId: props.CompanyId,
            sk1: "Data::Online::Lambda::PreSelect::Company", //??????
            key: "Data::Online::Lambda::PreSelect::Company", //??????
            KeyId: props.KeyId
        }
        console.log("SelectAssetBox.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

  console.log("Preselectemail.state", state);

  return (
    <Paper className={classes.paper}>

      <Grid container spacing={1}>
        {props.Data.ListType !== "Contractor" && (
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
            <InputLabel id="demo-simple-select-outlined-label">Pre Select Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              //id={`select-${Item.key}-${index}`}
              defaultValue="Location"
              error={state.ErrorMessage}
              required={true}
              disabled={state.disabled}
              autoWidth={true}
              value={state.Selectedkey}
              onChange={(e) => {
                SetValues(e.target.value);
              }}
              label="Pre Select Category"
            >
              {CategoryList.map((o, indd) => (
                <MenuItem key={indd} value={o.key}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl >
        </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h4">
            {state.title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => AddPerson()}
          >
            {`Add ${state.Category}`}
          </Button>
        </Grid>

        
          {state.ContactList && state.ContactList.length > 0 && (
          <>
                {state.ContactList.map((eachstep, indd) => (
                  <Grid item xs={12} key={"eachstepbox"+indd}>
                    <ContactBox
                      key={"eachstep" + indd}
                      MoveUp={e => MoveUp(indd, "ContactList")}
                      MoveDown={e => MoveDown(indd, "ContactList")}
                      DeleteItem={() => DeleteItem(indd, "ContactList")}
                      CompanyId={props.CompanyId}
                      data={eachstep}
                    //  StepNumber={` (Question ${indd + 1})`}
                   // UpdatedValue={(vv, ind) => console.log("StepBox3", vv, ind)}
                  />
                  </Grid>
                ))}
              

                      {/*
                       * <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => SaveData()}
                >
                  Save List
          </Button>
                      </Grid>
                      */}

                      <Grid item xs={12}>
                          <div>
                              <div>
                                  <Button
                                      disabled={props.activeStep === 0}
                                      onClick={() => props.handleBack()}
                                  // className={classes.button}
                                  >
                                      Back
                                  </Button>
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => handleNext()}
                                  //  className={classes.button}
                                  >
                                      {'Next'}
                                  </Button>
                              </div>
                          </div>
                      </Grid>


           </>

          )}
       

      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DrawerShow
        LoadComponenet={state.NewLoadComponent}
        CloseDrawer={() => CloseDrawer()}
        DrawerTitle={state.Category}
        //LoadComponenet="ItemAddPage"
        propss={{
          Category: state.Category,
          CloseDrawer: (e) => CloseDrawer(e),
          handleInclude: (e) => handleInclude(e),
          label: state.field1,
          title: state.title,
            CompanyId: props.CompanyId,
          CompanyName: props.CompanyName,
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </Paper>
  );
}
