import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
//import moment from 'moment';
import {
    Card, CardContent,
    //FormControl, InputLabel, Select, MenuItem,
    //Switch,
    Button,
    //TextField, Stack,
    Snackbar,
   // Typography,
    Grid,
    CardHeader,
} from '@mui/material';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';
//import AutoComplateBox from '../components/AutoComplete';
import { API, graphqlOperation } from "aws-amplify";
//import { useDispatch } from 'react-redux';
import Page from '../components/Page';
//import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
//import CloudUpload from '@mui/icons-material/CloudUpload';
import { formatDate } from '../utils/DateTime';
//import UploadMain from '../Upload/UploadMain';
import { createBatch } from '../graphql/mutations';
//import { batchGet } from "../graphql/queries";
//import { DocumentType } from '../utils/Documents';
//import DrawerShow from '../utils/DrawerModule';
import DynamicForms from '../Forms/FormShow';
import { FormArray } from '../Forms/FormList';


//var dateToday = new Date();
//let currentTime = dateToday.toISOString();

const Profile = ({ className, ...rest }) => {
    const dispatch = useDispatch();
    //const [Requestor, SetRequestor] = useState(false);
   // const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    //const [SelectCompany, SetSelectCompany] = useState(false);
    //const [DrawerStatus, OpenDrawer] = useState(false);
    //const [AddAssetList, SetAddAssetList] = useState(false);
    //const [Upload, SetUpload] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        AssetList: [],
        CompanyName: "",
        CompanyId: "",
        UploadDocForm: [],
        uploadDocument: "",
        AlarmMessage: "",
        OpenAlert: false,
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        let UploadDocForm = GetDeveloperForm();
        if (UploadDocForm) {
            setState(prevState => ({
                ...prevState,
                UploadDocForm: UploadDocForm
            }));
        }
       
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };

    const GetDeveloperForm = () => {

        let DevEdit = FormArray("uploadDocument", NewPageId("uploadDocument"));
        if (DevEdit && DevEdit[0].FormData) {
            return DevEdit[0].FormData
        } else {
            return []
        }
    }


    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const SaveData = () => {

        try {

            var currentUnixTime = new Date();
            var currentTime = currentUnixTime.toISOString();
            let DateShow = formatDate(currentUnixTime);  //new Date().toLocaleString()
            let UUID = NewPageId("Doc")
            let InputArray = [];
            let Location = "";
            let NewFormJSON = {};
            let Description = ""
            let Field1 = "";
            let Field2 = "";
            let DocumentType = "";
            let avatar = "";
            let SearchWords = "";
            let AssetData = {};
            let PhotoService = [];
            let Documents = [];

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            

            if (state.uploadDocument
                && state.uploadDocument.FormData
                && state.uploadDocument.FormData.length > 0) {

                if (state.uploadDocument.PhotoService.length > 0
                    || state.uploadDocument.Documents.length > 0) {

                    if (state.uploadDocument.Documents.length > 0) {
                        Documents = state.uploadDocument.Documents;
                    }

                    if (state.uploadDocument.PhotoService.length > 0) {
                        PhotoService = state.uploadDocument.PhotoService;
                    }

                    state.uploadDocument.FormData.map((EachForm, indd) => {
                        switch (EachForm.VariableName) {
                            case "name":
                                Field1 = EachForm.ShowValue
                                break;
                            case "DocumentType":
                                Field2 = `${EachForm.ShowValue} ${DateShow}`;
                                DocumentType = EachForm.key;
                                break;
                            case "Description":
                                Description = EachForm.VariableInput
                                break;
                        }

                       // SearchWords += `${EachForm.label}: ${EachForm.ShowValue}, `;
                        if (EachForm.ShowValue && EachForm.ShowValue !== "") {
                            SearchWords += `${EachForm.ShowValue}, `;
                        }

                    })

                    let Dockey = `Doc::${DocumentType}::${currentTime}::${UUID}`

                    let Dataentry = {
                        pk1: rest.CompanyId,
                        sk1: Dockey,
                        gsi1Pk2: `Doc::${rest.CompanyId}`,
                        gsi1Sk2: currentTime,
                        gsi2Pk2: `Doc::${rest.CompanyId}`,
                        gsi2Sk2: currentTime
                    }

                    let Mini = {
                        field1: Field1,
                        field2: Field2,
                        AllItemForms: [state.uploadDocument],
                        PhotoService: PhotoService,
                        Documents: Documents,
                        Description: Description,
                        date: currentTime,
                        SearchWords: SearchWords,
                        Category: "Documents",
                        posterId: posterId,
                        key: Dockey,
                        ...Dataentry
                    };

                    let Dataentry2 = {
                        ...Dataentry,
                        dataJSON: JSON.stringify(removeEmptyStringElements(Mini))
                    }

                    InputArray.push(Dataentry2)

                    if (state.AssetList.length > 0) {
                        state.AssetList.map((each, indd) => {

                            if (each.Location) {
                                Location = each.Location;
                            } else {
                                Location = "";
                            }

                            if (each.avatar) {
                                avatar = each.avatar;
                            } else {
                                avatar = "";
                            }

                            AssetData = {
                                pk1: Dockey,
                                sk1: each.key,
                                gsi1Pk2: each.key, 
                                gsi1Sk2: Dockey,
                                gsi2Pk2: rest.CompanyId,
                                gsi2Sk2: Dockey
                            }

                            NewFormJSON = {
                                ...Mini,
                                field1: each.field1,
                                field2: `${Field2}: ${Field1}`,
                                Location: Location,
                                avatar: avatar,
                                key: each.key,
                                ...AssetData
                            }

                            InputArray.push({
                                ...AssetData,
                                dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
                            })

                            Location = "";
                        })
                    }

                    console.log("uploadDocument.InputArray", InputArray);
                    console.log("uploadDocument.Mini", Mini);
                    console.log("uploadDocument.NewFormJSON", NewFormJSON);

                    if (InputArray.length > 0) {
                    API.graphql(graphqlOperation(createBatch, { input: InputArray }))
                        .then((results) => {
                            console.log('createBatch Data ', results);
                            dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                            if (rest.CloseDrawer) {
                                rest.CloseDrawer();
                            }
                        })
                    }

                } else {
                    //alart upload document

                    setState(prevState => ({
                        ...prevState,
                        AlarmMessage: "Please Select Files for Upload",
                        OpenAlert: true
                    }));

                }
            } else {
                //alart upload document

                setState(prevState => ({
                    ...prevState,
                    AlarmMessage: "Please Complete the form",
                    OpenAlert: true
                }));

            }
           

            

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    console.log("uploadDocument.rest", rest)
    console.log("uploadDocument.state", state)

    return (
        <Page key={state.NewId}>
            <Grid container spacing={2}>
                {state.UploadDocForm && state.UploadDocForm.length > 0 && (
                <Grid item xs={12} >
                    
                            <DynamicForms
                                key={"uploadDocument11"}
                                id={"uploadDocument33"}
                                SaveDetails={true}
                                Category="uploadDocument"
                                FormType="uploadDocument"
                                DisableForm={false}
                                //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                //  CompanyId={state.CompanyId}
                                title="Add Document"
                                ShowEditButton={false}
                                UpdateFormData={e => console.log("UpdateFormData", e)}
                                PreviewEdit={false}
                                EditItem={e => console.log("EditItem", e)}
                                FormData={state.UploadDocForm}
                                onSubmit={(model) => SetValues(model, "uploadDocument")}
                            />

                       
                </Grid>
                )}

                <Grid item xs={12} >
                    <Card>
                        <CardHeader
                            title="Link Documents to Assets"
                        />

                        <CardContent>

                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "AssetList")}
                                CompanyId={rest.CompanyId}
                                CompanyName={rest.CompanyName}
                                title={`Select Asset`}
                                Category="Asset"
                                AssetList={state.AssetList}
                            />

                        </CardContent>


                    </Card>
                </Grid>
                <Grid xs={12} item>
                    <Button color="primary"
                        variant="contained"
                        onClick={() => SaveData()}>
                        {`Save List`}
                    </Button>
                </Grid>
            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
