import React, { useState } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Newpageiddd = NewPageId("toolbar")

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [SelectCompany, SetSelectCompany] = useState(false);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);

  const [UserCid, SetUserCid] = useState(UserData);

  const initialState = {
    CurrentCompany: UserCid.CompanyName,
  };

  const [state, setState] = useState(initialState);

  const GetCompanyName = (data) => {
    console.log("data", data);
    let CurrentCompany = data.field1;

    setState(prevState => ({
      ...prevState,
      CurrentCompany: CurrentCompany,
    }));

    SetSelectCompany(false);
    rest.CompanyData(data);

  }

  

  return (
    <div
      className={clsx(classes.root, className)}
      key={"key" + Newpageiddd}
      id={"id" + Newpageiddd}
    >

      <Grid container spacing={1} >
        
        <Grid item xs={12} >

          {rest.Category !== "Company" && (

            <Typography variant="h4">
              {state.CurrentCompany}
            </Typography>

          )}

          {UserCid.CustomerType === "Contractor" && rest.Category !== "Company" && (
              <Button
                //disabled={activeStep === 0}
                onClick={() => SetSelectCompany(!SelectCompany)}
                className={classes.button}
              >
                ({rest.AddCompany ? (`Add Company`) : (`Change Company`)})
              </Button>
          )}

          </Grid>
        
        

        {SelectCompany && UserCid.CustomerType === "Contractor" && (
          <Grid
            item
            xs={12}
          >
            <AutoComplateBox
              //SaveDetails={props.SaveDetails}
              //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
              Category="Company"
              FormType="Company"
              title="Company"
              id={Newpageiddd}
              ShowEditButton={false}
              cid={UserCid.CompanyId}
              ReturnBackData={e => console.log(e)}
              PreviewEdit={false}
              EditItem={e => console.log("EditItem", e)}
              //FormData={each.FormData}
              Selection={(model) => GetCompanyName(model)}
            />
          </Grid>
        )}

        
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  ProcessSearch: PropTypes.func
};

export default Toolbar;
