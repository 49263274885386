import Chart from 'react-apexcharts';
import React, { useState, useEffect } from "react";
import {
    Box, Card, CardHeader,
    Divider, Grid, Backdrop,
    CardContent, Typography,
    CircularProgress
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
    NewPageId,
    MiddlePostSearch,
    ContreactorPostSearch
} from '../utils/CommonGraphql';
import { adjustedTime } from '../utils/DateTime';
import HoverRating from '../components/Rating';

let olddate = adjustedTime(-90, "Start");
let Todaydatestring = adjustedTime(2, "End"); //Todaydate.toISOString();

const ProductChannel = (props) => {
    const [loading, SetLoading] = useState(false);
    const NowEditing = useSelector(state => state.NowEditing);
    const initialState = {
        Communication: 0,
        WorkmanShip: 0,
        Recommend: 0,
        readOnly: true,
        NewId: "92929383"
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        GetDatafromDB(props.CompanyId, olddate, Todaydatestring)

        //setState(prevState => ({
        //    ...prevState,
            
            
        //}))

        console.log("RatingChart.Props", props);

    }, [props.CompanyId])

    const GetDatafromDB = async (pk1, startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let CommunicationTotal = 0;
        let CommunicationCount = 0;
        let WorkmanShipCount = 0;
        let WorkmanShipTotal = 0;
        let RecommendCount = 0;
        let RecommendTotal = 0;
        let FeedBackdata = {};
        console.log("RatingChart.DBinput", NowEditing, pk1, startDate, endDate);

        if (NowEditing === "Contractor") {
            NewData = await ContreactorPostSearch("Post::FeedBack::" + pk1, startDate, endDate);
        } else {
            NewData = await MiddlePostSearch("Post::FeedBack::" + pk1, startDate, endDate);
        }

        SetLoading(false);

        console.log("RatingChart.ShowString.NewData1", NewData);
        let ShowString = "";
        if (NewData) {
            NewData.map(d => {
                ShowString = JSON.parse(d.dataJSON);
                console.log("RatingChart.FeedBackdata1.each", ShowString);

                if (ShowString && ShowString.Feedback !== undefined && ShowString.Feedback != null) {
                    FeedBackdata = ShowString.Feedback
                    console.log("RatingChart.FeedBackdata2.each", FeedBackdata);
                    if (FeedBackdata.Communication) {
                        CommunicationCount += 1;
                        CommunicationTotal += Number(FeedBackdata.Communication);
                        console.log("RatingChart.FeedBackdata5", CommunicationCount, CommunicationTotal);
                    }

                    if (FeedBackdata.WorkmanShip) {
                        WorkmanShipCount += 1;
                        WorkmanShipTotal += FeedBackdata.WorkmanShip;
                    }

                    if (FeedBackdata.Recommend) {
                        RecommendCount += 1;
                        RecommendTotal += FeedBackdata.Recommend;
                    }
                }
                FeedBackdata = "";
               // console.log("Charts.ShowString.Progress", ShowString.Progress, ZeroBucket);
            })
        }

        console.log("RatingChart.FeedBackdata3", CommunicationCount, CommunicationTotal);

        let Communication = Math.round(CommunicationTotal / CommunicationCount, 1);
        let WorkmanShip = Math.round(WorkmanShipTotal / WorkmanShipCount, 1);
        let Recommend = Math.round(RecommendTotal / RecommendCount, 1);

        console.log("RatingChart.FeedBackdata4", Communication);

        setState(prevState => ({
            ...prevState,
            Communication: Communication,
            WorkmanShip: WorkmanShip,
            Recommend: Recommend,
            NewId: NewPageId("chart"),
        }))
        

        
       // console.log("SRPage.NewData2", AutoSelectData);
    }

    console.log("RatingChart.state", state);
   // console.log("Charts.state.options", options);

    return state.NewId && (
            <div key={state.NewId}>
                <Card
                    variant="outlined"
                    sx={{ height: '100%' }}
                >
                    <CardHeader
                    title={props.CompanyName}
                        subheader="Last 90 days"
                    />
                    <Divider />
                
                <CardContent>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>

                                <Grid xs={6} item>
                                    <Typography>
                                        Communication
                                    </Typography>
                                </Grid>

                                <Grid xs={6} item>
                                    <HoverRating
                                        RatingsValue={state.Communication || ""}
                                        key="Communication"
                                        readOnly={state.readOnly}
                                    />
                                </Grid>


                                <Grid xs={6} item>
                                    <Typography>
                                        WorkmanShip
                                    </Typography>
                                </Grid>

                                <Grid xs={6} item>
                                    <HoverRating
                                        RatingsValue={state.WorkmanShip || ""}
                                        key="WorkmanShip"
                                        readOnly={state.readOnly}
                                    />
                                </Grid>

                                <Grid xs={6} item>
                                    <Typography>
                                        Recommend Service to Others
                                    </Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <HoverRating
                                        RatingsValue={state.Recommend || ""}
                                        key="Recommend"
                                        readOnly={state.readOnly}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>
        <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ProductChannel;