import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    Avatar,
    TextField,
    Button,
    Grid,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    CardHeader,
    Snackbar,
} from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import MuiAlert from '@mui/material/Alert';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
//import SelectCategory from '../components/SelectCategory';
import { NewPageId } from '../utils/CommonGraphql';
import AutocompleteModule from '../components/AutoComplete';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';
import ContactBox from '../components/ContactBox'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NewKey = NewPageId("Contact")


//const useStyles = makeStyles((theme) => ({
//  root: {
//    // width: '100%',
//    //maxWidth: '36ch',
//    flexGrow: 1,
//    backgroundColor: theme.palette.background.paper,
//  },
//  inline: {
//    display: 'inline',
//  },
//}));

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}
// => "+1 (234) 567-8900"

const Profile = (props) => {
    // const classes = useStyles();
    const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const dispatch = useDispatch()

    const initialState = {
        CompanyId: cid.CompanyId,
        RequesterList: [],
        TotalPerson: [],
        BaseInfoArray: [],
        ExtraInfoArray: [],
        BaseInfo: [],
        ExtraInfo: [],
        ShowBox: false,
        field1: "",
        OpenAlert: false
        //CustomerType: "Company",
        //ShowPersonalAuto: false
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("CreateContactUs.props", props);
        setState(prevState => ({
            ...prevState,
            CompanyId: props.CompanyId,
            role: props.label
        }));

    }, [])

    useEffect(() => {
        //console.log("CreateContactUs.BaseInfoArray", state.BaseInfoArray);
        if (state.BaseInfoArray.length > 0) {
            setState(prevState => ({
                ...prevState,
                ShowBox: true
            }));
        }
    }, [state.BaseInfoArray])

    useEffect(() => {

        let TotalPerson = state.TotalPerson;
        let label = `${TotalPerson.field1}, ${TotalPerson.field2}`;
        TotalPerson.BaseInfo = state.BaseInfo;
        TotalPerson.ExtraInfo = state.ExtraInfo;
        TotalPerson.role = state.role;
        TotalPerson.Memo = state.Memo;
        TotalPerson.label = `${state.role}: ${label}, Please Confirm with yes, or enter correct ${state.role}`;
        console.log("CreateContactUs.TotalPerson", state.TotalPerson);
        if (state.BaseInfoArray.length > 0) {
            setState(prevState => ({
                ...prevState,
                TotalPerson: TotalPerson
            }));
        }
    }, [state.ExtraInfo, state.BaseInfo, state.role, state.Memo])

    const SetValues = (item, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: item,
        }));

    };


    const SelectAsset = (Person) => {
        console.log("CreateContactUs.SelectedPerson", Person)
        setState(prevState => ({
            ...prevState,
            ShowBox: false
        }));

        try {
            let FindFormData = Person.AllItemForms;
            let showchecked = false;
            let BaseInfoArray = [];
            let NewVariableInput = "";
            FindFormData.map((BaseInfo, indd) => {
                if (BaseInfo && BaseInfo.FormData) {
                    if (indd === 0) {
                        showchecked = true;
                    } else {
                        showchecked = false;
                    }

                    
                    console.log("CreateContactUs.BaseInfo.FormData", BaseInfo.FormData);
                    BaseInfo.FormData.map((each, indd) => {
                        if (each && each.VariableInput && each.VariableInput !== undefined) {

                            if (each.type === "phone" || each.type === "mobile") {
                                NewVariableInput = formatPhoneNumber(each.ShowValue);
                            } else {
                                NewVariableInput = each.ShowValue;
                            }

                            
                            //selecting what to show as an option
                            if (each.ListInfo) {

                                BaseInfoArray.push({
                                    key: each.VariableName + indd,
                                    sk: Person.key,
                                    //field1: each.label,
                                    field1: `${each.label}: ${NewVariableInput}`,
                                    Category: each.Category,
                                    VariableInput: showchecked,
                                    VariableName: each.VariableName // use VariableName to update the field
                                })

                            }
                        }
                    })
                }
            })


            let ExtraInfoArray = [];
            let ExtraInfo = Person.FormExtra;
            console.log("CreateContactUs.BaseInfo.ExtraInfo", ExtraInfo)
            //console.log("CreateContactUs.BaseInfo.FormData", BaseInfo.FormData);
            if (ExtraInfo) {
                ExtraInfo.map((each, indd) => {
                    if (each) {
                        ExtraInfoArray.push({
                            key: each.Category + indd,
                            sk: Person.key,
                            //field1: each.Category,  
                            field1: each.SearchWords.replace(/,([^,]*)$/, '$1'),
                            Category: each.Category, // use category to update the field  
                            VariableInput: false,
                        })
                    }
                })
            }

            let KeyInformation = {};
            if (Person.SearchWords && Person.SearchWords !== "") {
                KeyInformation.SearchWords = Person.SearchWords;
            }
            if (Person.Location && Object.keys(Person.Location).length !== 0) {
                KeyInformation.Location = Person.Location;
            }
            if (Person.mobile && Person.mobile !== "") {
                KeyInformation.mobile = Person.mobile;
            }
            if (Person.phone && Person.phone !== "") {
                KeyInformation.email = Person.email;
            }
            if (Person.email && Person.email !== "") {
                KeyInformation.email = Person.email;
            }

            let TotalPerson = {
                BaseInfo: BaseInfoArray,
                ExtraInfo: ExtraInfoArray,
                role: props.label,
                field1: Person.field1,
                field2: Person.field2,
                KeyInformation: KeyInformation,
                key: Person.key,
                Category: props.Category,
                PreSelect: true,
                SearchWords: Person.SearchWords
            }

            setState(prevState => ({
                ...prevState,
                BaseInfoArray: BaseInfoArray,
                ExtraInfoArray: ExtraInfoArray,
                TotalPerson: TotalPerson,
                field1: Person.field1,
                BaseInfo: BaseInfoArray,
                ExtraInfo: ExtraInfoArray
                // ShowBox: true
            }));

            //console.log("CreateContactUs.BaseInfoArray", BaseInfo, BaseInfoArray)
            //console.log("CreateContactUs.ExtraInfoArray", ExtraInfo, ExtraInfoArray)
            //console.log("CreateContactUs.TotalPerson", TotalPerson)

        } catch (err) { console.log("CreateContactUs.SelectAsset.err", err) }

    }

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };


    const AddItem = () => {

        if (!state.role || state.role === "") {
            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please Role/Label",
                OpenAlert: true
            }));
        } else {
            console.log("state.TotalPerson", state.TotalPerson)
            if (props.handleInclude) {
                props.handleInclude(state.TotalPerson)

                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
                
            }
        }

    }

    const KeyArray = (data, cat) => {
        console.log("ProgressList.KeyArray", data, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));
    }

    //console.log("CreateContactUs.state", state)
    //console.log("CreateContactUs.ExtraInfo", state.ExtraInfo)
    //console.log("cid", cid)
    //console.log("state", state)


    console.log("CreateContactUs.state", state);

    return (
        <div
        // className={classes.root}
        >
            <Card>
                <CardHeader
                    title={props.title}
                />

                <CardActions>

                    <AutocompleteModule
                        key={NewKey}
                        id={NewKey}
                        Category={props.Category}
                        FormType={props.Category}
                        title={props.Category}
                        CompanyId={props.CompanyId}
                        Selection={(e) => SelectAsset(e)}
                    />

                </CardActions>
                <CardContent>
                    {state.ShowBox && (
                        <Card>
                            <CardHeader
                                title={`Add ${state.field1}`}
                            />

                            <CardContent>
                                <Grid container spacing={2} >

                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {`Add ${state.field1}'s role in the account`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required={false}
                                            label="Heading / Role / Position"
                                            name="role"
                                            value={state.role || ""}
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => KeyArray(e.target.value, "role")}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {`Select ${state.field1}'s Base Information to show`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <ListCheckboxMoveUpDown
                                            Data={state.BaseInfoArray}
                                            title="Base Information"
                                            SearchWords=""
                                            ShowUpDown={true}
                                            ShowEdit={false}
                                            //  AddBox={(e) => AddBox(e)}
                                            DefaultChecked={state.DefaultChecked || []}
                                            NextLoadComponenet="AddSetupSelection"
                                            Category="Contact"
                                            FullJSONBack={(e) => KeyArray(e, "BaseInfo")}
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {`Select ${state.field1}'s Extra Information to show`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <ListCheckboxMoveUpDown
                                            Data={state.ExtraInfoArray}
                                            title="Addional Information"
                                            ShowUpDown={true}
                                            ShowEdit={false}
                                            SearchWords=""
                                            //   AddBox={(e) => AddBox(e)}
                                            DefaultChecked={state.DefaultChecked || []}
                                            NextLoadComponenet="AddSetupSelection"
                                            Category="Contact"
                                            FullJSONBack={(e) => KeyArray(e, "ExtraInfo")}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>

                                        <TextField
                                            id="Memo1"
                                            key="Memo2"
                                            disabled={state.disabled}
                                            label="Notes / Memo / Instructions"
                                            variant="outlined"
                                            name="Memo"
                                            value={state.Memo || ""}
                                            fullWidth
                                            multiline
                                            rows={2}
                                            onChange={(e) => SetValues(e.target.value, "Memo")}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => AddItem()}
                                        >
                                            Add
                                        </Button>
                                    </Grid>


                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </CardContent>
                <Divider />

            </Card>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

        </div>
    );
};


export default Profile;
