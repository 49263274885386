import Checkout from './Checkout';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

//https://dev.to/paypaldeveloper/how-to-add-paypal-checkout-payments-to-your-react-app-53aa

const initialOptions = {
    "client-id": process.env.REACT_APP_PPclientid,
    currency: "USD",
    intent: "capture",
    "enable-funding": "venmo",
    //vault: true,
    components: "applepay,buttons"
};

function App(props) {
    return (
        <PayPalScriptProvider options={initialOptions}>
            <Checkout
                Amount={props.Amount}
                ConfirmPayment={e => props.ConfirmPayment(e)}
              //  DataBack={props.DataBack}
               // email={props.email}
            />
        </PayPalScriptProvider>
    );
}

export default App;