import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Tooltip, Chip, Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DrawerBottomCurved from '../utils/DrawerModule';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import SRStatusSummery from '../List/SRStatusSummery';
import {
    removeEmptyStringElements,
    getAutoCompletedata,
    NewPageId,
    UpdateData,
    FindCategoryIcon
} from '../utils/CommonGraphql';
import { DateTime } from "luxon";


let Colorss = [
    {
        name: "Pending Manager Approval",
        color: "#f5a623",
        key: "PendingManagerApproval"
    },
    {
        name: "Manager Approved",
        color: "#7ed321",
        key: "ManagerApproved"
    },
    {
        name: "Priority: Emergency",
        color: "#d0021b",
        key: "Emergency"
    },
    {
        name: "Priority: High",
        color: "#bd10e0",
        key: "High"
    },
    {
        name: "Priority: Medium",
        color: "#4a90e2",
        key: "Medium"
    },
    {
        name: "Priority: Low",
        color: "#f8e71c",
        key: "Low"
    },
    {
        name: "Box Color",
        color: "#ffffff",
        key: "BoxColor"
    }
];

export default function RecipeReviewCard(props) {
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const UserData = useSelector(state => state.UserData);
    const dispatch = useDispatch();
    const initialState = {
        title: "",
        message: "",
        include: false,
        DrawerTitle: "Service Request Details",
        Progress: 0,
        ProgressList: [],
        label: "this is what",
        UpdateSREvent: "",
        UpdateSREventFlag: false,
        ManagerApprovalStatusColor: "#f5a623",
        Priority: "#4a90e2",
        BoxColor: "#ffffff",
        ShowLinkk: true
    }
    const [state, setState] = useState(initialState);

    const ClosingDrawer = () => {
     //   dispatch({ type: 'TRIGGER_UPDATE', payload: true });
        UpdateDatabase();
        SetDeawerOpen(false)
    }

    const UpdateDatabase = () => {

       // UpdateSREvent(data);

        let data = state.UpdateSREvent;

        if (data && data.ServiceRequest && data.ServiceRequest.pk1) {

            var ValueToUpdate = {
                pk1: data.ServiceRequest.pk1,
                sk1: data.ServiceRequest.sk1,
                gsi1Pk2: data.gsi1Pk2,
                gsi2Pk2: data.gsi2Pk2,
                dataJSON: JSON.stringify(removeEmptyStringElements(data)),
            };

            console.log("SRDetails.1.pksk", ValueToUpdate)
            console.log("SRDetails.1.datajson", data)

            UpdateData(ValueToUpdate, "381-SRDetails")
                .then((up) => {
                    console.log("SRDetails.Update.results", up)
                    // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                })
                .catch((err) => console.log("SRDetails.failed", err));
        }
    }

    useEffect(() => {
        if (props && props.SR && props.SR.postTEXT) {
            let Shwomessage = props.SR.postTEXT.substring(0, 100);
            if (props.SR.ColorSelection) {
                Colorss = props.SR.ColorSelection;
            }

            if (props.SR.EventData && props.SR.EventData.length > 0) {
                if (props.SR.EventData[0].Body === "Service Needed") {
                    Shwomessage = props.SR.EventData[1].Body.substring(0, 100);
                } else {
                    Shwomessage = props.SR.EventData[0].Body.substring(0, 100);
                }
            }

            let ShowRead = "#F5F2C9";
            if (props.SR && props.SR.Read) {
                let FindRead = props.SR.Read.findIndex(x => x.sk1 === UserData.key);
                if (FindRead > -1) {
                    ShowRead = "#ffffff";
                }
            }

            let ManagerApprovalStatusColor = "#f5a623";

            //if (props.SR.ManagerApprovalStatus && props.SR.ManagerApprovalStatus === "Approved") {
            //    ManagerApprovalStatus = Colorss.find(x => x.key === "ManagerApproved");
            //}

            let BoxColor = Colorss.find(x => x.key === "BoxColor");
            if (!BoxColor) {
                BoxColor = "#ffffff"
            }

            let Priority = "#4a90e2";
            let Prirotity2 = "";

            if (props.SR.ManagerApprovalStatus) {
                switch (props.SR.ManagerApprovalStatus) {
                    case "Pending":
                        ManagerApprovalStatusColor = Colorss.find(x => x.key === "PendingManagerApproval");
                        break;
                    case "Approved":
                        ManagerApprovalStatusColor = Colorss.find(x => x.key === "ManagerApproved");
                        break;
                    default:
                        ManagerApprovalStatusColor = Colorss.find(x => x.key === "ManagerApproved");
                }
            }

            if (props.SR.Priority) {
                switch (props.SR.Priority) {
                    case "High":
                        Prirotity2 = Colorss.find(x => x.key === "High");
                        break;
                    case "Medium":
                        Prirotity2 = Colorss.find(x => x.key === "Medium");
                        break;
                    case "Low":
                        Prirotity2 = Colorss.find(x => x.key === "Low");
                        break;
                    default:
                        Prirotity2 = Colorss.find(x => x.key === "Medium");
                }
            }

            if (Prirotity2 && Prirotity2 !== "") {
                Priority = Prirotity2.color;
            }

            let ShowLinkk = true;
            let UTCTume = DateTime.utc().toString();
            let diff = 60;
            let Callstart1 = new Date(props.SR.ServiceRequest.sk1).getTime();
            let Callstart = new Date(Callstart1 + diff * 60000).getTime();
            let CallNowTime = new Date(UTCTume).getTime();
            let currentTime = props.SR.date; //props.SR.currentTime; //.replace("Z", "");
            let zonne = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let NewTime = props.SR.date // moment(currentTime).format('LLL');  //zonne; //new Date(currentTime).toLocaleString();
            let title = props.SR.title;
            if (props.SR.status === "Initiated") {
                title += " (incomplete)";
                ShowRead = "#ff9933";

                if (CallNowTime < Callstart) {
                    ShowLinkk = false;
                }
            }

            console.log("SRBox.currentTime", currentTime, NewTime)

            setState(prevState => ({
                ...prevState,
                ManagerApprovalStatusColor: ManagerApprovalStatusColor,
                BoxColor: BoxColor,
                Priority: Priority,
                title: title,
                NewTime: NewTime,
                ShowLinkk: ShowLinkk,
                ShowRead: ShowRead,
                Shwomessage: Shwomessage
            }));

        }
    }, [props.SR])

    const SRDetails = () => {
        SetDeawerOpen(true);
    }

    const SelectedValues = (item, name) => {
        console.log("SRBox.UpdateSREvent", item)
        setState({
            ...state,
            [name]: item,
        });
        // props.handleInclude(change, props.step.key);
    };
    console.log("SRBox.props", props)
    console.log("SRBox.state", state)

    return (
        <>
            <Paper sx={{ border: 1, borderColor: state.BoxColor }} >
                <Card>
                    <CardHeader
                        avatar={props.SR.posterId && props.SR.posterId.avatar && props.SR.posterId.avatar !== "" ? (
                            <Avatar src={props.SR.posterId.avatar} />
                        ) : (
                            <Avatar ></Avatar>
                        )}

                        action={state.ShowLinkk && (
                            <IconButton aria-label="settings" onClick={() => SRDetails()} size="large">
                                <MoreVertIcon />
                            </IconButton>
                        )}
                        sx={{ backgroundColor: state.ShowRead }}
                        subheader={state.NewTime}
                        title={state.title}
                    />

                    {state.Shwomessage && state.Shwomessage !== "" && (
                        <Box sx={{ m: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                                {state.Shwomessage}...
                            </Typography>
                        </Box>
                    )}

                    <CardActions>
                        <SRStatusSummery
                            SR={props.SR}
                        />
                       
                    </CardActions>
                </Card>

            </Paper>
            <DrawerBottomCurved
                LoadComponenet="SRDetails"
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle={state.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: () => ClosingDrawer(),
                    SREvent: props.SR,
                    UpdateSREvent: data => SelectedValues(data, "UpdateSREvent"),
                    //UpdateSREventFlag: data => SelectedValues(data, "UpdateSREventFlag")
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="right"
            />
        </>
    );
}
