
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
//import 'react-big-calendar-like-google/lib/css/react-big-calendar.css'
//import myEventsList from '../Preference/DatesTest'

const localizer = momentLocalizer(moment);



const MyCalendar = props => {
    console.log('Calendar.props', props);

    const GetMonth = (date) => {
        props.ActiveDate(date);
        //let datee = new Date(date);
        //let Month = datee.getMonth();
       // console.log("calendar.date", date)
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
      //  console.log("Calendar.event", event);

        var style = {
            backgroundColor: event.ShiftBoxColor,
            borderRadius: '4px',
            opacity: 0.8,
            color: event.ShiftTextColor,
            border: '0px',
            display: 'block'
        };
        return ({
            style: style
        })
    }

    return (
        <div>
            {props.myEventsList && (
                <Calendar
                    localizer={localizer}
                    events={props.myEventsList}
                    startAccessor="start"
                    onSelectEvent={(e) => props.onSelectEvent(e)}
                    //tooltipAccessor={(e) => console.log("calendar.tooltipAccessor", e)}
                    //getNow={(e) => console.log("calendar.getNow", e)}
                    onNavigate={(e) => GetMonth(e)}
                    //getNow={(e) => console.log("calendar.getNow", e)}
                    //getNow={(e) => console.log("calendar.getNow", e)}
                    toolbar={true}
                    popup={true}
                    selectable={true}
                    views={['month']}
                    endAccessor="end"
                    style={{ height: 1000 }}
                    eventPropGetter={event => eventStyleGetter(event)}
                />
            )}
        </div>
    )
}

export default MyCalendar;
