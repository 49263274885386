import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
//import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    Breadcrumbs,
    Link
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';
import Tabs from '../components/Tabs';
import { useDispatch } from 'react-redux';
import {
    ContractorTabs,
    CompanyTabs, PersonTabs, PersonServiceTabs,
    AssetTabs, HQTabs,
    SubTabs, GlobalTabs,
    OwnerTabs, SubCheckinTab,
    SmallContractorTabs, SmallCompanyTabs
} from './TabsList';
import Page from '../components/Page';
import ToolbarHeading from '../components/ToolbarHeading';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

//const useStyles = makeStyles((theme) => ({
//    root: {
//        margin: theme.spacing(1),
//        backgroundColor: theme.palette.background.dark,
//        minHeight: '100%',
//        paddingBottom: theme.spacing(3),
//        paddingTop: theme.spacing(3)
//    },
//    importButton: {
//        marginRight: theme.spacing(1)
//    },
//    exportButton: {
//        marginRight: theme.spacing(1)
//    }
//}));

const Newpageiddd = NewPageId("toolbar")

const Toolbar = (props) => {
    //  const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [SelectCompany, SetSelectCompany] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const UserData = useSelector(state => state.UserData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    let NowEditing = useSelector(state => state.NowEditing);
    const HQData = useSelector(state => state.HQData);

    const items = useLocation();
    let navigate = useNavigate();

    const initialState = {
        CurrentCompany: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        CompanyName: UserData.CompanyName,
        CustomerType: UserData.CustomerType,
        TabList: []
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        NewValues()

    }, [])

    useEffect(() => {

        NewValues(items.state)

    }, [items, params])

    useEffect(() => {

        NewValues(props.Data)

    }, [props.Data])

    const NewValues = (NewItems) => {

        console.log('CompanyPage.NewItems', NewItems);
        console.log('CompanyPage.items', items);

        let NewData = "";

        if (items && items.state) {
            NewData = items.state;
        }

        if (NewItems && NewItems != null) {
            NewData = NewItems;
        }

        let TabList = [];
        let CompanyId = UserData.CompanyId;
        let CustomerType = UserData.CustomerType;
        let CompanyName = UserData.CompanyName;
        // let NowEditing = UserData.CustomerType;
        let BranchType = UserData.BranchType;
        let UserEditor = UserData.CustomerType;
        let BranchId = UserData.BranchId;
        let HQId = UserData.HQId;
        //let field1 = UserData.field1;
        let ActiveKey = UserData.key;
        let sk1 = UserData.sk1;
        let field1 = UserData.CompanyName;
        let Category = "Company";
        let ContractorId = ContractorData.CompanyId;
        let ContractorName = ContractorData.CompanyName;
        let Refresh = NewPageId("CP");

        let BreadCrum1 = ContractorData.field1;
        let Linkdata1 = ContractorData;
        let BreadCrum2 = "";
        let Linkdata2 = "";

        console.log('CompanyPage.NowEditing-109', NowEditing);
        console.log('CompanyPage.CompanyId.Userdata', Category, CompanyId);

        //if (BranchType === "HQ") {
        //    Category = "HQ";
        //}

        //  using this module to show company data
        if (params.CompanyId) {
            CompanyId = params.CompanyId;
            CompanyName = params.CompanyName;
            CustomerType = "Company";
            field1 = CompanyName;
            //  BreadCrum1 = "";
            console.log('CompanyPage.CompanyId.params', CustomerType, Category, CompanyId);
        }

        //if contractor is going to its page, they use contractor as CustomerType
        if (UserData.CompanyId === params.CompanyId) {
            CompanyId = UserData.CompanyId;
            CustomerType = UserData.CustomerType;
            //  NowEditing = UserData.CustomerType;
            CompanyName = UserData.CompanyName;
            console.log('CompanyPage.NowEditing-132', NowEditing);
            console.log('CompanyPage.CompanyId.params=UserData', CustomerType, Category, CompanyId);
        }

        if (NewData) {
            field1 = NewData.field1;

            console.log('CompanyPage.NewData', NewData);

            ActiveKey = NewData.key;
            if (NewData.AssetPkSk && NewData.AssetPkSk.sk1) {
                ActiveKey = NewData.AssetPkSk.sk1;
            }

            //   BreadCrum1 = (<Link to={`../${NewData.CompanyId}/${NewData.CompanyName}`}>{NewData.CompanyName}</Link>);
            if (NewData.CustomerType) {
                NowEditing = NewData.CustomerType;
            }

            CustomerType = NewData.CustomerType;
            sk1 = NewData.sk1;
            Category = NewData.Category;
            CompanyId = NewData.CompanyId;
            CompanyName = NewData.CompanyName;
            BranchId = NewData.BranchId;
            HQId = NewData.HQId;
            ContractorId = NewData.ContractorId;
            ContractorName = NewData.ContractorName;

            // ActiveKey = NewData.key;

            if (Category === "Person") {
                field1 = NewData.field1;
            }

            if (Category === "Company") {
                BreadCrum2 = NewData.field1;
                Linkdata2 = NewData;
            }

            if (Category === "Branch") {
                CompanyId = NewData.key;
                CompanyName = NewData.field1;
                BranchType = NewData.BranchType;
                //   UserEditor = NewData.CustomerType;
            }

            // if (Category === "Branch" && BranchType === "HQ") {
            //     Category = "HQ";
            //  }

            //   CustomerType = NewData.CustomerType;
            console.log('CompanyPage.NowEditing-172', NowEditing);
        }

        let TapsProps = {
            CompanyId: CompanyId,
            UserCompanyId: UserData.CompanyId,
            CustomerType: CustomerType,
            CompanyName: CompanyName,
            NowEditing: NowEditing,
            Activefield1: field1,
            ActiveCategory: Category,
            ActiveKey: ActiveKey,
            UserEditor: UserEditor,
            BranchId: BranchId,
            HQId: HQId,
            Category: Category,
            ContractorId: ContractorData.CompanyId,
            ContractorName: ContractorData.CompanyName,
            ItemData: NewData,
            ContractorType: "Contractor"
        }

        console.log('CompanyPage.Category', Category, CustomerType);

        dispatch({ type: 'NOW_EDITING', payload: NowEditing });

        //switch (Category) {
        //    case "Company":
        //        dispatch({ type: 'CURRENT_COMPANY', payload: NewData });

        //        switch (CustomerType) {
        //            case "Contractor":
        //                TabList = OwnerTabs(TapsProps);
        //                BreadCrum1 = "";
        //                Linkdata1 = "";
        //                BreadCrum2 = "";
        //                Linkdata2 = "";
        //                dispatch({ type: 'CONTRACTOR_DATA', payload: NewData });
        //                break;
        //            case "Company":
        //                TabList = CompanyTabs(TapsProps);
        //                BreadCrum1 = ContractorData.field1;
        //                Linkdata1 = ContractorData;
        //                BreadCrum2 = "";
        //                Linkdata2 = "";
        //                break;

        //            //case "Sub":
        //            //    TabList = SubTabs(TapsProps);
        //            //    BreadCrum1 = "";
        //            //    Linkdata1 = "";
        //            //    BreadCrum2 = "";
        //            //    Linkdata2 = "";
        //            //    break;
        //            default:
        //                TabList = CompanyTabs(TapsProps);
        //                BreadCrum1 = ContractorData.field1;
        //                Linkdata1 = ContractorData;
        //                BreadCrum2 = "";
        //                Linkdata2 = "";
        //        }
        //        break;

        //    case "Sub_Contractor":
        //        TabList = SubCheckinTab(TapsProps);
        //        if (NowEditing === "Contractor") {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = "";
        //            Linkdata2 = "";
        //        } else {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = "";
        //            Linkdata2 = "";
        //        }
        //        dispatch({ type: 'ACCOUNT_ACCESS', payload: NewData });
        //        break;

        //    case "Person":
        //        TabList = PersonTabs(TapsProps);
        //        if (NowEditing === "Contractor") {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = "";
        //            Linkdata2 = "";
        //        } else {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = CurrentCompany.CompanyName;
        //            Linkdata2 = CurrentCompany;
        //        }

        //        break;
        //    case "Location":
        //        TabList = AssetTabs(TapsProps);

        //        if (NowEditing === "Contractor") {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = "";
        //            Linkdata2 = "";
        //        } else {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = CurrentCompany.CompanyName;
        //            Linkdata2 = CurrentCompany;
        //        }

        //        break;
        //    case "HQ":
        //        TabList = HQTabs(TapsProps);
        //        break;
        //    case "Contractor":
        //        TabList = OwnerTabs(TapsProps);
        //        BreadCrum1 = "";
        //        BreadCrum2 = "";
        //        dispatch({ type: 'CONTRACTOR_DATA', payload: NewData });

        //        break;
        //    case "Branch":
        //        TabList = OwnerTabs(TapsProps);
        //        BreadCrum1 = "";
        //        BreadCrum2 = "";
        //        dispatch({ type: 'CONTRACTOR_DATA', payload: NewData });
        //        break;
        //    case "Global":
        //        TabList = GlobalTabs(TapsProps);
        //        BreadCrum1 = "";
        //        BreadCrum2 = "";
        //        //  dispatch({ type: 'CONTRACTOR_DATA', payload: NewData });
        //        break;

        //    case "GlobalCompany":
        //        TabList = CompanyTabs(TapsProps);
        //        BreadCrum1 = ContractorData.field1;
        //        Linkdata1 = ContractorData;
        //        BreadCrum2 = "";
        //        Linkdata2 = "";
        //        break;

        //    case "Assets":
        //        TabList = AssetTabs(TapsProps);
        //        if (NowEditing === "Contractor") {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = "";
        //            Linkdata2 = "";
        //        } else {
        //            BreadCrum1 = ContractorData.field1;
        //            Linkdata1 = ContractorData;
        //            BreadCrum2 = CurrentCompany.CompanyName;
        //            Linkdata2 = CurrentCompany;
        //        }
        //        break;
        //    default:
        //        TabList = CompanyTabs(TapsProps);
        //}

        let whichbox = 0;
        let trii = 0;

        /*
        if (UserData.CustomerType === "Contractor") {

            if (Category === "Company") {

                if (CustomerType === "Contractor") {
                    whichbox = 1;

                    if (WebsiteType === "ContractorCheckIn") {
                        TabList = OwnerTabs(TapsProps);
                        trii = 1;
                    } else {
                        TabList = SmallContractorTabs(TapsProps);
                        trii = 2;
                    }
                    BreadCrum1 = "";
                    Linkdata1 = "";
                    BreadCrum2 = "";
                    Linkdata2 = "";
                    dispatch({ type: 'CONTRACTOR_DATA', payload: NewData });
                }
                if (CustomerType === "Company") {
                    dispatch({ type: 'CURRENT_COMPANY', payload: NewData });
                    whichbox = 11;

                    if (WebsiteType === "ContractorCheckIn") {
                        TabList = CompanyTabs(TapsProps);
                        trii = 3;
                    } else {
                        TabList = SmallCompanyTabs(TapsProps);
                        trii = 4;
                    }
                    BreadCrum1 = ContractorData.field1;
                    Linkdata1 = ContractorData;
                    BreadCrum2 = "";
                    Linkdata2 = "";
                }
            }

            if (Category === "Person") {
                whichbox = 2;
               // TabList = PersonTabs(TapsProps);

                if (WebsiteType === "ContractorCheckIn") {
                    TabList = PersonTabs(TapsProps);
                    trii = 5;
                } else {
                    TabList = PersonServiceTabs(TapsProps);
                    trii = 6;
                }

                BreadCrum1 = ContractorData.field1;
                Linkdata1 = ContractorData;
                BreadCrum2 = "";
                Linkdata2 = "";
            }

            if (Category === "Assets") {
                TabList = AssetTabs(TapsProps);
                trii = 7;
                BreadCrum1 = ContractorData.field1;
                Linkdata1 = ContractorData;
                BreadCrum2 = "";
                Linkdata2 = "";
            }
        }

       // console.log('CompanyPage.inperson', WebsiteType, Category, UserData.CustomerType);


        if (UserData.CustomerType === "Company") {
            dispatch({ type: 'CURRENT_COMPANY', payload: NewData });
            if (Category === "Company") {
                whichbox = 3;

                if (WebsiteType === "ContractorCheckIn") {
                    TabList = CompanyTabs(TapsProps);
                    trii = 8;
                } else {
                    TabList = SmallCompanyTabs(TapsProps);
                    trii = 9;
                }

                BreadCrum1 = ContractorData.field1;
                Linkdata1 = ContractorData;
                BreadCrum2 = "";
                Linkdata2 = "";
            }

            if (Category === "Contractor") {
                whichbox = 4;
                //come up with owner uneder sub
                if (WebsiteType === "ContractorCheckIn") {
                    TabList = CompanyTabs(TapsProps);
                    trii = 10;
                } else {
                    TabList = SmallCompanyTabs(TapsProps);
                    trii = 11;
                }

                BreadCrum1 = ContractorData.field1;
                Linkdata1 = ContractorData;
                BreadCrum2 = "";
                Linkdata2 = "";
            }

            if (Category === "Person") {
                whichbox = 5;
                
                if (WebsiteType === "ContractorCheckIn") {
                    TabList = PersonTabs(TapsProps);
                    trii = 12;
                } else {
                    TabList = PersonServiceTabs(TapsProps);
                    trii = 13;
                }

                BreadCrum1 = ContractorData.field1;
                Linkdata1 = ContractorData;
                BreadCrum2 = "";
                Linkdata2 = "";
            }

        }
    */
        ////////////////// try new way //////////////////////

        if (WebsiteType === "ServiceWithText") {

            if (CustomerType === "Contractor") {
                switch (Category) {
                    case "Company":
                        TabList = SmallContractorTabs(TapsProps);
                        dispatch({ type: 'CONTRACTOR_DATA', payload: NewData });

                        //put in contractor logo
                        //if (NewData.avatar && NewData.avatar !== "") {
                        //    let NewHQData = HQData;
                        //    NewHQData.avatar = NewData.avatar;
                        //    dispatch({ type: 'HQ_DATA', payload: NewHQData });
                        //}

                        BreadCrum1 = "";
                        Linkdata1 = "";
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 1;
                        break;
                    case "Person":
                        TabList = PersonServiceTabs(TapsProps);
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 2;
                        break;
                    case "Assets":
                        TabList = AssetTabs(TapsProps);
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 3;
                        break;
                    default:
                        TabList = SmallContractorTabs(TapsProps);
                        trii = 4;
                }
            }

            if (CustomerType === "Company") {
                switch (Category) {
                    case "Company":
                        TabList = SmallCompanyTabs(TapsProps);
                        dispatch({ type: 'CURRENT_COMPANY', payload: NewData });
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 5;
                        break;
                    case "Person":
                        TabList = PersonServiceTabs(TapsProps);
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 6;
                        break;
                    case "Assets":
                        TabList = AssetTabs(TapsProps);
                        trii = 7;
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        break;
                    default:
                        TabList = SmallCompanyTabs(TapsProps);
                        trii = 8;
                }
            }

        }

        if (WebsiteType === "ContractorCheckIn") {

            if (UserData.CustomerType === "Contractor") {
                switch (Category) {
                    case "Company":
                        TabList = OwnerTabs(TapsProps);
                        dispatch({ type: 'CONTRACTOR_DATA', payload: NewData });

                        //put in contractor logo
                        //if (NewData.avatar && NewData.avatar !== "") {
                        //    let NewHQData = HQData;
                        //    NewHQData.avatar = NewData.avatar;
                        //    dispatch({ type: 'HQ_DATA', payload: NewHQData });
                        //}

                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 9;
                        break;
                    case "Person":
                        TabList = PersonTabs(TapsProps);
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 10;
                        break;
                    case "Assets":
                        TabList = AssetTabs(TapsProps);
                        trii = 11;
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        break;
                    default:
                        TabList = OwnerTabs(TapsProps);
                        trii = 12;
                }
            }

            if (UserData.CustomerType === "Company") {
                switch (Category) {
                    case "Company":
                        TabList = CompanyTabs(TapsProps);
                        dispatch({ type: 'CURRENT_COMPANY', payload: NewData });
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 13;
                        break;
                    case "Person":
                        TabList = PersonTabs(TapsProps);
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 14;
                        break;
                    case "Assets":
                        TabList = AssetTabs(TapsProps);
                        BreadCrum1 = ContractorData.field1;
                        Linkdata1 = ContractorData;
                        BreadCrum2 = "";
                        Linkdata2 = "";
                        trii = 15;
                        break;
                    default:
                        TabList = CompanyTabs(TapsProps);
                        trii = 16;
                }
            }

        }

        console.log('CompanyPage.CompanyId.end', trii, Category, UserData.CustomerType, CompanyId);
        console.log('CompanyPage.TabList.NewData', NewData);
        console.log('CompanyPage.TabList.TapsProps', TapsProps);
        console.log('CompanyPage.TabList.tabs', TabList);
        console.log('CompanyPage.TabList.props', props);

        if (UserData.CustomerType !== "Contractor") {
            BreadCrum1 = "";
            BreadCrum2 = "";
        }

        setState(prevState => ({
            ...prevState,
            TabList: TabList,
            Category: Category,
            CompanyId: CompanyId,
            CustomerType: CustomerType,
            CompanyName: CompanyName,
            field1: field1,
            BreadCrum1: BreadCrum1,
            BreadCrum2: BreadCrum2,
            sk1: sk1,
            Refresh: Refresh,
            Linkdata1: Linkdata1,
            Linkdata2: Linkdata2
        }));

    }

    const DetailLink = (data) => {
        let Path = `/app/company/${data.CompanyId}`;
        navigate(Path, { state: data });
    }


    return (
        <Page
            //  className={classes.root}
            title="Account"
        >
            <Grid container spacing={1} >

                <Grid item xs={12}>
                    <Typography variant="h2">
                        {state.field1}
                    </Typography>
                </Grid>

                {state.BreadCrum1 !== "" && (
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* 
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => navigate(-1)}
                            >
                                Go Back
                            </Link>
                            */}
                            {state.BreadCrum1 && (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    onClick={() => DetailLink(state.Linkdata1)}
                                >
                                    {state.BreadCrum1}
                                </Link>
                            )}
                            {state.BreadCrum2 && (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    onClick={() => DetailLink(state.Linkdata2)}
                                >
                                    {state.BreadCrum2}
                                </Link>
                            )}
                        </Breadcrumbs>
                    </Grid>
                )}

                <Grid item xs={12} >

                    <Box mt={3}>
                        {state.TabList && (
                            <Tabs
                                TabList={state.TabList}
                                MenuHeader={state.field1}
                                key={state.Refresh + "key"}
                                id={state.Refresh + "id"}
                            />
                        )}
                    </Box>

                </Grid>
            </Grid>
        </Page>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
