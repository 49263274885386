/*
 * 
 * List all the progress steps in a card
 * add button to edit current list
 * 
 * 
 */

import React, { useState, useEffect } from 'react';
//import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import {
    TextField,
    Collapse,
    Paper, Grid, Button,
    Backdrop,
    CircularProgress,
    Card, FormGroup,
    CardContent,
    CardActions,
    InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
    RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    Switch, Tooltip, Divider
} from '@mui/material';
import {
    removeEmptyStringElements,
    NewPageId,
    EleminateDuplicateObjects,
    getAutoCompletedata,
    Pk1SingelItem
} from '../utils/CommonGraphql';
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import ProgressStepper from '../Setup/ProgressStepper'
import {
    SMSQuestions,
    CheckOutQuestions, CheckInQuestions,
    OrderQuestions, PartsQuestions
} from '../utils/UrgencyOptions';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';


//const UUid = NewPageId("SMSNew");

export default function SimpleCard(props) {
    // const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const NowEditing = useSelector(state => state.NowEditing)
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        UseCustomSMS: true,
        SMSQuestions: [],
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("SMSListQuestions.props", props);

        let SMSQuestionsNew = [];

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            let StepValueArray2 = props.StepValueArray.find(x => x.KeyId === props.KeyId);

            console.log("SMSListQuestions.StepValueArray2", StepValueArray2);

            if (StepValueArray2) {
                SMSQuestionsNew = StepValueArray2.SMSQuestions;
            } else {

                switch (props.KeyId) {
                    case "CheckInQuestions":
                        SMSQuestionsNew = InsertKeys(CheckInQuestions, NewPageId("CheckInQuestions"));
                        break;
                    case "CheckOutQuestions":
                        SMSQuestionsNew = InsertKeys(CheckOutQuestions, NewPageId("CheckOutQuestions")); 
                        break;
                    case "SMSQuestions":
                        SMSQuestionsNew = InsertKeys(SMSQuestions, NewPageId("newww"));
                        break;
                    case "PartsQuestions":
                        SMSQuestionsNew = InsertKeys(PartsQuestions, NewPageId("newww"));
                        break;
                    case "OrderQuestions":
                        SMSQuestionsNew = InsertKeys(OrderQuestions, NewPageId("newww"));
                        break;
                    default:
                        SMSQuestionsNew = InsertKeys(SMSQuestions, NewPageId("neww")); 

                }
                
            }
            
            setState(prevState => ({
                ...prevState,
                ...StepValueArray2,
                SMSQuestions: SMSQuestionsNew
            }));

        }

        //if (NowEditing === "Company") {
        //    GetDatafromDB(props.CompanyId);
        //}


    }, [props.KeyId])

    const InsertKeys = (list, Idd) => {
        let NewList = [];
        list.map((each, indd) => {
            NewList.push({
                ...each,
                key: each.key + Idd + indd
            })
        })
        return list;
    }


    const GetDatafromDB = async (cid) => {
        SetLoading(true);
        let ContactList = [];
        let label = "";
        let NewSMSQuestions = [];

        let NewData2 = await Pk1SingelItem(cid, `Data::Online::Lambda::PreSelect::Company`);
        let NewData = await NewData2;

        if (NewData && NewData.dataJSON) {
            let ContactList2 = JSON.parse(NewData.dataJSON);
            ContactList = ContactList2.ContactList;

            let Combinedarrr = [...state.SMSQuestions, ...ContactList];
            let EleminateDuplicateNewSMSQuestions = EleminateDuplicateObjects(Combinedarrr, "key");

            console.log("ContactList.EleminateDuplicateNewSMSQuestions", EleminateDuplicateNewSMSQuestions);

            EleminateDuplicateNewSMSQuestions.map((each, indd) => {
                NewSMSQuestions.push({
                    ...each,
                    pk1: cid,
                    BaseInfo: [],
                    ExtraInfo: [],
                    include: false,
                    VariableInput: false,
                    keywords: {
                        words: "",
                        ESC: []
                    }
                })
                label = "";
            })
        }

        console.log("ContactList.NewData", NewSMSQuestions);
        setState(prevState => ({ ...prevState, SMSQuestions: NewSMSQuestions }))

        SetLoading(false);

    }

    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("SMSListQuestions.key", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    //useEffect(() => {
    //  console.log("SMSListQuestions.props", props)
    //  setState(prevState => ({
    //    ...prevState,
    //    ...initialState
    //  }))

    //}, [props.NewId])

    //const handleExpandClick = () => {
    //  setExpanded(!expanded);
    //};


    const CloseDrawer = () => {

        //let SelectedDataReturn = {
        //  //UseCustomSMS: state.UseCustomSMS,
        //  //SMSQuestions: state.SMSQuestions,
        //  ...state,
        //  id: props.id,
        //  KeyId: props.KeyId
        //}

        //if (props.SelectedDataReturn) {
        //  props.SelectedDataReturn(SelectedDataReturn)
        //}

        SetDeawerOpen(false)
    }



    const AddItem = () => {

        let newitem = {
            key: NewPageId("SMSNew"),
            Category: "",
            label: "",
            field1: "",
            include: false,
            keywords: {
                words: "",
                ESC: []
            }
        }

        //let SMSQuestions = [...state.SMSQuestions, newitem];

        setState(prevState => ({
            ...prevState,
            // SMSQuestions: SMSQuestions,
            DetailsProps: newitem
        }));

        SetDeawerOpen(true)

    }

    const AdditemDone = () => {
        setState(prevState => ({
            ...prevState,
            TriggerAdd: false,
        }))
    }


    const KeyArray = (SMSQuestions, cat) => {
        console.log("SMSListQuestions.KeyArray", SMSQuestions, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: SMSQuestions
        }));

        //  NewValues(state.UseCustomSMS, SMSQuestions)

    }

    const handleChange = (event, name) => {
        let UseCustomSMS = event.target.checked
        setState({
            ...state,
            [name]: UseCustomSMS,
        });

        // NewValues(UseCustomSMS, state.SMSQuestions)
    };

    const AddBox = (add) => {

        setState(prevState => ({
            ...prevState,
            DetailsProps: add,
        }));

        SetDeawerOpen(true)

        //add.LoadComponenet = "UrgencyDetails";
        //props.AddBox(add);

    }

    const handleInclude = (data) => {
        console.log("SMSListQuestions.1.handleInclude", data)

        let SMSQuestions = state.SMSQuestions;
        let findindex = SMSQuestions.findIndex(x => x.key === data.key);

        console.log("SMSListQuestions.1.findindex", findindex)

        if (findindex > -1) {
            SMSQuestions[findindex] = data;
        } else {
            // if key does not exist, then add as new
            console.log("SMSListQuestions.1.handleInclude.New", data)
            SMSQuestions = [...state.SMSQuestions, data];
        }

        // NewValues(state.UseCustomSMS, SMSQuestions)

        setState(prevState => ({
            ...prevState,
            SMSQuestions: SMSQuestions
        }));
    }


    console.log("SMSListQuestions.1.state", state);



    return (
        <div
        //className={classes.paper} id={props.key} key={"key" + props.key}
        >

            <Grid container spacing={2}>
                {/*
                <Grid item xs={12}>

                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                onClick={(event) => handleChange(event, "UseCustomSMS")}
                                value={state.UseCustomSMS || ""} />}
                            label="Use following Custom SMS Questions" />
                    </FormGroup>

                </Grid>
*/}
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => AddItem()}
                    >
                        Add New Question
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Typography>
                    Please select checkbox of the questions that will be asked. Use the arrows to change the order of questions.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    {/*  
          <Card className={classes.root}>

            <CardHeader
              title={props.field1}
              subheader={props.field2}
              action={
                    
              }
            />

            <CardContent>
           
          <ProgressStepper
            Data={ProgressQuestions}
            title="Progress Steps"
            ShowUpDown={false}
            //TriggerAdd={state.TriggerAdd}
            //AdditemDone={() => AdditemDone()}
            ShowEdit={false}
            //AddBox={(e) => props.AddBox(e)}
            //DefaultChecked={props.DefaultChecked || []}
            //NextLoadComponenet={props.NextLoadComponenet}
            Category="Progress"
            SelectedDataReturn={(e) => KeyArray(e, "ProgressList")}
            PerPage={5}
          />
           

          <ListCheckboxMoveUpDown
            Data={SMSQuestions}
            title="Progress Steps"
            ShowUpDown={true}
            ShowEdit={true}
            AddBox={(e) => AddBox(e)}
            DefaultChecked={state.DefaultChecked || []}
            NextLoadComponenet="AddSetupSelection"
            Category="Progress"
            KeyArray={(e) => KeyArray(e, "ProgressList")}
          />
          */}

                    <ListCheckboxMoveUpDown
                        Data={state.SMSQuestions}
                        key={"key2" + props.id}
                        title="Service Actions"
                        SearchWords=""
                        ShowUpDown={true}
                        ShowEdit={true}
                        AddBox={(e) => AddBox(e)}
                        // DefaultChecked={state.SMSQuestions || []}
                        NextLoadComponenet="AddSetupSelection"
                        Category="SMS"
                        FullJSONBack={(e) => KeyArray(e, "SMSQuestions")}
                    />

                    {/*  
            </CardContent>

          </Card>

          */}
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>


            </Grid>

            <Backdrop
                // className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <DrawerShow
                LoadComponenet="SMSAddEdit"
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle="SMS Question Add/Edit"
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => CloseDrawer(e),
                    step: state.DetailsProps,
                    handleInclude: (e) => handleInclude(e),
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </div>
    );
}
