import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Grid,
  Card,
  CardContent,
  InputLabel, Select, FormControl, MenuItem, RadioGroup, Typography,
  Snackbar, Button, FormLabel, Radio, CardHeader, Box,
  Switch, FormControlLabel, Checkbox
} from '@mui/material';
import AddSelectFields from './AddFormFieldClass';
import { useSelector } from 'react-redux'
import DynamicForms from '../Forms/FormShow';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
import { FormSchema, FormGroups } from '../Forms/FormList';

const ShortId = NewPageId("FORM");

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  field: {
    margin: '30px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
}));



const model = [
  { key: "name", label: "Person Approving", requiredvalue: true, fieldmessage: 'be happy', props: { required: true } },
  { key: "email", label: "Email", props: { required: true } },
  { key: "position", label: "Person Position" },
  {
    key: "payment",
    label: "Payment Method",
    type: "select",

    defaultValue: ["NewPO"],
    options: [
      { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
      { key: "NewPO", label: "New PO", value: "NewPO" },
      { key: "warranty", label: "Warranty", value: "warranty" },
      { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
    ]
  },
  { key: "paymentnote", type: "textbox", label: "Note/details" }
];

const DataSourceOption = (props) => {
  return (<>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl variant="outlined" style={{ width: '100%' }} >
          <FormLabel component="legend">Option Data Source</FormLabel>
          <RadioGroup
            aria-label="OptionDataSource"
            name="Option Data Source"
            defaultValue="options"
            required={true}
            onChange={(e) => {
              props.SetDataSource(e.target.value)
            }}
          >
            <FormControlLabel value="options" control={<Radio />} label="Custom Options" />
            <FormControlLabel value="CompanyPersonal" control={<Radio />} label="Customer Personal" />
            <FormControlLabel value="ContractorPersonal" control={<Radio />} label="Contractor Personal" />
            <FormControlLabel value="Equipment" control={<Radio />} label="Equipment" />
            <FormControlLabel value="CompanyLocations" control={<Radio />} label="Customer Locations" />
          </RadioGroup>
        </FormControl >
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => props.handleChange()}>
          Select Data Source</Button>
      </Grid>
    </Grid>
  </>)
}



const SaveFieldOptions = (props) => {
  return (<>
    <Grid item sm={6} xs={12} >
      <FormControlLabel
        control={<Checkbox checked={props.RequiredField} onChange={(e) => props.HandleRequired(e.target.checked, "RequiredField")} name="Required" />}
        label="Required"
      />
    </Grid>
    <Grid item sm={6} xs={12} >
      <FormControlLabel
        control={<Checkbox checked={props.TableColumn} onChange={(e) => props.HandleRequired(e.target.checked, "TableColumn")} name="Table" />}
        label="Table Column"
      />
    </Grid>
    <Grid item sm={6} xs={12} >
      <FormControlLabel
        control={<Checkbox defaultChecked checked={props.Editable} onChange={(e) => props.HandleRequired(e.target.checked, "Editable")} name="Editable" />}
        label="Editable"
      />
    </Grid>
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      spacing={2}
    >
      <Grid >
        <Button variant="contained" color="primary" onClick={() => props.SaveFormField()}>
          Save Field</Button>
      </Grid>
      <Grid  >
        <Button variant="contained" color="primary"
          onClick={() => props.PutState({ ShowFieldSelection: true, ShowFieldName: false })}>
          Change</Button>
      </Grid>
    </Grid>

  </>)
};


var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();

//var d = new Date();
var year = currentUnixTime.getFullYear();
var month = currentUnixTime.getMonth();
var day = currentUnixTime.getDate();
let DateToday = `${month}-${day}-${year}`;


const FormCreator = props => {
  const classes = useStyles();
  const ContractorData = useSelector(state => state.ContractorData);
  const ContractorId = ContractorData.ContractorId;
  const initialState = {
    FormName: '',
    Description: '',
    FieldName: '',
    FieldType: '',
    ShowTitleError: false,
    //classes: useStyles(),
    InitialDom: 1,
    FormAddOptions: '',
    FormOptionSelect: false,
    SelectFlag: false,
    OptionDataSourse: "options",
    Editable: false,
    SelectOptions: [],
    RequiredField: false,
    AlarmMessage: "",
    TotalForm: [],
    ShowFieldSelection: true,
    UserData: "",
    ContractorData: "",
    FormType: "",
    FormTypeArrays: [],
    AddFormType: false,
    TypeValue: "Type",
    options: "",
    FormData: [],
    edit: false,
    Order: 0,
    OrderChange: 1,
    AutoComplete: false,
    TableColumn: false,
    ExistingId: "",
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    console.log("props", props)

    let ExistingId = "";
    let FormData = [];
    let FormType = props.Category;
    let FormName = props.Category;

    if (props.FormType && props.FormType !== "") {
      FormType = props.FormType;
      FormName = props.FormType;
    }


    // get id of existing item for Copy
    if (props.ExistingId && props.ExistingId !== "") {
      ExistingId = props.ExistingId;
    }

    console.log("FormDataEmpty1", props.FormData)

    if (props.FormData && props.FormData.length > 0) {
      FormData = props.FormData;
    } else {
      if (FormType && FormType !== "") {
        FormData = FormSchema.filter(x => x.Category === FormType);
      } else {
        FormData = [];
      }
    }

    setState(prevState => ({
      ...prevState,
      ExistingId: ExistingId,
      FormName: `${FormName} Form - ${DateToday}`,
      TotalForm: FormData,
      FormType: FormType,
      ContractorData: props.ContractorData,
    }))

  }, [props])

  const SaveForm = (copy) => {

    let AlarmMessage = '';
    //toast.dismiss();
    if (state.FormName === "") {
      AlarmMessage += '* Please enter Form Title ';
    } else {
      setState(prevState => ({
        ...prevState,
        ShowTitleError: false
      }));
    }
    if (state.TotalForm.length < 1) {
      AlarmMessage += '* Please enter Form Fields ';
    }
    //if (state.FormType == "Type") {
    //  AlarmMessage += '* Please select Form Type ';

    //}

    if (AlarmMessage !== '') {
      setState(prevState => ({
        ...prevState,
        open: true,
        AlartMessage: AlarmMessage,
        ShowTitleError: true
      }));
      return
    }


    //stablish key
    let NewTeam = `Data::Online::Form::${state.FormType}#` + ShortId;
    let InputArray = [];

    if (copy === true && state.ExistingId !== "") {
      NewTeam = state.ExistingId;
    }

    // update record if key exist
    if (props.itemkey && props.itemkey !== "") {
      NewTeam = props.itemkey;
    }

    try {

      const FormdataJSON =
      {
        field1: state.FormName,
        field2: state.FormType,
        ItemId: ShortId,
        key: NewTeam,
        date: currentTime,
        SearchWords: `${state.FormType} | ${state.FormName}`,
        // AutoComplete: state.AutoComplete,
        //  FindData: `History::Items::${state.FormType}`,
        posterId: state.UserData, // change per new redux
        FormType: state.FormType,
        FormData: state.TotalForm,
        //CompanyName: props.cid.CompanyName,
        //CompanyId: props.cid.CompanyId,
        ContractorId: ContractorId,
        pk1: ContractorId,
        sk1: NewTeam,
        //gsi1Pk2: props.cid.CompanyId,
        //gsi1Sk2: state.FormType,
        gsi2Pk2: ContractorId,
        gsi2Sk2: NewTeam,
      }

      const inputraw = {
        pk1: ContractorId,
        sk1: NewTeam,
        //gsi1Pk2: props.cid.CompanyId,
        //gsi1Sk2: state.FormType,
        gsi2Pk2: ContractorId,
        gsi2Sk2: NewTeam,
        dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
      }

      InputArray.push(removeEmptyStringElements(inputraw));

      const FormUpdated = {
        pk1: ContractorId,
        sk1: "Data::Online::Pref::Update",
        gsi1Pk2: ContractorId,
        gsi1Sk2: currentTime,
        gsi2Pk2: ContractorId,
        gsi2Sk2: "Data::Online::Pref::Update",
      }

      InputArray.push(removeEmptyStringElements(FormUpdated));

      console.log('input', InputArray)
      console.log('FormdataJSON', FormdataJSON)

      if (InputArray.length > 0) {
        API.graphql(graphqlOperation(createBatch, { input: InputArray }))
          .then((results) => {
            console.log('createBatch Data ', results);
            if (props.CloseDrawer) {
              props.CloseDrawer(FormdataJSON);
            }
          }).catch(error => console.log(error.message));
      }

    } catch (err) {
      console.error('error from api', err)
    }

  }

  const SaveFormField = (DataSourse = "options") => {

    let TotalFormAdd = state.TotalForm;
    const index = state.TotalForm.findIndex(item => state.EditKey === item.key);
    if (state.FieldName !== "") {
      let VariableName = state.FieldName.replace(/[^a-zA-Z0-9_]/g, "");
      if (index > -1) {

        TotalFormAdd[index].label = state.FieldName;
        TotalFormAdd[index].type = state.FieldType;
        TotalFormAdd[index].required = state.RequiredField;
        TotalFormAdd[index].options = state.SelectOptions;
        TotalFormAdd[index].Order = state.OrderChange;
        TotalFormAdd[index].TableColumn = state.TableColumn;
        TotalFormAdd[index].Editable = state.Editable;
        TotalFormAdd[index].VariableName = VariableName;
        TotalFormAdd[index].Category = state.FormType;
        //setState(prevState => ({ ...prevState,  TotalForm: TotalFormAdd

      } else {
        let Orderchange = state.Order + 1;
        setState(prevState => ({ ...prevState, Order: Orderchange }))
        let AddingField = {
          key: ShortId + Orderchange,
          label: state.FieldName,
          type: state.FieldType,
          required: state.RequiredField,
          TableColumn: state.TableColumn,
          Editable: state.Editable,
          options: state.SelectOptions,
          Order: Orderchange,
          DataSourse: state.OptionDataSourse,
          VariableName: VariableName,
          Category: state.FormType,
          //defaultValue: ["NewPO"],
        }
        TotalFormAdd.push(AddingField);
      }

      setState(prevState => ({
        ...prevState,
        TotalForm: TotalFormAdd,
        FieldName: "",
        FieldType: "",
        ShowFieldSelection: true,
        ShowFieldName: false,
        Editable: false,
        EditKey: "",
        SelectOptions: [],
        RequiredField: false,
        ShowFieldError: false,
        TableColumn: false
      }))
      //handleChange("Type")
      // setState(prevState => ({ ...prevState, ShowFieldSelection: true, ShowFieldName: false, ShowFieldError: false }))
      //});
    } else {
      setState(prevState => ({
        ...prevState,
        open: true,
        AlartMessage: "Please enter Field Name",
        ShowFieldError: true
      }));
      return
    }

    console.log("save", TotalFormAdd);
  }

  const AddingLine = (e) => {
    setState(prevState => ({ ...prevState, SelectOptions: e }));
  }


  const handleChange = (options = [], edit = false) => {
    console.log("handleChange", state.OptionDataSourse)
    let SaveButtons = true;
    let fields = "";
    if (state.OptionDataSourse === "options") {
      fields = (
        <AddSelectFields
          key="skljdfs"
          AddLineOpt={(e) => AddingLine(e)}
          Options={options}
          Edit={edit}
          SaveFormField={(e) => SaveFormField(e)}
          ShortId={ShortId}
        />)
      setState(prevState => ({ ...prevState, FormAddOptions: fields }));
    } else {
      fields = "";
      //SaveButtons = true;
    }
    setState(prevState => ({ ...prevState, FormAddOptions: fields, SaveButtons: SaveButtons }));
  }

  const SetDataSource = (e) => {
    setState(prevState => ({ ...prevState, OptionDataSourse: e }))

  }

  const HandleOptionsSelect = (fieldtype, options = [], edit = false) => {
    //console.log("HandleOptionsSelect", fieldtype)
    let fields = "";
    let SaveButtons = false;
    //console.log("state.OptionDataSourse", state.OptionDataSourse)
    setState(prevState => ({ ...prevState, FieldType: fieldtype, ShowFieldSelection: false, ShowFieldName: true }));
    if (fieldtype === "radio" || fieldtype === "select") {
      fields = (<DataSourceOption
        SetDataSource={SetDataSource}
        handleChange={() => handleChange(options, edit)} />);
      //SaveButtons = true;
    } else if (fieldtype === "Type") {
      setState(prevState => ({ ...prevState, ShowFieldSelection: true, ShowFieldName: false }))
    } else {
      fields = "";
      SaveButtons = true;
    }
    setState(prevState => ({ ...prevState, FormAddOptions: fields, SaveButtons: SaveButtons, TypeValue: fieldtype }))

  }

  const HandleRequired = (e, f) => {
    console.log("HandleRequired", e, f)
    setState(prevState => ({ ...prevState, [f]: e }));
  }

  const EditItem = (e) => {
    let edit = false;
    const index = state.TotalForm[e];
    //.find(item => e === item.key);
    console.log("edit", e, state.TotalForm)
    setState(prevState => ({
      ...prevState,
      FieldName: index.label,
      EditKey: index.key,
      OrderChange: index.Order,
      ShowFieldName: true,
      ShowFieldSelection: false
    }))
    if (index.options.length > 0) {
      //setState(prevState => ({ ...prevState,  options: index.options, edit: true })
      edit = true;
    } else {
      //setState(prevState => ({ ...prevState,  options: "", edit: false })
      edit = false;
    }
    HandleOptionsSelect(index.type, index.options, edit)
  }

  const DeleteItem = (e) => {
    let TradeServicestemparr = state.TotalForm;
    const index = TradeServicestemparr.findIndex(item => e === item.key);
    TradeServicestemparr.splice(index, 1);
    console.log('kkey', index, e, TradeServicestemparr)
    setState(prevState => ({
      ...prevState,
      TotalForm: TradeServicestemparr,
      InitialDom: state.InitialDom + 1
    }));
    HandleOptionsSelect("Type");

  }

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false
    }))
  }

  const SetNewValue = (name, e) => {
    setState(prevState => ({ ...prevState, [name]: e }))
  }

  const SetAllValue = (e) => {
    setState(prevState => ({ ...prevState, ...e }))
  }


  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={2} justifyContent="center">

        <Grid item sm={12} xs={12} >
          <Card
            className={clsx(classes.root)}
          >
            <CardHeader
              title="Form Edit/Create"
            />
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item sm={12} xs={12} >
                  <TextField
                    required={true}
                    id="formtitle"
                    error={state.ShowTitleError}
                    defaultValue={props.FormTitle}
                    label="Form Name"
                    name="FormName"
                    value={state.FormName}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => SetNewValue("FormName", e.target.value)}
                  />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12} >
          <Card className={clsx(classes.root)} >

            <CardHeader
              title="Add Form Inputs"
            />

            <CardContent>
              <Grid container alignItems="center" spacing={2}>

                {state.ShowFieldName && (
                  <>
                    <Grid item sm={12} xs={12} >
                      <TextField
                        required={true}
                        error={state.ShowFieldError}
                        id="FieldName"
                        label="Field Name"
                        name="FieldName"
                        value={state.FieldName}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetNewValue("FieldName", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} >
                      {state.FormAddOptions}
                      {state.SaveButtons && (
                        <SaveFieldOptions
                          RequiredField={state.RequiredField}
                          TableColumn={state.TableColumn}
                          HandleRequired={HandleRequired}
                          SaveFormField={SaveFormField}
                          PutState={(e) => SetAllValue(e)}
                        />
                      )}
                    </Grid>
                  </>
                )}

                {state.ShowFieldSelection && (
                  <Grid item sm={12} xs={12} >
                    <FormControl variant="outlined" style={{ width: '100%' }} >
                      <InputLabel id="demo-simple-select-outlined-label3">Select Field Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-labelreed"
                        id="selectfieldtypes123"
                        defaultValue={state.TypeValue}
                        required={true}
                        autoWidth={true}
                        value={state.TypeValue}
                        onChange={e => HandleOptionsSelect(e.target.value)}
                        label="Select Field Type"
                      >
                        <MenuItem value="Type">Select Input Type..</MenuItem>
                        <MenuItem value="textline">One line Input</MenuItem>
                        <MenuItem value="name">Name / Tag / lable / Company name</MenuItem>
                        <MenuItem value="textbox">Paragraph</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="password">Password</MenuItem>
                        <MenuItem value="phone">Phone / Land Line</MenuItem>
                        <MenuItem value="mobile">Mobile / SMS</MenuItem>
                        <MenuItem value="radio">Multiple Choices</MenuItem>
                        <MenuItem value="checkbox">Check Box</MenuItem>
                        <MenuItem value="select">Dropdown</MenuItem>
                        <MenuItem value="switch">Switch</MenuItem>
                        <MenuItem value="GoogleAddressAutoComplete">Google AutoComplete Address </MenuItem>
                        <MenuItem value="country">Country</MenuItem>
                        <MenuItem value="region">State/Region</MenuItem>
                        <MenuItem value="time">Time Selector</MenuItem>
                        <MenuItem value="datepicker">Date Selector</MenuItem>
                      </Select>
                    </FormControl >
                  </Grid>
                )}

              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12} >
          {state.TotalForm.length > 0 && (
            <DynamicForms
              key="1sslkfd"
              title={`Preview ${state.FormName}`}
              ShowEditButton={false}
              UpdateFormData={e => SetNewValue("TotalForm", e)}
              PreviewEdit={true}
              EditItem={e => EditItem(e)}
              FormData={state.TotalForm}
            />
          )}
        </Grid>

        {state.TotalForm.length > 0 && (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item >
                {state.ExistingId !== "" && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => SaveForm(true)}
                  >
                    Update Existing
                  </Button>
                )}
              </Grid>
              <Grid item >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => SaveForm(false)}
                >
                  Save as New
                  </Button>
              </Grid>
            </Grid>
          </Grid>)}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={state.open}
        onClose={handleClose}
        message={state.AlartMessage}
        key="w87482ows"
      />
    </div>
  )
}



export default FormCreator;
