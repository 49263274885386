import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { useSelector } from 'react-redux';
//import moment from 'moment';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Button,
    TextField,
    Typography,
    Grid, Backdrop,
    CircularProgress,
    CardHeader,
    Card, CardContent
} from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import {
    NewPageId,
    removeEmptyStringElements,
    getAutoCompletedata,
    UpdateData,
    PrimaryKeyDataGet
} from '../utils/CommonGraphql';
import AutoComplateBox from '../components/AutoComplete';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { useDispatch } from 'react-redux';
import Page from '../components/Page';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CloudUpload from '@mui/icons-material/CloudUpload';
//import { currentTime } from '../utils/DateTime';
import UploadMain from '../Upload/UploadMain';
import { SendEmail } from '../utils/SendEmail';
import { Buffer } from 'buffer';
import ListModule from '../List/ListModule';
import { DateTime } from "luxon";

const NewId = NewPageId("AM")

const Profile = ({ className, ...rest }) => {
    // const classes = useStyles();
    //const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);    
    const [loading, SetLoading] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        Message: "",
        MessageNo: "",
        Personal: [],
        PrivateMessage: false,
        CurrentCompany: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        NewId: NewId,
        PhotoService: [],
        Documents: [],
        ImageStateSet: [],
        OptoutList: []
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {
                
        var dateToday = new Date();
        let currentTime = dateToday.toISOString();
        let UTCTime = DateTime.utc().toString();

        setState(prevState => ({
            ...prevState,
            dateToday: dateToday,
            currentTime: currentTime,
            UTCTime: UTCTime
        }));

    }, [])
           
    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const TriggerLambda = (event, from) => {
        console.log("AddMessage.TriggerLambda", from, event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    const ProcessMessage = () => {

        let DocFlag = false;
        let SREvent = rest.SREvent;

        if (state.PhotoService.length > 0) {
            let PhotoAdd = [...SREvent.PhotoService, ...state.PhotoService];
            SREvent.PhotoService = PhotoAdd;
            DocFlag = true;
        }

        if (state.Documents.length > 0) {
            let DocumentsAdd = [...SREvent.Documents, ...state.Documents];
            SREvent.Documents = DocumentsAdd;
            DocFlag = true;
        }               
        
        if (DocFlag) {

            UpdateDocuments(SREvent);
            UpdateSREvent(SREvent);
            console.log("AddMessage.SREvent", SREvent)

            //update checkin
            //let PosttoDB = {
            //    MutationType: "updateSvcbotDb",
            //    DBData: SREvent
            //};

            //// Post to DB
            //TriggerLambda(PosttoDB, "UpdateDatabase");
        }
               
    }

    const UpdateSREvent = (SREvent) => {

            var ValueToUpdate = {
                pk1: SREvent.ServiceRequest.pk1,
                sk1: SREvent.ServiceRequest.sk1,
                gsi1Pk2: SREvent.gsi1Pk2,
                gsi2Pk2: SREvent.gsi2Pk2,
                dataJSON: JSON.stringify(removeEmptyStringElements(SREvent)),
            };

            //console.log("SRDetails.1.pksk", ValueToUpdate)            

            UpdateData(ValueToUpdate, "AddDocument")
                .then((up) => {
                    console.log("SRDetails.Update.results", up)
                    // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                })
                .catch((err) => console.log("SRDetails.failed", err));
        
    }

    const UpdateDocuments = (event) => {

        try {

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            const DocData = {
                pk1: event.ContractorData.ContractorId,					//doc per owner
                sk1: `Doc:: Reports::${event.ServiceRequest.sk1}::${event.PostId}`,
                gsi1Pk2: `Doc::${event.posterId.CompanyId}`,				//get all doc per sub
                gsi1Sk2: state.UTCTime,
                gsi2Pk2: `Doc::${event.ContractorData.CompanyId}`,			//get all doc per owner
                gsi2Sk2: state.UTCTime,
            };

            let NewFormJSON = {
                // posterId: event.posterId,
                post: event.postTEXT,
                title: event.title,
                field1: `Pic/Doc`,
                field2: event.title,
                PhotoService: state.PhotoService,
                Documents: state.Documents,
                date: state.currentTime,
                PostId: event.PostId,
                currentTime: state.currentTime,
                Category: "Documents",
                posterId: posterId,
                ...DocData
            };

            let DBInput = {
                ...DocData,
                dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
            }

            console.log("AddMessage.NewFormJSON", NewFormJSON)

            //let PosttoDB = {
            //    MutationType: "createSvcbotDb",
            //    DBData: DBInput
            //};

            //// Post to DB
            //TriggerLambda(PosttoDB, "createSvcbotDb");


            //if (rest.CloseDrawer) {
            //    rest.CloseDrawer();
            //}

            API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput })).then(
                (results) => {
                    console.log("createBatch Data ", results);
                    if (rest.CloseDrawer) {
                        rest.CloseDrawer();
                    }
                }
            );


        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    console.log("AddMessage.props", rest)
    console.log("AddMessage.state", state)

    return (
        <Page >
            <Grid container spacing={1}>
                <Grid xs={12} item>

                    <Typography variant="h4">
                        {rest.DrawerTitle}
                    </Typography>
                </Grid>
                
                <Grid item xs={12}>
                    <UploadMain
                        id="PhotoService"
                        key="PhotoService"
                        title="Upload Images, PDF, or Zip"
                        ImageList={(e) => SaveImage("PhotoService", e)}
                        DocumentList={(e) => SaveImage("Documents", e)}
                        coid={ContractorData.ContractorId}
                        ImageStateSet={(e) => SaveImage("ImageStateSet", e)}
                        ImageStateSetBack={state.ImageStateSet}
                        fileallowed="any"
                        cat="PhotoNamePlate"
                    />
                </Grid>

                <Grid xs={12} item>
                    <Button color="primary" variant="contained" onClick={() => ProcessMessage()}>
                        Add Pic/Doc
                    </Button>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
