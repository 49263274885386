import React, { useEffect, useState } from 'react';
//import Radio from '@mui/material/Radio';
//import RadioGroup from '@mui/material/RadioGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
//import FormGroup from '@mui/material/FormGroup';
//import Switch from '@mui/material/Switch';
//import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
import {
  TextField,
  //Collapse,
  Paper,
  Grid, Button,
  Backdrop,
  CircularProgress,
  Typography,
  Stepper, Step, StepContent, StepLabel,
  Snackbar,
  FormControl, RadioGroup, FormControlLabel, Radio,

  //Card,
  //CardContent,
  //CardActions,
  //InputLabel, Select, FormControl, MenuItem, Checkbox, 
  //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  //Switch, Tooltip, Divider
} from '@mui/material';

export default function RadioButtonsGroup(props) {

  const initialState = {
    Priority: "Medium",
  }
  const [state, setState] = useState(initialState);


  //useEffect(() => {

  //  let SelectedDataReturn = {
  //    Priority: state.Priority,
  //    id: props.id
  //  }
  // // console.log("UrgencyDetails.Priority.databack", SelectedDataReturn)
  //  if (props.SelectedDataReturn) {
  //    props.SelectedDataReturn(SelectedDataReturn)
  //  }

  //}, [state.Priority])

  useEffect(() => {

    let StepValueArray = {};
    if (props.KeyId && props.StepValueArray) {
      StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
    }
    console.log("Priority.StepValueArray", StepValueArray)
    if (props && props.StepValueArray) {
      setState(prevState => ({
        ...prevState,
        ...StepValueArray,
      }));
    }

  }, [props])

  const handleNext = () => {

    let SelectedDataReturn = {
      ...state,
      id: props.id,
      KeyId: props.KeyId
    }
    console.log("SaveDataBack.Priority", SelectedDataReturn)
    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(SelectedDataReturn)
    }

    if (props.handleNext) {
      props.handleNext();
    }

  }

  const KeyArray = (data, cat) => {

    let newdata = data.target.value;

    setState(prevState => ({
      ...prevState,
      [cat]: newdata
    }));


  }

  console.log("Priority.state", state)
  console.log("Priority.props", props)

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Priority"
            // defaultValue="High"
            name="radio-buttons-group"
            //onChange={(e) => KeyArray(e, "Priority")}
            onChange={(data) => KeyArray(data, "Priority")}
            value={state.Priority || ""}
          >

            <FormControlLabel value="Emergency" control={<Radio />} label="Emergency" />

            <FormControlLabel value="High" control={<Radio />} label="High Priority" />

            <FormControlLabel value="Medium" control={<Radio />} label="Medium Priority" />

            <FormControlLabel value="Low" control={<Radio />} label="Low Priority" />

          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div>
          <div>
            <Button
              disabled={props.activeStep === 0}
              onClick={() => props.handleBack()}
             // className={classes.button}
            >
              Back
                              </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
            //  className={classes.button}
            >
              {'Next'}
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>

  );
}
