import React, { useEffect, useState } from 'react';
import {
    Grid, Button, InputLabel
} from '@mui/material';
import TimeZone from '../components/TimeZone';



export default function RadioButtonsGroup(props) {

  const initialState = {
      TimeZone: "",
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {

    let StepValueArray = {};
    if (props.KeyId && props.StepValueArray) {
      StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
      }

      console.log("TimeZone.StepValueArray", StepValueArray)

    if (props && props.StepValueArray) {
      setState(prevState => ({
        ...prevState,
        ...StepValueArray,
      }));
    }

  }, [props])

  const handleNext = () => {

    let SelectedDataReturn = {
      ...state,
      id: props.id,
      KeyId: props.KeyId
      }

      console.log("TimeZone", SelectedDataReturn)

    if (props.SelectedDataReturn) {
      props.SelectedDataReturn(SelectedDataReturn)
    }

    if (props.handleNext) {
      props.handleNext();
    }

  }

  const KeyArray = (data, cat) => {

    setState(prevState => ({
      ...prevState,
        [cat]: data,
        defaultValue: data
    }));

  }

    console.log("TimeZone.state", state)
    console.log("TimeZone.props", props)

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
              <InputLabel>Time Zone</InputLabel>
              <TimeZone
                  defaultValue={state.defaultValue}
                //  error={ErrorMessage}
                  autocomplete="off"
                //  disabled={state.disabled}
                  label="Time Zone"
                  TimeZoneValue={(e) => KeyArray(e, "TimeZone")}
              />
      </Grid>
      <Grid item xs={12}>
        <div>
          <div>
            <Button
              disabled={props.activeStep === 0}
              onClick={() => props.handleBack()}
             // className={classes.button}
            >
              Back
                              </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
            //  className={classes.button}
            >
              {'Next'}
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>

  );
}
