// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
    "userPool": "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
    "aws_cognito_region": "us-east-1",
    //"aws_user_pools_id": "us-east-1_lqVMaUwBZ",
    //"aws_user_pools_web_client_id": "2aohtp602oqt8lmtr5rep9vvkc",
    "aws_user_pools_id": "us-east-1_RG4ppYlcm",
    "aws_user_pools_web_client_id": "1g8m9h4ajcfkgtpvpqnhmj0bku",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ProcessPost-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "svcbot_db",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "svcoptengimagebucket160759-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ProcessPost",
            "endpoint": "https://fs5en0pote.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "CancelStripe",
            "endpoint": "https://bu3aszvqmmo3iw4mbt2dd63zkm0xodim.lambda-url.us-east-1.on.aws/",
            "region": "us-east-1"
        },
        {
            "name": "PostToDB",
            "endpoint": "https://4mxtezcrnqfrw3oivql3vtvryu0ihkxi.lambda-url.us-east-1.on.aws/",
            "region": "us-east-1"
        },
        {
            "name": "ZapSendData",
            "endpoint": "https://gqhgcdah4sggbc3dpupdyamhfq0qmsau.lambda-url.us-east-1.on.aws/",
            "region": "us-east-1"
        },
        {
            "name": "StartTimer",
            "endpoint": "https://23irzmin6ji5iwywafiscp4pl40ldxtn.lambda-url.us-east-1.on.aws/",
            "region": "us-east-1"
        },
        {
            "name": "SendEmail",
            "endpoint": "https://id2zuxhuaditwrfrs7kr4ds74e0zpeki.lambda-url.us-east-1.on.aws/",
          //  "endpoint": "https://l9jwrnlz60.execute-api.us-east-1.amazonaws.com/default",
            "region": "us-east-1"
        },
        {
            "name": "EmailTemplateLambda",
            "endpoint": "https://agm9o3nkee.execute-api.us-east-1.amazonaws.com",
            //"https://agm9o3nkee.execute-api.us-east-1.amazonaws.com/serverlessrepo-EmailTemplateLambda-LambdaEmailer-yW6dGkAFXWO9",
            //"https://jvirm65eg9.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }, 
        {
            "name": "PinPhone",
            "endpoint": "https://d89q29w9j9.execute-api.us-east-1.amazonaws.com/default",
            //  "endpoint": "https://eg6ddp8i58.execute-api.us-east-1.amazonaws.com/Dev/SendSMSwithTwilio",
            "region": "us-east-1"
        },
        {
            "name": "SMStoTwilio",
            "endpoint": "https://gvcb4wl5aveve7pik2kbl766ru0ycjij.lambda-url.us-east-1.on.aws/",
//                "https://4pg4tpcbf8.execute-api.us-east-1.amazonaws.com/Dev",
            //  "endpoint": "https://eg6ddp8i58.execute-api.us-east-1.amazonaws.com/Dev/SendSMSwithTwilio",
            "region": "us-east-1"
        },
        {
            "name": "TriggerESCData",
            //"endpoint": "https://hukaiqm5lnuixmxl4rblsiayv40druri.lambda-url.us-east-1.on.aws",
            "endpoint": "https://chrijsx4mb.execute-api.us-east-1.amazonaws.com",
            "region": "us-east-1"
        },
        {
            "name": "MySendEmail",
            "endpoint": "https://lk95z7pv15.execute-api.us-east-1.amazonaws.com/Prod", //"https://7oqst8cnhj.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        },
        {
            "name": "StartStepFunction",
            "endpoint": "https://m7awbrgmh5.execute-api.us-east-1.amazonaws.com/Dev",
            "region": "us-east-1"
        }, 
        {
            "name": "TwilioNumberList",
            "endpoint": "https://nrjpya2voqh4ptplhywlxeckua0zckuv.lambda-url.us-east-1.on.aws/",
            "region": "us-east-1"
        }, 
        {
            "name": "CheckPayment",
            "endpoint": "https://hzrag4qixrt3ofmebcpba5kkze0kulua.lambda-url.us-east-1.on.aws/",
            "region": "us-east-1"
        }, 
        {
            "name": "ImageResize",
            "endpoint": "https://b6ny7198cg.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "TriggerEmail",
            "endpoint": "https://40875o5yzg.execute-api.us-east-1.amazonaws.com/Dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yfxflbgfcnfb7axkgtvlimexl4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "Auth": {

        //"identityPoolId": "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
        //"region": "us-east-1",
        //"identityPoolRegion": "us-east-1",
        //"userPoolId": "us-east-1_lqVMaUwBZ",
        //"userPoolWebClientId": "2aohtp602oqt8lmtr5rep9vvkc",
        //"mandatorySignIn": false

        "identityPoolId": "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
        "region": "us-east-1",
        "identityPoolRegion": "us-east-1",
        "userPoolId": "us-east-1_RG4ppYlcm",
        "userPoolWebClientId": "1g8m9h4ajcfkgtpvpqnhmj0bku",
        "mandatorySignIn": false

    },
    "Storage": {
        "region": "us-east-1",
        "bucket": "svcoptengimagebucket160759-dev",
        "identityPoolId": "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
        "AWSS3": {
            "bucket": "svcoptengimagebucket160759-dev",
            "region": "us-east-1"
        }
    },
    "API": {
        "endpoints": [
            {
                "name": "ProcessPost",
                "endpoint": "https://fs5en0pote.execute-api.us-east-1.amazonaws.com/dev",
                "region": "us-east-1"
            },
            {
                "name": "CancelStripe",
                "endpoint": "https://bu3aszvqmmo3iw4mbt2dd63zkm0xodim.lambda-url.us-east-1.on.aws/",
                "region": "us-east-1"
            },
            {
                "name": "PostToDB",
                "endpoint": "https://4mxtezcrnqfrw3oivql3vtvryu0ihkxi.lambda-url.us-east-1.on.aws/",
                "region": "us-east-1"
            },
            {
                "name": "ZapSendData",
                "endpoint": "https://gqhgcdah4sggbc3dpupdyamhfq0qmsau.lambda-url.us-east-1.on.aws/",
                "region": "us-east-1"
            },
            {
                "name": "StartTimer",
                "endpoint": "https://23irzmin6ji5iwywafiscp4pl40ldxtn.lambda-url.us-east-1.on.aws/",
                "region": "us-east-1"
            },
            {
                "name": "SendEmail",
                "endpoint": "https://id2zuxhuaditwrfrs7kr4ds74e0zpeki.lambda-url.us-east-1.on.aws/",
                //  "endpoint": "https://l9jwrnlz60.execute-api.us-east-1.amazonaws.com/default",
                "region": "us-east-1"
            },
            {
                "name": "EmailTemplateLambda",
                "endpoint": "https://agm9o3nkee.execute-api.us-east-1.amazonaws.com",
                //"https://agm9o3nkee.execute-api.us-east-1.amazonaws.com/serverlessrepo-EmailTemplateLambda-LambdaEmailer-yW6dGkAFXWO9",
                //"https://jvirm65eg9.execute-api.us-east-1.amazonaws.com/Prod",
                "region": "us-east-1"
            },
            {
                "name": "SMStoTwilio",
                "endpoint": "https://gvcb4wl5aveve7pik2kbl766ru0ycjij.lambda-url.us-east-1.on.aws/",
//                    "https://4pg4tpcbf8.execute-api.us-east-1.amazonaws.com/Dev",
                // "endpoint": "https://eg6ddp8i58.execute-api.us-east-1.amazonaws.com/Dev/SendSMSwithTwilio",
                "region": "us-east-1"
            },
            {
                "name": "PinPhone",
                "endpoint": "https://d89q29w9j9.execute-api.us-east-1.amazonaws.com/default",
                //  "endpoint": "https://eg6ddp8i58.execute-api.us-east-1.amazonaws.com/Dev/SendSMSwithTwilio",
                "region": "us-east-1"
            },
            {
                "name": "TriggerESCData",
               // "endpoint": "https://hukaiqm5lnuixmxl4rblsiayv40druri.lambda-url.us-east-1.on.aws",
                "endpoint": "https://chrijsx4mb.execute-api.us-east-1.amazonaws.com",
                "region": "us-east-1"
            },
            {
                "name": "TwilioNumberList",
                "endpoint": "https://nrjpya2voqh4ptplhywlxeckua0zckuv.lambda-url.us-east-1.on.aws/",
                "region": "us-east-1"
            }, 
            {
                "name": "MySendEmail",
                "endpoint": "https://lk95z7pv15.execute-api.us-east-1.amazonaws.com/Prod", //"https://7oqst8cnhj.execute-api.us-east-1.amazonaws.com/Prod",
                "region": "us-east-1"
            },
            {
                "name": "StartStepFunction",
                "endpoint": "https://m7awbrgmh5.execute-api.us-east-1.amazonaws.com/Dev",
                "region": "us-east-1"
            },
            {
                "name": "ImageResize",
                "endpoint": "https://b6ny7198cg.execute-api.us-east-1.amazonaws.com/dev",
                //https://b6ny7198cg.execute-api.us-east-1.amazonaws.com/dev/send
                "region": "us-east-1"
            },
            {
                "name": "TriggerEmail",
                "endpoint": "https://40875o5yzg.execute-api.us-east-1.amazonaws.com/Dev",
                "region": "us-east-1"
            }
        ]
    }
};



export default awsmobile;
