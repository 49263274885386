import React, { useState, useEffect } from 'react';
import {
    NewPageId,
    MiddlePostSearch,
    ContreactorPostSearch
} from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import {
    Grid,
    Backdrop, Box,
    CircularProgress,
    Card, CardHeader, Button, CardContent
} from '@mui/material';
import ListItems from '../List/ListItems';
import ListModule from '../List/ListModule';
import ShowDetailsText from '../Add/ShowDetailsText';
import { adjustedTime, TimeStampEndofDay, formatDate } from '../utils/DateTime';
import DrawerShow from '../utils/DrawerModule';
import NarrowDatesToolBar from '../components/NarrowDatesToolBar';
import { useNavigate } from 'react-router-dom';
import ChartOnly from '../Charts/ChartOnly';

const UUid = NewPageId("shwdtl");
let olddate = adjustedTime(-30, "Start");
let Todaydatestring = adjustedTime(1, "End");

export default function SimpleCard(props) {
    let navigate = useNavigate();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const NowEditing = useSelector(state => state.NowEditing)
    const UserData = useSelector(state => state.UserData);
    const ContractorData = useSelector(state => state.ContractorData);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const initialState = {
        DataBack: [],
        id: UUid,
        StartDate: olddate,
        EndDate: Todaydatestring,
        BarData: [],
        BarNames: [],
        SelectSystem: "CheckIn"
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log("SRFrequency.props", props)

        GetDatafromNarrow(olddate, Todaydatestring);

        let LocalStart = formatDate(olddate);
        let LocalEnd = formatDate(Todaydatestring);

        setState(prevState => ({
            ...prevState,
            ShowDates: `${LocalStart} to ${LocalEnd}`
        }));


    }, [props.CompanyId])

    useEffect(() => {

        let SelectSystem = "CheckIn";

        if (WebsiteType === "ServiceWithText") {
            SelectSystem = "Service";
        }
        
        setState(prevState => ({
            ...prevState,
            SelectSystem: SelectSystem
        }));


    }, [])


    const GetDatafromNarrow = async (startDate, endDate) => {
        SetLoading(true);
        let NewData = [];
        let AutoSelectData = [];
        let ShowString = {};
        let Frequency = [];
        let tempmarker = "";
        let FindingSameLocation = -1;
        let BarData = [];
        let BarNames = [];

        let SelectSystem = "CheckIn";

        if (WebsiteType === "ServiceWithText") {
            SelectSystem = "Service";
        }

        ////////////////////////////
              
        if (startDate == null || startDate === "") {
            startDate = state.StartDate
        }

        if (endDate == null || endDate === "") {
            endDate = state.EndDate
        }

        let UTCdate = new Date(endDate).toISOString();
        let StartDate = new Date(startDate).toISOString();

        if (NowEditing === "Contractor") {
            NewData = await ContreactorPostSearch(`${SelectSystem}::${ContractorData.CompanyId}`, StartDate, UTCdate);
        }

        if (NowEditing === "Company") {
            NewData = await MiddlePostSearch(`${SelectSystem}::${props.CompanyId}`, StartDate, UTCdate);
        }

        console.log("SRFrequency.start", NewData);

        let each = {};
        if (NewData && NewData.length > 0) {
            NewData.map((eachService, indd) => {
               // console.log("SRFrequency.eachService", eachService);
                each = JSON.parse(eachService.dataJSON);

                if (each.posterId && each.posterId.sk1 && each.posterId.sk1 !== "") {

                    FindingSameLocation = Frequency.findIndex(x => x.sk1 === each.posterId.sk1)

                    // console.log("SRFrequency.find.each", FindingSameLocation, each.posterId.sk1, Frequency);

                    if (FindingSameLocation > -1) {
                        //add to count
                        tempmarker = Frequency[FindingSameLocation];
                        tempmarker.Count = tempmarker.Count + 1;
                        tempmarker.field2 = `Count: ${tempmarker.Count}`;
                        Frequency[FindingSameLocation] = tempmarker;

                        //   console.log("SRFrequency.tempmarker", tempmarker);

                    } else {

                        //  console.log("SRFrequency.ShowString", ShowString);
                        Frequency.push({
                            field1: `${each.posterId.field1}, ${each.posterId.CompanyName}`,
                            BarShow: `${each.posterId.field1}`,
                            sk1: each.posterId.sk1,
                            pk1: each.posterId.sk1,
                            ActiveKey: each.posterId.sk1,
                            CompanyId: each.posterId.CompanyId,
                            CompanyName: each.posterId.CompanyName,
                            CustomerType: "Contractor",
                            key: each.posterId.sk1,
                            Category: "Person",
                            field2: "Count: 1",
                            Count: 1
                        })
                    }
                }
            })
        }

        //sort list
        Frequency.sort((a, b) => (a.Count > b.Count) ? -1 : ((b.Count > a.Count) ? 1 : 0));

        //create chart
        for (var i = 0; i <= 4; i++) {
            if (Frequency[i] && Frequency[i].Count > 0) {
                BarData.push(Frequency[i].Count)
                BarNames.push(Frequency[i].BarShow)
            }
        }

        setState(prevState => ({
            ...prevState,
            DataBack: Frequency,
            BarData: BarData,
            BarNames: BarNames
        }))

        SetLoading(false);
       // console.log("SRFrequency.NewData2", Frequency);

      //  console.log("SRFrequency.NewData", NewData);
    }

    //const AddBox = (add) => {
    //    console.log("DocumentPage.AddBox", add);
    //    setState(prevState => ({
    //        ...prevState,
    //        DrawerTitle: "Service History",
    //        // SetupSubscription: true,
    //        DetailComponent: "SRDetailsPkSk",
    //        ServiceData: add,
    //        DrawerKey: NewPageId("DrawerKey"),
    //        NewCategory: "ShowDetails",
    //    }))

    //    SetDeawerOpen(true);
    //    //}
    //}



    const SortPref = (StartDate, EndDate) => {
        console.log("AssetId, startDate, endDate2", state.AssetId, StartDate, EndDate);
        if (StartDate !== state.StartDate || EndDate !== state.EndDate) {

            let NewStartDate = TimeStampEndofDay(StartDate, "Start");
            let NewEndDate = TimeStampEndofDay(EndDate, "End");

            GetDatafromNarrow(NewStartDate, NewEndDate);

            let LocalStart = formatDate(NewStartDate);
            let LocalEnd = formatDate(NewEndDate);

            setState(prevState => ({
                ...prevState,
                StartDate: NewStartDate,
                EndDate: NewEndDate,
                ShowDates: `${LocalStart} to ${LocalEnd}`
            }));
        }

    }

    const CloseDrawer = () => {
        //console.log("CloseDrawer", e)
        SetDeawerOpen(false)
    }

    //const DetailLink = item => {
    //    let Path = `/app/company/${item.CompanyId}`;
    //    navigate(Path, { state: item });
    //}

    const AddBox = (add, DetailComponent) => {
        
        let NewKey = NewPageId("list");
        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            Category: state.Category,
            key: add.key || NewKey,
            ServiceData: add,
            ShowDetails: false,
            DetailsProps: {
                title: add.field1,
                Category: state.Category,
                ShowEditButton: false,
                PreviewEdit: false,
                Data: add
            },
        }))

        SetDeawerOpen(true);
    }

    const DetailLink = (item, DetailComponent = "") => {

        console.log("SRFrequency.item", item)

        //let NewDetailComponent = "";
        //if (DetailComponent !== "") {
        //    NewDetailComponent = DetailComponent;
        //} else {
        //    NewDetailComponent = state.DetailComponent;
        //}

        // Use Datalink to change the page
        if (props.DetailLink) {
            AddBox(item, "CompanyPage");
        } else {
            let Path = `/app/company/${item.CompanyId}`;
            navigate(Path, { state: item });
        }
    }


    console.log("SRFrequency.state", state)

    return (
        <>

            <Card>
                <CardHeader
                    title={`Frequency by ${props.Category}`}
                    subheader={state.ShowDates}
                />
                <CardContent>
                    <Grid container spacing={2} >

                        {state.BarData && state.BarData.length > 0 && (
                        <Grid item xs={12}>
                            <ChartOnly
                                    BarData={state.BarData}
                                    Category="Person"
                                    BarNames={state.BarNames}
                                    title={`Frequency by ${props.Category}`}
                            />
                        </Grid>
                        )}

                        <Grid item xs={12}>
                            <NarrowDatesToolBar
                                SortPref={(StartDate, EndDate) => SortPref(StartDate, EndDate)}
                                StartDate={state.StartDate}
                                EndDate={state.EndDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ListModule
                                DataBack={state.DataBack}
                                SearchWords={props.SearchWords}
                                title="Frequency"
                                disabled={false}
                                DetailLink={(e) => DetailLink(e)}                                
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: state.Category,
                    CloseDrawer: (e) => CloseDrawer(),
                    Data: state.ServiceData,
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </>
    );
}
