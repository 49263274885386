import React, { useMemo, useState, useEffect } from "react";
//import spacetime from "spacetime";
import Box from '@mui/material/Box';
import TimezoneSelect, { allTimezones } from "react-timezone-select";

let Datestring = Intl.DateTimeFormat().resolvedOptions().timeZone;
let offset = new Date().getTimezoneOffset();
let Hours = (-1) * (offset / 60);
let NewDatestring = {
    "value": Datestring,
    "label": Datestring,
    "offset": Hours,
    "altName": Datestring
}


export default function TimeZone(props) {
    const initialState = {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    const [state, setState] = useState(initialState);
   // const [tz, setTz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
   // const [datetime, setDatetime] = useState(NewDatestring); //spacetime.now());

    useMemo(() => {
        //const tzValue = tz.value ?? tz;

        //console.log("TimeZone.tzValue", tzValue);

       // setDatetime(datetime.goto(tzValue));

        
        if (props.TimeZoneValue) {
            props.TimeZoneValue(state.tz)
        }
        
        
    }, [state.tz]);

    useEffect(() => {
        let tz = state.tz;
        if (props.defaultValue && props.defaultValue.offset) {
            tz = props.defaultValue;
        } else {
            tz = NewDatestring;
        }

        setState(prevState => ({
            ...prevState,
            tz: tz
        }));
        
    }, []);

    const Onchangevalue = data => {
        console.log("TimeZone.data", data);
       // setTz(data)
        setState(prevState => ({
            ...prevState,
            tz: data
        }));
       
    }

  //  console.log("TimeZone.datetime", datetime);
    console.log("TimeZone.props", props);
    console.log("TimeZone.tz", state.tz);

    return (
        <Box
            style={{ position: "relative", zIndex: 'tooltip' }}
        >
                <TimezoneSelect
                value={state.tz}
                onChange={data => Onchangevalue(data)}
                    timezones={{
                        ...allTimezones,
                        "America/Lima": "Pittsburgh",
                        "Europe/Berlin": "Frankfurt"
                    }}
            />
        </Box>
       
    );
}

