import React, { useState, useEffect } from 'react';
import {
    Card, CardContent,
    Button, Stepper, Step, StepContent, StepLabel,
    Backdrop, CircularProgress,
    Typography,
    TextField, Grid
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { NewPageId } from '../utils/CommonGraphql';
import PaymentSystem from '../Payment/PaypalModule4';
import SubscriptionPage from '../Payment/SubscriptionPage';
import { SendEmail } from '../utils/SendEmail';
import AddTwilioNumber from '../MainWebsite/AddTwilioNumber';
import { EnterSMS } from '../MainWebsite/CreateDBEntries';
import { useSelector } from 'react-redux';
import { API } from "aws-amplify";
import { Buffer } from 'buffer';


var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();
var TimeStamp = currentUnixTime.getTime();
let ShowDate = currentUnixTime.toLocaleString();
const UUid = NewPageId("x");
export default function SimpleCard(props) {
    const [loading, SetLoading] = useState(false);
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData)
    const [activeStep, setActiveStep] = useState(0);
    const initialState = {
        AlarmMessage: "",
        OpenAlert: false,
        PaymentMade: false,
        SelectedNumber: "",
        DoneDone: false,
        Amount: 29,
        Title: ""
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    useEffect(() => {
        let PaymentMessag = "You can have multiple SMS/text numbers in your account. Each number will cost $3 per month in addition to the cost per Shift Check in/out."

        if (window.WebsiteType === "ServiceWithText") {
            PaymentMessag = "You can have multiple SMS/text numbers in your account. Each number will cost $5 per month in addition to the cost per service call.  The new number cannot be deleted for 30 days"
        }

        setState(prevState => ({
            ...prevState,
            PaymentMessag: PaymentMessag
        }));

    }, []);

    console.log("AddSMSNumber.loading", loading)

    const handleClose = (event, reason) => {
        // handle close of Alert

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const handleNext = (verr) => {

        console.log("AddSMSNumber.verr", verr)

        let AlarmMessage = "";
        let OpenAlert = false;  //

        //if (verr === "PaymentMade" && !state.PaymentMade) {
        //    OpenAlert = true;
        //    AlarmMessage += ` * Please Select Payment Method`;
        //}

        if (verr === "Title" && state.Title === "") {
            OpenAlert = true;
            AlarmMessage += ` * Please enter Title`;
        }

        if (verr === "SelectedNumber") {
            if (state.SelectedNumber === "") {
                OpenAlert = true;
                AlarmMessage += ` * Please Select Number`;
            } else {
                GetTwilioNumber()
            }
        }

        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: OpenAlert,
                SnackbarKey: NewPageId("snk")
            }));

        } else {

            setActiveStep((prevActiveStep) => prevActiveStep + 1);

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const Updatedata = (LocalNumbers) => {

        //let status = "Pending"
        //if (state.SubscriptionId === "CheckInSubcontractorPaysAdd") {
        //    status = "Active"
        //}

        let CreateSMS = {
            UUID: UUid,
            SelectedNumber: state.SelectedNumber,
            OwnerId: ContractorData.CompanyId,
            OwnerName: ContractorData.CompanyName,
            date: currentTime,
            ShowDate: ShowDate,
            TimeZone: ContractorData.TimeZone,
            PersonId: UserData.sk1,
            PersonName: UserData.field1,
            PersonMobile: UserData.mobile,
            PersonEmail: UserData.email,
            Title: state.Title,
            TimeStamp: TimeStamp,
            PhoneSid: LocalNumbers,
            WebsiteType: state.WebsiteType,
            SubscriptionPlan: state.SubscriptionId,
            status: "Active",
            NewSubscription: false
        }

        EnterSMS(CreateSMS);

        EmailNotification();

        setState({
            ...state,
            DoneDone: true,
        });

    }

    const GetTwilioNumber = async () => {

        try {
            if (state.SelectedNumber !== "") {

                SetLoading(true)

                var SMSstring = {
                    "areaCode": "",
                    "CountryCode": "",
                    "phoneNumber": state.SelectedNumber,
                    "TwilioExecute": "Registor",
                    "WebsiteType": window.WebsiteType
                };

                console.log(
                    "GetTwilioNumber.STRING",
                    SMSstring
                );

                let LocalNumbers = await API.post("TwilioNumberList", "", {
                    body: { Records: SMSstring }
                });

                console.log(
                    "GetTwilioNumber.rESULT",
                    LocalNumbers
                );

                SetLoading(false)

                Updatedata(LocalNumbers);

            }
        } catch (err) {
            console.log("GetTwilioNumber.err", err)
        }
    }


    const SelectedValues = (item, name) => {

        if (name === "SelectedNumber") {
            let NewReferance = item.replace("+", "")
            setState(prevState => ({
                ...prevState,
                CLIENT_REFERENCE_ID: NewReferance
            }));
        }

        setState({
            ...state,
            [name]: item,
        });

    };


    const EmailNotification = () => {

        let title = `New Number Registration`;
        let PersonList = [UserData.email];
        //let postHTML = state.postHTML;
        //let postTEXT = state.postTEXT;

        // let postHTML = `<h1>Amount Paid: ${state.amount_paid} ${state.currency_code}</h1>`;
        let postHTML = `<p>New SMS Number: ${state.SelectedNumber}</p>`;
        //let postTEXT = "Expense Report\n\n";
        //postTEXT += `Amount Paid: ${state.amount_paid} ${state.currency_code}\n\n`;
        let postTEXT = `New SMS Number: ${state.SelectedNumber}`;

        let ContractorSubDomain = "https://app.ContractorCheckIn.com/";

        let SendEvent = {
            SpecialInstructions: "",
            PreSelect: [],
            title: title,
            postHTML: postHTML,
            postTEXT: postTEXT,
            PersonList: PersonList,
            ReplyEmail: "support@sninc.us",
            PostId: "expense",
            ContractorAddress: "30 N Gould St, Suite 37310, Sheridan, WY 82801",
            ContractorPhone: "",
            ContractorLogo: "",
            ContractorName: "ContractorCheckIn",
            avatar: "",
            UnsubscribeLink: `${ContractorSubDomain}/Unsubscribe`,
            ActionButton: `${ContractorSubDomain}`,
            ActionButtonTitle: "Log In",
            ActionButtonShow: false
        }

        console.log("SubscriptionAdd1.SendEmail", SendEvent)

        SendEmail(SendEvent);

    }

    const ConfirmPayment = details => {

        console.log("AddSMSNumber.details", details)

        let currency_code = details.purchase_units[0].amount.currency_code;
        let amount_paid = details.purchase_units[0].amount.value;

        setState(prevState => ({
            ...prevState,
            PaymentMade: true,
            currency_code: currency_code,
            amount_paid: amount_paid
        }))

        handleNext("ppp")

    }

   const cid = Buffer.from("Data::Owner::ServiceWithText::ali_rehan@msn.com").toString('base64');

    console.log("AddSMSNumber.state", state);

    return (
        <>
            <div>
                <Card>
                    <CardContent>

                        <Stepper activeStep={activeStep} orientation="vertical">

                            <Step key={0}>
                                <StepLabel>Acknowledgment</StepLabel>
                                <StepContent>

                                    <Typography>
                                        {state.PaymentMessag}
                                    </Typography>
                                    
                                    <div >
                                        <div>
                                            
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleNext("")}
                                            >
                                                Acknowledge
                                            </Button>
                                        </div>
                                    </div>

                                </StepContent>
                            </Step>

                            

                            <Step key={1}>
                                <StepLabel>New Text Number Title/Label</StepLabel>
                                <StepContent>
                                    <Grid container spacing={1}>

                                        <Grid xs={12} item>
                                            <TextField
                                                value={state.Title}
                                                placeholder="Choose Friendly Title"
                                                label="Title"
                                                disabled={props.disabled}
                                                fullWidth
                                                onChange={(e) => SelectedValues(e.target.value, "Title")}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid xs={12} item>
                                            <Typography color="error">
                                                {state.AlarmMessage}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <div >
                                                <div>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}

                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => handleNext("Title")}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </StepContent>
                            </Step>





                            <Step key={2}>
                                <StepLabel>Select New Text Number</StepLabel>
                                <StepContent>

                                    <AddTwilioNumber
                                        SelectedNumber={e => SelectedValues(e, "SelectedNumber")}
                                        handleNext={() => handleNext("SelectedNumber")}
                                        handleBack={handleBack}
                                    />

                                </StepContent>
                            </Step>

                            <Step key={3}>
                                <StepLabel>New Number Active</StepLabel>
                                <StepContent>

                                    <Typography>
                                        Please check the Text/SMS Policy link for the new number.
                                    </Typography>
                                    
                                </StepContent>
                            </Step>

                            {/* 
                            <Step key={3}>
                                <StepLabel>Activate the new number</StepLabel>
                                <StepContent>
                                    <Grid container spacing={1}>

                                        {state.SubscriptionId === "ServiceWithTextPlanAdd" ? (
                                            <Grid xs={12} item>
                                                <stripe-pricing-table
                                                    pricing-table-id="prctbl_1PNQrWIaiZMWM6GQGrjHZqCO"
                                                    publishable-key="pk_live_NU222DhVRWY91e5LXkDunENb"
                                                    client-reference-id={state.CLIENT_REFERENCE_ID}
                                                    customer-email={UserData.email}
                                                >
                                                </stripe-pricing-table>
                                            </Grid>
                                        ) : null}

                                        {state.SubscriptionId === "CheckInYouPayAdd" ? (
                                            <Grid xs={12} item>
                                                <stripe-pricing-table
                                                    pricing-table-id="prctbl_1PNR3ZIaiZMWM6GQJLGsbszb"
                                                    publishable-key="pk_live_NU222DhVRWY91e5LXkDunENb"
                                                    client-reference-id={state.CLIENT_REFERENCE_ID}
                                                    customer-email={UserData.email}
                                                >
                                                </stripe-pricing-table>
                                            </Grid>
                                        ) : null}

                                        {state.SubscriptionId === "CheckInSubcontractorPaysAdd" ? (
                                            <Grid xs={12} item>
                                                <Typography variant="h5" gutterBottom>
                                                    Thanks, the system is ready.  You can pass the new number to personnel who will use to check in/out
                                                </Typography>

                                            </Grid>
                                        ) : null}


                                    </Grid>

                                </StepContent>
                            </Step>
                            */}
                            
                        </Stepper>
                    </CardContent>
                </Card>
            </div>


            <Backdrop
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

        </>
    );
}
