
import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



export default function RecipeReviewCard(props) {

    return (
        <Grid container spacing={1} >
            <Grid item xs={12}>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                       // alignItems: 'center',
                        '& > *': {
                            m: 1,
                        },
                    }}
                >

                    <ButtonGroup variant="text" aria-label="text button group">
                        <Button
                            onClick={() => props.ActiveDate(props.LastMonth)}
                        >
                            <ArrowBackIosIcon />
                        </Button>
                        <Button>
                            <Typography variant="h4" gutterBottom>
                            {`${props.CurrentMonth} ${props.CurrentYear}`}
                        </Typography>
                        </Button>
                        <Button
                            onClick={() => props.ActiveDate(props.NextMonth)}
                        >
                            <ArrowForwardIosIcon />
                        </Button>
                    </ButtonGroup>
                </Box>

            </Grid>
            {props.AgendaDate && props.AgendaDate.map((EachDate, indd) => (
                <Grid item xs={12}>
                    <Card
                        key={"agdadate" + indd + EachDate.Today}
                        sx={{ backgroundColor: EachDate.BgColorr }}
                    >
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    {EachDate.Date}
                                </Avatar>
                            }
                            title=<Typography variant="h4" gutterBottom>
                                {EachDate.Day}
                            </Typography>
                        />
                        <CardContent>
                            <Grid container spacing={0} >
                            {EachDate.PerDate && EachDate.PerDate.map((eachSch, Ind) => (
                                
                                <Grid item xs={12} key={"agdevt" + Ind}>
                                        <Button
                                            onClick={() => props.onSelectEvent(eachSch)}
                                            variant="text">
                                            <Typography variant="body2" color="text.secondary">
                                                {eachSch.title}
                                            </Typography>
                                        </Button>
                                    </Grid>                                
                            ))}

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}

        </Grid>
    );
}


