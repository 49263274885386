import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
//import { styled } from '@mui/material/styles';
import {
  TextField,
  Collapse,
  Paper, Grid,
  Backdrop, 
  CircularProgress, FormGroup, 
  Card,
  CardContent,
  CardActions,
  InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
  RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
  Switch, Tooltip, Divider
} from '@mui/material';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
import RenderModule from '../utils/RenderModule';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


export default function SimpleCard(props) {
  const classes = useStyles();
  console.log("ContactBox.props", props)
  

  
  return <>
    <Card className={classes.root}>

        
      <CardContent>

        <Grid container spacing={0}>

        <Grid item xs={12}>
          <Typography variant="h4" key={"role1"}>
              {`${props.data.role}:`}
          </Typography>
        </Grid>

          
          {props.data && props.data.BaseInfo.length > 0 && props.data.BaseInfo.map((base, indd) => {
            if (base.VariableInput) {
              return (
                <Grid item xs={12} key={"baseg" + indd}>
                  <Typography variant="body1" key={"base" + indd}>
                    {base.field1}
                  </Typography>
                </Grid>
              )
            }
          }
          )}
          {props.data && props.data.ExtraInfo.length > 0 && props.data.ExtraInfo.map((base, indd) => (
            <Grid item xs={12} key={"ebaseg" + indd}>
              <Typography key={"ebase" + indd}>
                {base.field1}
              </Typography>
            </Grid>      
          ))}

          {props.data && props.data.Memo && (
            <Grid item xs={12} key={"eba11seg"}>
              <Typography key={"ebaseww1"}>
                {`Notes: ${props.data.Memo}`}
              </Typography>
            </Grid>
          )}

          </Grid>
          </CardContent>

          {props.ShowButtons ? (
      <CardActions>
        <IconButton aria-label="settings" onClick={() => props.DeleteItem()} size="large">
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="settings" onClick={() => props.MoveDown()} size="large">
          <ExpandMoreIcon />
        </IconButton>
        <IconButton aria-label="settings" onClick={() => props.MoveUp()} size="large">
          <ExpandLessIcon />
        </IconButton>
      </CardActions>
          ) : null}

    </Card>
    
  </>;
}
