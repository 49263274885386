import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { Box, Avatar, Divider, Checkbox, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import { FindCategoryIcon } from '../utils/CommonGraphql';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '../components/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    item: {
        padding: theme.spacing(1.2)
    },
    avatar: { marginRight: theme.spacing(2) },
    paginator: {
        justifyContent: "center",
        padding: "10px"
    }
}));


function FormRow(props) {

    const SetValues = (chekk, item) => {
        let Data = props.DataIn;
        Data[item] = chekk;
        props.DataOut(Data);
    }

    return (
        <React.Fragment>
            <Grid item xs={8}>
                <Grid container spacing={1} alignItems="center">
                    {props.matches && (
                        <Grid item>
                            {props.DataIn.avatar ?
                                (<Avatar alt="Logo" src={props.DataIn.avatar} />) : (
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon(props.DataIn.Category)}
                                    </Avatar>
                                )}
                        </Grid>
                    )}
                    <Grid item>
                        <Typography >
                            {props.DataIn.field1}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/*    
      <Grid item xs={2} align="center">
        <Checkbox
          // value={props.DataIn.email || false}
          id={props.DataIn.key + "push"}
          disabled={props.DataIn.push === "" || props.DataIn.push === undefined}
          onChange={(e) => SetValues(e.target.checked, "pushcheck")}
          checked={props.DataIn.pushcheck || false}
        />
      </Grid>
      */}
            <Grid item xs={4} align="center">
                <Checkbox
                    // value={props.DataIn.email || false}
                    id={props.DataIn.key + "email"}
                    disabled={props.DataIn.email === "" || props.DataIn.email === undefined}
                    onChange={(e) => SetValues(e.target.checked, "emailcheck")}
                    checked={props.DataIn.emailcheck || false}
                />
            </Grid>
            {/* 
            <Grid item xs={2} align="center">
                <Checkbox
                    //  value={props.DataIn.sms || false}
                    id={props.DataIn.key + "sms"}
                    disabled={props.DataIn.mobile === "" || props.DataIn.mobile === undefined}
                    onChange={(e) => SetValues(e.target.checked, "smscheck")}
                    checked={props.DataIn.smscheck || false}
                />
            </Grid>
            <Grid item xs={2} align="center">
                <Checkbox
                    //  value={props.DataIn.phone || false}
                    id={props.DataIn.key + "phone"}
                    disabled={props.DataIn.phone === "" || props.DataIn.phone === undefined}
                    onChange={(e) => SetValues(e.target.checked, "phonecheck")}
                    checked={props.DataIn.phonecheck || false}
                />
            </Grid>
            */}
        </React.Fragment>
    );
}

function Header() {
    return (
        <React.Fragment>
            <Grid item xs={8}>
                <Typography align="center" variant="button" display="block" gutterBottom>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography align="center" variant="button" display="block" gutterBottom>
                    Include
                </Typography>
            </Grid>
            {/* 
            <Grid item xs={2}>
                <Typography align="center" variant="button" display="block" gutterBottom>
                    SMS
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography align="center" variant="button" display="block" gutterBottom>
                    Phone
                </Typography>
            </Grid>
            */}
        </React.Fragment>
    );
}

export default function NestedGrid(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const classes = useStyles();
    const initialState = {
        DataBack: [],
        propss: {},
        TotalCount: 0,
        PersonalList: []
    }
    const [state, setState] = useState(initialState);
    const itemsPerPage = props.PerPage || 10;
    const [page, setPage] = useState(1);
    const [noOfPages, SetnoOfPages] = useState(0);

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {

        console.log("ESCAddPersonal.PersonalChecklist.props", props);

        if (props && props.DataBack) {

            let DataBack = props.DataBack;

            console.log("ESCAddPersonal.PersonalCheckList.DataBack", DataBack);
            setState(prevState => ({
                ...prevState,
                DataBack: DataBack,
                TotalCount: DataBack.length
            }));

            SetnoOfPages(Math.ceil(DataBack.length / itemsPerPage))
        }

    }, [props.DataBack])

    useEffect(() => {
        NarrowSearch(props.SearchWords);
    }, [props.SearchWords])

    //const handleClick = (event) => {
    //  props.AddBox(event);
    //};

    const NarrowSearch = (search) => {
        try {
            let Newdata = [];
            let RawData = props.DataBack;
            if (search !== "") {
                RawData.map((str, i) => {
                    if (str.SearchWords) {
                        if (str.SearchWords.toLowerCase().includes(search.toLowerCase())) {
                            Newdata.push(str);
                        }
                    }
                })
                console.log("Newdata search", Newdata);
            } else {
                Newdata = RawData;
            }
            SetnoOfPages(Math.ceil(Newdata.length / itemsPerPage))
            setState(prevState => ({ ...prevState, DataBack: Newdata }));
        } catch (err) { console.log("NarrowSearch err", err); }
    }

    //const SetValues = (VariableInput, IndexOf) => {
    //  try {
    //    console.log("Onchange form", VariableInput, IndexOf)
    //    let NewFormJSON = state.DataBack;
    //    NewFormJSON[IndexOf].VariableInput = VariableInput;
    //    NewFormJSON[IndexOf].defaultValue = VariableInput;
    //    //console.log("NewFormJSON[IndexOf]", NewFormJSON[IndexOf])
    //    setState(prevState => ({ ...prevState, DataBack: NewFormJSON }));
    //    //console.log("NewFormJSON", NewFormJSON);
    //  } catch (err) { console.log("SetValues", err) }
    //}

    const CheckedList = (e) => {

        try {
            console.log("ReturnValue", e);
            let NewData = state.DataBack;
            let StepPersonalList = [];
            let PersonalList = state.PersonalList;
            let FindItem = NewData.find(x => x.key === e.key);
            let FindPersonalList = PersonalList.findIndex(x => x.key === e.key);
            console.log("FindPersonalList", FindPersonalList);
            if (FindPersonalList > -1) {
                PersonalList[FindPersonalList] = {
                    key: e.key,
                    Category: "Person",
                    field1: e.field1,
                    field2: e.CompanyName,
                    CompanyName: e.CompanyName,
                    CompanyId: e.CompanyId,
                    phone: e.phone,
                    mobile: e.mobile,
                    email: e.email,
                    phonecheck: e.phonecheck,
                    emailcheck: e.emailcheck,
                    smscheck: e.smscheck,
                    pk1: e.pk1,
                    sk1: e.sk1,
                    avatar: e.avatar,
                    Category: e.Category
                }
            } else {
                PersonalList.push({
                    key: e.key,
                    field1: e.field1,
                    field2: e.CompanyName,
                    CompanyName: e.CompanyName,
                    CompanyId: e.CompanyId,
                    Category: "Person",
                    phone: e.phone,
                    mobile: e.mobile,
                    email: e.email,
                    phonecheck: e.phonecheck,
                    emailcheck: e.emailcheck,
                    smscheck: e.smscheck,
                    pk1: e.pk1,
                    sk1: e.sk1,
                    avatar: e.avatar,
                    Category: e.Category
                })
            }

            NewData[FindItem] = e;

            if (NewData) {
                NewData.map(e => {
                    if (e.phonecheck || e.emailcheck || e.smscheck) {
                        StepPersonalList.push({
                            key: e.key,
                            field1: e.field1,
                            field2: e.CompanyName,
                            CompanyName: e.CompanyName,
                            phone: e.phone,
                            mobile: e.mobile,
                            email: e.email,
                            phonecheck: e.phonecheck,
                            emailcheck: e.emailcheck,
                            smscheck: e.smscheck,
                            pk1: e.pk1,
                            sk1: e.sk1,
                            avatar: e.avatar,
                            Category: e.Category
                        })
                    }
                })
            }

            if (props.StepPersonalList) {
                props.StepPersonalList(StepPersonalList)
            }
            console.log("Personal-NewData", NewData);
            console.log("StepPersonalList", StepPersonalList);

            setState(prevState => ({
                ...prevState,
                DataBack: NewData,
                PersonalList: PersonalList
            }));

        } catch (err) { console.log(err) }
    }
    //console.log("state.DataBack", state.DataBack);

    console.log("ESCAddPersonal.PersonalChecklist.state", state);

    return (
        <div className={classes.root} key={props.key + "person"}>
            <Grid container spacing={1} >
                <Grid container item xs={12} spacing={1}>
                    <Header />
                </Grid>
                <Grid item xs={12} >
                    <Divider />
                </Grid>
                {state.DataBack && state.DataBack.length > 0 ? (state.DataBack
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map((product, i) => (
                        <Grid key={i + "ddls"} container item xs={12} spacing={1} alignItems="center">
                            <FormRow
                                DataIn={product}
                                DataOut={(e) => CheckedList(e)}
                                disabled={props.disabled}
                                matches={matches}
                            />
                        </Grid>
                    ))) : (<Skeleton />) }
                <Grid item xs={12} >
                    <Divider />
                </Grid>
            </Grid>

            <Box component="span">
                <Pagination
                    count={noOfPages}
                    page={page}
                    onChange={handleChange}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                    classes={{ ul: classes.paginator }}
                />
            </Box>
        </div>
    );
}
