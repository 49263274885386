import React from 'react'
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import ShowMarkerList from '../../Add/ShowMarkerList';
//    '../../Add/ShowMarkerList';

const containerStyle = {
    width: '100%',
    height: '600px'
};

const center = {
    lat: 33.8867193333425,
    lng: -118.2689000203263,
};

const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

let SRCount = "";

const MyComponent = (props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey:  process.env.REACT_APP_googleMapsApiKey
    })
    const ContractorData = useSelector(state => state.ContractorData);
    const [map, setMap] = React.useState(null)
    const [markers, setMarkers] = React.useState([]);
    const [Center, setCenter] = React.useState([]);
    const [ShowWindow, setShowWindow] = React.useState(false);
    const [SelectedMarker, setSelectedMarker] = React.useState(false);
    const [BranchLoc, SetBranch] = React.useState(null);

    const [latt, setlatt] = React.useState(center.lat);
    const [lagg, setlagg] = React.useState(center.lng);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    React.useEffect(() => {

        if (ContractorData && ContractorData.Location && ContractorData.Location.lat) {
            SetBranch({
                lat: ContractorData.Location.lat,
                lng: ContractorData.Location.lng
            })
        }

        if (props.Data && props.Data.length > 0) {
            setMarkers(props.Data);
            setCenter(props.center)
        }

    }, [props.Data])

    
    const mapRef = React.useRef();

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const ShowCallList = mark => {
        console.log("MapPage.GoogleMap.mark", mark);
        setSelectedMarker(mark)
        setShowWindow(true)
    }

    console.log("mapdata.map", props)

    return isLoaded && (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={Center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          //  onClick={onMapClick}
            options={options}
            onLoad={onMapLoad}
        >
            {markers.map((marker) => {

                if (marker.SRInfo && marker.SRInfo.length > 0) {
                    SRCount = marker.SRInfo.length.toString();
                } else {
                    SRCount = "";
                }
                
                return (
                    <Marker
                        key={`${marker.lat}-${marker.lng}`}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        label={{
                            text: SRCount,
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: "bold"
                        }}
                        onClick={() => {
                            ShowCallList(marker);
                        }}
                    />
                )
            })}

            {BranchLoc && BranchLoc !== null && (
            <Marker
                key="Branchlocationssss"
                    position={BranchLoc}
                    //label={{
                    //    text: "B",
                    //    color: "#fff",
                    //    fontSize: "16px",
                    //    fontWeight: "bold"
                    //}}
                icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                    scaledSize: new window.google.maps.Size(30, 30),
                }}
            />
            )}

            {ShowWindow && ( 
                <InfoWindow
                    position={{ lat: SelectedMarker.lat, lng: SelectedMarker.lng }}
                    onCloseClick={() => setShowWindow(false)}
                >
                    <ShowMarkerList data={SelectedMarker} />
                    </InfoWindow>
                )}

        </GoogleMap>
    )
}

export default MyComponent;
