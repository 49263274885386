import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import DrawerShow from '../utils/DrawerModule';
import {
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import ListModule from '../List/ListModule'


const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


export default function GmailTreeView(props) {
  const classes = useStyles();
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);

  const initialState = {
    NotificationStepsList: [],
    StepTeam: 0,
    EditItem: {},
    propss: {
      DataBack: []
    }
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {

    setState(prevState => ({
      ...prevState,
      StepNumber: props.StepNumber,
    }));

    if (props.Teams && props.Teams.length > 0) {
      setState(prevState => ({
        ...prevState,
        NotificationStepsList: props.Teams,
      }));
    }

    console.log("props", props);
  }, [props]);

  const DelayMessage = (delay) => {
    if (delay === "end") {
      return "Process Ends"
    } else {
      return `Time Delay - ${delay} minutes`
    }
  }

  //const AddTeam = () => {
  //  props.AddTeam(props.StepNumber)
  //}

  const ClosingDrawer = () => {
    SetDeawerOpen(false);
  }

  const AddNextStepOrEnd = (data) => {
    try {
      //console.log("AddNextStepOrEnd", state.StepNumber, data)
      let NotificationStepsList = state.NotificationStepsList;
      let StepIndex = NotificationStepsList.findIndex((post) => post.key === data.key);
      
      if (StepIndex > -1) {

        NotificationStepsList[StepIndex] = { ...data }
      } else {

      NotificationStepsList.push({
        ...data
      })
      }
      console.log("NNNNNNNNN", NotificationStepsList);

      if (props.AddStepData) {
        props.AddStepData(NotificationStepsList);
      }

      setState(prevState => ({
        ...prevState,
        NotificationStepsList: NotificationStepsList,
      }));

    } catch (err) { }
  };

  const AddTeam = () => {

    let NewTeamNumber = state.StepTeam + 1;
    console.log("NewTeamNumber", state.StepTeam, NewTeamNumber);
    setState(prevState => ({
      ...prevState,
      NextLoadModule: "ESCPersonal",
      StepTeam: NewTeamNumber
    }));

    SetDeawerOpen(true);
  }

  
  const AddBox = () => {

    setState(prevState => ({
      ...prevState,
      NextLoadModule: "Details",
    }));

    SetDeawerOpen(true);
  }

  const DeleteItem = (index) => {

    let NotificationStepsList = state.NotificationStepsList;
    NotificationStepsList.splice(index, 1);
    setState(prevState => ({
      ...prevState,
      NotificationStepsList: NotificationStepsList,
    }));

  }

  const EditItem = (e) => {
    setState(prevState => ({
      ...prevState,
      NextLoadModule: "ESCPersonal",
      EditItem: e,
      //StepNumber: e.StepNumber,
      //StepTeam: e.StepTeam,
    }));

    SetDeawerOpen(true);
  }

  console.log("state", state);
 
  return (
    <div key={props.StepNumber + state.StepTeam + "divnotidyd"}>
    <Grid container spacing={1}>
       
        <Grid item xs={12} key={props.StepNumber + "NotificationStepsListgrid"}>
          <Card className={classes.root} key={props.StepNumber + "NotificationStepsList"}>
            <CardHeader
              //action={
              //  <IconButton aria-label="settings">
              //    <EditIcon />
              //  </IconButton>
              //}
              title={`Step ${props.StepNumber}`}
              //subheader={eachStep.field2}
            />
            
            <CardContent>
              <Grid container spacing={1}>

                <Grid item xs={12} >
                  <TreeView
                    className={classes.root}
                    //defaultExpanded={['3']}
                    //defaultCollapseIcon={<ArrowDropDownIcon />}
                    //defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                  >
                    <StyledTreeItem nodeId="4" labelText={DelayMessage(props.delay)} labelIcon={AccessAlarmIcon} />
                    
                  </TreeView>
                </Grid>
                {
                  state.NotificationStepsList && state.NotificationStepsList.map((eachStep, indd) => (
                  
                  <Grid item xs={12} >
                    <ListModule
                        propss={eachStep.StepPersonalList}
                        //title={`${eachStep.field1} - ${eachStep.field2}`}
                        field1={eachStep.field1}
                        field2={eachStep.field2}
                        key={indd + "linsmodd"}
                        AddBox={e => AddBox(e)}
                        AllowDelete={true}
                        AllowEdit={true}
                        DeleteItem={() => DeleteItem(indd)}
                        EditItem={() => EditItem(eachStep)}
                    />
                  </Grid>
                ))
              }
                {/*
                  state.NotificationStepsList && state.NotificationStepsList.map((eachStep, indd) => (
                
                  <Grid item xs={12} >
                    <TreeView
                      className={classes.root}
                      defaultExpanded={['3']}
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                      defaultEndIcon={<div style={{ width: 24 }} />}
                    >
                      
                      <StyledTreeItem
                        nodeId="3"
                        labelText={`Team ${eachStep.field1} - ${eachStep.field2}`}
                        labelInfo={<EditIcon />}
                        labelIcon={NotificationsNoneIcon}
                      >
                        {eachStep.StepPersonalList && eachStep.StepPersonalList.map((eachss, indexx) => (

                          //each.map((eachss, index) => (
                          <StyledTreeItem
                            nodeId={ "personn" + eachStep.StepNumber + indexx}
                            key={ "personn" + eachStep.StepNumber + indexx}
                            labelText={eachss.field1}
                            labelIcon={PersonOutlineIcon}
                            //labelInfo="90"
                            color="#1a73e8"
                            bgColor="#e8f0fe"
                          />
                        ))}

                      </StyledTreeItem>
                     
                    </TreeView>
                  </Grid>
                
                ))
                */}
              </Grid>
            </CardContent>
            {props.delay !== "end" && (
            <CardActions>
              <Button
                  variant="contained"
                color="primary"
                onClick={() => AddTeam()}
              >
                Add Team
                </Button>
            </CardActions>
            )}
          </Card>
        </Grid>
        
    </Grid>

    <DrawerShow
        LoadComponenet={state.NextLoadModule}
        CloseDrawer={() => ClosingDrawer()}
        DrawerTitle="change this"
        propss={{
          CloseDrawer: () => ClosingDrawer(),
          StepPersonal: (e) => AddNextStepOrEnd(e),
          StepNumber: props.StepNumber,
          StepTeam: state.StepTeam,
          ShowEditButton: false,
          disabled: false,
          EditItem: state.EditItem,
          //StepTeam: state.StepTeam[state.EditStep],
          ESCId: props.ESCId
        }}
        DrawerStatus={DeawerOpen}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />
    </div>
  );
}
