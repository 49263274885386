import React from "react";
import { useSelector } from 'react-redux';
import RatingChart from '../Charts/RatingChart';

const ShowRating = (props) => {

    const UserData = useSelector(state => state.UserData);    
    console.log('ShowRating.props', props);

    return UserData.CustomerType === "Contractor" ? (

        <RatingChart
            {...props}
        />

    ): null;
};


export default ShowRating;
