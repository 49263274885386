import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getAutoCompletedata } from '../utils/CommonGraphql';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formControl: {
    // margin: theme.spacing(1),
  },
}));

const SelectCategory = (props) => {
  const classes = useStyles();
  const [loading, SetLoading] = useState(false);
  const [value, SetValue] = useState("");
  const initialState = {
    DataBack: [],
    Category: "",
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {
    GetDatafromDB(props.Category, props.cid);
    console.log("SelectCatProps", props)
    //for (let [key, value] of Object.entries(localStorage)) {
    //  console.log(` localStorage ${key}: ${value}`);
    //}

  }, [props.key])

  const GetDatafromDB = async (Category, cid) => {
    SetLoading(true);

    const DataString = {
      Category: Category,
      FindArray: [
        {
          ArrayName: "DataBack",
          ArraySearchString: ":",
        }
      ]
    };


    let NewData = await getAutoCompletedata(DataString, cid);
    setState(prevState => ({ ...prevState, ...NewData }))

    SetLoading(false);
    console.log("SelectNewData", NewData);
    //return NewData;
  }

  const SetValues = (e) => {
    console.log("SetValues", e);
    SetValue(e);

    let findInfo = state.DataBack.find(x => x.id === e);

    props.Selected(findInfo);
  }


  return (
    <>

      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
        <InputLabel id="demo-simple-select-outlined-label">{`Select ${props.Category}`}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id={props.key}
          autoWidth={true}
          value={value || ""}
          onChange={(e) => SetValues(e.target.value)}
          label={`Select ${props.Category}`}
        >
          {state.DataBack.map((o, indd) => (
            <MenuItem key={indd} value={o.id}>
              {o.field1}
            </MenuItem>
          ))}
        </Select>
      </FormControl >

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SelectCategory;
