/*
 * 
 * List Service Action (Urgency)
 * click to get details in a seperate window (AddBox)
 * the new window will allow additional link to change/add steps, progress, and ESC
 * 
 * 
 */



import React, { useState, useEffect } from 'react';
import {
  Container,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Snackbar,
  CardHeader,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
//import CardBox from '../components/ShowCheckBoxGroup';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';
import {
  removeEmptyStringElements,
  NewPageId,
  EleminateDuplicateObjects,
  getAutoCompletedata
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import ListCheckBox from '../List/ListCheckbox';
import ESCEachStepDetails from '../Setup/ESCEachStepDetails';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ESCId = NewPageId("ESC");

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  Cardroot: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    //margin: theme.spacing(1),
  },

}));

const WaitOptions = [
  { value: 'end', label: "End Notification Steps" },
  { value: '0', label: "No Wait" },
  { value: '10', label: "wait 10 minutes -> Next step" },
  { value: '30', label: "wait 30 minutes -> Next step" },
  { value: '45', label: "wait 45 minutes -> Next step" },
  { value: '60', label: "wait 1 hour -> Next step" },
  { value: '90', label: "wait 1.5 hour -> Next step" },
  { value: '120', label: "wait 2 hours -> Next step" },
  { value: '180', label: "wait 3 hours -> Next step" },
  { value: '240', label: "wait 4 hours -> Next step" },
  { value: '480', label: "wait 8 hours -> Next step" },
  { value: '720', label: "wait 12 hours -> Next step" },
  { value: '1440', label: "wait 24 hours -> Next step" },
]


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Account = (props) => {
  const classes = useStyles();
  const params = useParams();
  let query = useQuery();
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const cid = useSelector(state => state.cid);
  const [DeawerOpen, SetDeawerOpen] = React.useState(false);
  const matches = useMediaQuery('(min-width:600px)');
  const [loading, SetLoading] = useState(false);
  const [DrawerStatus, OpenDrawer] = useState(false);
  //console.log('cid cid ', cid)
  const initialState = {
    Category: "",
    FormJSON: [],
    FormData: [],
    NotificationStepsList: [],
    AlarmMessage: "",
    ESCId: ESCId,
    StepTitle: "Step 1",
    StepTeam: [],
    StepNumber: 0,
    step: 1,
    delay: '0',
    ShiftSelected: "",
    AreaSelected: "",
    UrgencySelected: "",
    TradeSelected: "",
    OpenAlert: false,
    ShowNewServiceForm: false,
    ImagePic: "",
    Shift: [
      {
        key: 1,
        field2: "6 AM - 6 PM",
        field1: "Office Hours",
        value: "123",
        checked: true
      },
      {
        key: 2,
        field2: "6 PM - 11:30 PM",
        field1: "Second Shift",
        value: "123",
        checked: true
      },
      {
        key: 3,
        field2: "11:30 PM - 6 AM",
        field1: "Third Shift",
        value: "123",
        checked: false
      },
    ],
    Area: [
      {
        key: 1,
        field2: "",
        field1: "South side",
        value: "123",
        checked: true
      },
      {
        key: 2,
        field2: "",
        field1: "East Side",
        value: "123",
        checked: true
      },
      {
        key: 3,
        field2: "",
        field1: "West Side",
        value: "123",
        checked: false
      },
    ],
    Urgency: [
      {
        key: 1,
        field2: "",
        field1: "Add to-do list",
        value: "123",
        checked: true
      },
      {
        key: 2,
        field2: "",
        field1: "Schedule Service for Future Date",
        value: "123",
        checked: true
      },
      {
        key: 3,
        field2: "",
        field1: "Emergency / Need Service Now",
        value: "123",
        checked: false
      },
      {
        key: 4,
        field2: "",
        field1: "Proposal/Quote needed",
        value: "123",
        checked: false
      },
    ],
    Trade: [
      {
        key: 1,
        field2: "",
        field1: "HVAC",
        value: "123",
        checked: true
      },
      {
        key: 2,
        field2: "",
        field1: "Controls",
        value: "123",
        checked: true
      }
    ]
  }
  const [state, setState] = useState(initialState);
  const [activeStep, setActiveStep] = useState(0);
  //const [DeawerOpen, SetDeawerOpen] = useState(false);

  const handleNext = (item) => {
    console.log('ESCSetupPage.handleNext', item);
    if (state[item] && state[item].length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setState(prevState => ({
        ...prevState,
        AlarmMessage: "Please select items before proceeding to next step",
        OpenAlert: true
      }));
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {

    if (UserData.CustomerType === "Company") {
      setState(prevState => ({
        ...prevState,
        ShowNewServiceForm: true,
      }));
    }

    if (cid && cid.CompanyId) {
      setState(prevState => ({
        ...prevState,
        CompanyId: cid.CompanyId,
      }));
    }



    //let Category = "";
    //console.log('props', props);
    //if (params.Category) {
    //  Category = params.Category;
    //  console.log('params.Category', params.Category);
    //}

    //if (props.Category) {
    //  Category = props.Category;
    //  console.log('props.Category', props.Category);
    //}



  }, [])

  useEffect(() => {
    GetDatafromDB("Lambda");
  }, [])

  //const CloseDrawerr = () => {
  //  GetDatafromDB("Lambda");
  //  SetDeawerOpen(false)
  //}
  

  const GetDatafromDB = async (Category) => {
    SetLoading(true);

    const DataString = {
      Category: Category,
      FindArray: [
        //{
        //  ArrayName: "Shift",
        //  ArraySearchString: "::Shift",
        //},
        //{
        //  ArrayName: "Area",
        //  ArraySearchString: "::Area",
        //},
        {
          ArrayName: "Urgency",
          ArraySearchString: "::Urgency",
        },
        //{
        //  ArrayName: "Trade",
        //  ArraySearchString: "::Trade",
        //}
      ]
    };

    let NewData = await getAutoCompletedata(DataString, ContractorData.ContractorId);
    setState(prevState => ({ ...prevState, ...NewData, Category: Category }))

    SetLoading(false);
    console.log("NewData", NewData);
  }


  const Image = imgg => {
    setState(prevState => ({
      ...prevState,
      ImagePic: imgg
    }));
  }

  const ReturnBackData = form => {
    console.log('formItemAddForm', form);
    setState(prevState => ({
      ...prevState,
      FormData: form
    }));
  }

  const GetSearchTerms = (selected, list) => {

    try {
      console.log("selected, list", selected, list)
      let SearchWords = "";
      let Temp = "";
      //  if (selected.length > 0)
      selected.map(each => {
        Temp = list.find(x => x.key === each);
        SearchWords += ` ${Temp.field1} |`;
      })
      return SearchWords;
    } catch (err) { console.log('GetSearchTerms err', err) }

  }

  const SetValueState = (e, name) => {
    setState(prevState => ({ ...prevState, [name]: e }));
  }


  ////////////////////////////////////////////
  const SaveData = () => {
    //console.log('SaveData', FormData);
    try {

      const key = `Data::Online::Lambda::ESC#${ESCId}`;
      //const key = `Data::Company::${Cat}#${ESCId}`;
      var currentUnixTime = new Date();
      var currentTime = currentUnixTime.toISOString();

      //   let posterId = User;

      let Field1 = "";
      let Field2 = "";
      let SearchWords = state.PolicyName;
      let MissingInfoFlag = false;
      let dataJSON = "";
      let FormdataJSON = {};
      let PhotoService = [];
      let Documents = [];
      let AlarmMessage = 'Please enter ';
      let DBInput = {};
      let DBInput2 = {};
      let Phone = "";
      let Email = "";
      let InputArray = [];
      let CompanyId = "";
      let CompanyName = "";
      let FormDD = {};
      let post = "";
      let title = "";
      let EndDate = "";
      let steps = [];

      //make sure cid and companyid exist
      if (cid && cid.CompanyId && cid.CompanyId !== "") {
        CompanyId = cid.CompanyId;
        CompanyName = cid.CompanyName;
      } else {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Company `;
      }

      if (state.PolicyName === "") {
        MissingInfoFlag = true;
        AlarmMessage += `* Please enter Policy Name `;
      }

      if (!state.ShiftSelected || state.ShiftSelected.length < 1) {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Shift `;
      } else {
        SearchWords += GetSearchTerms(state.ShiftSelected, state.Shift);
        console.log("state.ShiftSelected, state.Shift", state.ShiftSelected, state.Shift)
      }

      if (!state.AreaSelected || state.AreaSelected.length < 1) {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Area `;
      } else {
        SearchWords += GetSearchTerms(state.AreaSelected, state.Area);
      }

      if (!state.UrgencySelected || state.UrgencySelected.length < 1) {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Service Action / Urgency `;
      } else {
        SearchWords += GetSearchTerms(state.UrgencySelected, state.Urgency);
      }

      if (!state.TradeSelected || state.TradeSelected.length < 1) {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Trade `;
      } else {
        SearchWords += GetSearchTerms(state.TradeSelected, state.Trade);
      }


      if (!state.NotificationStepsList || state.NotificationStepsList.length < 1) {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Personal & Steps `;
      } else {

        state.NotificationStepsList.map((Step) => {
          let Teamms = [];
          if (Step.Teams) {
            Step.Teams.map(eachstep => {

              if (eachstep.ScheduleType === "Recurring") {
                EndDate = "3000-1-1";
              } else {
                EndDate = eachstep.EndDate;
              }

              DBInput = {
                pk1: CompanyId,
                sk1: eachstep.key,
                gsi1Pk2: "Notify::" + ContractorData.ContractorId,
                gsi1Sk2: EndDate,
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: eachstep.key,
                dataJSON: JSON.stringify(removeEmptyStringElements(eachstep))
              }

              Teamms.push({
                pk1: CompanyId,
                sk1: eachstep.key,
              })

              SearchWords += eachstep.SearchWords;
              InputArray.push(removeEmptyStringElements(DBInput));
            })

            steps.push({
              Teams: Teamms,
              delay: Step.delay,
              step: Step.step,
            })
          }
        });

      }


      FormdataJSON = {

        ContractorData: ContractorData,
        date: currentTime,
        PersonData: UserData,

        ShiftSelected: state.ShiftSelected,
        AreaSelected: state.AreaSelected,
        UrgencySelected: state.UrgencySelected,
        TradeSelected: state.TradeSelected,
        AreaSelected: state.AreaSelected,
        steps: steps,

        //FormData: FormData,
        SearchWords: SearchWords,
        CompanyId: CompanyId,
        CompanyName: CompanyName,
        id: ESCId,
        key: key,
        field1: state.PolicyName,
        //field2: Field2,
        ContractorId: ContractorData.ContractorId,

        //post: post,
        //title: title,
        //ServiceUrgency: ServiceUrgency,
        //ServiceTrade: ServiceTrade,
        //PostId: PostId,

        //EscPolicyFound: EscPolicyFound,


        //PhotoService: PhotoService,
        //Documents: Documents,
        //phone: Phone,
        //email: Email,
        pk1: CompanyId,
        sk1: key,
        gsi1Pk2: "Notify::" + ContractorData.ContractorId,
        gsi1Sk2: "3000-1-1",
        gsi2Pk2: ContractorData.ContractorId,
        gsi2Sk2: key
      }



      dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))

      console.log('MissingInfoFlag', MissingInfoFlag, AlarmMessage)


      //if alerts
      if (MissingInfoFlag) {
        setState(prevState => ({
          ...prevState,
          AlarmMessage: AlarmMessage,
          OpenAlert: true
        }));
      } else {
        //if no alerts

        DBInput = {
          pk1: CompanyId,
          sk1: key,
          gsi1Pk2: "Notify::" + ContractorData.ContractorId,
          gsi1Sk2: "3000-1-1",
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: key,
          dataJSON: dataJSON
        }

        InputArray.push(removeEmptyStringElements(DBInput));


        console.log('input', InputArray)
        console.log('FormdataJSON', FormdataJSON)
        console.log('state.NotificationStepsList', state.NotificationStepsList)


        if (InputArray.length > 0) {
          API.graphql(graphqlOperation(createBatch, { input: InputArray }))
            .then((results) => {
              console.log('createBatch Data ', results);
              if (props.CloseDrawer) {
                props.CloseDrawer(FormDD);
              }
            }).catch(error => alert(error.message));


          //if (props.CloseDrawer) {
          //  props.CloseDrawer();
          //}
        }



      } // end of no alerts

    } catch (err) { console.log("SaveData err", err); }
  }

  const handleClose = (event, reason) => {
    //if (reason === 'clickaway') {
    //  return;
    //}

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));

  };

  //const SelectedPerson = (e) => {

  //  setState(prevState => ({
  //    ...prevState,
  //    Requestor: e,
  //    ShowNewServiceForm: true
  //  }));

  //}

  const CloseDrawer = () => {
    SetDeawerOpen(false);
    GetDatafromDB("Lambda");
  }

  const KeyArray = (data, cat) => {
    console.log("KeyArray", data, cat)
    setState(prevState => ({
      ...prevState,
      [cat]: data
    }));
  }


  //const AddNextStepOrEnd = (data) => {
  //  try {
  //    console.log("AddNextStepOrEnd", state.StepNumber, data)
  //    let NotificationStepsList = state.NotificationStepsList;
  //    let StepIndex = NotificationStepsList.findIndex((post) => post.step === data.StepNumber);
  //    let NewStepNumber = state.StepNumber;

  //    if (StepIndex > -1) {
  //      NotificationStepsList[StepIndex] = {
  //        ScheduleType: data.ScheduleType,
  //        StartDate: data.StartDate,
  //        EndDate: data.EndDate,
  //        StepPersonalList: data.StepPersonalList,
  //        StepName: data.StepName,
  //        field1: data.field1,
  //        field2: data.field2,
  //        delay: data.delay,
  //        step: data.StepNumber,
  //        SearchWords: data.SearchWords,
  //        ESCId: data.ESCId
  //      }

  //    } else {

  //      NotificationStepsList.push({
  //        ScheduleType: data.ScheduleType,
  //        StartDate: data.StartDate,
  //        EndDate: data.EndDate,
  //        StepPersonalList: data.StepPersonalList,
  //        StepName: data.StepName,
  //        field1: data.field1,
  //        field2: data.field2,
  //        delay: data.delay,
  //        SearchWords: data.SearchWords,
  //        step: NewStepNumber,
  //        ESCId: data.ESCId
  //      })

  //      NewStepNumber = state.StepNumber + 1;

  //    }
  //    console.log("NotificationStepsList", StepIndex, NewStepNumber, NotificationStepsList);

  //    setState(prevState => ({
  //      ...prevState,
  //      NotificationStepsList: NotificationStepsList,
  //      StepNumber: NewStepNumber
  //    }));
  //  } catch (err) { }
  //};

  // Additional Information Box
  const AddBox = (add) => {

    add.LoadComponenet = "UrgencyDetails";
    props.AddBox(add);
    //console.log("AddBox", add);
    //let NewKey = NewPageId("list");
    //if (matches) {
    //  setState(prevState => ({
    //    ...prevState,
    //    DetailComponent: "UrgencyDetails",
    //    Category: state.Category,
    //    key: add.key || NewKey,
    //    ShowDetails: true,
    //    DetailsProps: {
    //      title: add.field1,
    //      Category: state.Category,
    //      ShowEditButton: false,
    //      PreviewEdit: false,
    //      Data: add
    //    },
    //  }))

    //} else {
    //  //open Drawer
    //  setState(prevState => ({
    //    ...prevState,
    //    DetailComponent: "UrgencyDetails",
    //    Category: state.Category,
    //    ShowDetails: false,
    //    DetailsProps: {
    //      title: add.field1,
    //      Category: state.Category,
    //      ShowEditButton: false,
    //      PreviewEdit: false,
    //      Data: add
    //    },
    //    key: add.key || NewKey
    //  }))

    //  OpenDrawer(true);
    //}
  }

  const AddCategory = (cat) => {
    console.log("cat", cat)
    setState(prevState => ({
      ...prevState,
      Category: cat,
      DetailComponent: "AddSetupSelection",
      FormData: {}
    }))
    SetDeawerOpen(true);
  };


  const AddNewStep = (delay) => {
    let NotificationStepsList = state.NotificationStepsList;

    //put the delay in previous step
    //NotificationStepsList[state.StepNumber].delay = delay;

    let NewStepNumber = state.StepNumber + 1;

    NotificationStepsList.push({
      step: NewStepNumber,
      Teams: [],
      delay: delay,
      ESCId: state.ESCId
    })

    setState(prevState => ({
      ...prevState,
      NotificationStepsList: NotificationStepsList,
      delay: delay,
      StepNumber: NewStepNumber
    }));

  }

  const AddStepData = (teams, index) => {
    let NotificationStepsList = state.NotificationStepsList;
    NotificationStepsList[index].Teams = teams;
    console.log("NotificationStepsList", NotificationStepsList)
    //{ ...NotificationStepsList[index], Teams: teams }
    setState(prevState => ({
      ...prevState,
      NotificationStepsList: NotificationStepsList
    }));
  }

  
  console.log("state", state)
  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          //avatar={
          //  <Avatar>
          //      <BuildIcon />
          //  </Avatar>
          //}
          action={
            <Button variant="contained" color="primary" onClick={() => AddCategory("Urgency")}>
              Add
              </Button>
          }
          title={`Select Service Actions`}
          //subheader={state.field2}
        />
        <CardContent>

            <ListCheckboxMoveUpDown
              Data={state.Urgency}
              title="Service Actions"
              ShowUpDown={true}
              ShowEdit={true}
              AddBox={(e) => AddBox(e)}
              DefaultChecked={state.DefaultChecked || []}
              NextLoadComponenet="AddSetupSelection"
              Category="Urgency"
              KeyArray={(e) => KeyArray(e, "UrgencySelected")}
            />

          
        </CardContent>
        <CardActions>

          <Button
            color="primary"
            variant="contained"
            disabled={state.Selection && state.Selection.length < 1}
            onClick={() => SaveData()}
          >
            Save details
          </Button>

        </CardActions>
      </Card>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

          <Snackbar
              open={state.OpenAlert}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              message={state.AlarmMessage}
          />
          {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={(e) => CloseDrawer(e)}
        Category={state.Category}
        CompanyId={state.CompanyId}
        CompanyName={state.CompanyName}
        propss={state.DetailsProps}
        key={state.key}
        DrawerStatus={DrawerStatus}
        OpenDrawer={(e) => OpenDrawer(e)}
        anchor={state.anchor || "left"}
      />

      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={() => CloseDrawer()}
        //DetailComponent: "AddSetupSelection" FormData
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawerAdd: (e) => CloseDrawer(e),
          //CloseDrawer: (e) => CloseDrawerr(e),
          Category: state.Category,
          FormData: state.FormData
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => CloseDrawer(e)}
        anchor="left"
      />


    </>
  );
};

export default Account;
