/*
 * list all the forms from database
 * allow customer to add a new form based on the select category from drop down menu
 *  * 
 */


import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import {
  Paper,
  Grid,
  Backdrop,
  CircularProgress, Snackbar,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
////import FormHelperText from '@mui/material/FormHelperText';
//import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
//import RenderModule from '../utils/RenderModule';
//import SelectCategory from '../components/SelectCategory';
import { NewPageId, getAutoCompletedata, removeEmptyStringElements } from '../utils/CommonGraphql';
import ListRadio from '../List/ListRadio';
//import CardBox from '../components/ShowCheckBoxGroup';
import DrawerShow from '../utils/DrawerModule';
import { FormSchema } from '../Forms/FormList';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let NewService = NewPageId("NSvc")

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    //margin: theme.spacing(1),
  },
}));

const FormTypeList = [
  { value: "Select", label: "Add New Form" },
  { value: "Equipment", label: "System/Equipment Data Input" },
  { value: "Task", label: "Service Task List" },
  { value: "Payment", label: "Payment Method" },
  { value: "Location", label: "Address/Locations" },
  { value: "Approval", label: "Customer Approving Person" },
  { value: "ContactUs", label: "Contact Us" },
  { value: "Company", label: "Add Company" },
  { value: "Person", label: "Add Person" },
  { value: "Survey", label: "Survey / Feedback" },
  { value: "Warranty", label: "Warranty" },
  { value: "Project", label: "Project" },
  { value: "Quote", label: "Quote" },
  { value: "Information", label: "Information" },
];

//const CommonFormeeeeeList = [
//  {
//    field1: "Equipment",
//    key: "Equipment",
//    Category: "Equipment",
//    FormData: [
//      {
//        Order: 1,
//        options: [],
//        label: "Name/Tag",
//        VariableName: "tag",
//        VariableInput: "",
//        Editable: false,
//        TableColumn: true,
//        extra: "field1",
//        message: "",
//        type: "textline",
//        key: 300,
//        Category: "Equipment",
//        required: true,
//      },
//      {
//        Order: 1,
//        options: [],
//        label: "Model",
//        VariableName: "Model",
//        VariableInput: "",
//        Editable: true,
//        TableColumn: false,
//        extra: "field2",
//        message: "",
//        type: "textline",
//        key: 301,
//        Category: "Equipment",
//        required: false,
//      },
//      {
//        Order: 1,
//        options: [],
//        label: "Manufacturer",
//        VariableName: "Manufacturer",
//        VariableInput: "",
//        Editable: true,
//        TableColumn: false,
//        extra: "",
//        message: "",
//        type: "textline",
//        key: 302,
//        Category: "Equipment",
//        required: false,
//      },
//      {
//        Order: 1,
//        options: [],
//        label: "Serial",
//        VariableName: "Serial",
//        VariableInput: "",
//        Editable: true,
//        TableColumn: false,
//        extra: "",
//        message: "",
//        type: "textline",
//        key: 303,
//        Category: "Equipment",
//        required: false,
//      },
//    ],
//  },
//  {
//    field1: "Location",
//    key: "Location",
//    Category: "Location",
//    FormData: [
//      {
//        Order: 1,
//        options: [],
//        label: "Address",
//        VariableName: "Address",
//        VariableInput: "",
//        Editable: false,
//        TableColumn: true,
//        extra: "field1",
//        message: "",
//        type: "GoogleAddressAutoComplate",
//        key: 200,
//        Category: "Location",
//        required: true,
//      },
//      {
//        Order: 1,
//        options: [],
//        label: "Building",
//        VariableName: "Building",
//        VariableInput: "",
//        Editable: true,
//        TableColumn: false,
//        extra: "field2",
//        message: "",
//        type: "textline",
//        key: 201,
//        Category: "Location",
//        required: false,
//      },
//      {
//        Order: 1,
//        options: [],
//        label: "Room",
//        VariableName: "Room",
//        VariableInput: "",
//        Editable: true,
//        TableColumn: false,
//        extra: "",
//        message: "",
//        type: "textline",
//        key: 202,
//        Category: "Location",
//        required: false,
//      },
//    ],
//  },
//  {
//    field1: "Person",
//    key: "Person",
//    Category: "Person",
//    FormData: [
//      {
//        Order: 1,
//        key: 1,
//        Category: "Person",
//        extra: "field1",
//        required: true,
//        options: [],
//        label: "Name",
//        VariableName: "name",
//        VariableInput: "",
//        Editable: false,
//        TableColumn: true,
//        message: "",
//        type: "textline",
//      },
//      {
//        Order: 1,
//        options: [],
//        label: "Email",
//        VariableName: "email",
//        VariableInput: "",
//        Editable: false,
//        TableColumn: true,
//        extra: "field2",
//        message: "",
//        type: "email",
//        key: 2,
//        Category: "Person",
//        required: true,
//      },
//      {
//        Order: 3,
//        options: [],
//        label: "Position",
//        VariableName: "position",
//        Editable: true,
//        TableColumn: false,
//        extra: "",
//        VariableInput: "",
//        message: "",
//        type: "textline",
//        key: 3,
//        Category: "Person",
//        required: false
//      },
//      {
//        Order: 3,
//        options: [],
//        label: "Mobile/SMS",
//        VariableName: "mobile",
//        Editable: true,
//        TableColumn: false,
//        extra: "",
//        VariableInput: "",
//        message: "",
//        type: "phone",
//        key: 3,
//        Category: "Person",
//        required: true
//      },
//      {
//        Order: 3,
//        options: [],
//        label: "Work Phone",
//        VariableName: "phone",
//        Editable: true,
//        TableColumn: false,
//        extra: "",
//        VariableInput: "",
//        message: "",
//        type: "phone",
//        key: 3,
//        Category: "Person",
//        required: false
//      },
//    ],
//  },
//  {
//    field1: "Payment",
//    key: "Payment",
//    Category: "Payment",
//    FormData: [
//      {
//        Order: 20,
//        VariableName: "Radio",
//        VariableInput: "",
//        message: "",
//        type: "radio",
//        key: 1100,
//        Category: "Payment",
//        required: false,
//        label: "Payment Options",
//        DataSourse: "options",
//        defaultValue: ["NewPO"],
//        options: [
//          { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
//          { key: "NewPO", label: "New PO", value: "NewPO" },
//          { key: "warranty", label: "Warranty", value: "warranty" },
//          { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
//        ]
//      }
//    ],
//  },
//]

//const FormPrefArray = ["Form::NewService", "Form::Person", "Form::Equipment", "Form::Location", "Form::Team", "Form::Company"];

export default function SimpleAccordion(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  const [noOfPages, SetnoOfPages] = useState(0);
  const cid = useSelector(state => state.cid);
  const itemsPerPage = props.PerPage || 10;
  const [page, setPage] = useState(1);
  const [DeawerOpen, SetDeawerOpen] = useState(false);
  const initialState = {
    DataBack: [],
    propss: {},
    AppliesTo: "Company",
    Category: "Urgency",
    Trade: [],
    Urgency: [],
    FormData: {},
    Form: [],
    UrgencySelected: [],
    TradeSelected: [],
    FormsSelected: [],

    title: ""
  }
  const [state, setState] = useState(initialState);
  const [loading, SetLoading] = useState(false);

  //const LoadCommonForms = () => {
  //  let FromType = [];
  //  let FormData = [];
  //  let FormJSON = [];
  //  FormPrefArray.forEach((form, ii) => {

  //    FromType = form.split("::");
  //    FormData = FormSchema.filter(x => x.Category === FromType[1]);

  //    FormJSON.push({
  //      FormType: FromType[1],
  //      key: `${form}::${ContractorData.ContractorId}`,
  //      FormId: form,
  //      FormName: FromType[1],
  //      Category: FromType[1],
  //      field1: FromType[1],
  //      FormData: FormData,
  //    })

  //   // store.set(`${form}::${ContractorId}`, FormJSON);

  //    FromType = [];
  //    FormData = [];
  //    FormJSON = {};

  //  })

  //  return FormJSON
  //}

  useEffect(() => {
    let DataBack = props.propss.DataBack;
    console.log("props", props);
    let PresentSelected = props.propss.PresentSelected;
    //let CommonFormList = LoadCommonForms();
    if (DataBack) {
      //let DataBack = [...CommonFormList, ...DataBack]
      //console.log("DataBack", DataBack);
      setState(prevState => ({ ...prevState, DataBack: DataBack, TotalCount: DataBack.length }));
      SetnoOfPages(Math.ceil(DataBack.length / itemsPerPage))
    }

  }, [props])

  //useEffect(() => {
  //  if (state.Form && state.Form.length > 0) {
  //    setState(prevState => ({ ...prevState, Form: [...CommonFormList, ...state.Form] }))
  //  }

  //  console.log("state.Form", state.Form);
  //  console.log("CommonFormList", CommonFormList);

  //}, [state.Form])

  //const GetDatafromDB = async (Category, GetBack) => {
  //  SetLoading(true);
  //  let GetBackArray = [];
  //  GetBack.map(each => {
  //    GetBackArray.push({
  //      ArrayName: each,
  //      ArraySearchString: `::${each}`
  //    })
  //  })
  //  const DataString = {
  //    Category: Category,
  //    FindArray: GetBackArray
  //  };

  //  let NewData = await getAutoCompletedata(DataString, ContractorData.ContractorId);

  //  if (NewData && NewData.Form) {
  //    NewData.Form = [...CommonFormList, ...NewData.Form]
  //  }

  //  setState(prevState => ({ ...prevState, ...NewData }))

  //  SetLoading(false);
  //  console.log("NewData", NewData);
  //}

  //const handleNext = () => {
  //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //};

  //const handleBack = () => {
  //  setActiveStep((prevActiveStep) => prevActiveStep - 1);
  //};

  //const handleChange = (event) => {
  //  console.log("event.target.value", event.target.name, event.target.value)
  //  setState(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
  //};

  //const AddCategory = (cat) => {
  //  console.log("cat", cat)
  //  setState(prevState => ({
  //    ...prevState,
  //    Category: cat,
  //    DetailComponent: "AddSetupSelection",
  //    FormData: {}
  //  }))
  //  SetDeawerOpen(true);
  //};

  const PreviewForms = (data) => {
    //console.log("cat", cat)
    setState(prevState => ({
      ...prevState,
      Category: data.Category,
      DetailComponent: "FormPreview",
      FormData: { ...data }
    }))
    SetDeawerOpen(true);
  }

  const CustomForm = (cat) => {

    setState(prevState => ({
      ...prevState,
      FormType: cat,
    }))

    if (cat !== "Select") {
      setState(prevState => ({
        ...prevState,
        Category: cat,
        DetailComponent: "FormCreatorClass",
        FormData: {}
      }))
      SetDeawerOpen(true);
    }
  }


  //const SelectedCat = (cat) => {
  //  console.log("cat", cat)
  //  setState(prevState => ({
  //    ...prevState,
  //    NewKey: NewPageId("key"),
  //    cat: cat
  //  }))
  //};

  //const handleReset = () => {
  //  setActiveStep(0);
  //};

  const KeyArray = (data, cat) => {
    console.log("KeyArray", data, cat)
    setState(prevState => ({
      ...prevState,
      [cat]: data
    }));
  }

  const handleClose = (event, reason) => {

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));

  };

  const ClosingDrawer = (e) => {
    let CategoryItems = state[state.Category]
    setState(prevState => ({ ...prevState, [state.Category]: [e, ...CategoryItems] }))
    SetDeawerOpen(false)
  }

  //const handleNextValidate = (item) => {
  //  if (state[item] && state[item].length > 0) {
  //    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //  } else {
  //    setState(prevState => ({
  //      ...prevState,
  //      AlarmMessage: "Please select items before proceeding to next step",
  //      OpenAlert: true
  //    }));
  //  }

  //};

  //const GetSearchTerms = (selected, list) => {

  //  try {
  //    console.log("selected, list", selected, list)
  //    let SearchWords = "";
  //    let Temp = "";
  //    //  if (selected.length > 0)
  //    selected.map(each => {
  //      Temp = list.find(x => x.key === each);
  //      SearchWords += ` ${Temp.field1} |`;
  //    })
  //    return SearchWords;
  //  } catch (err) { console.log('GetSearchTerms err', err) }

  //}

  //////////////////////////////////////////////
  //const SaveData = () => {
  //  //console.log('SaveData', FormData);
  //  try {

  //    const key = `Data::Online::NewService#${NewService}`;
  //    //const key = `Data::Company::${Cat}#${NewService}`;
  //    var currentUnixTime = new Date();
  //    var currentTime = currentUnixTime.toISOString();

  //    //   let posterId = User;

  //    let Field1 = "";
  //    let Field2 = "";
  //    let SearchWords = "";
  //    let MissingInfoFlag = false;
  //    let dataJSON = "";
  //    let FormdataJSON = {};
  //    let PhotoService = [];
  //    let Documents = [];
  //    let AlarmMessage = 'Please enter ';
  //    let DBInput = {};
  //    let DBInput2 = {};
  //    let Phone = "";
  //    let Email = "";
  //    let InputArray = [];
  //    let CompanyId = "";
  //    let CompanyName = "";
  //    let FormDD = {};
  //    let post = "";
  //    let title = "";
  //    let EndDate = "";
  //    let steps = [];

  //    //make sure cid and companyid exist
  //    //if (cid && cid.CompanyId && cid.CompanyId !== "") {
  //    //  CompanyId = cid.CompanyId;
  //    //  CompanyName = cid.CompanyName;
  //    //} else {
  //    //  MissingInfoFlag = true;
  //    //  AlarmMessage += `* Please select Company `;
  //    //}

  //    //if (state.PolicyName === "") {
  //    //  MissingInfoFlag = true;
  //    //  AlarmMessage += `* Please enter Policy Name `;
  //    //}

  //    //if (!state.ShiftSelected || state.ShiftSelected.length < 1) {
  //    //  MissingInfoFlag = true;
  //    //  AlarmMessage += `* Please select Shift `;
  //    //} else {
  //    //  SearchWords += GetSearchTerms(state.ShiftSelected, state.Shift);
  //    //  console.log("state.ShiftSelected, state.Shift", state.ShiftSelected, state.Shift)
  //    //}

  //    //if (!state.AreaSelected || state.AreaSelected.length < 1) {
  //    //  MissingInfoFlag = true;
  //    //  AlarmMessage += `* Please select Area `;
  //    //} else {
  //    //  SearchWords += GetSearchTerms(state.AreaSelected, state.Area);
  //    //}

  //    if (!state.UrgencySelected || state.UrgencySelected.length < 1) {
  //      MissingInfoFlag = true;
  //      AlarmMessage += `* Please select Service Action / Urgency `;
  //    } else {
  //      SearchWords += GetSearchTerms(state.UrgencySelected, state.Urgency);
  //    }

  //    if (!state.TradeSelected || state.TradeSelected.length < 1) {
  //      MissingInfoFlag = true;
  //      AlarmMessage += `* Please select Trade `;
  //    } else {
  //      SearchWords += GetSearchTerms(state.TradeSelected, state.Trade);
  //    }


  //    //if (!state.NotificationStepsList || state.NotificationStepsList.length < 1) {
  //    //  MissingInfoFlag = true;
  //    //  AlarmMessage += `* Please select Personal & Steps `;
  //    //} else {

  //    //  state.NotificationStepsList.map((Step) => {
  //    //    let Teamms = [];
  //    //    if (Step.Teams) {
  //    //      Step.Teams.map(eachstep => {

  //    //        if (eachstep.ScheduleType === "Recurring") {
  //    //          EndDate = "3000-1-1";
  //    //        } else {
  //    //          EndDate = eachstep.EndDate;
  //    //        }

  //    //        DBInput = {
  //    //          pk1: CompanyId,
  //    //          sk1: eachstep.key,
  //    //          gsi1Pk2: "Notify::" + ContractorData.ContractorId,
  //    //          gsi1Sk2: EndDate,
  //    //          gsi2Pk2: ContractorData.ContractorId,
  //    //          gsi2Sk2: eachstep.key,
  //    //          dataJSON: JSON.stringify(removeEmptyStringElements(eachstep))
  //    //        }

  //    //        Teamms.push({
  //    //          pk1: CompanyId,
  //    //          sk1: eachstep.key,
  //    //        })

  //    //        SearchWords += eachstep.SearchWords;
  //    //        InputArray.push(removeEmptyStringElements(DBInput));
  //    //      })

  //    //      steps.push({
  //    //        Teams: Teamms,
  //    //        delay: Step.delay,
  //    //        step: Step.step,
  //    //      })
  //    //    }
  //    //  });

  //    //}


  //    FormdataJSON = {

  //      ContractorData: ContractorData,
  //      date: currentTime,
  //      PersonData: UserData,
  //      UrgencySelected: state.UrgencySelected,
  //      TradeSelected: state.TradeSelected,
  //      FormsSelected: state.FormsSelected,
  //      SearchWords: SearchWords,
  //      CompanyId: ContractorData.ContractorId,
  //      CompanyName: CompanyName,
  //      id: NewService,
  //      key: key,
  //      field1: state.PolicyName,
  //      field2: "Service Form",
  //      Message: state.Message,
  //      ContractorId: ContractorData.ContractorId,
  //      title: state.title,
  //      pk1: ContractorData.ContractorId,
  //      sk1: key,
  //      gsi1Pk2: "NewService::" + ContractorData.ContractorId,
  //      gsi1Sk2: "3000-1-1",
  //      gsi2Pk2: ContractorData.ContractorId,
  //      gsi2Sk2: key
  //    }



  //    dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))

  //    console.log('MissingInfoFlag', MissingInfoFlag, AlarmMessage)


  //    //if alerts
  //    if (MissingInfoFlag) {
  //      setState(prevState => ({
  //        ...prevState,
  //        AlarmMessage: AlarmMessage,
  //        OpenAlert: true
  //      }));
  //    } else {
  //      //if no alerts

  //      DBInput = {
  //        pk1: ContractorData.ContractorId,
  //        sk1: key,
  //        gsi1Pk2: "NewService::" + ContractorData.ContractorId,
  //        gsi1Sk2: "3000-1-1",
  //        gsi2Pk2: ContractorData.ContractorId,
  //        gsi2Sk2: key,
  //        dataJSON: dataJSON
  //      }

  //      InputArray.push(removeEmptyStringElements(DBInput));


  //      console.log('input', InputArray)
  //      console.log('FormdataJSON', FormdataJSON)
  //      //console.log('state.NotificationStepsList', state.NotificationStepsList)


  //      if (InputArray.length > 0) {
  //        API.graphql(graphqlOperation(createBatch, { input: InputArray }))
  //          .then((results) => {
  //            console.log('createBatch Data ', results);
  //            if (props.CloseDrawer) {
  //              props.CloseDrawer(FormdataJSON);
  //            }
  //          }).catch(error => alert(error.message));


  //        //if (props.CloseDrawer) {
  //        //  props.CloseDrawer();
  //        //}
  //      }



  //    } // end of no alerts

  //  } catch (err) { console.log("SaveData err", err); }
  //}

  //const SetValue = (e, name) => {
  //  let Message = e.target.value;
  //  setState(prevState => ({ ...prevState, [name]: Message }))
  //}



  //console.log("props", props);
  return (
    <div className={classes.root}>

      <Paper className={classes.paper}>


        <Grid container spacing={1}>
          

          <Grid item xs={12}>
            <ListRadio
                          DataBack={state.DataBack}
              //propss={state.propss}
              title="Forms"
              SearchWords=""
              AddBox={(e) => PreviewForms(e)}
              SelectedKeyArray={(e) => props.SelectedKeyArray(e)}
              disabled={false}
              ShowUpDown={true}
              ShowEdit={true}
              PerPage={10}
              id={"FormsSelected" + activeStep + NewService}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>
              Or, Add a new form.  Please select the category from the drop down menu to add new form.
                  </Typography>
          </Grid>
          <Grid item xs={12}>

            <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
              <InputLabel id="demo-simple-select-outlined-label">Add Custom Form</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                //id={`select-${Item.key}-${index}`}
                //defaultValue={state.StartTime}
                //error={ErrorMessage}
                //required={Item.required}
                disabled={state.disabled}
                autoWidth={true}
                value={state.FormType || ""}
                onChange={e => CustomForm(e.target.value)}
                label="Add Custom Form"
              >
                {FormTypeList.map((o, indd) => (
                  <MenuItem key={indd} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))
                }
              </Select>
            </FormControl >

          </Grid>

        </Grid>


      </Paper>
      {/* end  */}




      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={() => ClosingDrawer()}
        //DetailComponent: "AddSetupSelection" FormData
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawerAdd: (e) => ClosingDrawer(e),
          Category: state.Category,
          FormData: state.FormData
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />


    </div>
  );
}
