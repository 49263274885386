import React, { useEffect, useState } from 'react';
//import Radio from '@mui/material/Radio';
//import RadioGroup from '@mui/material/RadioGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
//import FormGroup from '@mui/material/FormGroup';
//import Switch from '@mui/material/Switch';
//import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
import {
    TextField,
    //Collapse,
    Paper,
    Grid, Button,
    Backdrop,
    CircularProgress,
    Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar,
    FormControl, RadioGroup, FormControlLabel, Radio,
    FormGroup, Switch
    //Card,
    //CardContent,
    //CardActions,
    //InputLabel, Select, FormControl, MenuItem, Checkbox, 
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';
import { useSelector } from 'react-redux';

let CheckInMessages = [
    {
        key: "checkinmessage",
        label: "Message at the end of Check In process",
        name: "checkinmessage",
        value: "Thanks for Checking-in. Please text pictures and work progress during the shift to this number."
    },
    {
        key: "checkoutmessage",
        label: "Message at the end of Check Out process",
        name: "checkoutmessage",
        value: "Thanks for Checking Out"
    },
];


let ServiceOppMessages333 = [
    //{
    //    key: "ReplyEmail",
    //    label: "Message at the end of Check In process",
    //    name: "checkinmessage",
    //    value: "noreply@sninc.us"
    //},
    //{
    //    key: "AllowServiceMessage",
    //    label: "Service call received, the system is processing the service request",
    //    name: "AllowServiceMessage",
    //    value: "Service call received, the system is processing the service request"
    //},   
    //{
    //    key: "NotificationSent",
    //    label: "Message at the end of Check Out process",
    //    name: "checkinmessage",
    //    value: "Thank you for checking out"
    //},
    //{
    //    key: "DenyServiceMessage",
    //    label: "Message at the end of Check Out process",
    //    name: "checkinmessage",
    //    value: "Thank you for checking out"
    //},
];

//const ServiceMessage = {
//    ReplyEmail: "no-reply@sninc.us",
//    EndOfQuestions: `Notification has been sent to On-Call Personal. Use #new to start new service request. Use #cancel to cancel this service.`,
//    AllowServiceMessage: "Service call received, the system is processing the service request",
//    DenyServiceMessage: `You are not allowed to Request Service with SMS. Please call main office ${ContractorData.Phone}`,
//    NotinSystemMessage: `Sorry, we were not able to find you in our system. Please contact the main number ${ContractorData.Phone} to request service`,
//    NotificationSent: "Notification has been sent to On-Call team.",
//    SMSFeedbackMessages: true,
//    RepeatCustomerMessage: `This number is for new projects or service work. If you are texting regarding an ongoing project or issue, please contact us at ${ContractorData.mobile}. If this is new work, please answer following question`,
//    CustomerType: false,
//    ShowRepeatCustomer: false
//}





export default function RadioButtonsGroup(props) {
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const WebsiteType = useSelector(state => state.WebsiteType);

    let workUrl = `https://${HQData.SubDomain}`;

    const initialState = {
        phone: ContractorData.phone        
    }
    const [state, setState] = useState(initialState);

    let ServiceOppMessages = [
        {
            key: "FirstTime",
            label: "Customer first time use of number",
            name: "FirstTime",
            value: `This is ${ContractorData.CompanyName} service number. The system will ask you to provide some information and possibly upload pictures. This helps us offer you faster and more accurate service. Since, this is your first time, please provide following info:`
        },
        {
            key: "EndOfQuestions",
            label: "End Of Questions",
            name: "EndOfQuestions",
            value: "Notification has been sent to On-Call Personal. Use #new to start new service request. Use #cancel to cancel this service."
        }        
    ];

    let PartsOrderMessages = [
        {
            key: "FirstTime",
            label: "Customer first time use of number",
            name: "FirstTime",
            value: `Since, this is your first time, please provide following info:`
        },
        {
            key: "EndOfQuestions",
            label: "End Of Questions",
            name: "EndOfQuestions",
            value: "Thanks, someone from our parts team will get back to you shortly."
        }
    ];

    useEffect(() => {

        let ListItemsss = CheckInMessages;
        let ShowRepeatCustomerOption = false;

        if (WebsiteType === "ServiceWithText") {
            ListItemsss = ServiceOppMessages;
            ShowRepeatCustomerOption = true;
        }

        if (props.Category === "PartsMessages") {
            ListItemsss = PartsOrderMessages;
            ShowRepeatCustomerOption = false;
        }

        ListItemsss.map((each, inn) => {
            setState(prevState => ({
                ...prevState,
                [each.key]: each.value
            }));
        })
           
        setState(prevState => ({
            ...prevState,
            phone: ContractorData.phone,
            MessageList: ListItemsss,
            ShowRepeatCustomerOption: ShowRepeatCustomerOption
        }));

    }, [WebsiteType])

    useEffect(() => {

        let StepValueArray = {};
        let SelectCustomerType = {};
        let ShowCustomerType = false;

        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        if (props.StepValueArray) {
            SelectCustomerType = props.StepValueArray.find(x => x.KeyId === "SelectCustomerType");
            if (SelectCustomerType
                && SelectCustomerType.CustomerType
                && SelectCustomerType.CustomerType === "Individual") {
                ShowCustomerType = true;
            } else {
                setState(prevState => ({
                    ...prevState,
                    ShowRepeatCustomer: false
                }));
            }
        }

        console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
                ShowCustomerType: ShowCustomerType,                
            }));
        }

    }, [props])

    const handleNext = () => {

        let SelectedDataReturn = {
            ...state,
            id: props.id,
            KeyId: props.KeyId
        }
        console.log("SaveDataBack.Priority", SelectedDataReturn)
        if (props.SelectedDataReturn) {
            props.SelectedDataReturn(SelectedDataReturn)
        }

        if (props.handleNext) {
            props.handleNext();
        }

    }

    const SetValues = (data, cat) => {

        // let newdata = data.target.value;

        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));


    }

    console.log("Priority.state", state)
    console.log("Priority.props", props)

    return (
        <Grid container spacing={2}>

            {/*    
      <Grid item xs={12}>
        
        <FormGroup>
          <FormControlLabel
            control={<Switch
              onClick={(event) => SetValues(event.target.checked, "SMSFeedbackMessages")}
              value={state.SMSFeedbackMessages || ""} />}
            label="Use following Custom SMS Feedback Messages" />
        </FormGroup>

      </Grid>
      */}

            
            
            {state.MessageList
                && state.MessageList.map((each, indd) => (

                <Grid item xs={12}>
                    <TextField
                        id={each.key + indd}
                        key={each.key + indd}
                        disabled={state.disabled}
                        label={each.label}
                        variant="outlined"
                        name={each.name}
                        value={state[each.key] || ""}
                        fullWidth
                        multiline
                        rows={3}
                        onChange={(e) => SetValues(e.target.value, each.key)}
                    />
                </Grid>

                ))}

            {state.ShowRepeatCustomerOption ? (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Repeat Customer Message
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        

                        <FormGroup>
                            <FormControlLabel control={<Switch
                                onChange={(event) => SetValues(event.target.checked, "ShowRepeatCustomer")}
                                checked={state.ShowRepeatCustomer}
                            //  disabled={state.disabled}
                            />} label="Show Message" />
                            
                        </FormGroup>

                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            
                            label="Repeat Customer Message"
                            variant="outlined"
                            name="RepeatCustomerMessage"
                            value={state.RepeatCustomerMessage || ""}
                            fullWidth
                            multiline
                            rows={2}
                            onChange={(e) => SetValues(e.target.value, "RepeatCustomerMessage")}
                        />
                    </Grid>
                </>
            ) : null}
            
            <Grid item xs={12}>
                <div>
                    <div>
                        <Button
                            disabled={props.activeStep === 0}
                            onClick={() => props.handleBack()}
                        // className={classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext()}
                        //  className={classes.button}
                        >
                            {'Next'}
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>

    );
}
