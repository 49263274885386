import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import {
  Paper,
  Grid,
  Backdrop,
  CircularProgress, Snackbar,
  InputLabel, Select, MenuItem, Card,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import { API, graphqlOperation } from "aws-amplify";
import { createBatch } from '../graphql/mutations';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
////import FormHelperText from '@mui/material/FormHelperText';
//import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
//import RenderModule from '../utils/RenderModule';
//import SelectCategory from '../components/SelectCategory';
import { NewPageId, getAutoCompletedata, removeEmptyStringElements } from '../utils/CommonGraphql';
import ListCheckBox from '../List/ListCheckbox';
//import CardBox from '../components/ShowCheckBoxGroup';
import DrawerShow from '../utils/DrawerModule';
import SelectAsset from '../components/SelectAssets';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let NewService = NewPageId("NSvc")

let APIKey = NewPageId("API")
let EmailKey = NewPageId("EE");


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  cardmargin: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  formControl: {
    //margin: theme.spacing(1),
  },
}));



export default function SimpleAccordion(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const ContractorData = useSelector(state => state.ContractorData);
  const UserData = useSelector(state => state.UserData);
  //const cid = useSelector(state => state.cid);
  const [DeawerOpen, SetDeawerOpen] = useState(false);
  const initialState = {
    DataBack: [],
    propss: {},
    AppliesTo: "Company",
    Category: "Urgency",
    Trade: [],
    Urgency: [],
    FormData: {},
    Form: [],
    UrgencySelected: [],
    TradeSelected: [],
    FormsSelected: [],
    TriggerType: "",
    Trigger: "",
    title: ""
  }
  const [state, setState] = useState(initialState);
  const [loading, SetLoading] = useState(false);
  
  useEffect(() => {
    //let Cat = "Lambda";
    //let GetBack = ["Urgency", "Trade"];
    //GetDatafromDB(Cat, GetBack);

    let Cat = "Online";
    let GetBack = ["Form", "Urgency", "Trade"];
    GetDatafromDB(Cat, GetBack);
  }, [])

  //useEffect(() => {
  //  if (state.Form && state.Form.length > 0) {
  //    setState(prevState => ({ ...prevState, Form: [...CommonFormList, ...state.Form] }))
  //  }

  console.log("NotificaitonTrigger.props", props);
  //  console.log("CommonFormList", CommonFormList);

  //}, [state.Form])

  const GetDatafromDB = async (Category, GetBack) => {
    SetLoading(true);
    let GetBackArray = [];
    GetBack.map(each => {
      GetBackArray.push({
        ArrayName: each,
        ArraySearchString: `::${each}`
      })
    })
    const DataString = {
      Category: Category,
      FindArray: GetBackArray
    };

    let NewData = await getAutoCompletedata(DataString, ContractorData.ContractorId);

    //if (NewData && NewData.Form) {
    //  NewData.Form = [...CommonFormList, ...NewData.Form]
    //}

    setState(prevState => ({ ...prevState, ...NewData }))

    SetLoading(false);
    console.log("NewData", NewData);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    console.log("event.target.value", event.target.name, event.target.value)
    setState(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
  };

  const AddCategory = (cat) => {
    console.log("cat", cat)
    setState(prevState => ({
      ...prevState,
      Category: cat,
      DetailComponent: "AddSetupSelection",
      FormData: {}
    }))
    SetDeawerOpen(true);
  };

  const PreviewForms = (data) => {
    //console.log("cat", cat)
    setState(prevState => ({
      ...prevState,
      Category: data.Category,
      DetailComponent: "FormPreview",
      FormData: { ...data }
    }))
    SetDeawerOpen(true);
  }

  const CustomForm = (cat) => {

    setState(prevState => ({
      ...prevState,
      FormType: cat,
    }))

    if (cat !== "Select") {
      setState(prevState => ({
        ...prevState,
        Category: cat,
        DetailComponent: "FormCreatorClass",
        FormData: {}
      }))
      SetDeawerOpen(true);
    }
  }


  const SelectedCat = (cat) => {
    console.log("cat", cat)
    setState(prevState => ({
      ...prevState,
      NewKey: NewPageId("key"),
      cat: cat
    }))
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const KeyArray = (data, cat) => {
    console.log("KeyArray", data, cat)
    setState(prevState => ({
      ...prevState,
      [cat]: data
    }));
  }

  const handleClose = (event, reason) => {

    setState(prevState => ({
      ...prevState,
      AlarmMessage: "",
      OpenAlert: false
    }));

  };

  const ClosingDrawer = (e) => {
    let CategoryItems = state[state.Category]
    setState(prevState => ({ ...prevState, [state.Category]: [e, ...CategoryItems] }))
    SetDeawerOpen(false)
  }

  const handleNextValidate = (item) => {
    if (state[item] && state[item].length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setState(prevState => ({
        ...prevState,
        AlarmMessage: "Please select items before proceeding to next step",
        OpenAlert: true
      }));
    }

  };

  const GetSearchTerms = (selected, list) => {

    try {
      console.log("selected, list", selected, list)
      let SearchWords = "";
      let Temp = "";
      //  if (selected.length > 0)
      selected.map(each => {
        Temp = list.find(x => x.key === each);
        SearchWords += ` ${Temp.field1} |`;
      })
      return SearchWords;
    } catch (err) { console.log('GetSearchTerms err', err) }

  }

  ////////////////////////////////////////////
  const SaveData = () => {
    //console.log('SaveData', FormData);
    try {

      const key = `Data::Online::NewService#${NewService}`;
      //const key = `Data::Company::${Cat}#${NewService}`;
      var currentUnixTime = new Date();
      var currentTime = currentUnixTime.toISOString();

      //   let posterId = User;

      let Field1 = "";
      let Field2 = "";
      let SearchWords = "";
      let MissingInfoFlag = false;
      let dataJSON = "";
      let FormdataJSON = {};
      let PhotoService = [];
      let Documents = [];
      let AlarmMessage = 'Please enter ';
      let DBInput = {};
      let DBInput2 = {};
      let Phone = "";
      let Email = "";
      let InputArray = [];
      let CompanyId = "";
      let CompanyName = "";
      let FormDD = {};
      let post = "";
      let title = "";
      let EndDate = "";
      let steps = [];

      //make sure cid and companyid exist
      //if (cid && cid.CompanyId && cid.CompanyId !== "") {
      //  CompanyId = cid.CompanyId;
      //  CompanyName = cid.CompanyName;
      //} else {
      //  MissingInfoFlag = true;
      //  AlarmMessage += `* Please select Company `;
      //}

      //if (state.PolicyName === "") {
      //  MissingInfoFlag = true;
      //  AlarmMessage += `* Please enter Policy Name `;
      //}

      //if (!state.ShiftSelected || state.ShiftSelected.length < 1) {
      //  MissingInfoFlag = true;
      //  AlarmMessage += `* Please select Shift `;
      //} else {
      //  SearchWords += GetSearchTerms(state.ShiftSelected, state.Shift);
      //  console.log("state.ShiftSelected, state.Shift", state.ShiftSelected, state.Shift)
      //}

      //if (!state.AreaSelected || state.AreaSelected.length < 1) {
      //  MissingInfoFlag = true;
      //  AlarmMessage += `* Please select Area `;
      //} else {
      //  SearchWords += GetSearchTerms(state.AreaSelected, state.Area);
      //}

      if (!state.UrgencySelected || state.UrgencySelected.length < 1) {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Service Action / Urgency `;
      } else {
        SearchWords += GetSearchTerms(state.UrgencySelected, state.Urgency);
      }

      if (!state.TradeSelected || state.TradeSelected.length < 1) {
        MissingInfoFlag = true;
        AlarmMessage += `* Please select Trade `;
      } else {
        SearchWords += GetSearchTerms(state.TradeSelected, state.Trade);
      }


      //if (!state.NotificationStepsList || state.NotificationStepsList.length < 1) {
      //  MissingInfoFlag = true;
      //  AlarmMessage += `* Please select Personal & Steps `;
      //} else {

      //  state.NotificationStepsList.map((Step) => {
      //    let Teamms = [];
      //    if (Step.Teams) {
      //      Step.Teams.map(eachstep => {

      //        if (eachstep.ScheduleType === "Recurring") {
      //          EndDate = "3000-1-1";
      //        } else {
      //          EndDate = eachstep.EndDate;
      //        }

      //        DBInput = {
      //          pk1: CompanyId,
      //          sk1: eachstep.key,
      //          gsi1Pk2: "Notify::" + ContractorData.ContractorId,
      //          gsi1Sk2: EndDate,
      //          gsi2Pk2: ContractorData.ContractorId,
      //          gsi2Sk2: eachstep.key,
      //          dataJSON: JSON.stringify(removeEmptyStringElements(eachstep))
      //        }

      //        Teamms.push({
      //          pk1: CompanyId,
      //          sk1: eachstep.key,
      //        })

      //        SearchWords += eachstep.SearchWords;
      //        InputArray.push(removeEmptyStringElements(DBInput));
      //      })

      //      steps.push({
      //        Teams: Teamms,
      //        delay: Step.delay,
      //        step: Step.step,
      //      })
      //    }
      //  });

      //}


      FormdataJSON = {

        ContractorData: ContractorData,
        date: currentTime,
        PersonData: UserData,
        UrgencySelected: state.UrgencySelected,
        TradeSelected: state.TradeSelected,
        FormsSelected: state.FormsSelected,
        SearchWords: SearchWords,
        CompanyId: ContractorData.ContractorId,
        CompanyName: CompanyName,
        id: NewService,
        key: key,
        field1: state.PolicyName,
        field2: "Service Form",
        Message: state.Message,
        ContractorId: ContractorData.ContractorId,
        title: state.title,
        pk1: ContractorData.ContractorId,
        sk1: key,
        gsi1Pk2: "NewService::" + ContractorData.ContractorId,
        gsi1Sk2: "3000-1-1",
        gsi2Pk2: ContractorData.ContractorId,
        gsi2Sk2: key
      }



      dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))

      console.log('MissingInfoFlag', MissingInfoFlag, AlarmMessage)


      //if alerts
      if (MissingInfoFlag) {
        setState(prevState => ({
          ...prevState,
          AlarmMessage: AlarmMessage,
          OpenAlert: true
        }));
      } else {
        //if no alerts

        DBInput = {
          pk1: ContractorData.ContractorId,
          sk1: key,
          gsi1Pk2: "NewService::" + ContractorData.ContractorId,
          gsi1Sk2: "3000-1-1",
          gsi2Pk2: ContractorData.ContractorId,
          gsi2Sk2: key,
          dataJSON: dataJSON
        }

        InputArray.push(removeEmptyStringElements(DBInput));


        console.log('input', InputArray)
        console.log('FormdataJSON', FormdataJSON)
        //console.log('state.NotificationStepsList', state.NotificationStepsList)


        if (InputArray.length > 0) {
          API.graphql(graphqlOperation(createBatch, { input: InputArray }))
            .then((results) => {
              console.log('createBatch Data ', results);
              if (props.CloseDrawer) {
                props.CloseDrawer(FormdataJSON);
              }
            }).catch(error => alert(error.message));


          //if (props.CloseDrawer) {
          //  props.CloseDrawer();
          //}
        }



      } // end of no alerts

    } catch (err) { console.log("SaveData err", err); }
  }

  const SetValue = (e, name) => {
    let Message = e.target.value;
    let Trigger = "";
    setState(prevState => ({ ...prevState, [name]: Message }))
  }

  const SetTriggerType = (data) => {
    let TriggerType = data.target.value;
    let Trigger = "";

    if (TriggerType === "email") {
      Trigger = `${ContractorData.id}_${EmailKey}@svcbot.com`;
    } else {
      Trigger = `${ContractorData.id}_${APIKey}`;
    }

    setState((prevState) => ({
      ...prevState,
      TriggerType: TriggerType,
      Trigger: Trigger
    }))

  }

  //console.log("props", props);
  return (
    <div className={classes.root}>  

      <Paper className={classes.paper}>

        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key={0}>
            <StepLabel>Email / API</StepLabel>
            <StepContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    Top message on the new service request page
                  </Typography>
                </Grid>
                <Grid item xs={12}>

                  <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                    <FormLabel component="legend">Schedule Type</FormLabel>
                    <RadioGroup
                      //aria-label={Item.label}
                      //id={`radio-${Item.key}-${index}`}

                      name="TriggerType"
                      //error={ErrorMessage}
                      value={state.TriggerType}
                      required={true}
                      onChange={e => SetTriggerType(e)}
                    >
                      <FormControlLabel disabled={state.disabled} id="ScheduleType1" value="email" control={<Radio />} label="Email Trigger" />
                      <FormControlLabel disabled={state.disabled} id="ScheduleType2" value="api" control={<Radio />} label="API Trigger" />
                    </RadioGroup>
                  </FormControl >


                </Grid>
              </Grid>
              <div className={classes.actionsContainer}>
                <div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

          <Step key={1}>
            <StepLabel>Copy the Informaiton</StepLabel>
            <StepContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>

                  {state.TriggerType === "api" && (
                    <>
                      <Typography>
                        Use this end point to trigger.  The system will trigger notificaiton based on the Notification/Escalation
                    </Typography>
                      <Typography>
                        End Point: https://whatevere.com
                  </Typography>
                    </>
                  )}

                  {state.TriggerType === "email" && (
                    <Typography>
                      The system will trigger notificaiton based on the Notification/Escalation
                    </Typography>
                  )}

                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.cardmargin}>
                  <Typography variant="h5" gutterBottom component="div">
                    {state.Trigger}
                    </Typography>
                    </Card>
                </Grid>



              </Grid>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    //disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNextValidate("Trigger")}
                    className={classes.button}
                  >
                    {'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

          {UserData.CustomerType === "Contractor" && (
          <Step key={2}>
            <StepLabel>{`${ContractorData.ContractorName} Notification List`}</StepLabel>
            <StepContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    Select the time and dates the policy will trigger
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => AddCategory("Urgency")}
                    variant="contained" color="secondary"
                  >
                    Add
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <ListCheckBox
                                          DataBack={state.Urgency}
                    //propss={state.propss}
                    title={`${ContractorData.ContractorName} Notification List`}
                    SearchWords=""
                    AddBox={(e) => console.log("Addbox", e)}
                    SelectedKeyArray={(e) => KeyArray(e, "ContractorESC")}
                    disabled={false}
                    ShowUpDown={true}
                    ShowEdit={false}
                    PerPage={10}
                    id={"UrgencySelected" + activeStep}
                  />
                </Grid>

              </Grid>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    //disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNextValidate("ContractorESC")}
                    className={classes.button}
                  >
                    {'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
          )}

          <Step key={3}>
            <StepLabel>{`${props.CompanyName} Notification List`}</StepLabel>
            <StepContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    Select the time and dates the policy will trigger
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={() => AddCategory("Trade")}
                    variant="contained" color="secondary"
                  >
                    Add
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <ListCheckBox
                                      DataBack={state.Trade}
                    title={`${props.CompanyName} Notification List`}
                    SearchWords=""
                    AddBox={(e) => console.log("Addbox", e)}
                    SelectedKeyArray={(e) => KeyArray(e, "CompanyESC")}
                    disabled={false}
                    ShowUpDown={true}
                    ShowEdit={false}
                    PerPage={10}
                    id={"TradeSelected" + activeStep}
                  />


                </Grid>
              </Grid>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    //disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNextValidate("CompanyESC")}
                    className={classes.button}
                  >
                    {'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

          <Step key={4}>
            <StepLabel>Save Selections</StepLabel>
            <StepContent>


              <TextField
                required={true}
                //disabled={state.disabled}
                error={state.ErrorMessage}
                //id={`textline-${Item.key}-${index}`}
                //defaultValue="Notification Policy"
                //type={Item.type}
                label="Policy Name"
                name="PolicyName"
                value={state.PolicyName || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => SetValue(e, "PolicyName")}
              />


              <Button
                variant="contained"
                color="primary"
                onClick={() => SaveData()}
                className={classes.button}
              >
                {'Save Data'}
              </Button>
            </StepContent>
          </Step>

        </Stepper>
        <Button onClick={handleReset} className={classes.button}>
          Reset
          </Button>

      </Paper>
      {/* end  */}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

          <Snackbar
              open={state.OpenAlert}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              message={state.AlarmMessage}
          />
          {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}


      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={() => ClosingDrawer()}
        //DetailComponent: "AddSetupSelection" FormData
        //LoadComponenet="ItemAddPage"
        propss={{
          CloseDrawerAdd: (e) => ClosingDrawer(e),
          Category: state.Category,
          FormData: state.FormData
        }}
        DrawerStatus={DeawerOpen}
        // CloseDrawer={(e) => SetDeawerOpen(false)}
        OpenDrawer={(e) => SetDeawerOpen(false)}
        anchor="left"
      />


    </div>
  );
}
