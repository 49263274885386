import React, { useEffect, useState } from 'react';
import {
    TextField,
    //Collapse,
    Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    CardActions,
    Snackbar,
    CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import SelectAssets from '../components/SelectAssets';
//import { NewPageId } from '../utils/CommonGraphql';
//import MuiAlert from '@mui/material/Alert';
//import { useSelector } from 'react-redux';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function RadioButtonsGroup(props) {
  //  const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        AssetList: []
    }
    const [state, setState] = useState(initialState);
  //  const [DrawerStatus, OpenDrawer] = useState(false);

   
    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

      //  console.log("Priority.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])


    const SetValues = (data, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

    }

    const handleNext = () => {

            let SelectedDataReturn = {
                ...state,
                id: props.id,
                KeyId: props.KeyId
            }
        console.log("SelectAssetBox.key", SelectedDataReturn)
            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedDataReturn)
            }

            if (props.handleNext) {
                props.handleNext();
            }

    }

   
    console.log("SelectAssetBox.state", state)
    console.log("SelectAssetBox.props", props)

    return (
        <>
            <Grid container spacing={1}>
               
                <Grid item xs={12} >
                    <TextField
                        label="Description"
                        name="title"
                        value={state.Body || ""}
                        multiline
                        minRows={3}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Body")}
                    />

                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

           

        </>

    );
}
