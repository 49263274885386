import React, { useEffect, useState } from 'react';
import {
    //TextField,
    ////Collapse,
    //Typography,
    //Paper,
    Grid,
    Button,
    //Backdrop,
    //CircularProgress,
    Card,
    //FormGroup,
    CardContent,
    //CardActions,
    //Snackbar,
    CardHeader,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import SelectAssets from '../components/SelectAssets'; //
import GoogleGetGPS from '../views/modules/GoogleGetGPS'; 
//import { NewPageId } from '../utils/CommonGraphql';
//import MuiAlert from '@mui/material/Alert';
//import { useSelector } from 'react-redux';
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function RadioButtonsGroup(props) {
  //  const ContractorData = useSelector(state => state.ContractorData);
    const initialState = {
        AssetList: [],
        gpslink: "",
        gps: ""
    }
    const [state, setState] = useState(initialState);
   
    useEffect(() => {

        let StepValueArray = {};
        if (props.KeyId && props.StepValueArray) {
            StepValueArray = props.StepValueArray.find(x => x.KeyId === props.KeyId);
        }

        console.log("SelectAssetBox.StepValueArray", StepValueArray)
        if (props && props.StepValueArray) {
            setState(prevState => ({
                ...prevState,
                ...StepValueArray,
            }));
        }

    }, [])


    const SetValues = (data, name) => {

        let Body = "";

        if(data && data.length > 0){
            data.map(each => {
                Body += `${each.field1} ${each.field2}, `
                Body += `${state.gpslink}`
        })
        } else if(data && data.field1 && data.field2){
            Body = `${data.field1} ${data.field2} ${state.gpslink}`
        }

        setState(prevState => ({
            ...prevState,
            [name]: data,
            Body: Body
        }));

    }

    const handleNext = () => {

            let SelectedDataReturn = {
                ...state,
                id: props.id,
                KeyId: props.KeyId
            }
        console.log("SelectAssetBox.key", SelectedDataReturn)
            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(SelectedDataReturn)
            }

            if (props.handleNext) {
                props.handleNext();
            }

    }

    const SetCoordinates = (gps) => {        
        if (gps && gps.gpslink && gps.Location) {
            setState(prevState => ({
                ...prevState,
                Location: gps.Location,
                gpslink: gps.gpslink,
                Body: gps.gpslink
            }));
        }
    }
       
    console.log("SelectAssetBox.state", state)
    console.log("SelectAssetBox.props", props)

    return (
        <>
            <Grid container spacing={1}>
               
                <Grid item xs={12} >

                    <Card>
                        <CardHeader
                            title={`Select ${props.Category}`}
                        />

                        <CardContent>

                            <Grid container spacing={1}>

                                {state.Category === "Location" ? (
                                <Grid item xs={12} >
                                        <GoogleGetGPS
                                            GPSfound={(GPS) => SetCoordinates(GPS) }
                                        />
                                </Grid>
                                ) : null}
                                <Grid item xs={12} >
                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "AssetList")}
                                CompanyId={props.CompanyId}
                                CompanyName={props.CompanyName}
                                title={`Select ${props.Category}`}
                                Category={props.Category}
                                AssetList={state.AssetList}
                            />
                                </Grid>
                            </Grid>
                        </CardContent>


                    </Card>

                </Grid>
                
                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

           

        </>

    );
}
