import React, { useState } from 'react';
import {
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Avatar,
    IconButton,
    Stack,
    Link
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachEmailIcon from '@mui/icons-material/ForwardToInbox';
import { red } from '@mui/material/colors';
import ShareIcon from '@mui/icons-material/Share';

export default function ClipboardCopy(props) {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(props.copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            <Card>
                <CardHeader
                    title="Share Link"                    
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                            <ShareIcon />
                        </Avatar>
                    }
                />
                <CardContent>
                    
                    <Typography sx={{ color: red[500] }} variant="h4" gutterBottom component="div">
                        {props.copyText}
                    </Typography>

                </CardContent>
                <CardActions>
                    <Stack
                        direction={{ xs: 'row' }}
                        spacing={2}
                    >
                        <a href={`mailto:?subject=Work%20Details%20${props.newtitle}%20&body=%0D%0APlease%20use%20following%20link%20to%20see%20work%20details%2C%20pictures%2C%20and%20notes%3A%0D%0A%0D%0A${props.copyText}`}>
                            <Button
                                startIcon={<AttachEmailIcon />}
                            >
                                Email Link
                            </Button>
                        </a>

                    <Button
                        onClick={handleCopyClick}
                        startIcon={<ContentCopyIcon />}
                    >
                        {isCopied ? `Copied!` : `Copy Link`}
                    </Button>

                        </Stack>
                </CardActions>

            </Card>
        </div>
    );
}
