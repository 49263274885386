import React, { Component } from 'react'
import {
  TextField,
  Grid,
  Card,
  CardContent, Checkbox, Typography, Snackbar,
  Button, FormControlLabel
} from '@mui/material';
//import {
//  AddCircleOutlineIcon,
//  DeleteIcon
//} from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonIcon from '../components/ButtonIcon'

const sample = {
  Order: 7,
  VariableName: "payment",
  VariableInput: "",
  message: "",
  type: "select",
  key: "iho7AcjaW33",
  required: false,
  label: "Payment Method",
  DataSourse: "options",
  defaultValue: ["NewPO"],
  options: [
    { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
    { key: "NewPO", label: "New PO", value: "NewPO" },
    { key: "warranty", label: "Warranty", value: "warranty" },
    { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
  ]
};

class AddFormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionLable: '',
      AddSelectOptions: [],
      Required: false,
      open: false,
      AlartMessage: "",
    }
  }

  componentDidMount = () => {
    console.log("props", this.props)
    if (this.props.Edit) {
      this.setState({ AddSelectOptions: this.props.Options }, () => { })
    }
  }

  RemoveOption = (index) => {
    let TradeServicestemparr = this.state.AddSelectOptions;
    TradeServicestemparr.splice(index, 1);
    this.setState({ AddSelectOptions: TradeServicestemparr });
    this.props.AddLineOpt(TradeServicestemparr);
  }

  AddNewLine = () => {
    let shortidd = this.state.AddSelectOptions.length + 1;
    if (this.state.optionLable !== "") {
      let CleanLable = this.state.optionLable.replace(/[^a-zA-Z0-9_]/g, "");
      let NewSvcOption = {
        key: shortidd,
        label: this.state.optionLable,
        value: this.state.optionLable,
        Order: shortidd,
        VariableName: CleanLable,
        VariableInput: "",
        message: "",
        type: "select",
        DataSourse: "options"
      }
      let newarr = [...this.state.AddSelectOptions, NewSvcOption];
      this.setState({ optionLable: "" })
      this.setState({ AddSelectOptions: newarr });
      this.props.AddLineOpt(newarr);
    } else {
      this.setState({
        open: true,
        AlartMessage: "Please enter option Name",
      }, () => { });
      return
    }
  }

  handleClose = () => {
    this.setState({
      open: false
    }, () => { })
  }

  UpdateOption = (e, index) => {
    let newData = this.state.AddSelectOptions;
    console.log('e, kkey', e, index, newData)
    newData[index].label = e;
    newData[index].value = e;
    this.setState({ AddSelectOptions: newData });
    this.props.AddLineOpt(newData);
    console.log('newData', newData)
  }

  

  render() {
    return (
      <div>
        <Card>
          <CardContent>
            <Grid container alignItems="center" spacing={1}>
              {this.state.AddSelectOptions.length > 0 && this.state.AddSelectOptions.map((option, index) => (
                <>
                  <Grid item sm={12} xs={12} >
                    <ButtonIcon
                      required={true}
                      id={"forr" + index}
                      defaultValue={this.props.FormTitle}
                      label="Add Option"
                      name="AddOption"
                      value={this.state.AddSelectOptions[index].label}
                      indexx={index}
                      fullWidth
                      variant="outlined"
                      UseIcon="Delete"
                      AddCircleOutlineIcon={(e) => console.log(e)}
                      DeleteIcon={(x) => this.RemoveOption(x)}
                      GetOptions={(e) => this.UpdateOption(e.target.value, index)}
                    />
                  </Grid>
                  
                </>
              ))}
              
              <Grid item sm={12} xs={12} >
                <ButtonIcon
                  required={true}
                  id="formtitle"
                  defaultValue={this.props.FormTitle}
                  label="Add Option"
                  name="AddOption"
                  value={this.state.optionLable}
                  indexx={0}
                  fullWidth
                  variant="outlined"
                  UseIcon="Add"
                  AddCircleOutlineIcon={() => this.AddNewLine()}
                  DeleteIcon={(e) => console.log(e)}
                  GetOptions={(e) => this.setState({ optionLable: e.target.value })}
                />
                </Grid>
                
             
              
            </Grid>
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.open}
          onClose={this.handleClose}
          message={this.state.AlartMessage}
          key="w87482ows"
        />
      </div>
    )
  }
}

export default AddFormField
