import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Grid,
  
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import ToolbarHeading from '../components/ToolbarHeading';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, getAutoCompletedata } from '../utils/CommonGraphql';
import ListModule from '../List/ListModule';



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const ListItems = (props) => {

  let query = useQuery();
  console.log("query", query);

  const classes = useStyles();
  const params = useParams();

  //get Redux
  const UserData = useSelector(state => state.UserData)
  const ContractorData = useSelector(state => state.ContractorData);
  const cid = useSelector(state => state.cid);
  //if window is mobile or regular
  const matches = useMediaQuery('(min-width:600px)');
  const [DrawerStatus, OpenDrawer] = useState(false);
  const [loading, SetLoading] = useState(false);
  const initialState = {
    Blocks: [],
    SearchWords: "",
    ShowDetails: false,
    anchor: "left",
    BoxWidth: 6,
    //ListType: "ListModule",
    ListType: "ListModule",
    CompanyId: cid.CompanyId,
    pk1: cid.CompanyId,
    propss: {},
    key: NewPageId("win"),
  }
  const [state, setState] = useState(initialState);

  

  //propss = {
  //  DataBack, RawData, 
  //}
  //title, SearchWords, AddBox, disabled, ShowUpDown

  useEffect(() => {

    console.log('params', params);
    console.log('props', props);

    let Category = "Company";
    let DetailComponent = "Details"
    let ListType = "ListModule";
    let CompanyId = ContractorData.ContractorId;
    let pk1 = cid.CompanyId;

    if (params.Category) {
      Category = params.Category;
    }

    if (props.Category) {
      Category = props.Category;
    }

    if (params.DetailComponent) {
      DetailComponent = params.DetailComponent
    }

    if (props.DetailComponent) {
      DetailComponent = props.DetailComponent
    }

    if (params.ListType) {
      ListType = params.ListType
    }

    if (props.ListType) {
      ListType = props.ListType
    }

    if (params.CompanyId) {
      CompanyId = params.CompanyId
      pk1 = params.CompanyId
    }

    if (props.CompanyId) {
      CompanyId = props.CompanyId;
      pk1 = props.CompanyId
    }

    if (UserData.CustomerType === "Contractor" && Category === "Company") {
      pk1 = ContractorData.ContractorId;
      setState(prevState => ({
        ...prevState,
        pk1: ContractorData.ContractorId,
      }));
    }

    const UUid = NewPageId(Category);
    setState(prevState => ({
      ...prevState,
      Category: Category,
      UUid: UUid,
      //DetailComponent: DetailComponent,
      ListType: ListType,
      CompanyId: CompanyId
    }));

    GetDatafromDB(Category, pk1);

  }, [])

  const GetDatafromDB = async (Category, pk1) => {
    SetLoading(true);

    let ContractorInfo = [];
    if (UserData.CustomerType === "Contractor") {
      ContractorInfo = ContractorData;
    }

    const DataString = {
      Category: Category,
      FindArray: [
        {
          ArrayName: "DataBack",
          DuplicateArray: "RawData",
          ArraySearchString: ":",
        }
      ]
    };

    let NewData = await getAutoCompletedata(DataString, pk1);
    setState(prevState => ({ ...prevState, propss: { ContractorInfo, ...NewData } }))

    SetLoading(false);
    console.log("NewData", NewData);
  }


  // Additional Information Box
  const AddBox = (add) => {
    console.log("AddBox", add);
    let NewKey = NewPageId("list");
    if (matches) {
      setState(prevState => ({
        ...prevState,
        DetailComponent: add.DetailComponent,
        Category: add.Category,
        key: add.key || NewKey,
        ShowDetails: true,
        DetailsProps: {
          title: add.field1,
          Category: state.Category,
          ShowEditButton: false,
          CompanyId: props.CompanyId,
          CompanyName: props.CompanyName,
          PreviewEdit: false,
          Data: add
        },
      }))

    } else {
      //open Drawer
      setState(prevState => ({
        ...prevState,
        DetailComponent: add.DetailComponent,
        Category: state.Category,
        ShowDetails: false,
        DetailsProps: {
          title: add.field1,
          Category: state.Category,
          ShowEditButton: false,
          PreviewEdit: false,
          CompanyId: props.CompanyId,
          CompanyName: props.CompanyName,
          Data: add
        },
        key: add.key || NewKey
      }))

      OpenDrawer(true);
    }
  }

  const CompanyData = (data) => {
    console.log("data", data);
    GetDatafromDB(state.Category, data.pk1);
  }

  const AddItem = (data) => {
    setState(prevState => ({
      ...prevState,
      DetailComponent: "NotificationTriggerSetup",
      ShowDetails: false,
      DetailsProps: {
        //title: add.field1,
        Category: state.Category,
        CompanyId: props.CompanyId,
        CompanyName: props.CompanyName,
        
        //ShowEditButton: false,
        //PreviewEdit: false,
        //Data: add
      },
     // key: add.key || NewKey
    }))

    OpenDrawer(true);
  }

  const CloseDrawer = (c) => {
    GetDatafromDB(state.Category, state.pk1);
    OpenDrawer(false);
  }

  const SMSServiceList = [
    {
      field1: "Emergency Service",
      field2: "",
      key: `Emergency::${props.CompanyId}`,
      DetailComponent: "UrgencyDetails",
      Category: "Emergency",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
    },
    {
      field1: "Todo List",
      field2: "",
      key: `Todo::${props.CompanyId}`,
      DetailComponent: "UrgencyDetails",
      Category: "Todo",
      CompanyId: props.CompanyId,
      CompanyName: props.CompanyName,
    }
  ]

  return (
    <Page
      className={classes.root}
      title={state.Category}
    >
      <Container maxWidth={false}>

        <ToolbarHeading
          Headings="Notification Triggers"
          Category="Triggers"
          HideHeading={true}
          ProcessSearch={(e) => setState(prevState => ({ ...prevState, SearchWords: e }))}
          CompanyData={(e) => CompanyData(e)}
          AddItem={(e) => AddItem(e)}
          AddCompany={false}
          disabled={props.disabled}
        />

        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item key="10101010" lg={state.BoxWidth} xs={12}>

              <Grid container spacing={2}>
                <Grid item key="sdajnfdsalkj" xs={12}>

                  <ListModule
                    AddBox={(e) => AddBox(e)}
                    //SearchWords={state.SearchWords}
                    field1="SMS Service Request"
                    disabled={false}
                    //LoadComponenet={SMSServiceList}
                                      DataBack={SMSServiceList}
                  />
                </Grid>

                <Grid item key="sdajnfdsssalkj" xs={12}>

                  {!loading && <ListModule
                    AddBox={(e) => AddBox(e)}
                    SearchWords={state.SearchWords}
                    field1="Email/API Notification Triggers"
                    AllowAdd={true}
                    AddItem={(e) => AddItem(e)}
                    title={state.Category}
                    disabled={false}
                    LoadComponenet={state.ListType}
                    propss={state.propss}
                  />}
                </Grid>
              </Grid>
            </Grid>

            {state.ShowDetails && (
              <Grid
                item
                key={state.key + "10101010ddss"}
                lg={6}
                //md={6}
                xs={12}
              >
                <RenderModule
                  key={state.key}
                  LoadComponenet={state.DetailComponent}
                  {...state.DetailsProps}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={(e) => CloseDrawer(e)}
        Category={state.Category}
        
        propss={state.DetailsProps}
        key={state.key}
        DrawerStatus={DrawerStatus}
        OpenDrawer={(e) => OpenDrawer(e)}
        anchor={state.anchor || "left"}
      />
    </Page>
  );
};

export default ListItems;
